import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import Service from "../../../Services/Admin/Service";
import React, { useState, useEffect } from 'react'

const ExecutionTrackerListComponent = (props) => {
    const [data, setData] = useState([]);

    useEffect(() => {
      getCategoryData("Project/GetAll");
    }, [])
  
    const getCategoryData = (method) => {
      Service.getdata(method).then((res) => {
        setData(res.data);
      });
    }
  
    const staticname1 = "details";
    const staticname2 = "edit";
  
  
    const backbutton = () => {
      props.navigate(-1);
    }
  
  
    const formatDateTime = (cell, row) => {
      // Format the date and time here, assuming 'time' is a string in ISO 8601 format
      const formattedDate = new Date(cell).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
  
      });
      return formattedDate;
    }
    const linkformatter = (data, row) => {
      return <>
  
        <Link className="btn btn-warning btn-sm" to={`/AddProject/${data}`}>Details</Link>
  
      </>
    }
  
    const column = [
      { dataField: 'projectname', text: 'Project Name', sort: true },
      { dataField: 'leadid', text: 'Lead Name', sort: true },
      { dataField: 'startdate', text: 'Start Date', sort: true, formatter: formatDateTime },
      { dataField: 'enddate', text: 'End Date', formatter: formatDateTime },
      { dataField: 'id', text: 'Actions', formatter: linkformatter }
    ]
  
  return (
    <div className="container-fluid">
    <div className="card shadow mb-4">
      <div className="card-header py-3">
        <div className="row">
          <div className="col-sm-4"><h3>Project Execution Planning List</h3></div>
          <div className="col-sm-5"></div>
          <div className="col-sm-3 d-flex flex-row-reverse">
            <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
            <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link>
          </div>
        </div>
      </div>
    </div>
    <div className="card shadow mb-4">
      <div className="card-header py-3">
        <h6 className="m-0 font-weight-bold text-primary">Execution Planning List</h6>
        {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
      </div>

      <div className="card-body">
        <div className="table-responsive">
          <BootstrapTable keyField="id"
            data={data}
            columns={column}
            pagination={paginationFactory()}
          // filter={filterFactory()}
          />
        </div>
      </div>
    </div>
  </div>
  )
}

export default ExecutionTrackerListComponent