import React, { Component } from 'react' 
import { useNavigate } from 'react-router-dom';
import MeetingListComponent from '../../../Components/Main/Meeting/MeetingListComponent';


function MeetingList(props) {
    let navigate = useNavigate();
    return (  
        <div> 
            <MeetingListComponent {...props} navigate={navigate} />
        </div>
    );  
  }
  
export default MeetingList;  