import React, { useEffect, useState } from 'react'
import Service from '../../../Services/Admin/Service';
import { Link } from 'react-router-dom';

const ExtractedDataIsApprovedComponent = (props) => {

  const id = props.id;
  const [data, setData] = useState([]);
  const [isApproved, setIsApproved] = useState(false);
  const [isDenied, setIsDenied] = useState(false);

  useEffect(() => {
    getExtracetdDataDetails("ExtractedData/GetById?id=" + id);
  }, [])

  const getExtracetdDataDetails = (method) => {
    Service.getdatabyId(method, '').then((res) => {
      setData(res.data);
      console.log(res.data);
    });
  }

  const backbutton = () => {
    props.navigate(-1);
  }

  const handleApprovalChange = (e) => {
    const { name, checked } = e.target;

    if (name === 'isApprove') {
      setIsApproved(checked);
      setIsDenied(false)
    } else if (name === 'isDeny') {
      setIsDenied(checked);
      setIsApproved(false)
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <br />
        <form onSubmit="">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <div className="row">
                <div className="col-sm-3"><h3>Extracted Data Is Approved</h3></div>
                <div className="col-sm-5"></div>
                <div className="col-sm-3">
                  <Link to="/AdminDashboard" className="btn btn-secondary mt-1 ml-2 btn-sm">Home</Link>
                  <button className="btn btn-info mt-1 ml-1 btn-sm" onClick={() => backbutton()}>back</button>
                </div>
              </div>
            </div>
          </div>
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">is Approved</h6>
            </div>
            <div className="card-body" style={{ height: "auto" }}>
              <div className='row'>
                <div className='col-md-5'>
                  {/* <div className="form-group row">
                                        <div className='col-sm-5 required-label'>Field</div>
                                        <div className='col-sm-7'>
                                            <b>

                                            </b>
                                        </div>
                                    </div> */}

                  <div className="form-group row">
                    <div className='col-sm-5 required-label'>Client Name</div>
                    <div className='col-sm-7'>
                      <b>
                        {data.client}
                      </b>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-5 required-label'>Objective</div>
                    <div className='col-sm-7'>
                      <b>
                        {data.objective}
                      </b>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-5 required-label'>Fitment </div>
                    <div className='col-sm-7'>
                      <b>
                        {data.fitment}
                      </b>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-5 required-label'>Cycle</div>
                    <div className='col-sm-7'>
                      <b>
                        {data.cycle}
                      </b>
                    </div>
                  </div>

                </div>
                <div className='col-md-1'></div>
                <div className='col-md-5'>
                  <div className="form-group row">
                    <div className='col-sm-5 required-label'>Number of Visitors</div>
                    <div className='col-sm-7'>
                      <b>
                        {data.noofvisitors}
                      </b>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-5 required-label'>Total Space</div>
                    <div className='col-sm-7'>
                      <b>
                        {data.totalspace}
                      </b>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-5 required-label'>Concurrent Users</div>
                    <div className='col-sm-7'>
                      <b>
                        {data.concurrentusers}
                      </b>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label for="details" class="col-sm-5  required-label  control-label">Is Approved</label>
                    <div class="col-sm-7">
                      <input
                        type="checkbox"
                        name="isApprove"
                        checked={isApproved}
                        onChange={handleApprovalChange}
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="details" class="col-sm-5  required-label  control-label">Denied</label>
                    <div class="col-sm-7">
                      <input
                        type="checkbox"
                        name="isDeny"
                        checked={isDenied}
                        onChange={handleApprovalChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn btn-primary ml-3 mb-3">Update</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ExtractedDataIsApprovedComponent