import React, { Component } from 'react' 
import { useNavigate,useParams } from 'react-router-dom';
import NotificationComponent from '../../../Components/Main/NotificationComponent/NotificationComponent';

function Notification(props) {
    let navigate = useNavigate();
    const params = useParams();
    return (  
        <div> 
            <NotificationComponent {...props} navigate={navigate} id={params.id}  />
        </div>
    );  
  }
  
export default Notification  