import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import CostingListComponent from '../../../Components/Main/SelectVendorComponent/CostingListComponent';

function CostingList(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <CostingListComponent {...props} navigate={navigate} id={params.id} />
        </div>
    );  
  }
export default CostingList  