import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import Design2dListComponent from '../../../Components/Main/DesignComponent/AdminDesignComponent';

function Design2dList(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <Design2dListComponent {...props} navigate={navigate} id = {params.id} />
        </div>
    );  
  }
export default Design2dList  