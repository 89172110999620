import React, { useState, useEffect } from 'react'
import Service from "../../../Services/Admin/Service";
import ApiMethod from '../../../Helpers/ApiMethod/Method'

function AddProjectAssignUser(props) {
    const [dropdownTaskType, setdropdownTaskType] = useState([]);
    const [dropdownUser, setdropdownUser] = useState([]);
    const [selectedtype, setselectedtype] = useState([]);
    const [selectedUser, setselectedUser] = useState([]);
    const [Projectdata, setProjectdata] = useState([]);
    const [SelectedProject, setSelectedProject] = useState([]);
    let id = sessionStorage.getItem('id');
    const [inputField, setInputField] = useState({        
        projectid: 0,
        userid:0,
        createdby:id              
    })

    const apis = ApiMethod.find(o => o.name === 'Category');


    useEffect(() => {        
        getUserData("Users/GetAll");
        getProjectData("Project/GetAll")
    }, [])

   

    const getProjectData = (method) => {
        Service.getdata(method).then((res) => {
            setProjectdata(res.data);
        });
    }

    const getUserData = (method) => {
        Service.getdata(method).then((res) => {
            setdropdownUser(res.data);
        });
    }


    const inputsHandler = (e) => {
        //  setInputField( {[e.target.name]: e.target.value} )
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const backbutton = () => {
        props.navigate(-1);
    }

    const submitButton = (e) => {
        e.preventDefault();
        let task = inputField;

       
        if (selectedUser.length === 0) {
            task.userid = 0;
        } else {
            task.userid = selectedUser;
        }

        if(SelectedProject.length === 0){
            task.projectid = 0
        }else{
            task.projectid = SelectedProject;
        }
        
        Service.createorupdate("AssignProject/CreateOrUpdate", task).then(res => {
            props.navigate('/AddProjectUser');
        });
    }

    

    const handleProject = (e) => {
        setSelectedProject(e.target.value);
    }

    const handleInputUser = (e) => {
        setselectedUser(e.target.value);
    }
    return (

        <div>
            <div className="container-fluid">
                <div className='row'>
                    <div className='col-sm-9 text-left'></div>
                    <div className='col-sm-3'><button className="btn btn-info btn-sm" onClick={() => backbutton()}>back</button></div>
                </div>
                <br />
                <form>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <div className="row">
                                <div className="col-sm-3"><h3>Project Assign Users</h3></div>
                                <div className="col-sm-6"></div>
                                <div className="col-sm-3">
                                    <button className="btn btn-primary btn-block" onClick={submitButton} type='submit'>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Project Assign Users Info</h6>
                        </div>

                        <div className="card-body">
                            

                            <div className="form-group row">
                                <div className='col-sm-3'>Project</div>
                                <div className='col-sm-9'> 
                                    <select className="form-control form-control-user" name="projectid" onChange={handleProject}>
                                        <option>[NONE]</option>
                                        {
                                            Projectdata.map((e, key) => {
                                                return <option key={e.id} value={e.id}>{e.projectname}</option>;
                                            })}
                                    </select>
                                </div>

                            </div>

                            <div className="form-group row">
                                <div className='col-sm-3'>Assign Task</div>
                                <div className='col-sm-9'> <select className="form-control form-control-user" name="assigneeid" onChange={handleInputUser}>
                                    <option>[NONE]</option>
                                    {
                                        dropdownUser.map((e, key) => {
                                            return <option key={e.id} value={e.id}>{e.firstname}</option>;
                                        })}
                                </select>
                                </div>
                            </div>
                        </div>

                    </div>

                    

                </form>
            </div>

        </div>
    )
}

export default AddProjectAssignUser