import React, { Component } from 'react' 
import CreateVendorQuotationComponent from '../../../Components/Main/VendorQuotationComponent/CreateVendorQuotationComponent';

import { useNavigate, useParams } from 'react-router-dom';

function CreateVendorQuotation(props) {
    let navigate = useNavigate();
    const params = useParams();
    return (  
        <div> 
            <CreateVendorQuotationComponent {...props} navigate={navigate} id={params.id} name={params.name} />
        </div>
    );  
  }
  
export default CreateVendorQuotation  