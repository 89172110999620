import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { filterWithUniqueIds, formatDate, getCurrentDate, getCurrentDateTimeForDB, getLoginUserData } from '../../../Helpers/supportedFunctions/supportedGlobalFunctions'
import Service from '../../../Services/Admin/Service';

const UserSheetComponent = (props) => {


  const [rfpList, setRFPList] = useState([]);
  const [rfpIdData, setRfpIdData] = useState({});
  const [readOnly, setReadOnly] = useState(false);
  const [calendarData, setCalendarData] = useState({});
  const [optionDropdown, setOptionDropdown] = useState([]);
  const [optionValue, setOptionValue] = useState('');
  const [optionValue1, setOptionValue1] = useState('');
  const [inputField, setInputfield] = useState({
    rfpId: 0,
    userId: getLoginUserData().userId,
    date: null,
    userName: getLoginUserData().name,
    accountManager: "",
    showName: "",
    area: "",
    optionMade: "",
    createdby: getLoginUserData().userId,
    updatedby: getLoginUserData().userId,
    designerTimesheetDetails: []
  })
  const [timesheetInfo, setTimesheetInfo] = useState(
    {
      rfpId: 0,
      userId: getLoginUserData().userId,
      optionMade: "",
      startDate: null,
      endDate: null,
      clientType: "",
      optionType: "",
      projectPriority: "",
      workingHours: "",
      status: "",
      revisionMade: ""
    }
  );

  useEffect(() => {
    getRFPData("RFP/GetAll?id=" + getLoginUserData().userId);
  }, [])

  const getRFPData = (method) => {
    Service.getdata(method).then((res) => {
      var uniqueRFP = filterWithUniqueIds(res.data);
      setRFPList(uniqueRFP.reverse());
    })
  }


  const getOptionData = (method) => {
    Service.getdata(method).then((res) => {
      const dataCalendar = res.data.filter((e) => e.status === "Converted");
      debugger
      if (dataCalendar.length !== 0) {
        setCalendarData(dataCalendar[0]);
        setReadOnly(true);
      }
      else {
        setCalendarData({ status: "Not Converted" })
      }
      setOptionDropdown(res.data);
      debugger
    })
  }

  const inputOptionHandler = (event) => {
    const selectedOption = event.target.value;
    setOptionValue(selectedOption);
  };

  const inputOptionHandler1 = (event) => {
    var selectedOption = event.target.value;
    setOptionValue1(selectedOption);
  };

  const inputTimesheetHandler = (e) => {
    const { name, value } = e.target;
    setInputfield((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const inputTimesheetInfoHandler = (e) => {
    const { name, value } = e.target;
    setTimesheetInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleInputRFP = (evt) => {
    var RFPId = rfpList.filter((x) => x.id === parseInt(evt.target.value));
    debugger
    if (RFPId.length !== 0) {

      setRfpIdData(RFPId[0]);
      getOptionData("DesignerTimesheet/GetAllbyRFPId?id=" + RFPId[0].id);
    }
  }

  const save = (evt) => {
    evt.preventDefault();
    if (getLoginUserData().userId !== null || getLoginUserData().userId !== 0) {
      debugger
      if (readOnly === false) {
        var status1 = "Not Converted"
      }
      else{
        status1 = "Converted"
      }
      if (optionValue === "Add Option") {
        var actualOption = optionValue1;
      }
      else {
        actualOption = optionValue;
      }
      if (getCurrentDate() !== null) {
        console.log(getCurrentDate());
        debugger
      }
      var t = {
        ...timesheetInfo,
        status: status1,
        rfpId: rfpIdData.id,
        userId: getLoginUserData().userId,
        optionMade: actualOption,
      }
      var timeSheetData = {
        ...inputField,

        date: getCurrentDate(),
        rfpId: rfpIdData.id,
        optionMade: actualOption,
        area: rfpIdData.area,
        accountManager: rfpIdData.createdByName,
        showName: rfpIdData.leadid,
        designerTimesheetDetails: [t],
      }

      console.log(timeSheetData)
      debugger

      if (timeSheetData) {
        Service.createorupdate("DesignerTimesheet/CreateOrUpdate", timeSheetData).then((res) => {
          if (res.status === 200) {
            window.location.href = './UserSheetList';
          }
        })
      }
    }
    else {
      alert("something went wrong login again ");
    }
  }

  const backbutton = () => {
    props.navigate(-1);
  }
  return (
    <div>
      <div className="container-fluid">

        <div className="card shadow border-success mb-2">
          <div className="card-header rounded py-3">
            <div className="row">
              <div className="col-sm-7 row">
                <h3 className='mt-2'><b className='text-dark'>TimeSheet</b></h3>
              </div>
              <div className="col-sm-1"></div>
              <div className="col-sm-4 d-flex flex-row-reverse">
                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
              </div>

            </div>
          </div>
        </div>

        <div className="card shadow mb-4">
          <div className="card-header d-flex py-3">
            <h6 className="m-0 mt-1 font-weight-bold text-success ">Working TimeSheet</h6>
          </div>

          <form onSubmit={save}>
            <div className="card-body">
              <div className="row text-dark">
                <div className="col-sm-12 d-flex flex-wrap" style={{ overflowX: "auto" }}>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <div className='col-sm-12 required-label'><b>RFP</b></div>
                      <div className='col-sm-12'>
                        <select className="form-control  form-control-user" name="rfpId" onChange={handleInputRFP} required>
                          <option>[NONE]</option>
                          {
                            rfpList.map((e, key) => {
                              return <option key={e.id} value={e.id}>{e.leadid}</option>;
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <div className='col-sm-12 required-label'><b>Date</b></div>
                      <div className='col-sm-12'>
                        <input type="date" className='form-control  mb-1 form-control-user' readOnly min={getCurrentDate()} name='date' value={getCurrentDate()} onChange={inputTimesheetHandler} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <div className='col-sm-12 required-label'><b>Option Made</b></div>

                      <div className='col-sm-12'>
                        <select
                          disabled={readOnly}
                          className="form-control form-control-user"
                          name="optionMade"
                          required
                          value={calendarData.optionMade}
                          onChange={inputOptionHandler}
                        >
                          <option value="">[NONE]</option>
                          <option>Add Option</option>
                          {optionDropdown.map((e, index) => {
                            return <option key={e.id} value={e.optionMade}>{e.optionMade}</option>;
                          })}
                        </select>
                        {optionValue === 'Add Option' && (
                          <div className="mt-2">
                            <input
                              type="text"
                              className="form-control"
                              name="manualOption"
                              placeholder="Enter option value"
                              onChange={inputOptionHandler1}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group row">
                      <div className='col-sm-12 required-label'><b>Working hrs.</b></div>
                      <div className='col-sm-12'>
                        <select className="form-control  form-control-user" name="workingHours" onChange={inputTimesheetInfoHandler} required>
                          <option>[NONE]</option>
                          <option>2</option>
                          <option>4</option>
                          <option>6</option>
                          <option>8</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group row">
                      <div className='col-sm-12 required-label'><b>Start Date</b></div>
                      <div className='col-sm-12'>
                        <input type="date" className='form-control  mb-1 form-control-user' min={getCurrentDate()} name='startDate' onChange={inputTimesheetInfoHandler} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group row">
                      <div className='col-sm-12 required-label'><b>End Date</b></div>
                      <div className='col-sm-12'>
                        <input type="date" className='form-control  mb-1 form-control-user' min={getCurrentDate()} name='endDate' onChange={inputTimesheetInfoHandler} />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group row">
                      <div className='col-sm-12 required-label'><b>Priority</b></div>
                      <div className='col-sm-12'>
                        <select className="form-control  form-control-user" readOnly={readOnly} name="projectPriority" value={calendarData.projectPriority} onChange={inputTimesheetInfoHandler} required>
                          <option>[NONE]</option>
                          <option>Basic</option>
                          <option>Low</option>
                          <option>High</option>
                          <option>Very High</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group row">
                      <div className='col-sm-12 required-label'><b>Conversion Status</b></div>
                      <div className='col-sm-12'>
                        <select className="form-control  form-control-user" readOnly name="status" value={calendarData.status} onChange={inputTimesheetInfoHandler} required>
                          <option>[NONE]</option>
                          <option>Converted</option>
                          <option>Not Converted</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group row">
                      <div className='col-sm-12 required-label'><b>Option Type</b></div>
                      <div className='col-sm-12'>
                        <select className="form-control  form-control-user" readOnly={readOnly} name="optionType" value={calendarData.optionType} onChange={inputTimesheetInfoHandler} required>
                          <option>[NONE]</option>
                          <option>Custom</option>
                          <option>Be-Matrix</option>
                          <option>Custom & B.M.</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group row">
                      <div className='col-sm-12 required-label'><b>Client Type</b></div>
                      <div className='col-sm-12'>
                        <select className="form-control  form-control-user" readOnly={readOnly} name="clientType" value={calendarData.clientType} onChange={inputTimesheetInfoHandler} required>
                          <option>[NONE]</option>
                          <option>Old</option>
                          <option>New</option>
                          <option>Tendor</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group row">
                      <div className='col-sm-12 required-label'><b>Revision</b></div>
                      <div className='col-sm-12'>
                        <input type="text" className='form-control  mb-1 form-control-user' name='revisionMade' onChange={inputTimesheetInfoHandler} placeholder='revision number' />
                      </div>
                    </div>
                  </div>
                  {inputField.status === "" || inputField.status === null ? <div className="col-md-2">
                    <div className="form-group row">
                      <div className='col-sm-12 required-label'><b>Conversion Rate</b></div>
                      <div className='col-sm-12'>
                        <input type="text" className='form-control  mb-1 form-control-user' />
                      </div>
                    </div>
                  </div> : null}

                  <div className="col-md-2 mt-4">
                    <div className="form-group row">
                      <div className='col-sm-12'><button type='submit' className='btn btn-primary'>Save</button></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default UserSheetComponent