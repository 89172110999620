import { useState, useEffect } from "react";
import Service from "../../../Services/Admin/Service";
import { Link } from "react-router-dom";
import {
  getCurrentDate,
  getLoginUserData,
} from "../../../Helpers/supportedFunctions/supportedGlobalFunctions";
import AdminCalendarComponent from "../CalendarComponent/adminCalendarComponent";
import UserCalendarComponent from "../CalendarComponent/userCalendarComponent";
function DashboardComponent(props) {
  const [DashboardDetails, setDashboardDetails] = useState("");
  const [projectlist, setprojectlist] = useState([]);
  const [processprojectlist, setprocessprojectlist] = useState([]);
  const [processtasklist, setprocesstasklist] = useState([]);
  const [failedtasklist, setfailedtasklist] = useState([]);
  const [rfpData, setRfpData] = useState([]);
  const [failedshowtasklist, setfailedshowtasklist] = useState([]);
  const [failedleadtasklist, setfailedleadtasklist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userwiselist, setuserwiselist] = useState([]);
  const [allLead, setAllLead] = useState([]);
  const [userWiseLead, setUserWiseList] = useState([]);

  const staticName1 = "show";
  const staticName2 = "lead";
  const staticName3 = "project";
  let role = sessionStorage.getItem("role");
  let dept = sessionStorage.getItem("DeptId");
  //

  useEffect(() => {
    GetDashboardDetails(
      "Dashboard/GetDashboardDetails?userid=",
      getLoginUserData().userId
    );
    Getprojectlist(
      "Dashboard/GetAllProjectlist?userid=",
      getLoginUserData().userId
    );
    GetInprogressprojectlist(
      "Dashboard/GetAllProgressProjectlist?userid=",
      getLoginUserData().userId
    );
    GetInprogressTasklist(
      "Dashboard/GetAllInProgressTasklist?userid=",
      getLoginUserData().userId
    );
    GetfailedTasklist(
      "Dashboard/GetAllfailedDeadlineTasklist?userid=",
      getLoginUserData().userId
    );
    Getuserwiselist(
      "Dashboard/GetAllUserwiseTasklist?userid=",
      getLoginUserData().userId
    );
    getRFPData("RFP/GetAll?id=" + getLoginUserData().userId);
    if (getLoginUserData().userId === "1") {
      getAllLeadData("Lead/GetAllLead");
    }

    if (getLoginUserData().deptId === "1") {
      getUserWiseLeadData(
        "Lead/GetAll?userId=" +
          getLoginUserData().userId +
          "&dept=" +
          "Marketing"
      );
    } else {
      getUserWiseLeadData(
        "Lead/GetAll?userId=" + getLoginUserData().userId + "&dept=" + "Sales"
      );
    }
  }, []);

  const GetDashboardDetails = (method, id) => {
    Service.getdatabyId(method, id).then((res) => {
      setDashboardDetails(res.data);
      // console.log(res.data);
    });
  };

  const getAllLeadData = (method) => {
    Service.getdata(method).then((res) => {
      var filteredLead = res.data.filter(
        (x) => x.directLead === true && x.createRFP === true
      );
      setAllLead(filteredLead);
    });
  };

  const getUserWiseLeadData = (method) => {
    Service.getdata(method).then((res) => {
      setUserWiseList(res.data);
    });
  };

  function filterRFPWithUniqueIds(tasks) {
    // Create an object to store unique tasks based on their ID
    const uniqueTasks = tasks.reduce((acc, task) => {
      // Use the task ID as the key in the object
      const taskId = task.id;

      // If the task ID is not in the object, add it
      if (!acc[taskId]) {
        acc[taskId] = task;
      }

      return acc;
    }, {});

    // Convert the uniqueTasks object back to an array
    const uniqueTasksArray = Object.values(uniqueTasks);

    return uniqueTasksArray;
  }

  const getRFPData = (method) => {
    Service.getdata(method, "").then((res) => {
      var uniqueRFP = filterRFPWithUniqueIds(res.data);
      var progressrfp = uniqueRFP.filter((x) => x.dateTo >= getCurrentDate());
      debugger;
      setRfpData(progressrfp);
      console.log(res.data);
      //
    });
  };

  const Getprojectlist = (method, id) => {
    Service.getdatabyId(method, id).then((res) => {
      setprojectlist(res.data);
      // console.log(res.data);
    });
  };

  const GetInprogressprojectlist = (method, id) => {
    Service.getdatabyId(method, id).then((res) => {
      setprocessprojectlist(res.data);
      // console.log(res.data);
    });
  };

  const GetInprogressTasklist = (method, id) => {
    Service.getdatabyId(method, id).then((res) => {
      var data = res.data.filter((x) => x.projectId !== 0);
      setprocesstasklist(data);
      //  console.log(res.data);
    });
  };

  const GetfailedTasklist = (method, id) => {
    Service.getdatabyId(method, id).then((res) => {
      //;
      var data = res.data.filter(
        (x) =>
          (x.projectId !== 0 || x.projectId !== null) &&
          (x.showId === 0 || x.showId === null) &&
          (x.leadid === 0 || x.leadid === null)
      );
      setfailedtasklist(data);
      // if (res.data) {
      //     setLoading(true);
      // }
      // console.log(res.data);
      var showdata = res.data.filter(
        (x) =>
          (x.showId !== 0 || x.showId !== null) &&
          (x.leadid === 0 || x.leadid === null) &&
          (x.projectId === 0 || x.projectId === null)
      );
      setfailedshowtasklist(showdata);
      if (failedshowtasklist) {
        setLoading(true);
      }

      var leaddata1 = res.data.filter(
        (x) =>
          (x.leadid !== 0 || x.leadid !== null) &&
          (x.showId === 0 || x.showID === null) &&
          (x.projectId === 0 || x.projectId === null)
      );
      setfailedleadtasklist(leaddata1);
    });
  };

  const Getuserwiselist = (method, id) => {
    Service.getdatabyId(method, id).then((res) => {
      const task = filterTasksWithUniqueIds(res.data);
      setuserwiselist(task);

      console.log(res.data);
    });
  };

  function filterTasksWithUniqueIds(tasks) {
    // Create an object to store unique tasks based on their ID
    const uniqueTasks = tasks.reduce((acc, task) => {
      // Use the task ID as the key in the object
      const taskId = task.userid;

      // If the task ID is not in the object, add it
      if (!acc[taskId]) {
        acc[taskId] = task;
      }

      return acc;
    }, {});

    // Convert the uniqueTasks object back to an array
    const uniqueTasksArray = Object.values(uniqueTasks);

    return uniqueTasksArray;
  }

  return (
    <>
      <div className="container-fluid">
        {loading === false && (
          <div class="text-primary">
            <strong>Data Loading...</strong>
            <div
              class="spinner-border ml-auto"
              role="status"
              aria-hidden="true"
            ></div>
          </div>
        )}
        <div className="row flex-wrap ">
          {/* <div className="row justify-content-center align-items-center "> */}

          {/* Total Generated/Shared Lead start  Total Generated Lead Sales */}
          {(role === "Admin" ||
            role === "Managing Director(MD)" ||
            role === "Executive - Software Developer(SOP Tool)" ||
            role === "Assitant Manager - Process" ||
            dept === "3" ||
            dept === "2") && (
            <>
              <div className=" boxDashboard">
                {" "}
                {getLoginUserData().deptId === "2" ? (
                  <Link to={`/DashboardLead`} className="ml-4 mt-3">
                    <div className="myGeneratedLead">
                      <span className="h5 text-center ml-2  text-light counterPosition ">
                        <b>{userWiseLead.length}</b>
                      </span>
                    </div>
                  </Link>
                ) : (
                  <Link
                    to={`/DashboardLead/${"salesLead"}`}
                    className="ml-4 mt-3"
                  >
                    <div className="totalGeneratedLeadSales">
                      <span className="h5 text-center ml-2  text-light counterPosition ">
                        <b>{allLead.length}</b>
                      </span>
                    </div>
                  </Link>
                )}
              </div>
              <div className="boxDashboard ">
                {/* progress lead start */}
                <Link to="/RFPList" className="ml-4 mt-3">
                  <div className="progressLead ">
                    <span className="h5 text-center ml-2  text-light counterPosition ">
                      <b>{rfpData.length}</b>
                    </span>
                  </div>
                </Link>
              </div>
              {/* progress lead end */}
            </>
          )}
          {/* Total Generated/Shared Lead start  Total Generated Lead Sales */}

          {/* lead failed  */}
          {(role === "Admin" ||
            role === "Managing Director(MD)" ||
            role === "Executive - Software Developer(SOP Tool)" ||
            role === "Assitant Manager - Process" ||
            role === "Deputy General Manager - Creative Head Immersive" ||
            dept === "2") && (
            <div className=" boxDashboard">
              <Link
                to={`/DashboardFailedDeadline/${staticName2}`}
                className="ml-4 mt-3"
              >
                <div className="leadFailedlogo ">
                  <span className="h5 text-center ml-2  text-light counterPosition ">
                    <b>
                      {" "}
                      {failedleadtasklist.length !== null &&
                      failedleadtasklist.length !== 0
                        ? failedleadtasklist.length
                        : "0"}
                    </b>
                  </span>
                </div>
              </Link>
            </div>
          )}

          {/* project  */}
          {(role === "Admin" ||
            role === "Managing Director(MD)" ||
            role === "Executive - Software Developer(SOP Tool)" ||
            role === "Assitant Manager - Process" ||
            dept === "4") && (
            <>
              {/* total project start */}

              <div className=" boxDashboard">
                <Link to="/ProjectList" className="ml-4 mt-3">
                  <div className="totalProjectlogo">
                    <span className="h5 text-center ml-2  text-light counterPosition ">
                      <b>{DashboardDetails.totalProject}</b>
                    </span>
                  </div>
                </Link>
              </div>
              {/* total project end */}

              {/* progress project start */}

              <div className="boxDashboard">
                <Link to="/TotalProjectDashboard" className="ml-4 mt-3">
                  <div className="progressProjectlogo ">
                    <span className="h5 text-center ml-2  text-light counterPosition ">
                      <b>{DashboardDetails.progressProject}</b>
                    </span>
                  </div>
                </Link>
              </div>
              {/* progress project end */}
            </>
          )}

          {/* project failed */}
          {(role === "Admin" ||
            role === "Managing Director(MD)" ||
            role === "Executive - Software Developer(SOP Tool)" ||
            role === "Assitant Manager - Process" ||
            role === "DGM-Projects") && (
            <div className="boxDashboard">
              <Link
                to={`/DashboardFailedDeadline/${staticName3}`}
                className="ml-4 mt-3"
              >
                <div className="projectFailedDeadline ">
                  <span className="h5 text-center ml-2  text-light counterPosition ">
                    <b>
                      {failedtasklist.length !== null &&
                      failedtasklist.length !== 0
                        ? failedtasklist.length
                        : "0"}
                    </b>
                  </span>
                </div>
              </Link>
            </div>
          )}

          {/* completed projects start */}
          {(role === "Admin" ||
            role === "Managing Director(MD)" ||
            role === "Executive - Software Developer(SOP Tool)" ||
            role === "Assitant Manager - Process" ||
            dept === "4") && (
            <div className="boxDashboard">
              <Link to="/ProjectList/CompletedProjects" className="ml-4 mt-3">
                <div className="completedprojectlogo ">
                  <span className="h5 text-center ml-2  text-light counterPosition ">
                    <b>{DashboardDetails.finishedProject}</b>
                  </span>
                </div>
              </Link>
            </div>
          )}

          {/* completed projects end */}

          {/* project end  */}

          {/* show start */}

          {/* isShowConverted start */}

          {/* {(role === "Admin" || role === "Managing Director(MD)" || role === "Executive - Software Developer(SOP Tool)" || role === "Assitant Manager - Process" || dept === "1") &&
                    <>
                        <Link to={`/DashboardLead`} className="ml-4 mt-3">
                            <div className="isShowCoverted">
                                <span className="h5 text-center ml-2  text-light counterPosition "><b>{userWiseLead.length}</b></span>
                            </div>
                        </Link>
                    </>
                } */}

          {/* isShowConverted end */}
          {/* show End  */}

          {/* lead start */}
          {(role === "Admin" ||
            role === "Managing Director(MD)" ||
            role === "Executive - Software Developer(SOP Tool)" ||
            role === "Assitant Manager - Process" ||
            dept === "3" ||
            dept === "2") && (
            <>
              {/* isCovertedLead start */}

              {/* <div className="col-xl-4 col-md-8 mb-2">
                        <div className="card border-left-info shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-lg font-weight-bold text-info text-uppercase mb-1">Is Converted Lead
                                        </div>
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-auto">
                                                <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">{ }</div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div> */}
            </>
          )}

          {/* isConvertedLead end */}

          {/* lead End  */}

          {/* show failed */}

          {(role === "Admin" ||
            role === "Managing Director(MD)" ||
            role === "Executive - Software Developer(SOP Tool)" ||
            role === "Assitant Manager - Process" ||
            role === "Head of Marketing - North America" ||
            role === "Marketing Manager") && (
            <>
              {/* show failed deadline start */}

              {/* <Link to={`/DashboardFailedDeadline/${staticName1}`} className="ml-4 mt-3">
                            <div className="showFailedDeadline ">
                                <span className="h5 text-center ml-2  text-light counterPosition "><b>{failedshowtasklist.length !== null && failedshowtasklist.length !== 0
                                    ? failedshowtasklist.length
                                    : '0'}</b></span>
                            </div>
                        </Link> */}

              {/* show failed deadline end  */}
            </>
          )}

          {/* department wise employees task */}
          {/* department total task start */}
          {(role === "DGM-Projects" ||
            role === "Deputy General Manager - Creative Head Immersive" ||
            role === "Head of Marketing - North America" ||
            role === "Marketing Manager" ||
            dept === "2") && (
            <div className="boxDashboard">
              <Link to="/EmployeesTaskDetails" className="ml-4 mt-3">
                <div className="departmentTotalTask ">
                  <span className=""></span>
                </div>
              </Link>
            </div>
          )}
          {/* dpartment total task end */}
          {/* department wise employees task */}

          {/* </div>

            <div className="row  "> */}
          {/* Employees my and total task start */}
          {role === "Admin" ||
          role === "Managing Director(MD)" ||
          role === "Executive - Software Developer(SOP Tool)" ||
          role === "Assitant Manager - Process" ? (
            <div className="boxDashboard">
              <Link to="/EmployeesTaskDetails" className="ml-4 mt-3">
                <div className="employeesTotaTask ">
                  <span className="h5 text-center ml-2  text-light counterPosition ">
                    <b>{DashboardDetails.totalTask}</b>
                  </span>
                </div>
              </Link>
            </div>
          ) : (
            <div className="boxDashboard">
              <Link to="/KanbanMyTask" className="ml-4 mt-3">
                <div className="myTask ">
                  <span className="h5 text-center ml-2  text-light counterPosition ">
                    <b>{DashboardDetails.totalTask}</b>
                  </span>
                </div>
              </Link>
            </div>
          )}
          {/* Employees my and total task end */}

          {role !== "Admin" && (
            <>
              {
                role === "Admin" || role === "Managing Director(MD)" ? (
                  //progress task start
                  <div className="boxDashboard">
                    <Link to="/EmployeesPogressTask">
                      <div className="myProgressTask">
                        <span className="h5 text-center ml-2  text-light counterPosition ">
                          <b>{DashboardDetails.inProgressTask}</b>
                        </span>
                      </div>
                    </Link>
                  </div>
                ) : (
                  //progress task end
                  // my progress task start
                  <div className="boxDashboard">
                    <Link to="/KanbanMyTask" className="ml-4 mt-3">
                      <div className="myProgressTask">
                        <span className="h5 text-center ml-2  text-light counterPosition ">
                          <b>{DashboardDetails.inProgressTask}</b>
                        </span>
                      </div>
                    </Link>
                  </div>
                )

                // my progress task end
              }

              {/* employees completed  task start */}
              {/* my completed  task start */}

              {role === "Admin" || role === "Managing Director(MD)" ? (
                <div className="boxDashboard">
                  <Link to="/EmployeesClosedTask" className="ml-4 mt-3">
                    <div className="employeesCompletedTask ">
                      <span className="h5 text-center ml-2  text-light counterPosition ">
                        <b>{DashboardDetails.completedTask}</b>
                      </span>
                    </div>
                  </Link>
                </div>
              ) : (
                <div className="boxDashboard">
                  <Link to="/KanbanMyArchievedTask" className="ml-4 mt-3">
                    <div className="myCompletedTask ">
                      <span className="h5 text-center ml-2  text-light counterPosition ">
                        <b>{DashboardDetails.completedTask}</b>
                      </span>
                    </div>
                  </Link>
                </div>
              )}
            </>
          )}
          {/* employees completed  task end */}
          {/* my completed  task end */}

          {/* userTaskDetails start */}
          {(role === "Admin" || role === "Managing Director(MD)") && (
            <div className="boxDashboard">
              <Link to="/DashboardUserTask" className="ml-4 mt-3">
                <div className="usertaskDetails">
                  <div className="col-auto">
                    <span className=""></span>
                  </div>
                </div>
              </Link>
            </div>
          )}
          {/* userTaskDetails end */}

          {/*============================ user calendar start ================================ */}

          {/* assigned calendar start */}
          <div className="boxDashboard">
            <Link to="/UserCalendar/assigned" className="ml-4 mt-3">
              <div className="assignedTaskCalendar">
                <div className="col-auto">
                  {/* <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">{userwiselist.length}</div> */}
                </div>
              </div>
            </Link>
          </div>

          {(getLoginUserData().userId === "1" ||
            getLoginUserData().userId === "3") && (
            <div className="boxDashboard">
              <Link to="/UserCalendar/myTask" className="ml-4 mt-3">
                <div className="myTaskCalendar">
                  <div className="col-auto">
                    {/* <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">{userwiselist.length}</div> */}
                  </div>
                </div>
              </Link>
            </div>
          )}
          {/* assigned calendar end */}

          {getLoginUserData().userId !== "1" &&
            getLoginUserData().userId !== "3" && (
              <div className="col-sm-12">
                <UserCalendarComponent props={"/myTask"} />
              </div>
            )}
          {/*============================ user calendar start ================================ */}

          {/* =====================admin centralised calenadar start======================= */}

          {(getLoginUserData().userId === "1" ||
            getLoginUserData().userId === "3") && (
            <div className="col-sm-12">
              <AdminCalendarComponent />
            </div>
          )}
          {/*================== admin centralised calenadar end================= */}
        </div>
      </div>
    </>
  );
}
export default DashboardComponent;
