import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import DetailsProjectComponent from '../../../Components/Main/ProjectComponent/DetailsProjectComponent';

function DetailProject(props) {
    
    const params = useParams();
   
    let navigate = useNavigate();
    return (  
        <div> 
            <DetailsProjectComponent {...props} navigate={navigate} id={params.id} />
        </div>
    );  
  }
  
export default DetailProject  