import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Service from '../../../Services/Admin/Service';

const SelectVendorDetailsComponent = (props) => {
  const userId = sessionStorage.getItem("id");
  const id = props.id;

  const [data, setData] = useState([]);
  const [apiError, setApiError] = useState(null);
  const [isQuotaionShared, setIsQuotaionShared] = useState(false);
  const [isAgreementShared, setIsAgreementShared] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [inputField, setInputField] = useState({
    id: id,
    vendorid: 0,
    supplierName: "",
    leadid: "",
    projectid: 0,
    cost: "",
    isQuaotationShared: false,
    shareddate: "",
    isAgreementShared: false,
    agreementSharedDate: "",
    updatedby: 0,
    createdby: 0
  })


  useEffect(() => {

    getSelectVendorDerails("SelectVendor/GetById?id=" + id);

  }, [])

  const getSelectVendorDerails = (method) => {
    Service.getdata(method).then((res) => {
      setData(res.data);
      setInputField(res.data);
      if(res.data.isQuaotationShared === true){

        setIsQuotaionShared("Yes Shared");
      }
      else {
        setIsQuotaionShared(false);
      }
      if(res.data.isAgreementShared === true){

        setIsAgreementShared("Yes Shared");
      }
      else {
        setIsAgreementShared(false);
      }
      debugger
    });
  };


  useEffect(() => {
    if (apiResponse || apiError) {
      const timeoutId = setTimeout(removeSuccessMessage, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [apiResponse, apiError]);


  const removeSuccessMessage = () => {
    setApiError(null);
    setApiResponse(null);
  };


  const handlesetIsQuotaionChange = (evt) => {
    setIsQuotaionShared(evt.target.value);
    debugger
  }

  const handlesetAgreementChange = (evt) => {
    setIsAgreementShared(evt.target.value);
    debugger
  }

  const save = async (evt) => {

    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }

    const formData = new FormData(evt.target);

    const data = {}
    for (const [key, value] of formData) {
      data[key] = value;
    }

    const { isQuaotationShared } = data;

    if (data.isQuaotationShared === "on" ) {

      inputField.isQuaotationShared = true;
    }
    if(data.isAgreementShared === "on"){
      inputField.isAgreementShared = true;
    }
    const task = {
      ...data,
      ...inputField,

    };

    console.log(task)
    debugger
    if (task) {
      Service.createorupdate("SelectVendor/CreateOrUpdate", task)
        .then(res => {
          setApiResponse(res.data); // Set the API response in state
          window.location.reload(true);

        })
        .catch(error => {
          setApiError(error);
          // Handle error if API call fails
          console.error("API Error:", error);
        });
    }


  }

  const backbutton = () => {
    props.navigate(-1);
  }
  const formatDateTime = (cell, row) => {
    
    if (cell === null) {
      return 'N/A'; // Or any other placeholder for null dates
    }
    const formattedDate = new Date(cell).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',


    });
    return formattedDate;
  }

  return (
    <div>
      <div className="container-fluid">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <div className="row">
              <div class="d-flex flex-start align-items-center">

              </div>
              <div className="col-sm-5"><h2>Select Vendor Details</h2></div>
              <div className="col-sm-5"></div>
              <div className="col-sm-2 d-flex flex-row-reverse">
                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="card cardUser shadow mb-4" >
          <div className="card-header py-3">
            {apiResponse && (
              <div className="alert alert-success" role="alert">
                Select Vendor has been updated successfully!
              </div>
            )}
            {apiError && (
              <div className="alert alert-danger" role="alert">
                {apiError}
              </div>
            )}
            <h6 className="m-0 font-weight-bold text-primary">Select Vendor</h6>
          </div>

          <div class="container">
            <form onSubmit={save} class="form-horizontal mt-2">
              <div class="form-group row">
                <label for="supplier" class="col-sm-3 required-label  control-label">Supplier Name</label>
                <div class="col-sm-9">
                  <b>{data.supplierName}</b>
                </div>
              </div>
              <div class="form-group row">
                <label for="firstName" class="col-sm-3 required-label  control-label">Project</label>
                <div class="col-sm-9">
                  <b>{data.supplierName}</b>
                </div>
              </div>
              <div class="form-group row">
                <label for="email" class="col-sm-3  control-label">Cost </label>
                <div class="col-sm-9">
                  <b>{data.cost}</b>
                </div>
              </div>



              <div class="form-group row">
                <label for="supplier" class="col-sm-3 required-label  control-label">Cost Share Date</label>
                <div class="col-sm-9">
                  <b>{formatDateTime(data.shareddate)}</b>
                </div>
              </div>

              <div class="form-group row">
                <label for="supplier" class="col-sm-3 required-label  control-label">Agreement Share Date</label>
                <div class="col-sm-9">
                  <b>{formatDateTime(data.agreementSharedDate)}</b>
                </div>
              </div>

              <div class="form-group row">
                <label htmlFor="note" className='mt-1 ml-3'>Note:</label>
                <h6 className='mt-1 ml-3'> dates are automatically filed when you tick the checkbox</h6>
              </div>

              <div class="form-group">
                  <input class="" type="checkbox" checked={isQuotaionShared} onChange={handlesetIsQuotaionChange}  name='isQuaotationShared' id="" />
                  <label class="col-sm-3 required-label  control-label" for="flexCheckDefault">
                    Is Costing Shared
                  </label>
                  <b>{isQuotaionShared}</b>

              </div>

              <div class="form-group ">
                  <input class="" type="checkbox" checked={isAgreementShared} onChange={handlesetAgreementChange}  name='isAgreementShared' id="agreementCheckbox" />
                  <label class="col-sm-3 required-label  control-label" for="flexCheckDefault">
                    Is Agreement Shared
                  </label>
                  <b>{isAgreementShared}</b>
              </div>
              <button type="submit" class="btn btn-primary ml-3 mb-3">Save</button>

            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectVendorDetailsComponent