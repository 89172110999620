import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { filterWithUniqueIds, formatDate, getLoginUserData } from '../../../Helpers/supportedFunctions/supportedGlobalFunctions'
import Service from '../../../Services/Admin/Service';


const EditUserSheetComponent = (props) => {

  const [rfpList, setRFPList] = useState([]);
  const [rfpIdData, setRfpIdData] = useState({});
  const [rfpId, setRFpId] = useState(null);
  const [optionDropdown, setOptionDropdown] = useState([]);
  const [readOnly, setReadOnly] = useState(false);
  const [optionValue, setOptionValue] = useState('');
  const [optionValue1, setOptionValue1] = useState('');
  const [inputField, setInputfield] = useState({
    id:props.id,
    rfpId: 0,
    userId: getLoginUserData().userId,
    date: null,
    userName: getLoginUserData().name,
    accountManager: "",
    showName: "",
    area: "",
    optionMade: "",
    createdby: getLoginUserData().userId,
    updatedby: getLoginUserData().userId,
  })
  const [timesheetInfo, setTimesheetInfo] = useState(
   [ {
      rfpId: 0,
      userId: getLoginUserData().userId,
      optionMade: "",
      startDate: null,
      completionDate: null,
      endDate: null,
      workingHours: "",
      status: "",
      revisionMade: ""
    }]
  );

  useEffect(() => {
    getRFPData("RFP/GetAll?id=" + getLoginUserData().userId);
    getSheetDatabyId("DesignerTimesheet/GetById?id=" + props.id);
    if (props.name === "edit") {
      setReadOnly(true);
    }
  }, [])

  const getRFPData = (method) => {
    Service.getdata(method).then((res) => {
      var uniqueRFP = filterWithUniqueIds(res.data);
      setRFPList(uniqueRFP.reverse());
    })
  }

  const getSheetDatabyId = (method) => {
    Service.getdata(method).then((res) => {
      setInputfield(res.data);
      debugger
      setTimesheetInfo(res.data.designerTimesheetDetails);
      setOptionValue(res.data.optionMade)
      getOptionData("DesignerTimesheet/GetAllbyRFPId?id=" + res.data.rfpId);
      setRFpId(res.data.rfpId);
    })
  }

  const getOptionData = (method) => {
    Service.getdata(method).then((res) => {
      setOptionDropdown(res.data);
      debugger
    })
  }

  const inputOptionHandler = (event) => {
    const selectedOption = event.target.value;
    setOptionValue(selectedOption);
  };



  const inputTimesheetHandler = (e) => {

    const { name, value } = e.target;
    setInputfield((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const inputTimesheetInfoHandler = (index, evnt) => {

    const { name, value } = evnt.target;
    const list = [...timesheetInfo];
    list[index][name] = value;
    setTimesheetInfo(list);
  }

  const handleInputRFP = (evt) => {
    var RFPId = rfpList.filter((x) => x.id === parseInt(evt.target.value));
    if (RFPId.length !== 0) {
      setRfpIdData(RFPId[0]);
      getOptionData("DesignerTimesheet/GetAllbyRFPId?id=" + RFPId[0].id);
    }
  }

  const save = (evt) => {
    evt.preventDefault();
    if (getLoginUserData().userId !== null || getLoginUserData().userId !== 0) {

      if (optionValue === "Add Option") {
        var actualOption = optionValue1;
      }
      else {
        actualOption = optionValue;
      }
      var t = {
        ...timesheetInfo,
        rfpId: rfpIdData.id,
        userId: getLoginUserData().userId,
        optionMade: actualOption,
      }

      var timeSheetData = {
        ...inputField,
        optionMade: actualOption,
        designerTimesheetDetails: timesheetInfo,
      }

      console.log(timeSheetData)
      debugger

      if (timeSheetData) {
        Service.createorupdate("DesignerTimesheet/CreateOrUpdate", timeSheetData).then((res) => {
          if (res.status === 200) {
            window.location.href = '/UserSheetList';
          }
        })
      }
    }
    else {
      alert("something went wrong login again ");
    }

  }

  const backbutton = () => {
    props.navigate(-1);
  }
  return (
    <div>
      <div className="container-fluid">

        <div className="card shadow border-success mb-2">
          <div className="card-header rounded py-3">
            <div className="row">
              <div className="col-sm-7 row">
                <h3 className='mt-2'><b className='text-dark'>TimeSheet Details</b></h3>
              </div>
              <div className="col-sm-1"></div>
              <div className="col-sm-4 d-flex flex-row-reverse">
                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
              </div>

            </div>
          </div>
        </div>

        <div className="card shadow mb-4">
          <div className="card-header d-flex py-3">
            <h6 className="m-0 mt-1 font-weight-bold text-success ">MasterSheet Details</h6>
          </div>

          <form onSubmit={save}>
            <div className="card-body">
              <div className="row text-dark">
                <div className="col-sm-12 d-flex flex-wrap" style={{ overflowX: "auto" }}>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <div className='col-sm-12 required-label'><b>RFP</b></div>
                      <div className='col-sm-12'>
                        <select className="form-control  form-control-user" name="rfpId" disabled={readOnly} value={inputField.rfpId} onChange={handleInputRFP} required>
                          <option>[NONE]</option>
                          {
                            rfpList.map((e, key) => {
                              return <option key={e.id} value={e.id}>{e.leadid}</option>;
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <div className='col-sm-12 required-label'><b>Date</b></div>
                      <div className='col-sm-12'>
                        <input type="date" className='form-control  mb-1 form-control-user' readOnly={readOnly} name='date' value={formatDate(inputField.date)} onChange={inputTimesheetHandler} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group row">
                      <div className='col-sm-12 required-label'><b>Option Made</b></div>

                      <div className='col-sm-12'>
                        <select
                          disabled={readOnly}
                          className="form-control form-control-user"
                          name="optionMade"
                          required
                          onChange={inputOptionHandler}
                          value={optionValue}
                        >
                          <option value="">[NONE]</option>
                          <option>Add Option</option>
                          {optionDropdown.map((e, index) => {
                            return <option key={e.id} value={e.optionMade}>{e.optionMade}</option>;
                          })}
                        </select>

                      </div>
                    </div>
                  </div>
                  {timesheetInfo.map((data,index) => {
                     const { workingHours, startDate, endDate, status, revisionMade, completionDate } = data;
                    return (
                      <div className='row' key={index}>
                        <div className="col-md-2">
                          <div className="form-group row">
                            <div className='col-sm-12 required-label'><b>Working hrs.</b></div>
                            <div className='col-sm-12'>
                              <select className="form-control  form-control-user" name="workingHours" readOnly={readOnly} value={workingHours} onChange={(evnt) => inputTimesheetInfoHandler( index, evnt)} required>
                                <option>[NONE]</option>
                                <option>2</option>
                                <option>4</option>
                                <option>6</option>
                                <option>8</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group row">
                            <div className='col-sm-12 required-label'><b>Start Date</b></div>
                            <div className='col-sm-12'>
                              <input type="date" className='form-control  mb-1 form-control-user' readOnly={readOnly} value={formatDate(startDate)} name='startDate' onChange={(evnt) => inputTimesheetInfoHandler( index, evnt)} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group row">
                            <div className='col-sm-12 required-label'><b>End Date</b></div>
                            <div className='col-sm-12'>
                              <input type="date" className='form-control  mb-1 form-control-user' readOnly={readOnly} name='endDate' value={formatDate(endDate)} onChange={(evnt) => inputTimesheetInfoHandler( index, evnt)} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group row">
                            <div className='col-sm-12 required-label'><b>Completion Date</b></div>
                            <div className='col-sm-12'>
                              <input type="date" className='form-control  mb-1 form-control-user' name='completionDate' value={formatDate(completionDate)} onChange={(evnt) => inputTimesheetInfoHandler( index, evnt)} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group row">
                            <div className='col-sm-12 required-label'><b>Conversion Status</b></div>
                            <div className='col-sm-12'>
                              <select className="form-control  form-control-user" name="status" value={status} onChange={(evnt) => inputTimesheetInfoHandler( index, evnt)} required>
                                <option>[NONE]</option>
                                <option>Converted</option>
                                <option>Not Converted</option>

                              </select>

                            </div>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group row">
                            <div className='col-sm-12 required-label'><b>Revision</b></div>
                            <div className='col-sm-12'>
                              <input type="text" className='form-control  mb-1 form-control-user' name='revisionMade' readOnly={readOnly} value={revisionMade} onChange={(evnt) => inputTimesheetInfoHandler( index, evnt)} placeholder='revision number' />
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                  {inputField.status === "" || inputField.status === null ? <div className="col-md-2">
                    <div className="form-group row">
                      <div className='col-sm-12 required-label'><b>Conversion Rate</b></div>
                      <div className='col-sm-12'>
                        <input type="text" className='form-control  mb-1 form-control-user' />
                      </div>
                    </div>
                  </div> : null}

                  <div className="col-md-2">
                    <div className="form-group row">
                      <div className='col-sm-12'><button type='submit' className='btn btn-primary'>Save</button></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}


export default EditUserSheetComponent