import React, { useEffect, useState } from 'react'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Service from '../../../Services/Admin/Service';
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { Link } from 'react-router-dom';

const CreateProjectClosureFeedbackComponent = (props) => {


  let id = sessionStorage.getItem('id');
  const [projectError, setProjectError] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [detailFlag, setDetailFlag] = useState(false);
  const [projectData, setProjectData] = useState([]);
  const [date, setDate] = useState([]);
  const [summary, setsummary] = useState('');
  const [ratetheProject, setratetheProject] = useState('');
  const [comments1, setcomments1] = useState('');
  const [ratetheVendor, setratetheVendor] = useState('');
  const [comments2, setcomments2] = useState('');
  const [ratetheClient, setratetheClient] = useState('');
  const [comments3, setcomments3] = useState('');
  const [ratetheClientVirtualExperience, setratetheClientVirtualExperience] = useState('');
  const [comments4, setcomments4] = useState('');
  const [projectid, setprojectid] = useState('');
  const [ratetheExecution, setratetheExecution] = useState('');
  const [comments5, setcomments5] = useState('');
  const [projectWentoffWell, setprojectWentoffWell] = useState('');
  const [projectDidnotGoWell, setprojectDidnotGoWell] = useState('');
  const [losethisaccount, setlosethisaccount] = useState('');
  const [suggestions, setsuggestions] = useState('');
  const [projectClosureData, setProjectClosureData] = useState([]);
  const [customerFeedback, setcustomerFeedback] = useState('');
  const [feedbackProvidedby, setfeedbackProvidedby] = useState('');
  const [projectId, setProjectId] = useState([]);
  const [inputField, setInputFields] = useState({
    id: props.id,
    projectId: '',
    userid: id,
    summary: "",
    ratetheProject: "",
    comments1: "",
    ratetheVendor: "",
    comments2: "",
    ratetheClient: "",
    comments3: "",
    ratetheClientVirtualExperience: "",
    comments4: "",
    ratetheExecution: "",
    comments5: "",
    projectWentoffWell: "",
    projectDidnotGoWell: "",
    losethisaccount: "",
    suggestions: "",
    customerFeedback: "",
    feedbackProvidedby: "",
    createdBy: id,
    updatedBy: id,
  })



  useEffect(() => {
    getProjectData("Project/GetAll");
    var d = props.name;
    if (d === "details") {
      setDetailFlag(true);
    }
    if (props.name !== 0 && props.name !== undefined) {

      getProjectClosureFeedbackbyId("Feedback/GetByIDProjectClosure?id=" + props.id, '');

    }
    const currentDateObj = new Date();
    const day = String(currentDateObj.getDate()).padStart(2, '0');
    const month = String(currentDateObj.getMonth() + 1).padStart(2, '0');
    const year = currentDateObj.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;

    // Update the state variable with the formatted date
    setDate(formattedDate);

  }, [])

  const getProjectData = (method) => {
    Service.getdata(method).then((res) => {
      setProjectData(res.data);

    });
  }
  const getProjectClosureFeedbackbyId = (method) => {
    Service.getdata(method).then((res) => {
      setProjectClosureData(res.data);
      console.log(res.data)
      debugger
      setsummary(res.data.summary)
      setratetheProject(res.data.ratetheProject)
      setcomments1(res.data.comments1)
      setratetheVendor(res.data.ratetheVendor)
      setcomments2(res.data.comments2)
      setratetheClient(res.data.ratetheClient)
      setcomments3(res.data.comments3)
      setratetheClientVirtualExperience(res.data.ratetheClientVirtualExperience)
      setcomments4(res.data.comments4)
      // setExperience(res.data.experience)
      setratetheExecution(res.data.ratetheExecution)
      debugger
      setcomments5(res.data.comments5)
      setprojectWentoffWell(res.data.projectWentoffWell)
      setprojectDidnotGoWell(res.data.projectDidnotGoWell)
      setlosethisaccount(res.data.losethisaccount)
      setprojectid(res.data.projectId)
      setsuggestions(res.data.suggestions)
      setcustomerFeedback(res.data.customerFeedback)
      setfeedbackProvidedby(res.data.feedbackProvidedby)
    });
  }
  useEffect(() => {
    if (apiResponse || apiError) {
      const timeoutId = setTimeout(removeSuccessMessage, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [apiResponse, apiError]);

  const removeSuccessMessage = () => {
    setApiResponse(null);
    setApiError(null);
  };

  const handleSummaryChange = (evt) => {
    setsummary(evt.target.value);
  }

  const handleRatetheProjectChange = (evt) => {
    setratetheProject(evt.target.value);
  }

  const handleComent1Change = (evt) => {
    setcomments1(evt.target.value);
  }

  const handleComment2Change = (evt) => {
    setcomments2(evt.target.value);
  }
  const handleComment3Change = (evt) => {
    setcomments3(evt.target.value);
  }
  const handleComment4Change = (evt) => {
    setcomments4(evt.target.value);
  }
  const handleComment5Change = (evt) => {
    setcomments5(evt.target.value);
  }

  const handleRatetheVendorChange = (evt) => {
    setratetheVendor(evt.target.value);
  }
  const handleRatetheClientChange = (evt) => {
    setratetheClient(evt.target.value);
  }

  const handleProjectWentoffWellChange = (evt) => {
    setprojectWentoffWell(evt.target.value);
  }

  const handleRatetheClientVirtualExperienceChange = (evt) => {
    setratetheClientVirtualExperience(evt.target.value);
  }

  // const handleCommentsChange = (evt) => {
  //   setComments(evt.target.value);
  // }

  const handleRatetheExecutionChange = (evt) => {
    setratetheExecution(evt.target.value);
  }

  // const handleOverallSatisficationChange = (evt) => {
  //   setOverallSatisfication(evt.target.value);
  // }
  // const handleProjectWentoffWellChange = (evt) => {
  //   setprojectWentoffWell(evt.target.value);
  // }

  const handleProjectChange = (evt) => {
    setprojectid(evt.target.value);
  }

  const handleProjectDidnotGoWellChange = (evt) => {
    setprojectDidnotGoWell(evt.target.value);
  }
  const handleLosethisaccountChange = (evt) => {
    setlosethisaccount(evt.target.value);
  }
  const handleSuggestionsChange = (evt) => {
    setsuggestions(evt.target.value);
  }
  const handleCustomerFeedbackChange = (evt) => {
    setcustomerFeedback(evt.target.value);
  }

  const handleFeedbackProvidedbyChange = (evt) => {
    setfeedbackProvidedby(evt.target.value);
  }


  const save = async (evt) => {

    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }
    const formData = new FormData(evt.target);

    const data = {}
    for (const [key, value] of formData) {
      data[key] = value;
    }

    function validateData(data) {

      const { projectId } = data;


      if (!projectId || projectId === "[NONE]") {
        setProjectError(true);
        return false;
      } else {
        setProjectError(false);
      }

      return true;
    }

    if (!validateData(data)) {
      return;
    }

    const task = {
      ...inputField,
      ...data,
    };
    console.log(task);
    debugger

    if (task) {
      Service.createorupdate("Feedback/CreateOrUpdateProjectClosure", task)
        .then(res => {
          setApiResponse(res.data); // Set the API response in state
          window.location.href = "/ProjectClosureFeedbackList";
        })
        .catch(error => {
          // Handle error if API call fails
          console.error("API Error:", error);
          setApiError("Error occurred while saving the feedback. Please try again later.");
        });
    }

  }

  const backbutton = () => {
    props.navigate(-1);
  }
  return (
    <div>
      <div className="container-fluid">
        <br />
        <div className="card shadow border-success mb-2">
          <div className="card-header py-3">
            <div className="row">
              <div className="col-sm-6 mt-1"><h3><b className='text-dark'>Project Closure Feedback</b></h3></div>
              <div className="col-sm-1"></div>
              <div className="col-sm-5 d-flex flex-row-reverse">
                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link>
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={save}>
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-success">Closure Feedback</h6>
              {apiResponse && (
                <div className="alert alert-success mt-2" role="alert">
                  feedback has been submitted successfully!
                </div>
              )}
              {apiError && (
                <div className="alert alert-danger" role="alert">
                  {apiError}
                </div>
              )}
            </div>
            <div className="card-body" style={{ height: "auto" }}>
              <div className='row text-dark'>
                <div className='col-md-5 ml-5'>
                  {/* <div className="form-group row">
                    <div className='col-sm-12 -label'>Project Start Date</div>
                    <div className='col-sm-12'><input type="Date" className="form-control form-control-user" id="headline"
                      name="date" /></div>
                  </div> */}

                  <div className="form-group row">
                    <div className='col-sm-12'><b className="text-dark">Project</b></div>
                    <div className='col-sm-12'>
                      {projectError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a Project.</div>}
                      <select className="form-control  form-control-user" readOnly={detailFlag} name="projectId" value={projectid} onChange={handleProjectChange}>
                        <option>[NONE]</option>
                        {
                          projectData.map((e, key) => {
                            //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;
                            return <option key={e.id} value={e.id}>{e.projectname}</option>;
                          })}
                      </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-12 '><b>Project summary:</b></div>
                    <div className='col-sm-12'><textarea
                      rows={4} // You can adjust the number of visible rows
                      cols={100}
                      name='summary'
                      readOnly={detailFlag}
                      onChange={handleSummaryChange}
                      value={summary}
                      placeholder="enter project summary" // You can adjust the number of visible columns
                      class="form-control"
                      autofocus
                    /></div>
                  </div>
                </div>
                <div className='col-md-1'></div>
                <div className='col-md-5'>

                  {/* <div className="form-group row ">
                    <div className='col-sm-12 -label'>Project End Date</div>
                    <div className='col-sm-12'><input type="Date" className="form-control form-control-user" id="headline"
                      name="date" /></div>
                  </div> */}
                  <div className="form-group row">
                    <div className='col-sm-12 -label'><b>Project Manager Name</b></div>
                    <div className='col-sm-12'>
                      {/* <input type="text" className="form-control form-control-user" id="headline"
                        name="signature" placeholder="enter project manager name" /> */}
                      <b>
                        Sushil Bhatt
                      </b>
                    </div>
                  </div>
                  <div className="form-group row ">
                    <div className='col-sm-12 -label'> <b>Date</b></div>
                    <div className='col-sm-12'><b>{date}</b></div>
                  </div>
                </div>


              </div>
              <div className='col-md-11 text-dark ml-4'>
                <h4><b>Questionare</b></h4>
                <div className="form-group row">
                  <div className='col-sm-12 -label'><b>How would you rate the project on an overall basis on the basis of design, execution, client satisfaction, etc?</b></div>
                  <div className='col-sm-12'>
                    <select className="form-control  form-control-user" readOnly={detailFlag} name="ratetheProject" value={ratetheProject} onChange={handleRatetheProjectChange}>
                      <option>[ Please select rating ]</option>
                      <option>1 star</option>
                      <option>2 star</option>
                      <option>3 star</option>
                      <option>4 star</option>
                      <option>5 star</option>
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <div className='col-sm-12'><b>comments</b></div>
                  <div className='col-sm-12'><textarea
                    rows={2} // You can adjust the number of visible rows
                    cols={100}
                    value={comments1}
                    onChange={handleComent1Change}
                    readOnly={detailFlag}
                    name='comments1'
                    placeholder="enter comments" // You can adjust the number of visible columns
                    class="form-control"
                    autofocus
                  /></div>
                </div>

                <div className="form-group row">
                  <div className='col-sm-12 -label'><b>How would you rate the vendor you worked with for this project? (If there were multiple vendors, you can write their ratings in the comments)</b></div>
                  <div className='col-sm-12'>
                    <select className="form-control  form-control-user" readOnly={detailFlag} name="ratetheVendor" value={ratetheVendor} onChange={handleRatetheVendorChange}>
                      <option>[ Please select rating ]</option>
                      <option>1 star</option>
                      <option>2 star</option>
                      <option>3 star</option>
                      <option>4 star</option>
                      <option>5 star</option>
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <div className='col-sm-12'><b>comments</b></div>
                  <div className='col-sm-12'><textarea
                    rows={2} // You can adjust the number of visible rows
                    cols={100}
                    value={comments2}
                    readOnly={detailFlag}
                    onChange={handleComment2Change}
                    name='comments2'
                    placeholder="enter comments" // You can adjust the number of visible columns
                    class="form-control"
                    autofocus
                  /></div>
                </div>


                <div className="form-group row">
                  <div className='col-sm-12 -label'><b>How would you rate the client satisfaction?</b></div>
                  <div className='col-sm-12'>
                    <select className="form-control  form-control-user" readOnly={detailFlag} name="ratetheClient" value={ratetheClient} onChange={handleRatetheClientChange}>
                      <option>[ Please select rating ]</option>
                      <option>1 star</option>
                      <option>2 star</option>
                      <option>3 star</option>
                      <option>4 star</option>
                      <option>5 star</option>
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <div className='col-sm-12'><b>comments</b></div>
                  <div className='col-sm-12'><textarea
                    rows={2} // You can adjust the number of visible rows
                    cols={100}
                    name='comments3'
                    readOnly={detailFlag}
                    onChange={handleComment3Change}
                    value={comments3}
                    placeholder="enter comments" // You can adjust the number of visible columns
                    class="form-control"
                    autofocus
                  /></div>
                </div>

                <div className="form-group row">
                  <div className='col-sm-12 -label'><b>How would you rate client's satisfaction with the virtual experience? </b></div>
                  <div className='col-sm-12'>
                    <select className="form-control  form-control-user" readOnly={detailFlag} name="ratetheClientVirtualExperience" value={ratetheClientVirtualExperience} onChange={handleRatetheClientVirtualExperienceChange} >
                      <option>[ Please select rating ]</option>
                      <option>1 star</option>
                      <option>2 star</option>
                      <option>3 star</option>
                      <option>4 star</option>
                      <option>5 star</option>
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <div className='col-sm-12'><b>comments</b></div>
                  <div className='col-sm-12'><textarea
                    rows={2} // You can adjust the number of visible rows
                    cols={100}
                    value={comments4}
                    readOnly={detailFlag}
                    onChange={handleComment4Change}
                    name='comments4'
                    placeholder="enter comments" // You can adjust the number of visible columns
                    class="form-control"
                    autofocus
                  /></div>
                </div>

                <div className="form-group row">
                  <div className='col-sm-12 -label'><b>How would you rate the execution of the project? (Management by and of the team)</b></div>
                  <div className='col-sm-12'>
                    <select className="form-control  form-control-user" readOnly={detailFlag} name="ratetheExecution" value={ratetheExecution} onChange={handleRatetheExecutionChange}>
                      <option>[ Please select rating ]</option>
                      <option>1 star</option>
                      <option>2 star</option>
                      <option>3 star</option>
                      <option>4 star</option>
                      <option>5 star</option>
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <div className='col-sm-12'><b>comments</b></div>
                  <div className='col-sm-12'><textarea
                    rows={2} // You can adjust the number of visible rows
                    cols={100}
                    readOnly={detailFlag}
                    value={comments5}
                    onChange={handleComment5Change}
                    name='comments5'
                    placeholder="enter comments" // You can adjust the number of visible columns
                    class="form-control"
                    autofocus
                  /></div>
                </div>

                <div className="form-group row">
                  <div className='col-sm-12'><b>If the project went off well, what were the reasons for the same? What should we continue doing?</b></div>
                  <div className='col-sm-12'><textarea
                    rows={4} // You can adjust the number of visible rows
                    cols={100}
                    name='projectWentoffWell'
                    readOnly={detailFlag}
                    value={projectWentoffWell}
                    onChange={handleProjectWentoffWellChange}
                    placeholder="enter comments" // You can adjust the number of visible columns
                    class="form-control"
                    autofocus
                  /></div>
                </div>

                <div className="form-group row">
                  <div className='col-sm-12'><b>What were some of the reasons if the project did not go well? What are your suggestions to improve them for the next time?</b></div>
                  <div className='col-sm-12'><textarea
                    rows={4} // You can adjust the number of visible rows
                    cols={100}
                    onChange={handleProjectDidnotGoWellChange}
                    readOnly={detailFlag}
                    name='projectDidnotGoWell'
                    value={projectDidnotGoWell}
                    placeholder="enter comments" // You can adjust the number of visible columns
                    class="form-control"
                    autofocus
                  /></div>
                </div>
                <div className="form-group row">
                  <div className='col-sm-12'><b>If we have lost this account, please state reasons for the same. How do we correct this for the next time?</b></div>
                  <div className='col-sm-12'><textarea
                    rows={4} // You can adjust the number of visible rows
                    cols={100}
                    value={losethisaccount}
                    readOnly={detailFlag}
                    onChange={handleLosethisaccountChange}
                    name='losethisaccount'
                    placeholder="enter comments" // You can adjust the number of visible columns
                    class="form-control"
                    autofocus
                  /></div>
                </div>

                <div className="form-group row">
                  <div className='col-sm-12'><b>Any other suggestions/ recommendations?:</b></div>
                  <div className='col-sm-12'><textarea
                    rows={4} // You can adjust the number of visible rows
                    cols={100}
                    value={suggestions}
                    readOnly={detailFlag}
                    onChange={handleSuggestionsChange}
                    name='suggestions'
                    placeholder="enter suggestions" // You can adjust the number of visible columns
                    class="form-control"
                    autofocus
                  /></div>
                </div>

                <div className="form-group row">
                  <div className='col-sm-12'><b>Customer Feedback:</b></div>
                  <div className='col-sm-12'><textarea
                    rows={4} // You can adjust the number of visible rows
                    cols={100}
                    value={customerFeedback}
                    readOnly={detailFlag}
                    onChange={handleCustomerFeedbackChange}
                    name='customerFeedback'
                    placeholder="enter feedback" // You can adjust the number of visible columns
                    class="form-control"
                    autofocus
                  /></div>
                </div>

                <div className="form-group row">
                  <div className='col-sm-12 -label'><b>Feedback provided by:</b></div>
                  <div className='col-sm-12'>
                    <input type="text" className="form-control form-control-user" id="headline" readOnly={detailFlag}
                      name="feedbackProvidedby" placeholder="enter feedback provider name" value={feedbackProvidedby} onChange={handleFeedbackProvidedbyChange} />
                  </div>
                </div>
                {!detailFlag && <button className="btn btn-primary" type='submit'>Save</button>}



              </div>
            </div>

          </div>
        </form>
      </div>
    </div>
  )
}

export default CreateProjectClosureFeedbackComponent