import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import VendorCostingDetailsComponent from '../../../Components/Main/VendorComponent/VendorCostingDetailsComponent';


function VendorCostingDetails(props) {
    let navigate = useNavigate();
    const params = useParams();
    return (  
        <div> 
            <VendorCostingDetailsComponent {...props} navigate={navigate} id={params.id} />
        </div>
    );  
  }
export default VendorCostingDetails