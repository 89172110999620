import React, { Component } from 'react' 
import CreateVendorQuotationComponent from '../../../Components/Main/VendorQuotationComponent/CreateVendorQuotationComponent';
import CreateClientQuotationComponent from '../../../Components/Main/VendorQuotationComponent/CreateClientQuotationComponent';

import { useNavigate, useParams } from 'react-router-dom';

function CreateClientQuotation(props) {
    let navigate = useNavigate();
    const params = useParams();
    return (  
        <div> 
            <CreateClientQuotationComponent {...props} navigate={navigate} id={params.id} name={params.name} />
        </div>
    );  
  }
  
export default CreateClientQuotation  