import React, { useEffect, useState } from 'react'
import Service from '../../../Services/Admin/Service';
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState, convertFromHTML, ContentState, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Select from 'react-select';
import draftToHtml from 'draftjs-to-html';
import { Link } from 'react-router-dom';

const AgreementDetailsComponent = (props) => {
    const id = props.id

    const [agreementDetails, setAgreementDetails] = useState('');
    const [shared, setShared] = useState('');
    const [approved, setApproved] = useState('');
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );


    const handleEditorChange = (state) => {
        setEditorState(state);
    }

    useEffect(() => {
        getAgreementDetails("Agreement/GetById?id=" + id)
    }, []);

    const getAgreementDetails = (method) => {
        Service.getdatabyId(method, '').then((res) => {
            setAgreementDetails(res.data);
            console.log(res.data)
            debugger
           if(res.data.isShared === true){
            setShared("True");
           }
           else {
            setShared("False")
           }
           if(res.data.isAproved === true){
            setApproved("True");
           }
           else {
            setApproved("False")
           }
           
            const content1 = convertFromRaw(JSON.parse(res.data.details));

            setEditorState(EditorState.createWithContent(content1));
        });
    }


    const formatDateTime = (cell, row) => {
        // Format the date and time here, assuming 'time' is a string in ISO 8601 format
        const formattedDate = new Date(cell).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          
        });
        return formattedDate;
    }

    const backbutton = () => {
        props.navigate(-1);
    }
    return (
        <div>
            <div className="container-fluid">

                <div className="card shadow border-success mb-2">
                    <div className="card-header py-3">
                        <div className="row">
                            <div class="d-flex flex-start align-items-center">

                            </div>
                            <div className="col-sm-4"><h2><b className='text-dark'>New Agreement Details</b></h2></div>
                            <div className="col-sm-6"></div>
                            <div className="col-sm-2 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="card cardUser shadow mb-4" >
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-success">Details</h6>

                    </div>

                    <div class="container">
                        <form class="form-horizontal mt-2 text-dark">

                            <div className="form-group row">
                                <div className='col-sm-3 required-label'><b>Project</b></div>
                                <div className='col-sm-9'>
                                    {agreementDetails.projectName}
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="boagreementType" class="col-sm-3 required-label  control-label"><b>Agreement Type</b></label>
                                <div class="col-sm-9">
                                    {agreementDetails.type}
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="boagreementType" class="col-sm-3 required-label  control-label"><b>Is Approved</b></label>
                                <div class="col-sm-9">
                                    {approved}
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="boagreementType" class="col-sm-3 required-label  control-label"><b>Is Shared</b></label>
                                <div class="col-sm-9">
                                    {shared}
                                </div>
                            </div>


                            <div class="form-group row">
                                <label for="approvedDate" class="col-sm-3 required-label  control-label"><b>Approved Date</b></label>
                                <div class="col-sm-9">
                                    {formatDateTime(agreementDetails.createdDate)}
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="details" class="col-sm-12 required-label  control-label"><b>Agreement Details</b></label>
                                <div class="col-sm-12">
                                    <Editor
                                        readOnly
                                        editorState={editorState}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        onEditorStateChange={handleEditorChange}
                                        required
                                    />
                                </div>
                            </div>


                            {/* <button type="submit" class="btn btn-primary ml-3 mb-3">Save</button> */}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AgreementDetailsComponent