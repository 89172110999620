import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import Service from "../../../Services/Admin/Service";
import React from 'react'
import { useState, useEffect } from 'react';

const RFPFinalDesignComponent = (props) => {
    const deptId = sessionStorage.getItem("DeptId");
    const [contentLink, setContentLink] = useState([]);
    const [data, setData] = useState([]);
    const [projectName, setProjectName] = useState([]);
    // const apis = ApiMethod.find(o => o.name === 'Category');

    useEffect(() => {

        getDesignlList("Task/GetAllTypetaskdetails?typename=" + props.name);
        const project = () => {

            if (deptId === 4 || deptId === 3) {
                setProjectName(data.projectName)
                debugger
            }
        }
        project();

    }, [])

    const getDesignlList = (method) => {
        Service.getdata(method).then((res) => {
            const filteredData = res.data.filter((element) => element.contentLink !== null);

            // Set the state with the filtered data
            setData(filteredData);

            // Log the filtered data
            console.log(filteredData);
            debugger
        });
    }

    const backbutton = () => {
        props.navigate(-1);
    }

    const externalLink = (data) => {
        window.open(data, '_blank')
    }

    const linkformatter = (data, row) => {
        var Co = data;

        if (Co !== undefined && Co !== null) {
            var content = Co.split(',');
            return (
                <>
                    <div className='d-flex'>
                        {content?.map((link, index) => (
                            <div className="form-group  d-flex " key={index}>
                                <div className='btn btn-sm  ml-2 btn-info' onClick={() => externalLink(link)}>
                                    {index !== 0 ? 'Change' + index : 'Latest Link'}
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            );
        } else {
            return null; // Return null if content is undefined or null
        }
    }

    const linkformatterActions = (data, row) => {
        return <>
            {/* <Link target="_blank" rel="noopener noreferrer"  to={`${data}`}>{data}</Link> */}
            <Link className="btn btn-warning btn-sm" to={`/TaskDetails/${data}`}>See Details</Link>
        </>
    }

    const assigneeName = (data, row) => {
        console.log(data);
        var a = { ...data }
        console.log(a);
        debugger
        return (
            <>
                <div className="d-flex">
                    {data?.map((e) => (
                        <b>{e.assigneeName},</b>
                    ))}
                </div>
            </>
        )
    }





    const column = [

        { dataField: 'leadName', text: 'RFP', sort: true },
        { dataField: 'contentLink', text: 'Final Design Link', formatter: linkformatter },
        { dataField: 'taskassignuserdetails', text: 'Final Design Link', formatter: assigneeName },
        { dataField: 'id', text: 'Actions', formatter: linkformatterActions }
    ]

    return (
        <div>
            <div className="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col-sm-5"><h3>RFP Final Design</h3></div>

                            <div className="col-sm-5"></div>
                            <div className="col-sm-2 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">List Of Design</h6>
                        {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
                    </div>

                    <div className="card-body">
                        <div className="table-responsive">
                            <BootstrapTable keyField="id"
                                data={data}
                                columns={column}

                                pagination={paginationFactory()}
                            // filter={filterFactory()}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default RFPFinalDesignComponent