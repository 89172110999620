import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link, useParams } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import Service from "../../../Services/Admin/Service";
import React, { useState, useEffect } from 'react'

const SpecificTaskListComponent = (props) => {

  

    const [data, setData] = useState([]);


    useEffect(() => {
        getShowData("Task/GetAllTypetaskdetails?typename=" + props.name);
    }, [])

    const getShowData = (method) => {
        Service.getdata(method).then((res) => {
            setData(res.data);
            debugger
        });
    }

    const backbutton = () => {
        props.navigate(-1);
    }

    const linkformatter = (data, row) => {
        return <>
            {/* <Link className="btn btn-warning btn-sm" to={`/CreateMeeting/${data}`}>Approve</Link> &nbsp; */}
            <Link className="btn btn-warning btn-sm" to={`/TaskDetails/${data}`}>Details</Link>
        </>
    }

    const column = [
        { dataField: 'headline', text: 'Headline', sort: true },
        { dataField: 'status', text: 'Status' },
        { dataField: 'priority', text: 'Priority' },
        { dataField: 'showName', text: 'Show Name' },
        // { dataField: 'author', text: 'Author' },
        // { dataField: 'meetingMinutes', text: 'Meeting Minutes', sort: true },
        {
            dataField: 'date',
            text: 'Due Date',
            sort: true,
            formatter: (cell, row) => {
                // Format the date here, assuming createddate is a string in ISO 8601 format
                const formattedDate = new Date(cell).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                });
                return formattedDate;
            }
        },
        { dataField: 'id', text: 'Actions', formatter: linkformatter }
    ]
    return (
        <div className="container-fluid">
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <div className="row">
                        <div className="col-sm-7"><h3>Review - {props.name} List</h3></div>
                        <div className="col-sm-3"></div>
                        <div className="col-sm-2 d-flex flex-row-reverse">
                            <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                            <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">List</h6>
                    {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
                </div>

                <div className="card-body">
                    <div className="table-responsive">
                        <BootstrapTable keyField="id"
                            data={data}
                            columns={column}
                            pagination={paginationFactory()}
                        // filter={filterFactory()}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SpecificTaskListComponent