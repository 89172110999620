import React, { Component } from 'react' 
import { useNavigate, useParams } from 'react-router-dom';
import MeetingDetailsComponent from '../../../Components/Main/Meeting/MeetingDetailsComponent';


function MeetingDetails(props) {
    let navigate = useNavigate();
    const params = useParams();
    return (  
        <div> 
            <MeetingDetailsComponent {...props} navigate={navigate} id={params.id} />
        </div>
    );  
  }
  
export default MeetingDetails;  