import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import CreateAgreementComponent from '../../../Components/Main/AgreementComponent/CreateAgreementComponent';

function CreateAgreement(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <CreateAgreementComponent {...props} navigate={navigate} id={params.id}  />
        </div>
    );  
  }
export default CreateAgreement  