import React, { Component } from 'react';
import Header from './Header'
import Footer from './Footer'
import Navbar from './Navbar';
import Home from './Home';
import Tables from './Tables';
import routes from '../../Routes/routes';


import {
    BrowserRouter as Router,
    Route, Routes, Redirect, Navigate
} from 'react-router-dom';

export class Content extends Component {
    loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>
    render() {
        return (
            <Router>
                <div className="fixed-top mt-2" style={{ backgroundColor: "#d7ffce"}}>
                    <div id="wrapper" >
                        <Navbar></Navbar>
                        <div id="content-wrapper" className="d-flex flex-column" style={{ backgroundColor: "#d7ffce",overflowY:"auto",maxHeight:"98vh", marginTop: "5.2%"}}>
                            <div id="content" >
                                <Header />
                               
                                <Routes>
                                    {routes.map((route, idx) => {
                                        return (
                                            route.component && (
                                                <Route
                                                    key={idx}
                                                    path={route.path}
                                                    exact={route.exact}
                                                    name={route.name}
                                                    // element
                                                    element={<route.component />}

                                                />
                                            )
                                        )
                                    })}
                                </Routes>


                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </Router>
        )
    }
}

export default Content