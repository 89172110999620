import logo from './logo.svg';
import React from 'react'
import Content from './Pages/Layout/Content';
import Login from './Pages/Main/Login/Login';
import {
  BrowserRouter as Router,
  Route, Routes, Redirect
} from 'react-router-dom';
import Cookies from 'js-cookie';

function App() {


  let id = Cookies.get('id');
  sessionStorage.setItem('name', Cookies.get('name'));
  sessionStorage.setItem('id', Cookies.get('id'));
  sessionStorage.setItem('token', Cookies.get('token'));
  sessionStorage.setItem('email', Cookies.get('email'));
  sessionStorage.setItem('Rolelst', Cookies.get('Rolelst'));
  sessionStorage.setItem('Deptlst', Cookies.get('Deptlst'));
  sessionStorage.setItem('role', Cookies.get('role'));
  sessionStorage.setItem('DeptId', Cookies.get('DeptId'));
  let name = Cookies.get('name')
  // console.log(navigate)
  return (
    <div className="App">
      {
        id === undefined ?
          <Router>
            <Login></Login>
          </Router>

          :
          <Content />
      }
          {/* <Content1 /> */}

    </div>
  );
}

export default App;
