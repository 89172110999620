import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import Service from '../../../Services/Admin/Service';
import paginationFactory from 'react-bootstrap-table2-paginator';

const SitePhotosAndVideosListComponent = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getPhotosVideosDetails("ConstructionStatus/GetAll");
  }, []);

  const getPhotosVideosDetails = (method) => {
    Service.getdata(method).then((res) => {
      setData(res.data);
      debugger
    });
  }

  const backbutton = () => {
    props.navigate(-1);
  }

  const externalLink = (data) => {
    window.open(data, '_blank')
    debugger
  }

  const linkformatter = (data, row) => {
    return <>
      {/* <Link target="_blank" rel="noopener noreferrer"  to={`${data}`}>{data}</Link> */}
      <button className='btn btn-sm btn-info' onClick={() => externalLink(data)} >Open Drive Link</button>
    </>
  }

  const staticName1 = "edit";
  const staticName2 = "details"

  const linkformatterAction = (data, row) => {
    return <>
      <Link className="btn btn-warning  m-1 btn-sm" to={`/SitePhotosAndVideo/${data}&${staticName1}`}>Edit</Link>
      <Link className="btn btn-warning btn-sm m-1" to={`/SitePhotosAndVideo/${data}&${staticName2}`}>Details</Link>
    </>
  }

  const column = [
    { dataField: 'projectName', text: 'Project Name', sort: true },
    { dataField: 'status', text: 'Drive Link', sort: true, formatter: linkformatter },
    { dataField: 'id', text: 'Actions', formatter: linkformatterAction }
  ]

  return (
    <div>
      <div className="container-fluid">
        <div className="card shadow mt-2 border-success mb-2">
          <div className="card-header rounded py-3">
            <div className="row">
              <div className="col-sm-5"><h3>Site Photos And Videos List</h3></div>
              <div className="col-sm-5"></div>
              <div className="col-sm-2 d-flex flex-row-reverse">
                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
              </div>
            </div>
          </div>
        </div>
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-success">Photos and Videos</h6>
          </div>

          <div className="card-body">
            <div className="table-responsive">
              <BootstrapTable keyField="id"
                data={data}
                rowStyle={{color:"black", fontWeight:700}}
                columns={column}
                pagination={paginationFactory()}
              />
            </div>
          </div>
        </div>
        {/* <button type="submit" class="btn btn-primary ml-3 mb-3">Save</button> */}
      </div>
    </div>
  )
}

export default SitePhotosAndVideosListComponent