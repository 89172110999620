import React, { useEffect, useState } from 'react'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Service from '../../../Services/Admin/Service';
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { Link } from 'react-router-dom';

const CreateCustomerFeedbackComponent = (props) => {


  let id = sessionStorage.getItem('id');

  const [apiResponse, setApiResponse] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [projectData, setProjectData] = useState([]);
  const [customerFeedbackData, setCustomerFeedbackData] = useState([]);
  const [detailFlag, setDetailFlag] = useState(false);
  const [reliablity, setReliability] = useState('');
  const [appearance, setAppearance] = useState('');
  const [consideration, setConsideration] = useState('');
  const [efficiency, setEfficiency] = useState('');
  const [updatesOnJobProgress, setUpdatesOnJobProgress] = useState('');
  const [workmanship, setWorkmanship] = useState('');
  const [productQuality, setProductQuality] = useState('');
  const [completingYourRequirement, setCompletingYourRequirement] = useState('')
  const [cleanUp, setCleanUp] = useState('');
  const [overallSatisfication, setOverallSatisfication] = useState('');
  const [recomended, setRecomended] = useState('');
  const [comments, setComments] = useState('');
  const [date, setDate] = useState('');
  const [projectid, setprojectid] = useState('');
  const [signature, setSignatue] = useState('');
  const [projectId, setProjectId] = useState([]);
  const [inputField, setInputFields] = useState({

    id: props.id,
    projectid: '',
    userid: id,
    reliablity: "",
    appearance: "",
    consideration: "",
    efficiency: "",
    updatesOnJobProgress: "",
    workmanship: "",
    productQuality: "",
    cleanUp: "",
    completingYourRequirement: "",
    experience: "",
    overallSatisfication: "",
    recomended: "",
    comments: "",
    signature: "",
    date: "",
    createdBy: id,
    updatedBy: id,

  })



  useEffect(() => {
    getProjectData("Project/GetAll");

    var d = props.name;
    if (d === "details") {
      setDetailFlag(true);
    }

    if (props.id !== 0 && props.id !== undefined) {
      getCustomerFeedbackbyId("Feedback/GetByIDCustomerFeedback?id=" + props.id, '');
    }

  }, [])

  const getProjectData = (method) => {
    Service.getdata(method).then((res) => {
      setProjectData(res.data);
    });
  }

  const getCustomerFeedbackbyId = (method) => {
    Service.getdata(method).then((res) => {
      setCustomerFeedbackData(res.data);
      console.log(res.data)
      debugger
      setReliability(res.data.reliablity)
      setAppearance(res.data.appearance)
      setConsideration(res.data.consideration)
      setEfficiency(res.data.efficiency)
      setUpdatesOnJobProgress(res.data.updatesOnJobProgress)
      setWorkmanship(res.data.workmanship)
      setCompletingYourRequirement(res.data.completingYourRequirement)
      setProductQuality(res.data.productQuality)
      setCleanUp(res.data.cleanUp)
      // setExperience(res.data.experience)
      setOverallSatisfication(res.data.overallSatisfication)
      setRecomended(res.data.recomended)
      setComments(res.data.comments)
      setDate(res.data.date)

      setSignatue(res.data.signature)
      setprojectid(res.data.projectid)
    });
  }
  useEffect(() => {
    if (apiResponse || apiError) {
      const timeoutId = setTimeout(removeSuccessMessage, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [apiResponse, apiError]);

  const removeSuccessMessage = () => {
    setApiResponse(null);
    setApiError(null);
  };

  const handleReliabilityChange = (evt) => {
    setReliability(evt.target.value);
  }

  const handleAppearanceChange = (evt) => {
    setAppearance(evt.target.value);
  }

  const handleConsiderationChange = (evt) => {
    setConsideration(evt.target.value);
  }

  const handleEfficiencyChange = (evt) => {
    setEfficiency(evt.target.value);
  }
  const handleUpdatesOnJobProgressChange = (evt) => {
    setUpdatesOnJobProgress(evt.target.value);
  }
  const handleWorkmanshipChange = (evt) => {
    setWorkmanship(evt.target.value);
  }
  const handleProductQualityChange = (evt) => {
    setProductQuality(evt.target.value);
  }

  const handleCleanUpChange = (evt) => {
    setCleanUp(evt.target.value);
  }
  const handleRequirementChange = (evt) => {
    setCompletingYourRequirement(evt.target.value);
  }

  const handleRecomendedChange = (evt) => {
    setRecomended(evt.target.value);
  }

  const handleProjectChange = (evt) => {
    setprojectid(evt.target.value);
  }

  const handleCommentsChange = (evt) => {
    setComments(evt.target.value);
  }

  const handleDateChange = (evt) => {
    setDate(evt.target.value);
  }

  const handleOverallSatisficationChange = (evt) => {
    setOverallSatisfication(evt.target.value);
  }
  const handleSignatureChange = (evt) => {
    setSignatue(evt.target.value);
  }

  // const handleProjectChange = (evt) => {
  //   setProjectId(evt.target.value);
  // }

  const save = async (evt) => {
    debugger
    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }
    const formData = new FormData(evt.target);

    const data = {}
    for (const [key, value] of formData) {
      data[key] = value;
    }

    function validateData(data) {



      return true;
    }

    if (!validateData(data)) {
      return;
    }

    const task = {
      ...inputField,
      ...data,
    };
    console.log(task);
    debugger

    if (task) {
      Service.createorupdate("Feedback/CreateOrUpdateCustomerFeedback", task)
        .then(res => {
          setApiResponse(res.data); // Set the API response in state
          window.location.href = "/CustomerFeedbackList";
        })
        .catch(error => {
          // Handle error if API call fails
          console.error("API Error:", error);
          setApiError("Error occurred while saving the feedback. Please try again later.");
        });
    }

  }

  function formatDate(date) {
    if (!date) return ""; // Handle null or undefined dates
    const localDate = new Date(date);
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, '0');
    const day = String(localDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }


  const backbutton = () => {
    props.navigate(-1);
  }
  return (
    <div>
      <div className="container-fluid">
        <br />
        <div className="card shadow mb-2 border-success">
          <div className="card-header rounded py-3">
            <div className="row">
              <div className="col-sm-5 mt-1"><h3><b className='text-dark'> Customer Feedback</b></h3></div>
              <div className="col-sm-4"></div>
              <div className="col-sm-3 d-flex flex-row-reverse">
                <button className="btn btn-info  ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link> */}
              </div>

            </div>
          </div>
        </div>
        <form onSubmit={save}>
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-success"> Feedback</h6>
              {apiResponse && (
                <div className="alert alert-success mt-2" role="alert">
                  Feedback has been submitted successfully!
                </div>
              )}
              {apiError && (
                <div className="alert alert-danger" role="alert">
                  {apiError}
                </div>
              )}
            </div>

            <div className="card-body" style={{ height: "auto" }}>
              <div className='row text-dark'>
                <div className='col-md-5 ml-5'>
                  <div className="form-group row">
                    <div className='col-sm-12 required-label'><b>Exhibitor/Vendor Survey</b> </div>
                  </div>
                  <h6><b className='text-dark'>Thank you for exhibiting at</b>  <select className="form-control  form-control-user" readOnly={detailFlag} required name="projectid" value={projectid} onChange={handleProjectChange}>
                    <option>[NONE]</option>
                    {
                      projectData.map((e, key) => {
                        //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;
                        return <option key={e.id} value={e.id}>{e.projectname}</option>;
                      })}
                  </select> Please take few minutes to complete the following surveys, as your feedback will help us to evaluate this year conference and increase its effectiveness in the future.
                    Thank you for your help!</h6>


                  <h5>Product</h5>

                  <div className="form-group row">
                    <div className='col-sm-12 required-label'><b className='text-dark'>Workmanship</b></div>
                    <div className='col-sm-12'>

                      <select className="form-control  form-control-user" name="workmanship" readOnly={detailFlag} required value={workmanship} onChange={handleWorkmanshipChange} >
                        <option>[ Please select rating ]</option>
                        <option>1 star</option>
                        <option>2 star</option>
                        <option>3 star</option>
                        <option>4 star</option>
                        <option>5 star</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-12 required-label'><b className="text-dark">Product Quality</b></div>
                    <div className='col-sm-12'>

                      <select className="form-control  form-control-user" name="productQuality" readOnly={detailFlag} required value={productQuality} onChange={handleProductQualityChange}>
                        <option>[ Please select rating ]</option>
                        <option >1 star</option>
                        <option>2 star</option>
                        <option>3 star</option>
                        <option>4 star</option>
                        <option>5 star</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-12 required-label'><b className="text-dark">Clean up</b></div>
                    <div className='col-sm-12'>

                      <select className="form-control  form-control-user" name="cleanUp" readOnly={detailFlag} required value={cleanUp} onChange={handleCleanUpChange}>
                        <option>[ Please select rating ]</option>
                        <option>1 star</option>
                        <option>2 star</option>
                        <option>3 star</option>
                        <option>4 star</option>
                        <option>5 star</option>
                      </select>
                    </div>
                  </div>


                  <div className="form-group row">
                    <div className='col-sm-12 required-label'><b className="text-dark">Completing your requirements</b></div>
                    <div className='col-sm-12'>

                      <select className="form-control  form-control-user" name="completingYourRequirement" readOnly={detailFlag} required value={completingYourRequirement} onChange={handleRequirementChange}>
                        <option>[ Please select rating ]</option>
                        <option>1 star</option>
                        <option>2 star</option>
                        <option>3 star</option>
                        <option>4 star</option>
                        <option>5 star</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-12'><b className="text-dark">Other Comments:</b></div>
                    <div className='col-sm-12'><textarea
                      rows={3} // You can adjust the number of visible rows
                      cols={100}
                      value={comments}
                      readOnly={detailFlag} 
                      
                      onChange={handleCommentsChange}
                      name='comments'
                      placeholder="enter other comments" // You can adjust the number of visible columns
                      class="form-control"
                      autofocus
                    /></div>
                  </div>
                  <div className="form-group row">
                    <div className='col-sm-12 required-label'><b className="text-dark">Signature</b></div>
                    <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline"
                      name="signature" placeholder="enter authority name/signature" readOnly={detailFlag} required value={signature} onChange={handleSignatureChange} /></div>
                  </div>
                </div>
                <div className='col-md-1'></div>
                <div className='col-md-5'>

                  <h5><b>Service</b></h5>
                  <div className="form-group row">
                    <div className='col-sm-12 required-label'><b className="text-dark">Reliability</b></div>
                    <div className='col-sm-12'>

                      <select className="form-control  form-control-user" readOnly={detailFlag} required name="reliablity" value={reliablity} onChange={handleReliabilityChange} >
                        <option>[ Please select rating ]</option>
                        <option>1 star</option>
                        <option>2 star</option>
                        <option>3 star</option>
                        <option>4 star</option>
                        <option>5 star</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className='col-sm-12 required-label'><b className="text-dark">Appearance</b></div>
                    <div className='col-sm-12'>

                      <select className="form-control  form-control-user" name="appearance" readOnly={detailFlag} required value={appearance} onChange={handleAppearanceChange} >
                        <option>[ Please select rating ]</option>
                        <option>1 star</option>
                        <option>2 star</option>
                        <option>3 star</option>
                        <option>4 star</option>
                        <option>5 star</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-12 required-label'><b className="text-dark">Consideration</b></div>
                    <div className='col-sm-12'>

                      <select className="form-control  form-control-user" name="consideration" readOnly={detailFlag} required value={consideration} onChange={handleConsiderationChange}>
                        <option>[ Please select rating ]</option>
                        <option>1 star</option>
                        <option>2 star</option>
                        <option>3 star</option>
                        <option>4 star</option>
                        <option>5 star</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-12 required-label'><b className="text-dark">Efficiency</b></div>
                    <div className='col-sm-12'>

                      <select className="form-control  form-control-user" name="efficiency" readOnly={detailFlag} required value={efficiency} onChange={handleEfficiencyChange} >
                        <option>[ Please select rating ]</option>
                        <option>1 star</option>
                        <option>2 star</option>
                        <option>3 star</option>
                        <option>4 star</option>
                        <option>5 star</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className='col-sm-12 required-label'><b className="text-dark">Updates on job progress</b></div>
                    <div className='col-sm-12'>

                      <select className="form-control  form-control-user" name="updatesOnJobProgress" readOnly={detailFlag} required value={updatesOnJobProgress} onChange={handleUpdatesOnJobProgressChange} >
                        <option>[ Please select rating ]</option>
                        <option>1 star</option>
                        <option>2 star</option>
                        <option>3 star</option>
                        <option>4 star</option>
                        <option>5 star</option>
                      </select>
                    </div>
                  </div>

                  <h5>Experience</h5>
                  <div className="form-group row">
                    <div className='col-sm-12 required-label'><b className="text-dark">Overall satisfaction</b></div>
                    <div className='col-sm-12'>

                      <select className="form-control  form-control-user" name="overallSatisfication" readOnly={detailFlag} required value={overallSatisfication} onChange={handleOverallSatisficationChange}>
                        <option>[ Please select rating ]</option>
                        <option>1 star</option>
                        <option>2 star</option>
                        <option>3 star</option>
                        <option>4 star</option>
                        <option>5 star</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-12 required-label'><b className='text-dark'>Would You recommend something?</b></div>
                    <div className='col-sm-12'><b className="text-dark">Comments:</b></div>
                    <div className='col-sm-12'><textarea
                      rows={3} // You can adjust the number of visible rows
                      cols={100}
                      name='recomended'
                      onChange={handleRecomendedChange}
                      readOnly={detailFlag} 
                   
                      value={recomended}
                      placeholder="enter comments" // You can adjust the number of visible columns
                      class="form-control"
                      autofocus
                    /></div>
                  </div>
                  <div className="form-group row">
                    <div className='col-sm-12 required-label'><b className="text-dark">Date</b></div>
                    <div className='col-sm-12'><input type="Date" readOnly={detailFlag} className="form-control form-control-user" value={formatDate(date)} onChange={handleDateChange} id="headline"
                      name="date" /></div>
                  </div>

                </div>
              </div>
              {!detailFlag && <button className="btn btn-primary ml-5" type='submit'>Save</button>}
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CreateCustomerFeedbackComponent