import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import AddProjectComponent from '../../../Components/Main/ProjectComponent/AddProjectComponent';

function AddProject(props) {
    
    const params = useParams();
   
    let navigate = useNavigate();
    return (  
        <div> 
            <AddProjectComponent {...props} navigate={navigate} id={params.id} name={params.name} />
        </div>
    );  
  }
  
export default AddProject  