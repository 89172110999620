import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import ExtractedDataDetails from '../../../Components/Main/ExtractedDataComponent/ExtractedDataDetails';

function ExtractedData(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <ExtractedDataDetails {...props} navigate={navigate} id = {params.id} />
        </div>
    );  
  }
export default ExtractedData  