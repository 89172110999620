import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import TasksListComponent from '../../../Components/Main/Task/TasksListComponent';

function TasksList(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <TasksListComponent {...props} navigate={navigate}  />
        </div>
    );  
  }
export default TasksList  