import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import AdminDashboardComponent from '../../../Components/Main/AdminPanelComponent/AdminDashboardComponent';

function AdminDashboard(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <AdminDashboardComponent {...props} navigate={navigate} id={params.id} />
        </div>
    );  
  }
export default AdminDashboard  