import React, { Component } from 'react' 

import { useNavigate, useParams } from 'react-router-dom';
import CreateProjectClosureFeedbackComponent from '../../../Components/Main/FeedbackComponent/CreateProjectClosureFeedbackComponent';

function CreateProjectClosureFeedback(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <CreateProjectClosureFeedbackComponent {...props} navigate={navigate} id={params.id} name={params.name} />
        </div>
    );  
  }
  
export default CreateProjectClosureFeedback  