import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';

import PPTListComponent from '../../../Components/Main/PPTComponent/PPTListComponent';

function PPTList(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <PPTListComponent {...props} navigate={navigate} id={params.id} name={params.name} />
        </div>
    );  
  }
export default PPTList  