import React, { useEffect, useState } from 'react'
import Service from '../../../Services/Admin/Service';
import Select from 'react-select';
import { Link } from 'react-router-dom';
const CreateProjectExecutionTrackerComponent = (props) => {
    let id = sessionStorage.getItem('id');
    const [project, setProject] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [projectId, setProjectId] = useState('');
    const [projectError, setProjectError] = useState(false);
    const [detailFlag, setDetailFlag] = useState(false);

    const [elements, setelement] = useState([]);
    const [elementId, setelementId] = useState([]);
    const [elementError, setelementError] = useState(false);
    const [selectedTaskComponent, setSelectedTaskComponent] = useState('');
    const [vendor, setvendor] = useState([]);
    const [supplierid, setsupplierid] = useState([]);
    const [vendorError, setvendorError] = useState(false);

    const [apiResponse, setApiResponse] = useState(null);

    const [apiError, setApiError] = useState(null);
    const [inputFields, setInputFields] = useState({
        id: 0,
        projectId: '',
        showDate: "",
        area: "",
        venue: "",
        approverby: id,
        createdBy: id,
        updatedBy: id,
    })

    const [projectExecutionTasks, setProjectExecutionTasks] = useState([{
        id: 0,
        // projectExecutionId: '',
        elementId: '',
        elementName: '',
        amount: '',
        deadline: '',
        status: '',
        remark: '',
    }]);

    const addprojectExecutionTasks = () => {
        setProjectExecutionTasks([...projectExecutionTasks, {
            id: 0,
            projectExecutionId: 0,
            elementId: '',
            currency: '',
            amount: '',
            deadline: '',
            status: '',
            remark: '',
        }])

    }

    const handleprojectExecutionsTasks = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...projectExecutionTasks];
        debugger
        list[index][name] = value;
        setProjectExecutionTasks(list);
        debugger
    }



    const removeInputFields = (index) => {
        const rows = [...projectExecutionTasks];
        rows.splice(index, 1);
        setProjectExecutionTasks(rows);
    }




    useEffect(() => {
        getProjectData("Project/GetAll");
        getStatusData("Status/GetAll");
        getvendorData("Vendor/GetAll");
        getelementData("PrePL/GetAllPreElement");

        var d = props.name;
        if (d === "details") {
            setDetailFlag(true);
        }

        if (props.id !== 0 && props.id !== undefined) {
            getExecutionDatabyId("ProjectExecution/GetByProjectID?id=" + props.id, '');
        }
    }, [])

    const getProjectData = (method) => {
        Service.getdata(method).then((res) => {
            setProject(res.data);
        });
    }

    const getStatusData = (method) => {
        Service.getdata(method).then((res) => {
            setStatusList(res.data);
        });
    }

    const getExecutionDatabyId = (method) => {
        Service.getdata(method).then((res) => {
            console.log(res.data);
            debugger
            var data = res.data[0]
            if (data !== null && data !== undefined) {

                setInputFields(data);
                setProjectExecutionTasks(data.projectExecutionTasks);
            }

            debugger
        });
    }


    const getelementData = (method) => {
        Service.getdata(method).then((res) => {
            setelement(res.data);
            console.log(res.data)

        });
    }

    const getvendorData = (method) => {
        Service.getdata(method).then((res) => {
            setvendor(res.data);

        });
    }

    const backbutton = () => {
        props.navigate(-1);
    }

    const inputsHandler = (e) => {

        //  setInputField( {[e.target.name]: e.target.value} )
        const { name, value } = e.target;
        setInputFields((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        debugger
    }

    const projectChange = (e) => {

        setProjectId(e.target.value);
    }

    useEffect(() => {
        if (apiResponse || apiError) {
            const timeoutId = setTimeout(removeSuccessMessage, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [apiResponse, apiError]);

    const removeSuccessMessage = () => {
        setApiResponse(null);
        setApiError(null);
    };


    const save = async (evt) => {

        if (evt && evt.preventDefault) {
            evt.preventDefault();
        }
        const formData = new FormData(evt.target);

        const data = {}
        for (const [key, value] of formData) {
            data[key] = value;
        }
        function validateData(data) {

            const { area, projectId, showDate, venue } = data;

          

            if (!projectId || projectId === "[NONE]") {
                setProjectError(true);
                return false;

            } else {
                setProjectError(false);
            }

            if (!area || !showDate || !venue) {
                alert("Please fill in all required fields.");
                return false;
            }

            return true;
        }

        if (!validateData(data)) {
            // The validation returned false, so stop execution here.
            // You can add any specific handling for invalid data here, if needed.
            return;
        }

        const task = {
            // inputFields,
            projectExecutionTasks,

            ...data
        };
        console.log(task)
        debugger

        if (task) {
            Service.createorupdate("ProjectExecution/CreateOrUpdate", task)
                .then(res => {
                    setApiResponse(res.data);
                    // window.location.href = "/PrePLList";

                })
                .catch(error => {
                    console.error("API Error:", error);
                    setApiError("Error occurred while saving the meeting. Please try again later.");
                });
        }
    }

    function formatDate(date) {
        if (!date) return ""; // Handle null or undefined dates
        const localDate = new Date(date);
        const year = localDate.getFullYear();
        const month = String(localDate.getMonth() + 1).padStart(2, '0');
        const day = String(localDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    return (
        <div>
            <div className="container-fluid">

                <br />
                <div className="card shadow border-success mb-2">
                    <div className="card-header  rounded py-3">
                        <div className="row">
                            <div className="col-sm-6"><h3 className='text-dark'><b>Project Execution Planning</b></h3></div>
                            <div className="col-sm-3"></div>
                            <div className="col-sm-3 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link> */}
                            </div>

                        </div>
                    </div>
                </div>
                <form onSubmit={save}>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary text-success">Execution Planning</h6>
                            {apiResponse && (
                                <div className="alert alert-success mt-2" role="alert">
                                    projcet execution has been created successfully!
                                </div>
                            )}
                            {apiError && (
                                <div className="alert alert-danger" role="alert">
                                    {apiError}
                                </div>
                            )}
                        </div>

                        <div className="card-body " style={{ height: "auto" }}>
                            <div className='row'>
                                <div className='col-md-5 ml-5'>

                                    <div className="form-group row">
                                        <div className='col-sm-12  required-label'>Project</div>
                                        <div className='col-sm-12'>
                                            {projectError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a Project.</div>}
                                            <select className="form-control  form-control-user" onChange={inputsHandler} value={inputFields.projectId} readOnly={detailFlag} name="projectId"  >
                                                <option>[NONE]</option>
                                                {
                                                    project.map((e, key) => {
                                                        //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;
                                                        return <option key={e.id} value={e.id}>{e.projectname}</option>;
                                                    })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className='col-sm-12 required-label'>Area</div>
                                        <div className='col-sm-12'><input type="text" value={inputFields.area} onChange={inputsHandler} readOnly={detailFlag} className="form-control form-control-user" id="headline"
                                            name="area" placeholder="enter area in sqm." required /></div>
                                    </div>

                                </div>
                                <input type="hidden" value={inputFields.id} className="form-control form-control-user" id="headline"
                                    name="id" placeholder="enter area in sqm." />
                                <div className='col-md-1'></div>
                                <div className='col-md-5'>

                                    <div className="form-group row">
                                        <div className='col-sm-12 required-label '>Show Date</div>
                                        <div className='col-sm-12'><input type="date" value={formatDate(inputFields.showDate)} readOnly={detailFlag} onChange={inputsHandler} className="form-control form-control-user" id="headline"
                                            name="showDate" placeholder="enter show date" required/></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 required-label'>Venue</div>
                                        <div className='col-sm-12'><textarea
                                            rows={3} // You can adjust the number of visible rows
                                            cols={100}
                                            name='venue'
                                            readOnly={detailFlag}
                                            value={inputFields.venue}
                                            onChange={inputsHandler}
                                            placeholder='enter Venue' // You can adjust the number of visible columns
                                            class="form-control"
                                            autofocus
                                            required
                                        /></div>
                                    </div>
                                </div>

                            </div>
                            <div className="row">

                                <div className='col-md-12 ml-5'>
                                    <br></br>
                                    <div className="form-group row">
                                        <div className='col-sm-1'><b>Task</b></div>
                                        <div className='col-sm-2'><b></b></div>
                                        <div className='col-sm-2'><b>Deadline</b></div>
                                        {/* <div className='col-sm-2'><b></b></div> */}
                                        <div className='col-sm-2'><b>Status</b></div>
                                        {/* <div className='col-sm-2'><b></b></div> */}
                                        <div className='col-sm-2'><b>Remark</b></div>
                                        <div className='col-sm-2'><b> </b></div>
                                    </div>
                                    {
                                        projectExecutionTasks.map((data, index) => {
                                            debugger
                                            const { projectExecutionId, elementId, id, elementName, deadline, status, remark } = data;
                                            return (
                                                <div key={index}>
                                                    <div className="form-group row d-flex">
                                                        <div className='col-sm-3'>
                                                            <select
                                                                className="form-control form-control-user"
                                                                readOnly={detailFlag}
                                                                onChange={(e) => {
                                                                    setSelectedTaskComponent(e.target.value);
                                                                    handleprojectExecutionsTasks(index, e);
                                                                }}
                                                                name="elementId"
                                                                value={elementId}
                                                                required
                                                            >
                                                                <option>[NONE]</option>
                                                                {
                                                                    elements.map((e, key) => {
                                                                        return <option key={e.id} value={e.id}>{e.plelement}</option>;
                                                                    })}
                                                            </select>
                                                        </div>

                                                        <div className='col-sm-2'>
                                                            <input type="date" onChange={(evnt) => handleprojectExecutionsTasks(index, evnt)} readOnly={detailFlag} value={formatDate(deadline)} name="deadline" className="form-control" placeholder="Enter deadline" />
                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <select className="form-control  form-control-user" readOnly={detailFlag} onChange={(evnt) => handleprojectExecutionsTasks(index, evnt)} name="status" value={status} required>
                                                                <option>[NONE]</option>
                                                                {
                                                                    statusList.map((e, key) => {
                                                                        return <option key={e.statusId} value={e.statusId}>{e.name}</option>;
                                                                    })}
                                                            </select>
                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <input type="text" onChange={(evnt) => handleprojectExecutionsTasks(index, evnt)} readOnly={detailFlag} value={remark} name="remark" className="form-control" placeholder="enter remarks" />
                                                        </div>
                                                        {detailFlag === false && <div className='col-sm-2'>
                                                            <span className="btn btn-outline-success " onClick={addprojectExecutionTasks}>Add</span>
                                                        </div>}
                                                        {elementId === 16 || elementId === "16" ?
                                                            <div className='col-sm-2 mt-1'>
                                                                <input type="text" onChange={(evnt) => handleprojectExecutionsTasks(index, evnt)} readOnly={detailFlag} value={elementName} name="elementName" className="form-control" placeholder="Enter task" />
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        {detailFlag === false && <div className="col-sm-1">
                                                            {(projectExecutionTasks.length !== 1) ? <span className="btn btn-outline-danger" onClick={removeInputFields}>x</span> : ''}

                                                        </div>}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }








                                    {/* {selectedTaskComponent === "16" && <>
                                        {
                                            prePlStandConstructions.map((data, index) => {
                                                const { element, supplierid, currency, amount, paymentterm } = data;

                                                return (
                                                    <div>
                                                        <div className="form-group row" key={index}>
                                                            <div className='col-sm-3'>
                                                                <input type="text" readOnly={detailFlag} name="amount" className="form-control" placeholder="enter task" />

                                                            </div>
                                                            <div className='col-sm-3'>
                                                                <input type="date" readOnly={detailFlag} className="form-control" placeholder="Enter deadline" />
                                                            </div>
                                                            <div className='col-sm-3'>
                                                                <select className="form-control form-control-fs form-control-user" readOnly value={1} name="statusId" required>
                                                                    <option>[NONE]</option>
                                                                    {
                                                                        status.map((e, key) => {
                                                                            return <option key={e.statusId} value={e.statusId}>{e.name}</option>;
                                                                        })}
                                                                </select>
                                                            </div>
                                                            <div className='col-sm-2'>
                                                                <input type="text" readOnly={detailFlag} name="amount" className="form-control" placeholder="enter remark" />
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </>} */}

                                    {detailFlag === false && <button className="btn btn-primary" type='submit'>Save</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div >
        </div >
    )
}

export default CreateProjectExecutionTrackerComponent