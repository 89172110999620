import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import CreateUserComponent from '../../../Components/Main/UserComponent/CreateUserComponent';

function CreateUser(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <CreateUserComponent {...props} navigate={navigate}  />
        </div>
    );  
  }
export default CreateUser  