import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import Service from "../../../Services/Admin/Service";
import React, { useState, useEffect } from 'react'

const CustomerFeedbackListComponent = (props) => {
    const [data, setData] = useState([]);

    useEffect(() => {

        getVendorData("Feedback/GetALLCustomerFeedback");

    }, [])

    const staticname1 = "details";
    const staticname2 = "edit";

    const getVendorData = (method) => {
        Service.getdata(method).then((res) => {
            const task = filterTasksWithUniqueIds(res.data)
            setData(task);
            debugger
        });
    }

    function filterTasksWithUniqueIds(tasks) {
        // Create an object to store unique tasks based on their ID
        const uniqueTasks = tasks.reduce((acc, task) => {
            // Use the task ID as the key in the object
            const taskId = task.id;

            // If the task ID is not in the object, add it
            if (!acc[taskId]) {
                acc[taskId] = task;
            }

            return acc;
        }, {});

        // Convert the uniqueTasks object back to an array
        const uniqueTasksArray = Object.values(uniqueTasks);

        return uniqueTasksArray;
    }

    const backbutton = () => {
        props.navigate(-1);
    }

    const linkformatter = (data, row) => {
        return <>
            &nbsp; <Link className="btn btn-info btn-sm m-1" to={`/CreateCustomerFeedback/${data}&${staticname1}`}>Details</Link>
            &nbsp; <Link className="btn btn-warning btn-sm m-1" to={`/CreateCustomerFeedback/${data}&${staticname2}`}>Edit</Link>

        </>
    }

    const formatDateTime = (cell, row) => {
        // Format the date and time here, assuming 'time' is a string in ISO 8601 format
        const formattedDate = new Date(cell).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });
        return formattedDate;
    }

    const column = [
        { dataField: 'overallSatisfication', text: 'Overall Satisfaction', sort: true },
        { dataField: 'signature', text: 'Filled By', sort: true },
        { dataField: 'recomended', text: 'Recommended', sort: true },
        { dataField: 'reliablity', text: 'Reliability', sort: true },

        { dataField: 'id', text: 'Actions', formatter: linkformatter }
    ]

    return (
        <div className="container-fluid">
            <div className="card shadow border-success mb-2">
                <div className="card-header rounded py-3">
                    <div className="row">
                        <div className="col-sm-6"><h3><b className='text-dark'>Customer Feedback List</b></h3></div>
                        <div className="col-sm-3"></div>
                        <div className="col-sm-3 d-flex flex-row-reverse">
                            <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                            <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-success">Feedback List</h6>
                    {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
                </div>

                <div className="card-body">
                    <div className="table-responsive">
                        <BootstrapTable keyField="id"
                            data={data}
                            classes='text-dark'
                            columns={column}
                            pagination={paginationFactory()}
                        // filter={filterFactory()}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerFeedbackListComponent