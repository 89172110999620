import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import TextareaAutosize from 'react-textarea-autosize';
import Service from "../../../Services/Admin/Service";
import { Link } from 'react-router-dom';
import css from "./RFP.css"
import { Mention, MentionsInput } from 'react-mentions';
import defaultStyle from '../Task/defaultStyle';
import defaultMentionStyle from '../Task/defaultMentionStyle';



const RFPDetailsComponent = (props) => {
    const deptId = sessionStorage.getItem("DeptId");
    const senderName = sessionStorage.getItem("name");
    const id = props.id;
    const userId = sessionStorage.getItem("id");
    const [apiError, setApiError] = useState(null);
    const [detailFlag, setDetailFlag] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);
    const [RFPDataDetail, setRFPDataDetail] = useState([]);
    const [leadData, setLeadData] = useState([]);
    const [exhibitorName, setExhibitorName] = useState('');
    const [venue, setVenue] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [dateFrom, setDateFrom] = useState('');
    const [commentInput, setCommentInput] = useState({
        comments: ''
    });
    const [comments, setComments] = useState([]);
    const [userNameData, setUserNameData] = useState([]);
    const [apiResponseComment, setapiResponseComment] = useState(null)
    const [showDiscussion, setShowDiscussion] = useState(false);
    const [discussionData, setDiscussionData] = useState([]);
    const [apiSharedResponse, setApiSharedResponse] = useState(null);
    const [showAssigneePopup, setShowAssigneePopup] = useState(false);
    const [userName, setUserName] = useState([]);
    const [selectedAssignees, setSelectedAssignees] = useState([]);
    const [designDeadlineDate, setDesignDeadline] = useState('');
    const [floorplan, setFloorPlan] = useState('');
    const [guidelines, setGuidelines] = useState('');
    const [stallNo, setStallNo] = useState('');
    const [hallNo, setHallNo] = useState('');
    const [budget, setBudget] = useState('');
    const [contactName, setContactName] = useState('');
    const [fax, setFax] = useState('');
    const [createdByName, setCreatedByName] = useState("");
    const [finalLink, setFinalLink] = useState('');
    const [contentLink, setContentLink] = useState('');
    const [email, setEmail] = useState('');
    const [position, setPosition] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [phone, setPhone] = useState('');
    const [billingAddress, setBillingAddress] = useState('');
    const [anySpecificDetail, setAnySpecificDetail] = useState('');
    const [website, setWebsite] = useState('');
    const [description, setDescription] = useState('');
    const [leadId, setLeadId] = useState('');
    const [noofProduct, setNoofProduct] = useState('');
    const [designRefrence, setDesignRefrence] = useState('');
    const [yourpurpose, setYourPurpose] = useState('');
    const [brandguidelines, setBrandGuidelines] = useState('');
    const [dos, setDos] = useState('');
    const [isCompleted, setIsCompleted] = useState('');
    const [textareaRows, setTextareaRows] = useState(1);
    const [dont, setDont] = useState('');
    const [inputQuestionare, setInputQuestionare] = useState({
        taskid: 0,
        description: "",
        senderid: 0,
        senderName: "",
        receiverid: 0,
        receiverName: "",
        isRead: false,
        createdby: id,
    })

    const initCall = () => {
        getRFPInfo("RFP/GetById?id=" + id)
        getUserData("Users/GetAll");
        getLeadData("Lead/GetAllLead");
        updatenotification("Questionare/readnotification?rfpid=" + props.id + "&userid=" + userId)
        getDiscussionData("Task/GetALLRFPDiscussion?rfpId=" + props.id);
        debugger
        if (userId !== "38" && userId !== "25" && userId !== "53") {
            setDetailFlag(true);
        }

    }

    useEffect(() => {
        initCall();
    }, []);



    const getUserData = (method) => {
        Service.getdata(method).then((res) => {
            setUserName(res.data);
            // mentionData(res.data);
        });
    }


    const getRFPInfo = (method) => {
        Service.getdata(method).then((res) => {
            setRFPDataDetail(res.data);

            setExhibitorName(res.data.exhibitionName);
            setVenue(res.data.venue);
            setDateTo(res.data.dateTo);
            setDateFrom(res.data.dateFrom);
            setDesignDeadline(res.data.deadlineDate);
            setFloorPlan(res.data.floorplan);
            setGuidelines(res.data.guidelines);
            setStallNo(res.data.stallNo);
            setHallNo(res.data.hallNo);
            setBudget(res.data.budget);
            setContactName(res.data.contactName);
            setFax(res.data.fax);
            setEmail(res.data.email);
            if (res.data.isCompleted === false || res.data.isCompleted === null)
                setIsCompleted("In Progress");
            else {
                setIsCompleted("Completed");
            }
            setPosition(res.data.position);
            setCompanyName(res.data.companyName);
            setPhone(res.data.phone);
            setFinalLink(res.data.finalLink);
            setContentLink(res.data.contentLink);
            setBillingAddress(res.data.billingAddress);
            setAnySpecificDetail(res.data.anySpecific);
            setWebsite(res.data.website);
            setDescription(res.data.description);
            setNoofProduct(res.data.noofProduct);
            setDesignRefrence(res.data.designRefrence);
            setYourPurpose(res.data.yourpurpose);
            setBrandGuidelines(res.data.brandguidelines);

            setDos(res.data.do);
            setDont(res.data.dont);
            setLeadId(res.data.leadid);

            const assigneeIds = res.data.rfpShareList.map((assignee) => assignee.assigneeId);
            setSelectedAssignees(assigneeIds);
            debugger
            mentionData(res.data.rfpShareList, res.data.createdByName);
            // if(RFPDataDetail){

            //     setCreatedByName(res.data.createdByName);
            // }
            console.log(res.data)
            debugger

        });
    }

    const mentionData = (data, createdby) => {
        var arr = [];
        data.forEach((x, index) => {
            var abc = {};
            abc.id = x.asigneeName;
            abc.display = x.asigneeName;
            arr.push(abc);
            // setUserNameData({
            //     id:x.firstname, display:x.firstname
            // })
        })
        //         var createdUser = RFPDataDetail; 
        var abc = {}
        abc.id = createdby;
        abc.display = createdby;
        arr.push(abc)
        debugger
        setUserNameData(arr);
    }

    const updatenotification = (method) => {
        Service.getdata(method).then((res) => {

        });
    }


    const getLeadData = (method) => {
        Service.getdata(method).then((res) => {
            setLeadData(res.data);
            debugger
        })
    }

    useEffect(() => {
        if (apiResponse || apiError || apiSharedResponse || apiResponseComment) {
            const timeoutId = setTimeout(removeSuccessMessage, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [apiResponse, apiError, apiSharedResponse, apiResponseComment]);

    const removeSuccessMessage = () => {
        setApiResponse(null);
        setapiResponseComment(null);
        setApiError(null);
        setApiSharedResponse(null);
    };

    //share rfp code 

    const [inputDiscussion, setInputDiscussion] = useState({
        "discussionid": 0,
        "taskid": 0,
        "userid": sessionStorage.getItem("id"),
        comments:'',
    })


    function extractValuesFromIndexedObject(indexedObject) {
        return Object.values(indexedObject).map(item => item.value);
    }

    const handleSelectChange = (selectedOptions) => {
        const extractedValues = extractValuesFromIndexedObject(selectedOptions);
        setSelectedAssignees(extractedValues);
    };

    const shareData = () => {
        console.log(selectedAssignees)

        const data = []
        for (let i = 0; i < selectedAssignees.length; i++) {
            const assignment = {
                rfpid: id,
                userid: selectedAssignees[i],
                createdby: RFPDataDetail.createdby,
                updatedby: id,
            };

            data.push(assignment);

        }
        console.log(data)
        debugger
        if (data) {
            Service.createorupdate("RFP/CreateOrUpdateshare", data)
                .then(res => {
                    if (data) {
                        for (var m = 0; m <= data.length; m++) {
                            console.log(data[m]);
                            debugger
                            Service.createorupdate("Questionare/CreateOrUpdate", data[m])
                                .then(res => {
                                    setApiResponse(res.data);
                                    window.location.reload(true);

                                })
                                .catch(error => {

                                    console.error("API Error:", error);
                                });
                        }
                        window.location.reload(true);
                    }
                    setApiSharedResponse(res.data);
                    initCall();
                    // Set the API response in state
                })
                .catch(error => {
                    // Handle error if API call fails
                    console.error("API Error:", error);
                    setApiError("Error occurred while saving the meeting. Please try again later.");
                });
        }



    }

    // rfp discussion 

    const getDiscussionData = (method) => {
        Service.getdata(method).then((res) => {
            setDiscussionData(res.data);
            setComments(res.data); // Set the comments in the state
        });
    }

    const submitDiscussion = (evt) => {
        if (evt && evt.preventDefault) {
            evt.preventDefault();
        }
        // const formData = new FormData(evt.target);

        // const data = {}
        // for (const [key, value] of formData) {
        //     data[key] = value;
        // }

        // function validateData(data) {
        //     const { comments } = data;

        //     if (!comments) {
        //         alert("Please fill in all required fields.");
        //         return false;
        //     }
        //     //condition  met, so return true to proceed forward.
        //     return true;
        // }

        // if (!validateData(data)) {
        //     // The validation returned false, so stop execution here.
        //     // You can add any specific handling for invalid data here, if needed.
        //     return;
        // }

        const comment = {
            rfpid: id,
            ...inputDiscussion,
            // ...data,
        };
        console.log(comment)
        const data1 = []
        var users = [];
        users.push(...selectedAssignees)
        users.push(RFPDataDetail.createdby)
        console.log(selectedAssignees)
        console.log(users)
        debugger
        for (var i = 0; i < users.length; i++) {
            var task1 = {};
            if (i === users.length - 1) {

                task1 = {
                    ...inputQuestionare,
                    rfpid: props.id,
                    description: "comment added in RFP by",
                    receiverid: RFPDataDetail.createdby,
                    receiverName: RFPDataDetail.createdByName,
                    senderid: userId,
                    senderName: senderName,
                };
            }
            else {

                task1 = {
                    ...inputQuestionare,

                    rfpid: props.id,
                    description: "comment added in RFP by",
                    receiverid: users[i],
                    receiverName: RFPDataDetail.rfpShareList[i].asigneeName,
                    senderid: userId,
                    senderName: senderName,
                };
            }

            data1.push(task1)
        }
        // for notification
        console.log(data1)
        debugger
        if (comment) {
            Service.createorupdate("Task/CreateOrUpdateDiscussion", comment)
                .then(res => {
                    setapiResponseComment(res.data); // Set the API response in state
                    setComments(prevComments => [...prevComments, comment]);
                    getDiscussionData("Task/GetALLRFPDiscussion?rfpId=" + props.id);
                    setCommentInput({ comments: '' });
                    if (data1) {
                        for (var m = 0; m <= data1.length; m++) {
                            console.log(data1[m]);
                            debugger
                            Service.createorupdate("Questionare/CreateOrUpdate", data1[m])
                                .then(res => {
                                    setApiResponse(res.data);
                                })
                                .catch(error => {

                                    console.error("API Error:", error);
                                });
                        }
                        window.location.reload(true);
                    }
                })
                .catch(error => {
                    // Handle error if API call fails
                    console.error("API Error:", error);
                });
        }

    }
    const handleExhibitorNameChange = (evt) => {
        setExhibitorName(evt.target.value);
    }

    const handleVenueChange = (evt) => {
        setVenue(evt.target.value);
    }
    const handleStartDateChange = (evt) => {
        setDateTo(evt.target.value);
    }
    const handleEndDteChange = (evt) => {
        setDateFrom(evt.target.value);
    }
    const handleDesignDeadlineChange = (evt) => {
        setDesignDeadline(evt.target.value);
    }

    const handleIsCompleted = (evt) => {
        setIsCompleted(evt.target.value);
    }
    const handleFloorPlanChange = (evt) => {
        setFloorPlan(evt.target.value);
    }
    const handleGuidelineChange = (evt) => {
        setGuidelines(evt.target.value);
    }
    const handleStallNoChange = (evt) => {
        setStallNo(evt.target.value);
    }
    const handleHallNoChange = (evt) => {
        setHallNo(evt.target.value);
    }
    const handleBudgetChange = (evt) => {
        setBudget(evt.target.value);
    }
    const handleContactNameChange = (evt) => {
        setContactName(evt.target.value);
    }
    const handleFaxChange = (evt) => {
        setFax(evt.target.value);
    }
    const handleEmailChange = (evt) => {
        setEmail(evt.target.value);
    }
    const handlePositionChange = (evt) => {
        setPosition(evt.target.value);
    }
    const handleCompanyNameChange = (evt) => {
        setCompanyName(evt.target.value);
    }
    const handlePhoneChange = (evt) => {
        setPhone(evt.target.value);
    }
    const handleBillingAddressChange = (evt) => {
        setBillingAddress(evt.target.value);
    }
    const handleAnySpecificChange = (evt) => {
        setAnySpecificDetail(evt.target.value);
    }
    const handleWebsiteChange = (evt) => {
        setWebsite(evt.target.value);
    }
    const handleDescriptionChange = (evt) => {
        setDescription(evt.target.value);
    }
    const handleNoofProductChange = (evt) => {
        setNoofProduct(evt.target.value);
    }
    const handleDesignRefrenceChange = (evt) => {
        setDesignRefrence(evt.target.value);
    }
    const handleYourPurposeChange = (evt) => {
        setYourPurpose(evt.target.value);
    }
    const handleDosChange = (evt) => {
        setDos(evt.target.value);
    }

    const handleContentLinkChange = (evt) => {
        setContentLink(evt.target.value);
    }
    const handleDontsChange = (evt) => {
        setDont(evt.target.value);
    }
    const handleBrandGuidelinesChange = (evt) => {
        setBrandGuidelines(evt.target.value);
    }

    const handleLeadChange = (evt) => {
        setLeadId(evt.target.value);
    }

    const externalLink = (data) => {
        debugger
        window.open(data, '_blank')
    }

    const save = (evt) => {
        if (evt.preventDefault && evt) {
            evt.preventDefault();
        }
        const formData = new FormData(evt.target);

        const data = {};
        for (const [key, value] of formData) {
            data[key] = value;
        }

        const {
            name,
            exhibitionName,
            venue, dateTo,
            dateFrom,
            deadlineDate,
            floorplan,
            guidelines,
            stallNo,
            hallNo,
            budget,
            contactName,
            fax,
            email,
            position,
            companyName,
            phone,
            billingAddress,
            anySpecific, website,
            description,
            noofProduct,
            designRefrence,
            yourpurpose,
            brandguidelines,
            dont,
            contentLink,

        } = data;


        const task = {
            id: id,
            ...RFPDataDetail,
            contentLink: contentLink,
        };
        console.log(task)
        debugger
        if (task) {
            Service.createorupdate("RFP/CreateOrUpdate", task)
                .then(res => {
                    setApiResponse(res.data);
                })
                .catch(error => {

                    console.error("API Error:", error);
                    setApiError("Error occurred while saving the meeting. Please try again later.");
                });
        }

    }

    const backbutton = () => {
        props.navigate(-1);
    }


    function printPage() {
        // Add a class to the elements you want to print
        const elementsToPrint = document.querySelectorAll('.printable');

        // Add a class to the elements you want to exclude from printing
        const elementsToExclude = document.querySelectorAll('.non-printable');

        // Hide the elements to exclude from printing
        elementsToExclude.forEach(element => {
            element.style.display = 'none';
        });

        // Print the page
        window.print();

        // Show the elements that were previously hidden
        elementsToExclude.forEach(element => {
            element.style.display = 'block';
        });
    }


    // function formatDate(dateString) {
    //     const dateObject = new Date(dateString);

    //     const day = String(dateObject.getDate()).padStart(2, '0');
    //     const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    //     const year = dateObject.getFullYear();

    //     return `${day}-${month}-${year}`;
    // }

    const handleDiscussionButtonClick = () => {
        setShowDiscussion((prevShowDiscussion) => !prevShowDiscussion);
    };

    function formatDate(date) {
        if (!date) return ""; // Handle null or undefined dates
        const localDate = new Date(date);
        const year = localDate.getFullYear();
        const month = String(localDate.getMonth() + 1).padStart(2, '0');
        const day = String(localDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    function formatDateTime(dateString) {
        const dateObject = new Date(dateString);

        const day = String(dateObject.getDate()).padStart(2, '0');
        const month = String(dateObject.getMonth() + 1).padStart(2, '0');
        const year = dateObject.getFullYear();
        const hours = String(dateObject.getHours()).padStart(2, '0');
        const minutes = String(dateObject.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const humanReadableHours = hours % 12 || 12; // Convert to 12-hour format

        return `${day}-${month}-${year} ${humanReadableHours}:${minutes} ${ampm}`;
    }

    const rfpShareList = RFPDataDetail.rfpShareList;


    if (rfpShareList) {
        const assigneeIdSet = new Set();

        rfpShareList.forEach((item) => {
            const assigneeId = item.assigneeId;
            if (!assigneeIdSet.has(assigneeId)) {
                assigneeIdSet.add(assigneeId);
                // console.log(item.asigneeName);
            }
        });
    }



    return (
        <div>
            <div className="container-fluid">

                <br />
                <div className="card shadow mb-4 non-printable ">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col-sm-3"><h3>RFP Details</h3></div>
                            <div className="col-sm-5"></div>
                            <div className="col-sm-4 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link>
                                <button className="btn btn-warning mt-2 ml-1  text-uppercase " onClick={() => printPage()}><i class="fa fa-download mr-1" aria-hidden="true"></i> Download</button>
                            </div>

                        </div>
                    </div>
                </div>
                <form onSubmit={save}>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 non-printable">
                            <h6 className="m-0 font-weight-bold text-primary">Details</h6>
                            {apiResponse && (
                                <div className="alert alert-success mt-2" role="alert">
                                    RFP has been created successfully!
                                </div>
                            )}
                            {apiError && (
                                <div className="alert alert-danger" role="alert">
                                    {apiError}
                                </div>
                            )}
                        </div>

                        <div className="card-body" style={{ height: "auto" }}>
                            <div className='row'>
                                <div className='col-md-5'>
                                    <div className="form-group row">
                                        {(deptId === "2" || deptId === "3") && <div className='col-sm-12 required-label'><b>Lead</b></div>}
                                        {deptId === "4" && <div className='col-sm-12 required-label'><b>Project</b></div>}
                                        <div className='col-sm-12'>
                                            <select className="form-control form-control-user" readOnly value={RFPDataDetail.leadid} name="leadid" required>
                                                <option>[NONE]</option>
                                                {
                                                    leadData.map((e, key) => {
                                                        //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;
                                                        return <option key={e.id} value={e.leadid}>{e.leadid}</option>;
                                                    })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row ">
                                        <div className='col-sm-12 '><b>Exhibhition Name</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" readOnly id="headline"
                                            name="name" placeholder="enter exhibhition name" value={exhibitorName} onChange={handleExhibitorNameChange} /></div>
                                    </div>
                                    <div className="form-group row d-flex">
                                        <div className='col-sm-3 mt-1'><b>Show Start Date - </b></div>
                                        {/* <div className='col-sm-2'> */}
                                        <input type="date" className="form-control col-sm-3 mb-1 form-control-user" readOnly id="headline" name="dateFrom" placeholder="enter" onChange={handleStartDateChange} value={formatDate(dateFrom)} />
                                        {/* </div> */}
                                        <div className='col-sm-3 mt-1 '><b>Show End Date -  </b></div>
                                        {/* <div className='col-sm-4'> */}
                                        <input type="date" className="form-control col-sm-3 mb-1  form-control-user" readOnly id="headline" name="dateTo" placeholder="Date To" onChange={handleEndDteChange} value={formatDate(dateTo)} />
                                        {/* </div> */}
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Design Deadline</b></div>
                                        <div className='col-sm-12'><input type="date" className="form-control form-control-user" readOnly id="headline"
                                            name="deadlineDate" placeholder="Enter Design Deadline" onChange={handleDesignDeadlineChange} value={formatDate(designDeadlineDate)} /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Floor Plan</b></div>
                                        <div className='col-sm-12'><TextareaAutosize
                                            // rows={3} // You can adjust the number of visible rows
                                            cols={100}
                                            value={floorplan}

                                            readOnly
                                            name='floorplan'
                                            placeholder='enter floor plan' // You can adjust the number of visible columns
                                            class="form-control size1"
                                            autofocus
                                        /></div>
                                    </div>
                                    <div className="form-group non-printable row">
                                        <div className='btn btn-sm ml-3 btn-info' onClick={() => externalLink(floorplan)} >Open FloorPlan Link</div>
                                    </div>
                                    <h5>Contact Info</h5>
                                    <div className="form-group row">
                                        <div className='col-sm-12 -label'><b>Contact Name</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" readOnly id="headline"
                                            name="contactName" placeholder="enter contact name" onChange={handleContactNameChange} value={contactName} /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 -label'><b>Position</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" readOnly id="headline"
                                            name="position" placeholder="enter position" onChange={handlePositionChange} value={position} /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 -label'><b>Company Name</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" readOnly id="headline"
                                            name="contactName" placeholder="enter company name" onChange={handleCompanyNameChange} value={companyName} /></div>
                                    </div>

                                    <div className="form-group row">
                                        <div className='col-sm-12 -label'><b>Phone</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" readOnly id="headline"
                                            name="phone" placeholder="enter phone" onChange={handlePhoneChange} value={phone} /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 -label'><b>Web Address</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" readOnly id="headline"
                                            name="website" placeholder="http://example.com" onChange={handleWebsiteChange} value={website} /></div>
                                    </div>
                                    <div className="form-group non-printable row">
                                        <div className='btn btn-sm ml-3 btn-info' onClick={() => externalLink(website)} >Open Website Link</div>
                                    </div>
                                    <h5>Product display</h5>
                                    <div className="form-group row">
                                        <div className='col-sm-12 -label'><b>Number of Products to Display If Any</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" readOnly id="headline"
                                            name="noofProduct" placeholder="enter number of products" onChange={handleNoofProductChange} value={noofProduct} /></div>
                                    </div>
                                    <h6>Note : We need product LXWXH with drawing and photo if possible., you can attached product photo and drawing seperately</h6>

                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Your Purpose</b></div>
                                        <div className='col-sm-12'><TextareaAutosize
                                            rows={3} // You can adjust the number of visible rows
                                            cols={100}
                                            name='yourpurpose'
                                            value={yourpurpose}
                                            readOnly
                                            onChange={handleYourPurposeChange}
                                            placeholder='Please elaborate your purpose by providing examples of stalls you like will help us understand your taste better and also the style you are looking for.' // You can adjust the number of visible columns
                                            class="form-control size1"
                                            autofocus
                                        /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12'><b>Do's</b></div>
                                        <div className='col-sm-12'><TextareaAutosize
                                            rows={3} // You can adjust the number of visible rows
                                            cols={100}
                                            name='do'
                                            value={dos}
                                            readOnly
                                            onChange={handleDosChange}
                                            placeholder="enter Do's /optional" // You can adjust the number of visible columns
                                            class="form-control size1"
                                            autofocus
                                        /></div>
                                    </div>

                                    <div className="form-group row">
                                        <div className='col-sm-12'><b>Dont's</b></div>
                                        <div className='col-sm-12'><TextareaAutosize
                                            rows={3} // You can adjust the number of visible rows
                                            cols={100}
                                            name='dont'
                                            value={dont}
                                            readOnly
                                            onChange={handleDontsChange}
                                            placeholder="enter Dont's /optional" // You can adjust the number of visible columns
                                            class="form-control size1"
                                            autofocus
                                        /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12'><b>Final Design Link</b></div>
                                        <div className='col-sm-12'><TextareaAutosize
                                            rows={3} // You can adjust the number of visible rows
                                            cols={100}
                                            // readOnly={detailFlag}
                                            readOnly
                                            value={finalLink}
                                            name='contentLink'
                                            placeholder="enter additional attachment Link if any" // You can adjust the number of visible columns
                                            class="form-control size1"
                                            autofocus
                                        /></div>
                                    </div>

                                    <div className="form-group row non-printable">
                                        <div className='btn btn-sm ml-3 btn-info' onClick={() => externalLink(finalLink)} >Open Final Design Link</div>
                                    </div>

                                    {apiSharedResponse && (
                                        <div className="alert alert-success col-sm-7" role="alert">
                                            RFP has been Shared successfully!
                                        </div>
                                    )}

                                    {/* {(userId === "25" || userId === "53") && <button className="btn btn-primary text-uppercase non-printable mt-2" type='submit'>Update</button>} */}
                                    <button type="button" class="btn btn-primary ml-1 mt-2 non-printable" data-toggle="modal" data-target="#exampleModalCenter">
                                        <i class="fa fa-share-alt mr-1"></i>share RFP
                                    </button>

                                    <div class="modal fade " id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                        <div class="modal-dialog modal-dialog-centered" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="exampleModalLongTitle">Share RFP</h5>
                                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <Select
                                                        name="assigneeid"
                                                        isMulti={true}
                                                        placeholder="Enter members to share with"
                                                        options={userName.map((user) => ({
                                                            value: user.id,
                                                            label: `${user.firstname} ${user.lastname}`,
                                                        }))}
                                                        value={selectedAssignees.value}
                                                        onChange={handleSelectChange}
                                                        onClick={() => setShowAssigneePopup(false)}
                                                    />
                                                </div>

                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                                    <button type='submit' onClick={shareData} data-dismiss="modal" class="btn btn-primary">share</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {showAssigneePopup && (
                                        <>
                                            <Select
                                                name="assigneeid"
                                                isMulti={true}
                                                placeholder="Enter members to share with"
                                                options={userName.map((user) => ({
                                                    value: user.id,
                                                    label: `${user.firstname} ${user.lastname}`,
                                                }))}
                                                value={selectedAssignees.value}
                                                onChange={handleSelectChange}
                                                onClick={() => setShowAssigneePopup(false)}
                                            />
                                            <button className="close-popup-btn" onClick={() => setShowAssigneePopup(false)}>
                                                Close popup
                                            </button>
                                        </>
                                    )}


                                </div>
                                <div className='col-md-1'></div>
                                <div className='col-md-5'>
                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Venue/Place/City</b></div>
                                        <div className='col-sm-12'><TextareaAutosize
                                            rows={1} // You can adjust the number of visible rows
                                            cols={100}
                                            readOnly
                                            name='venue'
                                            value={venue}
                                            onChange={handleVenueChange}
                                            placeholder='enter Venue/Place/City' // You can adjust the number of visible columns
                                            class="form-control size1"
                                            autofocus
                                        /></div>
                                    </div>
                                    {/* <div className="form-group row">
                                        <div className='col-sm-12 '>Start Date</div>
                                        <div className='col-sm-12'><input type="date" className="form-control form-control-user" readOnly id="headline"
                                            name="dateFrom" placeholder="enter" onChange={handleStartDateChange} value={formatDate(dateFrom)} /></div>
                                    </div> */}
                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Exhibhition Guidelines</b></div>
                                        <div className='col-sm-12'><TextareaAutosize
                                            rows={3} // You can adjust the number of visible rows
                                            cols={100}
                                            name='guidelines'
                                            value={guidelines}
                                            readOnly
                                            onChange={handleGuidelineChange}
                                            placeholder='enter guidelines details ' // You can adjust the number of visible columns
                                            class="form-control size1"
                                            autofocus
                                        /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='btn btn-sm ml-3 non-printable btn-info' onClick={() => externalLink(guidelines)} >Open Guidelines Link</div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Stall No</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" readOnly id="headline"
                                            name="stallNo" placeholder="enter stall number" onChange={handleStallNoChange} value={stallNo} /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Hall No.</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" readOnly id="headline"
                                            name="hallNo" placeholder="enter hall number" onChange={handleHallNoChange} value={hallNo} /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 -label'><b>Budget</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" readOnly id="headline"
                                            name="budget" placeholder="enter budget" onChange={handleBudgetChange} value={budget} /></div>
                                    </div>
                                    <hr />

                                    {/* <div className="form-group row">
                                        <div className='col-sm-12 -label'>Fax</div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" readOnly id="headline"
                                            name="fax" placeholder="enter fax" onChange={handleFaxChange} value={fax} /></div>
                                    </div> */}
                                    <div className="form-group row" hidden={true}>
                                        <div className='col-sm-12 -label '><b>Email</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" readOnly id="headline"
                                            name="email" placeholder="enter email address" onChange={handleEmailChange} value={email} /></div>
                                    </div>
                                    <div className="form-group row" hidden={true}>
                                        <div className='col-sm-12 -label'><b>Billing Address</b></div>
                                        <div className='col-sm-12'><TextareaAutosize
                                            rows={2} // You can adjust the number of visible rows
                                            cols={100}
                                            name='billingAddress'
                                            value={billingAddress}
                                            readOnly
                                            onChange={handleBillingAddressChange}
                                            placeholder='enter billing address' // You can adjust the number of visible columns
                                            class="form-control size1"
                                            autofocus
                                        /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Any Specific Need</b></div>
                                        <div className='col-sm-12'><TextareaAutosize
                                            rows={2} // You can adjust the number of visible rows
                                            cols={100}
                                            name='anySpecific'
                                            value={anySpecificDetail}
                                            readOnly
                                            onChange={handleAnySpecificChange}
                                            placeholder='enter billing address ' // You can adjust the number of visible columns
                                            class="form-control size1"
                                            autofocus
                                        /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 -label'><b>Description If Any</b></div>
                                        <div className='col-sm-12'><TextareaAutosize
                                            rows={2} // You can adjust the number of visible rows
                                            cols={100}
                                            name='description'
                                            value={description}
                                            readOnly
                                            onChange={handleDescriptionChange}
                                            placeholder='enter description' // You can adjust the number of visible columns
                                            class="form-control size1"
                                            autofocus
                                        /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Design Refrence</b></div>
                                        <div className='col-sm-12'><TextareaAutosize
                                            rows={3} // You can adjust the number of visible rows
                                            cols={100}
                                            name='designRefrence'
                                            value={designRefrence}
                                            readOnly
                                            onChange={handleDesignRefrenceChange}
                                            placeholder='Please give design refrence if any' // You can adjust the number of visible columns
                                            class="form-control size1"
                                            autofocus
                                        /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Brand Guidelines</b></div>
                                        <div className='col-sm-12'><TextareaAutosize
                                            rows={3} // You can adjust the number of visible rows
                                            cols={100}
                                            name='brandguidelines'
                                            value={brandguidelines}
                                            readOnly
                                            onChange={handleBrandGuidelinesChange}
                                            placeholder='brand guidelines if any' // You can adjust the number of visible columns
                                            class="form-control size1"
                                            autofocus
                                        /></div>
                                    </div>

                                    <div className="form-group row">
                                        <div className='col-sm-12'><b>Additional Attachments Link</b></div>
                                        <div className='col-sm-12'><TextareaAutosize
                                            rows={3} // You can adjust the number of visible rows
                                            cols={100}
                                            // readOnly={detailFlag}
                                            readOnly
                                            onChange={handleContentLinkChange}
                                            value={contentLink}
                                            name='contentLink'
                                            placeholder="enter additional attachment Link if any" // You can adjust the number of visible columns
                                            class="form-control size1"
                                            autofocus
                                        /></div>
                                    </div>

                                    <div className="form-group row non-printable">
                                        <div className='btn btn-sm ml-3 btn-info' onClick={() => externalLink(contentLink)} >Open Additional Link</div>
                                    </div>



                                    <div class="form-group ">
                                        <label class="col-sm-6   control-label" for="flexCheckDefault">
                                            Is RFP Completed
                                        </label>
                                        <b>{isCompleted}</b>
                                    </div>

                                    <h5 className='non-printable'>People</h5>

                                    <div className="form-group form-group-h row non-printable">
                                        <div className='col-sm-5'><b>Author - </b></div>
                                        <div className='col-sm-7'>
                                            <b>{RFPDataDetail.createdByName}</b>
                                        </div>

                                    </div>
                                    <div className="form-group form-group-h row non-printable">
                                        <div className="col-sm-5 "><b>Shared with</b></div>
                                        <div className="col-sm-5">
                                            {RFPDataDetail.rfpShareList
                                                ? [...new Set(RFPDataDetail.rfpShareList.map(item => item.asigneeName))]
                                                    .map((name, index) => (
                                                        <b key={index}>{name}, </b>
                                                    ))
                                                : null
                                            }
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </form>


                <div className="card shadow mb-4 non-printable">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col-sm-9">
                                <div className="col-sm-6"></div>
                                <button className="btn btn-primary mr-2" onClick={handleDiscussionButtonClick}>
                                    Discussion
                                </button>

                            </div>
                        </div>
                    </div>

                    <div className="card-header py-3">
                        {apiResponseComment && (
                            <div className="alert alert-success" role="alert">
                                Comment Done successfully..!!
                            </div>
                        )}
                        <div className="row d-flex flex-column">
                            <div class="card-body" style={{ display: "flex" }}>
                                <img class="rounded-circle shadow-1-strong mt-3 me-3"
                                    src="https://w7.pngwing.com/pngs/184/113/png-transparent-user-profile-computer-icons-profile-heroes-black-silhouette-thumbnail.png" alt="avatar" width="45"
                                    height="45" />
                                <form onSubmit={submitDiscussion} className='card-body' style={{ display: "flex" }}>

                                    <div className='col-sm-11  '>
                                        <MentionsInput className="form-control  form-control-user"
                                            id="comment"
                                            name="comments"
                                            style={defaultStyle}
                                            placeholder="Enter Your Message" 
                                            // value={commentInput.comments}
                                            value={inputDiscussion.comments}
                                            onChange={(e) => setInputDiscussion({ ...inputDiscussion, comments: e.target.value })}>
                                            <Mention data={userNameData} style={defaultMentionStyle} />
                                        </MentionsInput>
                                    </div>

                                    <div className='col-sm-0'>
                                        <button type='submit' className="btn btn-primary mr-3">comment</button>
                                    </div>
                                </form>
                                <div className='col-sm-0'></div>
                            </div>
                            {comments.map((messageData) => (
                                <div class="card-body mt-1 card-body1">
                                    <div class="d-flex flex-start justify-content-between align-items-center">
                                        <div className='d-flex'>

                                            <img class="rounded-circle shadow-1-strong me-3"
                                                src="https://w7.pngwing.com/pngs/184/113/png-transparent-user-profile-computer-icons-profile-heroes-black-silhouette-thumbnail.png" alt="avatar" width="45"
                                                height="45" />
                                            <p class="mb-0 mt-2 pl-3 pb-1">
                                                {messageData.comments}
                                            </p>
                                        </div>
                                        <div class="small d-flex flex-row-reverse justify-content-start pr-5">
                                            <div className='d-flex'>
                                                <p class="text-muted font-weight-bold  ml-2 ">
                                                    Date-{formatDateTime(messageData.createdDate)}
                                                </p>
                                                <h6 class="font-weight-bold text-primary ml-2 ">{messageData.userName} </h6>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default RFPDetailsComponent