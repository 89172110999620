import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import Service from "../../../Services/Admin/Service";
import React, { useState, useEffect } from 'react'
import { formatDate } from '../../../Helpers/supportedFunctions/supportedGlobalFunctions';

const ProjectwiseExecutionTrackerListComponent = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getCategoryData("ProjectExecutionNew/GetAll");
  }, [])

  const getCategoryData = (method) => {
    Service.getdata(method).then((res) => {
      setData(res.data);
    });
  }

  const staticname1 = "details";
  const staticname2 = "edit";


  const backbutton = () => {
    props.navigate(-1);
  }


  const formatDateTime = (cell, row) => {
    // Format the date and time here, assuming 'time' is a string in ISO 8601 format
    const formattedDate = new Date(cell).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',

    });
    return formattedDate;
  }
  const linkformatter = (data, row) => {
    return <>
     
      {/* &nbsp; <Link className="btn btn-warning btn-sm" to={`/AddProject/${data}&${staticname2}`}>Edit</Link> */}

      <Link className="btn btn-warning btn-sm m-1" to={`/CreateProjectExecution/${data}&${staticname1}`}>Details</Link>
      <Link className="btn btn-warning btn-sm m-1" to={`/CreateProjectExecution/${data}&${staticname2}`}>Edit</Link>

    </>
  }

  const column = [
    { dataField: 'exhibitionName', text: 'Project Name', sort: true },
    { dataField: 'accountManagerName', text: 'Account Manager', sort: true },
    { dataField: 'projectManagerName', text: 'Project Manager', sort: true },
    { dataField: 'exhibitionStartDate', text: 'Show Start', sort: true, formatter: formatDate },
    { dataField: 'exhibitionEndDate', text: 'Show End', sort: true, formatter: formatDate },
    // { dataField: 'projectManagerName', text: 'Project Manager', sort: true },
    { dataField: 'id', text: 'Actions', formatter: linkformatter }
  ]

  return (
    <div className="container-fluid">
      <div className="card shadow border-success mb-2">
        <div className="card-header rounded py-3">
          <div className="row">
            <div className="col-sm-7"><h3><b className='text-dark'>Project Execution Planning List</b></h3></div>
            <div className="col-sm-2"></div>
            <div className="col-sm-3 d-flex flex-row-reverse">
              <button className="btn btn-info  ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
              {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link> */}
            </div>
          </div>
        </div>
      </div>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-success">Execution Planning List</h6>
          {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
        </div>

        <div className="card-body">
          <div className="table-responsive">
            <BootstrapTable keyField="id"
              data={data}
              columns={column}
              pagination={paginationFactory()}
            // filter={filterFactory()}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectwiseExecutionTrackerListComponent