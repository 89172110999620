import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import ExtractedDataListComponent from '../../../Components/Main/ExtractedDataComponent/ExtractedDataListComponent';

function ExtractedDataList(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <ExtractedDataListComponent {...props} navigate={navigate}  />
        </div>
    );  
  }
export default ExtractedDataList  