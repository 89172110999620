import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CustomerService from '../../../Services/Admin/Service';
import { useDropzone } from 'react-dropzone';
import Service from '../../../Services/Admin/Service';
import {
  externalLink,
  formatDate,
  getLoginUserData,
} from '../../../Helpers/supportedFunctions/supportedGlobalFunctions';


const EditRFPComponent = (props) => {


  //state variable start 
  const [loading, setLoading] = useState(false); // New state for loading
  const [loadingProject, setLoadingProject] = useState(false); // New state for loading
  const [dropdownUser, setdropdownUser] = useState([]);
  // const [accountManager, setAccountManager] = useState([]);
  const [finalDesigner, setFinalDesigner] = useState([]);
  const [finalDesignerUser, setFinalDesignerUser] = useState([]);
  const [apiError, setApiError] = useState(null);
  const [projectError, setProjectError] = useState(null);
  const [apiResponse, setApiResponse] = useState(null);
  const [projectApiResponse, setProjectApiResponse] = useState(null);
  const [isConvertedDetail, setIsConvertedDetail] = useState(false);
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [allFilesData, setAllFilesData] = useState([]);
  const [leadData, setLeadData] = useState([]);
  // project data
  const [inputProject, setInputProject] = useState({
    leadid: '',
    projectname: '',
    startdate: '',
    enddate: '',
    createdby: getLoginUserData().userId,
    lstTeam: [],
  })
  // notification
  const [inputQuestionare, setInputQuestionare] = useState({
    taskid: 0,
    description: "",
    senderid: 0,
    senderName: "",
    receiverid: 0,
    receiverName: "",
    isRead: false,
    createdby: getLoginUserData().userId,
  })

  // end notification
  const [rfpInputField, setRfpInputField] = useState({
    name: "",
    leadid: "",
    leadName: "",
    exhibitionName: "",
    venue: "",
    dateTo: "",
    finalDesignerId: 0,
    finalDesignerName: "",
    dateFrom: "",
    deadlineDate: "",
    floorplan: "",
    guidelines: "",
    stallNo: "",
    hallNo: "",
    budget: "",
    contactName: "",
    email: "",
    position: "",
    companyName: "",
    phone: "",
    billingAddress: "",
    anySpecific: "",
    website: "",
    description: "",
    noofProduct: "",
    designRefrence: "",
    yourpurpose: "",
    brandguidelines: "",
    do: "",
    dont: "",
    updatedby: getLoginUserData().userId,
    createdby: getLoginUserData().userId,

  })

  // state variable end
  // successs and error message 
  useEffect(() => {
    if (apiResponse || apiError) {
      const timeoutId = setTimeout(removeSuccessMessage, 3000);
      return () => clearTimeout(timeoutId);
    }
    if (projectApiResponse) {
      const timeoutId = setTimeout(removeSuccessMessage, 3000);
      return () => clearTimeout(timeoutId);
    }

  }, [apiResponse, apiError, projectApiResponse]);

  const removeSuccessMessage = (props) => {
    setApiResponse(null);
    setApiError(null);
    setProjectError(null);
  };
  // end successs and error message 

  useEffect(() => {
    getRFPInfo("RFP/GetById?id=" + props.id)
    getUserData("Users/GetAll");
    if (getLoginUserData().role === "Admin") {
      getLeadData("Lead/GetAllLead");
    }
    else {

      getLeadData("Lead/GetAll?userId=" + getLoginUserData().userId);
    }
    updatenotification("Questionare/readnotification?rfpid=" + props.id + "&userid=" + getLoginUserData().userId)

  }, [])

  const getUserData = (method) => {
    Service.getdata(method).then((res) => {
      if (res !== undefined) {

        setdropdownUser(res.data);
        var a = res.data
        var list = [];

        a.forEach((d, index) => {
          var role = d.lstRole;
          // var checkdepartment = role.find(x => x.depId === 2 || x.depId === 4)
          var checkdepartment = role.find(x => x.depId === 3)
          if (checkdepartment !== undefined) {
            d.rolename = d.lstRole[0].name;
            list.push(d);
          }
        });
        setFinalDesigner(list);
      }
    });
  };

  const getRFPInfo = (method) => {
    Service.getdata(method).then((res) => {
      if (res !== undefined) {

        setRfpInputField(res.data);
        setIsConvertedDetail(res.data.isConvertedProject);
        setAllFilesData(res.data.allfiles);
        // var assignee = res.data.rfpShareList.map((x) => x.assigneeId)
        const arr = [];

        if (res.data.finalDesignerId === null || res.data.finalDesignerId === 0) {

          setFinalDesignerUser(null);
        }
        else {
          arr.push({ value: res.data.finalDesignerId, label: res.data.finalDesignerName });
          setFinalDesignerUser(arr);
        }
        console.log(res.data)

      }
    });
  }

  const updatenotification = (method) => {
    Service.getdata(method).then((res) => {

    });
  }

  const handleIsConvertedChange = (e) => {

    if (isConvertedDetail === true) {
      setIsConvertedDetail(false);
    }
    else {
      setIsConvertedDetail(true)
    }
  }

  const inputsRfpHandler = (e) => {
    //  setInputField( {[e.target.name]: e.target.value} )
    const { name, value } = e.target;

    setRfpInputField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }



  const getLeadData = (method) => {
    Service.getdata(method).then((res) => {
      if (res !== undefined) {

        var filteredData = res.data.filter((x) => x.isConvincing !== true && x.createRFP === true)
        setLeadData(res.data);

        console.log(res.data)
      }

    })
  };

  // function extractValuesFromIndexedObject(indexedObject) {
  //   return Object.values(indexedObject).map(item => item.value);
  // }

  // const handlefinalDesignUserChange = (selectedOptions) => {
  //   // const selectedAssigneeIds = selectedOptions.map((option) => option.value);
  //   // const selectedAssigneeEmails = selectedOptions.map((option) => option.email);
  //   setFinalDesignerUser(selectedOptions);
  //   
  // };

  // const handleSelectRFPUserChange = (selectedOptions) => {

  //   const extractedValues = extractValuesFromIndexedObject(selectedOptions);
  //   setSelectedRFPAssignees(extractedValues);
  // };

  const autoProjectCreation = (evt) => {
    evt.preventDefault();
    setLoadingProject(true);
    var arr = [];

    if (getLoginUserData().userId !== null || getLoginUserData().userId !== undefined) {
      selectedAssignees.forEach((e, index) => {
        var lstTeam = {};
        lstTeam.id = 0;
        lstTeam.userId = e.value;
        lstTeam.createdby = getLoginUserData().userId;
        lstTeam.updatedby = getLoginUserData().userId;
        // setlstTeam(lstTeam);
        arr.push(lstTeam);

      })
      var lstTeam = {};
      lstTeam.id = 0;
      lstTeam.userId = 1;
      lstTeam.createdby = getLoginUserData().userId;
      lstTeam.updatedby = getLoginUserData().userId;
      arr.push(lstTeam);

      var lstTeam1 = {};
      lstTeam1.id = 0;
      lstTeam1.userId = 3;
      lstTeam1.createdby = getLoginUserData().userId;
      lstTeam1.updatedby = getLoginUserData().userId;
      arr.push(lstTeam1);

      var lstTeam2 = {};
      lstTeam2.id = 0;
      lstTeam2.userId = 47;
      lstTeam2.createdby = getLoginUserData().userId;
      lstTeam2.updatedby = getLoginUserData().userId;
      arr.push(lstTeam2);

      var lstTeam3 = {};
      lstTeam3.id = 0;
      lstTeam3.userId = 56;
      lstTeam3.createdby = getLoginUserData().userId;
      lstTeam3.updatedby = getLoginUserData().userId;
      arr.push(lstTeam3);

      var lstTeam4 = {};
      lstTeam4.id = 0;
      lstTeam4.userId = getLoginUserData().userId;
      lstTeam4.createdby = getLoginUserData().userId;
      lstTeam4.updatedby = getLoginUserData().userId;
      arr.push(lstTeam4);

      var projectData = {
        ...inputProject,
        startdate: formatDate(rfpInputField.dateFrom),
        enddate: formatDate(rfpInputField.dateTo),
        leadid: rfpInputField.leadid,
        projectname: rfpInputField.leadid.slice(5),
        lstTeam: arr,
      }
      console.log(projectData);
      //   const apis = ApiMethod.find(o => o.name === 'Category');
      if (projectData) {
        Service.createorupdate("Project/CreateOrUpdate", projectData)
          .then(res => {
            if (res.status === 200) {
              setLoadingProject(false);
              setProjectApiResponse(res.data);
              // Set the API response in state
              window.location.href = "/ProjectList";
            }
            else {
              alert("project data is wrong please try again later")
            }
          })
          .catch(error => {
            // Handle error if API call fails
            console.error("API Error:", error);
            setProjectError("Error occurred while saving the project. Please try again later.");
          });
      }
    }
    else {
      alert("something went wrong login again");
    }
  }

  const saveRfp = (evt) => {
    evt.preventDefault();
    var finalDesigner = [];
    var finalDesignerName = [];
    if (finalDesignerUser !== null) {
      finalDesigner = { ...finalDesignerUser.slice(0, 1).map((x) => x.value) };

      finalDesignerName = { ...finalDesignerUser.slice(0, 1).map((x) => x.label) };
    }


    var leadInfo = leadData.filter((e) => e.leadid === rfpInputField.leadid);

    const rfpData = {
      ...rfpInputField,
      finalDesignerId: finalDesigner[0],
      finalDesignerName: finalDesignerName[0],
      exhibitionAttachment: (/true/).test(rfpInputField.exhibitionAttachment),
      floorplanAttachment: (/true/).test(rfpInputField.floorplanAttachment),
      brandAttachemnt: (/true/).test(rfpInputField.brandAttachemnt),
      briefLinkAttachment: (/true/).test(rfpInputField.briefLinkAttachment),
      isConvertedProject: isConvertedDetail,
      name: leadInfo[0].id.toString(),
    }
    console.log(rfpData);

    //notification
    const data1 = []
    var users = [];
    rfpInputField.rfpShareList.map((e) => users.push(e.assigneeId));
    users.push(rfpInputField.createdby)
    console.log(selectedAssignees)
    console.log(users)

    for (var i = 0; i < users.length; i++) {
      var task1 = {};
      if (i === users.length - 1) {
        task1 = {
          ...inputQuestionare,
          rfpid: props.id,
          description: "comment added in RFP by",
          receiverid: rfpInputField.createdby,
          receiverName: rfpInputField.createdByName,
          senderid: getLoginUserData().userId,
          senderName: getLoginUserData().name,
        };
      }
      else {

        task1 = {
          ...inputQuestionare,

          rfpid: props.id,
          description: "RFP Edited by",
          receiverid: users[i],
          receiverName: rfpInputField.rfpShareList[i].asigneeName,
          senderid: getLoginUserData().userId,
          senderName: getLoginUserData().name,
        };
      }

      data1.push(task1)
    }

    console.log(data1)

    // finish notification data

    if (rfpData) {
      Service.createorupdate("RFP/CreateOrUpdate", rfpData)
        .then(res => {
          setApiResponse(res.data);
          if (data1) {
            for (var m = 0; m <= data1.length; m++) {


              Service.createorupdate("Questionare/CreateOrUpdate", data1[m])
                .then(res => {
                  if (res.data) {
                    setLoading(true);
                  }
                  setApiResponse(res.data);
                })
                .catch(error => {

                  console.error("API Error:", error);
                });
            }
            window.location.reload(true);
          }
          // shareData();
          // window.location.href = "/RFPList";
          // Set the API response in state
        })
        .catch(error => {
          // Handle error if API call fails
          console.error("API Error:", error);
          setApiError("Error occurred while saving the RFP. Please try again later.");
        });
    }
  }

  const backbutton = () => {
    props.navigate(-1);
  }

  // ===============================================================>
  const [uploadProgress, setUploadProgress] = useState(0);
  const [files, setFiles] = useState([]);
  const [uploadError, setUploadError] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [inputFileData, setinputFileData] = useState({
    typeId: 0,
    fileType: "RFP",
    fileName: "",
    createdby: getLoginUserData().userId,
  })

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles);
      setUploadError(null);  // Reset error state when new files are added
      setUploadSuccess(false);
      // Reset success state when new files are added
    }
  });

  const handleUpload = async () => {
    if (files.length === 0) {
      setUploadError('No files selected for upload');
      return;
    }

    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
    });

    const data = {
      ...inputFileData,
      typeId: rfpInputField.id,
      fileName: files[0].name,
    };

    try {
      debugger
      if (data) {
        const response = await CustomerService.createorupdateFile("FileUpload/UploadFile", formData, (progress) => {
          setUploadProgress(progress);
          if(progress === 100){
          
          }
        });
        console.log("File upload response:", response);
        debugger;
        if (response.status === 200) {
          const updateResponse = await CustomerService.createorupdate("FileUpload/CreateOrUpdate", data);
          debugger
          if (updateResponse.status === 200) {
            alert("File uploaded successfully");
            setinputFileData({
              typeId: 0,
              fileType: "RFP",
              fileName: "",
              createdby: getLoginUserData().userId,
            });
            window.location.reload();
            setUploadSuccess(true);
          } else {
            alert("File uploaded successfully but table not created");
          }
        } else {
          alert("File uploaded successfully but table not created");
        }
      }
    } catch (error) {
      debugger
      console.error("API Error during file upload:", error);
      setUploadError("File upload failed");
    }
  };


  const handleDownload = (file) => {

    const fileName = file;

    // Change to the name of the file you want to download
    Service.downloadFile("FileUpload/download/", fileName)
      .then(res => {

        const url = window.URL.createObjectURL(new Blob([res.data], { type: res.data.type }));

        if (res.data.type === 'application/pdf') {
          window.open(url, '_blank');
        }
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(error => {
        // Handle API error, if necessary
        console.error("API Error:", error);

      });
  };

  //    ============================================================> 

  return (
    <div>
      <div className="container-fluid">

        <br />
        <div className="card shadow border-success mb-2">
          <div className="card-header rounded py-3">
            <div className="row">
              <div className="col-sm-7 row">
                <h3 className='mt-2'><b className='text-dark'>Edit RFP Details</b></h3>
                <div className="col-sm-5">

                </div>
              </div>
              <div className="col-sm-1"></div>
              <div className="col-sm-4 d-flex flex-row-reverse">
                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
              </div>

            </div>
          </div>
        </div>


        <div className="card shadow mb-4">
          <div className="card-header d-flex py-3">
            <h6 className="m-0 mt-1 font-weight-bold text-success ">RFP Details  </h6>

          </div>

          {/* 1st part */}
          <form onSubmit={saveRfp}>
            <div className="card-body" style={{ height: "auto" }}>
              <div className="row text-dark">
                <div className="col-md-5 ml-5">

                  <>
                    <div className="form-group row">
                      <div className='col-sm-12 required-label'><b>Lead</b></div>

                      <div className='col-sm-12'>
                        <select className="form-control  form-control-user" name="leadid" value={rfpInputField.leadid} onChange={inputsRfpHandler} >
                          <option>[NONE]</option>
                          {
                            leadData.map((e, key) => {
                              //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;
                              return <option key={e.id} value={e.leadid}>{e.leadid}</option>;
                            })}
                        </select>
                      </div>
                    </div>
                  </>


                  <div className="form-group row">
                    <div className='col-sm-12 required-label'><b>Exhibhitor Name</b></div>
                    <div className='col-sm-12'>
                      <input type="text" className="form-control form-control-user" name="exhibitionName" placeholder="enter exhibhitor name" value={rfpInputField.exhibitionName} onChange={inputsRfpHandler} />
                    </div>
                  </div>

                  <div className="form-group row d-flex">
                    <div className='col-sm-3 mt-1 required-label'><b>Show Start Date</b></div>

                    <input type="date" className="form-control col-sm-3 mb-1 form-control-user" name="dateFrom" placeholder="enter" value={formatDate(rfpInputField.dateFrom)} onChange={inputsRfpHandler} />

                    <div className='col-sm-3 mt-1 required-label'><b> Show End&nbsp; Date</b>   </div>

                    <input type="date" className="form-control col-sm-3 mb-1  form-control-user" name="dateTo" placeholder="Date To" value={formatDate(rfpInputField.dateTo)} onChange={inputsRfpHandler} />
                  </div>

                  <h5>POC Info</h5>
                  <div className="form-group row">
                    <div className='col-sm-4  mt-2 required-label'><b>Contact Name</b></div>
                    <div className='col-sm-8'>
                      <input type="text" className="form-control form-control-user"
                        name="contactName" placeholder="enter Contact Name" value={rfpInputField.contactName} onChange={inputsRfpHandler} required />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-4  mt-2 required-label'><b>Position</b></div>
                    <div className='col-sm-8'>
                      <input type="text" className="form-control form-control-user"
                        name="position" placeholder="enter Position Name" value={rfpInputField.position} onChange={inputsRfpHandler} required />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-12'><b>Do's</b></div>
                    <div className='col-sm-12'><textarea
                      rows={3} // You can adjust the number of visible rows
                      cols={100}
                      name='do'
                      value={rfpInputField.do}
                      placeholder="enter Do's /optional" // You can adjust the number of visible columns
                      class="form-control"
                      autofocus
                      onChange={inputsRfpHandler}

                    /></div>
                  </div>

                </div>

                <div className='col-md-1'></div>

                <div className="col-md-5">

                  <div className="form-group row">
                    <div className='col-sm-4 required-label'><b>Venue/Place/City</b></div>
                    <div className='col-sm-8'>
                      <input type="text" className="form-control form-control-user"
                        name="venue" placeholder="enter Venue" value={rfpInputField.venue} onChange={inputsRfpHandler} required />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-4 mt-2'><b>Budget</b></div>
                    <div className='col-sm-8'><input type="text" className="form-control form-control-user" onChange={inputsRfpHandler}
                      name="budget" placeholder="enter budget" value={rfpInputField.budget} /></div>
                  </div>

                  <div className="form-group row">
                    {/* {deadlineError && <div className="alert alert-danger mt-3" role="alert">Please select a design Deadline.</div>} */}
                    <div className='col-sm-4 required-label'><b>Design Deadline</b></div>
                    <div className='col-sm-8'><input type="date" className="form-control form-control-user" onChange={inputsRfpHandler}
                      name="deadlineDate" placeholder="Enter Design Deadline" value={formatDate(rfpInputField.deadlineDate)} /></div>
                  </div>
                  <div className="form-group row">
                    <div className='col-sm-4  mt-2 required-label'><b>Company Name</b></div>
                    <div className='col-sm-8'>
                      <input type="text" className="form-control form-control-user"
                        name="companyName" placeholder="enter Company Name" value={rfpInputField.companyName} onChange={inputsRfpHandler} required />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-4  mt-2 required-label'><b>Email</b></div>
                    <div className='col-sm-8'>
                      <input type="text" className="form-control form-control-user"
                        name="email" placeholder="enter Email" value={rfpInputField.email} onChange={inputsRfpHandler} required />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-4  mt-2 required-label'><b>Phone</b></div>
                    <div className='col-sm-8'>
                      <input type="text" className="form-control form-control-user"
                        name="phone" placeholder="enter Phone" value={rfpInputField.phone} onChange={inputsRfpHandler} required />
                    </div>
                  </div>



                  <div className="form-group row">
                    <div className='col-sm-12'><b>Dont's</b></div>
                    <div className='col-sm-12'><textarea
                      rows={3} // You can adjust the number of visible rows
                      cols={100}
                      name='dont'
                      placeholder="enter Dont's /optional" // You can adjust the number of visible columns
                      class="form-control"
                      autofocus
                      value={rfpInputField.dont}
                      onChange={inputsRfpHandler}

                    /></div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-3 '><b>Is Convincing</b></div>
                    <div className='col-sm-1'>
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={true} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            {/* 2nd part */}
            <div className="card-body" style={{ height: "auto" }}>
              <div className="row text-dark">
                <div className="col-md-12 ml-5">
                  <div className="form-group row">
                    <div className='col-sm-2'><b></b></div>
                    <div className='col-sm-2 d-flex justify-content-center'><b>Link</b></div>
                    <div className='col-sm-2 d-flex justify-content-center'><b>Button</b></div>
                    <div className='col-sm-3'><b>Attached</b></div>
                    <div className='col-sm-3'><b>Unavailable</b></div>
                  </div>
                </div>

                <div className="col-md-12 ml-5">
                  <div className="form-group row">
                    <div className='col-sm-2 mt-1 '><b>Floor Plan</b></div>

                    <div className='col-sm-2 '><textarea
                      rows={1} // You can adjust the number of visible rows
                      cols={100}
                      name='floorplan'
                      placeholder='enter floor plan link' // You can adjust the number of visible columns
                      class="form-control"
                      autofocus
                      value={rfpInputField.floorplan}

                      onChange={inputsRfpHandler}
                    /></div>
                    <div className='col-sm-2 '>
                      <div className='btn btn-sm ml-4 btn-info' onClick={() => externalLink(rfpInputField.floorplan)} >Open floorPlan </div>
                    </div>
                    <div className='col-sm-3'>
                      <input type="radio" className='ml-3' name="floorplanAttachment" value={true} checked={rfpInputField.floorplanAttachment === true ? true : null} onChange={inputsRfpHandler} />
                    </div>
                    <div className='col-sm-3'>
                      <input type="radio" className='ml-3' name="floorplanAttachment" value={false} checked={rfpInputField.floorplanAttachment === false ? true : null} onChange={inputsRfpHandler} />
                    </div>

                  </div>
                </div>

                <div className="col-md-12 ml-5">
                  <div className="form-group row">
                    <div className='col-sm-2 mt-1 '><b>Exhibhition Guidelines</b></div>
                    <div className='col-sm-2'><textarea
                      rows={1} // You can adjust the number of visible rows
                      cols={100}
                      name='guidelines'
                      placeholder='enter guidelines details link' // You can adjust the number of visible columns
                      class="form-control"
                      autofocus
                      value={rfpInputField.guidelines}
                      onChange={inputsRfpHandler}
                    /></div>
                    <div className='col-sm-2 '>
                      <div className='btn btn-sm ml-4 btn-info' onClick={() => externalLink(rfpInputField.guidelines)} >Exhibhition Guidelines</div>
                    </div>
                    <div className='col-sm-3'>
                      <input type="radio" className='ml-3' name="exhibitionAttachment" value={true} checked={rfpInputField.exhibitionAttachment === true ? true : null} onChange={inputsRfpHandler} />
                    </div>
                    <div className='col-sm-3'>
                      <input type="radio" className='ml-3' name="exhibitionAttachment" value={false} checked={rfpInputField.exhibitionAttachment === false ? true : null} onChange={inputsRfpHandler} />
                    </div>
                  </div>
                </div>

                <div className="col-md-12 ml-5">
                  <div className="form-group row">
                    <div className='col-sm-2 mt-1'><b>Brand Guidelines</b></div>
                    <div className='col-sm-2 '><textarea
                      rows={1} // You can adjust the number of visible rows
                      cols={100}
                      name='brandguidelines'
                      placeholder='brand guidelines if any' // You can adjust the number of visible columns
                      class="form-control"
                      autofocus
                      value={rfpInputField.brandguidelines}
                      onChange={inputsRfpHandler}
                    /></div>
                    <div className='col-sm-2 '>
                      <div className='btn btn-sm ml-4 btn-info' onClick={() => externalLink(rfpInputField.brandguidelines)} > Brand Guidelines </div>
                    </div>
                    <div className='col-sm-3'>
                      <input type="radio" className='ml-3' name="brandAttachemnt" value={true} checked={rfpInputField.brandAttachemnt === true ? true : null} onChange={inputsRfpHandler} />
                    </div>
                    <div className='col-sm-3'>
                      <input type="radio" className='ml-3' name="brandAttachemnt" value={false} checked={rfpInputField.brandAttachemnt === false ? true : null} onChange={inputsRfpHandler} />
                    </div>
                  </div>
                </div>

                <div className="col-md-12 ml-5">
                  <div className="form-group row">
                    <div className='col-sm-2 mt-1'><b>Brief Link</b></div>
                    <div className='col-sm-2 '><textarea
                      rows={1} // You can adjust the number of visible rows
                      cols={100}
                      name='contentLink'
                      placeholder="enter additional attachment Link if any" // You can adjust the number of visible columns
                      class="form-control size1"
                      autofocus
                      value={rfpInputField.contentLink}
                      onChange={inputsRfpHandler}
                    /></div>
                    <div className='col-sm-2 '>
                      <div className='btn btn-sm ml-4 btn-info' onClick={() => externalLink(rfpInputField.contentLink)} >Open Brief Link </div>
                    </div>
                    <div className='col-sm-3'>
                      <input type="radio" className='ml-3' name="briefLinkAttachment" value={true} checked={rfpInputField.briefLinkAttachment === true ? true : null} onChange={inputsRfpHandler} />
                    </div>
                    <div className='col-sm-3'>
                      <input type="radio" className='ml-3' name="briefLinkAttachment" value={false} checked={rfpInputField.briefLinkAttachment === false ? true : null} onChange={inputsRfpHandler} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            {/* 3rd part */}
            <div className="card-body" style={{ height: "auto" }}>
              <div className="row text-dark">
                <div className='col-md-5 ml-5'>



                  <h5>Product display</h5>
                  <div className="form-group row">
                    <div className='col-sm-12'><b>Number of Products to Display If Any</b></div>
                    <div className='col-sm-12'><input type="text" className="form-control form-control-user"
                      name="noofProduct" placeholder="enter number of products" onChange={inputsRfpHandler} value={rfpInputField.noofProduct} /></div>
                  </div>
                  <h6>Note : We need product LXWXH with drawing and photo if possible., you can attached product photo and drawing seperately</h6>

                  <div className="form-group row">
                    <div className='col-sm-12'><b>Your Purpose</b></div>
                    <div className='col-sm-12'><textarea
                      rows={3} // You can adjust the number of visible rows
                      cols={100}
                      name='yourpurpose'
                      placeholder='Please elaborate your purpose by providing examples of stalls you like will help us understand your taste better and also the style you are looking for.' // You can adjust the number of visible columns
                      class="form-control"
                      autofocus
                      onChange={inputsRfpHandler}
                      value={rfpInputField.yourpurpose}
                    /></div>
                  </div>
                  <div className="form-group row">
                    <div className='col-sm-12 -label'><b>Description If Any</b></div>
                    <div className='col-sm-12'><textarea
                      rows={2} // You can adjust the number of visible rows
                      cols={100}
                      name='description'
                      placeholder='enter description' // You can adjust the number of visible columns
                      class="form-control"
                      autofocus
                      onChange={inputsRfpHandler}
                      value={rfpInputField.description}
                    /></div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-12'><b>Final Design Link</b></div>
                    <div className='col-sm-12 '>
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input className='rounded' {...getInputProps()} />
                        <p>Drag 'n' drop some files here, or click to select files</p>
                      </div>
                      <div>
                        {files.length > 0 && (
                          <div>
                            <h4>Selected files:</h4>
                            <ul>
                              {files.map(file => (
                                <li key={file.path}>{file.path}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                      <span className='btn btn-success bg-success' type="button" onClick={handleUpload}>Upload</span>
                      <div>
                        {(uploadProgress > 0 && uploadProgress < 100) && <progress value={uploadProgress} max="100">{uploadProgress}%</progress>}
                        {(uploadProgress > 0 && uploadProgress < 100) && <div value={uploadProgress} max="100">{uploadProgress}%</div>}
                      </div>
                      {uploadError && <div className="error">{uploadError}</div>}
                      {uploadSuccess && <div className="success">Files uploaded successfully!</div>}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="form-group d-flex flex-column">

                      {
                        rfpInputField.allfiles?.map((e, index) => (
                          <div className="m-1" key={index}>
                            <div className='btn btn-sm  btn-secondary' type="submit" onClick={() => { handleDownload(e.fileName) }} ><i class="fa-regular fa-file-pdf mr-1"></i>{e.fileName}</div>
                          </div>
                        ))}

                    </div>
                  </div>
                </div>
                <div className='col-md-1'></div>
                <div className='col-md-5'>

                  <div className="form-group row">
                    <div className='col-sm-2 mt-2 required-label'><b>Area</b></div>
                    <div className='col-sm-10'><input type="text" className="form-control form-control-user" onChange={inputsRfpHandler}
                      name="area" placeholder="enter Area" value={rfpInputField.area} required /></div>
                  </div>
                  <div className="form-group row">
                    <div className='col-sm-2 mt-2'><b>Stall No</b></div>
                    <div className='col-sm-10'><input type="text" className="form-control form-control-user" onChange={inputsRfpHandler}
                      name="stallNo" placeholder="enter stall number" value={rfpInputField.stallNo} /></div>
                  </div>
                  <div className="form-group row">
                    <div className='col-sm-2 mt-2'><b>Hall No.</b></div>
                    <div className='col-sm-10'><input type="text" className="form-control form-control-user" onChange={inputsRfpHandler}
                      name="hallNo" placeholder="enter hall number" value={rfpInputField.hallNo} /></div>
                  </div>


                  <hr />

                  <div className="form-group row" hidden={true}>
                    <div className='col-sm-12 -label'><b>Fax</b></div>
                    <div className='col-sm-12'><input type="text" className="form-control form-control-user" onChange={inputsRfpHandler}
                      name="fax" placeholder="enter fax" value={rfpInputField.fax} /></div>
                  </div>

                  <div className="form-group row" hidden={true}>
                    <div className='col-sm-12 required-label'><b>Billing Address</b></div>
                    <div className='col-sm-12'><textarea
                      rows={2} // You can adjust the number of visible rows
                      cols={100}
                      name='billingAddress'
                      placeholder='enter billing address' // You can adjust the number of visible columns
                      class="form-control"
                      value={"testBillingAddress"}
                      autofocus

                    /></div>
                  </div>
                  <div className="form-group row">
                    <div className='col-sm-12 '><b>Any Specific Need</b></div>
                    <div className='col-sm-12'><textarea
                      rows={2} // You can adjust the number of visible rows
                      cols={100}
                      name='anySpecific'
                      placeholder='enter specific need' // You can adjust the number of visible columns
                      class="form-control"
                      autofocus
                      onChange={inputsRfpHandler}
                      value={rfpInputField.anySpecific}
                    /></div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-12 '><b>Design Refrence</b></div>
                    <div className='col-sm-12'><textarea
                      rows={1} // You can adjust the number of visible rows
                      cols={100}
                      name='designRefrence'
                      placeholder='Please give design refrence if any' // You can adjust the number of visible columns
                      class="form-control"
                      autofocus
                      onChange={inputsRfpHandler}
                      value={rfpInputField.designRefrence}
                    /></div>
                  </div>

                  <h5>People</h5>
                  <div className="form-group form-group-h row">
                    <div className='col-sm-3'>Author - </div>
                    <div className='col-sm-7'>
                      <b>{getLoginUserData().name}</b>
                    </div>
                  </div>
                  <div className="form-group form-group-h row">
                    <div className="col-sm-3">Share with</div>
                    <div className="col-sm-9">
                      {rfpInputField.rfpShareList
                        ? [...new Set(rfpInputField.rfpShareList.map(item => item.asigneeName))]
                          .map((name, index) => (
                            <b key={index}>{name}, </b>
                          ))
                        : null
                      }
                    </div>
                  </div>
                  {/* <div className="form-group form-group-h row">
                    <div className='col-sm-3'><b>Final Designer</b></div>
                    <div className='col-sm-9'>
                      <Select
                        name="finalDesignerId"
                        isMulti={true}
                        placeholder="Enter Final Designer "
                        options={finalDesigner.map((user) => ({
                          value: user.id,
                          label: `${user.firstname} ${user.lastname}`,
                        }))}
                        value={finalDesignerUser}
                        onChange={handlefinalDesignUserChange}

                      />
                    </div>
                  </div> */}

                  {allFilesData.length>0 ?
                    <>
                      <div className="form-group row">
                        <div className='col-sm-4 '><b>Convert To Project</b></div>
                        <div className='col-sm-1'>
                          <input className="form-check-input" type="checkbox" checked={isConvertedDetail} onChange={handleIsConvertedChange} value="" id="flexCheckDefault" />
                        </div>
                      </div>
                    </>:null
                  }
                  <div className="form-group form-group-h row">
                    {apiResponse && (
                      <div className="alert alert-success" role="alert">
                        RFP has been Updated successfully!
                      </div>
                    )}
                    {apiError && (
                      <div className="alert alert-danger" role="alert">
                        {apiError}
                      </div>
                    )}
                  </div>
                  {loading === false && <h6 className="font-weight-bold text-primary">{ }</h6>}{loading === true &&
                    <div class=" text-primary">
                      <strong>Saving...</strong>
                      <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                    </div>
                  }
                  <button type='submit' className="btn btn-primary">Update RFP</button>

                </div>
              </div>
            </div>

            <hr />
            {/* 4th part */}
            <div className="card-body" style={{ height: "auto" }}>
              <div className="row text-dark">
                <div className='col-md-5 ml-5'>


                  {projectApiResponse && (
                    <div className="alert alert-success" role="alert">
                      Project has been Created successfully!
                    </div>
                  )}
                  {projectError && (
                    <div className="alert alert-danger" role="alert">
                      {projectError}
                    </div>
                  )}
                  <div className="col-sm-12 d-flex">

                    <div className="col-sm-3">
                      <h5>
                        note -:
                      </h5>
                    </div>
                    <div className="col-sm-12 mt-1">
                      <h6>
                        For convert to project first update RFP's Final Link and checkbox than click on convert to project button shown after RFP Update's.
                      </h6>
                    </div>
                  </div>
                  {loadingProject === false && <h6 className="font-weight-bold text-primary">{ }</h6>}{loadingProject === true &&
                    <div class=" text-primary">
                      <strong>Saving Project...</strong>
                      <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                    </div>
                  }
                  {rfpInputField.isConvertedProject === true && <span onClick={autoProjectCreation} className="btn btn-primary">Create Project</span>}
                  {/* <button className="btn btn-primary">Save</button> */}
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-5">

                </div>
              </div>
            </div>
          </form>


        </div>

      </div>
    </div>
  )
}

export default EditRFPComponent