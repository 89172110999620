import Service from "../../../Services/Admin/Service";
import React, { useEffect, useState } from "react";
import KanbanViewCss from "./KanbanViewComponent.css";
import { Link } from "react-router-dom";

function KanbanComponent(props) {
    const [backlogdata, setbacklogdata] = useState([]);
    const [progressData, setprogressData] = useState([]);
    const [reviewData, setReviewData] = useState([]);
    const [doneData, setdoneData] = useState([]);
    const [archiveData, setarchiveData] = useState([]);

    useEffect(() => {
        getStatusData("Status/GetAll")
    }, [])

    const getStatusData = (method) => {
        let userid = sessionStorage.getItem('id')
        Service.getdata(method).then((res) => {
            var currentdata = res.data;
            currentdata.forEach(element => {
                getTaskData("Task/GetAll?userid=" + userid + "&statusid=" + element.statusId, element.statusId);
            });
        });

    };

    function formatDate(dateString) {
        const dateObject = new Date(dateString);

        const day = String(dateObject.getDate()).padStart(2, '0');
        const month = String(dateObject.getMonth() + 1).padStart(2, '0');
        const year = dateObject.getFullYear();

        return `${day}-${month}-${year}`;
    }

    const getPriorityColor = (priority) => {
        switch (priority.toLowerCase()) {
            case "low":
                return "	#0096FF";
            case "medium":
                return "#32CD32";
            case "high":
                return "#ff0000";
            default:
                return "black";
        }
    };

    const getTaskData = (method, statusid) => {
        Service.getdata(method).then((res) => {
            if (statusid === 1) {
                setbacklogdata(res.data);
                console.log("Task Data backlog", (res.data))
            } else if (statusid === 2) {
                setprogressData(res.data);
                console.log("Task Data", (res.data))
            }
            else if (statusid === 3) {
                setReviewData(res.data);
                console.log("Task Data", (res.data))
            }
            else if (statusid === 4) {
                setdoneData(res.data);
                console.log("Task Data", (res.data))
            }
            else if(statusid === 5){
                setarchiveData(res.data);
            }
        });
    }

    const backbutton = () => {
        props.navigate(-1);
    }


    return (
        <>
            <div className="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col-sm-2"><h3>Kanban View</h3></div>
                            <div className="col-sm-6">
                                <select name="" id="" className="btn btn-primary ">
                                    <option disabled selected value="" className="btn btn-light">Task Type</option>
                                    <option className="btn btn-light" value="MyTask">My Task</option>
                                    <option className="btn btn-light" value="MyTask">Assigned Task</option>
                                </select>
                            </div>
                            <div className="col-sm-3">
                                <Link to="/AdminDashboard" className="btn btn-secondary mt-1 ml-2 btn-sm">Home</Link>
                                <button className="btn btn-info mt-1 ml-1 btn-sm" onClick={() => backbutton()}>back</button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card shadow mb-4">
                    <div className="container-fluid">
                        <div className="row py-3">
                            <div className="col-sm-3">
                                <div className="">0</div>
                                <div className="">Backlog</div>
                                <hr></hr>

                                {backlogdata.map((item) => (
                                    <div className="">
                                        <div class="items border border-light">
                                            <Link className=" link-no-underline ml-3 mb-2 btn-sm" to={`/TaskDetails/${item.id}`}>
                                                <div class="card draggable shadow-sm" id="cd1" draggable="true" ondragstart="drag(event)">
                                                    <div class="card-body p-2 bg-dark text-white">
                                                        <div class="card-title">
                                                            <div class="lead font-weight-light">Task - #{item.id}</div>
                                                            <div class="lead font-weight-light"></div>
                                                        </div>
                                                        <p>
                                                            {item.headline}
                                                        </p>
                                                        <div className="row">
                                                            <div className="col-md-7">
                                                                <p>Date- {formatDate(item.finishdate)}</p>
                                                            </div>
                                                            <div className="col-md-5">
                                                                {/* Apply inline style based on priority */}
                                                                <p className="priority" style={{ fontSize: "18px", fontWeight: "600", color: getPriorityColor(item.priority) }}>
                                                                    {item.priority}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                            {/* <div class="dropzone rounded" ondrop="drop(event)" ondragover="allowDrop(event)" ondragleave="clearDrop(event)"> &nbsp; </div> */}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="col-sm-3">
                                <div className="">0</div>
                                <div className="">InProgress</div>
                                <hr></hr>
                                {progressData.map((item) => (
                                    <div className="">
                                        <div class="items border border-light">
                                            <Link className=" link-no-underline ml-3 mb-2 btn-sm" to={`/TaskDetails/${item.id}`}>
                                                <div class="card draggable shadow-sm" id="cd1" draggable="true" ondragstart="drag(event)">
                                                    <div class="card-body p-2 bg-dark text-white">
                                                        <div class="card-title">
                                                            <div class="lead font-weight-light">Task - #{item.id}</div>
                                                            <div class="lead font-weight-light"></div>
                                                        </div>
                                                        <p>
                                                            {item.headline}
                                                        </p>
                                                        <div className="row">
                                                            <div className="col-md-7">
                                                                <p>Date- {formatDate(item.finishdate)}</p>
                                                            </div>
                                                            <div className="col-md-5">
                                                                {/* Apply inline style based on priority */}
                                                                <p className="priority" style={{ fontSize: "18px", fontWeight: "600", color: getPriorityColor(item.priority) }}>
                                                                    {item.priority}
                                                                </p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                            {/* <div class="dropzone rounded" ondrop="drop(event)" ondragover="allowDrop(event)" ondragleave="clearDrop(event)"> &nbsp; </div> */}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="col-sm-3">
                                <div className="">0</div>
                                <div className="">Reviewed</div>
                                <hr></hr>
                                {reviewData.map((item) => (
                                    <div className="">
                                        <div class="items border border-light">
                                            <Link className=" link-no-underline ml-3 mb-2 btn-sm" to={`/TaskDetails/${item.id}`}>
                                                <div class="card draggable shadow-sm" id="cd1" draggable="true" ondragstart="drag(event)">
                                                    <div class="card-body p-2 bg-dark text-white">
                                                        <div class="card-title">
                                                            <div class="lead font-weight-light">Task - #{item.id}</div>
                                                            <div class="lead font-weight-light"></div>
                                                        </div>
                                                        <p>
                                                            {item.headline}
                                                        </p>
                                                        <div className="row">
                                                            <div className="col-md-7">
                                                                <p>Date- {formatDate(item.finishdate)}</p>
                                                            </div>
                                                            <div className="col-md-5">
                                                                {/* Apply inline style based on priority */}
                                                                <p className="priority" style={{ fontSize: "18px", fontWeight: "600", color: getPriorityColor(item.priority) }}>
                                                                    {item.priority}
                                                                </p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                            {/* <div class="dropzone rounded" ondrop="drop(event)" ondragover="allowDrop(event)" ondragleave="clearDrop(event)"> &nbsp; </div> */}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="col-sm-3">
                                <div className="">0</div>
                                <div className="">Done</div>
                                <hr></hr>
                                {doneData.map((item) => (
                                    <div className="">
                                        <div class="items border border-light">
                                            <Link className=" link-no-underline ml-3 mb-2 btn-sm" to={`/TaskDetails/${item.id}`}>
                                                <div class="card draggable shadow-sm" id="cd1" draggable="true" ondragstart="drag(event)">
                                                    <div class="card-body p-2 bg-dark text-white">
                                                        <div class="card-title">
                                                            <div class="lead font-weight-light">Task - #{item.id}</div>
                                                            <div class="lead font-weight-light"></div>
                                                        </div>
                                                        <p>
                                                            {item.headline}
                                                        </p>
                                                        <div className="row">
                                                            <div className="col-md-7">
                                                                <p>Date- {formatDate(item.finishdate)}</p>
                                                            </div>
                                                            <div className="col-md-5">
                                                                {/* Apply inline style based on priority */}
                                                                <p className="priority" style={{ fontSize: "18px", fontWeight: "600", color: getPriorityColor(item.priority) }}>
                                                                    {item.priority}
                                                                </p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                            {/* <div class="dropzone rounded" ondrop="drop(event)" ondragover="allowDrop(event)" ondragleave="clearDrop(event)"> &nbsp; </div> */}
                                        </div>
                                    </div>
                                ))}
                            </div>


                            <div className="col-sm-3">
                                <div className="">0</div>
                                <div className="">Done</div>
                                <hr></hr>
                                {archiveData.map((item) => (
                                    <div className="">
                                        <div class="items border border-light">
                                            <Link className=" link-no-underline ml-3 mb-2 btn-sm" to={`/TaskDetails/${item.id}`}>
                                                <div class="card draggable shadow-sm" id="cd1" draggable="true" ondragstart="drag(event)">
                                                    <div class="card-body p-2 bg-dark text-white">
                                                        <div class="card-title">
                                                            <div class="lead font-weight-light">Task - #{item.id}</div>
                                                            <div class="lead font-weight-light"></div>
                                                        </div>
                                                        <p>
                                                            {item.headline}
                                                        </p>
                                                        <div className="row">
                                                            <div className="col-md-7">
                                                                <p>Date- {formatDate(item.finishdate)}</p>
                                                            </div>
                                                            <div className="col-md-5">
                                                                {/* Apply inline style based on priority */}
                                                                <p className="priority" style={{ fontSize: "18px", fontWeight: "600", color: getPriorityColor(item.priority) }}>
                                                                    {item.priority}
                                                                </p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                            {/* <div class="dropzone rounded" ondrop="drop(event)" ondragover="allowDrop(event)" ondragleave="clearDrop(event)"> &nbsp; </div> */}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>


                </div>
            </div>


        </>
    );
}

export default KanbanComponent