import React from 'react'
import { ToastContainer } from 'react-toastify';
import { Helmet } from "react-helmet";
import addNotification from 'react-push-notification';


import { useState, useEffect } from "react";
import Service from '../../Services/Admin/Service';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { getLoginUserData } from '../../Helpers/supportedFunctions/supportedGlobalFunctions';

function Header() {

    let id = sessionStorage.getItem('id');
    let name = sessionStorage.getItem('name');
    let updatecount = sessionStorage.getItem('updatecount');
    const [notification, setnotification] = useState([]);
    // const [notificationShown, setNotificationShown] = useState(false);
    const signout = () => {

        sessionStorage.clear();
        const cookieNames = Object.keys(Cookies.get());
        // Remove each cookie
        cookieNames.forEach(cookieName => {
            Cookies.remove(cookieName);
        });
        window.location.href = "/";
    }

    useEffect(() => {
        logTimer();
        scheduleLogout();
    }, [])
    // // // // // ================================> logout logic
    // let inactivityTimeout;

    // function startInactivityTimer() {
    //     // Set the inactivity timeout to 10 seconds (10000 milliseconds)
    //     inactivityTimeout = setTimeout(logoutUser, 1800000);

    //     // Add event listeners to reset the timer on user activity
    //     document.addEventListener("mousemove", resetTimer);
    //     document.addEventListener("keypress", resetTimer);
    //     document.addEventListener("scroll", resetTimer);

    // }

    // function resetTimer() {
    //     // Reset the inactivity timeout
    //     clearTimeout(inactivityTimeout);
    //     startInactivityTimer();
    // }


    function logTimer() {
        console.log("log function");

        Service.createorupdate("Users/CreateLog?id=" + id);

        setTimeout(logTimer, 60000)
    }

    function scheduleLogout() {
        setTimeout(signout, new Date().setHours(23, 59, 59, 0) - Date.now());
    }

    function logoutUser() {
        sessionStorage.clear();
        window.location.href = "/";
        console.log("User logged out due to inactivity");
    }


    // Start the inactivity timer when the page loads


    // // // // // ================================> logout logic



    useEffect(() => {
        let currentcount = updatecount + 1;
        sessionStorage.setItem('updatecount', currentcount);
        GetNotification("Dashboard/GetAllNotificationlist?userid=" + id + "&isRead=", 0);
    }, [updatecount])

    const GetNotification = (method, id) => {
        Service.getdatabyId(method, id).then((res) => {
            setnotification(res.data);


            // if (res.data.length > notification.length) {

            //     addNotification({
            //         title: 'SOP Notification ',
            //         message: 'Visit SOP TOOL',
            //         duration: 4000,
            //         native: true,
            //         onClick: () => window.open("http://localhost:3000/AdminDashboard", '_blank')
            //     })


            // }
            // console.log(res.data);

        });
    }



    const formatDateTime = (cell, row) => {
        // Format the date and time here, assuming 'time' is a string in ISO 8601 format
        const formattedDate = new Date(cell).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',

        });
        return formattedDate;
    }


    return (
        <>
            <div className="navbar border container-fluid  border-success navbar-expand-lg border-bottom  topbar mb-4   fixed-top shadow" style={{backgroundColor:"#d7ffce"}}>

                <Helmet>
                    <meta charSet="utf-8" />
                    {notification.length === 0 ? <title>SOP</title> :
                        <title>({`${notification.length}`}) SOP</title>}
                    <link rel="canonical" href="http://mysite.com/example" />
                </Helmet>

                <div className="col-sm-12 d-flex justify-content-between no-wrap " >
                    <div className=" col-sm-9 d-md-inline" >
                        
                        <button className="rounded-circle mt-3 bg-success  text-white border border-info" type="button" data-toggle="collapse" data-target="#navbarToggle" aria-expanded="false" aria-controls="collapseExample">
                            <i class="fa-solid fa-bars"></i>
                        </button>
                        <b className='ml-1'>SideMenu</b>
                    </div>
                    <div className="nav-item  dropdown no-arrow mx-1" >
                        <a className="nav-link dropdown-toggle" href="/" id="messagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-envelope fa-fw"></i>

                            {notification.length !== 0 && <span className="badge badge-danger badge-counter">{notification.length}</span>}
                        </a>

                        <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="messagesDropdown" >
                            <h6 className="dropdown-header">
                                Notification Message Center
                            </h6>
                            <div style={{ maxHeight: "300px", overflowY: "scroll" }}>

                                {notification.map((pr, index) => (
                                    <div className='' key={index} >
                                        {pr.taskid !== null && pr.taskid !== 0 ?
                                            (
                                                <>
                                                    <div className="modal" role="dialog">
                                                        <div className="modal-dialog" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title">Modal title</h5>
                                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <p>Modal body text goes here.</p>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-primary">Save changes</button>
                                                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <a className="dropdown-item d-flex align-items-center" href={`/TaskDetails/${pr.taskid}`}>
                                                        <div className="dropdown-list-image mr-3">
                                                            <img className="rounded-circle" src="/logo192.png" alt="" />
                                                            <div className="status-indicator bg-success"></div>
                                                        </div>
                                                        <div className="font-weight-bold">
                                                            <div className="text-truncate">{pr.description.substring(0, 25)}</div>
                                                            <div className="small ">{pr.senderName} ( {formatDateTime(pr.createddate)} )</div>
                                                        </div>
                                                    </a>
                                                </>)
                                            :
                                            pr.meetingId !== null ?
                                                <a className="dropdown-item d-flex align-items-center" href={`/MeetingDetails/${pr.meetingId}`}>
                                                    <div className="dropdown-list-image mr-3">
                                                        <img className="rounded-circle" src="/logo192.png" alt="" />
                                                        <div className="status-indicator bg-success"></div>
                                                    </div>
                                                    <div className="font-weight-bold">
                                                        <div className="text-truncate">{pr.description.substring(0, 25)}</div>
                                                        <div className="small ">{pr.senderName} ( {formatDateTime(pr.createddate)} )</div>
                                                    </div>
                                                </a>
                                                :
                                                pr.rfpid !== null && pr.rfpid !== 0 ?
                                                    (<a className="dropdown-item d-flex align-items-center" href={`/RFPDetails/${pr.rfpid}`}>
                                                        <div className="dropdown-list-image mr-3">
                                                            <img className="rounded-circle" src="/logo192.png" alt="" />
                                                            <div className="status-indicator bg-success"></div>
                                                        </div>
                                                        <div className="font-weight-bold">
                                                            <div className="text-truncate">{pr.description.substring(0, 25)}.</div>
                                                            <div className="small ">{pr.senderName} ( {formatDateTime(pr.createddate)} )</div>
                                                        </div>
                                                    </a>)
                                                    :
                                                    pr.leadId !== null && pr.leadId !== 0 ?
                                                        (<a className="dropdown-item d-flex align-items-center" href={`/SharedLeadDetails/${pr.leadId}&${"details"}`}>
                                                            <div className="dropdown-list-image mr-3">
                                                                <img className="rounded-circle" src="/logo192.png" alt="" />
                                                                <div className="status-indicator bg-success"></div>
                                                            </div>
                                                            <div className="font-weight-bold">
                                                                <div className="text-truncate">{pr.description.substring(0, 25)}.</div>
                                                                <div className="small ">{pr.senderName} ( {formatDateTime(pr.createddate)} )</div>
                                                            </div>
                                                        </a>)
                                                        :
                                                        pr.projectId !== null && pr.ProjectId !== 0 ?
                                                            (<a className="dropdown-item d-flex align-items-center" href={`/AddProject/${pr.projectId}&${"details"}`}>
                                                                <div className="dropdown-list-image mr-3">
                                                                    <img className="rounded-circle" src="/logo192.png" alt="" />
                                                                    <div className="status-indicator bg-success"></div>
                                                                </div>
                                                                <div className="font-weight-bold">
                                                                    <div className="text-truncate">{pr.description.substring(0, 25)}.</div>
                                                                    <div className="small ">{pr.senderName} ( {formatDateTime(pr.createddate)} )</div>
                                                                </div>
                                                            </a>)
                                                            :
                                                            null}

                                    </div>
                                ))}
                            </div>

                            <Link className="dropdown-item text-center small text-gray-500" to="/NotificationCenter">Read More Messages</Link>
                            {/* <a className="dropdown-item text-center small text-gray-500" href={`/`}>Read More Messages</a> */}
                        </div>
                    </div>

                    <ToastContainer />

                    <div className="topbar-divider border-success d-none d-sm-block"></div>


                    <div className="nav-item dropdown no-arrow">
                        <a className="nav-link dropdown-toggle" href="/" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="mr-2 d-none d-lg-inline text-dark small"><b>{getLoginUserData().name}</b></span>
                            <img className="img-profile rounded-circle" src="/logo192.png" alt="" />
                        </a>

                        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                            <a className="dropdown-item" href="/Profile">
                                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                Profile
                            </a>
                            <a className="dropdown-item" href="/">
                                <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                                Settings
                            </a>
                            <a className="dropdown-item" href="/">
                                <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                                Activity Log
                            </a>
                            <div className="dropdown-divider"></div>

                            <a href="/" className="dropdown-item" onClick={signout}>
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                <span>Logout</span>
                            </a>
                        </div>
                    </div>

                </div>
            </div>



        </>

    )

}

export default Header