import React, { useEffect, useState } from 'react'
import Service from "../../../Services/Admin/Service";
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

const VendorClientQuotationListComponent = (props) => {

  const [data, setData] = useState([]);


  useEffect(() => {
    getVendorClientData("VendorClientQuotation/GetAll");
  }, [])

  const getVendorClientData = (method) => {
    Service.getdata(method).then((res) => {
      const filteredData = res.data.filter((element) => element.vendorClient === "Vendor");

      // Set the state with the filtered data
      setData(filteredData);

      // Log the filtered data
      console.log(filteredData);
    });
  }

  const backbutton = () => {
    props.navigate(-1);
  }

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="btn-group" role="group">
      {
        options.map(option => (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${currSizePerPage === `${option.page}` ? 'btn-secondary' : 'btn-warning'}`}
          >
            {option.text}
          </button>
        ))
      }
    </div>
  );

  const options = {
    sizePerPageRenderer
  };

  const formatDateTime = (cell, row) => {
    // Format the date and time here, assuming 'time' is a string in ISO 8601 format
    const formattedDate = new Date(cell).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      // hour: '2-digit',
      // minute: '2-digit',
      // second: '2-digit',
    });
    return formattedDate;
  }

  const staticName1 = "edit";
  const staticName2 = "details"

  const linkformatter = (data, row) => {
    return <>
      {row.vendorClient === "Vendor" ?
        <>

          <Link className="btn btn-info btn-sm m-1" to={`/VendorQuotationDetail/${data}&${staticName1}`}>Edit</Link>
          <Link className="btn btn-info btn-sm m-1" to={`/VendorQuotationDetail/${data}&${staticName2}`}>Details</Link>
        </>

        : null

      }
      {row.vendorClient === "Client" ?

        <Link className="btn btn-info btn-sm m-1" to={`/ClientQuotationDetail/${data}`}>Details</Link> : null

      }

      {/* <Link className="btn btn-warning btn-sm" to={`/MeetingDetails/${data}`}>Details</Link> */}
    </>
  }

  const column = [
    { dataField: 'projectName', text: 'Project', sort: true },
    { dataField: 'vendorName', text: 'Vendor', sort: true },
    {
      dataField: 'showdate',
      text: 'Show Date ',
      sort: true,
      formatter: formatDateTime

    },
    { dataField: 'id', text: 'Actions', formatter: linkformatter }
  ]
  return (
    <div className="container-fluid">
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <div className="row">
            <div className="col-sm-5"><h3>Vendor Costing List</h3></div>
            <div className="col-sm-3"></div>
            <div className="col-sm-4 d-flex flex-row-reverse">
              <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
              <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">List</h6>
          {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
        </div>

        <div className="card-body">
          <div className="table-responsive">
            <BootstrapTable keyField="id"
              data={data}
              columns={column}
              pagination={paginationFactory(options)}
              filter={filterFactory()}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default VendorClientQuotationListComponent