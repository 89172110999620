import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import AgreementListComponent from '../../../Components/Main/AgreementComponent/AgreementListComponent';

function AgreementList(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <AgreementListComponent {...props} navigate={navigate} id={params.id} />
        </div>
    );  
  }
export default AgreementList  