import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import DashboardComponent from '../../../Components/Main/DashboardComponent/DashboardComponent';

function Dashboard(props){
    let navigate = useNavigate();
    const params = useParams();
     
     return (  
         <div> 
             <DashboardComponent {...props}  navigate={navigate} />
         </div>
     );  

}

export default Dashboard