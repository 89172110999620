import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import ApprovalListComponent from '../../../Components/Main/ApprovalListComponent/ApprovalListComponent';

function ApprovalList(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <ApprovalListComponent {...props} navigate={navigate} id={params.id}  />
        </div>
    );  
  }
export default ApprovalList 