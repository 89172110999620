import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import TestingComponent from '../../../Components/Main/TestingComponent/TestingComponent';

function Testing(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <TestingComponent {...props} navigate={navigate}  />
        </div>
    );  
  }
export default Testing  