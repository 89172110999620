import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import CreateRFPComponent from '../../../Components/Main/RFPComponent/CreateRFPComponent';

function CreateRFP(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <CreateRFPComponent {...props} navigate={navigate}  />
        </div>
    );  
  }
export default CreateRFP  