import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import CreateLeadComponent from '../../../Components/Main/LeadComponent/CreateLeadComponent';


function CreateLead(props) {
    let navigate = useNavigate();
    
    return (  
        <div> 
            <CreateLeadComponent {...props} navigate={navigate}  />
        </div>
    );  
  }
export default CreateLead  