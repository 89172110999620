import React from 'react';
import { Link } from 'react-router-dom';
import css from './AdminDashboard.css';

const AdminDashboardComponent = (props) => {


    const handleLifeToolButtonClick = () => {
        // Open the external link in a new tab
        window.open('https://life.expoodle.com/', '_blank');
    };

    const role = sessionStorage.getItem("role");

    const backbutton = () => {
        props.navigate(-1);
    }

    const staticid = 0;
    const staticname19 = "RFP Design";
    const staticName17 = "Request";
    // const staticname6 = "Create";


    const rolebuttonDataExtractor = [
        // {
        //     role: "Data Extractor",
        //     buttons: [
        //         { label: "My Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
        //         // { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
        //         { label: "Meeting List", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
        //         { label: "Approved Extracted Data List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/ApprovedExtraxctedDataList" },
        //     ]
        // },

        {
            role: "Executive - Data Extractor",
            buttons: [
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSpecificTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "Upcoming Shows List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminShowList" },

            ]
        },

    ]


    // Define an array of buttons for each role
    const roleButtons = [
        //  operations
        {
            role: "Admin",
            buttons: [
                { label: "marketing", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/DasboardMarketingOptions" },
                { label: "Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/DashboardSalesOptions" },
                { label: "pre-sales", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardPreSalesOptions" },
                { label: "Operations", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardOperationsOption" },
                { label: "i&c/Design", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/DashboardICDesignOptions" },
                { label: "Finance", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardCommercialOptions" },
                { label: "Admin panel", className: "btn btn-danger btn-md font-weight-bold text-uppercase adminPanel", to: "/" }

            ]
        },

        {
            role: "Managing Director(MD)",
            buttons: [
                { label: "marketing", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/DasboardMarketingOptions" },
                { label: "Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/DashboardSalesOptions" },
                { label: "pre-sales", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardPreSalesOptions" },
                { label: "Operations", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardOperationsOption" },
                { label: "i&c/Design", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/DashboardICDesignOptions" },
                { label: "Finance", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardCommercialOptions" },
                { label: "Admin panel", className: "btn btn-danger btn-md font-weight-bold text-uppercase adminPanel", to: "/" }

            ]
        },

        {
            role: "Assitant Manager - Process",
            buttons: [
                { label: "marketing", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/DasboardMarketingOptions" },
                { label: "Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/DashboardSalesOptions" },
                { label: "pre-sales", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardPreSalesOptions" },
                { label: "Operations", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardOperationsOption" },
                { label: "i&c/Design", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/DashboardICDesignOptions" },
                { label: "Finance", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardCommercialOptions" },
                { label: "Admin panel", className: "btn btn-danger btn-md font-weight-bold text-uppercase adminPanel", to: "/" }
            ]
        },

        // {
        //     role: "Project-Head",
        //     buttons: [
        //         { label: "marketing", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/DasboardMarketingOptions" },
        //         { label: "Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/DashboardSalesOptions" },
        //         { label: "pre-sales", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardPreSalesOptions" },
        //         { label: "Operations", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardOperationsOption" },
        //         { label: "i&c/Design", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/DashboardICDesignOptions" },
        //         { label: "Finance", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardCommercialOptions" }
        //     ]
        // },

        {
            role: "DGM-Projects",
            buttons: [
                { label: "Operations", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardOperationsOption" },
                { label: "Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/DashboardSalesOptions" },
            ]
        },
// project managers
        {
            role: "Project Manager",
            buttons: [
                { label: "Operations", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardOperationsOption" },
                { label: "Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/DashboardSalesOptions" },
            ]
        },

        {
            role: "Project-Manager-Poland",
            buttons: [
                { label: "Operations", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardOperationsOption" },
                { label: "Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/DashboardSalesOptions" },
            ]
        },

        {
            role: "Senior Executive - Administration",
            buttons: [
                { label: "Operations", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardOperationsOption" },
                { label: "Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/DashboardSalesOptions" },
            ]
        },

        {
            role: "Sr.Manager-Projects",
            buttons: [
                { label: "Operations", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardOperationsOption" },
                { label: "Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/DashboardSalesOptions" },
            ]
        },

        {
            role: "Head of Projects - North America",
            buttons: [
                { label: "Operations", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardOperationsOption" },
                { label: "Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/DashboardSalesOptions" },
            ]

        },

        {
            role: "Senior Manager-Projects & Client Servicing",
            buttons: [
                { label: "Operations", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardOperationsOption" },
                { label: "Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/DashboardSalesOptions" },
            ]
        },

        {
            role: "Executive - Operations",
            buttons: [
                { label: "Operations", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardOperationsOption" },
                { label: "Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/DashboardSalesOptions" },
            ]
        },


        // {
        //     role: "2D-Head",
        //     buttons: [
        //         { label: "My Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
        { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
        //         { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
        //         { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateTask" },
        //         // { label: "Task Notification", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
        //         //{ label: "Chat-box", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
        //         { label: "Create Meeting", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateMeeting" },
        //         { label: "Meeting List", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
        //         { label: "Boq List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/BoqList" },
        //         { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/RFPList" },
        //         { label: "Add Material Link", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/CreateMaterial" },
        //         // { label: "2D-drawing & working Details", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/marketing" },
        //         //{ label: "Static calculation verify", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/marketing" },
        //         // 
        //         { label: "Materials Link List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/MaterialLinkList" },
        //     ]
        // },

        {
            role: "Deputy-Manager-Projects",
            buttons: [
                { label: "My Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateTask/${staticName17}` },
                { label: "Request List", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Assigned Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateTask" },
                // { label: "Task Notification", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
                //{ label: "Chat-box", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "Create BOQ", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateBOQ" },
                { label: "Boq List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/BoqList" },
                { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                { label: "Materials Link List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/MaterialLinkList/${staticid}&${"Material Details"}` },
                // { label: "2D-design", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/Create2dDesign" },
                { label: "2D-design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${"2D Design"}` }, 
            ]
        },

        {
            role: "Senior Executive - Projects",
            buttons: [
                { label: "Operations", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardOperationsOption" },
                { label: "Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/DashboardSalesOptions" },
            ]
        },

        {
            role: "Assistant Manager - Graphics",
            buttons: [
                { label: "Operations", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardOperationsOption" },
                { label: "Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/DashboardSalesOptions" },
            ]
        },

        {
            role: "Deputy Manager - Administration",
            buttons: [
                { label: "Operations", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardOperationsOption" },
                { label: "Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/DashboardSalesOptions" },
            ]
        },




        // {
        //     role: "2D-Asst.Manager",
        //     buttons: [
        //         { label: "My Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
        { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
        //         { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
        //         { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateTask" },
        //         // { label: "Task Notification", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
        //         //{ label: "Chat-box", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
        //         { label: "Meeting List", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
        //         { label: "Create BOQ", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateBOQ" },
        //         { label: "Boq List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/BoqList" },
        //         { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/RFPList" },
        //         { label: "Add Material Link", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/CreateMaterial" },
        //         { label: "Materials Link List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/MaterialLinkList" },
        //     ]
        // },

        {
            role: "Assistant-Manager-Projects",
            buttons: [
                { label: "My Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateTask/${staticName17}` },
                { label: "Request List", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Assigned Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateTask" },
                // { label: "Task Notification", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
                //{ label: "Chat-box", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "Create BOQ", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateBOQ" },
                { label: "Boq List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/BoqList" },
                { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                { label: "Materials Link List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/MaterialLinkList/${staticid}&${"Material Details"}` },
                // { label: "2D-design", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/Create2dDesign" },
                { label: "2D-design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${"2D Design"}` },
            ]
        },


        // {
        //     role: "Graphic-Head",
        //     buttons: [
        //         { label: "My Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
        { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
        //         { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
        //         { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateTask" },
        //         // { label: "Task Notification", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
        //         // { label: "Chat-box", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
        //         { label: "Meeting List", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
        //         { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/RFPList" },
        //         { label: "Design List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DesignList" },
        //         // { label: "Graphic Design For Vendor", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/marketing" },
        //         // { label: "Client Graphics Verification", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/marketing" },
        //     ]
        // },

        {
            role: "Manager - Graphics",
            buttons: [
                { label: "Operations", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardOperationsOption" },
                { label: "Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/DashboardSalesOptions" },
            ]
        },

        // {
        //     role: "Graphic-Asst.Manager",
        //     buttons: [
        //         { label: "My Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
        { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
        //         { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
        //         { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateTask" },
        //         { label: "Meeting List", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
        //         { label: "Graphic Design", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/CreateGraphicDesign" },

        //         // { label: "Graphic Design For Vendor", className: "btn btn-primary btn-lg font-weight-bold text-uppercase", to: "/marketing" },
        //         // { label: "Client Graphics Verification", className: "btn btn-success btn-lg font-weight-bold text-uppercase", to: "/marketing" },

        //     ]
        // },

        // {
        //     role: "Assistant Manager - Graphics",
        //     buttons: [
        //         { label: "My Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
        //         { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateTask/${staticName17}` },
        //         { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

        //         { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
        //         { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
        //         { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateTask" },
        //         { label: "Meeting List", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
        //         // { label: "Graphic Design", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/CreateGraphicDesign" },
        //         { label: "BOQ List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/BoqList" },
        //         { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/RFPList" },
        //         { label: "Graphic Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname9}` },
        //         // { label: "Graphic Design For Vendor", className: "btn btn-primary btn-lg font-weight-bold text-uppercase", to: "/marketing" },
        //         // { label: "Client Graphics Verification", className: "btn btn-success btn-lg font-weight-bold text-uppercase", to: "/marketing" },

        //     ]
        // },

        {
            role: "Senior Executive - Digital Marketing",
            buttons: [
                { label: "My Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateTask" },
                { label: "Meeting List", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                // { label: "Graphic Design For Vendor", className: "btn btn-primary btn-lg font-weight-bold text-uppercase", to: "/marketing" },
                // { label: "Client Graphics Verification", className: "btn btn-success btn-lg font-weight-bold text-uppercase", to: "/marketing" },

            ]
        },



        {
            role: "Intern - Digital Marketing",
            buttons: [
                { label: "My Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateTask" },
                { label: "Meeting List", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                // { label: "Graphic Design For Vendor", className: "btn btn-primary btn-lg font-weight-bold text-uppercase", to: "/marketing" },
                // { label: "Client Graphics Verification", className: "btn btn-success btn-lg font-weight-bold text-uppercase", to: "/marketing" },

            ]
        },
        // Define other roles and their associated buttons here
        // {
        //     role: "Marketing Head",
        //     buttons: [
        //         { label: "My Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
        { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
        //         { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
        //         { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateSpecificTask" },

        //         { label: "Create Team Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingMarketing" },
        //         { label: " My Meeting List", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
        //        // { label: "Create Content Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname1}` },
        //         { label: "Content Task Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname1}` },

        //        // { label: "Create Email Writing Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname2}` },
        //         { label: "Email-Content Writing Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname2}` },


        //        // { label: "Create Creativity & Design Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname3}` },
        //         { label: " Creativity & Design Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname3}` },

        //         //{ label: "Create LinkedIn Notes Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname4}` },
        //         { label: "LinkedIn Notes Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname4}` },

        //       //  { label: "Create Landing Page Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname5}` },
        //         { label: "Landing Page Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname5}` },
        //         { label: "Approved Extracted Data List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/ApprovedExtraxctedDataList" },

        //         { label: "Upcoming Shows List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/showlist" },

        //         { label: "Shared Qualified List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLead" },



        //     ]
        // },

        {
            role: "Head of Marketing - North America",
            buttons: [
                // { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSpecificTask" },
                { label: "Create Multi Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMultiTaskMarketing" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },

                { label: "Create Team Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingMarketing" },
                { label: " My Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                // { label: "Create Content Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname1}` },
                { label: "Upcoming Shows List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminShowList" },
                { label: "Create Lead", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/ShareLead" },
                { label: "Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SharedLeadList  " },
                { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLeadList" },

                // { label: "Create Email Writing Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname2}` },
                // { label: "Email-Content Writing Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname2}` },


                // { label: "Create Creativity & Design Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname3}` },
                // { label: " Creativity & Design Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname3}` },

                //{ label: "Create LinkedIn Notes Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname4}` },
                // { label: "LinkedIn Notes Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname4}` },

                //  { label: "Create Landing Page Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname5}` },
                // { label: "Website Page Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname5}` },
                { label: "PPT List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/PPTList" },
                { label: "Attach PPT ", className: "btn btn-danger btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/CreatePPT" },
            ]
        },

        {
            role: "Marketing Consultant - North America",
            buttons: [
                { label: "My Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Create Multi Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMultiTaskMarketing" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateSpecificTask" },

                { label: "Create Team Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingMarketing" },
                { label: " My Meeting List", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                // { label: "Create Content Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname1}` },
                // { label: "Content Task Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname1}` },

                // { label: "Create Email Writing Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname2}` },
                // { label: "Email-Content Writing Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname2}` },


                // { label: "Create Creativity & Design Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname3}` },
                // { label: " Creativity & Design Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname3}` },

                //{ label: "Create LinkedIn Notes Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname4}` },
                // { label: "LinkedIn Notes Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname4}` },

                //  { label: "Create Landing Page Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname5}` },
                // { label: "Website Page Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname5}` },
                { label: "Approved Extracted Data List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/ApprovedExtraxctedDataList" },

                { label: "Upcoming Shows List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/AdminShowList" },

                { label: "Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLeadList" },
                { label: "PPT List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/PPTList" },
                { label: "Attach PPT ", className: "btn btn-danger btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/CreatePPT" },
            ]
        },
        //marketing Roles

        // {
        //     role: "Marketing Deputy Manager",
        //     buttons: [
        //         { label: "My Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
        { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
        //         { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
        //         { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateSpecificTask" },

        //         { label: "Create Team Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingMarketing" },
        //         { label: " My Meeting List", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },


        //        // { label: "Create Content Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname1}` },
        //         { label: "Content Task Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname1}` },

        //        // { label: "Create Email Writing Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname2}` },
        //         { label: "Email Content Writing Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname2}` },



        //         //{ label: "Create Creativity & Design Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname3}` },
        //         { label: " Creativity & Design Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname3}` },

        //         //{ label: "Create LinkedIn Notes Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname4}` },
        //         { label: "LinkedIn Notes Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname4}` },

        //        // { label: "Create Landing Page Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname5}` },
        //         { label: "Website Page Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname5}` },

        //         { label: "Approved Extracted Data List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/ApprovedExtraxctedDataList" },

        //         { label: "Upcoming Shows List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/AdminShowList" },

        //         { label: "Shared Qualified List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLead" },



        //     ]
        // },

        {
            role: "Marketing Manager",
            buttons: [
                // { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSpecificTask" },
                { label: "Create Multi Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMultiTaskMarketing" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },

                { label: "Create Team Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingMarketing" },
                { label: " My Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                // { label: "Create Content Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname1}` },
                { label: "Upcoming Shows List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminShowList" },
                { label: "Create Lead", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/ShareLead" },
                { label: "Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SharedLeadList  " },
                { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLeadList" },
                // { label: "Content Task Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname1}` },

                // { label: "Create Email Writing Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname2}` },
                // { label: "Email-Content Writing Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname2}` },


                // { label: "Create Creativity & Design Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname3}` },
                // { label: " Creativity & Design Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname3}` },

                //{ label: "Create LinkedIn Notes Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname4}` },
                // { label: "LinkedIn Notes Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname4}` },

                //  { label: "Create Landing Page Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname5}` },
                // { label: "Website Page Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname5}` },

                { label: "PPT List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/PPTList" },
                { label: "Attach PPT ", className: "btn btn-danger btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/CreatePPT" },

            ]
        },

        {
            role: "Executive - BD",
            buttons: [
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSpecificTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "Upcoming Shows List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminShowList" },

            ]
        },


        {
            role: "Sr. Executive - Brand Strategy",
            buttons: [
                // { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSpecificTask" },
                { label: "Create Multi Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMultiTaskMarketing" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },

                { label: "Create Team Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingMarketing" },
                { label: " My Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                // { label: "Create Content Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname1}` },
                { label: "Upcoming Shows List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminShowList" },
                { label: "Create Lead", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/ShareLead" },
                { label: "Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SharedLeadList  " },
                { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLeadList" },
                // { label: "Content Task Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname1}` },

                // { label: "Create Email Writing Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname2}` },
              //  { label: "Email-Content Writing Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname2}` },


                // { label: "Create Creativity & Design Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname3}` },
                // { label: " Creativity & Design Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname3}` },

                //{ label: "Create LinkedIn Notes Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname4}` },
                // { label: "LinkedIn Notes Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname4}` },

                //  { label: "Create Landing Page Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname5}` },
                // { label: "Website Page Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname5}` },


                { label: "PPT List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/PPTList" },
                { label: "Attach PPT ", className: "btn btn-danger btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/CreatePPT" },
            ]
        },


        // {
        //     role: "Content Writer",
        //     buttons: [
        //         { label: "My Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
        { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
        //         { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
        //         // { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateTask" },
        //         { label: "Meeting List", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },

        //         { label: "Create Content Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname1}` },

        //         { label: "Content Task Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname1}` },

        //         { label: "Email Content Writing Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname2}` },


        //         { label: " Creativity & Design Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname3}` },

        //         { label: "LinkedIn Notes Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname4}` },

        //         { label: "Website Page Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname5}` },

        //         // { label: "Attach Content Link", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/AttachContentLink" },
        //         // { label: "Content List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ContentAttachmentList" },

        //     ]
        // },

        {
            role: "Assistant Manager - Creative Designer",
            buttons: [
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSpecificTask" },

                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                // { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateTask" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },

                // { label: "Create Content Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname2}` },

                // { label: "Content Task Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname2}` },

                // { label: "Email Content Writing Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname2}` },


                // { label: " Creativity & Design Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname3}` },

                // { label: "LinkedIn Notes Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname4}` },

                // { label: "Website Page Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname5}` },

                // { label: "Attach Content Link", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/AttachContentLink" },
                // { label: "Content List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ContentAttachmentList" },

            ]
        },


        //salesE

        // {
        //     role: "Sales Head",
        //     buttons: [
        //         { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateSalesTask" },
        //         { label: "My Tasks", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
        //    { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
        //         { label: "Assigned Tasks", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },

        //         { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLead" },

        //         { label: "Create RFP", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/CreateRFP" },
        //         { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/RFPList" },
        //         { label: "Create Meeting Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingSales" },
        //         { label: "Meetings List", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
        //         { label: "PPT List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/PPTList" },

        //     ]
        // },

        //accounts and commercial

        {
            role: "Commercial-Head-Projects",
            buttons: [
                { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateFinanceTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateFinanceTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Create Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeeting" },
                { label: "Client & Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/ClientVendorDashboard" },

                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "BOQ List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/BOQList" },
                { label: "RFP List", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                // { label: "Create Agreement", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateAgreement" },
                { label: "Agreement List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AgreementList" },
            ]
        },

        {
            role: "Senior Manager - Commercial",
            buttons: [
                { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateFinanceTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateFinanceTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Create Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeeting" },
                { label: "Client & Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/ClientVendorDashboard" },

                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "BOQ List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/BOQList" },
                { label: "RFP List", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                // { label: "Create Agreement", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateAgreement" },
                { label: "Agreement List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AgreementList" },
            ]
        },

        {
            role: "Manager - Experiential & Key Accounts",
            buttons: [
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSalesTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateSalesTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Tasks", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Tasks", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Create Meeting Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingSales" },
                { label: "Meetings List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "Upcoming Shows List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminShowList" },
                // { label: "Upcoming Shows List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/showList" },
                
                { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLeadList" },
                { label: "Create RFP", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/CreateRFP" },
                { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                { label: "Project List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: `/ProjectList` },
                // { label: "PPT List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/PPTList/${staticid}&${staticname16}` },
                { label: "PPT List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/PPTList" },
                { label: "Attach PPT ", className: "btn btn-danger btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/CreatePPT" },
                { label: "Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: "/SharedLeadList  " },

                { label: "RFP MasterSheet", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/RFPMasterSheet" },
            ]
        },

        {
            role: "Assistant General Manager - Domestic Sales",
            buttons: [
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSalesTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateSalesTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Tasks", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Tasks", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Create Meeting Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingSales" },
                { label: "Meetings List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "Upcoming Shows List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminShowList" },
                // { label: "Upcoming Shows List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/showList" },
                
                { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLeadList" },
                { label: "Create RFP", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/CreateRFP" },
                { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                { label: "Project List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: `/ProjectList` },
                // { label: "PPT List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/PPTList/${staticid}&${staticname16}` },
                { label: "PPT List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/PPTList" },
                { label: "Attach PPT ", className: "btn btn-danger btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/CreatePPT" },
                { label: "Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: "/SharedLeadList  " },
                { label: "RFP MasterSheet", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/RFPMasterSheet" },

            ]
        },

        {
            role: "Senior Manager - Sales & BD",
            buttons: [
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSalesTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateSalesTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Tasks", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Tasks", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Create Meeting Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingSales" },
                { label: "Meetings List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "Upcoming Shows List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminShowList" },
                // { label: "Upcoming Shows List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/showList" },
                
                { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLeadList" },
                { label: "Create RFP", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/CreateRFP" },
                { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                { label: "Project List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: `/ProjectList` },
                // { label: "PPT List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/PPTList/${staticid}&${staticname16}` },
                { label: "PPT List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/PPTList" },
                { label: "Attach PPT ", className: "btn btn-danger btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/CreatePPT" }, 
                { label: "Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SharedLeadList  " },
                { label: "RFP MasterSheet", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/RFPMasterSheet" },

            ]
        },


        {
            role: "Assistant General Manager - Business Development",
            buttons: [
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSalesTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateSalesTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Tasks", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Tasks", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Create Meeting Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingSales" },
                { label: "Meetings List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "Upcoming Shows List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminShowList" },
                // { label: "Upcoming Shows List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/showList" },
                
                { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLeadList" },
                { label: "Create RFP", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/CreateRFP" },
                { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                { label: "Project List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: `/ProjectList` },
                // { label: "PPT List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/PPTList/${staticid}&${staticname16}` },
                { label: "PPT List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/PPTList" },
                { label: "Attach PPT ", className: "btn btn-danger btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/CreatePPT" }, 
                { label: "Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: "/SharedLeadList" },
                { label: "RFP MasterSheet", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/RFPMasterSheet" },

            ]
        },
        //Pre-Sales

        {
            role: "Pre-Sales-Head",
            buttons: [
                { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateSalesTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateSalesTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "My Tasks", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Assigned Tasks", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },

                { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLeadList" },

                { label: "Create RFP", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/CreateRFP" },
                { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/RFPList" },
                { label: "Create Meeting Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingSales" },
                { label: "Meetings List", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "Project List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: `/ProjectList` },
                // { label: "PPT List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/PPTList/${staticid}&${staticname16}` },
                { label: "PPT List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/PPTList" },
                { label: "Attach PPT ", className: "btn btn-danger btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/CreatePPT" },
                { label: "RFP MasterSheet", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/RFPMasterSheet" },

            ]
        },

        // {
        //     role: "Pre-Sales-Executive",
        //     buttons: [
        //         // { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateSalesTask" },
        //         { label: "My Tasks", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
        { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },

        //         // { label: "Create RFP", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/CreateRFP" },
        //         { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/RFPList" },

        //         // { label: "Create Meeting Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingSales" },
        //         { label: "My Meetings", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },

        //         { label: "Attach PPT Link", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/CreatePPT" },
        //         { label: "PPT List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/PPTList" },

        //     ]
        // },

        {
            role: "Senior Executive - Marketing Communications",
            buttons: [
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSalesTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateSalesTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Tasks", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "My Meetings", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                { label: "Project List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: `/ProjectList` },
                // { label: "PPT List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/PPTList/${staticid}&${staticname16}` },
                 { label: "PPT List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/PPTList" },
                 { label: "Attach PPT ", className: "btn btn-danger btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/CreatePPT" },
                // { label: "Create Meeting Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingSales" },

                { label: "RFP MasterSheet", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/RFPMasterSheet" },

            ]
        },

        // {
        //     role: "Pre-Sales-Head",
        //     buttons: [
        //         { label: "marketing", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/DasboardMarketingOptions" },
        //         { label: "Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/DashboardSalesOptions" },
        //         { label: "pre-sales", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardPreSalesOptions" },
        //         { label: "Operations", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardOperationsOption" },
        //         { label: "i&c/Design", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/DashboardICDesignOptions" },
        //         { label: "Finance", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" }
        //     ]
        // },

        //I&C design

        // {
        //     role: "I&C-Head",
        //     buttons: [
        //         { label: "My Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
        { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
        //         { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
        //         { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateTask" },
        //         { label: "Create Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeeting" },
        //         { label: "Meeting List", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
        //         { label: "RFP List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/RFPList" },
        //         { label: "Attach Design", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateDesign" },
        //         { label: "Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/DesignList" },
        //     ]
        // },

        {
            role: "Deputy General Manager - Creative Head Immersive",
            buttons: [
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateDesignTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateDesignTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Create Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeeting" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                // { label: "Daily TimeSheet", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/UserSheet" },
                { label: "RFP List", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                { label: "Summary Sheet List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SummarySheetList" },
        // { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminDesign" },
                // { label: "RFP Final Design", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: `/RFPFinalDesign/${staticid}&${staticname19}` },
                // { label: "Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminDesign" },

            ]
        },

        {
            role: "Executive - 3D Modeling & Texturing Artist",
            buttons: [
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateDesignTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateDesignTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "Daily TimeSheet", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/UserSheet" },
                { label: "Daily TimeSheet List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/UserSheetList" },
                { label: "RFP List", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                // { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: "/AdminDesign" },
                // { label: "RFP Final Design", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: `/RFPFinalDesign/${staticid}&${staticname19}` },
            ]
        },

        {
            role: "Executive Conceptualizer Designer",
            buttons: [
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateDesignTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateDesignTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "Daily TimeSheet", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/UserSheet" },
                { label: "Daily TimeSheet List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/UserSheetList" },
                { label: "RFP List", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                // // { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: "/AdminDesign" },
                // // { label: "RFP Final Design", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: `/RFPFinalDesign/${staticid}&${staticname19}` },
            ]
        },

        {
            role: "EXECUTIVE-TECHNICAL DESIGN",
            buttons: [
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateDesignTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateDesignTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Daily TimeSheet", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/UserSheet" },
                { label: "Daily TimeSheet List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/UserSheetList" },
                { label: "RFP List", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                // { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: "/AdminDesign" },
                // { label: "RFP Final Design", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: `/RFPFinalDesign/${staticid}&${staticname19}` },
            ]
        },

        {
            role: "SENIOR EXECUTIVE- TECHNICAL DESIGN",
            buttons: [
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateDesignTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateDesignTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "Daily TimeSheet", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/UserSheet" },
                { label: "Daily TimeSheet List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/UserSheetList" },
                { label: "RFP List", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                // { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: "/AdminDesign" },
                // { label: "RFP Final Design", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: `/RFPFinalDesign/${staticid}&${staticname19}` },
            ]
        },

        {
            role: "Manager - Graphic Design",
            buttons: [
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateDesignTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateDesignTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "Daily TimeSheet", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/UserSheet" },
                { label: "Daily TimeSheet List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/UserSheetList" },
                { label: "RFP List", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                // { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: "/AdminDesign" },
                // { label: "RFP Final Design", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: `/RFPFinalDesign/${staticid}&${staticname19}` },
            ]
        },

        {
            role: "Senior Executive - 3D Generalist",
            buttons: [
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateDesignTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateDesignTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "Daily TimeSheet", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/UserSheet" },
                { label: "Daily TimeSheet List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/UserSheetList" },
                { label: "RFP List", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                // { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: "/AdminDesign" },
                // { label: "RFP Final Design", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: `/RFPFinalDesign/${staticid}&${staticname19}` },
            ]
        },

        {
            role: "Manager - 3D Production",
            buttons: [
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateDesignTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateDesignTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "RFP List", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                { label: "Daily TimeSheet", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/UserSheet" },
                { label: "Daily TimeSheet List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/UserSheetList" },
                // { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: "/AdminDesign" },
                // { label: "RFP Final Design", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: `/RFPFinalDesign/${staticid}&${staticname19}` },
            ]
        },

        {
            role: "Executive - 3D Modeler",
            buttons: [
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateDesignTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateDesignTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "RFP List", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                { label: "Daily TimeSheet List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/UserSheetList" },
                { label: "Daily TimeSheet", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/UserSheet" },
                // { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: "/AdminDesign" },
                // { label: "RFP Final Design", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: `/RFPFinalDesign/${staticid}&${staticname19}` },
            ]
        },

        {
            role: "Assistant Manager - Visual Designer",
            buttons: [
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateDesignTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateDesignTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "RFP List", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                { label: "Daily TimeSheet", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/UserSheet" },
                { label: "Daily TimeSheet List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/UserSheetList" },
                { label: "RFP MasterSheet", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/RFPMasterSheet" },
                // { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: "/AdminDesign" },
                // { label: "RFP Final Design", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: `/RFPFinalDesign/${staticid}&${staticname19}` },
            ]
        },

        {
            role: "Designer",
            buttons: [
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateDesignTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateDesignTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "RFP List", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                { label: "Daily TimeSheet", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/UserSheet" },
                { label: "Daily TimeSheet List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/UserSheetList" },
                // { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: "/AdminDesign" },
                // { label: "RFP Final Design", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: `/RFPFinalDesign/${staticid}&${staticname19}` },
            ]
        },


        {
            role: "Executive - Software Developer",
            buttons: [
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateDesignTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateDesignTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "RFP List", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                { label: "Daily TimeSheet", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/UserSheet" },
                { label: "Daily TimeSheet List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/UserSheetList" },
                // { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: "/AdminDesign" },
                // { label: "RFP Final Design", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: `/RFPFinalDesign/${staticid}&${staticname19}` },
            ]
        },
        {
            role: "Executive - Software Developer(SOP Tool)",
            buttons: [
                { label: "marketing", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/DasboardMarketingOptions" },
                { label: "Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/DashboardSalesOptions" },
                { label: "pre-sales", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardPreSalesOptions" },
                { label: "Operations", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardOperationsOption" },
                { label: "i&c/Design", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/DashboardICDesignOptions" },
                // { label: "Daily TimeSheet", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/UserSheet" },
                { label: "Finance", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardCommercialOptions" },
                { label: "Admin panel", className: "btn btn-danger btn-md font-weight-bold text-uppercase adminPanel ", to: "/" }
            ]
        },



        // {
        //     role: "Designer",
        //     buttons: [
        //         { label: "My Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
        { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },

        //         { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateDesignTask" },
        //         { label: "Meeting List", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
        //         { label: "RFP List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/RFPList" },
        //         { label: "Attach Design", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateDesign" },
        //         { label: "Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/DesignList" },
        //     ]
        // },

        {
            role: "Manager-Creative-Design",
            buttons: [
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateDesignTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateDesignTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "RFP List", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                { label: "Daily TimeSheet", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/UserSheet" },
                { label: "Daily TimeSheet List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/UserSheetList" },
                // { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: "/AdminDesign" },
                // { label: "RFP Final Design", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: `/RFPFinalDesign/${staticid}&${staticname19}` },
            ]
        },
        {
            role: "Senior Executive Design Drafting",
            buttons: [
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateDesignTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateDesignTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "RFP List", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                { label: "Daily TimeSheet", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/UserSheet" },
                { label: "Daily TimeSheet List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/UserSheetList" },
                // // { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: "/AdminDesign" },
                // // { label: "RFP Final Design", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: `/RFPFinalDesign/${staticid}&${staticname19}` },
            ]
        },
        {
            role: "Senior-Executive-Design Drafting",
            buttons: [
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateDesignTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateDesignTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "RFP List", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                { label: "Daily TimeSheet", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/UserSheet" },
                { label: "Daily TimeSheet List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/UserSheetList" },
                // { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: "/AdminDesign" },
                // { label: "RFP Final Design", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: `/RFPFinalDesign/${staticid}&${staticname19}` },
            ]
        },
        {
            role: "Assistant Manager - Creative Design",
            buttons: [
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateDesignTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateDesignTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "RFP List", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                { label: "Daily TimeSheet", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/UserSheet" },
                { label: "Daily TimeSheet List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/UserSheetList" },
                // { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: "/AdminDesign" },
                // { label: "RFP Final Design", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: `/RFPFinalDesign/${staticid}&${staticname19}` },
            ]
        },
        {
            role: "Assistant Manager - Technical Design",
            buttons: [
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateDesignTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateDesignTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "RFP List", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                { label: "Daily TimeSheet", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/UserSheet" },
                { label: "Daily TimeSheet List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/UserSheetList" },
                // { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: "/AdminDesign" },
                // { label: "RFP Final Design", className: "btn btn-primary btn-md font-weight-bold text-uppercase salesHeadRFP", to: `/RFPFinalDesign/${staticid}&${staticname19}` },
            ]
        },

        // Finance

        {
            role: "Finance-Head",
            buttons: [

                { label: "My Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateFinanceTask" },
                { label: "Create Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeeting" },
                { label: "Meeting List", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "Pre P&L List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/PrePLList" },
                { label: "Create Agreement", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateAgreement" },
                { label: "AgreementList", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/AgreementList" },
                { label: "Invoice", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/CreatePPT" },

            ]
        },

        {
            role: "Assistant General Manager - Finance & Accounts",
            buttons: [

                { label: "My Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateFinanceTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateFinanceTask" },
                { label: "Create Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeeting" },
                { label: "Meeting List", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "Pre P&L List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/PrePLList" },
                { label: "AgreementList", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/AgreementList" },
                { label: "Create Agreement", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateAgreement" },
                { label: "Invoice", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/CreatePPT" },

            ]
        },
        {
            role: "Deputy Manager - Finance & Accounts",
            buttons: [

                { label: "My Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateFinanceTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateFinanceTask" },
                { label: "Create Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeeting" },
                { label: "Meeting List", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "Pre P&L List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/PrePLList" },
                { label: "Create Agreement", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateAgreement" },
                { label: "AgreementList", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/AgreementList" },
                { label: "Invoice", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/CreatePPT" },

            ]
        },

        {
            role: "Sr. Executive - Accounts",
            buttons: [

                { label: "My Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateFinanceTask/${staticName17}` },
                { label: "Requested Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateFinanceTask" },
                { label: "Create Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeeting" },
                { label: "Meeting List", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "Pre P&L List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/PrePLList" },
                { label: "Create Agreement", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateAgreement" },
                { label: "AgreementList", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/AgreementList" },
                { label: "Invoice", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/CreatePPT" },

            ]
        },

        {
            role: "Asst Manager - SEO ",
            buttons: [

                { label: "My Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateTask" },
                { label: "Meeting List", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },

            ]
        },

        {
            role: "Senior Executive - Digital Marketing ",
            buttons: [

                { label: "My Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateTask" },

            ]
        },

        {
            role: "Senior Executive - Creative Designer",
            buttons: [

                { label: "My Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateTask" },
                { label: "Meeting List", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },

            ]
        },

        {
            role: "Intern - Digital Marketing ",
            buttons: [

                { label: "My Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateTask" },

            ]
        },

        {
            role: "Executive - Market Research ",
            buttons: [

                { label: "My Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateTask" },

            ]
        },

        // {
        //     role: "GM-Commercial",
        //     buttons: [
        //         { label: "marketing", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/DasboardMarketingOptions" },
        //         { label: "Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/DashboardSalesOptions" },
        //         { label: "pre-sales", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardPreSalesOptions" },
        //         { label: "Operations", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardOperationsOption" },
        //         { label: "i&c/Design", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/DashboardICDesignOptions" },
        //         { label: "Finance", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardCommercialOptions" }
        //     ]
        // },

        // {
        //     role: "Commercial-Head-Projects",
        //     buttons: [
        //         { label: "marketing", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/DasboardMarketingOptions" },
        //         { label: "Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/DashboardSalesOptions" },
        //         { label: "pre-sales", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardPreSalesOptions" },
        //         { label: "Operations", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardOperationsOption" },
        //         { label: "i&c/Design", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/DashboardICDesignOptions" },
        //         { label: "Finance", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/DashboardCommercialOptions" }
        //     ]
        // },

    ];
    return (
        <div>
            <div className="container-fluid">
                <div className="card shadow border  border-success mb-2">
                    <div className="card-header rounded py-3">
                        <div className="row ">
                            
                            <div className="col-sm-4"><h2><b className='text-dark'>Dashboard</b></h2></div>
                            <div className="col-sm-3"></div>
                            <div className="col-sm-5 d-flex flex-row-reverse">
                                {/* <button className="btn btn-info text-uppercase  ml-1 mt-2  " onClick={() => backbutton()}>Back</button> */}
                                <Link to="/" className="btn btn-secondary text-uppercase  ml-1 mt-2 ">Main</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="card cardUser shadow mb-2">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-success">Dashboard</h6>
                    </div>
                    <div className="container" style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                        <div className="row">
                            {roleButtons.map((roleButtonGroup, index) => (
                                (roleButtonGroup.role === role) && (
                                    roleButtonGroup.buttons.map((button, buttonIndex) => (
                                        <div key={buttonIndex} className="col-xl-4 col-md-8">
                                            <div className="card border-success shadow p-1 mt-4 ml-3">
                                                <div className="btn-group">
                                                    <Link to={button.to} className={button.className} type="button">
                                                        {button.label}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )
                            ))}
                            {role === "Executive - Data Extractor" && (<div className="container-fluid">


                                <div className="container" style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
                                    <div className="row">
                                        {rolebuttonDataExtractor.map((roleButtonGroup, index) => (
                                            (roleButtonGroup.role === role) && (
                                                roleButtonGroup.buttons.map((button, buttonIndex) => (
                                                    <div key={buttonIndex} className="col-xl-4 col-md-8">
                                                        <div className="card border-success mt-2 shadow ml-2">
                                                            <div className="btn-group">
                                                                <Link to={button.to} className={button.className} type="button">
                                                                    {button.label}
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            )
                                        ))}

                                        <div className="col-xl-4 col-md-8">
                                            <div className="card border-success mt-2 shadow ml-2">
                                                <div className="btn-group">
                                                    <button className="btn btn-primary  btn-md font-weight-bold text-uppercase lifeTool" onClick={handleLifeToolButtonClick} type="button">
                                                        Life Tool
                                                    </button>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>



                            </div>)}
                        </div>
                    </div>
                </div>
            </div>

            {/* {role === "Data Extractor" && (<div className="container-fluid">
                <div className="card cardUser shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Sales Department</h6>
                    </div>
                    <div className="container" style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
                        <div className="row">
                            {rolebuttonDataExtractor.map((roleButtonGroup, index) => (
                                (roleButtonGroup.role === role) && (
                                    roleButtonGroup.buttons.map((button, buttonIndex) => (
                                        <div key={buttonIndex} className="col-xl-4 col-md-8">
                                            <div className="card border-success shadow ml-2">
                                                <div className="btn-group">
                                                    <Link to={button.to} className={button.className} type="button">
                                                        {button.label}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )
                            ))}

                            <div className="col-xl-4 col-md-8">
                                <div className="card border-success shadow mt-2 ml-2">
                                    <div className="btn-group">
                                        <button className="btn btn-primary btn-md font-weight-bold text-uppercase" onClick={handleLifeToolButtonClick} type="button">
                                            Life Tool
                                        </button>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>


                </div>
            </div>)} */}
        </div>
    )
}

export default AdminDashboardComponent;
