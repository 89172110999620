import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import Service from "../../../Services/Admin/Service";
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';


const VendorCostingListComponent = (props) => {

    const [data, setData] = useState([]);


    useEffect(() => {
        getVendorData("Vendor/GetAll");

    }, [])

    const getVendorData = (method) => {
        Service.getdata(method).then((res) => {
            setData(res.data);

        });
    }

    const backbutton = () => {
        props.navigate(-1);
    }



    const linkformatter = (data, row) => {
        return <>
            <Link className="btn btn-warning m-1 btn-sm" to={`/CreateVandour/${data}`}>Edit</Link>
            <Link className="btn btn-warning m-1 btn-sm" to={`/VendorDetails/${data}`}>Details</Link>
        </>
    }

    const column = [
        { dataField: 'supplierName', text: 'Supplier Name', sort: true },

        { dataField: 'id', text: 'Actions', formatter: linkformatter }
    ]

    return (
        <div className="container-fluid">
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <div className="row">
                        <div className="col-sm-3"><h3>Vendor Costing List</h3></div>

                        <div className="col-sm-6"></div>
                        <div className="col-sm-2 d-flex flex-row-reverse">
                            <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                            <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">List</h6>
                    {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
                </div>

                <div className="card-body">
                    <div className="table-responsive">
                        <BootstrapTable keyField="id"
                            data={data}
                            columns={column}

                            pagination={paginationFactory()}
                        // filter={filterFactory()}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VendorCostingListComponent