import React, { Component } from 'react' 
import CreateTaskComponent from '../../../Components/Main/Task/CreateTaskComponent';

import { useNavigate, useParams } from 'react-router-dom';

function CreateTask(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <CreateTaskComponent {...props} navigate={navigate} id={params.id} name={params.name} />
        </div>
    );  
  }
  
export default CreateTask  