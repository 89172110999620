import React, { useEffect, useState } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import Service from '../../../Services/Admin/Service';

const DenyExtractedDataListComponent = () => {

    const [data, setData] = useState([]);

    useEffect(() => {
        getExtracetdData("ExtractedData/GetAll");
    }, []);

    const getExtracetdData = (method) => {
        Service.getdata(method).then((res) => {
            setData(res.data);
            console.log(res.data);
            debugger
        });

    }

    const checkboxFormatter = (cell, row) => {
        return (
            <input type="checkbox" name={`checkbox-${row.id}`} />
        );
    };

    const linkformatter = (data, row) => {
        return <>
            {/* <Link className="btn btn-warning btn-sm" to={`/CreateMeeting/${data}`}>Edit</Link> &nbsp; */}
            <Link className="btn btn-warning btn-sm" to={`/ExtractedDataDetails/${data}`}>Details</Link>
            <Link className="btn btn-warning btn-sm ml-1" to={`/ExtractedDataIsApproved/${data}`}>Is Approved</Link>
        </>
    }


    const column = [
        // { dataField: 'checkbox', text: 'Select', formatter: checkboxFormatter }, 
        // { dataField: 'leadId', text: 'Lead Id', sort: true },
        { dataField: 'country', text: 'Country' },
        { dataField: 'objective', text: 'Objective', sort: true },
        { dataField: 'fitment', text: 'Fitment', sort: true },
        { dataField: 'cycle', text: 'Cycle', sort: true },
        { dataField: 'noofvisitors', text: 'No of Visitors', sort: true },
        { dataField: 'totalspace', text: 'Total Space', sort: true },
        { dataField: 'concurrentusers', text: 'Concurrenr Users', sort: true },
        { dataField: 'id', text: 'Actions', formatter: linkformatter }
    ]


  return (
    <div>
    <div className="container-fluid">
        <div className="card shadow mb-4">
            <div className="card-header py-3">
                <div className="row">
                    <div className="col-sm-3"><h3>Deny Extracted Data List</h3></div>
                    <div className="col-sm-6"></div>
                    <div className="col-sm-3">
                        <Link to="/AdminDashboard" className="btn btn-secondary mt-1 ml-2 btn-sm">Home</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className="card shadow mb-4">
            <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">Denied List</h6>
            </div>

            <div className="card-body">
                <div className="table-responsive">
                    <BootstrapTable keyField="id"
                        data={data}
                        columns={column}
                        pagination={paginationFactory()}
                    />
                </div>
            </div>
        </div>
        {/* <button type="submit" class="btn btn-primary ml-3 mb-3">Save</button> */}
    </div>
</div>
  )
}

export default DenyExtractedDataListComponent