import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import SharedLeadDetailsComponent from '../../../Components/Main/LeadComponent/SharedLeadDetailsComponent';


function SharedLeadDetails(props) {
    let navigate = useNavigate();
    const params = useParams();
    return (  
        <div> 
            <SharedLeadDetailsComponent {...props} navigate={navigate} id={params.id} name={params.name} />
        </div>
    );  
  }
export default SharedLeadDetails  