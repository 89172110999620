import React, { Component } from 'react' 
import { useNavigate, useParams } from 'react-router-dom';
import AdminShowListComponent from '../../../Components/Main/LifeToolComponent/AdminShowListComponent';


function AdminShowList(props) {
    let navigate = useNavigate();
    const params = useParams();
    return (  
        <div> 
            <AdminShowListComponent {...props} navigate={navigate} id={params.id} />
        </div>
    );  
  }
  
export default AdminShowList;  