import React from 'react'

const method = [
    { 
        name:'Customer',
        get:'Customer/GetAllCustomers',
        getbyId:'Customer/GetCustomerById?id=',
        createorupdate:'Customer/CreateOrUpdateCustomers',
        delete:'Customer/DeleteCustomer?id=',
        filter:'',
        others:'' 
    },
    { 
        name:'Role',
        get:'CustomerRole/GetAllCustomerRole',
        getbyId:'CustomerRole/GetCustomerRoleById?id=',
        createorupdate:'CustomerRole/CreateOrUpdateCustomers',
        delete:'DeleteCustomer?id=',
        filter:'',
        others:'' 
    },
    { 
        name:'Category',
        get:'Category/GetAllCategory',
        getbyId:'Category/GetCategoryById?id=',
        createorupdate:'Category/CreateOrUpdateCategory',
        delete:'DeleteCategory?id=',
        filter:'',
        others:'' 
    },
    { 
        name:'Manufacturer',
        get:'Manufacturer/GetAllManufacturer',
        getbyId:'Manufacturer/GetManufacturerById?id=',
        createorupdate:'Manufacturer/CreateOrUpdateManufacturer',
        delete:'DeleteCategory?id=',
        filter:'',
        others:'' 
    },
    { 
        name:'Product',
        get:'Product/GetAllProducts',
        getbyId:'Product/GetProductById?id=',
        createorupdate:'Product/CreateOrUpdateProduct',
        delete:'',
        filter:'',
        others:'' 
    },
    { 
        name:'Discount',
        get:'Discount/GetAllDiscount',
        getbyId:'Discount/GetDiscountById?id=',
        createorupdate:'Discount/CreateOrUpdateDiscount',
        delete:'Discount/DeleteDiscount?id=',
        filter:'',
        others:'Discount/GetAllDiscountType' 
    },
    { 
        name:'TaxCategory',
        get:'TaxCategory/GetAllTaxCategory',
        getbyId:'TaxCategory/GetTaxCategoryById?id=',
        createorupdate:'TaxCategory/CreateOrUpdateTaxCategory',
        delete:'TaxCategory/DeleteTaxCategory?id=',
        filter:'',
        others:'' 
    },
    { 
        name:'Picture',
        get:'Product/GetAllPictures?id=',
        getbyId:'Product/GetAllPictures?id=',
        createorupdate:'Product/CreateOrUpdatePictures',
        delete:'Product/DeleteProductPicture?id=',
        filter:'',
        others:'' 
    },
    { 
        name:'ProductAttribute',
        get:'ProductAttribute/GetAllProductAttribute',
        getbyId:'ProductAttribute/GetProductAttributeById?id=',
        createorupdate:'ProductAttribute/CreateOrUpdateProductAttribute',
        delete:'ProductAttribute/DeleteProductAttribute?id=',
        filter:'',
        others:'' 
    },
    { 
        name:'ProductAttributeDetail',
        get:'ProductAttributeDetails/GetAllProductAttributeDetail',
        getbyId:'ProductAttributeDetails/GetProductAttributeDetailById?id=',
        createorupdate:'ProductAttributeDetails/CreateOrUpdateProductAttributeDetail',
        delete:'ProductAttributeDetails/DeleteProductAttributeDetail?id=',
        filter:'',
        others:'' 
    },
    { 
        name:'ProductAttributeValue',
        get:'ProductAttributeDetails/GetAllAttributeValue',
        getbyId:'ProductAttributeDetails/GetAttributeValueById?id=',
        createorupdate:'ProductAttributeDetails/CreateOrUpdateAttributeValue',
        delete:'ProductAttributeDetails/DeleteAttributeValue?id=',
        filter:'',
        others:'' 
    },
    { 
        name:'EmailAccount',
        get:'EmailAccounts/GetAllEmailAccount',
        getbyId:'EmailAccounts/GetEmailAccountById?id=',
        createorupdate:'EmailAccounts/CreateOrUpdateEmailAccount',
        delete:'EmailAccounts/DeleteEmailAccount?id=',
        filter:'',
        others:'' 
    },
    { 
        name:'Country',
        get:'Country/GetAllCountry',
        getbyId:'Country/GetCountryById?id=',
        createorupdate:'Country/CreateOrUpdateCountry',
        delete:'Country/DeleteCountry?id=',
        filter:'',
        others:'' 
    },
    { 
        name:'State',
        get:'State/GetAllState',
        getbyId:'State/GetStateById?id=',
        createorupdate:'State/CreateOrUpdateState',
        delete:'State/DeleteCountry?id=',
        filter:'',
        others:'' 
    },
    { 
        name:'ProductReview',
        get:'ProductReview/GetAllProductReview',
        getbyId:'ProductReview/GetProductReviewById?id=',
        createorupdate:'ProductReview/CreateOrUpdateProductReview',
        delete:'ProductReview/DeleteProductReview?id=',
        filter:'',
        others:'' 
    },
    { 
        name:'Newsletter',
        get:'NewsletterSubscription/GetAllNewsLetterSubscription',
        getbyId:'NewsletterSubscription/GetNewsLetterSubscriptionById?id=',
        createorupdate:'NewsletterSubscription/CreateOrUpdateNewsLetterSubscription',
        delete:'NewsletterSubscription/DeleteNewsLetterSubscription?id=',
        filter:'',
        others:'' 
    },
    { 
        name:'Poll',
        get:'Poll/GetAllPoll',
        getbyId:'Poll/GetPollById?id=',
        createorupdate:'Poll/CreateOrUpdatePoll',
        delete:'Poll/DeletePoll?id=',
        filter:'',
        others:'' 
    },
    { 
        name:'PollAnswer',
        get:'Poll/GetAllPollAnswer',
        getbyId:'Poll/GetPollAnswerById?id=',
        createorupdate:'Poll/CreateOrUpdatePollAnswer',
        delete:'Poll/DeletePollAnswer?id=',
        filter:'',
        others:'' 
    },
    { 
        name:'Setting',
        get:'Setting/GetAllSetting',
        getbyId:'Setting/GetSettingById?id=',
        createorupdate:'Setting/CreateOrUpdateSetting',
        delete:'Setting/DeleteSetting?id=',
        filter:'',
        others:'Setting/GetAllSettingByName?Name=' 
    },
    { 
        name:'Topic',
        get:'Topic/GetAllTopic',
        getbyId:'Topic/GetTopicById?id=',
        createorupdate:'Topic/CreateOrUpdateTopic',
        delete:'Topic/DeleteTopic?id=',
        filter:'',
        others:'' 
    },
]

export default method