import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import HomeComponent from '../../../Components/Main/HomeComponent/HomeComponent';

function Home(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <HomeComponent {...props} navigate={navigate} id={params.id}  />
        </div>
    );  
  }
export default Home  