import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import DasboardMarketingOptionsComponent from '../../../Components/Main/DashboardOptionsComponent/DasboardMarketingOptionsComponent';

function DasboardMarketingOptions(props){
    let navigate = useNavigate();
    const params = useParams();
     
     return (  
         <div> 
             <DasboardMarketingOptionsComponent {...props}  navigate={navigate} />
         </div>
     );   

}

export default DasboardMarketingOptions