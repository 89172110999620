import React, { Component } from 'react' 
import { useNavigate } from 'react-router-dom';
import UnloadingMaterialChecklistComponent from '../../../Components/Main/PermitAndPassesComponent/UnloadingMaterialChecklistComponent';

function UnloadingMaterialChecklist(props) {
    let navigate = useNavigate();
    return (  
        <div> 
            <UnloadingMaterialChecklistComponent {...props} navigate={navigate} />
        </div>
    );  
  }
  
export default UnloadingMaterialChecklist  