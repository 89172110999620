import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { getLoginUserData } from '../../Helpers/supportedFunctions/supportedGlobalFunctions';
import logo from "../../../src/xsnxtlight.png"
import { AiFillFilePpt  } from "react-icons/ai";
import { BsFiletypePpt } from "react-icons/bs";
export class Navbar extends Component {
    render() {
        return (

            <div className="navbar-nav collapse sidebar sidebar-dark " id='navbarToggle' style={{ backgroundColor: "rgb(0 141 58)", color: "black", minWidth: "270px", marginTop: "62px" }}>
                <div style={{ maxHeight: "90vh", overflowY: "auto", scrollbarColor: "blue" }}>

                    <li className='nav-item text-center'>
                        <img src={logo} style={{ height: "78px", width: "170px" }} alt="" className='navbar-logo' />
                    </li>
                    <hr className="sidebar-divider d-none d-md-block" />
                    <li className="nav-item ">
                        <Link className="nav-link" to={"/"}>
                            <i className="fas fa-fw fa-tachometer-alt"></i>
                            <span className="hover1">Dashboard</span></Link>
                    </li>
                    <hr className="sidebar-divider" />
                    {/* home button */}
                    {(getLoginUserData().role === "Admin" || getLoginUserData().userId === "47") &&
                        <>
                            <li className="nav-item dropdown">
                                <span className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" >

                                    <i class="fa-solid fa-house-chimney"></i>
                                    <span className="hover1">Home</span>
                                </span>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style={{ minWidth: "13.6rem", backgroundColor: "rgb(43 175 83)" }}>
                                    <Link className="dropdown-item" to={"/DasboardMarketingOptions"}>
                                        <i class="fa-solid fa-headset hover-item mr-1"></i>
                                        <span className="hover-item">Marketing</span>
                                    </Link>

                                    <Link className="dropdown-item" to={"/DashboardSalesOptions"}>
                                        {/* <i class="fa-regular fa-table-list mr-1"></i> */}
                                        <i class="fa-solid fa-chart-line hover-item mr-1"></i>
                                        <span className="hover-item">Sales</span>
                                    </Link>

                                    <Link className="dropdown-item" to={"/DashboardICDesignOptions"}>
                                        {/* <i class="fa-regular fa-table-list mr-1"></i> */}
                                        <i class="fa-solid fa-swatchbook hover-item mr-1"></i>
                                        <span className="hover-item">I&C Design</span>
                                    </Link>

                                    <Link className="dropdown-item" to={"/DashboardPreSalesOptions"}>
                                        {/* <i class="fa-regular fa-table-list mr-1"></i> */}
                                        <i class="fa-solid fa-cart-plus hover-item mr-1"></i>
                                        <span className="hover-item">Pre Sales</span>
                                    </Link>

                                    <Link className="dropdown-item" to={"/DashboardOperationsOption"}>
                                        {/* <i class="fa-regular fa-table-list mr-1"></i> */}
                                        <i class="fa-solid fa-truck hover-item mr-1"></i>
                                        <span className="hover-item">Operations</span>
                                    </Link>

                                    <Link className="dropdown-item" to={"/DashboardCommercialOptions"}>
                                        {/* <i class="fa-regular fa-table-list mr-1"></i> */}
                                        <i class="fa-solid fa-file-invoice-dollar hover-item mr-2"></i>
                                        <span className="hover-item">Finance</span>
                                    </Link>
                                </div>
                            </li>
                            <hr className="sidebar-divider" />
                        </>
                    }

                    {/* home button */}

                    {/* {getLoginUserData().deptId === "4" &&
                    <>
                        <li className="nav-item dropdown">
                            <span className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" >
                                <i class="fa-solid fa-house-chimney"></i>
                                <span className="hover1">Home</span>
                            </span>
                            <div className="dropdown-menu " aria-labelledby="navbarDropdownMenuLink" style={{ minWidth: "13.6rem", backgroundColor: "rgb(43 175 83)" }}>
                                <Link className="dropdown-item" to="/DashboardSalesOptions">
                                    <i class="fa-solid fa-chart-line mr-1"></i>Sales
                                </Link>

                                <Link className="dropdown-item" to="/DashboardOperationsOption">
                                    <i class="fa-solid fa-cart-flatbed-suitcase mr-1"></i>Operations
                                </Link>

                            </div>
                        </li>
                        <hr className="sidebar-divider" />
                    </>
                } */}

                    {/* end home button */}

                    {/* tasks menu */}
                    <li className="nav-item dropdown">
                        <span className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" >
                            <i class="fa-solid fa-list-check"></i>
                            <span className="hover1">Tasks</span>
                        </span>
                        <div className="dropdown-menu " aria-labelledby="navbarDropdownMenuLink" style={{ minWidth: "13.6rem", backgroundColor: "rgb(43 175 83)" }}>
                            {/* for operations */}
                            {getLoginUserData().deptId === "4" &&
                                <Link className="dropdown-item" to="/CreateMultiTask">
                                    <i class="fa-solid fa-clipboard hover-item mr-1"></i>
                                    <span className='hover-item'>Create Tasks</span>
                                </Link>}
                            {/*  end for operations */}
                            {/* for design */}
                            {getLoginUserData().deptId === "3" && <Link className="dropdown-item" to="/CreateDesignTask">
                                <i class="fa-solid fa-clipboard hover-item mr-1"></i>
                                <span className='hover-item'>Create Tasks</span>

                            </Link>}
                            {/*  end for design */}
                            {/* for sales */}
                            {getLoginUserData().deptId === "2" && <Link className="dropdown-item" to="/CreateSalesTask">
                                <i class="fa-solid fa-clipboard hover-item mr-1"></i>
                                <span className='hover-item'>Create Tasks</span>

                            </Link>}
                            {/*  end for sales */}
                            {/* for marketing */}
                            {getLoginUserData().deptId === "1" && <Link className="dropdown-item" to="/CreateMultiTaskMarketing">
                                <i class="fa-solid fa-clipboard hover-item mr-1"></i>
                                <span className='hover-item'>Create Tasks</span>

                            </Link>}
                            {/*  end for marketing */}
                            {/* for accounts */}
                            {getLoginUserData().deptId === "5" && <Link className="dropdown-item" to="/CreateFinanceTask">
                                <i class="fa-regular fa-clipboard hover-item mr-1"></i>
                                <span className='hover-item'>Create Tasks</span>

                            </Link>}
                            {/*  end for accounts */}
                            <Link className="dropdown-item" to="/KanbanMyTask">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard-data hover-item mr-1" viewBox="0 0 16 16">
                                    <path d="M4 11a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0zm6-4a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0zM7 9a1 1 0 0 1 2 0v3a1 1 0 1 1-2 0z" />
                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                                </svg><span className='hover-item'>My Tasks</span></Link>
                            <Link className="dropdown-item" to="/KanbanAssignedTask">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard-pulse hover-item mr-1" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M10 1.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5zm-5 0A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5v1A1.5 1.5 0 0 1 9.5 4h-3A1.5 1.5 0 0 1 5 2.5zm-2 0h1v1H3a1 1 0 0 0-1 1V14a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3.5a1 1 0 0 0-1-1h-1v-1h1a2 2 0 0 1 2 2V14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3.5a2 2 0 0 1 2-2m6.979 3.856a.5.5 0 0 0-.968.04L7.92 10.49l-.94-3.135a.5.5 0 0 0-.895-.133L4.232 10H3.5a.5.5 0 0 0 0 1h1a.5.5 0 0 0 .416-.223l1.41-2.115 1.195 3.982a.5.5 0 0 0 .968-.04L9.58 7.51l.94 3.135A.5.5 0 0 0 11 11h1.5a.5.5 0 0 0 0-1h-1.128z" />
                                </svg><span className='hover-item'>Assigned Tasks</span></Link>
                            <Link className="dropdown-item" to="/KanbanMyArchievedTask">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard-x hover-item mr-1" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M6.146 7.146a.5.5 0 0 1 .708 0L8 8.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 9l1.147 1.146a.5.5 0 0 1-.708.708L8 9.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 9 6.146 7.854a.5.5 0 0 1 0-.708" />
                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                                </svg><span className='hover-item'>Closed Tasks</span></Link>
                            {/*

                     */}
                        </div>
                    </li>
                    <hr className="sidebar-divider" />

                    {/* sales sidebar start */}
                    {(getLoginUserData().deptId === "2" || getLoginUserData().deptId === "3" || getLoginUserData().deptId === "4") &&
                        <>
                            <li className="nav-item dropdown">
                                <span className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" >

                                    <i class="fa-solid fa-layer-group"></i>
                                    <span className="hover1 ">RFP</span>
                                </span>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style={{ minWidth: "13.6rem", backgroundColor: "rgb(43 175 83)" }}>
                                    <Link className="dropdown-item" to={"/CreateRFP"}>
                                        <i class="fa-solid fa-pen-to-square hover-item"></i>
                                        <span className="hover-item">Create RFP</span>
                                    </Link>

                                    <Link className="dropdown-item" to={"/RFPList"}>
                                        {/* <i class="fa-regular fa-table-list mr-1"></i> */}
                                        <i class="fa fa-table hover-item mr-1"></i>
                                        <span className="hover-item">RFP List</span>
                                    </Link>
                                </div>
                            </li>
                            <hr className="sidebar-divider" />
                        </>
                    }
                    {((getLoginUserData().deptId === "3" || getLoginUserData().role === "Admin") && getLoginUserData().userId !== "38") &&
                        <>
                            <li className="nav-item dropdown">
                                <span className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" >

                                    <i class="fa-solid fa-calendar-days"></i>
                                    <span className="hover1 ">Daily Calendar</span>
                                </span>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style={{ minWidth: "13.6rem", backgroundColor: "rgb(43 175 83)" }}>
                                    <Link className="dropdown-item" to={"/UserSheet"}>
                                        <i class="fa-solid fa-pen-to-square hover-item"></i>
                                        <span className="hover-item">Daily Timesheet</span>
                                    </Link>

                                    <Link className="dropdown-item" to={"/RFPList"}>
                                        {/* <i class="fa-regular fa-table-list mr-1"></i> */}
                                        <i class="fa fa-table hover-item mr-1"></i>
                                        <span className="hover-item">Daily Timesheet List</span>
                                    </Link>
                                </div>
                            </li>
                            <hr className="sidebar-divider" />
                        </>
                    }
                    {/* sales sidebar end */}

                    {(getLoginUserData().deptId === "4" || getLoginUserData().role === "Admin") &&
                        <>
                            <li className="nav-item ">
                                <Link className="nav-link" to={"/ProjectList"}>
                                    <i class="fa-solid fa-users-gear mr-1"></i>
                                    <span className="hover1">Project List</span>
                                </Link>
                            </li>
                            <hr className="sidebar-divider" />

                            <>
                                <li className="nav-item dropdown">
                                    <span className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" >

                                        <i class="fa-solid fa-clock "></i>
                                        <span className="hover1 ">Project Execution</span>
                                    </span>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style={{ minWidth: "13.6rem", backgroundColor: "rgb(43 175 83)" }}>
                                        <Link className="dropdown-item" to={"/CreateProjectExecution"}>
                                            <i class="fa-solid fa-pen-to-square hover-item"></i>
                                            <span className="hover-item">Create Project Execution</span>
                                        </Link>

                                        <Link className="dropdown-item" to={"/ProjectwiseExecutionTrackerList"}>
                                            {/* <i class="fa-regular fa-table-list mr-1"></i> */}
                                            <i class="fa fa-table mr-1 hover-item"></i>
                                            <span className="hover-item">Project Execution List</span>
                                        </Link>
                                    </div>
                                </li>
                                <hr className="sidebar-divider" />
                            </>

                            {/* vendor and client start*/}
                            <>
                                <li className="nav-item dropdown">
                                    <span className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" >

                                        {/* <i class="fa-solid fa-users-line"></i> */}
                                        <i class="fa-solid fa-truck"></i>
                                        <span className="hover1">Client & Vendor</span>
                                    </span>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style={{ minWidth: "13.6rem", backgroundColor: "rgb(43 175 83)" }}>
                                        <Link className="dropdown-item" to={"/CreateVandour"}>
                                            <i class="fa-sharp fa-solid fa-truck-fast mr-1 hover-item"></i>
                                            <span className="hover-item">Create Vendor</span>
                                        </Link>

                                        <Link className="dropdown-item" to={"/CreateSelectVendor"}>
                                            <i class="fa-solid fa-truck-ramp-box mr-1 hover-item"></i>

                                            <span className="hover-item">Select Vendor</span>
                                        </Link>

                                        <Link className="dropdown-item" to={"/VendorList"}>
                                            <i class="fa-solid fa-list-ol mr-1 hover-item"></i>
                                            <span className="hover-item">Vendor List</span>
                                        </Link>

                                        <Link className="dropdown-item" to={"/CreateVendorQuotation"}>
                                            <i class="fa-solid fa-money-check-dollar mr-1 hover-item"></i>
                                            <span className="hover-item">Create Vendor Costing</span>
                                        </Link>

                                        <Link className="dropdown-item" to={"/VendorClientQuotationList"}>
                                            <i class="fa-solid fa-list-ol mr-1 hover-item"></i>
                                            <span className="hover-item">Vendor Costing List</span>
                                        </Link>

                                        <Link className="dropdown-item" to={"/SelectVendorList"}>
                                            <i class="fa-solid fa-list-check mr-1 hover-item"></i>
                                            <span className="hover-item">Project Selecetd Vendors</span>
                                        </Link>

                                        <Link className="dropdown-item" to={"/CreateClientQuotation"}>
                                            <i class="fa-solid fa-money-check-dollar mr-1 hover-item"></i>
                                            <span className="hover-item">Create Client Costing</span>
                                        </Link>

                                        <Link className="dropdown-item" to={"/ClientListQuotation"}>
                                            <i class="fa-solid fa-list-ol mr-1 hover-item"></i>
                                            <span className="hover-item">Client Costing List</span>
                                        </Link>
                                    </div>
                                </li>
                                <hr className="sidebar-divider" />
                            </>
                            {/* vendor and client end*/}

                        </>
                    }
                    {/* rfp mastersheet */}
                    {(getLoginUserData().deptId === "4" || getLoginUserData().deptId === "2" || getLoginUserData().role === "Admin") &&
                        <>
                            <li className="nav-item ">
                                <Link className="nav-link" to={"/RFPMasterSheet"}>
                                    <i class="fa-solid fa-file-csv"></i>
                                    <span className="hover1">RFP MasterSheet</span>
                                </Link>
                            </li>
                            <hr className="sidebar-divider" />
                        </>
                    }
                    {(getLoginUserData().userId === "47" || getLoginUserData().role === "Admin" || getLoginUserData().userId === "38") &&
                        <>
                            <li className="nav-item dropdown">
                                <span className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" >

                                    <i class="fa-solid fa-boxes-stacked"></i>
                                    <span className="hover1">MasterSheet</span>
                                </span>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style={{ minWidth: "13.6rem", backgroundColor: "rgb(43 175 83)" }}>
                                    <Link className="dropdown-item" to={"/RFPMasterSheet"}>
                                        <i class="fa fa-table mr-1 hover-item mr-1"></i>
                                        <span className="hover-item">RFP Master Sheet</span>
                                    </Link>

                                    <Link className="dropdown-item" to={"/SummarySheetList"}>
                                        {/* <i class="fa-regular fa-table-list mr-1"></i> */}
                                        <i class="fa fa-table mr-1 hover-item mr-1"></i>
                                        <span className="hover-item">Summary Sheet List</span>
                                    </Link>
                                </div>
                            </li>
                            <hr className="sidebar-divider" />
                        </>
                    }

                    {/* prepl start */}

                    {(getLoginUserData().deptId === "4" || getLoginUserData().role === "Admin") &&
                        <>
                            <li className="nav-item dropdown">
                                <span className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" >

                                    <i class="fa-solid fa-boxes-stacked"></i>
                                    <span className="hover1">Pre P&L</span>
                                </span>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style={{ minWidth: "13.6rem", backgroundColor: "rgb(43 175 83)" }}>
                                    <Link className="dropdown-item" to={"/CreatePrePL"}>
                                        <i class="fa-solid fa-pen-to-square hover-item mr-1"></i>
                                        <span className="hover-item">Create pre P&L</span>
                                    </Link>

                                    <Link className="dropdown-item" to={"/PrePLList"}>
                                        {/* <i class="fa-regular fa-table-list mr-1"></i> */}
                                        <i class="fa fa-table mr-1 hover-item mr-1"></i>
                                        <span className="hover-item">pre p&L List</span>
                                    </Link>
                                </div>
                            </li>
                            <hr className="sidebar-divider" />
                        </>
                    }



                    {/* meeting  */}
                    <li className="nav-item dropdown">
                        <span className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" >
                            <i class="fa-solid fa-handshake mr-1"></i>
                            <span className="hover1">Meeting</span>
                        </span>
                        <div className="dropdown-menu " aria-labelledby="navbarDropdownMenuLink" style={{ minWidth: "13.6rem", backgroundColor: "rgb(43 175 83)" }}>
                            {getLoginUserData().deptId === "1" && <Link className="dropdown-item" to="/CreateMeetingMarketing">
                                <i class="fa-solid fa-people-group mr-1 hover-item"></i><span className='hover-item'>Create Meeting</span>
                            </Link>}


                            {getLoginUserData().deptId === "2" && <Link className="dropdown-item" to="/CreateMeetingSales">
                                <i class="fa-solid fa-people-group mr-1 hover-item"></i><span className='hover-item'>Create Meeting</span>
                            </Link>}

                            {(getLoginUserData().deptId === "4" || getLoginUserData().deptId === "5" || getLoginUserData().deptId === "3") && <Link className="dropdown-item" to="/CreateMeeting">
                                <i class="fa-solid fa-people-group mr-1 hover-item"></i><span className="hover-item">Create Meeting</span>
                            </Link>}

                            <Link className="dropdown-item" to="/MeetingList">
                                <i class="fa-solid fa-layer-group mr-1 hover-item"></i><span className="hover-item">My Meetings</span>
                            </Link>

                        </div>
                    </li>
                    <hr className="sidebar-divider" />

                    {/* feedback start */}
                    {(getLoginUserData().deptId === "4" || getLoginUserData().role === "Admin") &&
                        <>
                            <li className="nav-item dropdown">
                                <span className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" >

                                    <i class="fa-solid fa-comments"></i>
                                    <span className="hover1">Feedbacks</span>
                                </span>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style={{ minWidth: "13.6rem", backgroundColor: "rgb(43 175 83)" }}>
                                    <Link className="dropdown-item" to={"/CreateCustomerFeedback"}>
                                        <i class="fa-solid fa-pen-to-square mr-1 hover-item"></i>
                                        <span className="hover-item">Customer Feedback</span>
                                    </Link>

                                    <Link className="dropdown-item" to={"/CreateProjectClosureFeedback"}>
                                        <i class="fa-solid fa-pen-to-square mr-1 hover-item"></i>
                                        <span className="hover-item">Project Closure Feedback</span>
                                    </Link>

                                    {/* <Link className="dropdown-item" to={"/CreateProjectFeedback"}>
                                    <i class="fa-solid fa-pen-to-square"></i>
                                    <span className="hover1">Create Project Feedback</span>
                                </Link> */}

                                    <Link className="dropdown-item" to={"/CustomerFeedbackList"}>
                                        <i class="fa fa-table mr-1 hover-item"></i>
                                        <span className="hover-item">Customer Feedback List</span>
                                    </Link>
                                    <Link className="dropdown-item" to={"/ProjectClosureFeedbackList"}>
                                        <i class="fa fa-table mr-1 hover-item"></i>
                                        <span className="hover-item">Closure feedback List</span>
                                    </Link>

                                    {/* <Link className="dropdown-item" to={"/ProjectFeedbackList"}>
                                    <i class="fa fa-table mr-1"></i>
                                    <span className="hover1">Project Feedback List</span>
                                </Link> */}

                                </div>
                            </li>
                            <hr className="sidebar-divider" />
                        </>
                    }
                    {/* feedback end */}
                    {/* others operation start */}
                    {(getLoginUserData().deptId === "4" || getLoginUserData().role === "Admin") &&
                        <>
                            <li className="nav-item dropdown">
                                <span className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" >

                                    <i class="fa-solid fa-comments"></i>
                                    <span className="hover1">Others</span>
                                </span>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style={{ minWidth: "13.6rem", backgroundColor: "rgb(43 175 83)" }}>
                                    <Link className="dropdown-item" to={"/CreateBoq"}>
                                        <i class="fa-solid fa-pen-to-square mr-1 hover-item"></i>
                                        <span className="hover-item">Create BOQ</span>
                                    </Link>

                                    <Link className="dropdown-item" to={"/SitePhotosAndVideo"}>
                                        <i class="fa-solid fa-pen-to-square mr-1 hover-item"></i>
                                        <span className="hover-item">Add Photo Video</span>
                                    </Link>

                                    <Link className="dropdown-item" to={"/CreateApprovalList"}>
                                        <i class="fa-solid fa-pen-to-square mr-1 hover-item"></i>
                                        <span className="hover-item">Create Approval List</span>
                                    </Link>

                                    <Link className="dropdown-item" to={"/BoqList"}>
                                        <i class="fa fa-table mr-1 hover-item"></i>
                                        <span className="hover-item">BOQ List</span>
                                    </Link>
                                    <Link className="dropdown-item" to={"/SitePhotosAndVideosList"}>
                                        <i class="fa fa-table mr-1 hover-item"></i>
                                        <span className="hover-item">Photos Videos List</span>
                                    </Link>

                                    <Link className="dropdown-item" to={"/ApprovalList"}>
                                        <i class="fa fa-table mr-1 hover-item"></i>
                                        <span className="hover-item">Material Link List</span>
                                    </Link>

                                </div>
                            </li>
                            <hr className="sidebar-divider" />
                        </>
                    }

                    {/* marketing start  */}
                   { (getLoginUserData().deptId === "1" || getLoginUserData().deptId === "2") &&
                   <>
                   {/* <hr className="sidebar-divider d-none d-md-block" /> */}
                    <li className="nav-item ">
                        <Link className="nav-link" to={"/AdminShowList"}>
                            <i className="fas fa-fw fa-tachometer-alt"></i>
                            <span className="hover1">Upcoming Shows</span></Link>
                    </li>
                    </>}

                    {(getLoginUserData().deptId === "1" || getLoginUserData().userId === "47") &&
                        <>
                            <hr className="sidebar-divider" />

                            <li className="nav-item dropdown">
                                <span className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" >

                                <i class="fa-solid fa-address-card mr-1"></i>
                                    <span className="hover1">Lead</span>
                                </span>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style={{ minWidth: "13.6rem", backgroundColor: "rgb(43 175 83)" }}>
                                    <Link className="dropdown-item" to={"/ShareLead"}>
                                    <i class="fa-solid fa-address-card mr-1"></i>
                                        <span className="hover-item">Create Lead</span>
                                    </Link>

                                    <Link className="dropdown-item" to={"/SharedLeadList"}>
                                    <i class="fa-solid fa-list-check mr-1"></i>
                                        <span className="hover-item">Lead List</span>
                                    </Link>

                                   

                                    <Link className="dropdown-item" to={"/DashboardLead"}>
                                        <i class="fa fa-table mr-1 hover-item"></i>
                                        <span className="hover-item">Qualified Leads</span>
                                    </Link>

                                </div>
                            </li>
                        </>
                    }

{(getLoginUserData().deptId === "1" || getLoginUserData().userId === "47") &&
                        <>
                            <hr className="sidebar-divider" />

                            <li className="nav-item dropdown">
                                <span className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" >
                                <AiFillFilePpt />
                                    <span className="hover1">PPT</span>
                                </span>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style={{ minWidth: "13.6rem", backgroundColor: "rgb(43 175 83)" }}>
                                    <Link className="dropdown-item" to={"/CreatePPT"}>
                                    <BsFiletypePpt />
                                        <span className="hover-item">Attach PPT</span>
                                    </Link>

                                    <Link className="dropdown-item" to={"/PPTList"}>
                                    <i class="fa-solid fa-list-check mr-1"></i>
                                        <span className="hover-item">PPt List</span>    
                                    </Link>
                                </div>
                            </li>
                        </>
                    }
                    {/* marketing end  */}
                    {/* others operation end */}
                </div>

            </div>
        )
    }
}

export default Navbar  