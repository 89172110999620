import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import Service from '../../../Services/Admin/Service';
import { Link } from 'react-router-dom';

const CreateVandourComponent = (props) => {


    let id = sessionStorage.getItem('id');

    const [apiError, setApiError] = useState(null);
    const [apiResponse, setApiResponse] = useState(null);
    const [allVendor, setAllVendor] = useState([]);
    const [vendor, setVendor] = useState('');
    const [supplierName, setSupplierName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [website, setWebsite] = useState('');
    const [country, setCountry] = useState('');
    const [region, setRegion] = useState('');
    const [lastProject, setLastProject] = useState('');
    const [serviceName, setServiceName] = useState('');
    const [serviceError, setServiceError] = useState(false);
    const [inputField, setInputField] = useState({

        id: props.id,
        supplierName: "",
        email: "",
        phone: "",
        website: "",
        country: "",
        region: "",
        typeofservice: "",
        lastProject: "",
        createdBy: id,
        updatedBy: id
    });


    useEffect(() => {
        getAllVendor("Vendor/GetAllVendorSevice");
        if (props.id !== 0 && props.id !== undefined) {
            getVendorDatabyId("Vendor/GetById?id=" + props.id, '');
        }
    }, [])

    const getVendorDatabyId = (method, id) => {
        Service.getdatabyId(method, id).then((res) => {
            setVendor(res.data);
            console.log('Vendor', res.data);
            setSupplierName(res.data.supplierName);
            setPhone(res.data.phone);
            setEmail(res.data.email);
            setWebsite(res.data.website);
            setCountry(res.data.country);
            setRegion(res.data.region);
            setLastProject(res.data.lastProject);
            setServiceName(res.data.typeofservice);
            console.log(setServiceName);
        });
    }

    const getAllVendor = (method) => {
        Service.getdata(method).then((res) => {
            setAllVendor(res.data);
        });
    }



    const handleInputChange = (e) => {
        setSupplierName(e.target.value);
    }

    const handlePhoneChange = (e) => {
        setPhone(e.target.value);

    }
    const handleEmailChange = (e) => {
        setEmail(e.target.value);

    }
    const handleRegionChange = (e) => {
        setRegion(e.target.value);

    }
    const handleWebsiteChange = (e) => {
        setWebsite(e.target.value);

    }
    const handleCountryChange = (e) => {
        setCountry(e.target.value);

    }

    const handleProjectChange = (e) => {
        setLastProject(e.target.value);

    }

    const handleService = (e) => {
        setServiceName(e.target.value);
    }

    const backbutton = () => {
        props.navigate(-1);
    }

    useEffect(() => {
        if (apiResponse || apiError) {
            const timeoutId = setTimeout(removeSuccessMessage, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [apiResponse, apiError]);

    const removeSuccessMessage = () => {
        setApiError(null);
        setApiResponse(null);
    };


    const save = async (evt) => {

        if (evt && evt.preventDefault) {
            evt.preventDefault();
        }

        const formData = new FormData(evt.target);

        const data = {}
        for (const [key, value] of formData) {
            data[key] = value;
        }

     

        const task = {
            ...inputField,
            ...data
        };

        if (task) {
            Service.createorupdate("Vendor/CreateOrUpdate", task)
                .then(res => {
                    setApiResponse(res.data); // Set the API response in state
                    window.location.href = "/VendorList"
                })
                .catch(error => {
                    // Handle error if API call fails
                    console.error("API Error:", error);
                });
        }

    }

    return (
        <div>
            <div className="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div class="d-flex flex-start align-items-center">
                                {/* <img class="shadow-1-strong me-32"
                                    src="https://e7.pngegg.com/pngimages/52/220/png-clipart-vendor-computer-icons-font-supplier-miscellaneous-silhouette.png" alt="avatar" width="45"
                                    height="45" /> */}
                            </div>
                            <div className="col-sm-4"><h2>New Vendor</h2></div>
                            <div className="col-sm-6"></div>
                            <div className="col-sm-2 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="card cardUser shadow mb-4" >
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Add Vendor</h6>
                        {apiResponse && (
                            <div className="alert alert-success" role="alert">
                                Vendor has been created successfully!
                            </div>
                        )}
                        {apiError && (
                            <div className="alert alert-danger" role="alert">
                                {apiError}
                            </div>
                        )}
                    </div>

                    <div class="container">
                        <form onSubmit={save} class="form-horizontal mt-2">
                            <div class="form-group">
                                <label for="supplier" class="col-sm-3   control-label">Supplier Name</label>
                                <div class="col-sm-9">
                                    <input type="text" id="supplier" value={supplierName} onChange={handleInputChange} name='supplierName' placeholder="Supplier Name"  class="form-control" autofocus />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="firstName" class="col-sm-3   control-label">Phone</label>
                                <div class="col-sm-9">
                                    <input type="tel" id="phone" name='phone' value={phone} onChange={handlePhoneChange} placeholder="Phone Number"  class="form-control" autofocus />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="email" class="col-sm-3  control-label">Email </label>
                                <div class="col-sm-9">
                                    <input type="email" id="email" placeholder="Email" value={email} onChange={handleEmailChange} class="form-control" name="email"  />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="website" class="col-sm-3  control-label">Website </label>
                                <div class="col-sm-9">
                                    <input type="text" id="website" placeholder="https://www.example.com" value={website} onChange={handleWebsiteChange} class="form-control" name="website"  />
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="country" class="col-sm-3   control-label">Country</label>
                                <div class="col-sm-9">
                                    <input type="text" id="countryId" name='country' placeholder="Country" value={country} onChange={handleCountryChange}  class="form-control" autofocus />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="Region" class="col-sm-3   control-label">Region</label>
                                <div class="col-sm-9">
                                    <input type="text" id="Region" name='region' placeholder="Region" value={region} onChange={handleRegionChange}  class="form-control" autofocus />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="lastProject" class="col-sm-3   control-label">Last project</label>
                                <div class="col-sm-9">
                                    <input type="text" id="lastProject" name='lastProject' placeholder="Last Project" value={lastProject} onChange={handleProjectChange}  class="form-control" autofocus />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="service" class="col-sm-3  control-label">Type of Service</label>
                                <div class="col-sm-9">
                                    {serviceError && <div className="alert alert-danger mt-3" role="alert">Please select a service.</div>}

                                    <select
                                        value={serviceName}
                                        onChange={handleService}
                                        className="form-control form-control-fs form-control-user"
                                        name="typeofservice" // Update the name attribute to "roleId"
                                    >
                                        <option value="">[NONE]</option>
                                        {allVendor.map((e, key) => {
                                            return <option key={e.id} value={e.id}>{e.name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>

                            <button type="submit" class="btn btn-primary ml-2 mb-3">Save</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateVandourComponent