import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import Service from '../../../Services/Admin/Service';
import paginationFactory from 'react-bootstrap-table2-paginator';


const BoqListComponent = (props) => {

    const [data, setData] = useState([]);

    useEffect(() => {
        getMeetingDetails("Boq/GetAll");
    }, []);

    const getMeetingDetails = (method) => {
        Service.getdata(method).then((res) => {
            setData(res.data);
        });

    }

    const formatDateTime = (cell, row) => {
        // Format the date and time here, assuming 'time' is a string in ISO 8601 format
        const formattedDate = new Date(cell).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });
        return formattedDate;
    }

    const backbutton = () => {
        props.navigate(-1);
    }

    const linkformatter = (data, row) => {
        return <>
            <Link className="btn btn-warning m-1 btn-sm" to={`/CreateBoq/${data}`}>Edit</Link>
            <Link className="btn btn-info  m-1 btn-sm" to={`/BoqIsShared/${data}`}>Shared</Link>
            <Link className="btn btn-info  m-1 btn-sm" to={`/BoqDetails/${data}`}>Details</Link>
            {/* <Link className="btn btn-warning m-1 btn-sm" to={`/BoqIsApproved/${data}`}>Approved</Link> */}

        </>
    }

    const column = [
        { dataField: 'projectName', text: 'Project Name', sort: true },
        { dataField: 'isShared', text: 'Is Shared', sort: true },
        { dataField: 'createdDate', text: 'Created At', sort: true, formatter: formatDateTime },
        // { dataField: 'isAproved', text: 'Is Approved' },
        { dataField: 'id', text: 'Actions', formatter: linkformatter }
    ]

    return (
        <div>
            <div className="container-fluid">
                <div className="card shadow border-success mb-2">
                    <div className="card-header rounded py-3">
                        <div className="row">
                            <div className="col-sm-4 "><h3><b className='text-dark'>All Boq List</b></h3></div>
                            <div className="col-sm-6"></div>
                            <div className="col-sm-2 d-flex flex-row-reverse">
                                <button className="btn btn-info  m-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-success">Boq List</h6>
                    </div>

                    <div className="card-body">
                        <div className="table-responsive">
                            <BootstrapTable keyField="id"
                                data={data}
                                columns={column}
                                pagination={paginationFactory()}
                            />
                        </div>
                    </div>
                </div>
                <button type="submit" class="btn btn-primary ml-3 mb-3">Save</button>
            </div>
        </div>
    )
}

export default BoqListComponent