import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Service from '../../../Services/Admin/Service';

const CreateMaterialComponent = (props) => {
    let id = sessionStorage.getItem('id');
    const [projectError, setProjectError] = useState(false);
    const [projectData, setProjectData] = useState([]);
    const [apiError, setApiError] = useState(null);
    const [apiResponse, setApiResponse] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState('');
    const [inputField, setInputField] = useState(
        {
            fileid: 0,
            files: "",
            projectid: "",
            createdby: id,
            updatedby: id,
        })


    useEffect(() => {
        getProjectData("Project/GetAll");
    })

    const getProjectData = (method) => {
        Service.getdata(method).then((res) => {
            setProjectData(res.data);

        })
    }

    useEffect(() => {
        if (apiResponse || apiError) {
            const timeoutId = setTimeout(removeSuccessMessage, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [apiResponse, apiError]);

    const removeSuccessMessage = () => {
        setApiResponse(null);
        setApiError(null);
    };


    const handleFileChange = (e) => {
        setSelectedFiles(e.target.files[0]);
    };

    const save = async (evt) => {
        if (evt && evt.preventDefault) {
            evt.preventDefault();
        }

        const formData = new FormData(evt.target);

        const data = {}
        for (const [key, value] of formData) {
            data[key] = value;
        }

        function validateData(data) {
            const { files, leadid } = data;

            if (!files || files === "[NONE]") {
                alert("Please fill in all required fields.");
                return false;
            }

            if (!leadid || leadid === "[NONE]") {
                setProjectError(true);
                return false;
            } else {
                setProjectError(false);
            }

            return true;
        }

        if (!validateData(data)) {
            return;
        }

        const formData1 = new FormData();
        formData1.append("files", selectedFiles);

        const task = {
            ...inputField,
            ...data,
        };
        console.log(task);
        debugger
        if (task) {
            Service.createorupdateFile("Presentation/CreateOrUpdate?Leadid=" + data.leadid + "&createdby=" + id, formData1)
                .then(res => {
                    setApiResponse(res.data); // Set the API response in state
                    window.location.href = "/PPTList";
                })
                .catch(error => {
                    // Handle error if API call fails
                    console.error("API Error:", error);
                    setApiError("Error occurred while saving the meeting. Please try again later.");
                });
        }
    }

    const backbutton = () => {
        props.navigate(-1);
    }

    return (
        <div>
            <div className="container-fluid">

                <br />

                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col-sm-3"><h3>Material List Link</h3></div>
                            <div className="col-sm-3"></div>
                            <div className="col-sm-2 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="card cardUser shadow mb-4" >
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Add Material List</h6>
                            {apiResponse && (
                                <div className="alert alert-success" role="alert">
                                    Material Link has been uploaded successfully!
                                </div>
                            )}
                            {apiError && (
                                <div className="alert alert-danger" role="alert">
                                    {apiError}
                                </div>
                            )}
                        </div>

                        <div class="container">
                            <form onSubmit={save} class="form-horizontal mt-2">
                                <div className="form-group ">
                                    <div className='col-sm-9 required-label'>Project</div>
                                    <div className='col-sm-9'>
                                        {projectError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a Project.</div>}
                                        <select className="form-control  form-control-user" name="projectId" required>
                                            <option>[NONE]</option>
                                            {
                                                projectData.map((e, key) => {
                                                    //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;
                                                    return <option key={e.id} value={e.id}>{e.projectname}</option>;
                                                })}
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="field" class="col-sm-3 required-label  control-label">Attach Material List Drive Link</label>
                                    <div class="col-sm-9">
                                        <input type="text" id="topic" className='form-control  form-control-user' name='link' onChange={handleFileChange} required autoFocus />
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary ml-3 mb-3">Upload</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateMaterialComponent