import React, { useState, useEffect } from 'react'
import Service from "../../../Services/Admin/Service";
import Select from 'react-select';
import ApiMethod from '../../../Helpers/ApiMethod/Method'
import TextareaAutosize from 'react-textarea-autosize';
import { Link } from 'react-router-dom';
import { getLoginUserData } from '../../../Helpers/supportedFunctions/supportedGlobalFunctions';

function AddProjectComponent(props) {
    const [detailFlag, setDetailFlag] = useState(false);
    const [apiError, setApiError] = useState(null);
    const [leadError, setLeadError] = useState(false);
    const [dropdownUser, setDropdownUser] = useState([]);
    const [projectInfo, setProjectInfo] = useState([]);
    const [memberError, setMemberError] = useState(false);
    const [selectedLastUsers, setSelectedLastUsers] = useState([]);
    const [apiResponse, setApiResponse] = useState(null);
    let userid = sessionStorage.getItem('id');
    const [inputField, setInputField] = useState({
        id: props.id,
        leadid: '',
        projectname: '',
        startdate: '',
        shortDescription: '',
        longDescription: '',
        createdby: userid,
        lstTeam: [],
    })


    // const [lstTeam, setlstTeam] = useState({
    //     id: 0,

    //     userId: 0,
    //     createdby: 0,
    //     updatedby: 0,
    //     createddate: "",
    //     updateddate: "",
    // })

    const [lstInputTeam, setlstInputTeam] = useState([]);

    const [lead, setLead] = useState([]);

    const [leadid, setLeadid] = useState([]);

    useEffect(() => {

        updatenotification("Questionare/readnotification?projectId=" + props.id + "&userid=" + getLoginUserData().userId)
        if (!props.id) {

            getUser("Users/GetAll")
        }
        getLeadDataDetails("Lead/GetAllLead");

        var d = props.name;
        if (d === "details") {
            setDetailFlag(true);
            autoResizeTextArea();
        }
        else if (d === "edit") {
            setDetailFlag(false);
        }

        if (props.id !== 0 && props.id !== undefined) {
            getProjectDetailbyId("Project/GetById?id=" + props.id, '');
        }

    }, [])

    const updatenotification = (method) => {
        Service.getdata(method).then((res) => {
        });
    }

    const getUser = (method, lstUsers) => {
        Service.getdata(method).then((res) => {
            var selecteduser = [];

            setDropdownUser(res.data);
            lstUsers.forEach((d, index) => {
                ;
                var curr = res.data.find(x => x.id === d.userId);
                ;
                // Check if user with this ID is already selected
                var existingUser = selecteduser.find(user => user.value === curr.id);
                if (!existingUser) {
                    // User with this ID is not already selected, so add it to selecteduser
                    var user = {
                        value: curr.id,
                        label: curr.firstname + ' ' + curr.lastname
                    };
                    selecteduser.push(user);
                }
            });

            setSelectedLastUsers(selecteduser);

        });
    }
    const getProjectInfo = (method) => {
        Service.getdata(method).then((res) => {
            setProjectInfo(res.data);
            debugger
        });
    }


    const getProjectDetailbyId = (method, id) => {
        Service.getdatabyId(method, id).then((res) => {
            setInputField(res.data);
            setLeadid(res.data.leadid)

            var listofusers = res.data.lstTeam;
            // 
            var projectId = res.data.id;
            getUser("Users/GetAll", listofusers)
            getProjectInfo("Project/projectInfoBox?id=" + res.data.id)
            debugger
            const lstMember = res.data.lstTeam.map((member) => member.firstname + ' ' + member.lastname);
            setlstInputTeam(lstMember.join(', '));
            console.log(res.data)
            // 
        });
    }

    const getLeadDataDetails = (method) => {
        Service.getdata(method, '').then((res) => {
            const filteredData = res.data.filter((element) => element.createRFP !== false);
             debugger
            // Set the state with the filtered data
            setLead(filteredData);

            // Log the filtered data
            console.log(filteredData);

        });
    }

    function extractValuesFromIndexedObject(indexedObject) {
        return Object.values(indexedObject).map(item => item.value);
    }

    const handleSelectChange = (selectedOptions) => {
        setSelectedLastUsers(selectedOptions);


    };

    function autoResizeTextArea() {
        // Find the text areas by their IDs
        const shortDescriptionTextArea = document.getElementById("shortDescription");
        const longDescriptionTextArea = document.getElementById("longDescription");

        if (shortDescriptionTextArea && longDescriptionTextArea) {
            // Auto-resize the short description text area
            shortDescriptionTextArea.style.height = "auto";
            shortDescriptionTextArea.rows = shortDescriptionTextArea.value.split('\n').length + 1;

            // Auto-resize the long description text area
            longDescriptionTextArea.style.height = "auto";
            longDescriptionTextArea.rows = longDescriptionTextArea.value.split('\n').length + 1;
        }
    }

    useEffect(() => {
        if (apiResponse || apiError) {
            const timeoutId = setTimeout(removeSuccessMessage, 3000);
            // window.location.href = "/MeetingList";
            return () => clearTimeout(timeoutId);
        }
    }, [apiResponse, apiError]);

    const removeSuccessMessage = () => {
        setApiResponse(null);
        setApiError(null);
    };


    const inputsHandler = (e) => {
        //  setInputField( {[e.target.name]: e.target.value} )
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const backbutton = () => {
        props.navigate(-1);
    }

    const handleInputStatus = (e) => {
        setLeadid(e.target.value);

        var showid = lead.filter(x => x.leadid === e.target.value);
        if (showid.length !== 0) {
            getShowDetailsById("Shows/GetALLShowsChapterId?id=", showid[0].showid);
            getLeadDetailsById("Lead/GetById?id=", showid[0].id);
        }

    }

    const getLeadDetailsById = (method, id) => {
        Service.getdatabyId(method, id).then((res) => {
            console.log(res.data);
            setInputField((prevState) => ({
                ...prevState,
                ["startdate"]: res.data.startDate,
                ["enddate"]: res.data.endDate,
            }));

            // 
        });
    }

    const getShowDetailsById = (method, id) => {
        Service.getdatabyId(method, id).then((res) => {
            console.log(res.data);
            setInputField((prevState) => ({
                ...prevState,
                ["startdate"]: res.data.startDate,
                ["enddate"]: res.data.endDate,
            }));

            // 
        });
    }



    const submitButton = (e) => {
        e.preventDefault();

        if (e && e.preventDefault) {
            e.preventDefault();
        }
        const formData = new FormData(e.target);

        const data = {}
        for (const [key, value] of formData) {
            data[key] = value;
        }

        function validateData(data) {

            const { topic, leadid, lstTeam, kind } = data;

            // if (!topic || !time || kind === "[NONE]") {
            //     alert("Please fill in all required fields.");
            //     return false;
            // }

            if (!leadid || leadid === "[NONE]") {
                setLeadError(true);
                return false;
            } else {
                setLeadError(false);
            }

            if (!lstTeam || lstTeam === "[NONE]") {
                setMemberError(true);
                return false;
            } else {
                setMemberError(false);
            }

            return true;
        }

        if (!validateData(data)) {
            // The validation returned false, so stop execution here.
            // You can add any specific handling for invalid data here, if needed.
            return;
        }

        console.log(selectedLastUsers)
        // 
        var arr = [];

        selectedLastUsers.forEach((e, index) => {
            var lstTeam = {};
            lstTeam.id = 0;
            lstTeam.userId = e.value;
            lstTeam.createdby = userid;
            lstTeam.updatedby = userid;
            // setlstTeam(lstTeam);
            arr.push(lstTeam);

        })

        let project = inputField;
        project.createdby = userid;
        project.leadid = leadid;
        project.projectname = leadid;
        project.lstTeam = arr;
        console.log(project);

        //   const apis = ApiMethod.find(o => o.name === 'Category');
        if (project) {
            Service.createorupdate("Project/CreateOrUpdate", project)
                .then(res => {
                    setApiResponse(res.data); // Set the API response in state
                    window.location.href = "/ProjectList";
                })
                .catch(error => {
                    // Handle error if API call fails
                    console.error("API Error:", error);
                    setApiError("Error occurred while saving the project. Please try again later.");
                });
        }
    }

    function formatDate(date) {
        if (!date) return ""; // Handle null or undefined dates
        const localDate = new Date(date);
        const year = localDate.getFullYear();
        const month = String(localDate.getMonth() + 1).padStart(2, '0');
        const day = String(localDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const handleClick = () => {
        if (projectInfo.projectExecutionId === 0) {
            alert('Project execution is 0');
        }
    };

    return (

        <div className='container-fluid'>


            {/* <br /> */}
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <div className="row">
                        <div className="col-sm-3"><h3>Add Project</h3></div>
                        <div className="col-sm-6"></div>
                        <div className="col-sm-3 d-flex flex-row-reverse">
                            <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                            <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Project Info-:    {inputField.projectname}</h6>
                    {apiResponse && (
                        <div className="alert alert-success" role="alert">
                            project has been created successfully!
                        </div>
                    )}
                    {apiError && (
                        <div className="alert alert-danger" role="alert">
                            {apiError}
                        </div>
                    )}
                </div>

                <div className="card-body">
                    <form onSubmit={submitButton}>

                        <div class="form-group row">
                            <label for="departmentId" class="col-sm-3 required-label control-label">Lead</label>
                            <div class="col-sm-4">
                                {leadError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a lead.</div>}

                                <select className="form-control form-control-fs form-control-user" disabled={detailFlag} value={leadid} onChange={handleInputStatus} id='leadid' name="leadid" required>
                                    <option>[NONE]</option>
                                    {
                                        lead.map((e, key) => {
                                            return <option key={e.id} value={e.leadid}>{e.leadid}</option>;
                                        })}
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className='col-sm-3 required-label'>Project Name</div>
                            <div className='col-sm-4'><input type="text" value={inputField.projectname} readOnly={detailFlag} className="form-control form-control-user" id="name"
                                onChange={inputsHandler} name="projectname" placeholder="Project Name" /></div>
                        </div>
                        <div className="form-group row">
                            <div className='col-sm-3 required-label'>Start Date</div>
                            <div className='col-sm-4'><input type="date" readOnly={detailFlag} onChange={inputsHandler} value={formatDate(inputField.startdate)} className="form-control form-control-user" id="name"
                                name="startdate" /></div>
                        </div>
                        <div className="form-group row">
                            <div className='col-sm-3 required-label'>End Date</div>
                            <div className='col-sm-4'><input type="date" readOnly={detailFlag} value={formatDate(inputField.enddate)} onChange={inputsHandler} className="form-control form-control-user" id="name"
                                name="enddate" /></div>
                        </div>
                        <div className="form-group row">
                            <div className='col-sm-3'>Short Description</div>
                            <div className='col-sm-4'><TextareaAutosize
                                id="shortDescription"
                                name="shortDescription"
                                value={inputField.shortDescription}
                                onChange={inputsHandler}
                                readOnly={detailFlag}
                                // minRows={1} // Minimum number of rows
                                className='form-control form-control-user'
                                placeholder="Short Description"
                            />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className='col-sm-3'>Long Description</div>
                            <div className='col-sm-4'><TextareaAutosize className="form-control form-control-user" id="description"
                                onChange={inputsHandler} value={inputField.longDescription} readOnly={detailFlag} name="longDescription" placeholder="Long Description" /></div>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-3 required-label">List Project Members</div>
                            <div className="col-sm-4">
                                {memberError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select project members.</div>}

                                <Select
                                    name="lstTeam"
                                    isMulti={true}
                                    isDisabled={detailFlag}
                                    placeholder="Enter project members"
                                    options={dropdownUser.map((user) => ({
                                        value: user.id,
                                        label: `${user.firstname} ${user.lastname}`,
                                    }))}
                                    value={selectedLastUsers}
                                    onChange={handleSelectChange}
                                />
                            </div>

                        </div>
                        {detailFlag === false && <button type="submit" class="btn btn-primary ml-3 mt-3 mb-3">Save</button>}
                    </form>
                </div>

               {props.name !=="details" && <div className="col-sm-12 p-1">

                    <div className="col-sm-12 d-flex border border-danger mb-1">
                        <div className="col-sm-5 p-2  "><h5><b>Other Formats</b></h5></div>
                        <div className="col-sm-7 d-flex justify-content-center border border-warning">
                            <div className='mt-1'>
                                <h4><b>Actions</b></h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 d-flex border border-danger mb-1">
                        <div className="col-sm-5 p-2  "><h5>Project Execution</h5></div>
                        <div className="col-sm-7 d-flex justify-content-end border border-warning">
                            <div className='mt-1'>
                                {/* {projectInfo.projectExecutionId === 0 ? */}
                                <button className='btn btn-success mr-1' onClick={() => {
                                    if (projectInfo.projectExecutionId !== 0) {
                                        alert('Project execution is already created');
                                    } 
                                   
                                    else {
                                        window.location.href = "/CreateProjectExecution";
                                    }
                                }} ><i class="fa fa-solid fa-file-pen mr-1"></i>Create</button>
                                <>
                                    <button className='btn btn-info mr-1' onClick={() => {
                                        if (projectInfo.projectExecutionId === 0) {
                                            alert('Project execution is not created yet');
                                        } else {
                                            window.location.href = `/CreateProjectExecution/${projectInfo.projectExecutionId}&details`;
                                        }
                                    }} ><i class="fa fa-solid fa-info mr-1"></i>Details</button>
                                    <button className='btn btn-primary ' onClick={() => {
                                        if (projectInfo.projectExecutionId === 0) {
                                            alert('Project execution is not created yet');
                                        } else {
                                            window.location.href = `/CreateProjectExecution/${projectInfo.projectExecutionId}&edit`;
                                        }
                                    }} ><i class=" fa-solid fa-pen-to-square mr-1"></i>Edit</button>
                                </>
                                {/* } */}
                            </div>
                        </div>
                    </div>
                    {/* {projectInfo.projectExecutionId !== 0 &&  */}
                    <div className="col-sm-12 d-flex border border-danger mb-1">
                        <div className="col-sm-5 p-2  "><h5>Multiple Task</h5></div>
                        <div className="col-sm-7 d-flex justify-content-end border border-warning">
                            <div className='mt-1'>
                                <Link className='btn btn-success mr-1' to="/CreateMultiTask" ><i class="fa fa-solid fa-file-pen mr-1"></i>Create</Link>
                                <Link className='btn btn-info mr-1' to="/KanbanAssignedTask" ><i class="fa fa-solid fa-info mr-1"></i>Details</Link>
                            </div>
                        </div>
                    </div>
                    {/* } */}

                    {/* {projectInfo.projectExecutionId !== 0 &&  */}
                    <div className="col-sm-12 d-flex border border-danger mb-1">
                        <div className="col-sm-5 p-2  "><h5>BOQ</h5></div>
                        <div className="col-sm-7 d-flex justify-content-end border border-warning">
                            <div className='mt-1'>
                                {/* {projectInfo.boqId === 0 ?  */}
                                <button className='btn btn-success mr-1' onClick={() => {
                                    if (projectInfo.projectExecutionId === 0) {
                                        alert("First Create project execution...!");
                                    }
                                    else if(projectInfo.boqId !== 0){
                                        alert("Already created, Click on edit/details button");
                                    }
                                    else {
                                        window.location.href = "/CreateBoq";
                                    }
                                }}><i class="fa fa-solid fa-file-pen mr-1"></i>Create</button>
                                <>
                                    <button className='btn btn-info mr-1' onClick={() => {
                                        if (projectInfo.boqId === 0) {
                                            alert("Boq is not Created yet");
                                        }
                                        else {
                                            window.location.href = `/BoqDetails/${projectInfo.boqId}`;
                                        }
                                    }} ><i class="fa fa-solid fa-info mr-1"></i>Details</button>
                                    <button className='btn btn-primary' onClick={() => {
                                        if (projectInfo.boqId === 0) {
                                            alert("Boq is not Created yet");
                                        }
                                        else {
                                            window.location.href = `/CreateBoq/${projectInfo.boqId}`;
                                        }
                                    }}><i class="fa fa-solid fa-pen-to-square mr-1"></i>Edit</button>
                                </>
                                {/* } */}
                            </div>
                        </div>
                    </div>
                    {/* } */}

                    {/* {projectInfo.boqId !== 0 &&  */}
                    <div className="col-sm-12 d-flex border border-danger mb-1">
                        <div className="col-sm-5 p-2  "><h5>Pre PL</h5></div>
                        <div className="col-sm-7 d-flex justify-content-end border border-warning">
                            <div className='mt-1'>
                                {/* {projectInfo.PrePl === 0 ?  */}
                                <button className='btn btn-success mr-1' onClick={()=>{
                                    if(projectInfo.boqId === 0){
                                        alert("create boq to access this");
                                    }
                                    else if(projectInfo.prePlId !== 0){
                                        alert("Already created, Click on edit/details button");
                                    }
                                    else{
                                        window.location.href = "/CreatePrePL";
                                    }
                                }}><i class="fa fa-solid fa-file-pen mr-1"></i>Create</button>
                                 <>
                                    <button className='btn btn-info mr-1' onClick={()=>{
                                        if(projectInfo.prePlId === 0){
                                            alert("pre pl is not created yet");
                                        }
                                        else{
                                            window.location.href = `/DetailPrePL/${projectInfo.prePlId}&details`;
                                        }
                                    }}><i class="fa fa-solid fa-info mr-1"></i>Details</button>
                                    <button className='btn btn-primary' onClick={()=>{
                                        if(projectInfo.prePlId === 0){
                                            alert("pre pl is not created yet");
                                        }
                                        else{
                                            window.location.href = `/DetailPrePL/${projectInfo.prePlId}&edit`;
                                        }
                                    }}><i class="fa fa-solid fa-pen-to-square mr-1"></i>Edit</button>
                                </>
                                {/* } */}
                            </div>
                        </div>
                    </div>
                    {/* } */}

                    <div className="col-sm-12 d-flex border border-danger mb-1">
                        <div className="col-sm-5 p-2  "><h5>Meeting</h5></div>
                        <div className="col-sm-7 d-flex justify-content-end border border-warning">
                            <div className='mt-1'>
                                <Link className='btn btn-success mr-1' to="/CreateMeeting" ><i class="fa fa-solid fa-file-pen mr-1"></i>create</Link>
                                <Link className='btn btn-info mr-1' to="/MeetingList" ><i class="fa fa-solid fa-info mr-1"></i>Details</Link>
                            </div>
                        </div>
                    </div>

                    {/* {projectInfo.prePlId !== 0 &&  */}
                    <div className="col-sm-12 d-flex border border-danger mb-1">
                        <div className="col-sm-5 p-2  "><h5>Add Photo Video Link</h5></div>
                        <div className="col-sm-7 d-flex justify-content-end border border-warning">
                            <div className='mt-1'>
                                {/* {projectInfo.photoVideoId === 0 ?  */}
                                <button className='btn btn-success mr-1' onClick={()=>{
                                    if(projectInfo.prePlId === 0){
                                        alert("prePl is not created yet");
                                    }
                                    else if (projectInfo.photoVideoId !== 0){
                                        alert("Already Created, Please click on edit/details");
                                    }
                                    else{
                                        window.location.href = "/SitePhotosAndVideo";
                                    }
                                }}  ><i class="fa fa-solid fa-file-pen mr-1"></i>Create</button>
                                <>
                                    <button className='btn btn-info mr-1' onClick={()=>{
                                        if(projectInfo.photoVideoId === 0){
                                            alert("photos and videos not added yet");
                                        }
                                        else{
                                            window.location.href = `/SitePhotosAndVideo/${projectInfo.photoVideoId}&details`;
                                        }
                                    }} ><i class="fa fa-solid fa-info mr-1"></i>Details</button>
                                    <button className='btn btn-primary' onClick={()=>{
                                        if(projectInfo.photoVideoId === 0){
                                            alert("photos and videos not added yet");
                                        }
                                        else{
                                            window.location.href = `/SitePhotosAndVideo/${projectInfo.photoVideoId}&edit`;
                                        }
                                    }} ><i class="fa fa-solid fa-pen-to-square mr-1"></i>Edit</button>
                                </>
                                {/* } */}
                            </div>
                        </div>
                    </div>
                    {/* } */}

                    {/* {projectInfo.photoVideoId !== 0 && */}
                    {/* <>
                        <div className="col-sm-12 d-flex border border-danger mb-1">
                            <div className="col-sm-5 p-2  "><h5>Project Feedback</h5></div>
                            <div className="col-sm-7 d-flex justify-content-end border border-warning">
                                <div className='mt-1'>
                                    <button className='btn btn-success mr-1' onClick={()=>{
                                        if(projectInfo.projectFeedbackId !== 0){
                                            alert("project feedback is already created")
                                        }
                                        else{
                                            window.location.href = "/CreateProjectFeedback"
                                        }
                                    }}  ><i class="fa fa-solid fa-file-pen mr-1"></i>Create</button>
                                    <>
                                        <button className='btn btn-info mr-1' onClick={()=>{
                                            if(projectInfo.projectFeedbackId === 0){
                                                alert("project feedback is not created yet")
                                            }
                                            else{
                                                window.location.href = `/CreateProjectFeedback/${projectInfo.projectFeedbackId}&details`
                                            }
                                        }}  ><i class="fa fa-solid fa-info mr-1"></i>Details</button>
                                        <button className='btn btn-primary' onClick={()=>{
                                            if(projectInfo.projectFeedbackId === 0){
                                                alert("project feedback is not created yet")
                                            }
                                            else{
                                                window.location.href = `/CreateProjectFeedback/${projectInfo.projectFeedbackId}&edit`
                                            }
                                        }}  ><i class="fa fa-solid fa-pen-to-square mr-1"></i>Edit</button>
                                    </>
                                </div>
                            </div>
                        </div>
                    </> */}
                    {/* } */}

                    {/* {projectInfo.projectFeedbackId !== 0 &&  */}
                    <div className="col-sm-12 d-flex border border-danger mb-1">
                        <div className="col-sm-5 p-2  "><h5>Project Closure Feedback</h5></div>
                        <div className="col-sm-7 d-flex justify-content-end border border-warning">
                            <div className='mt-1'>
                                {/* {projectInfo.projectClosureFeedbackId === 0 ?  */}
                                <button className='btn btn-success mr-1' onClick={()=>{
                                    if(projectInfo.photoVideoId === 0){
                                        alert("photos and videos is not uploaded yet");
                                    }
                                    else if(projectInfo.projectClosureFeedbackId !== 0){
                                        alert("Already created, please click on edit/details button")

                                    }
                                    else{
                                        window.location.href = "/CreateProjectClosureFeedback"
                                    }
                                }} to="/CreateProjectClosureFeedback" ><i class="fa fa-solid fa-file-pen mr-1"></i>Create</button>
                                
                                <>   <button className='btn btn-info mr-1' onClick={()=>{
                                    if(projectInfo.projectClosureFeedbackId === 0){
                                        alert("project closure is not created yet");
                                    }
                                    else{
                                        window.location.href = `/CreateProjectClosureFeedback/${projectInfo.projectClosureFeedbackId}&details`
                                    }
                                }} ><i class="fa fa-solid fa-info mr-1"></i>Details</button>
                                    <button className='btn btn-primary' onClick={()=>{
                                        if(projectInfo.projectClosureFeedbackId === 0){
                                            alert("project closure is not created yet")
                                        }
                                        else{
                                            window.location.href = `/CreateProjectClosureFeedback/${projectInfo.projectClosureFeedbackId}&edit`
                                        }
                                    }} ><i class="fa fa-solid fa-pen-to-square mr-1"></i>Edit</button>
                                </>
                                {/* } */}
                            </div>
                        </div>
                    </div>
                    {/* } */}

                    {/* {projectInfo.projectClosureFeedbackId !== 0 &&  */}
                    <div className="col-sm-12 d-flex border border-danger mb-1">
                        <div className="col-sm-5 p-2  "><h5>Customer Feedback</h5></div>
                        <div className="col-sm-7 d-flex justify-content-end border border-warning">
                            <div className='mt-1'>
                                {/* {projectInfo.customerFeedbackId === 0 ?  */}
                                <button className='btn btn-success mr-1' onClick={()=>{
                                    if(projectInfo.projectClosureFeedbackId === 0){
                                        alert("Project closure Feedback  is not created yet");
                                    }
                                    else if(projectInfo.customerFeedbackId !== 0){
                                        alert("Already created, please click on edit/details button");
                                    }
                                    else{
                                        window.location.href = "/CreateCustomerFeedback";
                                    }
                                }} ><i class="fa fa-solid fa-file-pen mr-1"></i>Create</button>
                                
                                <>   <button className='btn btn-info mr-1' onClick={()=>{
                                    if(projectInfo.customerFeedbackId === 0){
                                        alert("customer feedback is not created yet");
                                    }
                                    else{
                                        window.location.href = `/CreateCustomerFeedback/${projectInfo.customerFeedbackId}&details`;
                                    }
                                }} ><i class="fa fa-solid fa-info mr-1"></i>Details</button>
                                    <button className='btn btn-primary' onClick={()=>{
                                        if(projectInfo.customerFeedbackId === 0){
                                            alert("customer feedback is not created yet");
                                        }
                                        else{
                                            window.location.href = `/CreateCustomerFeedback/${projectInfo.customerFeedbackId}&edit`;
                                        }
                                    }} ><i class="fa-solid fa-pen-to-square mr-1"></i>Edit</button>
                                </>
                                {/* } */}
                            </div>
                        </div>
                    </div>
                    {/* } */}



                </div>}

            </div>


        </div >
    )
}

export default AddProjectComponent