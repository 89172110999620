import React, { useState, useEffect, useRef } from 'react'
import Service from "../../../Services/Admin/Service";
import { Link } from 'react-router-dom';
import css from '../AdminPanelComponent/AdminDashboard.css'

function DetailsProjectComponent(props) {

    const [loading, setLoading] = useState(false); // New state for loading
    //sanjay
    const [overAllTaskData, setOverAllTaskData] = useState([]);
    const [dataName, setDataName] = useState('');
    let myRef = useRef()
    //sanjay
    const [data, setdata] = useState([]);
    const [taskData, setTaskData] = useState([]);
    const [requireDay, setRequireDay] = useState(0);
    const [spentDay, setspentDay] = useState(0);
    const [taskefficency, settaskefficency] = useState(0);
    const [openTask, setOpenTask] = useState([]);
    const [progressTask, setProgressTask] = useState([]);
    const [holdTask, setholdTask] = useState([]);
    const [cmpltTask, setCmpltTask] = useState([]);
    //Marketing
    const [openMarketingTask, setOpenMarketingTask] = useState([]);
    const [progressMarketingTask, setProgressMarketingTask] = useState([]);
    const [holdMarketingTask, setholdMarketingTask] = useState([]);
    const [cmpltMarketingTask, setCmpltMarketingTask] = useState([]);
    const [totalMarketingTask, seTotaltCmpltMarketingTask] = useState([]);
    // Marketing over
    //Sales
    const [openSalesTask, setOpenSalesTask] = useState([]);
    const [progressSalesTask, setProgressSalesTask] = useState([]);
    const [holdSalesTask, setholdSalesTask] = useState([]);
    const [cmpltSalesTask, setCmpltSalesTask] = useState([]);
    const [totalSalesTask, seTotaltCmpltSalesTask] = useState([]);
    // Sales over
    //Design
    const [openDesignTask, setOpenDesignTask] = useState([]);
    const [progressDesignTask, setProgressDesignTask] = useState([]);
    const [holdDesignTask, setholdDesignTask] = useState([]);
    const [cmpltDesignTask, setCmpltDesignTask] = useState([]);
    const [totalDesignTask, seTotaltCmpltDesignTask] = useState([]);
    // Design over
    //Operations
    const [openOperationsTask, setOpenOperationsTask] = useState([]);
    const [progressOperationsTask, setProgressOperationsTask] = useState([]);
    const [holdOperationsTask, setholdOperationsTask] = useState([]);
    const [cmpltOperationsTask, setCmpltOperationsTask] = useState([]);
    const [totalOperationsTask, seTotaltCmpltOperationsTask] = useState([]);
    // Operations over
    //Accounts
    const [openAccountsTask, setOpenAccountsTask] = useState([]);
    const [progressAccountsTask, setProgressAccountsTask] = useState([]);
    const [holdAccountsTask, setholdAccountsTask] = useState([]);
    const [cmpltAccountsTask, setCmpltAccountsTask] = useState([]);
    const [totalAccountsTask, seTotaltCmpltAccountsTask] = useState([]);
    // Accounts over

    useEffect(() => {
        getProjectData("Dashboard/TaskReportByProjectId?id=" + props.id);

    }, [props.id])

    const getProjectData = (method) => {
        Service.getdata(method).then((res) => {
            setdata(res.data);
            debugger
            var arr = [];
            res.data.lstTaskReport.map((e) => (
                arr.push(e)
            ))
            setTaskData(arr)
            setOverAllTaskData(arr);
            if (overAllTaskData) {
                setLoading(true);
            }
            loadNumericData(arr);
            setDataName("OverAll Data")
            debugger
        });

    }



    const requ = [];
    let spentdata = 0;
    useEffect(() => {
        // This effect will run whenever taskData is updated

    }, [taskData]);

    function loadNumericData(data) {
        if (data.length > 0) {
            debugger
            const cmpltTasks = data.filter((e) => e.taskStatus === "Closed");
            const progTasks = data.filter((e) => e.taskStatus === "WIP");
            const holTask = data.filter((e) => e.taskStatus === "Under Review");
            const OpTask = data.filter((e) => e.taskStatus === "Open");

            setCmpltTask(cmpltTasks);
            setProgressTask(progTasks);
            setholdTask(holTask);
            setOpenTask(OpTask);
            debugger
            setRequireDay(parseInt(requ[requ.length - 1]))
            setspentDay(spentdata);

            //Marketing Start
            const cmpltMarketingTask = data.filter((e) => e.taskStatus === "Closed" && e.department === "Marketing");
            const progressMarketingTask = data.filter((e) => e.taskStatus === "WIP" && e.department === "Marketing");
            const holdMarketingTask = data.filter((e) => e.taskStatus === "Under Review" && e.department === "Marketing");
            const opentMarketingTask = data.filter((e) => e.taskStatus === "Open" && e.department === "Marketing");
            const totalMarketingTask = data.filter((e) => e.department === "Marketing");

            setCmpltMarketingTask(cmpltMarketingTask);
            setProgressMarketingTask(progressMarketingTask);
            setholdMarketingTask(holdMarketingTask);
            setOpenMarketingTask(opentMarketingTask);
            seTotaltCmpltMarketingTask(totalMarketingTask);
            //Marketing Over

            //Sales Start
            const cmpltSalesTask = data.filter((e) => e.taskStatus === "Closed" && e.department === "Sales");
            const progressSalesTask = data.filter((e) => e.taskStatus === "WIP" && e.department === "Sales");
            const holdSalesTask = data.filter((e) => e.taskStatus === "Under Review" && e.department === "Sales");
            const opentSalesTask = data.filter((e) => e.taskStatus === "Open" && e.department === "Sales");
            const totalSalesTask = data.filter((e) => e.department === "Sales");

            setCmpltSalesTask(cmpltSalesTask);
            setProgressSalesTask(progressSalesTask);
            setholdSalesTask(holdSalesTask);
            setOpenSalesTask(opentSalesTask);
            seTotaltCmpltSalesTask(totalSalesTask);
            //Sales Over

            //Design Start
            const cmpltDesignTask = data.filter((e) => e.taskStatus === "Closed" && e.department === "Design");
            const progressDesignTask = data.filter((e) => e.taskStatus === "WIP" && e.department === "Design");
            const holdDesignTask = data.filter((e) => e.taskStatus === "Under Review" && e.department === "Design");
            const opentDesignTask = data.filter((e) => e.taskStatus === "Open" && e.department === "Design");
            const totalDesignTask = data.filter((e) => e.department === "Design");

            setCmpltDesignTask(cmpltDesignTask);
            setProgressDesignTask(progressDesignTask);
            setholdDesignTask(holdDesignTask);
            setOpenDesignTask(opentDesignTask);
            seTotaltCmpltDesignTask(totalDesignTask);
            //Design Over

            //Operation Start
            const cmpltOperationsTask = data.filter((e) => e.taskStatus === "Closed" && e.department === "Operation");
            const progressOperationsTask = data.filter((e) => e.taskStatus === "WIP" && e.department === "Operation");
            const holdOperationsTask = data.filter((e) => e.taskStatus === "Under Review" && e.department === "Operation");
            const opentOperationsTask = data.filter((e) => e.taskStatus === "Open" && e.department === "Operation");
            const totalOperationsTask = data.filter((e) => e.department === "Operation");

            setCmpltOperationsTask(cmpltOperationsTask);
            setProgressOperationsTask(progressOperationsTask);
            setholdOperationsTask(holdOperationsTask);
            setOpenOperationsTask(opentOperationsTask);
            seTotaltCmpltOperationsTask(totalOperationsTask);
            //Operation Over

            //Operation Start
            const cmpltAccountsTask = data.filter((e) => e.taskStatus === "Closed" && e.department === "Accounts & Finance");
            const progressAccountsTask = data.filter((e) => e.taskStatus === "WIP" && e.department === "Accounts & Finance");
            const holdAccountsTask = data.filter((e) => e.taskStatus === "Under Review" && e.department === "Accounts & Finance");
            const opentAccountsTask = data.filter((e) => e.taskStatus === "Open" && e.department === "Accounts & Finance");
            const totalAccountsTask = data.filter((e) => e.department === "Accounts & Finance");

            setCmpltAccountsTask(cmpltAccountsTask);
            setProgressAccountsTask(progressAccountsTask);
            setholdAccountsTask(holdAccountsTask);
            setOpenAccountsTask(opentAccountsTask);
            seTotaltCmpltAccountsTask(totalAccountsTask);
            //Operation Over


            //    Please update task efficecy formula
            //    var taskeff = (parseInt(requ[requ.length - 1]) / spentData) * 100;
            // settaskefficency(taskeff);
        }
    }

    function FilterLoadData(status, dept, all) {

        var data = overAllTaskData;
        if (all === 1) {
            setTaskData(data);

            setDataName("OverAll Task");
        }
        else if (all === 2) {
            var openAllTask = data.filter((e) => e.taskStatus === "Open");
            setTaskData(openAllTask);
            setDataName("OverAll Open Task");
        }

        else if (all === 3) {
            var cmpltAllTask = data.filter((e) => e.taskStatus === "Closed");
            setTaskData(cmpltAllTask);
            setDataName("OverAll Closed Task");
        }

        else if (all === 4) {
            var InPogressAllTask = data.filter((e) => e.taskStatus === "WIP");
            setTaskData(InPogressAllTask);
            setDataName("OverAll InProgress Task");
        }

        else if (all === 5) {
            var holdAllTask = data.filter((e) => e.taskStatus === "Under Review");
            setTaskData(holdAllTask);
            setDataName("OverAll InHold Task");
        }

        else if (status === 1 && dept === 1) {
            var marketingOpenTask = data.filter((e) => e.taskStatus === "Open" && e.department === "Marketing");
            setTaskData(marketingOpenTask);
            setDataName("Marketing Open Task");
        }

        else if (status === 2 && dept === 1) {
            var marketingInProgressTask = data.filter((e) => e.taskStatus === "WIP" && e.department === "Marketing");
            setTaskData(marketingInProgressTask);
            setDataName("Marketing InProgress Task");
        }

        else if (status === 3 && dept === 1) {
            var marketingInholdTask = data.filter((e) => e.taskStatus === "Under Review" && e.department === "Marketing");
            setTaskData(marketingInholdTask);
            setDataName("Marketing Hold Task");
        }

        else if (status === 4 && dept === 1) {
            var marketingClosedTask = data.filter((e) => e.taskStatus === "Closed" && e.department === "Marketing");
            setTaskData(marketingClosedTask);
            setDataName("Marketing Closed Task");
        }

        else if (status === 5 && dept === 1) {
            var marketingTask = data.filter((e) => e.department === "Marketing");
            setTaskData(marketingTask);
            setDataName("Marketing Total Task");
        }

        else if (status === 1 && dept === 2) {
            var SalesOpenTask = data.filter((e) => e.taskStatus === "Open" && e.department === "Sales");
            setTaskData(SalesOpenTask);
            setDataName("Sales Open Task");
        }

        else if (status === 2 && dept === 2) {
            var SalesInProgressTask = data.filter((e) => e.taskStatus === "WIP" && e.department === "Sales");
            setTaskData(SalesInProgressTask);
            setDataName("Sales Progress Task");
        }

        else if (status === 3 && dept === 2) {
            var SalesInholdTask = data.filter((e) => e.taskStatus === "Under Review" && e.department === "Sales");
            setTaskData(SalesInholdTask);
            setDataName("Sales Hold Task");
        }

        else if (status === 4 && dept === 2) {
            var SalesClosedTask = data.filter((e) => e.taskStatus === "Closed" && e.department === "Sales");
            setTaskData(SalesClosedTask);
            setDataName("Sales Closed Task");
        }

        else if (status === 5 && dept === 2) {
            var SalesTask = data.filter((e) => e.department === "Sales");
            setTaskData(SalesTask);
            setDataName("Sales Total Task");
        }

        else if (status === 1 && dept === 3) {
            var DesignOpenTask = data.filter((e) => e.taskStatus === "Open" && e.department === "Design");
            setTaskData(DesignOpenTask);
            setDataName("Design Open Task");
        }

        else if (status === 2 && dept === 3) {
            var DesignInProgressTask = data.filter((e) => e.taskStatus === "WIP" && e.department === "Design");
            setTaskData(DesignInProgressTask);
            setDataName("Design Progress Task");
        }

        else if (status === 3 && dept === 3) {
            var DesignInholdTask = data.filter((e) => e.taskStatus === "Under Review" && e.department === "Design");
            setTaskData(DesignInholdTask);
            setDataName("Design Hold Task");
        }

        else if (status === 4 && dept === 3) {
            var DesignClosedTask = data.filter((e) => e.taskStatus === "Closed" && e.department === "Design");
            setTaskData(DesignClosedTask);
            setDataName("Design Closed Task");
        }

        else if (status === 5 && dept === 3) {
            var DesignTask = data.filter((e) => e.department === "Design");
            setTaskData(DesignTask);
            setDataName("Design Total Task");
        }

        else if (status === 1 && dept === 4) {
            var OperationOpenTask = data.filter((e) => e.taskStatus === "Open" && e.department === "Operation");
            setTaskData(OperationOpenTask);
            setDataName("Operation Open Task");
        }

        else if (status === 2 && dept === 4) {
            var OperationInProgressTask = data.filter((e) => e.taskStatus === "WIP" && e.department === "Operation");
            setTaskData(OperationInProgressTask);
            setDataName("Operation Progress Task");
        }

        else if (status === 3 && dept === 4) {
            var OperationInholdTask = data.filter((e) => e.taskStatus === "Under Review" && e.department === "Operation");
            setTaskData(OperationInholdTask);
            setDataName("Operation Hold Task");
        }

        else if (status === 4 && dept === 4) {
            var OperationClosedTask = data.filter((e) => e.taskStatus === "Closed" && e.department === "Operation");
            setTaskData(OperationClosedTask);
            setDataName("Operation Closed Task");
        }

        else if (status === 5 && dept === 4) {
            var OperationTask = data.filter((e) => e.department === "Operation");
            setTaskData(OperationTask);
            setDataName("Operation Total Task");
        }

        else if (status === 1 && dept === 5) {
            var AccountsOpenTask = data.filter((e) => e.taskStatus === "Open" && e.department === "Accounts & Finance");
            setTaskData(AccountsOpenTask);
            setDataName("Accounts Open Task");
        }

        else if (status === 2 && dept === 5) {
            var AccountsInProgressTask = data.filter((e) => e.taskStatus === "WIP" && e.department === "Accounts & Finance");
            setTaskData(AccountsInProgressTask);
            setDataName("Accounts Progress Task");
        }

        else if (status === 3 && dept === 5) {
            var AccountsInholdTask = data.filter((e) => e.taskStatus === "Under Review" && e.department === "Accounts & Finance");
            setTaskData(AccountsInholdTask);
            setDataName("Accounts Hold Task");
        }

        else if (status === 4 && dept === 5) {
            var AccountsClosedTask = data.filter((e) => e.taskStatus === "Closed" && e.department === "Accounts & Finance");
            setTaskData(AccountsClosedTask);
            setDataName("Accounts Closed Task");
        }

        else if (status === 5 && dept === 5) {
            var AccountsTask = data.filter((e) => e.department === "Accounts & Finance");
            setTaskData(AccountsTask);
            setDataName("Accounts Total Task");
        }

        window.scrollTo({ behavior: 'smooth', top: myRef.current.offsetTop });
    }

    const projectPage = () => {
        window.location.href = "/TotalProjectDashboard";
    }

    function formatDate(dateString) {
        const dateObject = new Date(dateString);

        const day = String(dateObject.getDate()).padStart(2, '0');
        const month = String(dateObject.getMonth() + 1).padStart(2, '0');
        const year = dateObject.getFullYear();

        if (day !== "NaN") {

            return `${day}-${month}-${year}`;
        }

        else {
            return ""
        }
    }



    //var req = 0;
    // const dayRequiredCalculaion = (start, end) => {

    //     console.log(start);
    //     console.log(end);
    //     var s1 = new Date(start)
    //     var e1 = new Date(end)


    //     console.log(s1);
    //     console.log(e1);
    //     debugger
    //     const dateDifference = new Date(new Date(end) - new Date(start));
    //     console.log(dateDifference)
    //     debugger
    //     const day = String(dateDifference.getDate()).padStart(2, '0');
    //     console.log(day);
    //     debugger
    //     if (requ[requ.length - 1] !== undefined) {

    //         var d = parseInt(requ[requ.length - 1]) + parseInt(day);
    //         requ.push(d);
    //     } else {
    //         requ.push(day);
    //     }

    //     // req = requ[requ.length - 1]
    //     console.log("Last value of requ array:", requ[requ.length - 1]);

    //     // if(requ.length === data.lstTaskReport.length ){
    //     //     setRequireDay(parseInt(requ[requ.length - 1]) )
    //     // }

    //     return `${day}`;
    // }

    const dayRequiredCalculaion = (start, end) => {
        const startWithoutTime = new Date(start);
        startWithoutTime.setHours(0, 0, 0, 0);

        const endWithoutTime = new Date(end);
        endWithoutTime.setHours(0, 0, 0, 0);

        const dateDifference = endWithoutTime - startWithoutTime;

        const days = Math.max(1, Math.floor(dateDifference / (24 * 60 * 60 * 1000)));

        if (requ[requ.length - 1] !== undefined) {
            const accumulatedDays = parseInt(requ[requ.length - 1]) + days;
            requ.push(accumulatedDays);
        } else {
            requ.push(days);
        }

        console.log("Last value of requ array:", requ[requ.length - 1]);

        return `${days}`;
    };


    const daySpentCalculaion = (start, end, status) => {

        if (status === "Closed") {
            const dateDifference = new Date(new Date(end) - new Date(start));
            const day = String(dateDifference.getDate()).padStart(2, '0');
            spentdata = parseInt(spentdata) + parseInt(day);
            return `${day}`;
        }

        else {
            return 0
        }


    };

    const ProgressPercent = (e) => {
        if (e === "Open") {
            return "5%";
        }
        else if (e === "WIP") {
            return "10%";
        }
        else if (e === "Under Review") {
            return "80%";
        }
        else if (e === "Closed") {
            return "100%";
        }
    }

    const RemainingPercent = (e) => {
        if (e === "Open") {
            return "95%";
        }
        else if (e === "WIP") {
            return "90%";
        }
        else if (e === "Under Review") {
            return "10%";
        }
        else if (e === "Closed") {
            return "0%";
        }
    }

    const externalLink = (data) => {
        window.open(data, '_blank')
    }

    const linkformatter = (data, row) => {
        var Co = data;
        debugger
        if (Co !== undefined && Co !== null) {
            var content = Co.split(',');
            return (
                <>
                    <div className='d-flex'>
                        {content?.map((link, index) => (
                            <div className="form-group  d-flex " key={index}>
                                <div className='btn btn-sm ml-2 btn-info' style={{ fontSize: "11px" }} onClick={() => externalLink(link)}>
                                    {index !== 0 ? 'Oldest Link ' + index : 'Latest Link'}
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            );
        } else {
            return null; // Return null if content is undefined or null
        }
    }


    return (

        <div>
            <div className="container-fluid">
                <br />

                <form>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <div className="row">
                                <div className="col-sm-9"><h3>Project Details - {data.project}</h3></div>
                                {/* <div className="col-sm-1"></div> */}
                                <div className="col-sm-3 d-flex flex-row-reverse">
                                    <Link className="btn btn-info mt-2 ml-1  text-uppercase " to='/TotalProjectDashboard'>back</Link>
                                    <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            {loading === true && <h6 className="font-weight-bold text-primary">All Details Info</h6>}{loading === false &&
                                <div class=" text-primary">
                                    <strong>Loading...</strong>
                                    <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                                </div>
                            }
                        </div>

                        <div className="card-body">
                            <div className="form-group row">
                                <div className='col-sm-3'>Project Name</div>
                                <div className='col-sm-9'> <b>{data.project}</b> </div>
                            </div>
                            <div className="form-group row">
                                <div className='col-sm-3'>Lead Name</div>
                                <div className='col-sm-9'> <b>{data.lead}</b> </div>
                            </div>
                            <div className="form-group row">
                                <div className='col-sm-3'>Show Name/Direct Lead</div>
                                <div className='col-sm-9'> <b>{data.show}</b> </div>

                            </div>
                            <div className="form-group row">
                                <div className='col-sm-3'>Exhibitor Name/Direct Lead</div>
                                <div className='col-sm-9'> <b>{data.exhibitor}</b> </div>

                            </div>
                            <div className="form-group row">
                                <div className='col-sm-3'>Start Date</div>
                                <div className='col-sm-9'> <b>{formatDate(data.startDate)}</b></div>

                            </div>

                            <div className="form-group row">
                                <div className='col-sm-3'>End Date</div>
                                <div className='col-sm-9'> <b>{formatDate(data.endDate)}</b> </div>

                            </div>

                            <div className="form-group row">
                                <div className='col-sm-3'>RFP</div>
                                <div className='col-sm-9'>
                                    <Link to={`/RFPDetails/${data.rfPid}`}>Click Here</Link>
                                </div>

                            </div>

                            <div className="form-group row">
                                <div className='col-sm-3'>Operations Team</div>
                                <div className='col-sm-9'>
                                    {data.lstProjectTeam?.map((idx) => (
                                        <b>
                                            {idx.userName}, &nbsp;
                                        </b>
                                    ))}
                                </div>

                            </div>
                            <div className="form-group row">
                                <div className='col-sm-3'>Design Team</div>
                                <div className='col-sm-9'>
                                    {data.lstDesignTeam?.map((idx) => (
                                        <b>
                                            {idx.userName}, &nbsp;
                                        </b>
                                    ))}
                                </div>

                            </div>

                            <div className='col-sm-12 row'>
                                <div className='col-sm-4'>
                                    <div className="card-header" >
                                        <h6 className="m-0 font-weight-bold text-dark">Task Status OverAll</h6>
                                    </div>

                                    <div className="card-body">
                                        <div className="form-group row d-flex border border-dark rounded btn hover" onClick={() => FilterLoadData(0, 0, 2)}>
                                            <div className='col-sm-3'>Open</div>
                                            <div className="col-sm-6"></div>
                                            <div className='col-sm-3'> <b>{openTask.length}</b></div>
                                        </div>

                                        <div className="form-group row d-flex border border-dark rounded btn hover" onClick={() => FilterLoadData(0, 0, 4)}>
                                            <div className='col-sm-4'>In Progress</div>
                                            <div className="col-sm-5"></div>
                                            <div className='col-sm-3'> <b>{progressTask.length}</b></div>
                                        </div>


                                        <div className="form-group row d-flex border border-dark rounded btn hover" onClick={() => FilterLoadData(0, 0, 5)}>
                                            <div className='col-sm-3'>In Hold</div>
                                            <div className="col-sm-6"></div>
                                            <div className='col-sm-3'> <b>{holdTask.length}</b></div>
                                        </div>

                                        <div className="form-group row d-flex border border-dark rounded btn hover" onClick={() => FilterLoadData(0, 0, 3)}>
                                            <div className='col-sm-4'>Completed</div>
                                            <div className="col-sm-5"></div>
                                            <div className='col-sm-3'> <b>{cmpltTask.length}</b></div>
                                        </div>


                                        <hr />

                                        <div className="form-group row btn-dark rounded" onClick={() => FilterLoadData(0, 0, 1)}>
                                            <div className='col-sm-8'>Total Task</div>
                                            <div className='col-sm-1 mr-3'></div>
                                            <div className='col-sm-2'> <b>{overAllTaskData.length}</b></div>
                                        </div>

                                    </div>
                                </div>
                                {/* <div className='col-sm-4'>
                                    <div className="card-header">
                                        <h6 className="m-0 font-weight-bold text-info">Project Status</h6>
                                    </div>

                                    <div className="card-body">
                                        <div className="form-group row">
                                            <div className='col-sm-9'>Total Day Req</div>
                                            <div className='col-sm-3'> <b>{requireDay}</b></div>
                                        </div>

                                        <div className="form-group row">
                                            <div className='col-sm-9'>Total Day Spent</div>
                                            <div className='col-sm-3'> <b>{spentDay}</b></div>
                                        </div>


                                        <div className="form-group row">
                                            <div className='col-sm-9'>Task Efficency</div>
                                            <div className='col-sm-3'> <b>{taskefficency}</b></div>
                                        </div>


                                    </div>
                                </div> */}
                                <div className='col-sm-4'>

                                </div>
                                <div className='col-sm-4'>

                                </div>

                                {/* Department */}
                                <div className='col-sm-4'>
                                    <div className="card-header">
                                        <h6 className="m-0 font-weight-bold text-info">Marketing Task</h6>
                                    </div>

                                    <div className="card-body">
                                        <div className="form-group row d-flex border border-info rounded btn hoverMarketing" onClick={() => FilterLoadData(1, 1, 0)}>
                                            <div className='col-sm-4'>Open</div>
                                            <div className="col-sm-5"></div>
                                            <div className='col-sm-3'> <b>{openMarketingTask.length}</b></div>
                                        </div>

                                        <div className="form-group row d-flex border border-info rounded btn hoverMarketing" onClick={() => FilterLoadData(2, 1, 0)}>
                                            <div className='col-sm-4'>In Progress</div>
                                            <div className="col-sm-5"></div>
                                            <div className='col-sm-3'> <b>{progressMarketingTask.length}</b></div>
                                        </div>


                                        <div className="form-group row d-flex border border-info rounded btn hoverMarketing" onClick={() => FilterLoadData(3, 1, 0)}>
                                            <div className='col-sm-4'>In Hold</div>
                                            <div className="col-sm-5"></div>
                                            <div className='col-sm-3'> <b>{holdMarketingTask.length}</b></div>
                                        </div>

                                        <div className="form-group row d-flex border border-info rounded btn hoverMarketing" onClick={() => FilterLoadData(4, 1, 0)}>
                                            <div className='col-sm-4'>Completed</div>
                                            <div className="col-sm-5"></div>
                                            <div className='col-sm-3'> <b>{cmpltMarketingTask.length}</b></div>
                                        </div>

                                        {/* <div className="form-group row">
                                            <div className='col-sm-9'>RequiredDay</div>
                                            <div className='col-sm-3'> <b>{requireDay}</b></div>
                                        </div> */}


                                        <hr />

                                        <div className="form-group row btn-info rounded" onClick={() => FilterLoadData(5, 1, 0)}>
                                            <div className='col-sm-8'>Total Task</div>
                                            <div className='col-sm-1 mr-3'></div>
                                            <div className='col-sm-2'> <b>{totalMarketingTask.length}</b></div>
                                        </div>

                                    </div>
                                </div>

                                <div className='col-sm-4'>
                                    <div className="card-header">
                                        <h6 className="m-0 font-weight-bold text-success ">Sales Task</h6>
                                    </div>

                                    <div className="card-body">
                                        <div className="form-group row d-flex border border-success rounded btn hoverSales" onClick={() => FilterLoadData(1, 2, 0)}>
                                            <div className='col-sm-4'>Open</div>
                                            <div className="col-sm-5"></div>
                                            <div className='col-sm-3'> <b>{openSalesTask.length}</b></div>
                                        </div>

                                        <div className="form-group row d-flex border border-success rounded btn hoverSales" onClick={() => FilterLoadData(2, 2, 0)}>
                                            <div className='col-sm-4'>In Progress</div>
                                            <div className="col-sm-5"></div>
                                            <div className='col-sm-3'> <b>{progressSalesTask.length}</b></div>
                                        </div>


                                        <div className="form-group row d-flex border border-success rounded btn hoverSales" onClick={() => FilterLoadData(3, 2, 0)}>
                                            <div className='col-sm-4'>In Hold</div>
                                            <div className="col-sm-5"></div>
                                            <div className='col-sm-3'> <b>{holdSalesTask.length}</b></div>
                                        </div>

                                        <div className="form-group row d-flex border border-success rounded btn hoverSales" onClick={() => FilterLoadData(4, 2, 0)}>
                                            <div className='col-sm-4'>Completed</div>
                                            <div className="col-sm-5"></div>
                                            <div className='col-sm-3'> <b>{cmpltSalesTask.length}</b></div>
                                        </div>

                                        {/* <div className="form-group row">
                                            <div className='col-sm-9'>RequiredDay</div>
                                            <div className='col-sm-3'> <b>{requireDay}</b></div>
                                        </div> */}


                                        <hr />

                                        <div className="form-group row btn-success rounded" onClick={() => FilterLoadData(5, 2, 0)}>
                                            <div className='col-sm-8'>Total Task</div>
                                            <div className="col-sm-1 mr-3"></div>
                                            <div className='col-sm-2'> <b>{totalSalesTask.length}</b></div>
                                        </div>

                                    </div>
                                </div>

                                <div className='col-sm-4'>
                                    <div className="card-header">
                                        <h6 className="m-0 font-weight-bold text-primary">Design Task</h6>
                                    </div>

                                    <div className="card-body">
                                        <div className="form-group row d-flex border border-primary rounded btn hoverDesign" onClick={() => FilterLoadData(1, 3, 0)}>
                                            <div className='col-sm-4'>Open</div>
                                            <div className="col-sm-5"></div>
                                            <div className='col-sm-3'> <b>{openDesignTask.length}</b></div>
                                        </div>

                                        <div className="form-group row d-flex border border-primary rounded btn hoverDesign" onClick={() => FilterLoadData(2, 3, 0)}>
                                            <div className='col-sm-4'>In Progress</div>
                                            <div className="col-sm-5"></div>
                                            <div className='col-sm-3'> <b>{progressDesignTask.length}</b></div>
                                        </div>


                                        <div className="form-group row d-flex border border-primary rounded btn hoverDesign" onClick={() => FilterLoadData(3, 3, 0)}>
                                            <div className='col-sm-4'>In Hold</div>
                                            <div className="col-sm-5"></div>
                                            <div className='col-sm-3'> <b>{holdDesignTask.length}</b></div>
                                        </div>

                                        <div className="form-group row d-flex border border-primary rounded btn hoverDesign" onClick={() => FilterLoadData(4, 3, 0)}>
                                            <div className='col-sm-4'>Completed</div>
                                            <div className="col-sm-5"></div>
                                            <div className='col-sm-3'> <b>{cmpltDesignTask.length}</b></div>
                                        </div>

                                        {/* <div className="form-group row">
                                            <div className='col-sm-9'>RequiredDay</div>
                                            <div className='col-sm-3'> <b>{requireDay}</b></div>
                                        </div> */}


                                        <hr />

                                        <div className="form-group row btn-primary rounded" onClick={() => FilterLoadData(5, 3, 0)}>
                                            <div className='col-sm-8'>Total Task</div>
                                            <div className="col-sm-1 mr-3"></div>
                                            <div className='col-sm-2 '> <b>{totalDesignTask.length}</b></div>
                                        </div>

                                    </div>
                                </div>

                                <div className='col-sm-4'>
                                    <div className="card-header">
                                        <h6 className="m-0 font-weight-bold text-warning">Operations Task</h6>
                                    </div>

                                    <div className="card-body">
                                        <div className="form-group row d-flex border border-warning rounded btn hoverOperations" onClick={() => FilterLoadData(1, 4, 0)}>
                                            <div className='col-sm-4'>Open</div>
                                            <div className="col-sm-5 "></div>
                                            <div className='col-sm-3'> <b>{openOperationsTask.length}</b></div>
                                        </div>

                                        <div className="form-group row d-flex border border-warning rounded btn hoverOperations" onClick={() => FilterLoadData(2, 4, 0)}>
                                            <div className='col-sm-4'>In Progress</div>
                                            <div className="col-sm-5 "></div>
                                            <div className='col-sm-3'> <b>{progressOperationsTask.length}</b></div>
                                        </div>


                                        <div className="form-group row d-flex border border-warning rounded btn hoverOperations" onClick={() => FilterLoadData(3, 4, 0)}>
                                            <div className='col-sm-4'>In Hold</div>
                                            <div className="col-sm-5 "></div>
                                            <div className='col-sm-3'> <b>{holdOperationsTask.length}</b></div>
                                        </div>

                                        <div className="form-group row d-flex border border-warning rounded btn hoverOperations" onClick={() => FilterLoadData(4, 4, 0)}>
                                            <div className='col-sm-4'>Completed</div>
                                            <div className="col-sm-5 "></div>
                                            <div className='col-sm-3'> <b>{cmpltOperationsTask.length}</b></div>
                                        </div>

                                        {/* <div className="form-group row">
                                            <div className='col-sm-9'>RequiredDay</div>
                                            <div className='col-sm-3'> <b>{requireDay}</b></div>
                                        </div> */}


                                        <hr />

                                        <div className="form-group row btn-warning rounded" onClick={() => FilterLoadData(5, 4, 0)}>
                                            <div className='col-sm-8'>Total Task</div>
                                            <div className='col-sm-1 mr-3'></div>
                                            <div className='col-sm-2'> <b>{totalOperationsTask.length}</b></div>
                                        </div>

                                    </div>
                                </div>

                                <div className='col-sm-4'>
                                    <div className="card-header">
                                        <h6 className="m-0 font-weight-bold text-danger">Finance/Accounts Task</h6>
                                    </div>

                                    <div className="card-body">
                                        <div className="form-group row d-flex border border-danger rounded btn  hoverfinance" onClick={() => FilterLoadData(1, 5, 0)}>
                                            <div className='col-sm-4'>Open</div>
                                            <div className="col-sm-5"></div>
                                            <div className='col-sm-3'> <b>{openAccountsTask.length}</b></div>
                                        </div>

                                        <div className="form-group row d-flex border border-danger rounded btn hoverfinance" onClick={() => FilterLoadData(2, 5, 0)}>
                                            <div className='col-sm-4'>In Progress</div>
                                            <div className="col-sm-5"></div>
                                            <div className='col-sm-3'> <b>{progressAccountsTask.length}</b></div>
                                        </div>


                                        <div className="form-group row d-flex border border-danger rounded btn hoverfinance" onClick={() => FilterLoadData(3, 5, 0)}>
                                            <div className='col-sm-4'>In Hold</div>
                                            <div className="col-sm-5"></div>
                                            <div className='col-sm-3'> <b>{holdAccountsTask.length}</b></div>
                                        </div>

                                        <div className="form-group row d-flex border border-danger rounded btn hoverfinance" onClick={() => FilterLoadData(4, 5, 0)}>
                                            <div className='col-sm-4'>Completed</div>
                                            <div className="col-sm-5"></div>
                                            <div className='col-sm-3'> <b>{cmpltAccountsTask.length}</b></div>
                                        </div>

                                        {/* <div className="form-group row">
                                            <div className='col-sm-9'>RequiredDay</div>
                                            <div className='col-sm-3'> <b>{requireDay}</b></div>
                                        </div> */}


                                        <hr />

                                        <div className="form-group row btn-danger rounded" onClick={() => FilterLoadData(5, 5, 0)}>
                                            <div className='col-sm-8'>Total Task</div>
                                            <div className='col-sm-1 mr-3'></div>
                                            <div className='col-sm-2'> <b>{totalAccountsTask.length}</b></div>
                                        </div>

                                    </div>
                                </div>

                                {/* <div className='col-sm-4'>
                                    <div className="card-header">
                                        <h6 className="m-0 font-weight-bold text-info">Task Status OverAll</h6>
                                    </div>

                                    <div className="card-body">
                                        <div className="form-group row">
                                            <div className='col-sm-9'>Open</div>
                                            <div className='col-sm-3'> <b>{openTask.length}</b></div>
                                        </div>

                                        <div className="form-group row">
                                            <div className='col-sm-9'>In Progress</div>
                                            <div className='col-sm-3'> <b>{progressTask.length}</b></div>
                                        </div>


                                        <div className="form-group row">
                                            <div className='col-sm-9'>In Hold</div>
                                            <div className='col-sm-3'> <b>{holdTask.length}</b></div>
                                        </div>

                                        <div className="form-group row">
                                            <div className='col-sm-9'>Completed</div>
                                            <div className='col-sm-3'> <b>{cmpltTask.length}</b></div>
                                        </div>
                                        <hr />

                                        <div className="form-group row">
                                            <div className='col-sm-9'>Total Task</div>
                                            <div className='col-sm-3'> <b>{data.lstTaskReport ? data.lstTaskReport.length : 0}</b></div>
                                        </div>

                                    </div>
                                </div> */}
                            </div>

                        </div>

                    </div>
                    <h4 ref={myRef}>Filtered Data - {dataName}</h4>
                    <div className="card shadow mb-4" style={{ overflow: "y", }} id='tdData' >
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Project TaskList</h6>
                            {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
                        </div>

                        <div className="card-body m-0" style={{ maxHeight: "500px", overflow: "auto", }}>
                            <div class="row" >
                                <div className="col-lg-12 ml-0">
                                    <table class="table table-xl">
                                        {/* <caption>List of users</caption> */}
                                        <thead className='thead-dark' style={{ position: "sticky", top: "-19px", zIndex: "1" }}>
                                            <tr>
                                                {/* <th scope="col">Task Id</th> */}
                                                <th scope="col">Work Requirement</th>
                                                <th scope="col">Department</th>
                                                <th scope="col">Project/Lead/Show</th>
                                                <th scope="col">Task HeadLine</th>
                                                <th scope="col">Starting Date</th>
                                                <th scope="col">Deadline Date</th>
                                                <th scope="col">Completed Date</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Days spent</th>
                                                <th scope="col">Days Required</th>
                                                <th scope="col">Assigned by</th>
                                                <th scope="col">Assigned To</th>
                                                <th scope="col">Content Link</th>
                                                <th scope="col">Progress%</th>
                                                <th scope="col">Remainig%</th>
                                                <th scope="col">Details</th></tr>
                                        </thead>
                                        <tbody>
                                            {taskData.map((e) => (<tr>
                                                {/* <th scope="row">{e.id}</th> */}
                                                <td>{e.taskType}</td>
                                                <td>{e.department}</td>
                                                <td>{e.project}</td>
                                                <td>{e.headline}</td>
                                                <td>{formatDate(e.startDate)}</td>
                                                <td>{formatDate(e.deadlineDate)}</td>
                                                <td>{formatDate(e.taskStatus !== "Closed" ? "" : e.completedDate)}</td>
                                                <td>{e.taskStatus}</td>
                                                <td>{daySpentCalculaion(e.startDate, e.completedDate, e.taskStatus)}</td>
                                                <td>{dayRequiredCalculaion(e.startDate, e.deadlineDate)}</td>
                                                <td>{e.taskassignuserdetails?.slice(0, 1).map((t) => (
                                                    <p>{t.assignerName},</p>
                                                ))}</td>
                                                <td>{e.taskassignuserdetails?.map((t) => (
                                                    <p>{t.assigneeName},</p>
                                                ))}</td>
                                                <td>{linkformatter(e.contentLink)}</td>
                                                <td>{ProgressPercent(e.taskStatus)}</td>
                                                <td>{RemainingPercent(e.taskStatus)}</td>
                                                <td><Link className="btn btn-warning btn-sm" to={`/TaskDetails/${e.id}&${"deadline"}`}>Details</Link></td>
                                            </tr>))}


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>



                </form>
            </div>

        </div>
    )
}

export default DetailsProjectComponent