import React, { useEffect, useState } from 'react';
import Service from "../../../Services/Admin/Service";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Select from 'react-select';

import draftToHtml from 'draftjs-to-html';
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState, convertFromHTML, ContentState, convertFromRaw } from "draft-js";
import { Link } from 'react-router-dom';

const SpecificTaskDetailComponent = (props) => {
    const [taskDetail, setTaskDetail] = useState([])

    const id = props.id;
    var Createdby = 0;

   const userid = sessionStorage.getItem("id")
    const [status, setStatus] = useState([]);
    const [commentInput, setCommentInput] = useState({
        comments: ''
    });
    const [comments, setComments] = useState([]);
    const [taskassignerid, settaskassignerid] = useState(0);
    const [detailFlag, setDetailFlag] = useState(false);
    const [userName, setUserName] = useState([]);
    const [showdata, setShowdata] = useState([]);
    const [statusId, setStatusId] = useState('');
    const [contentLink, setContentLink] = useState('');
    const [dropdownTaskType, setdropdownTaskType] = useState([]);
    const [showAssigneePopup, setShowAssigneePopup] = useState(false);
    const [TaskTypeid, setTaskTypeid] = useState('');
    const [taskPriority, setTaskPriority] = useState([]);
    const [taskPriorityid, settaskPriorityid] = useState('');
    const [dateId, setdateId] = useState([]);
    const [departent, setDepartment] = useState([]);
    const [departentid, setdepartentid] = useState('');
    const [Projectdata, setProjectdata] = useState([]);
    const [Projectid, setProjectid] = useState([]);
    const [taskAssignerId, settaskAssignerId] = useState(0);
    const [statusError, setStatusError] = useState(false);
    const [showDiscussion, setShowDiscussion] = useState(false);
    const [apiResponseComment, setapiResponseComment] = useState(null)
    const [apiResponse, setApiResponse] = useState(null);
    const [apiError, setApiError] = useState(null);
    const [discussionData, setDiscussionData] = useState([]);
    const [selectedAssignees, setSelectedAssignees] = useState([]);

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const [editorCommentState, setEditorCommentState] = useState(() =>
        EditorState.createEmpty()
    );
    const [inputField, setInputField] = useState({
        id: id,
        headline: '',
        shortDescription: '',
        projectId: '',
        showId: 0,
        leadId: 0,
        parentId: null,
        typeId: 0,
        statusId: 0,
        priorityId: 0,
        planhour: 0,
        finishdate: '',
        createdby: id,
        updatedby: id,
        assignerid: id,
        contentLink: null,
        isDeleted: false,
        isActive: true,
        assigneeid: [0],
        removeAssigneeid: []
    })

    const [sharedData, setSharedData] = useState({
        id: 0,
        headline: '',
        shortDescription: '',
        projectId: '',
        parentId: props.id,
        typeId: 0,
        statusId: 1,
        priorityId: 0,
        planhour: 0,
        finishdate: '',
        createdby: id,
        updatedby: id,
        contentLink: null,
        assignerid: id,
        isDeleted: false,
        isActive: true,
        assigneeid: [0],
        removeAssigneeid: []

    })

    const handleDiscussionButtonClick = () => {
        setShowDiscussion((prevShowDiscussion) => !prevShowDiscussion);
    };

    const [inputDiscussion, setInputDiscussion] = useState({
        "discussionid": 0,
        "taskid": props.id,
        "userid": sessionStorage.getItem("id")
    })

    useEffect(() => {
        getTaskInfo("Task/GetById?id=" + id)
        getStatusData("Status/GetAll");
        getTaskTypeData("TaskType/GetAll");
        getTaskPriority("Priority/GetAll")
        getUserData("Users/GetAll");
        getShowData("Shows/GetAllShows");
        getDepartmentData("Department/GetAll");
        getDiscussionData("Task/GetALLTaskDiscussion?taskid=" + inputDiscussion.taskid);
      

    }, [])

    const getStatusData = (method) => {
        Service.getdata(method).then((res) => {
            setStatus(res.data);
        });
    }

    const getUserData = (method) => {
        Service.getdata(method).then((res) => {
            setUserName(res.data);
        });
    }

    const getDiscussionData = (method) => {
        Service.getdata(method).then((res) => {
            setDiscussionData(res.data);
            setComments(res.data); // Set the comments in the state
        });
    }


    useEffect(() => {
        if (apiResponse || apiError) {
            const timeoutId = setTimeout(removeSuccessMessage, 3000);

            return () => clearTimeout(timeoutId);
        }
    }, [apiResponse, apiError]);

    useEffect(() => {
        if (apiResponseComment) {
            const timeoutId = setTimeout(removeSuccessMessage, 3000);

            return () => clearTimeout(timeoutId);
        }
    }, [apiResponseComment]);

    const removeSuccessMessage = () => {
        setApiResponse(null);
        setapiResponseComment(null);
        setApiError(null);
    };

    const getTaskTypeData = (method) => {
        Service.getdata(method).then((res) => {
            setdropdownTaskType(res.data);
        });
    }

    const getTaskPriority = (method) => {
        Service.getdata(method).then((res) => {
            setTaskPriority(res.data);
        });
    }



    const getShowData = (method) => {
        Service.getdata(method).then((res) => {
            setShowdata(res.data);
            debugger
        });
    }

    const getDepartmentData = (method) => {
        Service.getdata(method).then((res) => {
            setDepartment(res.data);
        });
    }

    const getTaskInfo = (method) => {
        Service.getdata(method).then((res) => {
            setTaskDetail(res.data);
            console.log("Task Details", res.data)
            settaskassignerid(res.data.createdby);
            Createdby = res.data.createdby;
            debugger
            var taskAssignerId = res.data.taskassignuserdetails[0].assignerid;
            settaskAssignerId(taskAssignerId)
            const content1 = convertFromRaw(JSON.parse(res.data.shortDescription));

            setEditorState(EditorState.createWithContent(content1));
            setStatusId(res.data.statusId);
            setTaskTypeid(res.data.typeId);
            settaskPriorityid(res.data.priorityId);
            setProjectid(res.data.projectId);
          
            setContentLink(res.data.contentLink);
            debugger
            setdepartentid("sales");
            setdateId(res.data.finishdate);

            const assigneeIds = res.data.taskassignuserdetails.map((assignee) => assignee.assigneeid);
            setSelectedAssignees(assigneeIds);
        });
    }

    const handleStatusChange = (e) => {
        setStatusId(e.target.value);

    }

    const handleLinkChange = (e) => {
        setContentLink(e.target.value);

    }
    const handleTaskChange = (e) => {
        setTaskTypeid(e.target.value);

    }
    const handlePriorityChange = (e) => {
        settaskPriorityid(e.target.value);

    }
    const handleProjectChange = (e) => {
        setProjectid(e.target.value);

    }

    const handleDepartmentChange = (e) => {
        setdepartentid(e.target.value);

    }

    function extractValuesFromIndexedObject(indexedObject) {
        return Object.values(indexedObject).map(item => item.value);
    }

    const handleSelectChange = (selectedOptions) => {
        const extractedValues = extractValuesFromIndexedObject(selectedOptions);
        setSelectedAssignees(extractedValues);

    };

    const shared = (evt) => {

        if (evt && evt.preventDefault) {
            evt.preventDefault();
        }

        const formData = new FormData(evt.target);

        const data = {}
        for (const [key, value] of formData) {
            data[key] = value;
        }

        const currentContent = editorState.getCurrentContent();
        const messageBody = convertToRaw(currentContent);
        var jsonData = JSON.stringify(messageBody);
        // const loadData = JSON.parse(jsonData);
        // var content=loadData.blocks[0].text;
        let currentContentAsHTML = draftToHtml(messageBody);
        //  inputField.ContentJosn = jsonData;

        sharedData.shortDescription = jsonData;

        const task = {
            ...sharedData,
            ...data,
            statusId: 1,
            assigneeid: selectedAssignees,
            finishdate: dateId,

        };
        console.log(task)
        debugger
        if (task) {
            Service.createorupdate("Task/CreateOrUpdate", task)
                .then(res => {
                    setApiResponse(res.data); // Set the API response in state
                })
                .catch(error => {
                    // Handle error if API call fails
                    console.error("API Error:", error);
                    setApiError("Error occurred while saving the meeting. Please try again later.");
                });
        }
    }


    const submitButton = (evt) => {

        if (evt && evt.preventDefault) {
            evt.preventDefault();
        }
        const formData = new FormData(evt.target);

        const data = {}
        for (const [key, value] of formData) {
            data[key] = value;
        }



        const currentContent = editorState.getCurrentContent();
        const messageBody = convertToRaw(currentContent);
        var jsonData = JSON.stringify(messageBody);
        // const loadData = JSON.parse(jsonData);
        // var content=loadData.blocks[0].text;
        let currentContentAsHTML = draftToHtml(messageBody);
        //  inputField.ContentJosn = jsonData;

        inputField.shortDescription = jsonData;

        inputField.createdby = taskAssignerId;
        inputField.updatedby = taskAssignerId;
        inputField.assignerid = taskAssignerId;
        const task = {
            ...inputField,
            ...data,
            assigneeid: selectedAssignees,
            finishdate: dateId,

        };
        console.log(task)

        if (task) {
            Service.createorupdate("Task/CreateOrUpdate", task)
                .then(res => {
                    setApiResponse(res.data); // Set the API response in state
                })
                .catch(error => {
                    // Handle error if API call fails
                    console.error("API Error:", error);
                    setApiError("Error occurred while saving the meeting. Please try again later.");

                });
        }

    }

    const submitDiscussion = (evt) => {
        if (evt && evt.preventDefault) {
            evt.preventDefault();
        }
        const formData = new FormData(evt.target);

        const data = {}
        for (const [key, value] of formData) {
            data[key] = value;
        }

        function validateData(data) {
            const { comments } = data;

            if (!comments) {
                alert("Please fill in all required fields.");
                return false;
            }
            //condition  met, so return true to proceed forward.
            return true;
        }

        if (!validateData(data)) {
            // The validation returned false, so stop execution here.
            // You can add any specific handling for invalid data here, if needed.
            return;
        }

        const comment = {
            ...inputDiscussion,
            ...data,
        };
        console.log(comment)

        if (comment) {
            Service.createorupdate("Task/CreateOrUpdateDiscussion", comment)
                .then(res => {
                    setapiResponseComment(res.data); // Set the API response in state
                    setComments(prevComments => [...prevComments, comment]);
                    getDiscussionData("Task/GetALLTaskDiscussion?taskid=" + inputDiscussion.taskid);
                    setCommentInput({ comments: '' });
                })
                .catch(error => {
                    // Handle error if API call fails
                    console.error("API Error:", error);
                });
        }

    }
    const handleEditorChange = (state) => {
        setEditorState(state);
    }

    const handleEditorComment = (state) => {
        setEditorCommentState(state);
    }

    const externalLink = (data) => {
        debugger
        window.open(data, '_blank')
    }



    function formatDate(dateString) {
        const dateObject = new Date(dateString);

        const day = String(dateObject.getDate()).padStart(2, '0');
        const month = String(dateObject.getMonth() + 1).padStart(2, '0');
        const year = dateObject.getFullYear();

        return `${day}-${month}-${year}`;
    }

    const backbutton = () => {
        props.navigate(-1);
    }



    return (
        <div>
            <div className="container-fluid">
                <form id='taskDetails' onSubmit={shared}>
                    <br />
                    <form onSubmit={submitButton}>
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <div className="row">
                                    <div className="col-sm-3"><h3>Tasks Details -  {props.id}</h3>
                                    </div>
                                    <div className="col-sm-6"></div>
                                    <div className="col-sm-3">
                                        <Link to="/AdminDashboard" className="btn btn-secondary mt-1 ml-2 btn-sm">Home</Link>
                                        <button className="btn btn-info mt-1 ml-1 btn-sm" onClick={() => backbutton()}>back</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">Task Info</h6>
                                {apiResponse && (
                                    <div className="alert alert-success" role="alert">
                                        Status has been Update successfully!
                                    </div>
                                )}
                                {apiError && (
                                    <div className="alert alert-danger" role="alert">
                                        {apiError}
                                    </div>
                                )}
                            </div>


                            <div className="card-body" style={{ height: "auto" }}>
                                <div className='row'>
                                    <div className='col-md-7'>
                                        <div className="form-group row">
                                            <div className='col-sm-12 required-label'>Task Headline</div>
                                            <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline"
                                                value={taskDetail.headline} readOnly name="headline" placeholder="Enter Task headline" required /></div>
                                        </div>
                                        <div className="form-group row">
                                            <div className='col-sm-12 required-label'>Description</div>
                                            <Editor
                                                readOnly
                                                editorState={editorState}
                                                wrapperClassName="demo-wrapper"
                                                editorClassName="demo-editor"
                                                onEditorStateChange={handleEditorChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-1'></div>
                                    <div className='col-md-4'>

                                        <div className="form-group form-group-h row">
                                            <div className='col-sm-12 required-label'>Status</div>
                                            <div className='col-sm-12'>
                                                {statusError && <div className="alert alert-danger mt-3" readOnlt={detailFlag} role="alert">Please select a status.</div>}
                                                <select className="form-control form-control-fs form-control-user" value={statusId} name="statusId" onChange={handleStatusChange} required>
                                                    <option>[NONE]</option>
                                                    {
                                                        status.map((e, key) => {
                                                            //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;
                                                            return <option key={e.statusId} value={e.statusId}>{e.name}</option>;
                                                        })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group form-group-h row">
                                            <div className='col-sm-12 required-label'>Department</div>
                                            <div className='col-sm-12'>

                                                <select className="form-control form-control-fs form-control-user" readOnly value="sales" name="departmentId" onChange={handleTaskChange} required>
                                                    <option>[NONE]</option>
                                                    {
                                                        departent.map((e, key) => {
                                                            //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;
                                                            return <option key={e.depId} value={e.depId}>{e.name}</option>;
                                                        })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group form-group-h row">
                                            <div className='col-sm-12 required-label'>Type</div>
                                            <div className='col-sm-12'>

                                                <select className="form-control form-control-fs form-control-user" name="typeId" readOnly value={taskDetail.typeId} required>
                                                    <option>[NONE]</option>
                                                    {
                                                        dropdownTaskType.map((e, key) => {
                                                            return <option key={e.id} value={e.id}>{e.taskname}</option>;
                                                        })}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group form-group-h row">
                                            <div className='col-sm-12 required-label'>Priority</div>
                                            <div className='col-sm-12'>

                                                <select className="form-control form-control-fs  form-control-user" readOnly value={taskDetail.priorityId} name="priorityId" onChange={handlePriorityChange} required>
                                                    <option>[NONE]</option>
                                                    {
                                                        taskPriority.map((e, key) => {
                                                            return <option key={e.priorityId} value={e.priorityId}>{e.name}</option>;
                                                        })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group form-group-h row">
                                            <div className='col-sm-12 required-label'>ShowList</div>
                                            <div className='col-sm-12'>
                                                <select className="form-control form-control-fs  form-control-user" readOnly name="showId" required>
                                                    <option>[NONE]</option>
                                                    {
                                                        showdata.map((e, key) => {
                                                            return <option key={e.showId} value={e.showId}>{e.chapterName}</option>;
                                                        })}
                                                </select>
                                            </div>
                                        </div>

                                        <h5>People</h5>

                                        <div className="form-group form-group-h row">
                                            <div className='col-sm-5'>Author - </div>
                                            <div className='col-sm-7'>
                                                {
                                                    taskDetail.taskassignuserdetails?.slice(0, 1).map((e) => (
                                                        <div>
                                                            <b>
                                                                {e.assignerName}
                                                            </b>
                                                        </div>
                                                    ))}
                                            </div>

                                        </div>
                                        <div className="form-group form-group-h row">
                                            <div className="col-sm-5 required-label">Assigned To</div>
                                            <div className="col-sm-7 ">
                                                {
                                                    taskDetail.taskassignuserdetails?.map((e) => (
                                                        <div>
                                                            <b>
                                                                {e.assigneeName}
                                                            </b>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                        <h5>Dates</h5>

                                        <div className="form-group form-group-h row">
                                            <div className='col-sm-5'>Last Update - </div>
                                            <div className='col-sm-7'>
                                                <b>{formatDate(taskDetail.date)} </b>
                                            </div>

                                        </div>

                                        <div className="form-group form-group-h row">
                                            <div className='col-sm-12 required-label'>Due Date / Finish date</div>
                                            <div className='col-sm-12'> <input type="text" className="form-control form-control-fs form-control-user" id="finishdate"
                                                readOnly value={formatDate(taskDetail.finishdate)} placeholder="Enter Task Topic" required />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className='col-sm-12 '>Content Link</div>
                                            <div className='col-sm-12'><textarea
                                                rows={2} // You can adjust the number of visible rows
                                                cols={100}
                                                value={contentLink}
                                                onChange={handleLinkChange}
                                                name='contentLink'
                                                placeholder='enter drive link' // You can adjust the number of visible columns
                                                class="form-control"
                                                autofocus
                                            /></div>
                                        </div>

                                        <div className="form-group row">
                                        <div className='btn btn-sm ml-3 btn-info' onClick={() => externalLink(contentLink)} >Open Drive Link</div>
                                    </div>

                                    </div>
                                </div>
                                <button className="btn btn-primary mr-1" type='submit'>Save</button>
                                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
                                    <i class="fa fa-share-alt mr-1"></i>share task
                                </button>
                                <button className="btn btn-primary mr-1 ml-1" type='submit'><i class="fa fa-check mr-1" aria-hidden="true"></i>Approve</button>
                                <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLongTitle">Share Task</h5>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <Select
                                                    name="assigneeid"
                                                    isMulti={true}
                                                    placeholder="Enter members to share with"
                                                    options={userName.map((user) => ({
                                                        value: user.id,
                                                        label: `${user.firstname} ${user.lastname}`,
                                                    }))}
                                                    value={selectedAssignees.value}
                                                    onChange={handleSelectChange}
                                                    onClick={() => setShowAssigneePopup(false)}
                                                />
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                                <button type='submit' class="btn btn-primary">share</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {showAssigneePopup && (
                                    <>
                                        <Select
                                            name="assigneeid"
                                            isMulti={true}
                                            placeholder="Enter members to share with"
                                            options={userName.map((user) => ({
                                                value: user.id,
                                                label: `${user.firstname} ${user.lastname}`,
                                            }))}
                                            value={selectedAssignees.value}
                                            onChange={handleSelectChange}
                                            onClick={() => setShowAssigneePopup(false)}
                                        />
                                        <button className="close-popup-btn" onClick={() => setShowAssigneePopup(false)}>
                                            Close popup
                                        </button>
                                    </>
                                )}
                            </div>

                        </div>
                    </form>
                </form>

                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col-sm-9">
                                <div className="col-sm-6"></div>
                                <button className="btn btn-primary mr-2" onClick={handleDiscussionButtonClick}>
                                    Discussion
                                </button>

                            </div>
                        </div>
                    </div>

                    <div className="card-header py-3">
                        {apiResponseComment && (
                            <div className="alert alert-success" role="alert">
                                Comment Done successfully..!!
                            </div>
                        )}
                        <div className="row d-flex flex-column">
                            <div class="card-body" style={{ display: "flex" }}>
                                <img class="rounded-circle shadow-1-strong me-3"
                                    src="https://w7.pngwing.com/pngs/184/113/png-transparent-user-profile-computer-icons-profile-heroes-black-silhouette-thumbnail.png" alt="avatar" width="45"
                                    height="45" />
                                <form onSubmit={submitDiscussion} className='card-body' style={{ display: "flex" }}>

                                    <div className='col-sm-11  '><input
                                        type="text"
                                        className="form-control w-100 form-control-user"
                                        id="comment"
                                        name="comments"
                                        placeholder="Enter Your Message"
                                        value={commentInput.comments}
                                        onChange={(e) => setCommentInput({ ...commentInput, comments: e.target.value })}
                                    />
                                    </div>

                                    <div className='col-sm-0'>
                                        <button type='submit' className="btn btn-primary mr-3">comment</button>
                                    </div>
                                </form>
                                <div className='col-sm-0'></div>
                            </div>
                            {comments.map((messageData) => (
                                <div class="card-body mt-1 card-body1">
                                    <div class="d-flex flex-start justify-content-between align-items-center">
                                        <div className='d-flex'>

                                            <img class="rounded-circle shadow-1-strong me-3"
                                                src="https://w7.pngwing.com/pngs/184/113/png-transparent-user-profile-computer-icons-profile-heroes-black-silhouette-thumbnail.png" alt="avatar" width="45"
                                                height="45" />
                                            <p class="mb-0 mt-2 pl-3 pb-1">
                                                {messageData.comments}
                                            </p>
                                        </div>
                                        <div class="small d-flex flex-row-reverse justify-content-start pr-5">
                                            <div className='d-flex'>
                                                <p class="text-muted font-weight-bold  ml-2 ">
                                                    Date-{formatDate(messageData.createdDate)}
                                                </p>
                                                <h6 class="font-weight-bold text-primary ml-2 ">{messageData.userName} </h6>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default SpecificTaskDetailComponent