import React from 'react' 
import { useNavigate, useParams } from 'react-router-dom';
import TicketDetailsComponent from '../../../Components/Main/Task/TicketDetailsComponent';

function TicketDetails(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <TicketDetailsComponent {...props} navigate={navigate}  />
        </div>
    );  
  }
export default TicketDetails  