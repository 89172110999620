import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import RFPMasterSheetComponent from '../../../Components/Main/DesignerMasterComponent/RFPMasterSheetComponent';


 function RFPMasterSheet() {
    const params = useParams();
    const navigate  = useNavigate()
  return (
    <div>
        <RFPMasterSheetComponent navigate={navigate}/>
    </div>
  )
}

export default RFPMasterSheet
