import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import ShareLeadComponent from '../../../Components/Main/LeadComponent/ShareLeadComponent';


function ShareLead(props) {
    let navigate = useNavigate();
    const params = useParams();
    return (  
        <div> 
            <ShareLeadComponent {...props} navigate={navigate}   />
        </div>
    );  
  }
export default ShareLead  