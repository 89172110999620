import React, { useEffect, useState } from 'react'
import Service from '../../../Services/Admin/Service';

const TestingComponent = () => {

    const [attachmentData, setAttachmentData] = useState({});
    const MIME_TYPES = {
        pdf: 'application/pdf',
        xls: 'application/vnd.ms-excel',
        xlsx: 'application/vnd.ms-excel',
        ppt: 'application/vnd.ms-powerpoint',
        pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    };
    const getMimeType = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    return MIME_TYPES[extension] || 'application/octet-stream';
};
   
    const handleDownload = () => {
        debugger
        const fileName = 'Soul Stories Food Menu.pdf';
        
        // Change to the name of the file you want to download
        Service.downloadFile("FileUpload/download/", fileName)
            .then(res => {
                const mimeType = getMimeType(fileName);
                const url = window.URL.createObjectURL(new Blob([res.data], { type: res.data.type },fileName));
              window.open(url,'_blank');
                
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                // link.remove();
            })
            .catch(error => {
                // Handle API error, if necessary
                console.error("API Error:", error);

            });
    };

    return (
        <div>
            <button onClick={handleDownload}>Download File</button>
        </div>
    );
}
export default TestingComponent