import React, { useEffect, useState } from 'react'
import Service from '../../../Services/Admin/Service';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { formatDate, getLoginUserData } from '../../../Helpers/supportedFunctions/supportedGlobalFunctions';

const ShareLeadComponent = (props) => {
    let DeptId = sessionStorage.getItem('DeptId');

    let id = sessionStorage.getItem('id');
    const [RFPError, setRFPErrror] = useState(false);
    const [apiError, setApiError] = useState(null);
    const [assigneeError, setAssigneeError] = useState(false);
    const [dropdownUser, setdropdownUser] = useState([]);
    const [selectedAssignees, setSelectedAssignees] = useState([]);
    const [apiResponse, setApiResponse] = useState(null);
    const [exhibitorData, setExhibitordata] = useState([]);
    const [showData, setShowdata] = useState([]);
    const [selectedShow, setSelectedShow] = useState("");
    const [inputField, setInputField] = useState({

        showid: '',
        exhibitorid: '',
        startDate: '',
        endDate: '',
        history: "",
        phone: "",
        directLead: false,
        email: "",
        position: "",
        companyName: "",
        venue: "",
        background: "",
        isConvincing: false,
        comments: "",
        createdby: id,
        // updatedby: id,

    }); // State to store the selected show ID

    const [questionare,setQuestionare] = useState({
        
            leadId: 0,
            description: "Assigned Lead To You",
            senderid: getLoginUserData().userId,
            senderName: getLoginUserData().name,
            receiverid: 0,
            receiverName: "string",
            createdby: getLoginUserData().userId,
          
    })

    useEffect(() => {
        getUserData("Users/GetAll");
        getShowData("Shows/GetAllShows");

    }, []);

    const getShowData = (method) => {
        Service.getdata(method).then((res) => {
            setShowdata(res.data);
            
        });
    }

    const handleSelectChange = (selectedOptions) => {
        setSelectedAssignees(selectedOptions);
    };

    const getUserData = (method) => {
        Service.getdata(method).then((res) => {
            var data = res.data;
            
            var intdeptId = parseInt(DeptId)
            var list = [];

            data.forEach((d, index) => {
                var role = d.lstRole;

                var checkdepartment = role.find(x => x.depId === 2)
                if (checkdepartment !== undefined) {
                    d.rolename = d.lstRole[0].name;
                    list.push(d);
                }
                setdropdownUser(list);
            });
            
        });
    }

    const getShowDetailsById = (method, id) => {
        Service.getdatabyId(method, id).then((res) => {
            console.log(res.data);
            
            setInputField((prevState) => ({
                ...prevState,
                ["startDate"]: formatDate(res.data.startDate),
                ["endDate"]: formatDate(res.data.endDate),
                ["exhibitionName"]: res.data.exhibitorName,
                ["venue"]: res.data.venue,
            }));

            
        });
    }



    const handleShowChange = (evt) => {
        const selectedShowId = evt.target.value;
        setSelectedShow(selectedShowId); // Update the selected show ID

        // Fetch exhibitor data based on the selected show
        getExhibitorData(`Shows/GetAllExhibitorbyId?id=${selectedShowId}`);
        

        var showid = showData.filter(x => x.showChapterId === evt.target.value);
        // if (showid.length !== 0) {
        getShowDetailsById("Shows/GetALLShowsChapterId?id=", evt.target.value);

        // }
    }

    useEffect(() => {
        // Fetch exhibitor data based on the selected show when selectedShow changes
        if (selectedShow) {

            getExhibitorData(`Shows/GetAllExhibitorbyId?id=${selectedShow}`);
        }
    }, [selectedShow]); // Add selectedShow as a dependency to trigger the effect when it changes

    const getExhibitorData = (method) => {
        Service.getdata(method).then((res) => {
            setExhibitordata(res.data);
        });
    }

    useEffect(() => {
        if (apiResponse || apiError || RFPError) {
            const timeoutId = setTimeout(removeSuccessMessage, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [apiResponse, apiError, RFPError]);

    const removeSuccessMessage = () => {
        setApiResponse(null);
        setApiError(null);
        setRFPErrror(null)
    };

    const createUser = async (evt) => {
        if (evt && evt.preventDefault) {
            evt.preventDefault();
        }

        const formData = new FormData(evt.target);

        const data = {}
        for (const [key, value] of formData) {
            data[key] = value;
        }

        const { exhibitorid, salesHeadId } = data;
        function validateData(data) {


            if (!exhibitorid || exhibitorid === "[NONE]") {
                setRFPErrror(true);
                return false;
            } else {
                setRFPErrror(false);
            }

            if (!salesHeadId || salesHeadId === "[NONE]") {
                setAssigneeError(true);
                return false;
            } else {
                setAssigneeError(false);
            }
            return true
        }

        if (!validateData(data)) {
            return;
        }

        const isConvincingValue = data['isConvincing'] === 'true';
        const task = {
            ...inputField,
            ...data,
            isConvincing: isConvincingValue,
           
        };
        console.log(task);
        debugger

        var recieverName  = dropdownUser.filter((x)=>x.id === parseInt(salesHeadId))
        
        // const questionare1={
        //     ...questionare,
        //     receiverid:salesHeadId,
        //     receiverName: recieverName[0].firstname,
        // }
        // console.log(questionare1);
        // debugger
        if (task) {
            Service.createorupdate("Lead/CreateOrUpdate", task)
                .then(res => {
                    setApiResponse(res.data);
                    
                    window.location.href = "/SharedLeadList";

                })
                .catch(error => {
                    // Handle API error, if necessary
                    console.error("API Error:", error);
                    setApiError("Error occurred while saving lead. Please try again later.");
                });
        }
    }

    const backbutton = () => {
        props.navigate(-1);
    }
    return (
        <div>
            <div className="container-fluid">
                <br />
                <div className="card shadow border-success mb-2">
                    <div className="card-header rounded py-3">
                        <div className="row">
                            <div className="col-sm-3 "><h3><b className='text-dark '>Create Lead</b></h3></div>
                            <div className="col-sm-5"></div>
                            <div className="col-sm-4 d-flex flex-row-reverse">
                                <button className="btn btn-info  ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link> */}
                            </div>

                        </div>
                    </div>
                </div>
                <form onSubmit={createUser}>

                    <div className="card  shadow " >
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-success">Add Lead</h6>

                        </div>



                        <div className="card shadow">
                            <div className="card-body" style={{ height: "auto" }}>
                                <div className="row text-dark">
                                    <div className="col-md-5 ml-5">
                                        <div className="form-group row row">
                                            <div className='col-sm-3 required-label'><b>Show Name </b></div>
                                            <div className='col-sm-9'>
                                                {RFPError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a show and exhibitor.</div>}

                                                <select className="form-control form-control-fs  form-control-user" name="showid" onChange={handleShowChange} required>
                                                    <option>[NONE]</option>
                                                    {
                                                        showData.map((e, key) => {
                                                            return <option key={e.showChapterId} value={e.showChapterId}>{e.chapterName}</option>;
                                                        })}
                                                </select>
                                            </div>
                                        </div>

                                        <h5 className='ml-2'>POC</h5>
                                        {/* contact is history in backend */}

                                        <div class="form-group row row">
                                            <label for="objective" class="col-sm-3 required-label"><b>Contact Name</b></label>
                                            <div class="col-sm-9">
                                                <input type="text" className="form-control form-control-user" id="CompanyName"
                                                    name="history" placeholder="enter Contact Name" required/>
                                            </div>
                                        </div>

                                        <div class="form-group row row">
                                            <label for="objective" class="col-sm-3 required-label"><b>Company Name </b></label>
                                            <div class="col-sm-9">
                                                <input type="text" className="form-control form-control-user" id="CompanyName"
                                                    name="companyName" placeholder="enter Company Name" required/>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label for="objective" class="col-sm-3 required-label"><b>Email</b> </label>
                                            <div class="col-sm-9">
                                                <input type="text" className="form-control form-control-user" id="CompanyName"
                                                    name="email" placeholder="enter Email" required/>
                                            </div>
                                        </div>

                                        {/* brief synopsis is background in backend  */}

                                        <div class="form-group row">
                                            <label for="objective" class="col-sm-3 control-label"><b>Brief Synopsis </b></label>
                                            <div class="col-sm-9">
                                                <textarea
                                                    rows={2} // You can adjust the number of visible rows
                                                    cols={105}
                                                    name='background'
                                                    placeholder='enter Brief Synopsis' // You can adjust the number of visible columns
                                                    class="form-control"
                                                    autofocus
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-sm-3 required-label"><b>Account Manager</b></div>
                                            <div className="col-sm-9">
                                                {assigneeError && <div className="alert alert-danger  mt-3" role="alert">Please select a assignee</div>}
                                                <Select
                                                    name="salesHeadId"
                                                    isMulti={true}
                                                    placeholder="Enter Account Manager "
                                                    options={dropdownUser.map((user) => ({
                                                        value: user.id,
                                                        label: `${user.firstname} ${user.lastname} - ${user.rolename}`,
                                                    }))}
                                                    value={selectedAssignees}
                                                    onChange={handleSelectChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-1"></div>

                                    <div className="col-md-5">

                                        <div className="form-group row">
                                            <div className='col-sm-4 required-label'><b>Exhibitor Name</b></div>
                                            <div className='col-sm-8'>
                                                <select className="form-control form-control-fs  form-control-user" name="exhibitorid" required>
                                                    <option>[NONE]</option>
                                                    {
                                                        exhibitorData.map((e, key) => {
                                                            return <option key={e.exId} value={e.exId}>{e.exhibitorName}</option>;
                                                        })}
                                                </select>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label for="objective" class="col-sm-4 control-label required-label"><b>Venue/Place </b></label>
                                            <div class="col-sm-8">
                                                <textarea
                                                    rows={1} // You can adjust the number of visible rows
                                                    cols={110}
                                                    name='venue'
                                                    placeholder='enter Venue ' // You can adjust the number of visible columns
                                                    class="form-control"
                                                    autofocus
                                                    required
                                                />
                                            </div>
                                        </div>


                                        <div class="form-group row">
                                            <label for="objective" class="col-sm-3 required-label"><b>Phone</b> </label>
                                            <div class="col-sm-9">
                                                <input type="text" className="form-control form-control-user" id="CompanyName"
                                                    name="phone" placeholder="enter Phone" required/>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label for="objective" class="col-sm-3 required-label"><b>Position</b> </label>
                                            <div class="col-sm-9">
                                                <input type="text" className="form-control form-control-user" id="CompanyName"
                                                    name="position" placeholder="enter Position" required/>
                                            </div>
                                        </div>


                                        <div class="form-group row">
                                            <label for="objective" class="col-sm-3  control-label"><b>Additional Inputs </b></label>
                                            <div class="col-sm-9">
                                                <textarea
                                                    rows={2} // You can adjust the number of visible rows
                                                    cols={105}
                                                    name='comments'
                                                    placeholder='enter Additional Inputs' // You can adjust the number of visible columns
                                                    class="form-control"
                                                    autofocus
                                                />
                                            </div>
                                        </div>


                                        <div class="form-group row" hidden={true}>
                                            <div class="col-sm-9">
                                                <div class="form-check">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        value="false"
                                                        name='isConvincing'
                                                        id="flexCheckChecked"
                                                    />
                                                    <label class="form-check-label" for="flexCheckChecked">
                                                        <b>Is Convincing</b>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group row" hidden={true}>

                                            {apiResponse && (
                                                <div className="alert alert-success" role="alert">
                                                    Lead has been created successfully!
                                                </div>
                                            )}
                                            {apiError && (
                                                <div className="alert alert-danger" role="alert">
                                                    {apiError}
                                                </div>
                                            )}
                                        </div>

                                        <button type="submit" class="btn btn-primary ml-2 ">Save</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ShareLeadComponent