import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import Service from "../../../Services/Admin/Service";
import React, { useState, useEffect } from 'react'
const ApprovalListComponent = (props) => {
  const [data, setData] = useState([]);
  // const apis = ApiMethod.find(o => o.name === 'Category');

  useEffect(() => {
    getCategoryData("Project/GetAll");
  }, [])

  const getCategoryData = (method) => {
    Service.getdata(method).then((res) => {
      setData(res.data);
    });
  }

  const backbutton = () => {
    props.navigate(-1);
  }

  const formatDateTime = (cell, row) => {
    // Format the date and time here, assuming 'time' is a string in ISO 8601 format
    const formattedDate = new Date(cell).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
    return formattedDate;
  }



  const linkformatter = (data, row) => {
    debugger
    return <>
      <Link className="btn btn-info btn-sm" to={`/ShowApprovalList/${data}&${row.projectname}`}>Show Approval  </Link>

      <Link className="btn btn-info m-1 btn-sm" to={`/SupplierApprovalList/${data}&${row.projectname}`}>Supplier Approval  </Link>
      <Link className="btn btn-info m-1 btn-sm" to={`/ClientApprovalList/${data}&${row.projectname}`}>Client Approval  </Link>
    </>
  }



  const column = [
    { dataField: 'projectname', text: 'Project Name', sort: true },
    { dataField: 'id', text: 'List', formatter: linkformatter }
  ]
  return (
    <div>
      <div className="container-fluid">
        <div className="card shadow border-success mb-2 mt-2">
          <div className="card-header rounded py-3">
            <div className="row">
              <div className="col-sm-5"><h3> Approval List Projectwise</h3></div>

              <div className="col-sm-4"></div>
              <div className="col-sm-3 d-flex flex-row-reverse">
                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
              </div>
            </div>
          </div>
        </div>
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-success">Material List</h6>
            {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
          </div>

          <div className="card-body">
            <div className="table-responsive">
              <BootstrapTable keyField="id"
                data={data}
                columns={column}
                rowStyle={{ color: "black", fontWeight: 700 }}
                pagination={paginationFactory()}
              // filter={filterFactory()}
              />
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default ApprovalListComponent