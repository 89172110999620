import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import ProjectListComponent from '../../../Components/Main/ProjectComponent/ProjectListComponent';

function ProjectList(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <ProjectListComponent {...props} navigate={navigate} name={params.name} />
        </div>
    );  
  }
export default  ProjectList  