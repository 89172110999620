import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import TextareaAutosize from 'react-textarea-autosize';
import Service from "../../../Services/Admin/Service";
import { Link } from 'react-router-dom';
const EditRFPDetailsComponent = (props) => {
    const id = props.id;
    const userId = sessionStorage.getItem("id");
    const [apiError, setApiError] = useState(null);
    const [apiResponse, setApiResponse] = useState(null);
    const [RFPDataDetail, setRFPDataDetail] = useState([]);
    const [leadData, setLeadData] = useState([]);
    const [exhibitorName, setExhibitorName] = useState('');
    const [venue, setVenue] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [contentLink, setContentLink] = useState('');
    const [finalLink, setFinalLink] = useState('');
    const [dateFrom, setDateFrom] = useState('');
    const [designDeadlineDate, setDesignDeadline] = useState('');
    const [floorplan, setFloorPlan] = useState('');
    const [guidelines, setGuidelines] = useState('');
    const [stallNo, setStallNo] = useState('');
    const [hallNo, setHallNo] = useState('');
    const [budget, setBudget] = useState('');
    const [isCompleted, setIsCompleted] = useState('');
    const [contactName, setContactName] = useState('');
    const [fax, setFax] = useState('');
    const [email, setEmail] = useState('');
    const [position, setPosition] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [phone, setPhone] = useState('');
    const [billingAddress, setBillingAddress] = useState('');
    const [anySpecificDetail, setAnySpecificDetail] = useState('');
    const [website, setWebsite] = useState('');
    const [description, setDescription] = useState('');
    const [leadId, setLeadId] = useState('');
    const [noofProduct, setNoofProduct] = useState('');
    const [designRefrence, setDesignRefrence] = useState('');
    const [yourpurpose, setYourPurpose] = useState('');
    const [brandguidelines, setBrandGuidelines] = useState('');
    const [dos, setDos] = useState('');
    const [dont, setDont] = useState('');

    useEffect(() => {
        getRFPInfo("RFP/GetById?id=" + id)
        getLeadData("Lead/GetAll?userId=" + userId);


    }, []);

    const getRFPInfo = (method) => {
        Service.getdata(method).then((res) => {
            setRFPDataDetail(res.data);
            console.log(res.data)
            debugger
            setExhibitorName(res.data.exhibitionName);
            setVenue(res.data.venue);
            setDateTo(res.data.dateTo);
            setDateFrom(res.data.dateFrom);
            setDesignDeadline(res.data.deadlineDate);
            setFloorPlan(res.data.floorplan);
            setGuidelines(res.data.guidelines);
            setStallNo(res.data.stallNo);
            setHallNo(res.data.hallNo);
            setBudget(res.data.budget);
            setIsCompleted(res.data.IsCompleted);
            setContentLink(res.data.contentLink);
            setFinalLink(res.data.finalLink);
            debugger
            setContactName(res.data.contactName);
            setFax(res.data.fax);
            setEmail(res.data.email);
            setPosition(res.data.position);
            setCompanyName(res.data.companyName);
            setPhone(res.data.phone);
            setBillingAddress(res.data.billingAddress);
            setAnySpecificDetail(res.data.anySpecific);
            setWebsite(res.data.website);
            setDescription(res.data.description);
            setNoofProduct(res.data.noofProduct);
            setDesignRefrence(res.data.designRefrence);
            setYourPurpose(res.data.yourpurpose);
            setBrandGuidelines(res.data.brandguidelines);
            setDos(res.data.do);
            setDont(res.data.dont);
            setLeadId(res.data.leadid)

        });
    }


    const getLeadData = (method) => {
        Service.getdata(method).then((res) => {
            setLeadData(res.data);
        })
    }

    useEffect(() => {
        if (apiResponse || apiError) {
            const timeoutId = setTimeout(removeSuccessMessage, 7000);
            window.location.href = "/RFPList";
            return () => clearTimeout(timeoutId);
        }
    }, [apiResponse, apiError]);

    const removeSuccessMessage = () => {
        setApiResponse(null);
        setApiError(null);
    };

    const handleExhibitorNameChange = (evt) => {
        setExhibitorName(evt.target.value);
    }

    const handleVenueChange = (evt) => {
        setVenue(evt.target.value);
    }
    const handleStartDateChange = (evt) => {
        setDateTo(evt.target.value);
    }
    const handleEndDateChange = (evt) => {
        setDateFrom(evt.target.value);
    }
    const handleDesignDeadlineChange = (evt) => {
        setDesignDeadline(evt.target.value);
    }
    const handleFloorPlanChange = (evt) => {
        setFloorPlan(evt.target.value);
    }
    const handleGuidelineChange = (evt) => {
        setGuidelines(evt.target.value);
    }
    const handleStallNoChange = (evt) => {
        setStallNo(evt.target.value);
    }
    const handleHallNoChange = (evt) => {
        setHallNo(evt.target.value);
    }
    const handleBudgetChange = (evt) => {
        setBudget(evt.target.value);
    }
    const handleContactNameChange = (evt) => {
        setContactName(evt.target.value);
    }

    const handleIsCompleted = (evt) => {
        setIsCompleted(evt.target.value);
        debugger
    }
    const handleFaxChange = (evt) => {
        setFax(evt.target.value);
    }
    const handleEmailChange = (evt) => {
        setEmail(evt.target.value);
    }
    const handlePositionChange = (evt) => {
        setPosition(evt.target.value);
    }
    const handleCompanyNameChange = (evt) => {
        setCompanyName(evt.target.value);
    }
    const handlePhoneChange = (evt) => {
        setPhone(evt.target.value);
    }
    const handleBillingAddressChange = (evt) => {
        setBillingAddress(evt.target.value);
    }
    const handleAnySpecificChange = (evt) => {
        setAnySpecificDetail(evt.target.value);
    }
    const handleWebsiteChange = (evt) => {
        setWebsite(evt.target.value);
    }
    const handleDescriptionChange = (evt) => {
        setDescription(evt.target.value);
    }
    const handleNoofProductChange = (evt) => {
        setNoofProduct(evt.target.value);
    }
    const handleDesignRefrenceChange = (evt) => {
        setDesignRefrence(evt.target.value);
    }
    const handleYourPurposeChange = (evt) => {
        setYourPurpose(evt.target.value);
    }
    const handleDosChange = (evt) => {
        setDos(evt.target.value);
    }
    const handleDontsChange = (evt) => {
        setDont(evt.target.value);
    }
    const handleContentLinkChange = (evt) => {
        setContentLink(evt.target.value);
    }
    const handleFinalLinkChange = (evt) => {
        setFinalLink(evt.target.value);
    }
    const handleBrandGuidelinesChange = (evt) => {
        setBrandGuidelines(evt.target.value);
    }

    const handleLeadChange = (evt) => {
        setLeadId(evt.target.value);
    }

    const externalLink = (data) => {
        debugger
        window.open(data, '_blank')
    }

    const save = (evt) => {
        if (evt.preventDefault && evt) {
            evt.preventDefault();
        }
        const formData = new FormData(evt.target);

        const data = {};
        for (const [key, value] of formData) {
            data[key] = value;
        }

        const {
            name,
            exhibitionName,
            venue, dateTo,
            dateFrom,
            deadlineDate,
            floorplan,
            guidelines,
            stallNo,
            hallNo,
            budget,
            contactName,
            fax,
            email,
            position,
            companyName,
            phone,
            billingAddress,
            anySpecific, website,
            description,
            noofProduct,
            designRefrence,
            yourpurpose,
            brandguidelines,
            dont,

        } = data;


        if (data.isCompleted === "on" ) {

            data.isCompleted = true;
          }
        const task = {
            id: id,
            createdby: userId,
            updatedby: userId,
            ...data,
        };
        console.log(task)
        debugger
        if (task) {
            Service.createorupdate("RFP/CreateOrUpdate", task)
                .then(res => {
                    setApiResponse(res.data); // Set the API response in state
                })
                .catch(error => {
                    // Handle error if API call fails
                    console.error("API Error:", error);
                    setApiError("Error occurred while saving the meeting. Please try again later.");
                });
        }

    }

    const backbutton = () => {
        props.navigate(-1);
    }


    // function formatDate(dateString) {
    //     const dateObject = new Date(dateString);

    //     const day = String(dateObject.getDate()).padStart(2, '0');
    //     const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    //     const year = dateObject.getFullYear();

    //     return `${day}-${month}-${year}`;
    // }

    function formatDate(date) {
        if (!date) return ""; // Handle null or undefined dates
        const localDate = new Date(date);
        const year = localDate.getFullYear();
        const month = String(localDate.getMonth() + 1).padStart(2, '0');
        const day = String(localDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    return (
        <div>
            <div className="container-fluid">

                <br />
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col-sm-3 mt-1"><h3>Edit RFP Details</h3></div>
                            <div className="col-sm-5"></div>
                            <div className="col-sm-4 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <form onSubmit={save}>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">RFP Details</h6>
                            {apiResponse && (
                                <div className="alert alert-success mt-2" role="alert">
                                    RFP has been edited successfully!
                                </div>
                            )}
                            {apiError && (
                                <div className="alert alert-danger" role="alert">
                                    {apiError}
                                </div>
                            )}
                        </div>

                        <div className="card-body" style={{ height: "auto" }}>
                            <div className='row'>
                                <div className='col-md-5 ml-5'>
                                    <div className="form-group row">
                                        <div className='col-sm-12 required-label'><b>Lead</b></div>
                                        <div className='col-sm-12'>
                                            <select className="form-control form-control-user" value={leadId} onChange={handleLeadChange} name="leadid" required>
                                                <option>[NONE]</option>
                                                {
                                                    leadData.map((e, key) => {
                                                        //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;
                                                        return <option key={e.id} value={e.leadid}>{e.leadid}</option>;
                                                    })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Exhibhition Name</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline"
                                            name="exhibitionName"  value={exhibitorName} onChange={handleExhibitorNameChange} /></div>
                                    </div>
                                    <div className="form-group row d-flex">

                                        <div className='col-sm-3 mt-1 required-label'><b>Show Start Date</b></div>
                                        <input type="date" className="form-control col-sm-3 mb-1 form-control-user" id="headline"
                                            name="dateFrom"  onChange={handleEndDateChange} value={formatDate(dateFrom)} />
                                        <div className='col-sm-3 mt-1 required-label '><b>Show End Date</b></div>
                                        <input type="date" className="form-control col-sm-3 mb-1 form-control-user" id="headline"
                                            name="dateTo"  onChange={handleStartDateChange} value={formatDate(dateTo)} />
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Design Deadline</b></div>
                                        <div className='col-sm-12'><input type="date" className="form-control form-control-user" id="headline"
                                            name="deadlineDate"  onChange={handleDesignDeadlineChange} value={formatDate(designDeadlineDate)} /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Floor Plan</b></div>
                                        <div className='col-sm-12'><textarea
                                            rows={1} // You can adjust the number of visible rows
                                            cols={100}
                                            value={floorplan}
                                            onChange={handleFloorPlanChange}
                                            name='floorplan'
                                           // You can adjust the number of visible columns
                                            class="form-control"
                                            autofocus
                                        /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='btn btn-sm ml-3 btn-info' onClick={() => externalLink(floorplan)} >Open floorPlan Link</div>
                                    </div>
                                    <h5>Contact Info</h5>
                                    <div className="form-group row">
                                        <div className='col-sm-12 -label'><b>Contact Name</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline"
                                            name="contactName"  onChange={handleContactNameChange} value={contactName} /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 -label'><b>Position</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline"
                                            name="position"  onChange={handlePositionChange} value={position} /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 -label'><b>Company Name</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline"
                                            name="companyName" onChange={handleCompanyNameChange} value={companyName} /></div>
                                    </div>

                                    <div className="form-group row">
                                        <div className='col-sm-12 -label'><b>Phone</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline"
                                            name="phone" onChange={handlePhoneChange} value={phone} /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 -label'><b>Web Address</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline"
                                            name="website" onChange={handleWebsiteChange} value={website} /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='btn btn-sm ml-3 btn-info' onClick={() => externalLink(website)} >Open Website Link</div>
                                    </div>
                                    <h5>Product display</h5>
                                    <div className="form-group row">
                                        <div className='col-sm-12 -label'><b>Number of Products to Display If Any</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline"
                                            name="noofProduct" onChange={handleNoofProductChange} value={noofProduct} /></div>
                                    </div>
                                    <h6>Note : We need product LXWXH with drawing and photo if possible., you can attached product photo and drawing seperately</h6>

                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Your Purpose</b></div>
                                        <div className='col-sm-12'><textarea
                                            rows={3} // You can adjust the number of visible rows
                                            cols={100}
                                            name='yourpurpose'
                                            value={yourpurpose}
                                            onChange={handleYourPurposeChange}
                                            placeholder='Please elaborate your purpose by providing examples of stalls you like will help us understand your taste better and also the style you are looking for.' // You can adjust the number of visible columns
                                            class="form-control"
                                            autofocus
                                        /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12'><b>Do's</b></div>
                                        <div className='col-sm-12'><textarea
                                            rows={3} // You can adjust the number of visible rows
                                            cols={100}
                                            name='do'
                                            value={dos}
                                            onChange={handleDosChange}
                                           // You can adjust the number of visible columns
                                            class="form-control"
                                            autofocus
                                        /></div>
                                    </div>

                                    <div className="form-group row">
                                        <div className='col-sm-12'><b>Dont's</b></div>
                                        <div className='col-sm-12'><textarea
                                            rows={3} // You can adjust the number of visible rows
                                            cols={100}
                                            name='dont'
                                            value={dont}
                                            onChange={handleDontsChange}
                                           // You can adjust the number of visible columns
                                            class="form-control"
                                            autofocus
                                        /></div>
                                    </div>

                                    <div className="form-group row">
                                        <div className='col-sm-12'><b>Final Design Link</b></div>
                                        <div className='col-sm-12'><TextareaAutosize
                                            rows={3} // You can adjust the number of visible rows
                                            cols={100}
                                            onChange={handleFinalLinkChange}
                                            value={finalLink}
                                            name='finalLink'
                                           // You can adjust the number of visible columns
                                            class="form-control size1"
                                            autofocus
                                        /></div>
                                    </div>

                                    <div className="form-group row">
                                        <div className='btn btn-sm ml-3 btn-info' onClick={() => externalLink(finalLink)} >Open Final Design Link</div>
                                    </div>

                                </div>
                                <div className='col-md-1'></div>
                                <div className='col-md-5'>
                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Venue/Place/City</b></div>
                                        <div className='col-sm-12'><textarea
                                            rows={1} // You can adjust the number of visible rows
                                            cols={100}
                                            name='venue'
                                            value={venue}
                                            onChange={handleVenueChange}
                                            // You can adjust the number of visible columns
                                            class="form-control"
                                            autofocus
                                        /></div>
                                    </div>

                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Exhibhition Guidelines</b></div>
                                        <div className='col-sm-12'><textarea
                                            rows={3} // You can adjust the number of visible rows
                                            cols={100}
                                            name='guidelines'
                                            value={guidelines}
                                            onChange={handleGuidelineChange}
                                            // You can adjust the number of visible columns
                                            class="form-control"
                                            autofocus
                                        /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='btn btn-sm ml-3 btn-info' onClick={() => externalLink(guidelines)} ><b>Exhibition Guidelines</b></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Stall No</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline"
                                            name="stallNo" onChange={handleStallNoChange} value={stallNo} /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Hall No.</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline"
                                            name="hallNo" onChange={handleHallNoChange} value={hallNo} /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 -label'><b>Budget</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline"
                                            name="budget"  onChange={handleBudgetChange} value={budget} /></div>
                                    </div>
                                    <hr />

                                    {/* <div className="form-group row">
                                        <div className='col-sm-12 -label'>Fax</div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline"
                                            name="fax"  onChange={handleFaxChange} value={fax} /></div>
                                    </div> */}
                                    <div className="form-group row" hidden={true}>
                                        <div className='col-sm-12 -label '><b>Email</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline"
                                            name="email"  onChange={handleEmailChange} value={email} /></div>
                                    </div>
                                    <div className="form-group row" hidden={true}>
                                        <div className='col-sm-12 -label'><b>Billing Address</b></div>
                                        <div className='col-sm-12'><textarea
                                            rows={2} // You can adjust the number of visible rows
                                            cols={100}
                                            name='billingAddress'
                                            value={billingAddress}
                                            onChange={handleBillingAddressChange}
                                            // You can adjust the number of visible columns
                                            class="form-control"
                                            autofocus
                                        /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Any Specific Need</b></div>
                                        <div className='col-sm-12'><textarea
                                            rows={2} // You can adjust the number of visible rows
                                            cols={100}
                                            name='anySpecific'
                                            value={anySpecificDetail}
                                            onChange={handleAnySpecificChange}
                                            // You can adjust the number of visible columns
                                            class="form-control"
                                            autofocus
                                        /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 -label'><b>Description If Any</b></div>
                                        <div className='col-sm-12'><textarea
                                            rows={2} // You can adjust the number of visible rows
                                            cols={100}
                                            name='description'
                                            value={description}
                                            onChange={handleDescriptionChange}
                                            // You can adjust the number of visible columns
                                            class="form-control"
                                            autofocus
                                        /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Design Refrence</b></div>
                                        <div className='col-sm-12'><textarea
                                            rows={3} // You can adjust the number of visible rows
                                            cols={100}
                                            name='designRefrence'
                                            value={designRefrence}
                                            onChange={handleDesignRefrenceChange}
                                            // You can adjust the number of visible columns
                                            class="form-control"
                                            autofocus
                                        /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Brand Guidelines</b></div>
                                        <div className='col-sm-12'><textarea
                                            rows={3} // You can adjust the number of visible rows
                                            cols={100}
                                            name='brandguidelines'
                                            value={brandguidelines}
                                            onChange={handleBrandGuidelinesChange}
                                            // You can adjust the number of visible columns
                                            class="form-control"
                                            autofocus
                                        /></div>
                                    </div>



                                    <div className="form-group row">
                                        <div className='col-sm-12'><b>Additional Attachments Link</b></div>
                                        <div className='col-sm-12'><TextareaAutosize
                                            rows={3} // You can adjust the number of visible rows
                                            cols={100}
                                            onChange={handleContentLinkChange}
                                            value={contentLink}
                                            name='contentLink'
                                             // You can adjust the number of visible columns
                                            class="form-control size1"
                                            autofocus
                                        /></div>
                                    </div>

                                    


                                    <div className="form-group row">
                                        <div className='btn btn-sm ml-3 btn-info' onClick={() => externalLink(contentLink)} >Open Additional Link</div>
                                    </div>

                                    


                                    <div class="form-group ">
                                        <input class="" type="checkbox" checked={isCompleted} onChange={handleIsCompleted} name='isCompleted' id="agreementCheckbox" />
                                        <label class="col-sm-5 required-label  control-label" for="flexCheckDefault">
                                            Is RFP Completed
                                        </label>
                                        <b>{isCompleted}</b>
                                    </div>
                                </div>
                            </div>
                            {apiResponse && (
                                <div className="alert alert-success mt-2" role="alert">
                                    RFP has been updated successfully!
                                </div>
                            )}
                            {apiError && (
                                <div className="alert alert-danger" role="alert">
                                    {apiError}
                                </div>
                            )}
                            <button className="btn btn-primary text-uppercase ml-5 mt-2" type='submit'>Save</button>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default EditRFPDetailsComponent