import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import Service from '../../../Services/Admin/Service';
import { formatDate, getCurrentDate, getLoginUserData } from '../../../Helpers/supportedFunctions/supportedGlobalFunctions';

const CreateRFPNewComponent = (props) => {


    // state varrible start
    // const data = getLoginUserData()
    const [loading, setLoading] = useState(false); // New state for loading
    const [dropdownUser, setdropdownUser] = useState([]);
    const [accountManager, setAccountManager] = useState([]);
    const [apiError, setApiError] = useState(null);
    const [apiResponse, setApiResponse] = useState(null);
    const [selectedAssignees, setSelectedAssignees] = useState([]);
    const [selectedRFPAssignees, setSelectedRFPAssignees] = useState([]);
    const [leadData, setLeadData] = useState([]);
    const [leadDataPostSave, setLeadDataPostSave] = useState([]);
    const [isConvincingDetail, setIsConvincingDetail] = useState(false);
    const [leadId, setLeadId] = useState(0);
    const [newLeadId, setNewLeadId] = useState(0);
    const [salesRfp, setSalesRfp] = useState(false);
    const [view1, setView1] = useState(false);

    const [leadInputField, setLeadInputfield] = useState({

        showid: null,
        exhibitorid: null,
        exhibitorName: '',
        createRFP: false,
        startDate: '',
        endDate: '',
        history: "",
        phone: "",
        email: "",
        position: "",
        companyName: "",
        venue: "",
        background: "",
        comments: "",
        createdby: getLoginUserData().userId,
        updatedby: getLoginUserData().userId,

    })

    const [rfpInputField, setRfpInputField] = useState({
        name: "",
        leadid: "",
        IsconvertProject: false,
        exhibitionName: "",
        venue: "",
        dateTo: "",
        dateFrom: "",
        deadlineDate: "",
        floorplan: "",
        floorplanAttachment: null,
        exhibitionAttachment: null,
        brandAttachemnt: null,
        briefLinkAttachment: null,
        guidelines: "",
        stallNo: "",
        hallNo: "",
        budget: "",
        contactName: "",
        email: "",
        position: "",
        companyName: "",
        phone: "",
        billingAddress: "",
        anySpecific: "",
        website: "",
        description: "",
        noofProduct: "",
        designRefrence: "",
        yourpurpose: "",
        brandguidelines: "",
        do: "",
        dont: "",
        updatedby: getLoginUserData().userId,
        createdby: getLoginUserData().userId,

    })

    // state variable end

    // successs and error message 
    useEffect(() => {
        if (apiResponse || apiError) {
            const timeoutId = setTimeout(removeSuccessMessage, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [apiResponse, apiError,]);

    const removeSuccessMessage = () => {
        setApiResponse(null);
        setApiError(null);
    };

    // end successs and error message 

    useEffect(() => {
        getUserData("Users/GetAll");
        getLeadData("Lead/GetAll?userId=" + getLoginUserData().userId);

    }, [salesRfp])

    const inputsLeadHandler = (e) => {

        const { name, value } = e.target;
        setLeadInputfield((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    // const inputsLeadHandler1 = (e) => {
    //     //  setInputField( {[e.target.name]: e.target.value} )
    //     debugger
    //     const { name, value } = e.target;
    //     var dateCheck = formatDate(leadInputField.endDate);
    //     var i = 0;
    //     setLeadInputfield((prevState) => ({
    //         ...prevState,
    //         [name]: value,
    //     }));
    //     // if (name === "endDate") {

    //     //     if (formatDate(leadInputField.startDate) > formatDate(leadInputField.endDate)) {
    //     //         i = 1;
    //     //         alert("start date greater than end date..! we are setting current date.")

    //     //     }
    //     //     else {
    //     //         setLeadInputfield((prevState) => ({
    //     //             ...prevState,
    //     //             [name]: value,
    //     //         }));
    //     //     }
    //     // }
    //     // else {
    //     //     setLeadInputfield((prevState) => ({
    //     //         ...prevState,
    //     //         [name]: value,
    //     //     }));
    //     // }
    //     //  if (i === 1) {
    //     //      setLeadInputfield((prevState) => ({
    //     //          ...prevState,
    //     //          [name]: dateCheck,
    //     //      }));
    //     //  }
    // }

    const inputsRfpHandler = (e) => {
        //  setInputField( {[e.target.name]: e.target.value} )
        const { name, value } = e.target;
        setRfpInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const getUserData = (method) => {
        Service.getdata(method).then((res) => {
            if (res !== undefined) {
                setdropdownUser(res.data);
                var a = res.data

                var intdeptId = parseInt(getLoginUserData().deptId)
                var list = [];

                a.forEach((d, index) => {
                    var role = d.lstRole;
                    var checkdepartment = role.find(x => x.depId === 2 || x.depId === 4)
                    if (checkdepartment !== undefined) {
                        d.rolename = d.lstRole[0].name;
                        list.push(d);
                    }
                });
                setAccountManager(list);
            }
        });
    };


    const getLeadData = (method) => {
        Service.getdata(method).then((res) => {
            if (res !== undefined) {
                var filteredData = res.data.filter((x) => x.directLead !== true && x.createRFP === false)
                setLeadData(res.data);
                setLeadDataPostSave(filteredData)
            }
        })
    };

    const handleSelectChange = (selectedOptions) => {
        setSelectedAssignees(selectedOptions);
    };

    const handleInputLead = (e) => {
        var LeadId = leadData.filter(x => x.leadid === e.target.value)
        if (LeadId.length !== 0) {
            getLeadDetailsById("Lead/GetById?id=", LeadId[0].id);
        }
        // error message put
        else {
            alert({ message: "No lead Data" });
        }
    }

    const handleIsConvincingChange = (e) => {
        if (isConvincingDetail === true) {
            setIsConvincingDetail(false);
        }
        else {
            setIsConvincingDetail(true)
        }
    }

    const getLeadDetailsById = (method, id) => {
        Service.getdatabyId(method, id).then((res) => {
            if (res !== undefined) {

                setIsConvincingDetail(res.data.isConvincing);
                setSelectedAssignees(res.data.salesHeadId);
                setLeadId(res.data.id);
                setLeadInputfield((prevState) => ({
                    ...prevState,
                    ["startDate"]: res.data.startDate,
                    ["endDate"]: res.data.endDate,
                    ["exhibitorName"]: res.data.leadid,
                    ["venue"]: res.data.venue,
                    ["email"]: res.data.email,
                    ["position"]: res.data.position,
                    ["companyName"]: res.data.companyName,
                    ["phone"]: res.data.phone,
                    ["history"]: res.data.history,
                    ["comments"]: res.data.comments,
                    ["background"]: res.data.background,
                    ["showid"]: res.data.showid,
                    ["exhibitorid"]: res.data.exhibitorid,
                    ["salesHeadId"]: res.data.salesHeadId,
                    ["leadid"]: res.data.leadid,

                }));
            }

        });
    }

    const shareData = () => {
        const data = []
        // check selected assignee null
        // check for id of creater
        if ((selectedAssignees !== null || selectedAssignees.length !== 0) && (getUserData().userId !== null || getUserData().userId !== undefined)) {
            for (let i = 0; i < selectedRFPAssignees.length; i++) {
                const assignment = {

                    userid: selectedRFPAssignees[i],
                    createdby: getLoginUserData().userId,
                    updatedby: getLoginUserData().userId,
                };
                data.push(assignment);
            }
        }
        // alert for user message 
        else {
            alert({ message: "something Went Wrong please login again" });
        }
        console.log(data)
        debugger
        if (data) {
            Service.createorupdate("RFP/CreateOrUpdateshare", data)
                //res condition check
                .then(res => {
                    if (res !== undefined) {
                        if (data) {

                            for (var m = 0; m <= data.length; m++) {
                                console.log(data[m]);
                                debugger
                                Service.createorupdate("Questionare/CreateOrUpdate", data[m])
                                    .then(res => {
                                        if (res.status === 200) {
                                            setApiResponse(res.data);

                                        }
                                        else {
                                            alert("notification not sent")
                                        }
                                        // window.location.reload(true);

                                    })
                                    .catch(error => {
                                        alert("notification not sent")
                                        // console.error("API Error:", error);
                                    });
                            }
                            // window.location.reload(true);
                        }

                    }
                    else {
                        alert({ message: "RFP Not Created Please Something wrong in data try again later" })
                    }
                })
                .catch(error => {
                    // Handle error if API call fails
                    console.error("API Error:", error);
                    setApiError("Error occurred while saving the meeting. Please try again later.");
                });
        }

    }

    const saveLead = (evt) => {

        evt.preventDefault();
        if (isConvincingDetail === true) {
            var isConvincingData = true;
        }
        else {
            isConvincingData = false
        }
        if (getLoginUserData().userId === null || getLoginUserData().userId === undefined) {
            alert("please login again");
            return;
        }
        if (salesRfp === true) {
            var salesHeadId = { ...selectedAssignees.slice(0, 1).map((x) => x.value) }
            if (formatDate(leadInputField.startDate) > formatDate(leadInputField.endDate)) {
                alert("start date is greater than end date please select again ");
            }
            else {
                if (salesHeadId !== null || salesHeadId !== undefined) {

                    const leadData1 = {
                        isConvincing: true,
                        directLead: true,
                        salesHeadId: salesHeadId[0],
                        id: leadId,
                        ...leadInputField,

                    }
                    console.log(leadData1)
                    debugger
                    if (leadData1) {
                        Service.createorupdate("Lead/CreateOrUpdate", leadData1)
                            .then(res => {
                                if (res !== undefined) {
                                    setNewLeadId(res.data.currentId);
                                    debugger
                                    setView1(true);
                                }
                                else {
                                    alert("Lead Not Created Data Mismatch try Again Later")
                                }
                            })
                            .catch(error => {
                                // Handle API error, if necessary
                                console.error("API Error:", error);
                                setApiError("Error occurred. Please try again later.");
                            });
                    }
                }
                else {
                    alert("sales head id is not filled");
                }

            }

            // salesHeadId not available error message
        }
        else {
            const leadData1 = {
                ...leadInputField,
                isConvincing: isConvincingData,
                directLead: false,
                id: leadId,
                exhibitorName: null,

            }
            console.log(leadData1)
            debugger
            if (leadData1) {
                Service.createorupdate("Lead/CreateOrUpdate", leadData1)
                    .then(res => {

                        setNewLeadId(res.data.currentId);

                        setView1(true);
                        // window.location.href = "/QualifiedLeadList"

                    })
                    .catch(error => {
                        // Handle API error, if necessary
                        console.error("API Error:", error);
                        setApiError("Error occurred. Please try again later.");
                    });
            }
        }
    }

    const saveRfp = (evt) => {
        evt.preventDefault();
        setLoading(true);
        if (!salesRfp) {
            var leadInfo = leadData.filter((e) => e.leadid === leadInputField.leadid);
            // lead info condition 
            if (leadInfo !== undefined) {

                console.log(leadInfo.length);

                const rfpDataMarketing = {
                    ...rfpInputField,
                    leadid: leadInputField.exhibitorName,
                    name: leadInfo[leadInfo.length - 1].id.toString(),
                    venue: leadInputField.venue,
                    exhibitionAttachment: (/true/).test(rfpInputField.exhibitionAttachment),
                    floorplanAttachment: (/true/).test(rfpInputField.floorplanAttachment),
                    brandAttachemnt: (/true/).test(rfpInputField.brandAttachemnt),
                    briefLinkAttachment: (/true/).test(rfpInputField.briefLinkAttachment),
                    exhibitionName: leadInputField.exhibitorName,
                    dateFrom: formatDate(leadInputField.startDate),
                    dateTo: formatDate(leadInputField.endDate),
                    contactName: leadInputField.history,
                    position: leadInputField.position,
                    companyName: leadInputField.companyName,
                    phone: leadInputField.phone,
                    email: leadInputField.email,

                }
                console.log(rfpDataMarketing);
                debugger
                const leadNewData = {
                    ...leadInputField,
                    id: newLeadId,
                    directLead: false,
                    createRFP: true,
                    isConvincing: true,
                    exhibitorName: null,

                }
                console.log(leadNewData);
                debugger
                if (rfpDataMarketing) {
                    Service.createorupdate("RFP/CreateOrUpdate", rfpDataMarketing)
                        .then(res => {
                            debugger
                            // shareData();
                            if (res.status === 200) {
                                setLoading(false);
                                setApiResponse(res.data);
                                Service.createorupdate("Lead/CreateOrUpdate", leadNewData) // window.location.href = "/RFPList";
                                window.location.href = "/RFPList";
                            }
                            else {
                                alert("rfp data is not fomatted please try again later")
                            }
                            // Set the API response in state
                        })
                        .catch(error => {
                            // Handle error if API call fails
                            setLoading(false);
                            console.error("API Error:", error);
                            setApiError("Error occurred while saving the RFP. Please try again later.");
                        });
                }
            }
            else {
                alert("No Available Lead try again later")
            }
        }

        else {

            var rfpCreator = getLoginUserData().userId;
            if (formatDate(leadInputField.startDate) > formatDate(leadInputField.endDate)) {
                alert("start date is greater than end date please select again ");
            }
            if (rfpCreator !== undefined && rfpCreator !== null) {
                var rfpSalesData = {
                    ...rfpInputField,
                    leadid: "Lead_" + leadInputField.showName + "_" + leadInputField.exhibitorName,
                    exhibitionName: "Lead_" + leadInputField.showName + "_" + leadInputField.exhibitorName,
                    exhibitionAttachment: (/true/).test(rfpInputField.exhibitionAttachment),
                    floorplanAttachment: (/true/).test(rfpInputField.floorplanAttachment),
                    brandAttachemnt: (/true/).test(rfpInputField.brandAttachemnt),
                    briefLinkAttachment: (/true/).test(rfpInputField.briefLinkAttachment),
                    name: newLeadId.toString(),
                    venue: leadInputField.venue,
                    dateFrom: formatDate(leadInputField.startDate),
                    dateTo: formatDate(leadInputField.endDate),
                    contactName: leadInputField.history,
                    position: leadInputField.position,
                    companyName: leadInputField.companyName,
                    phone: leadInputField.phone,
                    email: leadInputField.email,
                }



                console.log(rfpSalesData);
                debugger
                const leadNewData = {
                    ...leadInputField,
                    leadid: "Lead_" + leadInputField.showName + "_" + leadInputField.exhibitorName,
                    id: newLeadId,
                    createRFP: true,
                    directLead: true,
                    isConvincing: true,
                    salesHeadId: getLoginUserData().userId,
                }
                if (rfpSalesData) {
                    Service.createorupdate("RFP/CreateOrUpdate", rfpSalesData)
                        .then(res => {
                            debugger
                            if (res.status === 200) {
                                // shareData();
                                setApiResponse(res.data);
                                setLoading(false);
                                Service.createorupdate("Lead/CreateOrUpdate", leadNewData) // window.location.href = "/RFPList";
                                window.location.href = "/RFPList";
                                // Set the API response in state
                            }
                            else {
                                alert("something wrong in rfp data try again later")
                                setLoading(false);

                            }
                        })
                        .catch(error => {
                            // Handle error if API call fails
                            setLoading(false);
                            console.error("API Error:", error);
                            setApiError("Error occurred while saving the RFP. Please try again later.");
                        });
                }
            }
        }
    }

    const backbutton = () => {
        props.navigate(-1);
    }

    return (
        <div>
            <div className="container-fluid">

                <br />
                <div className="card shadow  border-success mb-4">
                    <div className="card-header rounded py-3">
                        <div className="row">
                            <div className="col-sm-7 row">
                                <h3 className='mt-2'><b className='text-dark'>Create RFP</b></h3>
                                <div className="col-sm-7">
                                    <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => setSalesRfp(true)}>Sales RFP</button>
                                    <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => setSalesRfp(false)}>Marketing RFP</button>
                                </div>
                            </div>
                            <div className="col-sm-1"></div>
                            <div className="col-sm-4 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
                            </div>

                        </div>
                    </div>
                </div>


                <div className="card shadow mb-4 text-dark" style={{ height: "auto", backgroundColor:"rgb(221, 255, 213)" }}>
                    <div className="card-header d-flex py-3">
                        {salesRfp === false ? <h6 className="m-0 mt-1 font-weight-bold text-primary ">Marketing Lead</h6> :
                            <h6 className="m-0 mt-1 font-weight-bold text-primary ">Sales Lead</h6>}
                    </div>

                    {/* 1st part */}
                    <form onSubmit={saveLead}>
                        <div className="card-body " style={{ height: "auto" }}>
                            <div className="row ">
                                <div className="col-md-5 ml-5">
                                    {salesRfp === false ?
                                        <>
                                            <div className="form-group row">
                                                <div className='col-sm-12 required-label'><b>Lead</b> <i class="fa-solid fa-hand-holding-dollar"></i></div>

                                                <div className='col-sm-12'>
                                                    <select className="form-control  form-control-user" name="leadid" disabled={view1} onChange={handleInputLead} required>
                                                        <option>[NONE]</option>
                                                        {
                                                            leadDataPostSave.map((e, key) => {
                                                                //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;
                                                                return <option key={e.id} value={e.leadid}>{e.leadid}</option>;
                                                            })}
                                                    </select>
                                                </div>
                                            </div>

                                        </>
                                        :
                                        <div className="form-group row">
                                            <div className='col-sm-12 required-label'><b>Show Name</b></div>
                                            <div className='col-sm-12'>
                                                <input type="text" className="form-control form-control-user"
                                                    name="showName" placeholder="enter Show Name" onChange={inputsLeadHandler} required />
                                            </div>
                                        </div>
                                    }

                                    <div className="form-group row">
                                        <div className='col-sm-12 required-label'><b>Exhibhitor Name</b></div>
                                        <div className='col-sm-12'>
                                            {!salesRfp ? <input type="text" className="form-control form-control-user" name="exhibitorid" placeholder="enter exhibhitor name" value={leadInputField.exhibitorName} disabled={!salesRfp} />
                                                : <input type="text" className="form-control form-control-user" name="exhibitorName" placeholder="enter exhibhitor name" value={leadInputField.exhibitionName} disabled={!salesRfp} onChange={inputsLeadHandler} required />}
                                        </div>
                                    </div>

                                    <div className="form-group row d-flex">
                                        <div className='col-sm-3 mt-1 required-label'><b>Show Start Date</b></div>

                                        {!salesRfp ? <input type="date" className="form-control col-sm-3 mb-1 form-control-user" name="dateFrom" placeholder="enter" value={formatDate(leadInputField.startDate)} onChange={inputsLeadHandler} />
                                            : <input type="date" className="form-control col-sm-3 mb-1 form-control-user" name="startDate" onChange={inputsLeadHandler} min={getCurrentDate()} required />}

                                        <div className='col-sm-3 mt-1 required-label'><b> Show End&nbsp; Date</b>   </div>

                                        {!salesRfp ? <input type="date" className="form-control col-sm-3 mb-1  form-control-user" name="dateTo" placeholder="Date To" value={formatDate(leadInputField.endDate)} onChange={inputsLeadHandler} />
                                            : <input type="date" className="form-control col-sm-3 mb-1  form-control-user" name="endDate" onChange={inputsLeadHandler} min={getCurrentDate()} required />}
                                    </div>

                                    <h5>POC Info</h5>
                                    <div className="form-group row">
                                        <div className='col-sm-4  mt-2 required-label'><b>Contact Name</b></div>
                                        <div className='col-sm-8'>
                                            <input type="text" className="form-control form-control-user"
                                                name="history" placeholder="enter Contact Name" value={leadInputField.history} disabled={!salesRfp} onChange={inputsLeadHandler} required />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className='col-sm-4  mt-2 required-label'><b>Position</b></div>
                                        <div className='col-sm-8'>
                                            <input type="text" className="form-control form-control-user"
                                                name="position" placeholder="enter Position Name" value={leadInputField.position} disabled={!salesRfp} onChange={inputsLeadHandler} required />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className='col-sm-4  mt-2 required-label'><b>Company Name</b></div>
                                        <div className='col-sm-8'>
                                            <input type="text" className="form-control form-control-user"
                                                name="companyName" placeholder="enter Company Name" value={leadInputField.companyName} disabled={!salesRfp} onChange={inputsLeadHandler} required />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className='col-sm-4  mt-2 required-label'><b>Email</b></div>
                                        <div className='col-sm-8'>
                                            <input type="text" className="form-control form-control-user"
                                                name="email" placeholder="enter Email" value={leadInputField.email} disabled={!salesRfp} onChange={inputsLeadHandler} required />
                                        </div>
                                    </div>



                                </div>

                                <div className='col-md-1'></div>

                                <div className="col-md-5">

                                    <div className="form-group row">
                                        <div className='col-sm-12 required-label'><b>Venue/Place/City</b></div>
                                        <div className='col-sm-12'>
                                            <input type="text" className="form-control form-control-user"
                                                name="venue" placeholder="enter Venue" value={leadInputField.venue} disabled={!salesRfp} onChange={inputsLeadHandler} required />
                                        </div>
                                    </div>

                                    {salesRfp === true ?
                                        <div className="form-group row">
                                            <div className='col-sm-12 required-label'><b>Account Manager</b></div>
                                            <div className='col-sm-12'>
                                                <Select
                                                    name="salesHeadId"
                                                    isMulti={true}
                                                    placeholder="Enter Assignee "
                                                    options={accountManager.map((user) => ({
                                                        value: user.id,
                                                        label: `${user.firstname} ${user.lastname} - ${user.rolename}`,
                                                    }))}
                                                    value={selectedAssignees}
                                                    onChange={handleSelectChange}

                                                />
                                            </div>
                                        </div>
                                        :
                                        <div className="form-group row">
                                            <div className='col-sm-12 required-label '><b>Account Manager</b></div>
                                            <div className='col-sm-12'>
                                                <select className="form-control form-control-fs  form-control-user" disabled={!salesRfp} readOnly={true} onChange={handleSelectChange} value={selectedAssignees} name="exhibitorid" required >
                                                    <option>[NONE]</option>
                                                    {
                                                        dropdownUser.map((e, key) => {
                                                            return <option key={e.id} value={e.id}>{e.firstname} {e.lastname}</option>;
                                                        })}

                                                </select>
                                            </div>
                                        </div>
                                    }
                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Additional Inputs</b></div>
                                        <div className='col-sm-12'>
                                            <textarea
                                                rows={1} // You can adjust the number of visible rows
                                                cols={100}
                                                name='comments'
                                                placeholder='enter Additional Input' // You can adjust the number of visible columns
                                                class="form-control"
                                                autofocus
                                                disabled={!salesRfp}

                                                value={leadInputField.comments}
                                                onChange={inputsLeadHandler}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Brief Synopsis</b></div>
                                        <div className='col-sm-12'>
                                            <textarea
                                                rows={1} // You can adjust the number of visible rows
                                                cols={100}
                                                name='background'
                                                placeholder='enter Brief Synopsis' // You can adjust the number of visible columns
                                                class="form-control"
                                                autofocus
                                                disabled={!salesRfp}

                                                value={leadInputField.background}
                                                onChange={inputsLeadHandler}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className='col-sm-3  mt-2 required-label'><b>Phone</b></div>
                                        <div className='col-sm-9'>
                                            <input type="text" className="form-control form-control-user" disabled={!salesRfp}
                                                name="phone" placeholder="enter Phone" value={leadInputField.phone} onChange={inputsLeadHandler} required />
                                        </div>
                                    </div>



                                    {!salesRfp && <div className="form-group row">
                                        <div className='col-sm-3 '><b>Is Convincing</b></div>
                                        <div className='col-sm-1'>
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={isConvincingDetail} onClick={handleIsConvincingChange} />
                                        </div>
                                    </div>}
                                    {/* <div className="form-group row">
                                        {apiResponse && (
                                            <div className="alert alert-success" role="alert">
                                                Lead has been Convinced successfully!
                                            </div>
                                        )}
                                        {apiError && (
                                            <div className="alert alert-danger" role="alert">
                                                {apiError}
                                            </div>
                                        )}
                                    </div> */}
                                    {view1 === false && <button type='submit' className="btn btn-primary">Next</button>}

                                </div>
                            </div>
                        </div>
                    </form>

                    <hr />

                    {/* 2nd part */}
                    {view1 === true &&
                        <form onSubmit={saveRfp}>
                            <div className="card-body" style={{ height: "auto" }}>
                                <div className="row">
                                    <div className="col-md-12 ml-5">
                                        <div className="form-group row">
                                            <div className='col-sm-2'><b></b></div>
                                            <div className='col-sm-4 d-flex justify-content-center'><b>Link</b></div>
                                            {/* <div className='col-sm-2 d-flex justify-content-center'><b>Button</b></div> */}
                                            <div className='col-sm-3'><b>Attached</b></div>
                                            <div className='col-sm-3'><b>Unavailable</b></div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 ml-5">
                                        <div className="form-group row">
                                            <div className='col-sm-2 mt-1 '><b>Floor Plan</b></div>

                                            <div className='col-sm-4 '><textarea
                                                rows={1} // You can adjust the number of visible rows
                                                cols={100}
                                                name='floorplan'
                                                placeholder='enter floor plan link' // You can adjust the number of visible columns
                                                class="form-control"
                                                autofocus

                                                onChange={inputsRfpHandler}
                                            /></div>

                                            <div className='col-sm-3'>
                                                <input type="radio" className='ml-3' name="floorplanAttachment" value={true} onChange={inputsRfpHandler} />
                                            </div>
                                            <div className='col-sm-3'>
                                                <input type="radio" className='ml-3' name="floorplanAttachment" value={false} onChange={inputsRfpHandler} />
                                            </div>

                                            {/* <div className='col-sm-3'>
                                                <input type="radio" className='ml-3' name="floorplanAttachment" value={true} onChange={inputsRfpHandler} checked={rfpInputField.floorplanAttachment} />
                                            </div>
                                            <div className='col-sm-3'>
                                                <input type="radio" className='ml-3' name="floorplanAttachment" value={false} onChange={inputsRfpHandler} checked={rfpInputField.floorplanAttachment} />
                                            </div> */}
                                        </div>
                                    </div>

                                    <div className="col-md-12 ml-5">
                                        <div className="form-group row">
                                            <div className='col-sm-2 mt-1 '><b>Exhibhition Guidelines</b></div>
                                            <div className='col-sm-4'><textarea
                                                rows={1} // You can adjust the number of visible rows
                                                cols={100}
                                                name='guidelines'
                                                placeholder='enter guidelines details link' // You can adjust the number of visible columns
                                                class="form-control"
                                                autofocus

                                                onChange={inputsRfpHandler}
                                            /></div>

                                            <div className='col-sm-3'>
                                                <input type="radio" className='ml-3' name="exhibitionAttachment" value={true} onChange={inputsRfpHandler} />
                                            </div>
                                            <div className='col-sm-3'>
                                                <input type="radio" className='ml-3' name="exhibitionAttachment" value={false} onChange={inputsRfpHandler} />
                                            </div>

                                            {/* <div className='col-sm-3'>
                                                <input type="radio" className='ml-3' name="exhibitionAttachment" value={true} onChange={inputsRfpHandler} checked={rfpInputField.exhibitionAttachment} />
                                            </div>
                                            <div className='col-sm-3'>
                                                <input type="radio" className='ml-3' name="exhibitionAttachment" value={false} onChange={inputsRfpHandler} checked={rfpInputField.exhibitionAttachment} />
                                            </div> */}
                                        </div>
                                    </div>

                                    <div className="col-md-12 ml-5">
                                        <div className="form-group row">
                                            <div className='col-sm-2 mt-1 '><b>Brand Guidelines</b></div>
                                            <div className='col-sm-4 '><textarea
                                                rows={1} // You can adjust the number of visible rows
                                                cols={100}
                                                name='brandguidelines'
                                                placeholder='brand guidelines if any' // You can adjust the number of visible columns
                                                class="form-control"
                                                autofocus

                                                onChange={inputsRfpHandler}
                                            /></div>

                                            <div className='col-sm-3'>
                                                <input type="radio" className='ml-3' name="brandAttachemnt" value={true} onChange={inputsRfpHandler} />
                                            </div>
                                            <div className='col-sm-3'>
                                                <input type="radio" className='ml-3' name="brandAttachemnt" value={false} onChange={inputsRfpHandler} />
                                            </div>

                                            {/* <div className='col-sm-3'>
                                                <input type="radio" className='ml-3' name="brandAttachemnt" value={true} onChange={inputsRfpHandler} checked={rfpInputField.brandAttachemnt} />
                                            </div>
                                            <div className='col-sm-3'>
                                                <input type="radio" className='ml-3' name="brandAttachemnt" value={false} onChange={inputsRfpHandler} checked={rfpInputField.brandAttachemnt} />
                                            </div> */}
                                        </div>
                                    </div>

                                    <div className="col-md-12 ml-5">
                                        <div className="form-group row">
                                            <div className='col-sm-2 mt-1 '><b>Brief Link</b></div>
                                            <div className='col-sm-4 '><textarea
                                                rows={1} // You can adjust the number of visible rows
                                                cols={100}
                                                name='contentLink'
                                                placeholder="enter additional attachment Link if any" // You can adjust the number of visible columns
                                                class="form-control size1"
                                                autofocus

                                                onChange={inputsRfpHandler}
                                            /></div>

                                            <div className='col-sm-3'>
                                                <input type="radio" className='ml-3' name="briefLinkAttachment" value={true} onChange={inputsRfpHandler} />
                                            </div>
                                            <div className='col-sm-3'>
                                                <input type="radio" className='ml-3' name="briefLinkAttachment" value={false} onChange={inputsRfpHandler} />
                                            </div>

                                            {/* <div className='col-sm-3'>
                                                <input type="radio" className='ml-3' name="briefLinkAttachment" value={true} onChange={inputsRfpHandler} checked={rfpInputField.briefLinkAttachment} />
                                            </div>
                                            <div className='col-sm-3'>
                                                <input type="radio" className='ml-3' name="briefLinkAttachment" value={false}  checked={!rfpInputField.briefLinkAttachment} />
                                            </div> */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            {/* 3rd part */}
                            <div className="card-body" style={{ height: "auto" }}>
                                <div className="row">
                                    <div className='col-md-5 ml-5'>

                                        <div className="form-group row">
                                            {/* {deadlineError && <div className="alert alert-danger mt-3" role="alert">Please select a design Deadline.</div>} */}
                                            <div className='col-sm-12 required-label'><b>Design Deadline</b></div>
                                            <div className='col-sm-12'><input type="date" className="form-control form-control-user" onChange={inputsRfpHandler}
                                                min={getCurrentDate()} name="deadlineDate" placeholder="Enter Design Deadline" required autofocus /></div>
                                        </div>



                                        <h5>Product display</h5>
                                        <div className="form-group row">
                                            <div className='col-sm-12'><b>Number of Products to Display If Any</b></div>
                                            <div className='col-sm-12'><input type="text" className="form-control form-control-user"
                                                name="noofProduct" placeholder="enter number of products" onChange={inputsRfpHandler} /></div>
                                        </div>
                                        <h6>Note : We need product LXWXH with drawing and photo if possible., you can attached product photo and drawing seperately</h6>

                                        <div className="form-group row">
                                            <div className='col-sm-12'><b>Your Purpose</b></div>
                                            <div className='col-sm-12'><textarea
                                                rows={3} // You can adjust the number of visible rows
                                                cols={100}
                                                name='yourpurpose'
                                                placeholder='Please elaborate your purpose by providing examples of stalls you like will help us understand your taste better and also the style you are looking for.' // You can adjust the number of visible columns
                                                class="form-control"
                                                autofocus
                                                onChange={inputsRfpHandler}
                                            /></div>
                                        </div>
                                        <div className="form-group row">
                                            <div className='col-sm-12'><b>Do's</b></div>
                                            <div className='col-sm-12'><textarea
                                                rows={3} // You can adjust the number of visible rows
                                                cols={100}
                                                name='do'
                                                placeholder="enter Do's /optional" // You can adjust the number of visible columns
                                                class="form-control"
                                                autofocus
                                                onChange={inputsRfpHandler}

                                            /></div>
                                        </div>

                                        <div className="form-group row">
                                            <div className='col-sm-12'><b>Dont's</b></div>
                                            <div className='col-sm-12'><textarea
                                                rows={3} // You can adjust the number of visible rows
                                                cols={100}
                                                name='dont'
                                                placeholder="enter Dont's /optional" // You can adjust the number of visible columns
                                                class="form-control"
                                                autofocus
                                                onChange={inputsRfpHandler}

                                            /></div>
                                        </div>

                                    </div>
                                    <div className='col-md-1'></div>
                                    <div className='col-md-5'>

                                        <div className="form-group row">
                                            <div className='col-sm-2 mt-2 required-label'><b>Area</b></div>
                                            <div className='col-sm-10'><input type="text" className="form-control form-control-user" onChange={inputsRfpHandler}
                                                name="area" placeholder="enter Area" required /></div>
                                        </div>
                                        <div className="form-group row">
                                            <div className='col-sm-2 mt-2'><b>Stall No</b></div>
                                            <div className='col-sm-10'><input type="text" className="form-control form-control-user" onChange={inputsRfpHandler}
                                                name="stallNo" placeholder="enter stall number" /></div>
                                        </div>
                                        <div className="form-group row">
                                            <div className='col-sm-2 mt-2'><b>Hall No.</b></div>
                                            <div className='col-sm-10'><input type="text" className="form-control form-control-user" onChange={inputsRfpHandler}
                                                name="hallNo" placeholder="enter hall number" /></div>
                                        </div>

                                        <div className="form-group row">
                                            <div className='col-sm-2 mt-2'><b>Budget</b></div>
                                            <div className='col-sm-10'><input type="text" className="form-control form-control-user" onChange={inputsRfpHandler}
                                                name="budget" placeholder="enter budget" /></div>
                                        </div>
                                        <hr />

                                        <div className="form-group row" hidden={true}>
                                            <div className='col-sm-12 -label'><b>Fax</b></div>
                                            <div className='col-sm-12'><input type="text" className="form-control form-control-user" onChange={inputsRfpHandler}
                                                name="fax" placeholder="enter fax" /></div>
                                        </div>

                                        <div className="form-group row" hidden={true}>
                                            <div className='col-sm-12 required-label'><b>Billing Address</b></div>
                                            <div className='col-sm-12'><textarea
                                                rows={2} // You can adjust the number of visible rows
                                                cols={100}
                                                name='billingAddress'
                                                placeholder='enter billing address' // You can adjust the number of visible columns
                                                class="form-control"
                                                value={"testBillingAddress"}
                                                autofocus

                                            /></div>
                                        </div>
                                        <div className="form-group row">
                                            <div className='col-sm-12 '><b>Any Specific Need</b></div>
                                            <div className='col-sm-12'><textarea
                                                rows={2} // You can adjust the number of visible rows
                                                cols={100}
                                                name='anySpecific'
                                                placeholder='enter specific need' // You can adjust the number of visible columns
                                                class="form-control"
                                                autofocus
                                                onChange={inputsRfpHandler}
                                            /></div>
                                        </div>
                                        <div className="form-group row">
                                            <div className='col-sm-12 -label'><b>Description If Any</b></div>
                                            <div className='col-sm-12'><textarea
                                                rows={2} // You can adjust the number of visible rows
                                                cols={100}
                                                name='description'
                                                placeholder='enter description' // You can adjust the number of visible columns
                                                class="form-control"
                                                autofocus
                                                onChange={inputsRfpHandler}
                                            /></div>
                                        </div>

                                        <div className="form-group row">
                                            <div className='col-sm-12 '><b>Design Refrence</b></div>
                                            <div className='col-sm-12'><textarea
                                                rows={1} // You can adjust the number of visible rows
                                                cols={100}
                                                name='designRefrence'
                                                placeholder='Please give design refrence if any' // You can adjust the number of visible columns
                                                class="form-control"
                                                autofocus
                                                onChange={inputsRfpHandler}
                                            /></div>
                                        </div>

                                        <h5>People</h5>
                                        <div className="form-group form-group-h row">
                                            <div className='col-sm-3'>Author - </div>
                                            <div className='col-sm-7'>
                                                <b>{getLoginUserData().name}</b>
                                            </div>
                                        </div>
                                        {/* <div className="form-group form-group-h row">
                                            <div className="col-sm-3">Shared With - </div>
                                            <div className="col-sm-9">
                                                <Select
                                                    name="assigneeid"
                                                    isMulti={true}
                                                    placeholder="Enter Shared with members"
                                                    options={dropdownUser.map((user) => ({
                                                        value: user.id,
                                                        label: `${user.firstname} ${user.lastname}`,
                                                        email: user.email,
                                                    }))}
                                                    value={selectedRFPAssignees.value}
                                                    onChange={handleSelectRFPUserChange}
                                                />
                                            </div>
                                        </div> */}

                                        <div className="form-group form-group-h row">
                                            {apiResponse && (
                                                <div className="alert alert-success" role="alert">
                                                    RFP has been Created successfully!
                                                </div>
                                            )}
                                            {apiError && (
                                                <div className="alert alert-danger" role="alert">
                                                    {apiError}
                                                </div>
                                            )}
                                        </div>
                                        {loading === false ? <h6 className="font-weight-bold text-primary">{ }</h6> :
                                            <div class=" text-primary">
                                                <strong>Saving...</strong>
                                                <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                                            </div>
                                        }

                                        <button type='submit' className="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    }

                </div>

            </div>
        </div>
    )
}

export default CreateRFPNewComponent