import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import CreateApprovalListComponent from '../../../Components/Main/ApprovalListComponent/CreateApprovalListComponent';

function CreateApprovalList(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <CreateApprovalListComponent {...props} navigate={navigate} id={params.id} name={params.name} />
        </div>
    );  
  }
export default CreateApprovalList 