import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import ExtractedDataIsApprovedComponent from '../../../Components/Main/ExtractedDataComponent/ExtractedDataIsApprovedComponent';

function ExtractedDataIsApproved(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <ExtractedDataIsApprovedComponent {...props} navigate={navigate} id={params.id} />
        </div>
    );  
  }
export default ExtractedDataIsApproved  