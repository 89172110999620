import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import TextareaAutosize from 'react-textarea-autosize';
import { Link } from 'react-router-dom';
import Service from '../../../Services/Admin/Service';
import {
  externalLink,
  formatDate,
  formatDateTime,
  getLoginUserData,
  hasLink
} from '../../../Helpers/supportedFunctions/supportedGlobalFunctions';
//comment
import { Mention, MentionsInput } from 'react-mentions';
import defaultStyle from '../Task/defaultStyle';
import defaultMentionStyle from '../Task/defaultMentionStyle';
//finish comment
console.log(getLoginUserData().deptId);
const RFPDetailsNewComponent = (props) => {
  //comment
  const [userNameData, setUserNameData] = useState([]);
  const [inputDiscussion, setInputDiscussion] = useState({
    discussionid: 0,
    rfpid: props.id,
    taskid: 0,
    userid: getLoginUserData().userId,
    comments: '',
  })
  const [comments, setComments] = useState([]);
  const [apiResponseComment, setapiResponseComment] = useState(null)
  //finishComments

  // share RFP
  const [apiSharedResponse, setApiSharedResponse] = useState(null);
  const [showAssigneePopup, setShowAssigneePopup] = useState(false);
  // end share rfp
  // auto task generation
  const [taskInputField, setTaskInputField] = useState({
    id: 0,
    headline: 'Design Requirement',
    shortDescription: '{\"blocks\":[{\"key\":\"etueq\",\"text\":\"Final Design For This \",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}',
    projectId: 0,
    showId: 0,
    leadid: props.id,
    isRequested: false,
    parentId: null,
    // typeId: 119,
    typeId: 1130,
    statusId: 1,
    priorityId: 2,
    planhour: 0,
    finishdate: '',
    createdby: getLoginUserData().userId,
    updatedby: null,
    assignerid: getLoginUserData().userId,
    isDeleted: false,
    contentLink: null,
    isActive: true,
    assigneeid: [0],
    removeAssigneeid: []
  })
  //set end auto task generation
  // notification
  const [inputQuestionare, setInputQuestionare] = useState({
    taskid: 0,
    description: "",
    senderid: 0,
    senderName: "",
    receiverid: 0,
    receiverName: "",
    isRead: false,
    createdby: getLoginUserData().userId,
  })

  // google calendar
  const [calendarUser, setCalendarUser] = useState([]);
  const [inputCalendar, setInputCalendar] = useState(
    {
      summary: "Design Requirement",
      description: "",
      startTime: "",
      endTime: "",
      calendarId: "sop@xsproductions.com",
      refreshToken: "1//0gkWVmDAlyPkGCgYIARAAGBASNwF-L9Irz9KGiZeCkJMbqqXSTdlCky2cDgLR866ieBZs7Uwie0VBZZXQekor3AGqaieyoOCCvbM",
      users: [
        ""
      ]
    }
  );
  // end notification
  const [dropdownUser, setdropdownUser] = useState([]);
  const [accountManager, setAccountManager] = useState([]);
  const [apiError, setApiError] = useState(null);
  const [apiResponse, setApiResponse] = useState(null);
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [selectedRFPAssignees, setSelectedRFPAssignees] = useState([]);
  const [leadData, setLeadData] = useState([]);
  const [leadAllData, setAllLeadData] = useState([]);
  const [rfpInputField, setRfpInputField] = useState({
    name: "",
    leadid: "",
    // leadName: "",
    exhibitionName: "",
    venue: "",
    dateTo: "",
    dateFrom: "",
    deadlineDate: "",
    floorplan: "",
    guidelines: "",
    stallNo: "",
    hallNo: "",
    budget: "",
    contactName: "",
    email: "",
    position: "",
    companyName: "",
    phone: "",
    billingAddress: "",
    anySpecific: "",
    website: "",
    description: "",
    noofProduct: "",
    designRefrence: "",
    yourpurpose: "",
    brandguidelines: "",
    do: "",
    dont: "",
    updatedby: getLoginUserData().userId,
    createdby: getLoginUserData().userId,

  })


  // successs and error message 
  useEffect(() => {
    if (apiResponse || apiError) {
      const timeoutId = setTimeout(removeSuccessMessage, 3000);
      return () => clearTimeout(timeoutId);
    }
    else if (apiResponseComment) {
      const timeoutId = setTimeout(removeSuccessMessage, 3000);
      return () => clearTimeout(timeoutId);
    }
    else if (apiSharedResponse) {
      const timeoutId = setTimeout(removeSuccessMessage, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [apiResponse, apiError, apiResponseComment, apiSharedResponse]);
  const removeSuccessMessage = (props) => {
    setApiResponse(null);
    setApiError(null);
    setapiResponseComment(null);
    setApiSharedResponse(null);
  };
  // end successs and error message 

  useEffect(() => {
    getRFPInfo("RFP/GetById?id=" + props.id)
    updatenotification("Questionare/readnotification?rfpid=" + props.id + "&userid=" + getLoginUserData().userId)
    getUserData("Users/GetAll");
    getLeadData("Lead/GetAll?userId=" + getLoginUserData().userId);
    getLeadAllData("Lead/GetAllLead");

  }, [])

  //comment
  const mentionData = (data, createdby) => {
    var arr = [];
    data.forEach((x, index) => {
      var abc = {};
      abc.id = x.asigneeName;
      abc.display = x.asigneeName;
      arr.push(abc);
      // setUserNameData({
      //     id:x.firstname, display:x.firstname
      // })
    })
    //         var createdUser = RFPDataDetail; 
    var abc = {}
    abc.id = createdby;
    abc.display = createdby;
    arr.push(abc)
    setUserNameData(arr);
  }
  const updatenotification = (method) => {
    Service.getdata(method).then((res) => {
    });
  }
  useEffect(() => {
    getDiscussionData("Task/GetALLRFPDiscussion?rfpId=" + props.id);
    updatenotification("Questionare/readnotification?rfpid=" + props.id + "&userid=" + getLoginUserData().userId)

  }, []);

  const getDiscussionData = (method) => {
    Service.getdata(method).then((res) => {
      // setComments(res.data);
      const updatedData = res.data.map((item) => ({
        ...item,
        comments: hasLink(item.comments),
      }));

      setComments(updatedData);

      // Set the comments in the state
    });
  }

  const submitDiscussion = (evt) => {
    evt.preventDefault();
    const formData = new FormData(evt.target);

    const data = {}
    for (const [key, value] of formData) {
      data[key] = value;
    }
    const comment = {
      ...inputDiscussion,
      comments: data.comments,
    };
    console.log(comment);

    //notification
    const data1 = []
    var users = [];
    rfpInputField.rfpShareList.map((e) => users.push(e.assigneeId));
    users.push(rfpInputField.createdby)
    console.log(selectedAssignees)
    console.log(users)


    for (var i = 0; i < users.length; i++) {
      var task1 = {};
      if (i === users.length - 1) {
        task1 = {
          ...inputQuestionare,
          rfpid: props.id,
          description: "comment added in RFP by",
          receiverid: rfpInputField.createdby,
          receiverName: rfpInputField.createdByName,
          senderid: getLoginUserData().userId,
          senderName: getLoginUserData().name,
        };
      }
      else {

        task1 = {
          ...inputQuestionare,

          rfpid: props.id,
          description: "comment added in RFP by",
          receiverid: users[i],
          receiverName: rfpInputField.rfpShareList[i].asigneeName,
          senderid: getLoginUserData().userId,
          senderName: getLoginUserData().name,
        };
      }

      data1.push(task1)
    }

    console.log(data1)

    // finish notification data
    if (comment) {
      Service.createorupdate("Task/CreateOrUpdateDiscussion", comment)
        .then(res => {
          setapiResponseComment(res.data);
          setInputDiscussion({ ...inputDiscussion, comments: '' }); // Set the API response in state
          getDiscussionData("Task/GetALLRFPDiscussion?rfpId=" + props.id);
          if (data1) {
            for (var m = 0; m <= data1.length; m++) {
              console.log(data1[m]);

              Service.createorupdate("Questionare/CreateOrUpdate", data1[m])
                .then(res => {
                  setApiResponse(res.data);
                })
                .catch(error => {

                  console.error("API Error:", error);
                });
            }
            window.location.reload(true);
          }
        })
        .catch(error => {
          // Handle error if API call fails
          console.error("API Error:", error);
        });
    }
  }
  //finishComment


  const inputsRfpHandler = (e) => {
    //  setInputField( {[e.target.name]: e.target.value} )
    const { name, value } = e.target;
    setRfpInputField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const getRFPInfo = (method) => {
    Service.getdata(method).then((res) => {
      setRfpInputField(res.data);

      mentionData(res.data.rfpShareList, res.data.createdByName);//comment
      var assignee = res.data.rfpShareList.map((x) => x.assigneeId)
      // setSelectedRFPAssignees(res.data.rfpShareList)

      console.log(res.data)

    });
  }

  const getUserData = (method) => {
    Service.getdata(method).then((res) => {
      setdropdownUser(res.data);
      var a = res.data
      var list = [];

      a.forEach((d, index) => {
        var role = d.lstRole;
        var checkdepartment = role.find(x => x.depId === 2 || x.depId === 4)
        if (checkdepartment !== undefined) {
          d.rolename = d.lstRole[0].name;
          list.push(d);
        }
      });
      setAccountManager(list);
    });
  };


  const getLeadData = (method) => {
    Service.getdata(method).then((res) => {
      var filteredData = res.data.filter((x) => x.isConvincing !== true)
      setLeadData(res.data);
      console.log(res.data)

    })
  };

  const getLeadAllData = (method) => {
    Service.getdata(method).then((res) => {
      var filteredData = res.data.filter((x) => x.createRFP === true)
      setAllLeadData(res.data);
      console.log(res.data)

    })
  };

  function extractValuesFromIndexedObject(indexedObject) {
    return Object.values(indexedObject).map(item => item.value);
  }

  const handleSelectRFPUserChange = (selectedOptions) => {
    setCalendarUser(selectedOptions);
    const extractedValues = extractValuesFromIndexedObject(selectedOptions);
    setSelectedRFPAssignees(extractedValues);
  };

  const autoTaskCreation = () => {
    var multiUserTask = [];
    var descriptionTask = JSON.parse(taskInputField.shortDescription);
    descriptionTask.blocks[0].text = rfpInputField.description;
    const modifiedDescription = JSON.stringify(descriptionTask);
    for (var i = 0; i < selectedRFPAssignees.length; i++) {
      multiUserTask.push({
        ...taskInputField, assigneeid: [selectedRFPAssignees[i]], leadid: rfpInputField.name,
        shortDescription: modifiedDescription,
        finishdate: rfpInputField.deadlineDate,
      });
      console.log(multiUserTask[i]);

      var date1 = new Date(rfpInputField.deadlineDate);
      date1.setHours(18, 0, 0, 0);

      var userEmail = calendarUser[i].email;

      const calendarTask = {
        ...inputCalendar,
        description: rfpInputField.leadid,
        startTime: new Date().toISOString(),
        endTime: date1,
        colorId: "4",
        users: [userEmail],
      }
      console.log(calendarTask);

      const save = async () => {
        if (calendarTask) {
          await Service.createorupdate("user/calendarevent", calendarTask).then(res => {
            return res.data;
          })
        }
      }

      var res = save();
      console.log(res);
      debugger
      if (res) {
        if (multiUserTask[i]) {
          Service.createorupdate("Task/CreateOrUpdate", multiUserTask[i])
            .then(res => {
              if (res) {
                window.location.href = "/KanbanAssignedTask";
              }

            })
            .catch(error => {
              // Handle error if API call fails
              console.error("API Error:", error);
              setApiError("Error occurred while saving the task. Please try again later.");

            });
        }
      }

    }
    window.location.href = "/KanbanAssignedTask";
  }

  const shareData = () => {
    console.log(selectedAssignees)

    const data = []
    for (let i = 0; i < selectedRFPAssignees.length; i++) {
      const assignment = {
        rfpid: props.id,
        userid: selectedRFPAssignees[i],
        createdby: getLoginUserData().userId,
        updatedby: getLoginUserData().userId,
      };
      data.push(assignment);
    }
    console.log(data)

    if (data) {
      Service.createorupdate("RFP/CreateOrUpdateshare", data)
        .then(res => {
          if (data) {
            for (var m = 0; m <= data.length; m++) {
              console.log(data[m]);

              Service.createorupdate("Questionare/CreateOrUpdate", data[m])
                .then(res => {
                  setApiResponse(res.data);
                  window.location.reload(true);
                })
                .catch(error => {
                  console.error("API Error:", error);
                });
            }
            window.location.reload(true);
          }
          setApiSharedResponse(res.data);
        })
        .catch(error => {
          // Handle error if API call fails
          console.error("API Error:", error);
          setApiError("Error occurred while saving the meeting. Please try again later.");
        });
    }

  }

  const saveRfp = (evt) => {
    evt.preventDefault();

    const rfpDataMarketing = {
      ...rfpInputField,
    }
    console.log(rfpDataMarketing);

    if (rfpDataMarketing) {
      Service.createorupdate("RFP/CreateOrUpdate", rfpDataMarketing)
        .then(res => {
          setApiResponse(res.data);
          // shareData();
          // window.location.href = "/RFPList";
          // Set the API response in state
        })
        .catch(error => {
          // Handle error if API call fails
          console.error("API Error:", error);
          setApiError("Error occurred while saving the RFP. Please try again later.");
        });
    }
  }

  const backbutton = () => {
    props.navigate(-1);
  }
   // ===============================================================>
   const handleDownload = (file) => {
     debugger
     const fileName = file;
 
     // Change to the name of the file you want to download
     Service.downloadFile("FileUpload/download/", fileName)
       .then(res => {
 
         const url = window.URL.createObjectURL(new Blob([res.data], { type: res.data.type }));
         debugger
         if(res.data.type === 'application/pdf'){
             window.open(url, '_blank');
         }
         const link = document.createElement('a');
         link.href = url;
         link.setAttribute('download', fileName);
         document.body.appendChild(link);
         link.click();
         link.remove();
       })
       .catch(error => {
         // Handle API error, if necessary
         console.error("API Error:", error);
 
       });
   };
 
   //    ============================================================> 
 

  return (
    <div>
      <div className="container-fluid">

        <br />
        <div className="card border-success shadow mb-2">
          <div className="card-header rounded py-3">
            <div className="row">
              <div className="col-sm-7 row">
                <h3 className='mt-2'><b className='text-dark'>RFP Details</b></h3>
                <div className="col-sm-5">

                </div>
              </div>
              <div className="col-sm-1"></div>
              <div className="col-sm-4 d-flex flex-row-reverse">
                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
              </div>

            </div>
          </div>
        </div>


        <div className="card shadow  mb-4">
          <div className="card-header  d-flex py-3">
            <h6 className="m-0 mt-1 font-weight-bold  text-success ">RFP Details  </h6>

          </div>

          {/* 1st part */}
          <form onSubmit={saveRfp}>
            <div className="card-body" style={{ height: "auto" }}>
              <div className="row text-dark">
                <div className="col-md-5 ml-5">

                  <>
                    <div className="form-group row">
                      <div className='col-sm-12 required-label'><b>Lead</b></div>

                      <div className='col-sm-12'>
                        <input type="text" className="form-control form-control-user"
                          name="leadid" value={rfpInputField.leadid} onChange={inputsRfpHandler} disabled={true} />
                      </div>
                    </div>

                  </>

                  <div className="form-group row">
                    <div className='col-sm-12 '><b>Exhibhitor Name</b></div>
                    <div className='col-sm-12'>
                      <input type="text" className="form-control form-control-user" name="exhibitionName" value={rfpInputField.exhibitionName} onChange={inputsRfpHandler} disabled={true} />
                    </div>
                  </div>

                  <div className="form-group row d-flex">
                    <div className='col-sm-3 mt-1'><b>Show Start Date</b></div>

                    <input type="date" className="form-control col-sm-3 mb-1 form-control-user" name="dateFrom" value={formatDate(rfpInputField.dateFrom)} onChange={inputsRfpHandler} disabled={true} />

                    <div className='col-sm-3 mt-1 '><b> Show End&nbsp; Date</b>   </div>

                    <input type="date" className="form-control col-sm-3 mb-1  form-control-user" name="dateTo" value={formatDate(rfpInputField.dateTo)} onChange={inputsRfpHandler} disabled={true} />
                  </div>

                  <h5>POC Info</h5>
                  {(rfpInputField.createdby === parseInt(getLoginUserData().userId) || getLoginUserData().userId === "1" || getLoginUserData().userId === "47") && <>
                    <div className="form-group row">
                      <div className='col-sm-4  mt-2'><b>Contact Name</b></div>
                      <div className='col-sm-8'>
                        <input type="text" className="form-control form-control-user"
                          name="contactName" value={rfpInputField.contactName} onChange={inputsRfpHandler} disabled={true} />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className='col-sm-4  mt-2'><b>Position</b></div>
                      <div className='col-sm-8'>
                        <input type="text" className="form-control form-control-user"
                          name="position" value={rfpInputField.position} onChange={inputsRfpHandler} disabled={true} />
                      </div>
                    </div>
                  </>}
                  <div className="form-group row">
                    <div className='col-sm-12'><b>Do's</b></div>
                    <div className='col-sm-12'><TextareaAutosize
                      rows={3} // You can adjust the number of visible rows
                      cols={100}
                      name='do'
                      value={rfpInputField.do}
                      // You can adjust the number of visible columns
                      class="form-control"
                      autofocus
                      onChange={inputsRfpHandler} disabled={true}

                    /></div>
                  </div>

                </div>

                <div className='col-md-1'></div>

                <div className="col-md-5">

                  <div className="form-group row">
                    <div className='col-sm-12 '><b>Venue/Place/City</b></div>
                    <div className='col-sm-12'>
                      <input type="text" className="form-control form-control-user"
                        name="venue" value={rfpInputField.venue} onChange={inputsRfpHandler} disabled={true} />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-2 mt-2'><b>Budget</b></div>
                    <div className='col-sm-10'><input type="text" className="form-control form-control-user" onChange={inputsRfpHandler} disabled={true}
                      name="budget" value={rfpInputField.budget} /></div>
                  </div>

                  <div className="form-group row">
                    {/* {deadlineError && <div className="alert alert-danger mt-3" role="alert">Please select a design Deadline.</div>} */}
                    <div className='col-sm-2'><b>Design Deadline</b></div>
                    <div className='col-sm-10'><input type="date" className="form-control form-control-user" onChange={inputsRfpHandler} disabled={true}
                      name="deadlineDate" value={formatDate(rfpInputField.deadlineDate)} /></div>
                  </div>
                  {(rfpInputField.createdby === parseInt(getLoginUserData().userId) || getLoginUserData().userId === "1" || getLoginUserData().userId === "47") && <>
                    <div className="form-group row">
                      <div className='col-sm-4  mt-2'><b>Company Name</b></div>
                      <div className='col-sm-8'>
                        <input type="text" className="form-control form-control-user"
                          name="companyName" value={rfpInputField.companyName} onChange={inputsRfpHandler} disabled={true} />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className='col-sm-4  mt-2'><b>Email</b></div>
                      <div className='col-sm-8'>
                        <input type="text" className="form-control form-control-user"
                          name="email" value={rfpInputField.email} onChange={inputsRfpHandler} disabled={true} />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className='col-sm-4  mt-2'><b>Phone</b></div>
                      <div className='col-sm-8'>
                        <input type="text" className="form-control form-control-user"
                          name="phone" value={rfpInputField.phone} onChange={inputsRfpHandler} disabled={true} />
                      </div>
                    </div>

                  </>}

                  <div className="form-group row">
                    <div className='col-sm-12'><b>Dont's</b></div>
                    <div className='col-sm-12'><TextareaAutosize
                      rows={3} // You can adjust the number of visible rows
                      cols={100}
                      name='dont'
                      // You can adjust the number of visible columns
                      class="form-control"
                      autofocus
                      value={rfpInputField.dont}
                      onChange={inputsRfpHandler} disabled={true}

                    /></div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-12 '><b>Additional input</b></div>
                    <div className='col-sm-12'><TextareaAutosize
                      rows={3} // You can adjust the number of visible rows
                      cols={100}
                      name='yourpurpose'
                      value={rfpInputField.additionalInputs}
                      readOnly
                      onChange={inputsRfpHandler}
                      class="form-control size1"
                      autofocus
                    /></div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-3 '><b>Is Convincing</b></div>
                    <div className='col-sm-1'>
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={true} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            {/* 2nd part */}
            <div className="card-body" style={{ height: "auto" }}>
              <div className="row text-dark">
                <div className="col-md-12 ml-5">
                  <div className="form-group row">
                    <div className='col-sm-2'><b></b></div>
                    <div className='col-sm-2 d-flex justify-content-center'><b>Link</b></div>
                    <div className='col-sm-2 d-flex justify-content-center'><b>Button</b></div>
                    <div className='col-sm-3'><b>Attached</b></div>
                    <div className='col-sm-3'><b>Unavailable</b></div>
                  </div>
                </div>

                <div className="col-md-12 ml-5">
                  <div className="form-group row">
                    <div className='col-sm-2 mt-1'><b>Floor Plan</b></div>

                    <div className='col-sm-2 '><TextareaAutosize
                      rows={1} // You can adjust the number of visible rows
                      cols={100}
                      name='floorplan'
                      // You can adjust the number of visible columns
                      class="form-control"
                      autofocus
                      value={rfpInputField.floorplan}
                      onChange={inputsRfpHandler} disabled={true}
                    /></div>
                    <div className='col-sm-2 '>
                      <div className='btn btn-sm ml-4 btn-info' onClick={() => externalLink(rfpInputField.floorplan)} >Open floorPlan </div>
                    </div>

                    <div className='col-sm-3'>
                      <input type="radio" className='ml-3' name="floorplanAttachment" value={true} checked={rfpInputField.floorplanAttachment} onChange={inputsRfpHandler} />
                    </div>
                    <div className='col-sm-3'>
                      <input type="radio" className='ml-3' name="floorplanAttachment" value={false} checked={!rfpInputField.floorplanAttachment} onChange={inputsRfpHandler} />
                    </div>
                  </div>
                </div>

                <div className="col-md-12 ml-5">
                  <div className="form-group row">
                    <div className='col-sm-2 mt-1'><b>Exhibhition Guidelines</b></div>
                    <div className='col-sm-2'><TextareaAutosize
                      rows={1} // You can adjust the number of visible rows
                      cols={100}
                      name='guidelines'
                      // You can adjust the number of visible columns
                      class="form-control"
                      autofocus
                      value={rfpInputField.guidelines}
                      onChange={inputsRfpHandler} disabled={true}
                    /></div>
                    <div className='col-sm-2 '>
                      <div className='btn btn-sm ml-4 btn-info' onClick={() => externalLink(rfpInputField.guidelines)} >Exhibhition Guidelines</div>
                    </div>
                    <div className='col-sm-3'>
                      <input type="radio" className='ml-3' name="exhibitionAttachment" value={true} checked={rfpInputField.exhibitionAttachment} onChange={inputsRfpHandler} />
                    </div>
                    <div className='col-sm-3'>
                      <input type="radio" className='ml-3' name="exhibitionAttachment" value={false} checked={!rfpInputField.exhibitionAttachment} onChange={inputsRfpHandler} />
                    </div>
                  </div>
                </div>

                <div className="col-md-12 ml-5">
                  <div className="form-group row">
                    <div className='col-sm-2 mt-1'><b>Brand Guidelines</b></div>
                    <div className='col-sm-2 '><TextareaAutosize
                      rows={1} // You can adjust the number of visible rows
                      cols={100}
                      name='brandguidelines'
                      // You can adjust the number of visible columns
                      class="form-control"
                      autofocus
                      value={rfpInputField.brandguidelines}
                      onChange={inputsRfpHandler} disabled={true}
                    /></div>
                    <div className='col-sm-2 '>
                      <div className='btn btn-sm ml-4 btn-info' onClick={() => externalLink(rfpInputField.brandguidelines)} > Brand Guidelines </div>
                    </div>
                    <div className='col-sm-3'>
                      <input type="radio" className='ml-3' name="brandAttachemnt" value={true} checked={rfpInputField.brandAttachemnt} onChange={inputsRfpHandler} />
                    </div>
                    <div className='col-sm-3'>
                      <input type="radio" className='ml-3' name="brandAttachemnt" value={false} checked={!rfpInputField.brandAttachemnt} onChange={inputsRfpHandler} />
                    </div>
                  </div>
                </div>

                <div className="col-md-12 ml-5">
                  <div className="form-group row">
                    <div className='col-sm-2 mt-1'><b>Brief Link</b></div>
                    <div className='col-sm-2 '><TextareaAutosize
                      rows={1} // You can adjust the number of visible rows
                      cols={100}
                      name='contentLink'
                      // You can adjust the number of visible columns
                      class="form-control size1"
                      autofocus
                      value={rfpInputField.contentLink}
                      onChange={inputsRfpHandler} disabled={true}
                    /></div>
                    <div className='col-sm-2 '>
                      <div className='btn btn-sm ml-4 btn-info' onClick={() => externalLink(rfpInputField.contentLink)} >Open Brief Link </div>
                    </div>
                    <div className='col-sm-3'>
                      <input type="radio" className='ml-3' name="briefLinkAttachment" value={true} checked={rfpInputField.briefLinkAttachment} onChange={inputsRfpHandler} />
                    </div>
                    <div className='col-sm-3'>
                      <input type="radio" className='ml-3' name="briefLinkAttachment" value={false} checked={!rfpInputField.briefLinkAttachment} onChange={inputsRfpHandler} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            {/* 3rd part */}
            <div className="card-body" style={{ height: "auto" }}>
              <div className="row text-dark">
                <div className='col-md-5 ml-5'>

                  <h5>Product display</h5>
                  <div className="form-group row">
                    <div className='col-sm-12'><b>Number of Products to Display If Any</b></div>
                    <div className='col-sm-12'><input type="text" className="form-control form-control-user"
                      name="noofProduct" onChange={inputsRfpHandler} disabled={true} value={rfpInputField.noofProduct} /></div>
                  </div>
                  <h6>Note : We need product LXWXH with drawing and photo if possible., you can attached product photo and drawing seperately</h6>

                  <div className="form-group row">
                    <div className='col-sm-12'><b>Your Purpose</b></div>
                    <div className='col-sm-12'><TextareaAutosize
                      rows={3} // You can adjust the number of visible rows
                      cols={100}
                      name='yourpurpose'
                      class="form-control"
                      autofocus
                      onChange={inputsRfpHandler} disabled={true}
                      value={rfpInputField.yourpurpose}
                    /></div>
                  </div>
                  <div className="form-group row">
                    <div className='col-sm-12 -label'><b>Description If Any</b></div>
                    <div className='col-sm-12'><TextareaAutosize
                      rows={2} // You can adjust the number of visible rows
                      cols={100}
                      name='description'
                      class="form-control"
                      autofocus
                      onChange={inputsRfpHandler} disabled={true}
                      value={rfpInputField.description}
                    /></div>
                  </div>

                  <div className="form-group row">
                    {apiSharedResponse && (
                      <div className="alert alert-success col-sm-7" role="alert">
                        RFP has been Shared successfully!
                      </div>
                    )}

                    {/* {(userId === "25" || userId === "53") && <button className="btn btn-primary text-uppercase non-printable mt-2" type='submit'>Update</button>} */}
                    {getLoginUserData().deptId !== "3" && <button type="button" class="btn btn-primary ml-1 mt-2 non-printable" data-toggle="modal" data-target="#exampleModalCenter">
                      <i class="fa fa-share-alt mr-1"></i>share RFP
                    </button>}

                    <div class="modal fade " id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                      <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Share RFP</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <Select
                              name="assigneeid"
                              isMulti={true}
                              options={accountManager.map((user) => ({
                                value: user.id,
                                label: `${user.firstname} ${user.lastname}`,
                                email: user.email,
                              }))}
                              value={selectedRFPAssignees.value}
                              onChange={handleSelectRFPUserChange}
                              onClick={() => setShowAssigneePopup(false)}
                            />
                          </div>

                          <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <div class="btn-group">
                              <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Share
                              </button>
                              <div class="dropdown-menu">
                                {/* <button type='button' class="dropdown-item" onClick={shareData} data-dismiss="modal">Share Only</button> */}
                                {(getLoginUserData().deptId === "2" || getLoginUserData().deptId === "4") && <button type='button' class="dropdown-item" onClick={() => { shareData(); autoTaskCreation(); }} data-dismiss="modal">Share and Create Task</button>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {showAssigneePopup && (
                      <>
                        <Select
                          name="assigneeid"
                          isMulti={true}
                          placeholder="Enter members to share with"
                          options={dropdownUser.map((user) => ({
                            value: user.id,
                            label: `${user.firstname} ${user.lastname}`,
                          }))}
                          value={selectedRFPAssignees.value}
                          onChange={handleSelectRFPUserChange}
                          onClick={() => setShowAssigneePopup(false)}
                        />
                        <button className="close-popup-btn" onClick={() => setShowAssigneePopup(false)}>
                          Close popup
                        </button>
                      </>
                    )}
                  </div>
                </div>
                <div className='col-md-1'></div>
                <div className='col-md-5'>
                  <div className="form-group row">
                    <div className='col-sm-2 mt-2'><b>Area</b></div>
                    <div className='col-sm-10'><input type="text" className="form-control form-control-user" onChange={inputsRfpHandler} disabled={true}
                      name="area" value={rfpInputField.area} /></div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-2 mt-2'><b>Stall No</b></div>
                    <div className='col-sm-10'><input type="text" className="form-control form-control-user" onChange={inputsRfpHandler} disabled={true}
                      name="stallNo" value={rfpInputField.stallNo} /></div>
                  </div>
                  <div className="form-group row">
                    <div className='col-sm-2 mt-2'><b>Hall No.</b></div>
                    <div className='col-sm-10'><input type="text" className="form-control form-control-user" onChange={inputsRfpHandler} disabled={true}
                      name="hallNo" value={rfpInputField.hallNo} /></div>
                  </div>

                  <hr />

                  <div className="form-group row" hidden={true}>
                    <div className='col-sm-12 -label'><b>Fax</b></div>
                    <div className='col-sm-12'><input type="text" className="form-control form-control-user" onChange={inputsRfpHandler} disabled={true}
                      name="fax" value={rfpInputField.fax} /></div>
                  </div>

                  <div className="form-group row" hidden={true}>
                    <div className='col-sm-12 required-label'><b>Billing Address</b></div>
                    <div className='col-sm-12'><TextareaAutosize
                      rows={2} // You can adjust the number of visible rows
                      cols={100}
                      name='billingAddress'
                      class="form-control"
                      value={"testBillingAddress"}
                      autofocus

                    /></div>
                  </div>
                  <div className="form-group row">
                    <div className='col-sm-12 '><b>Any Specific Need</b></div>
                    <div className='col-sm-12'><TextareaAutosize
                      rows={2} // You can adjust the number of visible rows
                      cols={100}
                      name='anySpecific'
                      // You can adjust the number of visible columns
                      class="form-control"
                      autofocus
                      onChange={inputsRfpHandler} disabled={true}
                      value={rfpInputField.anySpecific}
                    /></div>
                  </div>


                  <div className="form-group row">
                    <div className='col-sm-12 '><b>Design Refrence</b></div>
                    <div className='col-sm-12'><TextareaAutosize
                      rows={1} // You can adjust the number of visible rows
                      cols={100}
                      name='designRefrence'
                      // You can adjust the number of visible columns
                      class="form-control"
                      autofocus
                      onChange={inputsRfpHandler} disabled={true}
                      value={rfpInputField.designRefrence}
                    /></div>
                  </div>

                  <h5>People</h5>
                  <div className="form-group form-group-h row">
                    <div className='col-sm-3'>Author - </div>
                    <div className='col-sm-7'>
                      <b>{rfpInputField.createdByName}</b>
                    </div>
                  </div>
                  <div className="form-group form-group-h row">
                    <div className="col-sm-3">Share with</div>
                    <div className="col-sm-9">
                      {rfpInputField.rfpShareList
                        ? [...new Set(rfpInputField.rfpShareList.map(item => item.asigneeName))]
                          .map((name, index) => (
                            <b key={index}>{name}, </b>
                          ))
                        : null
                      }
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </form>

          {(rfpInputField.finalLink !== null && rfpInputField.finalLink?.trim() !== '') && <>
            <hr />
            {/* 4th part */}
            <div className="card-body" style={{ height: "auto" }}>
              <div className="row text-dark">
                <div className='col-md-5 ml-5'>
                  {/* <div className="form-group row">
                    <div className='col-sm-12'><b>Final Design Link</b></div>
                    <div className='col-sm-12'><TextareaAutosize
                      rows={1} 
                      cols={100}
                      name='finalLink'
                      className="form-control"
                      autofocus
                      onChange={inputsRfpHandler}
                      value={rfpInputField.finalLink}
                      disabled={true}
                    /></div>
                  </div>
                  <div className="form-group row">
                    <div className='btn btn-sm ml-3 btn-info' onClick={() => externalLink(rfpInputField.finalLink)} >Open FinalDesign </div>
                  </div> */}

                  <div className="form-group row">
                    <div className='col-sm-12'><b>Final Design Link</b></div>
                    <div className="form-group d-flex flex-column">
                      {
                        rfpInputField.allfiles?.map((e, index) => (
                          <div className="m-1" key={index}>
                            <div className='btn btn-sm  btn-success' type="submit" onClick={() => { handleDownload(e.fileName) }} ><i class="fa-regular fa-file-pdf mr-1"></i>{e.fileName.slice(0,-4)}</div>
                          </div>
                        ))}

                    </div>
                  </div>

                </div>
                <div className="col-md-1"></div>
                <div className="col-md-5">
                  <div className="form-group row">
                    <div className='col-sm-4 '><b>Converted To Project</b></div>
                    <div className='col-sm-7'>
                      <input className="form-check-input" type="checkbox" checked={rfpInputField.isConvertedProject} value="" id="flexCheckDefault" />
                    </div>

                    <div className='col-sm-4 mt-2 '><b>Final Designer Name-</b></div>
                    <div className='col-sm-6 mt-2'>
                      <b>{rfpInputField.finalDesignerName}</b>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>}

        </div>

        {/* discussion */}
        <div className="card shadow mb-4 non-printable">
          <div className="card-header py-3">
            <div className="row">
              <div className="col-sm-9">
                <div className="col-sm-6"></div>
                <h3>
                  Discussion
                </h3>

              </div>
            </div>
          </div>

          <div className="card-header py-3">
            {apiResponseComment && (
              <div className="alert alert-success" role="alert">
                Comment Done successfully..!!
              </div>
            )}
            <div className="row d-flex flex-column">
              <div class="card-body" style={{ display: "flex" }}>
                <img class="rounded-circle shadow-1-strong mt-3 me-3"
                  src="https://w7.pngwing.com/pngs/184/113/png-transparent-user-profile-computer-icons-profile-heroes-black-silhouette-thumbnail.png" alt="avatar" width="45"
                  height="45" />
                <form onSubmit={submitDiscussion} className='card-body' style={{ display: "flex" }}>

                  <div className='col-sm-11' style={{ width: "200px" }}>
                    <MentionsInput className="form-control  form-control-user "
                      id="comment"
                      name="comments"
                      style={defaultStyle}

                      // value={commentInput.comments}
                      value={inputDiscussion.comments}
                      onChange={(e) => setInputDiscussion({ ...inputDiscussion, comments: e.target.value })}>
                      <Mention data={userNameData} style={defaultMentionStyle} />
                    </MentionsInput>
                  </div>

                  <div className='col-sm-0'>
                    <button type='submit' className="btn btn-primary mr-3">comment</button>
                  </div>
                </form>
                <div className='col-sm-0'></div>
              </div>
              <div style={{ width: "100%" }}>
                {comments.map((e) => (

                  <div class="card-body mt-1 card-body1">
                    <div class="d-flex flex-start justify-content-between align-items-center">
                      <div className='d-flex' style={{ width: "100%", maxHeight: "50px", overflowY: "auto" }}>

                        <img class="rounded-circle shadow-1-strong me-3"
                          src="https://w7.pngwing.com/pngs/184/113/png-transparent-user-profile-computer-icons-profile-heroes-black-silhouette-thumbnail.png" alt="avatar" width="45"
                          height="45" />
                        <p class="mb-0 mt-2 pl-3 pb-1" dangerouslySetInnerHTML={{ __html: e.comments }} />
                        {/* <p class="mb-0 mt-2 pl-3 pb-1">
                        {e.comments}
                      </p> */}
                      </div>
                      <div class="small d-flex flex-row-reverse justify-content-start pr-5">
                        <div className='d-flex'>
                          <p class="text-muted font-weight-bold  ml-2 ">
                            Date-{formatDateTime(e.createdDate)}
                          </p>
                          <h6 class="font-weight-bold text-primary ml-2 ">{e.userName} </h6>
                        </div>
                      </div>
                    </div>

                  </div>
                ))}
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default RFPDetailsNewComponent