import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import BoqListComponent from '../../../Components/Main/BoqComponent/BoqListComponent';

function BoqList(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <BoqListComponent {...props} navigate={navigate}/>
        </div>
    );  
  }
export default BoqList 