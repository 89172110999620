const name = sessionStorage.getItem('name');
const deptId = sessionStorage.getItem('DeptId');
const role = sessionStorage.getItem('role');
const userId = sessionStorage.getItem('id')
// current date in db format
const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}

// human readable date for db entry
function formatDate(date) {
    if (!date) return ""; // Handle null or undefined dates
    const localDate = new Date(date);
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, '0');
    const day = String(localDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

function getCurrentDateTimeForDB() {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    // const seconds = now.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
}

// human readable from db for user 
const formatDateTime = (cell, row) => {
    // Format the date and time here, assuming 'time' is a string in ISO 8601 format
    const formattedDate = new Date(cell).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',

    });
    return formattedDate;
}


const getLoginUserData = () => {
    //userid null or 0 or undefined

     if (userId !== undefined || userId !== "0" || userId !== null) {

        const data = {
            name: name,
            deptId: deptId,
            role: role,
            userId: userId,
        }

        return data;
     }
    else {
        alert("there is some issue please re-login " );
        window.location.href = "/";
        sessionStorage.clear();
    }

}

const externalLink = (data) => {

    window.open(data, '_blank')
}

// filtre repeated data with unique id 
function filterWithUniqueIds(tasks) {
    // Create an object to store unique tasks based on their ID
    const uniqueTasks = tasks.reduce((acc, task) => {
        // Use the task ID as the key in the object
        const taskId = task.id;

        // If the task ID is not in the object, add it
        if (!acc[taskId]) {
            acc[taskId] = task;
        }

        return acc;
    }, {});

    // Convert the uniqueTasks object back to an array
    const uniqueTasksArray = Object.values(uniqueTasks);

    return uniqueTasksArray;
}

const hasLink = (text) => {
    // Regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Replace URLs with clickable links
    return text.replace(urlRegex, (match) => {
        return `<a href="${match}" target="_blank" rel="noopener noreferrer">${match}</a>`;
    });
};

export {
    getCurrentDate,
    getLoginUserData,
    externalLink,
    formatDate,
    formatDateTime,
    hasLink,
    filterWithUniqueIds,
    getCurrentDateTimeForDB
}