import React from 'react'
import { Link } from 'react-router-dom';

const DashboardOperationsOptionComponent = (props) => {

    const role = sessionStorage.getItem("role");
    const staticid = 0;
    const staticname2 = "Content creation- email campaign";
    const staticname3 = "Content creation-creativity and design";
    const staticname4 = "Content creation- Linkedin campaign ";
    const staticname5 = "Website Page";
    const staticName17 = "Request";
    const backbutton = () => {
        props.navigate(-1);
    }

    // Define an array of buttons for each role
    const roleButtons = [

        {
            role: "Admin",
            buttons: [
                
                { label: "Create Multi Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMultiTaskMarketing" },
                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },

                { label: "Create Team Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingMarketing" },
                { label: " My Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                // { label: "Create Content Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname1}` },
                { label: "Upcoming Shows List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminShowList" },
                { label: "Create Lead", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/ShareLead" },
                { label: "Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SharedLeadList  " },
                { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/DashboardLead" },

                // { label: "Create Email Writing Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname2}` },
                // { label: "Email-Content Writing Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname2}` },


                // { label: "Create Creativity & Design Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname3}` },
                // { label: " Creativity & Design Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname3}` },

                //{ label: "Create LinkedIn Notes Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname4}` },
                // { label: "LinkedIn Notes Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname4}` },

                //  { label: "Create Landing Page Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname5}` },
                // { label: "Website Page Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname5}` },
                { label: "PPT List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/PPTList" },
                { label: "Attach PPT ", className: "btn btn-danger btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/CreatePPT" },
            ]
        },

        {
            role: "Managing Director(MD)",
            buttons: [
                
                 { label: "Create Multi Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateMultiTask/${"Marketing"}` },
                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },

                { label: "Create Team Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingMarketing" },
                { label: " My Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                // { label: "Create Content Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname1}` },
                { label: "Upcoming Shows List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminShowList" },
                { label: "Create Lead", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/ShareLead" },
                { label: "Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SharedLeadList  " },
                { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/DashboardLead" },

                // { label: "Create Email Writing Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname2}` },
                // { label: "Email-Content Writing Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname2}` },


                // { label: "Create Creativity & Design Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname3}` },
                // { label: " Creativity & Design Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname3}` },

                //{ label: "Create LinkedIn Notes Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname4}` },
                // { label: "LinkedIn Notes Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname4}` },

                //  { label: "Create Landing Page Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname5}` },
                // { label: "Website Page Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname5}` },
                { label: "PPT List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/PPTList" },
                { label: "Attach PPT ", className: "btn btn-danger btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/CreatePPT" },
            ]
        },
        //operations
        {
            role: "Assitant Manager - Process",
            buttons: [
                
                { label: "Create Multi Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMultiTaskMarketing" },
                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },

                { label: "Create Team Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingMarketing" },
                { label: " My Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                // { label: "Create Content Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname1}` },
                { label: "Upcoming Shows List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminShowList" },
                { label: "Create Lead", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/ShareLead" },
                { label: "Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SharedLeadList  " },
                { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/DashboardLead" },

                // { label: "Create Email Writing Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname2}` },
                // { label: "Email-Content Writing Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname2}` },


                // { label: "Create Creativity & Design Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname3}` },
                // { label: " Creativity & Design Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname3}` },

                //{ label: "Create LinkedIn Notes Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname4}` },
                // { label: "LinkedIn Notes Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname4}` },

                //  { label: "Create Landing Page Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname5}` },
                // { label: "Website Page Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname5}` },
                { label: "PPT List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/PPTList" },
                { label: "Attach PPT ", className: "btn btn-danger btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/CreatePPT" },
            ]
        },

        {
            role: "Executive - Software Developer(SOP Tool)",
            buttons: [
                
                { label: "Create Multi Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMultiTaskMarketing" },
                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },

                { label: "Create Team Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingMarketing" },
                { label: " My Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                // { label: "Create Content Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname1}` },
                { label: "Upcoming Shows List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminShowList" },
                { label: "Create Lead", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/ShareLead" },
                { label: "Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SharedLeadList  " },
                { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/DashboardLead" },

                // { label: "Create Email Writing Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname2}` },
                // { label: "Email-Content Writing Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname2}` },


                // { label: "Create Creativity & Design Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname3}` },
                // { label: " Creativity & Design Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname3}` },

                //{ label: "Create LinkedIn Notes Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname4}` },
                // { label: "LinkedIn Notes Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname4}` },

                //  { label: "Create Landing Page Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname5}` },
                // { label: "Website Page Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname5}` },
                { label: "PPT List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/PPTList" },
                { label: "Attach PPT ", className: "btn btn-danger btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/CreatePPT" },
            ]
        },


        {
            role: "GM-Commercial",
            buttons: [
                
                { label: "Create Multi Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMultiTaskMarketing" },
                { label: "Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticName17}` },
                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },

                { label: "Create Team Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingMarketing" },
                { label: " My Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                // { label: "Create Content Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname1}` },
                { label: "Upcoming Shows List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminShowList" },
                { label: "Create Lead", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/ShareLead" },
                { label: "Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SharedLeadList  " },
                { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/DashboardLead" },

                // { label: "Create Email Writing Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname2}` },
                // { label: "Email-Content Writing Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname2}` },


                // { label: "Create Creativity & Design Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname3}` },
                // { label: " Creativity & Design Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname3}` },

                //{ label: "Create LinkedIn Notes Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname4}` },
                // { label: "LinkedIn Notes Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname4}` },

                //  { label: "Create Landing Page Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: `/CreateSpecificTask/${staticid}&${staticname5}` },
                // { label: "Website Page Review List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/SpecificTaskList/${staticid}&${staticname5}` },
                { label: "PPT List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/PPTList" },
                { label: "Attach PPT ", className: "btn btn-danger btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/CreatePPT" },
            ]
        }
    ];
    return (
        <div>
            <div className="container-fluid">
                <div className="card shadow border-success mb-2">
                    <div className="card-header rounded border-success py-3">
                        <div className="row">
                            <div className="d-flex flex-start rounded align-items-center">
                                
                            </div>
                            <div className="col-sm-3"><h2><b className='text-dark'>Dashboard</b></h2></div>
                            <div className="col-sm-5"></div>
                            <div className="col-sm-4 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="card cardUser shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-success">Dashboard</h6>
                    </div>
                    <div className="container" style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                        <div className="row">
                            {roleButtons.map((roleButtonGroup, index) => (
                                (roleButtonGroup.role === role) && (
                                    roleButtonGroup.buttons.map((button, buttonIndex) => (
                                        <div key={buttonIndex} className="col-xl-4 col-md-8">
                                            <div className="card border-success shadow p-1 mt-3 ml-3">
                                                <div className="btn-group">
                                                    <Link to={button.to} className={button.className} type="button">
                                                        {button.label}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )
                            ))}
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default DashboardOperationsOptionComponent