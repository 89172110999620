import React, { useEffect, useState } from 'react'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Service from '../../../Services/Admin/Service';
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { Link } from 'react-router-dom';

const CreateApprovalListComponent = (props) => {
  let id = sessionStorage.getItem('id');

  const [projectList, setProjectList] = useState([]);
  const [detailFlag, setDetailFlag] = useState(false);
  const [approvalType, setApprovalType] = useState('');
  const [projectError, setProjectError] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [inputField, setInputField] = useState({
    id: 0,
    projectId: '',
    typeId: '',
    material: "",
    createdId: 0,
    updatedBy: id,
  })

  useEffect(() => {
    getProjectData("Project/GetAll");
    var d = props.name;
    if (d === "details") {
      setDetailFlag(true);
    }

    if (props.id !== 0 && props.id !== undefined) {
      getApprovalDetails("ApporvalList/GetById?id=" + props.id, '');
    }

  }, [])

  const getProjectData = (method) => {
    Service.getdata(method).then((res) => {
      setProjectList(res.data);
    });
  }
  const getApprovalDetails = (method) => {
    Service.getdata(method).then((res) => {
      setInputField(res.data);

      if (res.data.typeId === 1) {
        setApprovalType("Show Approval");
      }

      else if (res.data.typeId === 2) {
        setApprovalType("Client Approval");
      }

      else if (res.data.typeId === 3) {
        setApprovalType("Supplier Approval");
      }

      // setProjectId(res.data.projectId)
      // console.log(res.data)
      debugger
    });
  }

  useEffect(() => {
    if (apiResponse || apiError) {
      const timeoutId = setTimeout(removeSuccessMessage, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [apiResponse, apiError]);



  const removeSuccessMessage = () => {
    setApiResponse(null);
    setApiError(null);
  };

  const inputsHandler = (e) => {
    //  setInputField( {[e.target.name]: e.target.value} )
    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleInputType = (e) => {
    setApprovalType(e.target.value);
  }

  const save = (evt) => {

    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }
    const formData = new FormData(evt.target);

    const data = {}
    for (const [key, value] of formData) {
      data[key] = value;
    }

    function validateData(data) {
      const { material, projectId, typeId } = data;
      debugger

      if (!projectId || projectId === "[NONE]") {
        setProjectError(true);
        return false;
      } else {
        setProjectError(false);
      }
      return true;
    }

    if (!validateData(data)) {

      return;
    }

    if (data.typeId === "Show Approval") {
      data.typeId = 1;
    }

    else if (data.typeId === "Client Approval") {
      data.typeId = 2;
    }

    else if (data.typeId === "Supplier Approval") {
      data.typeId = 3;
    }


    const task = {
      ...inputField,
      ...data,
    };
    console.log(task)
    debugger
    if (task) {
      Service.createorupdate("ApporvalList/CreateOrUpdate", task)
        .then(res => {
          setApiResponse(res.data);
          // window.location.href = "/ApprovalList"
        })
        .catch(error => {
          // Handle error if API call fails
          console.error("API Error:", error);
          setApiError("Error occurred while saving the material. Please try again later.");

        });
    }

  }

  const backbutton = () => {
    props.navigate(-1);
  }

  return (
    <div>
      <div className="container-fluid">

        <div className="card shadow border-success mb-2">
          <div className="card-header rounded py-3">
            <div className="row">
              <div class="d-flex flex-start align-items-center">

              </div>
              <div className="col-sm-4"><h2><b>Material Approval List</b></h2></div>
              <div className="col-sm-5"></div>
              <div className="col-sm-3 d-flex flex-row-reverse">
                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2">Home</Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="card cardUser shadow mb-4" >
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-success">Approval List</h6>
          </div>

          <div class="container">
            <form onSubmit={save} class="form-horizontal mt-2">


              <div className="form-group ">
                <div className='col-sm-11 required-label'>Project</div>
                <div className='col-sm-11'>
                  {projectError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a project.</div>}
                  <select className="form-control  form-control-user" readOnly={detailFlag} onChange={inputsHandler} value={inputField.projectId} name="projectId" required>
                    <option>[NONE]</option>
                    {
                      projectList.map((e, key) => {
                        //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;
                        return <option key={e.id} value={e.id}>{e.projectname}</option>;
                      })}
                  </select>
                </div>
              </div>

              <div className="form-group ">
                <div className='col-sm-11 required-label'>Approval Type</div>
                <div className='col-sm-11'>
                  <select className="form-control  form-control-user" disabled={detailFlag} readOnly={detailFlag} value={approvalType} onChange={handleInputType} name="typeId" required>
                    <option>[NONE]</option>
                    <option>Show Approval</option>
                    <option>Client Approval</option>
                    <option>Supplier Approval</option>

                  </select>
                </div>
              </div>

              <div className="form-group ">
                <div className='col-sm-11'> Approval List</div>
                <div className='col-sm-11'>
                  <input type="text" className="form-control form-control-user" readOnly={detailFlag} id="name" onChange={inputsHandler} value={inputField.material} name="material" placeholder='enter material name' />
                </div>
              </div>

              <div className="form-group ">
                <div className='col-sm-11 required-label'>Approval Link</div>
                <div className='col-sm-11'>
                  <textarea
                    rows={1} // You can adjust the number of visible rows
                    cols={100}
                    name='approvalLink'
                    placeholder='enter approval Link' // You can adjust the number of visible columns
                    class="form-control"
                    value={inputField.approvalLink}
                    autofocus
                    required
                    onChange={inputsHandler}
                  />
                </div>
              </div>
              {apiResponse && (
                <div className="alert alert-success ml-2 ml-11" role="alert">
                  material approval has been created successfully!
                </div>
              )}
              {apiError && (
                <div className="alert alert-danger ml-2 ml-11" role="alert">
                  {apiError}
                </div>
              )}
              {detailFlag === false && <button type="submit" class="btn btn-primary ml-3 mt-3 mb-3">Save</button>}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateApprovalListComponent