import Service from "../../../Services/Admin/Service";
import React, { useEffect, useState } from "react";
import KanbanViewCss from "./KanbanViewComponent.css";
import { Link } from "react-router-dom";


const KanbanMyArchievedTaskComponent = (props) => {

    const deptId = sessionStorage.getItem("DeptId");
    let userid = sessionStorage.getItem('id')
    const [archiveData, setarchiveData] = useState([]);
    const [show, setShow] = useState([]);
    const [Projectdata, setProjectdata] = useState([]);
    const [doneData, setdoneData] = useState([]);
    const [lead, setLead] = useState([]);

    useEffect(() => {
        getStatusData("Status/GetAll", 0);
        getShowData("Shows/GetAllShows");
        getLeadData("Lead/GetAll");
        getProjectData("Project/GetAll");
    }, [])

    const getStatusData = (method, selectedProjectId) => {
        //debugger;
        Service.getdata(method).then((res) => {
            var currentdata = res.data;
            currentdata.forEach(element => {
                getTaskData("Task/GetALLArchiveTask?userid=" + userid + "&statusid=" + element.statusId, element.statusId, selectedProjectId);
            });
        });

    };

    const getShowData = (method) => {
        Service.getdata(method).then((res) => {
            setShow(res.data);
        });
    }

    const getLeadData = (method) => {
        Service.getdata(method).then((res) => {
            setLead(res.data);
        });
    }

    const getProjectData = (method) => {
        Service.getdata(method).then((res) => {
            setProjectdata(res.data);
        });
    }




    function filterTasksWithUniqueIds(tasks) {
        // Create an object to store unique tasks based on their ID
        const uniqueTasks = tasks.reduce((acc, task) => {
            // Use the task ID as the key in the object
            const taskId = task.id;

            // If the task ID is not in the object, add it
            if (!acc[taskId]) {
                acc[taskId] = task;
            }

            return acc;
        }, {});

        // Convert the uniqueTasks object back to an array
        const uniqueTasksArray = Object.values(uniqueTasks);

        return uniqueTasksArray;
    }

    function formatDate(dateString) {
        const dateObject = new Date(dateString);

        const day = String(dateObject.getDate()).padStart(2, '0');
        const month = String(dateObject.getMonth() + 1).padStart(2, '0');
        const year = dateObject.getFullYear();

        return `${day}-${month}-${year}`;
    }

    const getPriorityColor = (priority) => {
        switch (priority.toLowerCase()) {
            case "low":
                return "	#0096FF";
            case "medium":
                return "#32CD32";
            case "high":
                return "#ff0000";
            default:
                return "black";
        }
    };

    const handleProjectChange = (evt) => {

        var projectId = parseInt(evt.target.value);
        debugger


        if (projectId !== 0) {

            var achieve = archiveData.filter(x => x.projectId === projectId)


            if (achieve.length === 0) {
                getStatusData("Status/GetAll", projectId)


            }
            else {
                setarchiveData(achieve);

            }

        }
        else {
            getStatusData("Status/GetAll", 0)
        }

    }

    const handleLeadChange = (evt) => {

        var leadId = parseInt(evt.target.value);
        debugger


        if (leadId !== 0) {


            var achieve = archiveData.filter(x => x.leadid === leadId)


            if (achieve.length === 0) {
                getStatusData("Status/GetAll", leadId)
            }
            else {
                setarchiveData(achieve);

            }

        }
        else {
            getStatusData("Status/GetAll", 0)
        }

    }

    const handleShowChange = (evt) => {

        var showId = parseInt(evt.target.value);
        debugger


        if (showId !== 0) {

            var achieve = archiveData.filter(x => x.projectId === showId)


            if (achieve.length === 0) {
                getStatusData("Status/GetAll", showId)
            }
            else {
                setarchiveData(achieve);

            }

        }
        else {
            getStatusData("Status/GetAll", 0)
        }

    }

    // const getTaskData = (method, statusid) => {

    //     Service.getdata(method).then((res) => {
    //         const task = filterTasksWithUniqueIds(res.data)
    //         // debugger
    //         // if (statusid === 4) {
    //         //     console.log("Task Data", (task))
    //         //     debugger
    //         // }
    //         setarchiveData(task);

    //     });
    // }


    const getTaskData = (method, statusid, selectedProjectId) => {
        Service.getdata(method).then((res) => {

            setdoneData(res.data);
            debugger
            const task = filterTasksWithUniqueIds(res.data)
            if (statusid === 4) {
                if (selectedProjectId === 0) {

                    setarchiveData(task);
                    console.log("Task Data", (task))
                }
                else {
                    var b = task.filter(x => x.projectId === selectedProjectId || x.leadid === selectedProjectId || x.showId === selectedProjectId)
                    setarchiveData(b);
                }

            }

        });
    }

    const backbutton = () => {
        props.navigate(-1);
    }


    return (
        <div className="container-fluid">
            <div className="card shadow border-success mb-4">
                <div className="card-header rounded py-3">
                    <div className="row">
                        <div className="col-sm-8 mt-1"><h3><b>My Archieved Task</b></h3></div>
                        {/* <div className="col-sm-6">
                    <select name="" id="" className="btn btn-primary ">
                        <option disabled selected value="" className="btn btn-light">Task Type</option>
                        <option className="btn btn-light" value="MyTask">My Task</option>
                        <option className="btn btn-light" value="MyTask">Assigned Task</option>
                    </select>
                </div> */}
                        <div className="col-sm-4 d-flex flex-row-reverse">
                            <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                            {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="card shadow border-success mb-4">
                <div className="card-header rounded py-3">
                    <div className="row">
                        <div className="col-sm-4"><h3>Project Archieved Task</h3></div>
                        <div className="col-sm-2"></div>

                        <div className="col-sm-6">

                            {
                                deptId === "3" || deptId === "4" ? (
                                    <div className="  d-flex ">
                                        <div className="col-sm-3 required-label">Project</div>
                                        <div className="col-sm-9">
                                            <select
                                                className="form-control form-control-fs "
                                                readOnly
                                                onChange={handleProjectChange}
                                                name="projectId"

                                            >
                                                <option value="0" >[ ALL ]</option>
                                                {Projectdata.map((e, key) => (
                                                    <option key={e.id} value={e.id}>
                                                        {e.projectname}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                ) : null
                            }

                            {deptId === "1" ?

                                <div className="d-flex">
                                    <div className='col-sm-3 required-label'>Show Name</div>
                                    <div className='col-sm-9'>

                                        <select
                                            className="form-control form-control-fs"
                                            readOnly name='showId'
                                            required
                                            onChange={handleShowChange}
                                        >
                                            <option value="0">[ ALL ]</option>
                                            {
                                                show.map((e, key) => {
                                                    return <option key={e.showChapterId} value={e.showChapterId}>{e.chapterName}</option>;
                                                })}
                                        </select>
                                    </div>
                                </div>
                                :
                                null
                            }
                            {deptId === "2" ? (
                                <div className="d-flex">
                                    <div className='col-sm-3 required-label'>Lead Name</div>
                                    <div className='col-sm-9'>

                                        <select
                                            className="form-control form-control-fs"
                                            readOnly
                                            name='leadId'
                                            required
                                            onChange={handleLeadChange}
                                        >
                                            <option value="0">[ ALL ]</option>
                                            {
                                                lead.map((e, key) => {
                                                    return <option key={e.id} value={e.id}>{e.leadid}</option>;
                                                })}
                                        </select>
                                    </div>
                                </div>
                            ) : null}

                        </div>
                    </div>
                </div>
            </div>


            <div className="card shadow mb-4 ">
                <div className="container-fluid ">
                    <div className="row py-3">
                        <div className="col-sm-12 ">
                            <div className=""><h4>{archiveData.length}</h4></div>
                            <div className="">Archieved Tasks</div>
                            <div className="d-flex flex-wrap">
                                {archiveData.map((item) => (
                                    <div class="items col-sm-4 bd-highlight border border-light">
                                        <Link className=" link-no-underline ml-3 mb-2 btn-sm" to={`/TaskDetails/${item.id}`}>
                                            <div class="card draggable shadow-sm" id="cd1" draggable="true" ondragstart="drag(event)">
                                                <div class="card-body p-2 bg-dark text-white">
                                                    <div class="card-title">
                                                        <div class="lead font-weight-light">Task - #{item.id}</div>
                                                        <div class="lead font-weight-light"></div>
                                                    </div>
                                                    <p>
                                                        {item.headline}
                                                    </p>
                                                    <div className="row">
                                                        <div className="col-md-7">
                                                            <p>Date- {formatDate(item.finishdate)}</p>
                                                        </div>
                                                        <div className="col-md-3 ">
                                                            {/* Apply inline style based on priority */}
                                                            <div className="priority" style={{ fontSize: "21px", fontWeight: "600", color: getPriorityColor(item.priority), textAlign: "right" }}>
                                                                {item.priority}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                        {/* <div class="dropzone rounded" ondrop="drop(event)" ondragover="allowDrop(event)" ondragleave="clearDrop(event)"> &nbsp; </div> */}
                                    </div>

                                ))}
                            </div>
                        </div>

                    </div>
                </div>


            </div>
        </div>
    )
}

export default KanbanMyArchievedTaskComponent