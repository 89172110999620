import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import EditUserSheetComponent from '../../../Components/Main/DesignerMasterComponent/EditUserSheetComponent';

function EditUserSheet(props) {

    let navigate = useNavigate();
    const params = useParams();
    return (
        <div>
            <EditUserSheetComponent {...props} navigate={navigate} name={params.name} id={params.id} />
        </div>
    )
}
export default EditUserSheet