import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Service from '../../../Services/Admin/Service';

const CreateUnloadingMaterialComponent = (props) => {

  let id = sessionStorage.getItem('id');

  const [apiResponse, setApiResponse] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [projectError, setProjectError] = useState(false);
  const [project, setProject] = useState([]);
  const [inputField, setInputField] = useState(
    {
      projectid: "",
      materialName: "",
      details: "",
      createdby: 0,
    }
  )

  useEffect(() => {
    getProjectData("Project/GetAll");
  }, [])

  const getProjectData = (method) => {
    Service.getdata(method).then((res) => {
      setProject(res.data);
    });
  }


  useEffect(() => {
    if (apiResponse || apiError) {
      const timeoutId = setTimeout(removeSuccessMessage, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [apiResponse, apiError]);

  const removeSuccessMessage = () => {
    setApiResponse(null);
    setApiError(null);
  };

  const backbutton = () => {
    props.navigate(-1);
  }

  const save = async (evt) => {
    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }

    const formData = new FormData(evt.target);

    const data = {}
    for (const [key, value] of formData) {
      data[key] = value;
    }

    function validateData(data) {
      const { projectid, details, materialName } = data;

      if (!materialName || !details) {
        alert("Please fill in all required fields.");
        return;
      }

      if (!projectid || projectid === "[NONE]") {
        setProjectError(true);
        return false;
      } else {
        setProjectError(false);
      }
      return true
    }

    if (!validateData(data)) {
      return;
    }

    const task = {
      ...inputField,
      ...data,
    };
    console.log(task)
    debugger
    if (task) {

      Service.createorupdate("UnloadingMaterial/CreateOrUpdate", task)
        .then(res => {
          setApiResponse(res.data); // Set the API response in state
        })
        .catch(error => {
          // Handle error if API call fails
          console.error("API Error:", error);
          setApiError("Error occurred while saving the meeting. Please try again later.");
        });
    }
  }

  return (
    <div>
      <div className="container-fluid">

        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <div className="row">
              <div class="d-flex flex-start align-items-center">

              </div>
              <div className="col-sm-4"><h2>Create Unloading Material</h2></div>
              <div className="col-sm-5"></div>
              <div className="col-sm-3 d-flex flex-row-reverse">
                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="card cardUser shadow mb-4" >
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">Add Unloading Material</h6>
            {apiResponse && (
              <div className="alert alert-success" role="alert">
                Unloading Material has been created successfully!
              </div>
            )}
            {apiError && (
              <div className="alert alert-danger" role="alert">
                {apiError}
              </div>
            )}
          </div>

          <div class="container">
            <form onSubmit={save} class="form-horizontal mt-2">

              <div className="form-group ">
                <div className='col-sm-9 required-label'>Project</div>
                <div className='col-sm-9'>
                  {projectError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a project.</div>}
                  <select className="form-control  form-control-user" name="projectid" required>
                    <option>[NONE]</option>
                    {
                      project.map((e, key) => {
                        return <option key={e.id} value={e.id}>{e.projectname}</option>;
                      })}
                  </select>
                </div>
              </div>

              <div class="form-group">
                <label for="materialName" class="col-sm-3 required-label  control-label">Material Name</label>
                <div class="col-sm-9">
                  <input type="text" id="materialName" name='materialName' placeholder="Material Name" required class="form-control" autofocus />
                </div>
              </div>

              <div class="form-group">
                <label for="details" class="col-sm-3 required-label  control-label">Details</label>
                <div class="col-sm-9">
                  <input type="text" id="details" name='details' placeholder="Details" required class="form-control" autofocus />
                </div>
              </div>

              <button type="submit" class="btn btn-primary ml-3 mb-3">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateUnloadingMaterialComponent