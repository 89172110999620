import React, { Component } from 'react' 
import { useNavigate } from 'react-router-dom';
import PermitPassesListComponent from '../../../Components/Main/PermitAndPassesComponent/PermitPassesListComponent';

function PermitPasessList(props) {
    let navigate = useNavigate();
    return (  
        <div> 
            <PermitPassesListComponent {...props} navigate={navigate} />
        </div>
    );  
  }
  
export default PermitPasessList  