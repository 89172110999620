import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import UserSheetListComponent from '../../../Components/Main/DesignerMasterComponent/UserSheetListComponent';

function UserSheetList(props) {

    let navigate = useNavigate();
    const params = useParams();
    return (
        <div>
            <UserSheetListComponent {...props} navigate={navigate} params={params.name}/>
        </div>
    )
}
export default UserSheetList