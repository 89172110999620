import React, { Component } from 'react' 
import { useNavigate, useParams } from 'react-router-dom';
import SitePhotosAndVideoComponent from '../../../Components/Main/PermitAndPassesComponent/SitePhotosAndVideoComponent';

function SitePhotosAndVideo(props) {
    let navigate = useNavigate();
    const params = useParams();
    return (  
        <div> 
            <SitePhotosAndVideoComponent {...props} navigate={navigate} id={params.id} name = {params.name}/>
        </div>
    );  
  }
  
export default SitePhotosAndVideo  