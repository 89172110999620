import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import Service from '../../../Services/Admin/Service';
import paginationFactory from 'react-bootstrap-table2-paginator';

const CostingListComponent = (props) => {

    const [data, setData] = useState([]);

    
  
    useEffect(() => {
      getProjectDetails("Project/GetAll");
    }, [])
  
    const getProjectDetails = (method) => {
      Service.getdata(method).then((res) => {
        setData(res.data);
  
      });
  
    }
  
    const backbutton = () => {
      props.navigate(-1);
    }
  
 
  
    const linkformatter = (data, row) => {
      return <>  <Link className="btn btn-warning btn-sm" to={`/CreateSelectVendor/${data}`}>Edit</Link>
        &nbsp; <Link className="btn btn-warning btn-sm" to={`/SelectVendorDetails/${data}`}>Details</Link>
      </>
    }
  
    const column = [
      { dataField: 'supplierName', text: 'Supplier Name', sort: true },
      { dataField: 'cost', text: 'Cost', sort: true },
      { dataField: 'id', text: 'Actions', formatter: linkformatter }
    ]
  
  return (
    <div>
      <div className="container-fluid">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <div className="row">
              <div className="col-sm-4"><h3>Selected Vendor List</h3></div>
              <div className="col-sm-6"></div>
              <div className="col-sm-2 d-flex flex-row-reverse">
                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">Vendor List</h6>
          </div>

          <div className="card-body">
            <div className="table-responsive">
              <BootstrapTable keyField="id"
                data={data}
                columns={column}
                pagination={paginationFactory()}
              />
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-primary ml-3 mb-3">Save</button>
      </div>
    </div>
  )
}

export default CostingListComponent