import { useState, useEffect } from "react";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Link } from 'react-router-dom';
import Service from "../../../Services/Admin/Service";
import { getCurrentDate, getLoginUserData } from "../../../Helpers/supportedFunctions/supportedGlobalFunctions";

function ProjectListComponent(props) {

    const userId = sessionStorage.getItem("id")
    const [data, setData] = useState([]);
    var role = sessionStorage.getItem("role");
    var deptId = sessionStorage.getItem("DeptId");

    useEffect(() => {
        getCategoryData("Project/GetAllByUserId?id=" + userId);
        // getCategoryData("Project/GetAll");
    }, [])

    const getCategoryData = (method) => {
        Service.getdata(method).then((res) => {
            var result = filterTasksWithUniqueIds(res.data)
            debugger
            if(props.name==="CompletedProjects"){
                var complete = result.filter((x)=>x.enddate <= getCurrentDate())
                setData(complete);
                debugger;
            }
            else{
                setData(result);

            }
        });
    }

    const staticname1 = "details";
    const staticname2 = "edit";


    function filterTasksWithUniqueIds(tasks) {
        // Create an object to store unique tasks based on their ID
        const uniqueTasks = tasks.reduce((acc, task) => {
            // Use the task ID as the key in the object
            const taskId = task.id;

            // If the task ID is not in the object, add it
            if (!acc[taskId]) {
                acc[taskId] = task;
            }

            return acc;
        }, {});

        // Convert the uniqueTasks object back to an array
        const uniqueTasksArray = Object.values(uniqueTasks);

        return uniqueTasksArray;
    }


    const backbutton = () => {
        props.navigate(-1);
    }


    const formatDateTime = (cell, row) => {
        // Format the date and time here, assuming 'time' is a string in ISO 8601 format
        const formattedDate = new Date(cell).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            // hour: '2-digit',
            // minute: '2-digit',
            // second: '2-digit',
        });
        return formattedDate;
    }
    const linkformatter = (data, row) => {
        debugger
        return <>
            {/* <Link className="btn btn-warning btn-sm" to={`/AddProject/${data}`}><i className="fas fa-edit"></i></Link> */}
            &nbsp; <Link className="btn btn-warning btn-sm m-1" to={`/AddProject/${data}&${staticname1}`}>Details</Link>
            {
                role === "Admin" || row.createdby === parseInt(getLoginUserData().userId) || userId === "3" ?
                    <Link className="btn btn-warning btn-sm m-1" to={`/AddProject/${data}`}>Edit</Link>
                    :
                    null
            }

            {/* <Link className="btn btn-warning btn-sm" to={`/AddProject/${data}`}>Edit</Link> */}

        </>
    }

    const column = [
        { dataField: 'projectname', text: 'Project Name', sort: true },
        { dataField: 'leadid', text: 'Lead Name', sort: true },
        { dataField: 'startdate', text: 'Start Date', sort: true, formatter: formatDateTime },
        { dataField: 'enddate', text: 'End Date', formatter: formatDateTime },
        { dataField: 'id', text: 'Actions', formatter: linkformatter }
    ]

    return (
        <div>
            <div className="container-fluid">

                <div className="card border-success shadow mb-2">
                    <div className="card-header  rounded py-3">
                        <div className="row">
                            <div className="col-sm-6 mt-1"><h3>{props.name==="CompletedProjects"?<b className="text-dark">Completed Projects</b>:<b className="text-dark">Projects</b>}</h3></div>
                            <div className="col-sm-3"></div>
                            <div className="col-sm-3 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-2  text-uppercase " onClick={() => backbutton()}>back</button>
                                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-success">Details Of Projects</h6>
                        {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
                    </div>

                    <div className="card-body ">
                        <div className="table-responsive">
                            <BootstrapTable keyField="id"
                                data={data}
                                rowStyle={{color:"black"}}
                                columns={column}
                                pagination={paginationFactory()}
                            // filter={filterFactory()}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default ProjectListComponent