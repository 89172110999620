import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import UserCalendarComponent from '../../../Components/Main/CalendarComponent/userCalendarComponent';

function UserCalendar(props){
     let navigate = useNavigate();
     const params = useParams();
     
     return (  
         <div> 
             <UserCalendarComponent {...props}  navigate={navigate}  name={params.name} />
         </div>
     );   

}

export default UserCalendar