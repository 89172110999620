import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import CustomerService from '../../../Services/Admin/Service';
import { getLoginUserData } from '../../../Helpers/supportedFunctions/supportedGlobalFunctions';
import './testing.css';  // Ensure this path is correct

const Testing2Component = () => {
    const [uploadProgress, setUploadProgress] = useState(0);
    const [files, setFiles] = useState([]);
    const [uploadError, setUploadError] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [inputFileData, setInputFileData] = useState({
        typeId: 1,
        fileType: "Task",
        fileName: "",
        createdby: getLoginUserData().userId,
    });

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles);
            setUploadError(null);
            setUploadSuccess(false);
        }
    });

    const handleUpload = async () => {
        if (files.length === 0) {
            setUploadError('No files selected for upload');
            return;
        }

        const formData = new FormData();
        files.forEach(file => {
            formData.append('files', file);
        });

        const data = {
            ...inputFileData,
            fileName: files[0].name,
        };

        try {
            const response = await CustomerService.createorupdateFile("FileUpload/UploadFile", formData, (progress) => {
                setUploadProgress(progress);
            });

            if (response.status === 200) {
                const updateResponse = await CustomerService.createorupdate("FileUpload/CreateOrUpdate", data);
                if (updateResponse.status !== undefined) {
                    alert("File uploaded successfully");
                    setUploadSuccess(true);
                } else {
                    alert("File uploaded successfully but table not created");
                }
            } else {
                alert("Lead not created due to data mismatch, try again later");
            }
        } catch (error) {
            console.error("API Error during file upload:", error);
            setUploadError("File upload failed");
        }
    };

    return (
        <div>
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            {files.length > 0 && (
                <div>
                    <h4>Selected files:</h4>
                    <ul>
                        {files.map(file => (
                            <li key={file.name}>{file.name}</li>
                        ))}
                    </ul>
                </div>
            )}
            <button onClick={handleUpload}>Upload</button>
            {uploadProgress>0?<>{uploadProgress}%</>:null}
            {uploadProgress > 0 && (
                <div>
                    <progress value={uploadProgress} max="100">{uploadProgress}%</progress>
                </div>
            )}
            {uploadError && <div className="error">{uploadError}</div>}
            {uploadSuccess && <div className="success">Files uploaded successfully!</div>}
        </div>
    );
};

export default Testing2Component;
