import React from 'react'
import ProfileCss from "./index.css"
import { useState } from 'react'
import { useEffect } from 'react'
import Service from '../../../Services/Admin/Service'
import { Link } from 'react-router-dom'

const ProfileComponent = (props) => {

    const userName = sessionStorage.getItem("name");
    const userId = sessionStorage.getItem("id");
    const role = sessionStorage.getItem("role");
    const [apiResponseComment, setapiResponseComment] = useState(null);
    const [apiError, setApiError] = useState(null);
    const email = sessionStorage.getItem("email");
    const deptId = sessionStorage.getItem("DeptId");

    const [deptName, setDeptName] = useState("")
    const [deptLst, setDeptLst] = useState([])
    const [inputField, setInputField] = useState([{
        id: userId,
        password: "",
        updatedby: userId,
        updatedName: userName,
    }])
    const [password, setPassword] = useState("")

    useEffect(() => {
        if (deptId === "2") {
            setDeptName("Sales");
        }
        else if (deptId === "3") {
            setDeptName("Design")
        }
        else if (deptId === "4") {
            setDeptName("Operations");
        }
        else if (deptId === "5") {
            setDeptName("Accounts");
        }
        else if (deptId === "1") {
            setDeptName("Admin");
        }
        getUserData("Users/GetById?id=" + userId);

    }, [])

    useEffect(() => {
        if (apiResponseComment) {
            const timeoutId = setTimeout(removeSuccessMessage, 3000);

            return () => clearTimeout(timeoutId);
        }
    }, [apiResponseComment]);

    const getUserData = (method) => {
        Service.getdata(method).then((res) => {
            setInputField(res.data);
            console.log(res.data)
            setPassword(res.data.password)
            const deptlst = res.data.lstRole.map((idx) => idx.id);
            setDeptLst(deptlst);
            debugger
            // mentionData(res.data);

        });
    }


    const removeSuccessMessage = () => {
        setapiResponseComment(null);
        setApiError(null);
    };

    const handlePasswordChange = (evt) => {
        setPassword(evt.target.value);
    };

    const handleFormSubmit = (evt) => {
        evt.preventDefault();
       
      
        const task = {
            ...inputField,
            password: password,
             lstRole: deptLst,
        };

        console.log(task);
        debugger

        if (task) {
            Service.createorupdate("Users/CreateOrUpdate", task)
                .then(res => {
                    setapiResponseComment(res.data); // Set the API response in state

                })
                .catch(error => {
                    setApiError(error)// Handle error if API call fails
                    console.error("API Error:", error);
                });
        }
        // Your form submission logic here
    };


    const backbutton = () => {
        props.navigate(-1);
    }

    return (
        <div class="page-content page-container" id="page-content">
        <div className="card shadow mb-1">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col-sm-4"><h3 className='ml-2 font-weight-bold'>Profile</h3>
                            </div>
                            <div className="col-sm-6"></div>
                            <div className="col-sm-2 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                    {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link> */}
                            </div>

                        </div>
                    </div>
                </div>
            <div class="padding">
                {apiResponseComment && (
                    <div className="alert alert-success" role="alert">
                        Password Change successfully..!!
                    </div>
                )}
                {/* <div class="row container d-flex justify-content-center"> */}
                {/* <div class="col-xl-6 col-md-12"> */}
                <div class="card user-card-full">
                    <div class="row m-l-0 m-r-0">
                        <div class="col-sm-4 bg-c-lite-green user-profile">
                            <div class="card-block text-center text-white mt-3">
                                <div class="m-b-25">
                                    <img src="https://source.unsplash.com/random" height={"100px"} width={"100px"} className="img-radius rounded-circle" alt="User-Profile" />
                                </div>
                                <h6 class="f-w-600 h2">{userName}</h6>
                                {/* <p className='h3'>{deptName}</p> */}
                                <i class=" mdi mdi-square-edit-outline feather icon-edit m-t-10 f-16"></i>
                            </div>
                        </div>
                        <div class="col-sm-8">
                            <div class="card-block">
                                <h6 class="m-b-20 p-b-5 b-b-default f-w-600">Profile Information</h6>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <p class="m-b-10 f-w-600 h4">Name</p>
                                        <h6 class="text-muted f-w-400 h5">{userName}</h6>
                                    </div>
                                    <div class="col-sm-6">
                                        <p class="m-b-10 f-w-600 h4">Department</p>
                                        <h6 class="text-muted f-w-400 h5">{deptName}</h6>
                                    </div>
                                </div>
                                <span className="col-sm-2"></span>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <p class="m-b-10 f-w-600 h4">Email</p>
                                        <h6 class="text-muted f-w-400 h5">{email}</h6>
                                    </div>
                                    <div class="col-sm-6">
                                        <p class="m-b-10 f-w-600 h4">Designation</p>
                                        <h6 class="text-muted f-w-400 h5">{role}</h6>
                                    </div>
                                </div>

                                <div class="row">
                                    <span type="button" class="btn btn-info ml-3" data-toggle="modal" data-target="#exampleModalCenter">
                                        Change Password
                                    </span>
                                </div>
                                <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLongTitle">New Password</h5>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <form onSubmit={handleFormSubmit} action="submit">
                                                    <div className='col-sm-7'>

                                                        {/* <label>New Password</label> */}
                                                        <input type="text" className="form-control form-control-user" id="headline"
                                                            onChange={handlePasswordChange} name="password" placeholder="Enter New Password" required />
                                                    </div>
                                                </form>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                                <button type="submit" onClick={handleFormSubmit} data-dismiss="modal" class="btn btn-primary">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                {/* <ul class="social-link list-unstyled m-t-40 m-b-10"> */}
                                {/* <li><a href="#!" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="facebook" data-abc="true"><i class="mdi mdi-facebook feather icon-facebook facebook" aria-hidden="true"></i></a></li> */}
                                {/* <li><a href="#!" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="twitter" data-abc="true"><i class="mdi mdi-twitter feather icon-twitter twitter" aria-hidden="true"></i></a></li> */}
                                {/* <li><a href="#!" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="instagram" data-abc="true"><i class="mdi mdi-instagram feather icon-instagram instagram" aria-hidden="true"></i></a></li> */}
                                {/* </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
                {/* </div> */}
            </div>
        </div>
    )
}

export default ProfileComponent