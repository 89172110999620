import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import AgreementIsSharedComponent from '../../../Components/Main/AgreementComponent/AgreementIsSharedComponent';

function AgreementIsShared(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <AgreementIsSharedComponent {...props} navigate={navigate} id={params.id} />
        </div>
    );  
  }
export default AgreementIsShared  