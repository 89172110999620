
import React, { useEffect, useState } from 'react'
import { convertToRaw, EditorState, convertFromHTML, ContentState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Service from '../../../Services/Admin/Service';
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import draftToHtml from 'draftjs-to-html';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { getCurrentDateTimeForDB } from '../../../Helpers/supportedFunctions/supportedGlobalFunctions';


const CreateMeetingMarketingComponent = (props) => {
    const [loading, setLoading] = useState(false);
    let id = sessionStorage.getItem('id');
    var role = sessionStorage.getItem("role")
    const senderName = sessionStorage.getItem("name");


    const [meetingTime, setMeetingTime] = useState('');
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const [show, setShow] = useState([]);
    const [showId, setShowId] = useState([]);
    const [project, setProject] = useState([]);
    const [projectId, setProjectId] = useState([]);
    const [location, setLocation] = useState('');
    const [kind, setKind] = useState('');
    const [topic, setTopic] = useState('');
    const [meetingDetails, setMeetingDetails] = useState([]);
    const [editorContentValid, setEditorContentValid] = useState(true);
    const [projectError, setProjectError] = useState(false);
    const [dropdownUser, setDropdownUser] = useState([]);
    const [apiResponse, setApiResponse] = useState(null);
    const [apiError, setApiError] = useState(null);
    const [meetingMember, setMeetingMember] = useState([]);
    const [selectedAssignees, setSelectedAssignees] = useState([]);
    const [selectedLastUsers, setSelectedLastUsers] = useState([]);
    const [memberError, setMemberError] = useState(false);
    const [showError, setShowError] = useState(null);
    const [leadData, setLeadData] = useState([]);
    const [meetingtype, setmeetingtype] = useState([]);
    const [meetingtypeid, setmeetingtypeid] = useState('');
    const [agendahide, setagendahide] = useState('none');
    const [showid, setshowid] = useState('');
    const [inputField, setInputField] = useState({
        id: 0,
        topic: "",
        projectId: 0,
        leadid: 0,
        showid: '',
        meetingMinutes: "",
        Meetingtypeid: 0,
        lstmeetingMembers: [0],
        location: "",
        kind: "",
        time: "",
        createdby: id,
        updatedBy: id,
    })

    function getCurrentDate() {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const day = now.getDate().toString().padStart(2, '0');
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}`;
    }

    const [inputQuestionare, setInputQuestionare] = useState({
        id: 0,
        taskid: "0",
        description: "",
        senderid: 0,
        senderName: "",
        receiverid: 0,
        receiverName: "",
        isRead: false,
        createdby: id,
    })

    const [listuser, setlistuser] = useState({
        value: 0,
        label: "",

    })

    const [inputmultiFields, setinputmultiFields] = useState([{
        agenda: '',
        decision: '',
        responsiblity: '',
        targetdate: null,
    }]);

    const addInputField = () => {
        setinputmultiFields([...inputmultiFields, {
            agenda: '',
            decision: '',
            responsiblity: '',
            targetdate: '',
        }])

    }
    useEffect(() => {
        getLeadDataDetails("Lead/GetAll"); getProjectData("Project/GetAll");
        getShowData("Shows/GetAllShows");
        getMeetingTypeData("Meeting/GetAllMeetingType");
        getUser("Users/GetAll")
        if (props.id !== 0 && props.id !== undefined) {
            getMeetingbyId("Meeting/GetById?id=" + props.id, '');
        }
    }, [])

    const getLeadDataDetails = (method) => {
        Service.getdata(method, '').then((res) => {
            setLeadData(res.data);
            // setLeadData(res.data.leadId)
            // console.log(res.data);

        });
    }

    const removeInputFields = (index) => {
        const rows = [...inputmultiFields];
        rows.splice(index, 1);
        setinputmultiFields(rows);
    }

    const handleChange = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputmultiFields];
        list[index][name] = value;
        setinputmultiFields(list);
    }



    const getProjectData = (method) => {
        Service.getdata(method).then((res) => {
            setProject(res.data);
        });
    }

    const getShowData = (method) => {
        Service.getdata(method).then((res) => {
            setShow(res.data);
        });
    }

    const getMeetingTypeData = (method) => {
        Service.getdata(method).then((res) => {
            setmeetingtype(res.data);
        });
    }

    const getMeetingbyId = (method) => {
        Service.getdatabyId(method, "").then((res) => {
            setMeetingDetails(res.data)
            console.log("meting", res.data);
            setagendahide("");
            setLocation(res.data.location);
            setKind(res.data.kind);
            setinputmultiFields(res.data.inputmultiFields);
            setInputField(res.data);
            setProjectId(res.data.projectId)
            setTopic(res.data.topic);
            setMeetingTime(res.data.time);
            setmeetingtypeid(res.data.meetingtypeid);
            setshowid(res.data.showid);
            var listofusers = res.data.lstmeetingMembers;
            var selecteduser = [];
            listofusers.forEach((d, index) => {
                debugger;
                var user = [];
                user.value = d.id;
                user.label = d.firstname + ' ' + d.lastname;
                selecteduser.push(user);

            });
            setSelectedLastUsers(selecteduser);
            const lstMember = res.data.lstmeetingMembers.map((member) => member.firstname + ' ' + member.lastname);
            setMeetingMember(lstMember.join(', '));

            const content1 = convertFromRaw(JSON.parse(res.data.meetingMinutes));
            setEditorState(EditorState.createWithContent(content1));

        });
    }


    const getUser = (method) => {
        Service.getdata(method).then((res) => {
            setDropdownUser(res.data);
        });
    }

    useEffect(() => {
        if (apiResponse || apiError) {
            const timeoutId = setTimeout(removeSuccessMessage, 3000);
            // window.location.href = "/MeetingList";
            return () => clearTimeout(timeoutId);
        }
    }, [apiResponse, apiError]);

    const removeSuccessMessage = () => {
        setApiResponse(null);
        setApiError(null);
    };

    const backbutton = () => {
        props.navigate(-1);
    }

    const meetingKindChange = (e) => {
        setKind(e.target.value);
    }

    const meetingLocationChange = (e) => {
        setLocation(e.target.value);
    }



    const handleEditorChange = (state) => {
        setEditorState(state);
        setEditorContentValid(true);
    }

    const topicChange = (e) => {
        setTopic(e.target.value);
    }
    const meetingTimeChange = (e) => {
        setMeetingTime(e.target.value);
    }
    const projectChange = (e) => {
        setProjectId(e.target.value);
    }

    const meetingtypeChange = (e) => {
        setmeetingtypeid(e.target.value);
    }

    const showChange = (e) => {
        setshowid(e.target.value);
    }

    const save = async (evt) => {
        if (evt && evt.preventDefault) {
            evt.preventDefault();
        }
        setLoading(true);
        const formData = new FormData(evt.target);

        const data = {}
        for (const [key, value] of formData) {
            data[key] = value;
        }

        function validateData(data) {

            const { topic, showid, time, lstmeetingMembers, kind } = data;

            if (!topic || !time || kind === "[NONE]") {
                alert("Please fill in all required fields.");
                return false;
            }

            const currentContent = editorState.getCurrentContent();
            if (!currentContent.hasText()) {
                setEditorContentValid(false);
                return; // Abort save
            }

            if (!showid || showid === "[NONE]") {
                setShowError(true);
                return false;
            } else {
                setShowError(false);
            }
            if (!lstmeetingMembers || lstmeetingMembers === "[NONE]") {
                setMemberError(true);
                return false;
            } else {
                setMemberError(false);
            }
            return true;
        }

        if (!validateData(data)) {
            // The validation returned false, so stop execution here.
            // You can add any specific handling for invalid data here, if needed.
            return;
        }

        const currentContent = editorState.getCurrentContent();
        const messageBody = convertToRaw(currentContent);
        var jsonData = JSON.stringify(messageBody);
        // const loadData = JSON.parse(jsonData);
        // var content=loadData.blocks[0].text;
        let currentContentAsHTML = draftToHtml(messageBody);
        //  inputField.ContentJosn = jsonData;
        inputField.meetingMinutes = jsonData;
        var lstmeetingMembers = [];

        var currentUser = parseInt(id)
        var checkUser = selectedLastUsers.find(x => x.value === currentUser)
        if (checkUser === undefined) {
            lstmeetingMembers = [...selectedLastUsers.map((assignee) => assignee.value), id]
        }
        else {
            // lstmeetingMembers= [selectedLastUsers.map((assignee) => assignee.value)]
            selectedLastUsers.forEach((lst, index) => {
                lstmeetingMembers.push(lst.value);
            })

        }

        const task = {
            ...inputField,
            ...data,
            inputmultiFields,
            lstmeetingMembers,
        };
        console.log(task);
        debugger


        // const data1 = []
        // for (var i = 0; i < selectedAssignees.length; i++) {

        //     const task1 = {
        //         ...inputQuestionare,

        //         taskid: 1,
        //         description: "Meeting is assigned to you",
        //         receiverid: selectedAssignees[i],

        //         // receiverName: taskDetail.taskassignuserdetails[i].assigneeName,
        //         receiverName: selectedLastUsers[i].label,
        //         senderid: id,
        //         senderName: senderName,
        //     };
        //     data1.push(task1)
        // }
        // // for notification
        // console.log(data1)
        // debugger

        if (task) {
            Service.createorupdate("Meeting/CreateOrUpdate", task)
                .then(res => {
                    if(res.status === 200) {
                        setApiResponse(res.data);
                        setLoading(false);
                    }
                    // if (data1) {
                    //     for (var m = 0; m <= data1.length; m++) {
                    //         console.log(data1[m]);
                    //         debugger
                    //         Service.createorupdate("Questionare/CreateOrUpdate", data1[m])
                    //             .then(res => {
                    //                 setApiResponse(res.data);
                    //             })
                    //             .catch(error => {

                    //                 console.error("API Error:", error);
                    //             });
                    //     }
                    // } // Set the API response in state
                    window.location.href = "/MeetingList";
                })
                .catch(error => {
                    // Handle error if API call fails
                    console.error("API Error:", error);
                    setApiError("Error occurred while saving the meeting. Please try again later.");
                });
        }
    }

    function extractValuesFromIndexedObject(indexedObject) {
        return Object.values(indexedObject).map(item => item.value);
    }

    const handleSelectChange = (selectedOptions) => {
        const extractedValues = extractValuesFromIndexedObject(selectedOptions);
        setSelectedAssignees(extractedValues);
        setSelectedLastUsers(selectedOptions);
    };

    return (
        <div>
            <div className="container-fluid">

                <div className="card shadow border-success mb-2">
                    <div className="card-header rounded py-3">
                        <div className="row">
                           
                            <div className="col-sm-7"><h2><b className='text-dark'>Create Meeting Marketing</b></h2></div>
                            <div className="col-sm-3"></div>
                            <div className="col-sm-2 d-flex flex-row-reverse">
                                <button className="btn btn-info  ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="card cardUser shadow mb-4" >
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-success">Add Meeting</h6>
                        {apiResponse && (
                            <div className="alert alert-success" role="alert">
                                Meeting has been created successfully!
                            </div>
                        )}
                        {apiError && (
                            <div className="alert alert-danger" role="alert">
                                {apiError}
                            </div>
                        )}
                    </div>

                    <div class="container">
                        <form onSubmit={save} class="form-horizontal text-dark mt-2">
                            <div class="form-group">
                                <label for="field" class="col-sm-3 required-label  control-label"><b>Topic</b></label>
                                <div class="col-sm-11">
                                    <input type="text" id="topic" name='topic' placeholder="Topic" value={topic} onChange={topicChange} required class="form-control" autoFocus />
                                </div>
                            </div>
                            <div className="form-group">
                                <label
                                    htmlFor="Meeting Time"
                                    className="col-sm-3 required-label control-label"
                                >
                                    <b>Meeting Time</b>
                                </label>
                                <div className="col-sm-11">
                                    <input
                                        type="datetime-local"
                                        id="meetingTime"
                                        name="time"
                                        value={meetingTime}
                                        onChange={meetingTimeChange}
                                        min={getCurrentDateTimeForDB()}
                                        required
                                        className="form-control"
                                    />
                                </div>
                            </div>


                            <div className="form-group row">

                                <div className="col-sm-12 required-label ml-3"><b>Meeting Minutes</b></div>
                                <div className='col-sm-11 ml-3'>

                                    {!editorContentValid && (
                                        <div className="alert alert-danger ml-3 pl-2 mt-2" role="alert">
                                            Meeting Minutes are required.
                                        </div>
                                    )}
                                    <Editor
                                        editorState={editorState}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        onEditorStateChange={handleEditorChange}
                                        required
                                    />
                                </div>

                            </div>
                            <div className="form-group ">
                                <div className='col-sm-11 required-label'><b>Meeting Type</b></div>
                                <div className='col-sm-11'>
                                    {projectError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a Meeting Type.</div>}
                                    <select className="form-control  form-control-user" value={meetingtypeid} onChange={meetingtypeChange} name="meetingtypeid" required>
                                        <option>[NONE]</option>
                                        {
                                            meetingtype.map((e, key) => {
                                                //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;
                                                return <option key={e.id} value={e.id}>{e.typeName}</option>;
                                            })}
                                    </select>
                                </div>
                            </div>



                            <div className="form-group ">
                                <div className='col-sm-11 required-label'><b>Show List</b></div>
                                <div className='col-sm-11'>
                                    {showError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a Show.</div>}
                                    <select className="form-control  form-control-user" name="showid" value={showid} onChange={showChange} required>
                                        <option>[NONE]</option>
                                        {
                                            show.map((e, key) => {
                                                return <option key={e.showChapterId} value={e.showChapterId}>{e.chapterName}</option>;
                                            })}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-sm-11 required-label"><b>List Meeting Members</b></div>
                                <div className="col-sm-11">
                                    {memberError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a member.</div>}
                                    <Select
                                        name="lstmeetingMembers"
                                        isMulti={true}
                                        placeholder="Enter meeting members"
                                        options={dropdownUser.map((user) => ({
                                            value: user.id,
                                            label: `${user.firstname} ${user.lastname}`,
                                        }))}
                                        value={selectedLastUsers}
                                        onChange={handleSelectChange}
                                    />
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="field" class="col-sm-3   control-label"><b>Location</b></label>
                                <div class="col-sm-11">
                                    <input type="text" id="location" name='location' placeholder=" enter location" value={location} onChange={meetingLocationChange} class="form-control" autoFocus />
                                </div>
                            </div>

                            <div className="form-group ">
                                <div className='col-sm-11 required-label'><b>Meeting Kind</b></div>
                                <div className='col-sm-11'>
                                    {projectError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a Meeting Type.</div>}
                                    <select className="form-control  form-control-user" value={kind} onChange={meetingKindChange} name="kind" required>
                                        <option>[NONE]</option>
                                        <option>Virtual</option>
                                        <option>In person</option>

                                    </select>
                                </div>
                            </div>


                            <div style={{ display: agendahide }}>
                                <div className="form-group row">
                                    <div className="col-sm-12  ml-3"><b>Agenda Topic of discussion</b></div>
                                </div>
                                {
                                    inputmultiFields.map((data, index) => {
                                        const { agenda, decision, responsiblity, targetdate } = data;
                                        const formattedDate = targetdate ? targetdate.split('T')[0] : '';


                                        return (
                                            <div>

                                                <div className="form-group row" key={index}>

                                                    <div className='col-sm-3'>
                                                        <input type="text" onChange={(evnt) => handleChange(index, evnt)} value={agenda} name="agenda" className="form-control" placeholder="Enter Agenda" />
                                                    </div>

                                                    <>

                                                        <div className='col-sm-2'>
                                                            <input type="text" onChange={(evnt) => handleChange(index, evnt)} value={decision} name="decision" className="form-control" placeholder="Enter decision" />

                                                        </div>
                                                        <div className='col-sm-3'>
                                                            <input type="text" onChange={(evnt) => handleChange(index, evnt)} value={responsiblity} name="responsiblity" className="form-control" placeholder="Enter responsiblity" />
                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <input type="date" onChange={(evnt) => handleChange(index, evnt)} value={formattedDate || ''} name="targetdate" min={getCurrentDate()} className="form-control" placeholder="targetdate" />
                                                        </div>
                                                        <div className='col-sm-1'>
                                                            <span className="btn btn-outline-success " onClick={addInputField}>Add</span>
                                                        </div>
                                                        <div className="col">
                                                            {(inputmultiFields.length !== 1) ? <span className="btn btn-outline-danger" onClick={removeInputFields}>x</span> : ''}

                                                        </div>
                                                    </>



                                                </div>

                                            </div>
                                        )
                                    })
                                }

                                {/* <div className="row">
                    <div className="col-sm-12">
                    <button className="btn btn-outline-success " onClick={addInputField}>Add New</button>
                    </div>
               
                </div> */}
                            </div>
                            <div className="col-sm-4">
                            </div>
                            {apiResponse && (
                                <div className="alert alert-success" role="alert">
                                    Meeting has been created successfully!
                                </div>
                            )}
                            {apiError && (
                                <div className="alert alert-danger" role="alert">
                                    {apiError}
                                </div>
                            )}
                            {loading === false && <h6 className="font-weight-bold text-primary">{ }</h6>}{loading === true &&
                                <div class=" text-primary">
                                    <strong>Saving...</strong>
                                    <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                                </div>
                            }
                            <button type="submit" class="btn btn-primary ml-3 mt-3 ">Save</button>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateMeetingMarketingComponent