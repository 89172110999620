import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import VendorListComponent from '../../../Components/Main/VendorComponent/VendorListComponent';


function VendorList(props) {
    let navigate = useNavigate();
    
    return (  
        <div> 
            <VendorListComponent {...props} navigate={navigate}  />
        </div>
    );  
  }
export default VendorList