import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import ContentAttachmentListComponent from '../../../Components/Main/AttachmentComponent/ContentAttachmentListComponent';

function ContentAttachmentList(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <ContentAttachmentListComponent {...props} navigate={navigate} id={params.id}  />
        </div>
    );  
  }
export default ContentAttachmentList  