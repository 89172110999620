import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Service from '../../../Services/Admin/Service';

const SummarySheetListComponent = (props) => {
    const [summaryData, setSummaryData] = useState([]);

    useEffect(() => {
        getSummayData("DesignerTimesheet/GetAll");
    }, [])

    const getSummayData = (method) => {
        Service.getdata(method).then((res) => {
            setSummaryData(res.data);
        })
    }

    const backbutton = () => {
        props.navigate(-1);
    }
    return (
        <div>
            <div className="container-fluid">

                <div className="card shadow border-success mb-2">
                    <div className="card-header rounded py-3">
                        <div className="row">
                            <div className="col-sm-7 row">
                                <h3 className='mt-2'><b className='text-dark'>Designer Summary Sheet</b></h3>
                            </div>
                            <div className="col-sm-1"></div>
                            <div className="col-sm-4 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
                            </div>

                        </div>
                    </div>
                </div>

                <div className="card shadow mb-4">
                    <div className="card-header d-flex py-3">
                        <h6 className="m-0 mt-1 font-weight-bold text-success ">Summary Sheet</h6>
                    </div>

                    <form style={{ overflowX: "auto", overflowY: "auto" }}>
                        <div className=" d-flex ml-3">
                            <div className="col-sm-3 " style={{ color: "black", backgroundColor:"#1cc88a" }}><b>Designer Name</b></div>
                            <div className="col-sm-3 " style={{ color: "black", backgroundColor:"#1cc88a"   }}><b>Projects Done</b></div>
                            <div className="col-sm-3 d-flex justify-content-center " style={{ color: "black", backgroundColor:"#1cc88a"   }}><b>Total Working Hours</b></div>
                            <div className="col-sm-3  " style={{ color: "black", backgroundColor:"#1cc88a"   }}><b>Conversion Rate</b></div>
                            <div className="col-sm-3 " style={{ color: "black", backgroundColor:"#1cc88a"   }}><b>Averagescore (1 to 10)( K.A.M)</b></div>
                        </div>
                        <div className="card-body">
                            {
                                summaryData.map((e) => {
                                    return (
                                        <div className="row  mb-1">
                                            <div className=" d-flex" style={{ maxWidth: "100%" }}>
                                                <div className="col-sm-3">
                                                    <div className="form-group row">
                                                        {/* <div className='col-sm-12 required-label'><b>RFP</b></div> */}
                                                        <div className='col-sm-12'>
                                                            <input type="text" className='form-control  mb-1 form-control-user' value={e.userName} readOnly />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group row">
                                                        {/* <div className='col-sm-12 required-label'><b>Show Name</b></div> */}
                                                        <div className='col-sm-12'>
                                                            <input type="text" className='form-control mb-1 form-control-user' value={e.projectCount} readOnly />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group row">
                                                        {/* <div className='col-sm-12 required-label'><b>Area</b></div> */}
                                                        <div className='col-sm-12'>
                                                            <input type="text" className='form-control  mb-1 form-control-user' value={e.workingHourTotal} readOnly />

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group row">
                                                        {/* <div className='col-sm-12 required-label'><b>Date</b></div> */}
                                                        <div className='col-sm-12'>
                                                            <input type="text" className='form-control  mb-1 form-control-user' value={e.conversionRate} readOnly />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group row">
                                                        {/* <div className='col-sm-12 required-label'><b>Option Made</b></div> */}

                                                        <div className='col-sm-12'>
                                                            <input type="text" className='form-control  mb-1 form-control-user' value={e.kam} readOnly />
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="col-md-1">
                                                    <div className="form-group row">
                                                        <div className='col-sm-12'>
                                                            <Link to={`/EditSummaryUser/${e.userId}&edit`} className="btn btn-info">Edit</Link>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SummarySheetListComponent