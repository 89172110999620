import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import TaskTypeListComponent from '../../../Components/Main/TaskTypeComponent/TaskTypeListComponent';

function TasktypeList(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <TaskTypeListComponent {...props} navigate={navigate}  />
        </div>
    );  
  }
export default TasktypeList  