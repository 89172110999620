import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import KanbanComponent from '../../../Components/Main/Task/KanbanComponent';

function Kanban(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <KanbanComponent {...props} navigate={navigate}  />
        </div>
    );  
  }
export default Kanban  