import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Service from '../../../Services/Admin/Service';

const SitePhotosAndVideoComponent = (props) => {
  const id = sessionStorage.getItem("id");
  const [detailFlag, setDetailFlag] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [project, setProject] = useState([]);
  const [projectId, setProjectId] = useState([]);
  const [projectError, setProjectError] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [inputField, setInputField] = useState(
    {
      id: props.id,
      projectid: '',
      status: "",
      createdBy: id,
      updatedby: 0,
      files: [],
      filedetails: []
    }
  )

  useEffect(() => {
    getProjectData("Project/GetAll");

    var d = props.name;
    if (d === "details") {
      setDetailFlag(true);
    }

    if (props.id !== 0 && props.id !== undefined) {
      getSitePhotosById("ConstructionStatus/GetById?id=" + props.id, '');
    }
  }, [])

  const getProjectData = (method) => {
    Service.getdata(method).then((res) => {
      setProject(res.data);
    });
  }

  const getSitePhotosById = (method) => {
    Service.getdata(method).then((res) => {
      setInputField(res.data);
    });
  }

  useEffect(() => {
    if (apiResponse || apiError) {
      const timeoutId = setTimeout(removeSuccessMessage, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [apiResponse, apiError]);

  const removeSuccessMessage = () => {
    setApiResponse(null);
    setApiError(null);
  };

  const backbutton = () => {
    props.navigate(-1);
  }


  const inputsHandler = (e) => {
    //  setInputField( {[e.target.name]: e.target.value} )
    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }


  const save = async (evt) => {
    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }

    const formData = new FormData(evt.target);

    const data = {}
    for (const [key, value] of formData) {
      data[key] = value;
    }

    const { projectid } = data;
    
    function validateData(data) {
      const {  projectid } = data;
      debugger

      if (!projectid || projectid === "[NONE]") {
          setProjectError(true);
          return false;
      } else {
          setProjectError(false);
      }

     

      // All conditions are met, so return true to proceed forward.
      return true;
  }

  if (!validateData(data)) {
      // The validation returned false, so stop execution here.
      // You can add any specific handling for invalid data here, if needed.
      return;
  }
    debugger
    const task = {
      ...inputField,
      ...data,
      files:[],
    };
    console.log(task);
    debugger
    if (task) {
      Service.createorupdate("ConstructionStatus/CreateOrUpdate", task)
        .then(res => {
          setApiResponse(res.data); // Set the API response in state
          window.location.href = "/SitePhotosAndVideosList";
        })
        .catch(error => {
          // Handle error if API call fails
          console.error("API Error:", error);
          setApiError("Error occurred while saving link. Please try again later.");
        });
    }
  }
  return (
    <div>
      <div className="container-fluid">

        <div className="card shadow mt-2 border-success mb-4">
          <div className="card-header rounded py-3">
            <div className="row">
              <div class="d-flex flex-start align-items-center">
                {/* <img class="shadow-1-strong mb-2 me-32"
                  src="https://w7.pngwing.com/pngs/163/964/png-transparent-contract-computer-icons-rental-agreement-others-miscellaneous-angle-text.png"
                  alt="avatar" width="50"
                  height="40" /> */}
              </div>
              <div className="col-sm-5"><h2><b className='text-dark'>Site Photos And Video</b></h2></div>

              <div className="col-sm-5"></div>
              <div className="col-sm-2 d-flex flex-row-reverse">
                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="card cardUser shadow mb-4" >
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-success">Add photos and Videos</h6>
            {apiResponse && (
              <div className="alert alert-success" role="alert">
                Photos & videos has been uploaded successfully!
              </div>
            )}
            {apiError && (
              <div className="alert alert-danger" role="alert">
                {apiError}
              </div>
            )}
          </div>

          <div class="container">
            <form onSubmit={save} class="form-horizontal mt-2">
              <div className="form-group ">
                <div className='col-sm-9 required-label'><b className='text-dark'>Project</b></div>
                <div className='col-sm-9'>
                  {projectError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a project.</div>}
                  <select className="form-control  form-control-user" value={inputField.projectid} readOnly={detailFlag} onChange={inputsHandler} name="projectid" required>
                    <option>[NONE]</option>
                    {
                      project.map((e, key) => {
                        //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;
                        return <option key={e.id} value={e.id}>{e.projectname}</option>;
                      })}
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label for="field" class="col-sm-4 required-label  control-label"><b className='text-dark'>Paste Drive Link of Video And Photos</b></label>
                <div class="col-sm-9">
                  <input type="text" id="topic" name='status' value={inputField.status} readOnly={detailFlag} onChange={inputsHandler} placeholder="http://example.com" required class="form-control" autoFocus />
                </div>
              </div>
              <button type="submit" class="btn btn-primary ml-3 mb-3">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SitePhotosAndVideoComponent