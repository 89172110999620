import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import DenyExtractedDataListComponent from '../../../Components/Main/ExtractedDataComponent/DenyExtractedDataListComponent';

function DenyExtractedDataList(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <DenyExtractedDataListComponent {...props} navigate={navigate}  />
        </div>
    );  
  }
export default DenyExtractedDataList  