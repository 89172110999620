import { useState, useEffect } from "react";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Link } from 'react-router-dom';
import ApiMethod from '../../../Helpers/ApiMethod/Method'
import Service from "../../../Services/Admin/Service";

function MyTasksListComponent() {
    const [data, setData] = useState([]);
    // const apis = ApiMethod.find(o => o.name === 'Category');

    useEffect(() => {
        let id = sessionStorage.getItem('id');
        getData("Task/GetAllbyAssigneeId?userid=" + id);
    }, [])

    const getData = (method) => {
        Service.getdata(method).then((res) => {
            setData(res.data);
        });
    }



    const linkformatter = (data, row) => {
        return <>
            <Link className="btn btn-warning btn-sm" to={`/Detail/${data}`}>Details</Link>
        </>
    }

    const column = [
        { dataField: 'topic', text: 'Name', sort: true },
        { dataField: 'shortdescription', text: 'Short Description', sort: true },
        { dataField: 'createddate', text: 'Created date' },
        { dataField: 'deadlinedated', text: 'Deadline date' },
        { dataField: 'assignerName', text: 'Assigner Name' },
        { dataField: 'id', text: 'Actions', formatter: linkformatter }
    ]

    return (
        <div>
            <div className="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col-sm-3"><h3>My Tasks</h3></div>
                            <div className="col-sm-6"></div>
                            <div className="col-sm-3">
                                {/* <Link className="btn btn-info btn-primary" to={`/AddCategory`}>Add Category</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Details Of My Tasks</h6>
                        {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
                    </div>

                    <div className="card-body">
                        <div className="table-responsive">
                            <BootstrapTable keyField="id"
                                data={data}
                                columns={column}

                                pagination={paginationFactory()}
                            // filter={filterFactory()}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default MyTasksListComponent