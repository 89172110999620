import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import QualifiedLeadListComponent from '../../../Components/Main/LeadComponent/QualifiedLeadListComponent';


function QualifiedLeadList(props) {
    let navigate = useNavigate();

    const params = useParams();
    
    return (  
        <div> 
            <QualifiedLeadListComponent {...props} navigate={navigate} name = {params.name}  />
        </div>
    );  
  }
export default QualifiedLeadList  