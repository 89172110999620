import React from 'react'
import { Link } from 'react-router-dom';

const DashboardOperationsOptionComponent = (props) => {

    const role = sessionStorage.getItem("role");
    const staticname6 = "Material Details";
    const staticname9 = "Graphic Design";
    const staticname12 = "2D Design";
    const staticid = 0;
    const staticName17 = "Request";
    const backbutton = () => {
        props.navigate(-1);
    }


    // Define an array of buttons for each role
    const roleButtons = [

        {
            role: "Admin",
              buttons: [
                { label: "Create Multi Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMultiTask" },
                { label: "Assigned Tasks", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Tasks", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },

                { label: "Create Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeeting" },
                { label: "My Meetings", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },

                
                { label: "Project List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: `/ProjectList` },
                { label: "Site Photos And Videos List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SitePhotosAndVideosList" },

                { label: "Create Boq", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateBoq" },
                { label: "Boq List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/BoqList" },
                { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },

                { label: "Client & Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/ClientVendorDashboard" },
                { label: "Materials Link List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/MaterialLinkList/${staticid}&${staticname6}` },
                
                { label: "Add Site Photo and Video Link", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/SitePhotosAndVideo" },
                { label: "create project Execution planning", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateProjectExecution` },
                { label: "Project Execution planning List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ProjectwiseExecutionTrackerList" },
                
                // { label: "onSite unloading Material List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/UnloadingMaterialList" },
                { label: "Feedbacks", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/FeedbackDashboard" },
                { label: "Create Pre P&L", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreatePrePL" },
                { label: "Pre P&L List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/PrePLList" },
                { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminDesign" },
                
                { label: "Create Approval List", className: "btn btn-success btn-md font-weight-bold text-uppercase ", to: `/CreateApprovalList` },
                { label: "Approval List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: `/ApprovalList` },
            ]
        },

        {
            role: "Managing Director(MD)",
              buttons: [
                { label: "Create Multi Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMultiTask" },
                { label: "Assigned Tasks", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Tasks", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },

                { label: "Create Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeeting" },
                { label: "My Meetings", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },

                
                { label: "Project List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: `/ProjectList` },
                { label: "Site Photos And Videos List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SitePhotosAndVideosList" },

                { label: "Create Boq", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateBoq" },
                { label: "Boq List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/BoqList" },
                { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },

                { label: "Client & Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/ClientVendorDashboard" },
                { label: "Materials Link List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/MaterialLinkList/${staticid}&${staticname6}` },
                
                { label: "Add Site Photo and Video Link", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/SitePhotosAndVideo" },
                { label: "create project Execution planning", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateProjectExecution` },
                { label: "Project Execution planning List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ProjectwiseExecutionTrackerList" },
                
                // { label: "onSite unloading Material List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/UnloadingMaterialList" },
                { label: "Feedbacks", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/FeedbackDashboard" },
                { label: "Create Pre P&L", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreatePrePL" },
                { label: "Pre P&L List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/PrePLList" },
                { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminDesign" },
                
                { label: "Create Approval List", className: "btn btn-success btn-md font-weight-bold text-uppercase ", to: `/CreateApprovalList` },
                { label: "Approval List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: `/ApprovalList` },
            ]
        },
        //operations
        {
            role: "Assitant Manager - Process",
                buttons: [
                { label: "Create Multi Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMultiTask" },
                { label: "Assigned Tasks", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Tasks", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },

                { label: "Create Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeeting" },
                { label: "My Meetings", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },

                
                { label: "Project List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: `/ProjectList` },
                { label: "Site Photos And Videos List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SitePhotosAndVideosList" },
                
                { label: "Create Boq", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateBoq" },
                { label: "Boq List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/BoqList" },
                { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },

                { label: "Client & Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/ClientVendorDashboard" },
                { label: "Materials Link List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/MaterialLinkList/${staticid}&${staticname6}` },
                
                { label: "Add Site Photo and Video Link", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/SitePhotosAndVideo" },
                { label: "create project Execution planning", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateProjectExecution` },
                { label: "Project Execution planning List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ProjectwiseExecutionTrackerList" },
                
                // { label: "onSite unloading Material List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/UnloadingMaterialList" },
                { label: "Feedbacks", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/FeedbackDashboard" },
                { label: "Create Pre P&L", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreatePrePL" },
                { label: "Pre P&L List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/PrePLList" },
                { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminDesign" },
                
                { label: "Create Approval List", className: "btn btn-success btn-md font-weight-bold text-uppercase ", to: `/CreateApprovalList` },
                { label: "Approval List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: `/ApprovalList` },
            ]
        },

        {
            role: "Executive - Software Developer(SOP Tool)",
              buttons: [
                { label: "Create Multi Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMultiTask" },
                { label: "Assigned Tasks", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Tasks", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },

                { label: "Create Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeeting" },
                { label: "My Meetings", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },

                
                { label: "Project List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: `/ProjectList` },
                { label: "Site Photos And Videos List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SitePhotosAndVideosList" },
                
                { label: "Create Boq", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateBoq" },
                { label: "Boq List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/BoqList" },
                { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },

                { label: "Client & Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/ClientVendorDashboard" },
                { label: "Materials Link List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/MaterialLinkList/${staticid}&${staticname6}` },
                
                { label: "Add Site Photo and Video Link", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/SitePhotosAndVideo" },
                { label: "create project Execution planning", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateProjectExecution` },
                { label: "Project Execution planning List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ProjectwiseExecutionTrackerList" },
                
                // { label: "onSite unloading Material List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/UnloadingMaterialList" },
                { label: "Feedbacks", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/FeedbackDashboard" },
                { label: "Create Pre P&L", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreatePrePL" },
                { label: "Pre P&L List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/PrePLList" },
                { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminDesign" },
                
                { label: "Create Approval List", className: "btn btn-success btn-md font-weight-bold text-uppercase ", to: `/CreateApprovalList` },
                { label: "Approval List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: `/ApprovalList` },
            ]
        },


        {
            role: "Sr.Manager-Projects",
            buttons: [
                { label: "Create Multi Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMultiTask" },
                { label: "Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateTask/${staticName17}` },
                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Create Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeeting" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                
                { label: "Boq List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/BoqList" },
                { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/RFPList" },
                { label: "create pre P&L", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreatePrePL" },
                { label: "Pre P&L List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/PrePLList" },
                { label: "Project List", className: "btn btn-warning btn-md font-weight-bold text-uppercase project", to: `/ProjectList` },
                { label: "Client & Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/ClientVendorDashboard" },
                { label: "Upload pictures and videos", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/SitePhotosAndVideo" },
                { label: "Site Photos And Videos List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SitePhotosAndVideosList" },
                // { label: "create Vendor Costing", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Create project Execution planning", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateProjectExecution` },
                { label: "Project Execution planning List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ProjectwiseExecutionTrackerList" },
                { label: "Onsite unloading", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateUnloadingMaterial" },
                { label: " Create Approval List", className: "btn btn-success btn-md font-weight-bold text-uppercase ", to: `/CreateApprovalList` },
                { label: "Approval List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ApprovalList" },
                { label: "Onsite Unloading List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/UnloadingMaterialList" },
                { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminDesign" },
                // { label: "Feedbacks", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
            ]
          },
      
          
          {
            role: "DGM-Projects",
              buttons: [
                // { label: "Create Multi Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMultiTask" },
                // { label: "Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateTask/${staticName17}` },
                // { label: "Assigned Tasks", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                // { label: "My Tasks", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                // { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },

                // { label: "Create Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeeting" },
                // { label: "My Meetings", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },

                
                // { label: "Project List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: `/ProjectList` },
                { label: "Site Photos And Videos List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SitePhotosAndVideosList" },
                
                { label: "Create Boq", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateBoq" },
                { label: "Boq List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/BoqList" },
                // { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },

                // { label: "Client & Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/ClientVendorDashboard" },
                { label: "Materials Link List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/MaterialLinkList/${staticid}&${staticname6}` },
                
                { label: "Add Site Photo and Video Link", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/SitePhotosAndVideo" },
                // { label: "create project Execution planning", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateProjectExecution` },
                // { label: "Project Execution planning List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ProjectwiseExecutionTrackerList" },
                
                // { label: "onSite unloading Material List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/UnloadingMaterialList" },
                // { label: "Feedbacks", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/FeedbackDashboard" },
                // { label: "Create Pre P&L", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreatePrePL" },
                // { label: "Pre P&L List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/PrePLList" },
                // { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminDesign" },
                
                { label: "Create Approval List", className: "btn btn-success btn-md font-weight-bold text-uppercase ", to: `/CreateApprovalList` },
                { label: "Approval List", className: "btn btn-primary btn-md font-weight-bold text-uppercase ", to: `/ApprovalList` },
            ]
          },

          {
            role: "Project Manager",
            buttons: [
                { label: "Create Multi Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMultiTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateTask/${staticName17}` },
                { label: "Request List", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Create Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeeting" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "Create Boq", className: "btn btn-success btn-md font-weight-bold text-uppercase ", to: "/CreateBoq" },
                { label: "Boq List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/BoqList" },
                
                { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/RFPList" },
                { label: "create pre P&L", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreatePrePL" },
                { label: "Pre P&L List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/PrePLList" },
                { label: " Project List", className: "btn btn-warning btn-md font-weight-bold text-uppercase project", to: `/ProjectList` },
                { label: "Client & Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/ClientVendorDashboard" },
                { label: "Upload pictures and videos", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/SitePhotosAndVideo" },
                { label: "Site Photos And Videos List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SitePhotosAndVideosList" },
                // { label: "create Vendor Costing", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Create project Execution planning", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateProjectExecution` },
                { label: "Project Execution planning List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ProjectwiseExecutionTrackerList" },
                { label: "Onsite unloading", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateUnloadingMaterial" },
                { label: " Create Approval List", className: "btn btn-success btn-md font-weight-bold text-uppercase ", to: `/CreateApprovalList` },
                { label: "Approval List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ApprovalList" },
                { label: "Onsite Unloading List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/UnloadingMaterialList" },
                { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminDesign" },
                // { label: "Feedbacks", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
            ]
        },

        {
            role: "Project-Manager-Poland",
            buttons: [
                { label: "Create Multi Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMultiTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateTask/${staticName17}` },
                { label: "Request List", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Create Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeeting" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "Create Boq", className: "btn btn-success btn-md font-weight-bold text-uppercase ", to: "/CreateBoq" },
                { label: "Boq List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/BoqList" },
                { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/RFPList" },
                { label: "create pre P&L", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreatePrePL" },
                { label: "Pre P&L List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/PrePLList" },
                
                { label: " Project List", className: "btn btn-warning btn-md font-weight-bold text-uppercase project", to: `/ProjectList` },
                { label: "Client & Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/ClientVendorDashboard" },
                { label: "Upload pictures and videos", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/SitePhotosAndVideo" },
                { label: "Site Photos And Videos List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SitePhotosAndVideosList" },
                // { label: "create Vendor Costing", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Create project Execution planning", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateProjectExecution` },
                { label: "Project Execution planning List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ProjectwiseExecutionTrackerList" },
                { label: "Onsite unloading", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateUnloadingMaterial" },
                { label: " Create Approval List", className: "btn btn-success btn-md font-weight-bold text-uppercase ", to: `/CreateApprovalList` },
                { label: "Approval List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ApprovalList" },
                { label: "Onsite Unloading List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/UnloadingMaterialList" },
                { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminDesign" },
                // { label: "Feedbacks", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
            ]
        },

        {
            role: "Senior Executive - Administration",
            buttons: [
                { label: "Create Multi Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMultiTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateTask/${staticName17}` },
                { label: "Request List", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Create Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeeting" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "Create Boq", className: "btn btn-success btn-md font-weight-bold text-uppercase ", to: "/CreateBoq" },
                { label: "Boq List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/BoqList" },
                { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/RFPList" },
                { label: "create pre P&L", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreatePrePL" },
                { label: "Pre P&L List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/PrePLList" },
                
                { label: " Project List", className: "btn btn-warning btn-md font-weight-bold text-uppercase project", to: `/ProjectList` },
                { label: "Client & Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/ClientVendorDashboard" },
                { label: "Upload pictures and videos", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/SitePhotosAndVideo" },
                { label: "Site Photos And Videos List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SitePhotosAndVideosList" },
                // { label: "create Vendor Costing", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Create project Execution planning", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateProjectExecution` },
                { label: "Project Execution planning List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ProjectwiseExecutionTrackerList" },
                { label: "Onsite unloading", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateUnloadingMaterial" },
                { label: " Create Approval List", className: "btn btn-success btn-md font-weight-bold text-uppercase ", to: `/CreateApprovalList` },
                { label: "Approval List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ApprovalList" },
                { label: "Onsite Unloading List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/UnloadingMaterialList" },
                { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminDesign" },
                // { label: "Feedbacks", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
            ]
        },

        {
            role: "Head of Projects - North America",
            buttons: [
                { label: "Create Multi Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMultiTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateTask/${staticName17}` },
                { label: "Request List", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Create Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeeting" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "Create Boq", className: "btn btn-success btn-md font-weight-bold text-uppercase ", to: "/CreateBoq" },
                { label: "Boq List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/BoqList" },
                { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/RFPList" },
                { label: "create pre P&L", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreatePrePL" },
                { label: "Pre P&L List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/PrePLList" },
                
                { label: " Project List", className: "btn btn-warning btn-md font-weight-bold text-uppercase project", to: `/ProjectList` },
                { label: "Client & Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/ClientVendorDashboard" },
                { label: "Upload pictures and videos", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/SitePhotosAndVideo" },
                { label: "Site Photos And Videos List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SitePhotosAndVideosList" },
                // { label: "create Vendor Costing", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Create project Execution planning", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateProjectExecution` },
                { label: "Project Execution planning List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ProjectwiseExecutionTrackerList" },
                { label: "Onsite unloading", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateUnloadingMaterial" },
                { label: " Create Approval List", className: "btn btn-success btn-md font-weight-bold text-uppercase ", to: `/CreateApprovalList` },
                { label: "Approval List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ApprovalList" },
                { label: "Onsite Unloading List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/UnloadingMaterialList" },
                { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminDesign" },
                // { label: "Feedbacks", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
            ]

        },

        {
            role: "Senior Manager-Projects & Client Servicing",
            buttons: [
                { label: "Create Multi Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMultiTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateTask/${staticName17}` },
                { label: "Request List", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Create Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeeting" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "Create Boq", className: "btn btn-success btn-md font-weight-bold text-uppercase ", to: "/CreateBoq" },
                { label: "Boq List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/BoqList" },
                { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/RFPList" },
                { label: "create pre P&L", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreatePrePL" },
                { label: "Pre P&L List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/PrePLList" },
                
                { label: " Project List", className: "btn btn-warning btn-md font-weight-bold text-uppercase project", to: `/ProjectList` },
                { label: "Client & Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/ClientVendorDashboard" },
                { label: "Upload pictures and videos", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/SitePhotosAndVideo" },
                { label: "Site Photos And Videos List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SitePhotosAndVideosList" },
                // { label: "create Vendor Costing", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Create project Execution planning", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateProjectExecution` },
                { label: "Project Execution planning List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ProjectwiseExecutionTrackerList" },
                { label: "Onsite unloading", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateUnloadingMaterial" },
                { label: " Create Approval List", className: "btn btn-success btn-md font-weight-bold text-uppercase ", to: `/CreateApprovalList` },
                { label: "Approval List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ApprovalList" },
                { label: "Onsite Unloading List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/UnloadingMaterialList" },
                { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminDesign" },
                // { label: "Feedbacks", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
            ]
        },

        {
            role: "Executive - Operations",
            buttons: [
                { label: "Create Multi Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMultiTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateTask/${staticName17}` },
                { label: "Request List", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Assigned Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "My Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Create Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeeting" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "Create Boq", className: "btn btn-success btn-md font-weight-bold text-uppercase ", to: "/CreateBoq" },
                { label: "Boq List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/BoqList" },
                { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/RFPList" },
                { label: "create pre P&L", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreatePrePL" },
                { label: "Pre P&L List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/PrePLList" },
                
                { label: " Project List", className: "btn btn-warning btn-md font-weight-bold text-uppercase project", to: `/ProjectList` },
                { label: "Client & Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/ClientVendorDashboard" },
                { label: "Upload pictures and videos", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/SitePhotosAndVideo" },
                { label: "Site Photos And Videos List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SitePhotosAndVideosList" },
                // { label: "create Vendor Costing", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Create project Execution planning", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateProjectExecution` },
                { label: "Project Execution planning List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ProjectwiseExecutionTrackerList" },
                { label: "Onsite unloading", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateUnloadingMaterial" },
                { label: " Create Approval List", className: "btn btn-success btn-md font-weight-bold text-uppercase ", to: `/CreateApprovalList` },
                { label: "Approval List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ApprovalList" },
                { label: "Onsite Unloading List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/UnloadingMaterialList" },
                { label: "All Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminDesign" },
                // { label: "Feedbacks", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
            ]
        },

        {
            role: "Deputy-Manager-Projects",
            buttons: [
                { label: "My Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Create Multi Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMultiTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateTask/${staticName17}` },
                { label: "Request List", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Assigned Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateTask" },
                // { label: "Task Notification", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
                //{ label: "Chat-box", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
                { label: "Create BOQ", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateBOQ" },
                { label: "Boq List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/BoqList" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                { label: "Materials Link List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/MaterialLinkList/${staticid}&${staticname6}` },
                // { label: "2D-design", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/Create2dDesign" },
                { label: "2D-design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname12}` },
            ]
        },

        {
            role: "Senior Executive - Projects",
            buttons: [
                { label: "My Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Create Multi Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMultiTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateTask/${staticName17}` },
                { label: "Request List", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Assigned Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                // { label: "Task Notification", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
                //{ label: "Chat-box", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
                { label: "Create BOQ", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateBOQ" },
                { label: "Boq List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/BoqList" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                { label: "Materials Link List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/MaterialLinkList/${staticid}&${staticname6}` },
                // { label: "2D-design", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/Create2dDesign" },
                { label: "2D-design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname12}` },
            ]
        },

        {
            role: "Assistant Manager - Graphics",
            buttons: [
                { label: "My Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateTask/${staticName17}` },
                { label: "Request List", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Assigned Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateTask" },
                // { label: "Task Notification", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
                //{ label: "Chat-box", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
                { label: "Create BOQ", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateBOQ" },
                { label: "Boq List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/BoqList" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                { label: "Materials Link List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/MaterialLinkList/${staticid}&${staticname6}` },
                // { label: "2D-design", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/Create2dDesign" },
                { label: "2D-design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname12}` },
            ]
        },

        {
            role: "Deputy Manager - Administration",
            buttons: [
                { label: "My Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateTask/${staticName17}` },
                { label: "Request List", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Assigned Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateTask" },
                // { label: "Task Notification", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
                //{ label: "Chat-box", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
                { label: "Create BOQ", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateBOQ" },
                { label: "Boq List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/BoqList" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                { label: "Materials Link List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/MaterialLinkList/${staticid}&${staticname6}` },
                // { label: "2D-design", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/Create2dDesign" },
                { label: "2D-design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname12}` },
            ]
        },

        {
            role: "Assistant-Manager-Projects",
            buttons: [
                { label: "My Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateTask/${staticName17}` },
                { label: "Request List", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Assigned Task", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateTask" },
                // { label: "Task Notification", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
                //{ label: "Chat-box", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
                { label: "Meeting List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "Create BOQ", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateBOQ" },
                { label: "Boq List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/BoqList" },
                { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
                { label: "Materials Link List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/MaterialLinkList/${staticid}&${staticname6}` },
                // { label: "2D-design", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/Create2dDesign" },
                { label: "2D-design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname12}` },
            ]
        },

        {
            role: "Manager - Graphics",
            buttons: [
                { label: "My Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
                { label: "Generarte Request Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateTask/${staticName17}` },
                { label: "Request List", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/KanbanMyTask/${staticName17}` },

                { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
                { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
                { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateTask" },
                // { label: "Task Notification", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
                // { label: "Chat-box", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/marketing" },
                { label: "Meeting List", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
                { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/RFPList" },
                { label: "BOQ List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/BoqList" },
                { label: "Graphic Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname9}` },
                // { label: "Graphic Design For Vendor", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/marketing" },
                // { label: "Client Graphics Verification", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/marketing" },
            ]
        },
    ];
    return (
        <div>
            <div className="container-fluid">
                <div className="card shadow border  border-success mb-2">
                    <div className="card-header rounded py-3">
                        <div className="row">
                            <div className="d-flex flex-start align-items-center">
                                
                            </div>
                            <div className="col-sm-3"><h2><b className='text-dark'>Dashboard</b></h2></div>
                            <div className="col-sm-6"></div>
                            <div className="col-sm-3 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="card cardUser shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-success">Dashboard</h6>
                    </div>
                    <div className="container" style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                        <div className="row">
                            {roleButtons.map((roleButtonGroup, index) => (
                                (roleButtonGroup.role === role) && (
                                    roleButtonGroup.buttons.map((button, buttonIndex) => (
                                        <div key={buttonIndex} className="col-xl-4 col-md-8">
                                            <div className="card border-success shadow p-1 mt-3 ml-3">
                                                <div className="btn-group">
                                                    <Link to={button.to} className={button.className} type="button">
                                                        {button.label}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )
                            ))}
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default DashboardOperationsOptionComponent