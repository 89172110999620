import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import EditRFPComponent from '../../../Components/Main/RFPComponent/EditRFPComponent';

function EditRFP(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <EditRFPComponent {...props} navigate={navigate} id={params.id} />
        </div>
    );  
  }
export default EditRFP  