import React, { Component } from 'react' 
import { useNavigate, useParams } from 'react-router-dom';
import ExhibitorListComponent from '../../../Components/Main/LifeToolComponent/ExhibitorListComponent';


function ExhibitorList(props) {
    let navigate = useNavigate();
    const params = useParams();
    return (  
        <div> 
            <ExhibitorListComponent {...props} navigate={navigate} id={params.id} />
        </div>
    );  
  }
  
export default ExhibitorList;  