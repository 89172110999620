import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import Service from "../../../Services/Admin/Service";
import React, { useState, useEffect } from 'react'
const ProjectClosureFeedbackListComponent = (props) => {

  const [data, setData] = useState([]);

  useEffect(() => {
    getData("Feedback/GetALLProjectClosure");
  }, [])

  const staticname1 = "details";
  const staticname2 = "edit";

  const getData = (method) => {
    Service.getdata(method).then((res) => {
      setData(res.data);
    });
  }

  const linkformatter = (data, row) => {
    return <>  <Link className="btn btn-warning btn-sm" to={`/CreateProjectClosureFeedback/${data}&${staticname1}`}>Details</Link>
      &nbsp; <Link className="btn btn-primary btn-sm mt-1" to={`/CreateProjectClosureFeedback/${data}&${staticname2}`}>Edit</Link>
      {/* &nbsp; <Link className="btn btn-warning btn-sm" to={`/AgreementIsApproved/${data}`}>Approved</Link> */}
      {/* &nbsp; <Link className="btn btn-warning btn-sm" to={`/AgreementIsShared/${data}`}>Shared</Link> */}
    </>
  }

  const backbutton = () => {
    props.navigate(-1);
  }

  const column = [
    { dataField: 'customerFeedback', text: 'Customer Feedback', sort: true },
    { dataField: 'ratetheVendor', text: 'Vendor Rating', sort: true },
    { dataField: 'feedbackProvidedby', text: 'Feedabck Provided By', sort: true },
    { dataField: 'id', text: 'Actions', formatter: linkformatter }
  ]


  return (
    <div className="container-fluid">
      <div className="card shadow mb-2 border-success">
        <div className="card-header rounded py-3">  
          <div className="row">
            <div className="col-sm-6 mt-1"><h3><b className='text-dark'>Project Closure Feedback</b></h3></div>
            <div className="col-sm-4"></div>
            <div className="col-sm-2 d-flex flex-row-reverse">
              <button className="btn btn-info  ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
              {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link> */}
            </div>
          </div>
        </div>
      </div>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-success">Closure Feedback List</h6>
          {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
        </div>

        <div className="card-body">
          <div className="table-responsive ">
            <BootstrapTable keyField="id"
              data={data}
              classes='text-dark'
              columns={column}
              pagination={paginationFactory()}
            // filter={filterFactory()}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectClosureFeedbackListComponent