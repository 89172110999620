import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import RFPFinalDesignComponent from '../../../Components/Main/RFPComponent/RFPFinalDesignComponent';

function RFPFinalDesign(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <RFPFinalDesignComponent {...props} navigate={navigate} id={params.id} name = {params.name} />
        </div>
    );  
  }
export default RFPFinalDesign  