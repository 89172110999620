import { useState, useEffect } from "react";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Link } from 'react-router-dom';
import ApiMethod from '../../../Helpers/ApiMethod/Method'
import Service from "../../../Services/Admin/Service";

function RolesListComponent(props) {
    const [data, setData] = useState([]);
    // const apis = ApiMethod.find(o => o.name === 'Category');

    useEffect(() => {
        getCategoryData("Roles/GetAll");
    }, [])

    const getCategoryData = (method) => {
        Service.getdata(method).then((res) => {
            setData(res.data);
        });
    }

    const backbutton = () => {
        props.navigate(-1);
    }



    const linkformatter = (data, row) => {
        return <>
            <Link className="btn btn-warning btn-sm" to={`/EditCategory/${data}`}><i className="fas fa-edit"></i></Link>
        </>
    }



    const column = [
        { dataField: 'name', text: 'Role Nome', sort: true },
        { dataField: 'isDeleted', text: 'isDeleted' },
        { dataField: 'isActive', text: 'isActive', sort: true },
        { dataField: 'id', text: 'Actions', formatter: linkformatter }
    ]

    return (
        <div>
            <div className="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col-sm-3"><h3>Roles</h3></div>

                            <div className="col-sm-6"></div>
                            <div className="col-sm-3 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Details Of Roles</h6>
                        {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
                    </div>

                    <div className="card-body">
                        <div className="table-responsive">
                            <BootstrapTable keyField="id"
                                data={data}
                                columns={column}

                                pagination={paginationFactory()}
                            // filter={filterFactory()}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default RolesListComponent