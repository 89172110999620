import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import EmployeesPogressTaskComponent from '../../../Components/Main/DashboardComponent/EmployeesPogressTaskComponent';

function EmployeesPogressTask(props){
    let navigate = useNavigate();
    const params = useParams();
     
     return (  
         <div> 
             <EmployeesPogressTaskComponent {...props}  navigate={navigate} name={params.name} />
         </div>
     );  

}

export default EmployeesPogressTask