import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import DetailTaskComponent from '../../../Components/Main/Task/DetailTaskComponent';

function DetailTask(props) {
    
    const params = useParams();
   
    let navigate = useNavigate();
    return (  
        <div> 
            <DetailTaskComponent {...props} navigate={navigate} id={params.id} />
        </div>
    );  
  }
  
export default DetailTask  