import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import Service from "../../../Services/Admin/Service";
import React from 'react'
import { useState, useEffect } from 'react';

const MaterialLinkListComponent = (props) => {
    const [data, setData] = useState([]);
    // const apis = ApiMethod.find(o => o.name === 'Category');

    useEffect(() => {

        getDesignlList("Task/GetAllTypetaskdetails?typename=" + props.name);

    }, [])

    const getDesignlList = (method) => {
        Service.getdata(method).then((res) => {
            const filteredData = res.data.filter((element) => element.contentLink !== null);
    
            // Set the state with the filtered data
            setData(filteredData);
    
            // Log the filtered data
            console.log(filteredData);
            debugger
        });
    }
    
    const backbutton = () => {
        props.navigate(-1);
    }

    const externalLink = (data) => {
        window.open(data, '_blank')
    }

    const linkformatter = (data, row) => {
        return <>
            {/* <Link target="_blank" rel="noopener noreferrer"  to={`${data}`}>{data}</Link> */}
            <button className='btn btn-sm btn-info' onClick={() => externalLink(data)} >Open Drive Link</button>
        </>
    }

    const linkformatterActions = (data, row) => {
        return <>
            {/* <Link target="_blank" rel="noopener noreferrer"  to={`${data}`}>{data}</Link> */}
            <Link className="btn btn-warning btn-sm" to={`/TaskDetails/${data}`}>See Details</Link>
        </>
    }




    const column = [
        { dataField: 'projectName', text: 'Project', sort: true },
        { dataField: 'contentLink', text: 'Material Link', formatter: linkformatter },
        { dataField: 'id', text: 'Actions', formatter: linkformatterActions }
    ]

    return (
        <div>
            <div className="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col-sm-6"><h3>Material Link List</h3></div>

                            <div className="col-sm-4"></div>
                            <div className="col-sm-2 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">List Of Material</h6>
                        {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
                    </div>

                    <div className="card-body">
                        <div className="table-responsive">
                            <BootstrapTable keyField="id"
                                data={data}
                                columns={column}

                                pagination={paginationFactory()}
                            // filter={filterFactory()}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MaterialLinkListComponent