import React, { useEffect, useState } from 'react'
import Service from "../../../Services/Admin/Service";
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Select from 'react-select';


const AdminShowListComponent = (props) => {

    let DeptId = sessionStorage.getItem('DeptId');
    const userId = sessionStorage.getItem('id');
    const [rowData, setRowData] = useState([]);
    const [data, setData] = useState([]);
    const [apiError, setApiError] = useState(null);
    const [apiResponse, setApiResponse] = useState(null);
    const [userName, setUserName] = useState([]);
    const [selectedAssignees, setSelectedAssignees] = useState([]);
    const [showAssigneePopup, setShowAssigneePopup] = useState(false);
    const [inputField, setInputField] = useState([
        {
            id: 0,
            showName: "",
            showId: 0,
            sharedUserId: 0,
            createdby: 0,
            updatedby: 0,
            startdate: "",
            enddate: ""
        }
    ])

    useEffect(() => {

        getShowsData("Shows/GetAllShows");
        getUserData("Users/GetAll");

    }, [])

    useEffect(() => {
        if (apiResponse || apiError) {
            const timeoutId = setTimeout(removeSuccessMessage, 3000);
            // window.location.href = "/RFPList";
            return () => clearTimeout(timeoutId);
        }
    }, [apiResponse, apiError]);

    const removeSuccessMessage = () => {
        setApiResponse(null);
        setApiError(null);
    };


    const getShowsData = (method) => {
        Service.getdata(method).then((res) => {
            setData(res.data);
            console.log("Show Data", res.data)
            debugger
        });
    }

    const getUserData = (method) => {
        Service.getdata(method).then((res) => {
            var data = res.data;
            var intdeptId = parseInt(DeptId)
            var list = [];

            if (userId === "1" || userId === "2" || userId === "56" || userId === "47") {
                data.forEach((d, index) => {
                    var role = d.lstRole;

                    var checkdepartment = role.find(x => x.depId === 2)
                    if (checkdepartment !== undefined) {
                        d.rolename = d.lstRole[0].name;
                        list.push(d);
                    }
                    setUserName(list);
                });
            }
            // else {

            //     data.forEach((d, index) => {
            //         var role = d.lstRole;
            //         var checkdepartment = role.find(x => x.depId === intdeptId)
            //         if (checkdepartment !== undefined) {
            //             d.rolename = d.lstRole[0].name;
            //             list.push(d);
            //         }
            //     });
            //     setUserName(list);
            // }

        });
    }

    const backbutton = () => {
        props.navigate(-1);
    }

    function extractValuesFromIndexedObject(indexedObject) {
        return Object.values(indexedObject).map(item => item.value);
    }

    const handleSelectChange = (selectedOptions) => {
        const extractedValues = extractValuesFromIndexedObject(selectedOptions);
        setSelectedAssignees(extractedValues);
        debugger
    };

    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }) => (
        <div className="btn-group" role="group">
            {
                options.map(option => (
                    <button
                        key={option.text}
                        type="button"
                        onClick={() => onSizePerPageChange(option.page)}
                        className={`btn ${currSizePerPage === `${option.page}` ? 'btn-secondary' : 'btn-warning'}`}
                    >
                        {option.text}
                    </button>
                ))
            }
        </div>
    );

    const options = {
        sizePerPageRenderer
    };

    const shareRowData = (data) => {

        // console.log("Share button clicked for data:", data);
        setRowData(data);
        // debugger

    };

    const shareData = () => {
        console.log(rowData)
        console.log(selectedAssignees)

        const data = []

        const assignment = {
            showName: rowData.chapterName,
            showId: rowData.showChapterId,
            sharedUserId: selectedAssignees[0],
            createdby: userId,
            updatedby: userId,
            startdate: rowData.startDate,
            enddate: rowData.endDate
        };

        data.push(assignment);


        console.log(data)
        debugger
        if (data) {
            Service.createorupdate("Shows/ShareShow", data)
                .then(res => {
                    setApiResponse(res.data);
                    // Set the API response in state
                    window.location.reload(true);
                    window.location.href ="/showList"

                })
                .catch(error => {
                    // Handle error if API call fails
                    console.error("API Error:", error);
                    setApiError("Error occurred while sharing the show. Please try again later.");
                });
        }



    }


    const linkformatter = (data, row) => {
        return <>
            <Link className="btn btn-info btn-sm" to={`/ExhibitorList/${data}`}>Exhibhitor</Link> &nbsp;
            {(userId === "1" || userId === "2" || userId === "56" || userId === "47") && <button type="button" class="btn btn-primary ml-1 btn-sm" onClick={() => shareRowData(row)} data-toggle="modal" data-target="#myModal">
                <i class="fa fa-share-alt mr-1"></i>Share Show
            </button>}
            {/* <Link className="btn btn-warning btn-sm" to={`/MeetingDetails/${data}`}>Details</Link> */}
        </>
    }

    const column = [
        // { dataField: 'showChapterId', text: 'show Chapter Id', sort: true,},
        { dataField: 'chapterName', text: 'Show Name', sort: true },
        // { dataField: 'isActive', text: 'Is Active' },
        {
            dataField: 'startDate',
            text: 'Start Date',
            sort: true,

            formatter: (cell, row) => {
                // Format the date here, assuming createddate is a string in ISO 8601 format
                const formattedDate = new Date(cell).toLocaleDateString('en-US', {
                    day: 'numeric',
                    year: 'numeric',
                    month: 'short',
                });
                return formattedDate;
            }
        },
        {
            dataField: 'endDate', text: 'End Date', sort: true,
            formatter: (cell, row) => {
                // Format the date here, assuming createddate is a string in ISO 8601 format
                const formattedDate = new Date(cell).toLocaleDateString('en-US', {
                    day: 'numeric',
                    year: 'numeric',
                    month: 'short',
                });
                return formattedDate;
            }
        },
        { dataField: 'showChapterId', text: 'Actions', formatter: linkformatter }
    ]
    return (
        <div className="container-fluid">
            <div className="card shadow border-success mb-2">
                <div className="card-header rounded py-3">
                    <div className="row">
                        <div className="col-sm-5"><h3><b className='text-dark'>Shows List (Exhibition)</b></h3></div>
                        <div className="col-sm-4"></div>
                        <div className="col-sm-3 d-flex flex-row-reverse">
                            <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                            {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-success">Shows List</h6>
                    {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
                    {apiResponse && (
                        <div className="alert alert-success mt-2" role="alert">
                            show has been shared successfully!
                        </div>
                    )}
                    {apiError && (
                        <div className="alert alert-danger" role="alert">
                            {apiError}
                        </div>
                    )}
                </div>

                <div className="card-body">
                    <div className="table-responsive">
                        <BootstrapTable keyField="id"
                            data={data}
                            columns={column}
                            pagination={paginationFactory(options)}
                            filter={filterFactory()}
                        />
                    </div>
                </div>
            </div>

            <div class="modal fade" id="myModal" role="dialog">
                <div id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLongTitle">Share Show</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <Select
                                    name="assigneeid"
                                    isMulti={true}
                                    placeholder="Enter members to share with"
                                    options={userName.map((user) => ({
                                        value: user.id,
                                        label: `${user.firstname} ${user.lastname}`,
                                    }))}
                                    value={selectedAssignees.value}
                                    onChange={handleSelectChange}
                                    onClick={() => setShowAssigneePopup(false)}
                                />
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type='submit' class="btn btn-primary" onClick={shareData}>share</button>
                            </div>
                        </div>
                    </div>
                </div>
                {showAssigneePopup && (
                    <>
                        <Select
                            name="assigneeid"
                            isMulti={true}
                            placeholder="Enter members to share with"
                            options={userName.map((user) => ({
                                value: user.id,
                                label: `${user.firstname} ${user.lastname}`,
                            }))}
                            value={selectedAssignees.value}
                            onChange={handleSelectChange}
                            onClick={() => setShowAssigneePopup(false)}
                        />
                        <button className="close-popup-btn" onClick={() => setShowAssigneePopup(false)}>
                            Close popup
                        </button>
                    </>
                )}
            </div>
        </div>
    )
}

export default AdminShowListComponent