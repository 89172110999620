import React, { useEffect, useState } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import Service from '../../../Services/Admin/Service';

const ContentListComponent = (props) => {

    const [data, setData] = useState([]);

    useEffect(() => {
        getContentData("ExtractedData/GetAll");
    }, []);

    const getContentData = (method) => {
        Service.getdata(method).then((res) => {
            setData(res.data);
            console.log(res.data);
            debugger
        });

    }

    const linkformatter = (data, row) => {
        return <>
            {/* <Link className="btn btn-warning btn-sm" to={`/CreateMeeting/${data}`}>Edit</Link> &nbsp; */}
            <Link className="btn btn-warning btn-sm" to={`/ContentDetails/${data}`}>Details</Link>
            <Link className="btn btn-warning btn-sm ml-1" to={`/ContentIsApproved/${data}`}>Is Approved</Link>
        </>
    }


    const column = [
        // { dataField: 'checkbox', text: 'Select', formatter: checkboxFormatter }, 
        { dataField: 'bothObjective', text: 'Objective of Booth', sort: true },
        { dataField: 'visualDetails', text: 'Visual Appeal Details' },
        { dataField: 'targetAudience', text: 'Target Audience', sort: true },
        { dataField: 'id', text: 'Actions', formatter: linkformatter }
    ]

    const backbutton = () => {
        props.navigate(-1);
    }


    return (
        <div>
            <div className="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col-sm-3"><h3>Content Data List</h3></div>
                            <div className="col-sm-6"></div>
                            <div className="col-sm-3">
                            <button className="btn btn-info mt-1 ml-1 btn-sm" onClick={() => backbutton()}>back</button>

                                <Link to="/AdminDashboard" className="btn btn-secondary mt-1 ml-2 btn-sm">Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">List</h6>
                    </div>

                    <div className="card-body">
                        <div className="table-responsive">
                            {/* <BootstrapTable keyField="id"
                                data={data}
                                columns={column}
                                pagination={paginationFactory()}
                            /> */}

                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Lead</th>
                                        <th scope="col">Objective of Booth</th>
                                        <th scope="col">Unique Selling Proposition</th>
                                        <th scope="col">Visual Appeal Details</th>
                                        <th scope="col">Target Audience</th>
                                        <th scope="col">Functionality</th>
                                        <th scope="col">Engagement Strategies</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">1</th>
                                        <td>2023_8_string_string</td>
                                        <td>defence wing products</td>
                                        <td>Fighter jets And navy</td>
                                        <td>Army Personnals and leaders</td>
                                        <td>testing</td>
                                        <td>testing</td>
                                        <td>
                                            <Link className="btn btn-warning mr-1 mb-1 btn-sm" to={`/ContentDetails/${data}`}>Details</Link>
                                            <Link className="btn btn-warning btn-sm" to={`/ContentDetails/${data}`}>Is Approved</Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">2</th>
                                        <td>2023_8_india_xyz</td>
                                        <td>defence wing products</td>
                                        <td>Fighter jets And navy</td>
                                        <td>Army Personnals and leaders</td>
                                        <td>testing</td>
                                        <td>testing</td>
                                        <td>
                                            <Link className="btn btn-warning mr-1 mb-1 btn-sm" to={`/ContentDetails/${data}`}>Details</Link>
                                            <Link className="btn btn-warning btn-sm" to={`/ContentDetails/${data}`}>Is Approved</Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">3</th>
                                        <td>2023_8_india_xyz</td>
                                        <td>defence wing products</td>
                                        <td>Fighter jets And navy</td>
                                        <td>Army Personnals and leaders</td>
                                        <td>testing</td>
                                        <td>testing</td>
                                        <td>
                                            <Link className="btn btn-warning mr-1 mb-1 btn-sm" to={`/ContentDetails/${data}`}>Details</Link>
                                            <Link className="btn btn-warning btn-sm" to={`/ContentDetails/${data}`}>Is Approved</Link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {/* <button type="submit" class="btn btn-primary ml-3 mb-3">Save</button> */}
            </div>
        </div>
    )
}

export default ContentListComponent