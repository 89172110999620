/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from 'react'
import Service from "../../../Services/Admin/Service";
import Select from 'react-select';
import CreateUserComponentCss from "./CreateUserComponent.css";
import { Link } from 'react-router-dom';

function CreateUserComponent(props) {
    let id = sessionStorage.getItem('id');
    const [role, setRole] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [apiError, setApiError] = useState(null);
    const [apiResponse, setApiResponse] = useState(null);
    const [inputField, setInputField] = useState({
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        isActive: true,
        isDeleted: false,
        createdby: id,
        updatedby: id,
        createdName: id,
        updatedName: id,
        lstRole: [],
    });

    useEffect(() => {
        getRoleInfo("Roles/GetAll");
    }, [])

    useEffect(() => {
        if (apiResponse || apiError) {
            const timeoutId = setTimeout(removeSuccessMessage, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [apiResponse, apiError]);

    const getRoleInfo = (method) => {
        Service.getdata(method).then((res) => {
            setRole(res.data);
            debugger
        });
    }
    const removeSuccessMessage = () => {
        setApiResponse(null);
        setApiError(null);
    };

    const backbutton = () => {
        props.navigate(-1);
    }

    const handleSelectChange = (selectedOptions) => {
        setSelectedRoles(selectedOptions);
    };


    const createUser = async (evt) => {
        if (evt && evt.preventDefault) {
            evt.preventDefault();
        }

        const formData = new FormData(evt.target);

        const data = {}
        for (const [key, value] of formData) {
            data[key] = value;
        }

        function validateData(data) {
            const { firstname, lastname, email, password } = data;


            if (!firstname || !lastname || !email || !password) {
                alert("Please fill in all required fields.");
                return false;
            }
            return true
        }

        if (!validateData(data)) {
            return;
        }

        const task = {
            ...inputField,
            ...data,
            lstRole: selectedRoles.map((role) => role.value),
        };
        console.log(task);
        debugger
        if (task) {
            Service.createorupdate("Users/CreateOrUpdate", task)
                .then(res => {
                    setApiResponse(res.data);
                })
                .catch(error => {
                    // Handle API error, if necessary
                    console.error("API Error:", error);
                    setApiError("Error occurred while saving the meeting. Please try again later.");
                });
        }
    }

    return (
        <div>
            <div className="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div class="d-flex flex-start align-items-center">
                                <img class="rounded-circle shadow-1-strong me-32"
                                    src="https://cdn-icons-png.flaticon.com/512/615/615075.png" alt="avatar" width="40"
                                    height="40" />
                            </div>
                            <div className="col-sm-3"><h2>New User</h2></div>
                            <div className="col-sm-6"></div>
                            <div className="col-sm-2 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="card cardUser shadow mb-4" >
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Add User</h6>
                        {apiResponse && (
                            <div className="alert alert-success" role="alert">
                                User has been created successfully!
                            </div>
                        )}
                        {apiError && (
                            <div className="alert alert-danger" role="alert">
                                {apiError}
                            </div>
                        )}
                    </div>

                    <div class="container">
                        <form class="form-horizontal mt-2" role="form" onSubmit={createUser}>
                            <div class="form-group">
                                <label for="firstName" class="col-sm-3 required-label  control-label">First Name</label>
                                <div class="col-sm-9">
                                    <input type="text" id="firstName" name='firstname' placeholder="First Name" required class="form-control" autofocus />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="lastName" class="col-sm-3 required-label control-label">Last Name</label>
                                <div class="col-sm-9">
                                    <input type="text" id="lastName" name='lastname' placeholder="Last Name" class="form-control" autofocus required />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="email" class="col-sm-3 required-label control-label">Email </label>
                                <div class="col-sm-9">
                                    <input type="email" id="email" placeholder="Email" class="form-control" name="email" required />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="role" class="col-sm-3 required-label control-label">Last Role</label>
                                <div class="col-sm-9">
                                    <Select
                                        name="roleId"
                                        isMulti={true}
                                        placeholder="Enter Role"
                                        options={role.map((user) => ({
                                            value: user.id,
                                            label: `${user.name}`,
                                        }))}
                                        value={selectedRoles}
                                        onChange={handleSelectChange}
                                    />
                                    <div>
                                        {selectedRoles.map((selectedRole) => (
                                            <div key={selectedRole.id}>
                                                {selectedRole.name}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="password" class="col-sm-3 required-label control-label">Password</label>
                                <div class="col-sm-9">
                                    <input type="password" id="password" name='password' placeholder="Password" class="form-control" required />
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary ml-2 mb-3">Save</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CreateUserComponent