import React, { useEffect, useState } from "react";
import Service from "../../../Services/Admin/Service";
import { Link } from "react-router-dom";
import {
  filterWithUniqueIds,
  formatDate,
  getLoginUserData,
} from "../../../Helpers/supportedFunctions/supportedGlobalFunctions";

const UserCalendarComponent = (props) => {
  const [executiondata, setExecutionData] = useState([]);
  const [executiondatabyId, setExecutionDatabyId] = useState([]);
  const [calendarData, setCalendarData] = useState([]);
  const [monthName, setMonthName] = useState("");
  const [prevmonth, setPrevMonth] = useState("");
  const [nextMonth, setNextMonth] = useState("");
  const [monthDay, setMonthDay] = useState("");
  const [yearInt, setYearInt] = useState(null);
  const [taskData, setTaskData] = useState([]);

  useEffect(() => {
    getExecutionData("ProjectExecutionNew/GetAll");
    if (props.name === "assigned") {
      getTaskData(
        "Task/GetALLAssignedTaskWithStatus?userid=" + getLoginUserData().userId
      );
    } else {
      getTaskData("Task/GetALLWithStatus?userid=" + getLoginUserData().userId);
    }
  }, []);

  const getExecutionData = (method) => {
    Service.getdata(method).then((res) => {
      setExecutionData(res.data);
    });
  };

  const getTaskData = (method) => {
    Service.getdata(method).then((res) => {
      setTaskData(res.data);

      bindData(res.data, null, null);
    });
  };

  // Function to filter data by date part of createdDate
  const filterDataByDate = (data, year, month, date) => {
    return data.filter((item) => {
      const itemDate = new Date(item.createddate);
      const itemMonth = itemDate.getMonth() + 1;
      console.log("backend full date", itemDate);
      console.log("backend month", itemMonth);
      console.log("frontend month", month);
      console.log("backend date", itemDate.getDate());
      console.log("frontend day", date);
      console.log("backend year", itemDate.getFullYear());
      console.log("frontend year", year);

      return (
        itemDate.getFullYear() <= year &&
        itemMonth <= month &&
        itemDate.getDate() <= date
      );
    });
  };

  const MonthNameFn = (cMonth) => {
    if (cMonth === 1) {
      return "January";
    } else if (cMonth === 2) {
      return "February";
    } else if (cMonth === 3) {
      return "March";
    } else if (cMonth === 4) {
      return "April";
    } else if (cMonth === 5) {
      return "May";
    } else if (cMonth === 6) {
      return "June";
    } else if (cMonth === 7) {
      return "July";
    } else if (cMonth === 8) {
      return "August";
    } else if (cMonth === 9) {
      return "September";
    } else if (cMonth === 10) {
      return "October";
    } else if (cMonth === 11) {
      return "November";
    } else if (cMonth === 12) {
      return "December";
    }
  };

  const bindData = (data, mm, yyyy) => {
    const arr = [];

    var arr1 = {
      day: 0,
      projectManagerName: "",
      projectName: "",
    };
    var month = "";
    var year = "";
    var d = "";

    if (mm === 0) {
      year = parseInt(yyyy) - 1;
      month = 12;
      setDay(month, year);
    } else {
      if (mm === null) {
        month = new Date().getMonth() + 1;
        year = new Date().getFullYear();
        setDay(month, year);
      } else {
        year = yyyy;
        month = mm;
        setDay(month, year);
      }
    }
    setYearInt(year);

    if (mm !== null && mm !== 0) {
      month = mm;
      setDay(month, year);
    }
    //for next
    if (mm === 13) {
      month = 1;
      year = parseInt(yyyy) + 1;
      setYearInt(year);
      setDay(month, year);
    }

    if (yyyy !== null) {
      d = new Date(yyyy, month, 0).getDate();
    } else {
      d = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0
      ).getDate();
    }

    setPrevMonth(month - 1);
    setNextMonth(month + 1);
    setMonthName(MonthNameFn(month));
    for (let index = 0; index < d; index++) {
      var currentDay = index + 1;
      let date = "";
      if (currentDay < 10) {
        if (month > 10) {
          date = year + "-" + month + "-0" + currentDay + "T00:00:00";
        } else {
          date = year + "-0" + month + "-0" + currentDay + "T00:00:00";
        }
      } else {
        if (month < 10) {
          date = year + "-0" + month + "-" + currentDay + "T00:00:00";
        } else {
          date = year + "-" + month + "-" + currentDay + "T00:00:00";
        }
      }
      let arr2 = [];
      // console.log(taskData[0].createDate)
      // var matchData = data.filter((x) => x.createddate <= date);

      var matchData = filterDataByDate(data, year, month, currentDay);

      if (matchData.length !== 0) {
        matchData.forEach((element) => {
          console.log(element.finishdate);
          var project = "";
          if (element.projectName === null) {
            project = element.leadName;
          } else if (element.leadName === null) {
            project = element.projectName;
          }
          if (element.finishdate >= date) {
            var pData = {
              id: element.id,
              headLine: element.headline,
              status: element.status,
              finishdate: formatDate(element.finishdate),
              project: project,
            };
            arr2.push(pData);
          }
        });
      }
      var arr1 = {
        day: currentDay,
        dateData: arr2,
      };
      arr.push(arr1);
    }
    setCalendarData(arr);
  };

  const backbutton = () => {
    props.navigate(-1);
  };

  const setDay = (month, year) => {
    const currentDate1 = new Date(parseInt(year), parseInt(month) - 1, 1); // Get the current date
    const dayName = currentDate1.toLocaleString("en-US", { weekday: "long" });
    setMonthDay(dayName);
  };

  const setMonth = (mm, yyyy) => {
    bindData(taskData, mm, yyyy);
  };

  return (
    <div className="border rounded border-success mt-3">
      {props.name === "assigned" && (
        <div className="container-fluid">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <div className="row">
                <div class="d-flex flex-start align-items-center"></div>
                <div className="col-sm-4 mt-1">
                  <h2>
                    <b className="text-dark">User Calendar</b>
                  </h2>
                </div>
                <div className="col-sm-6"></div>
                <div className="col-sm-2 d-flex flex-row-reverse">
                  <button
                    className="btn btn-info mt-2 ml-1  text-uppercase "
                    onClick={() => backbutton()}
                  >
                    back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="container-fluid mt-2">
        <div className="card shadow border rounded border-success mb-2">
          <div className="card-header d-flex rounded justify-content-between py-3">
            <div className="col-sm-4">
              <h4 className="m-0 font-weight-bold text-dark">
                {" "}
                User Calendar:{" "}
                <b className="text-success">
                  {props.name === "assigned" ? "Assigned Task" : "My Task"}
                </b>
              </h4>
            </div>
            <div className="col-sm-3">
              <h4 className="m-0 font-weight-bold text-dark">
                {" "}
                Month:{" "}
                <b className="text-success">
                  {monthName} {yearInt}
                </b>{" "}
              </h4>
            </div>
            <div className="col-sm-3 d-flex justify-content-end">
              <span
                className=" btn btn-secondary m-0 font-weight-bold mr-2 mt-1"
                onClick={() => setMonth(prevmonth, yearInt)}
              >
                {" "}
                Prev
              </span>
              <span
                className=" btn btn-secondary m-0 font-weight-bold mr-2 mt-1"
                onClick={() => setMonth(nextMonth, yearInt)}
              >
                {" "}
                Next
              </span>
            </div>
          </div>
        </div>

        <div className="row d-flex justify-content-center">
          <div
            style={{
              maxWidth: "100%",
              maxHeight: "67vh",
              overflowX: "auto",
              overflowY: "auto",
            }}
          >
            <div
              className="d-flex border rounded"
              style={{
                width: "2200px",
                background: "rgb(0, 141, 58)",
                color: "white",
              }}
            >
              <h4 className="m1 text-center" style={{ width: "305px" }}>
                <b>Sunday</b>
              </h4>
              <h4 className="m1 text-center" style={{ width: "310px" }}>
                {" "}
                <b>Monday</b>{" "}
              </h4>
              <h4 className="m1 text-center" style={{ width: "306px" }}>
                {" "}
                <b>Tuesday</b>{" "}
              </h4>
              <h4 className="m1 text-center" style={{ width: "310px" }}>
                {" "}
                <b>Wednesday</b>{" "}
              </h4>
              <h4 className="m1 text-center" style={{ width: "300px" }}>
                {" "}
                <b>Thursday</b>{" "}
              </h4>
              <h4 className="m1 text-center" style={{ width: "310px" }}>
                {" "}
                <b>Friday</b>{" "}
              </h4>
              <h4 className="m1 text-center" style={{ width: "325px" }}>
                {" "}
                <b>Saturday</b>{" "}
              </h4>
            </div>
            <div
              className=" d-flex flex-wrap  rounded p-2"
              style={{ width: "2190px", border: "3px solid #5ecb5e" }}
            >
              {monthDay === "Monday" ? (
                <div
                  className="border border-danger ml-1 m-1 rounded rounded"
                  style={{
                    width: "300px",
                    height: "auto",
                    background: "white",
                  }}
                >
                  <div className="text-right text-primary font-weight-bold mr-1"></div>
                </div>
              ) : monthDay === "Tuesday" ? (
                <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                  <div
                    className="border border-danger ml-1 m-1 rounded"
                    style={{
                      width: "300px",
                      height: "auto",
                      background: "white",
                    }}
                  >
                    <div className="text-right text-primary font-weight-bold mr-1"></div>
                  </div>
                  <div
                    className="border border-danger ml-1 m-1 rounded"
                    style={{
                      width: "300px",
                      height: "auto",
                      background: "white",
                    }}
                  >
                    <div className="text-right text-primary font-weight-bold mr-1"></div>
                  </div>
                </div>
              ) : monthDay === "Wednesday" ? (
                <>
                  <div
                    className="border border-danger ml-1 m-1 rounded"
                    style={{
                      width: "300px",
                      height: "auto",
                      background: "white",
                    }}
                  >
                    <div className="text-right text-primary font-weight-bold mr-1"></div>
                  </div>
                  <div
                    className="border border-danger ml-1 m-1 rounded"
                    style={{
                      width: "300px",
                      height: "auto",
                      background: "white",
                    }}
                  >
                    <div className="text-right text-primary font-weight-bold mr-1"></div>
                  </div>
                  <div
                    className="border border-danger ml-1 m-1 rounded"
                    style={{
                      width: "300px",
                      height: "auto",
                      background: "white",
                    }}
                  >
                    <div className="text-right text-primary font-weight-bold mr-1"></div>
                  </div>
                </>
              ) : monthDay === "Thursday" ? (
                <>
                  <div
                    className="border border-danger ml-1 m-1 rounded"
                    style={{
                      width: "300px",
                      height: "auto",
                      background: "white",
                    }}
                  >
                    <div className="text-right text-primary font-weight-bold mr-1"></div>
                  </div>
                  <div
                    className="border border-danger ml-1 m-1 rounded"
                    style={{
                      width: "300px",
                      height: "auto",
                      background: "white",
                    }}
                  >
                    <div className="text-right text-primary font-weight-bold mr-1"></div>
                  </div>
                  <div
                    className="border border-danger ml-1 m-1 rounded"
                    style={{
                      width: "300px",
                      height: "auto",
                      background: "white",
                    }}
                  >
                    <div className="text-right text-primary font-weight-bold mr-1"></div>
                  </div>
                  <div
                    className="border border-danger ml-1 m-1 rounded"
                    style={{
                      width: "300px",
                      height: "auto",
                      background: "white",
                    }}
                  >
                    <div className="text-right text-primary font-weight-bold mr-1"></div>
                  </div>
                </>
              ) : monthDay === "Friday" ? (
                <>
                  <div
                    className="border border-danger ml-1 m-1 rounded"
                    style={{
                      width: "300px",
                      height: "auto",
                      background: "white",
                    }}
                  >
                    <div className="text-right text-primary font-weight-bold mr-1"></div>
                  </div>
                  <div
                    className="border border-danger ml-1 m-1 rounded"
                    style={{
                      width: "300px",
                      height: "auto",
                      background: "white",
                    }}
                  >
                    <div className="text-right text-primary font-weight-bold mr-1"></div>
                  </div>
                  <div
                    className="border border-danger ml-1 m-1 rounded"
                    style={{
                      width: "300px",
                      height: "auto",
                      background: "white",
                    }}
                  >
                    <div className="text-right text-primary font-weight-bold mr-1"></div>
                  </div>
                  <div
                    className="border border-danger ml-1 m-1 rounded"
                    style={{
                      width: "300px",
                      height: "auto",
                      background: "white",
                    }}
                  >
                    <div className="text-right text-primary font-weight-bold mr-1"></div>
                  </div>
                  <div
                    className="border border-danger ml-1 m-1 rounded"
                    style={{
                      width: "300px",
                      height: "auto",
                      background: "white",
                    }}
                  >
                    <div className="text-right text-primary font-weight-bold mr-1"></div>
                  </div>
                </>
              ) : monthDay === "Saturday" ? (
                <>
                  <div
                    className="border border-danger ml-1 m-1 rounded"
                    style={{
                      width: "300px",
                      height: "auto",
                      background: "white",
                    }}
                  >
                    <div className="text-right text-primary font-weight-bold mr-1"></div>
                  </div>
                  <div
                    className="border border-danger ml-1 m-1 rounded"
                    style={{
                      width: "300px",
                      height: "auto",
                      background: "white",
                    }}
                  >
                    <div className="text-right text-primary font-weight-bold mr-1"></div>
                  </div>
                  <div
                    className="border border-danger ml-1 m-1 rounded"
                    style={{
                      width: "300px",
                      height: "auto",
                      background: "white",
                    }}
                  >
                    <div className="text-right text-primary font-weight-bold mr-1"></div>
                  </div>
                  <div
                    className="border border-danger ml-1 m-1 rounded"
                    style={{
                      width: "300px",
                      height: "auto",
                      background: "white",
                    }}
                  >
                    <div className="text-right text-primary font-weight-bold mr-1"></div>
                  </div>
                  <div
                    className="border border-danger ml-1 m-1 rounded"
                    style={{
                      width: "300px",
                      height: "auto",
                      background: "white",
                    }}
                  >
                    <div className="text-right text-primary font-weight-bold mr-1"></div>
                  </div>
                  <div
                    className="border border-danger ml-1 m-1 rounded"
                    style={{
                      width: "300px",
                      height: "auto",
                      background: "white",
                    }}
                  >
                    <div className="text-right text-primary font-weight-bold mr-1"></div>
                  </div>
                </>
              ) : null}
              {calendarData.length > 0
                ? calendarData.map((index) => (
                    <div
                      key={index.day}
                      className="border border-danger rounded px-1  ml-1 m-1"
                      style={{
                        width: "300px",
                        maxHeight: "180px",
                        overflowX: "auto",
                        background: "rgb(179 255 174)",
                      }}
                    >
                      <h5 className="text-right text-dark font-weight-bold mr-1">
                        {index.day} {monthName}
                      </h5>
                      {index.dateData.map((x) => {
                        return (
                          <Link
                            to={`/TaskDetails/${x.id}`}
                            style={{ height: "300px" }}
                          >
                            <div
                              className="border border-success rounded p-1"
                              style={{ color: "black", fontWeight: 700 }}
                            >
                              <b>{x.status}</b>
                              <br />
                              <b>{x.project}</b>
                              <br />
                              <b>{x.finishdate}</b>
                            </div>{" "}
                            <br />
                          </Link>
                        );
                      })}
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCalendarComponent;
