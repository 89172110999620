import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import UserSheetComponent from '../../../Components/Main/DesignerMasterComponent/UserSheetComponent';

function UserSheet(props) {

    let navigate = useNavigate();
    const params = useParams();
    return (
        <div>
            <UserSheetComponent {...props} navigate={navigate} name={params.name} id={params.id} />
        </div>
    )
}
export default UserSheet