import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Service from '../../../Services/Admin/Service';

const AttachContentLinkComponent = (props) => {
    
    let id = sessionStorage.getItem('id');
    const [leadError, setLeadError] = useState(false);
    const [apiError, setApiError] = useState(null);
    const [showError, setShowError] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);
    const [showdata, setShowdata] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState('');
    const [inputField, setInputField] = useState(
        {
            fileid: 0,
            files: "",
            leadid: "",
            createdby: id,
            updatedby:id,
        })


    useEffect(() => {
        getShowData("Shows/GetAllShows");

    })

    const getShowData = (method) => {
        Service.getdata(method).then((res) => {
            setShowdata(res.data);
           
        })
    }

    useEffect(() => {
        if (apiResponse || apiError) {
            const timeoutId = setTimeout(removeSuccessMessage, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [apiResponse, apiError]);

    const removeSuccessMessage = () => {
        setApiResponse(null);
        setApiError(null);
    };

    
   const handleFileChange = (e) => {
      setSelectedFiles(e.target.files[0]);
   };

    const save = async (evt) => {
        if (evt && evt.preventDefault) {
            evt.preventDefault();
        }

        const formData = new FormData(evt.target);

        const data = {}
        for (const [key, value] of formData) {
            data[key] = value;
        }

        function validateData(data) {
            const { files, leadid } = data;

            if (!files || files === "[NONE]") {
                alert("Please fill in all required fields.");
                return false;
            }

            if (!leadid || leadid === "[NONE]") {
                setLeadError(true);
                return false;
            } else {
                setLeadError(false);
            }

            return true;
        }

        if (!validateData(data)) {
            return;
        }

        const formData1 = new FormData();
        formData1.append("files", selectedFiles);

        const task = {
            ...inputField,
            ...data,
        };
        console.log(task);
        debugger
        if (task) {
            Service.createorupdateFile("Presentation/CreateOrUpdate?Leadid="+ data.leadid + "&createdby=" + id, formData1)
                .then(res => {
                    setApiResponse(res.data); // Set the API response in state
                    window.location.href = "/PPTList";
                })
                .catch(error => {
                    // Handle error if API call fails
                    console.error("API Error:", error);
                    setApiError("Error occurred while saving the meeting. Please try again later.");
                });
        }
    }

    const backbutton = () => {
        props.navigate(-1);
    }
  return (
    <div>
            <div className="container-fluid">

                <br />

                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col-sm-3"><h3>Add Content Link</h3></div>
                            <div className="col-sm-6"></div>
                            <div className="col-sm-3">
                                {/* <button className="btn btn-primary" type='submit'>Save</button> */}
                                <Link to="/AdminDashboard" className="btn btn-secondary mt-1 ml-2 btn-sm">Home</Link>
                                <button className="btn btn-info mt-1 ml-1 btn-sm" onClick={() => backbutton()}>back</button>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="card cardUser shadow mb-4" >
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Add</h6>
                            {apiResponse && (
                                <div className="alert alert-success" role="alert">
                                    Attachment has been uploaded successfully!
                                </div>
                            )}
                            {apiError && (
                                <div className="alert alert-danger" role="alert">
                                    {apiError}
                                </div>
                            )}
                        </div>

                        <div class="container">
                            <form onSubmit={save} class="form-horizontal mt-2">
                                
                                    
                                    <div className="form-group form-group-h">
                                        <div className='col-sm-9 required-label'>ShowList</div>
                                        <div className='col-sm-9'>
                                            {showError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a Show.</div>}
                                            <select className="form-control form-control-fs  form-control-user" name="showId"  required>
                                                <option>[NONE]</option>
                                                {
                                                    showdata.map((e, key) => {
                                                        return <option key={e.showChapterId} value={e.showChapterId}>{e.chapterName}</option>;
                                                    })}
                                            </select>
                                        </div>
                                    </div>
                               
                                <div class="form-group">
                                    <label for="field" class="col-sm-3 required-label  control-label">Attach Link</label>
                                    <div class="col-sm-9">
                                    <input type="text" className="form-control form-control-user" id="headline"
                                            name="headline" placeholder="Enter Drive Link" required />
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary ml-3 mb-3">Upload</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default AttachContentLinkComponent