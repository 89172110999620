import {useState,useEffect} from "react";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory,{textFilter} from 'react-bootstrap-table2-filter';
import { Link } from 'react-router-dom'; 
import ApiMethod from '../../../Helpers/ApiMethod/Method'
import Service from "../../../Services/Admin/Service";

function AssignProjectManagerlist(){
    const[data, setData] = useState([]);
   // const apis = ApiMethod.find(o => o.name === 'Category');

    useEffect(()=>{
        getData("AssignProject/GetAll");
    },[])

   const getData = (method) =>{
        Service.getdata(method).then((res) => {            
            setData(res.data);      
            console.log("21",res.data)      
        });
   }

  

  const linkformatter = (data,row)=>{
    return <>
     <Link className="btn btn-warning btn-sm" to={`/Detail/${data}`}>Details</Link>
     </>
    }

    const column = [  
        { dataField: 'projectName',text: 'Project Name',sort:true },
        { dataField: 'userName',text: 'User Name',sort:true },        
        { dataField: 'roleName', text: 'Role Name' },        
        { dataField: 'assignedUserName', text: 'Assigner Name' },
       
    ]

    return (  
        <div> 
            <div className="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                         <div className="row">
                             <div className="col-sm-6"><h3>All Project Assign User</h3></div>
                             <div className="col-sm-3"></div>
                             <div className="col-sm-3">
                                <Link className="btn btn-info btn-primary" to={`/AddAssignUser`}>Project Assign User</Link>
                             </div>
                        </div>
                    </div>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                                    <h6 className="m-0 font-weight-bold text-primary">Details Of Project Assign User</h6>
                                  {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
                      </div>
                      
                      <div className="card-body">
                          <div className="table-responsive">
                          <BootstrapTable keyField="id" 
                            data={data} 
                            columns={column} 
                           
                            pagination={paginationFactory()} 
                            // filter={filterFactory()}
                             />
                          </div>
                      </div>
                </div>
            </div> 
              
        </div>  
     ); 
}

export default AssignProjectManagerlist