import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState, convertFromHTML, ContentState, convertFromRaw } from "draft-js";
import Service from '../../../Services/Admin/Service';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { Link } from 'react-router-dom';

const CreateBoqComponent = (props) => {

    let id = sessionStorage.getItem('id');
    const [project, setProject] = useState([]);
    const [editorContentValid, setEditorContentValid] = useState(true);
    const [projectError, setProjectError] = useState(false);
    const [typeError, setTypeError] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);
    const [boqDetails, setBoqDetails] = useState('');
    const [Projectid, setProjectid] = useState('');
    const [boqLink, setBoqLink] = useState('');
    const [typeid, settypeid] = useState('');

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const [apiError, setApiError] = useState(null);
    const [inputField, setInputField] = useState(
        {
            id: props.id,
            boqdetails: "",
            projectid: 0,
            boqType: "",
            createdby: id,
            updatedBy: id
        }
    )

    const [inputplatforming, setplatforming] = useState([{
        platforming: '',
        qty: '',
        unit: '',
    }]);

    const addplatforming = () => {
        setplatforming([...inputplatforming, {
            platforming: '',
            qty: '',
            unit: '',
        }])
    }

    const removeplatforming = (index) => {
        const rows = [...inputplatforming];
        rows.splice(index, 1);
        setplatforming(rows);
    }

    const handleChangeplatforming = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputplatforming];
        list[index][name] = value;
        setplatforming(list);
    }

    const [inputwalling, setwalling] = useState([{
        walling: '',
        qty: '',
        unit: '',
    }]);

    const addwalling = () => {
        setwalling([...inputwalling, {
            walling: '',
            qty: '',
            unit: '',
        }])
    }

    const removewalling = (index) => {
        const rows = [...inputwalling];
        rows.splice(index, 1);
        setwalling(rows);
    }


    const handleChangewalling = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputwalling];
        list[index][name] = value;
        setwalling(list);
    }

    const [inputaudiovisuals, setaudiovisuals] = useState([{
        audiovisuals: '',
        qty: '',
        unit: '',
    }]);

    const addaudiovisuals = () => {
        setaudiovisuals([...inputaudiovisuals, {
            audiovisuals: '',
            qty: '',
            unit: '',
        }])
    }

    const removeaudiovisuals = (index) => {
        const rows = [...inputaudiovisuals];
        rows.splice(index, 1);
        setaudiovisuals(rows);
    }


    const handleChangeaudiovisuals = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputaudiovisuals];
        list[index][name] = value;
        setaudiovisuals(list);
    }

    const [inputhospitality, sethospitality] = useState([{
        hospitality: '',
        qty: '',
        unit: '',
    }]);

    const addhospitality = () => {
        sethospitality([...inputhospitality, {
            hospitality: '',
            qty: '',
            unit: '',
        }])
    }

    const removehospitality = (index) => {
        const rows = [...inputhospitality];
        rows.splice(index, 1);
        sethospitality(rows);
    }


    const handleChangehospitality = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputhospitality];
        list[index][name] = value;
        sethospitality(list);
    }
    const [inputlighting, setlighting] = useState([{
        lighting: '',
        qty: '',
        unit: '',
    }]);

    const addlighting = () => {
        setlighting([...inputlighting, {
            lighting: '',
            qty: '',
            unit: '',
        }])
    }

    const removelighting = (index) => {
        const rows = [...inputlighting];
        rows.splice(index, 1);
        setlighting(rows);
    }


    const handleChangelighting = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputlighting];
        list[index][name] = value;
        setlighting(list);
    }

    const [inputbranding, setbranding] = useState([{
        branding: '',
        qty: '',
        unit: '',
    }]);

    const addbranding = () => {
        setbranding([...inputbranding, {
            branding: '',
            qty: '',
            unit: '',
        }])
    }

    const removebranding = (index) => {
        const rows = [...inputbranding];
        rows.splice(index, 1);
        setbranding(rows);
    }


    const handleChangebranding = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputbranding];
        list[index][name] = value;
        setbranding(list);
    }

    const [inputsuspension, setsuspension] = useState([{
        suspension: '',
        qty: '',
        unit: '',
    }]);

    const addsuspension = () => {
        setsuspension([...inputsuspension, {
            suspension: '',
            qty: '',
            unit: '',
        }])
    }
    const removesuspension = (index) => {
        const rows = [...inputsuspension];
        rows.splice(index, 1);
        setsuspension(rows);
    }


    const handleChangesuspension = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputsuspension];
        list[index][name] = value;
        setsuspension(list);
    }

    const [inputmezzanine, setmezzanine] = useState([{
        mezzanine: '',
        qty: '',
        unit: '',
    }]);

    const addmezzanine = () => {
        setmezzanine([...inputmezzanine, {
            mezzanine: '',
            qty: '',
            unit: '',
        }])
    }

    const removemezzanine = (index) => {
        const rows = [...inputmezzanine];
        rows.splice(index, 1);
        setmezzanine(rows);
    }


    const handleChangemezzanine = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputmezzanine];
        list[index][name] = value;
        setmezzanine(list);
    }

    const [inputothers, setinputothers] = useState([{
        others: '',
        qty: '',
        unit: '',
    }]);

    const addothers = () => {
        setinputothers([...inputothers, {
            others: '',
            qty: '',
            unit: '',
        }])
    }

    const removeothers = (index) => {
        const rows = [...inputothers];
        rows.splice(index, 1);
        setinputothers(rows);
    }


    const handleChangeothers = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputothers];
        list[index][name] = value;
        setinputothers(list);
    }

    const externalLink = (data) => {
        debugger
        window.open(data, '_blank')
    }


    useEffect(() => {
        getProjectData("Project/GetAll");
        if (props.id !== 0 && props.id !== undefined) {
            getProjectDatabyId("Boq/GetById?id=" + props.id, '');
        }
    }, [])

    const getProjectData = (method) => {
        Service.getdata(method).then((res) => {
            setProject(res.data);
        });
    }

    const getProjectDatabyId = (method, id) => {
        Service.getdatabyId(method, id).then((res) => {
            setBoqDetails(res.data);
            console.log('Boq', res.data);
            setBoqLink(res.data.boqLink);
            setProjectid(res.data.projectid);
            settypeid(res.data.boqType);
            setplatforming(res.data.inputplatforming)
            setwalling(res.data.inputwalling)
            setaudiovisuals(res.data.inputaudiovisuals)
            sethospitality(res.data.inputhospitality)
            setlighting(res.data.inputlighting)
            setbranding(res.data.inputbranding)
            setsuspension(res.data.inputsuspension)
            setmezzanine(res.data.inputmezzanine)
            setinputothers(res.data.inputothers)

            const content1 = convertFromRaw(JSON.parse(res.data.boqdetails));

            setEditorState(EditorState.createWithContent(content1));

        });
    }


    useEffect(() => {
        if (apiResponse || apiError) {
            const timeoutId = setTimeout(removeSuccessMessage, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [apiResponse, apiError]);

    const removeSuccessMessage = () => {
        setApiResponse(null);
        setApiError(null);
    };

    const backbutton = () => {
        props.navigate(-1);
    }

    const handleEditorChange = (state) => {
        setEditorState(state);
        setEditorContentValid(true);
    }

    const handleInputProject = (e) => {
        setProjectid(e.target.value);
    }

    const handleInputType = (e) => {
        settypeid(e.target.value);
    }


    const save = async (evt) => {
        if (evt && evt.preventDefault) {
            evt.preventDefault();
        }

        const formData = new FormData(evt.target);

        const data = {}
        for (const [key, value] of formData) {
            data[key] = value;
        }

        function validateData(data) {
            const { projectid, boqType } = data;

            const currentContent = editorState.getCurrentContent();
            if (!currentContent.hasText()) {
                setEditorContentValid(false);
                return; // Abort save
            }

            if (!projectid || projectid === "[NONE]") {
                setProjectError(true);
                return false;
            } else {
                setProjectError(false);
            }

            // if (!boqType || boqType === "[NONE]") {
            //     setTypeError(true);
            //     return false;
            // } else {
            //     setTypeError(false);
            // }

            return true
        }

        if (!validateData(data)) {
            return;
        }


        const currentContent = editorState.getCurrentContent();
        const messageBody = convertToRaw(currentContent);
        var jsonData = JSON.stringify(messageBody);
        // const loadData = JSON.parse(jsonData);
        // var content=loadData.blocks[0].text;
        let currentContentAsHTML = draftToHtml(messageBody);
        //  inputField.ContentJosn = jsonData;

        inputField.boqdetails = jsonData;

        const task = {
            ...inputField,
            ...data,
            inputplatforming,
            inputwalling,
            inputaudiovisuals,
            inputhospitality,
            inputlighting,
            inputbranding,
            inputsuspension,
            inputmezzanine,
            inputothers
        };
        console.log(task)
        debugger
        if (task) {

            Service.createorupdate("Boq/CreateOrUpdate", task)
                .then(res => {
                    setApiResponse(res.data); // Set the API response in state
                    // window.location.href = "/BoqList";


                })
                .catch(error => {
                    // Handle error if API call fails
                    console.error("API Error:", error);
                    setApiError("Error occurred while saving the boq. Please try again later.");
                });
        }
    }

    const handleKeyPress = (event, callback) => {
        // Check if the key pressed is Enter (key code 13)
        if (event.key === 'Enter') {
            // Prevent the default behavior of the Enter key (form submission)
            event.preventDefault();

            // Invoke the callback function (e.g., addplatforming or removeplatforming)
            callback();
        }
    };


    return (
        <div>
            <div className="container-fluid">

                <div className="card shadow border-success mb-2">
                    <div className="card-header rounded py-3">
                        <div className="row">
                            <div class="d-flex flex-start align-items-center">

                            </div>
                            <div className="col-sm-3"><h2><b className='text-dark'>New Boq</b></h2></div>
                            <div className="col-sm-7"></div>
                            <div className="col-sm-2 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid text-dark">
                <div className="card cardUser shadow mb-4" >
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-success">Add/Update Boq</h6>
                        {apiResponse && (
                            <div className="alert alert-success" role="alert">
                                Boq has been created successfully!
                            </div>
                        )}
                        {apiError && (
                            <div className="alert alert-danger" role="alert">
                                {apiError}
                            </div>
                        )}
                    </div>

                    <div class="container">
                        <form onSubmit={save} class="form-horizontal mt-2">
                            <div className="form-group row">

                                <div className="col-sm-12 required-label ml-3"><b>Boq Details</b></div>
                                <div className='col-sm-11 ml-3'>

                                    {!editorContentValid && (
                                        <div className="alert alert-danger ml-3 pl-2 mt-2" role="alert">
                                            Boq details are required.
                                        </div>
                                    )}
                                    <Editor
                                        editorState={editorState}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        onEditorStateChange={handleEditorChange}
                                        required
                                    />
                                </div>

                            </div>

                            <div className="form-group ">
                                <div className='col-sm-11 required-label'><b>Project</b></div>
                                <div className='col-sm-11'>
                                    {projectError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a project.</div>}
                                    <select className="form-control  form-control-user" value={Projectid} name="projectid" onChange={handleInputProject} required>
                                        <option>[NONE]</option>
                                        {
                                            project.map((e, key) => {
                                                return <option key={e.id} value={e.id}>{e.projectname}</option>;
                                            })}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group ">
                                <div className='col-sm-11 required-label'><b>Boq Link</b></div>
                                <div className='col-sm-11'>
                                    <textarea
                                        rows={1} // You can adjust the number of visible rows
                                        cols={100}
                                        name='boqLink'
                                        placeholder="enter Boq attachment Link if any" // You can adjust the number of visible columns
                                        class="form-control size1"
                                        autofocus
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className='btn btn-sm ml-3 btn-info' onClick={() => externalLink(boqLink)} >Open Additional Link</div>
                            </div>
                            {/* <div class="form-group">
                                <label for="boqType" class="col-sm-3 required-label  control-label">Boq Type</label>
                                <div class="col-sm-11">
                                    {typeError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a BoqType.</div>}

                                    <select className="form-control  form-control-user" value={typeid} name="boqType" onChange={handleInputType} required>
                                        <option>[NONE]</option>
                                        <option>Vendor</option>
                                        <option>Client</option>
                                    </select>
                                </div>
                            </div> */}
                            <div className="form-group row">
                                <div className="col-sm-12  "><b>Platform & Flooring</b></div>
                            </div>
                            {
                                inputplatforming.map((data, index) => {
                                    const { platforming, qty, unit } = data;
                                    return (
                                        <div>

                                            <div className="form-group row" key={index}>

                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangeplatforming(index, evnt)} value={platforming} name="platforming" className="form-control" placeholder="Enter Platform & Flooring" />
                                                </div>
                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangeplatforming(index, evnt)} value={qty} name="qty" className="form-control" placeholder="Enter qty" />

                                                </div>
                                                <div className='col-sm-3'>
                                                    <select type="text" onChange={(evnt) => handleChangeplatforming(index, evnt)} value={unit} name="unit" className="form-control" placeholder="Enter unit" >
                                                        <option>[NONE]</option>
                                                        <option>rmt</option>
                                                        <option>sqm</option>
                                                        <option>nos</option>
                                                        <option>set</option>
                                                    </select>
                                                </div>

                                                <div className='col-sm-2' >
                                                    <span
                                                        className="btn btn-outline-success"
                                                        tabIndex="0"
                                                        onClick={addplatforming}
                                                        onKeyDown={(evnt) => handleKeyPress(evnt, addplatforming)}
                                                    >
                                                        Add Row
                                                    </span>

                                                    <span
                                                        className="btn btn-sm btn-outline-danger ml-2"
                                                        tabIndex="0"
                                                        onClick={(evnt) => removeplatforming(index, evnt)}
                                                        onKeyDown={(evnt) => handleKeyPress(evnt, () => removeplatforming(index, evnt))}
                                                    >
                                                        ×
                                                    </span>

                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            <div className="form-group row">
                                <div className="col-sm-12  "><b>Walling & Special Construction</b></div>
                            </div>
                            {
                                inputwalling.map((data, index) => {
                                    const { walling, qty, unit } = data;
                                    return (
                                        <div>

                                            <div className="form-group row" key={index}>

                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangewalling(index, evnt)} value={walling} name="walling" className="form-control" placeholder="Enter Walling & Special Construction" />
                                                </div>
                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangewalling(index, evnt)} value={qty} name="qty" className="form-control" placeholder="Enter qty" />

                                                </div>
                                                <div className='col-sm-3'>
                                                    <select type="text" onChange={(evnt) => handleChangewalling(index, evnt)} value={unit} name="unit" className="form-control" placeholder="Enter unit" >
                                                        <option>[NONE]</option>
                                                        <option>rmt</option>
                                                        <option>sqm</option>
                                                        <option>nos</option>
                                                        <option>set</option>
                                                    </select>
                                                </div>

                                                <div className='col-sm-2'>
                                                    <span
                                                        className="btn btn-outline-success"
                                                        onClick={addwalling}
                                                        onKeyDown={(evnt) => handleKeyPress(evnt, addwalling)}
                                                        tabIndex="0"
                                                    >
                                                        Add Row
                                                    </span>

                                                    <span
                                                        className="btn btn-sm btn-outline-danger ml-2"
                                                        onClick={(evnt) => removewalling(index, evnt)}
                                                        onKeyDown={(evnt) => handleKeyPress(evnt, () => removewalling(index, evnt))}
                                                        tabIndex="0"
                                                    >
                                                        ×
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            <div className="form-group row">
                                <div className="col-sm-12  "><b>Audio Visuals</b></div>
                            </div>
                            {
                                inputaudiovisuals.map((data, index) => {
                                    const { audiovisuals, qty, unit } = data;
                                    return (
                                        <div>

                                            <div className="form-group row" key={index}>

                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangeaudiovisuals(index, evnt)} value={audiovisuals} name="audiovisuals" className="form-control" placeholder="Enter Audio Visuals" />
                                                </div>
                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangeaudiovisuals(index, evnt)} value={qty} name="qty" className="form-control" placeholder="Enter qty" />

                                                </div>
                                                <div className='col-sm-3'>
                                                    <select type="text" onChange={(evnt) => handleChangeaudiovisuals(index, evnt)} value={unit} name="unit" className="form-control" placeholder="Enter unit" >
                                                        <option>[NONE]</option>
                                                        <option>rmt</option>
                                                        <option>sqm</option>
                                                        <option>nos</option>
                                                        <option>set</option>
                                                    </select>
                                                </div>

                                                <div className='col-sm-2'>
                                                    <span className="btn btn-outline-success " onClick={addaudiovisuals}>Add Row</span>
                                                    <span className="btn btn-sm btn-outline-danger ml-2" onClick={(evnt) => removeaudiovisuals(index, evnt)} >×</span>
                                                </div>

                                            </div>
                                        </div>
                                    )
                                })
                            }

                            <div className="form-group row">
                                <div className="col-sm-12  "><b>Furniture & Hospitality Items</b></div>
                            </div>
                            {
                                inputhospitality.map((data, index) => {
                                    const { hospitality, qty, unit } = data;
                                    return (
                                        <div>

                                            <div className="form-group row" key={index}>

                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangehospitality(index, evnt)} value={hospitality} name="hospitality" className="form-control" placeholder="Enter Furniture & Hospitality Items" />
                                                </div>
                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangehospitality(index, evnt)} value={qty} name="qty" className="form-control" placeholder="Enter qty" />

                                                </div>
                                                <div className='col-sm-3'>
                                                    <select type="text" onChange={(evnt) => handleChangehospitality(index, evnt)} value={unit} name="unit" className="form-control" placeholder="Enter unit" >
                                                        <option>[NONE]</option>
                                                        <option>rmt</option>
                                                        <option>sqm</option>
                                                        <option>nos</option>
                                                        <option>set</option>
                                                    </select>
                                                </div>

                                                <div className='col-sm-2'>
                                                    <span className="btn btn-outline-success " onClick={addhospitality}>Add Row</span>
                                                    <span className="btn btn-sm btn-outline-danger ml-2" onClick={(evnt) => removehospitality(index, evnt)} >×</span>
                                                </div>



                                            </div>


                                        </div>
                                    )
                                })
                            }

                            <div className="form-group row">
                                <div className="col-sm-12  "><b>Lighting & Cabling</b></div>
                            </div>
                            {
                                inputlighting.map((data, index) => {
                                    const { lighting, qty, unit } = data;
                                    return (
                                        <div>

                                            <div className="form-group row" key={index}>

                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangelighting(index, evnt)} value={lighting} name="lighting" className="form-control" placeholder="Enter Lighting & Cabling" />
                                                </div>
                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangelighting(index, evnt)} value={qty} name="qty" className="form-control" placeholder="Enter qty" />

                                                </div>
                                                <div className='col-sm-3'>
                                                    <select type="text" onChange={(evnt) => handleChangelighting(index, evnt)} value={unit} name="unit" className="form-control" placeholder="Enter unit" >
                                                        <option>[NONE]</option>
                                                        <option>rmt</option>
                                                        <option>sqm</option>
                                                        <option>nos</option>
                                                        <option>set</option>
                                                    </select>
                                                </div>

                                                <div className='col-sm-2'>
                                                    <span
                                                        className="btn btn-outline-success"
                                                        onClick={addhospitality}
                                                        onKeyDown={(evnt) => handleKeyPress(evnt, addhospitality)}
                                                        tabIndex="0"
                                                    >
                                                        Add Row
                                                    </span>

                                                    <span
                                                        className="btn btn-sm btn-outline-danger ml-2"
                                                        onClick={(evnt) => removehospitality(index, evnt)}
                                                        onKeyDown={(evnt) => handleKeyPress(evnt, () => removehospitality(index, evnt))}
                                                        tabIndex="0"
                                                    >
                                                        ×
                                                    </span>
                                                </div>




                                            </div>


                                        </div>
                                    )
                                })
                            }

                            <div className="form-group row">
                                <div className="col-sm-12  "><b>Branding & Graphics</b></div>
                            </div>
                            {
                                inputbranding.map((data, index) => {
                                    const { branding, qty, unit } = data;
                                    return (
                                        <div>

                                            <div className="form-group row" key={index}>

                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangebranding(index, evnt)} value={branding} name="branding" className="form-control" placeholder="Enter Branding & Graphics" />
                                                </div>
                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangebranding(index, evnt)} value={qty} name="qty" className="form-control" placeholder="Enter qty" />

                                                </div>
                                                <div className='col-sm-3'>
                                                    <select type="text" onChange={(evnt) => handleChangebranding(index, evnt)} value={unit} name="unit" className="form-control" placeholder="Enter unit" >
                                                        <option>[NONE]</option>
                                                        <option>rmt</option>
                                                        <option>sqm</option>
                                                        <option>nos</option>
                                                        <option>set</option>
                                                    </select>
                                                </div>

                                                <div className='col-sm-2'>
                                                    <span
                                                        className="btn btn-outline-success"
                                                        onClick={addbranding}
                                                        onKeyDown={(evnt) => handleKeyPress(evnt, addbranding)}
                                                        tabIndex="0"
                                                    >
                                                        Add Row
                                                    </span>

                                                    <span
                                                        className="btn btn-sm btn-outline-danger ml-2"
                                                        onClick={(evnt) => removebranding(index, evnt)}
                                                        onKeyDown={(evnt) => handleKeyPress(evnt, () => removebranding(index, evnt))}
                                                        tabIndex="0"
                                                    >
                                                        ×
                                                    </span>
                                                </div>




                                            </div>


                                        </div>
                                    )
                                })
                            }

                            {/* <div className="form-group row">
                                <div className="col-sm-12  ">Suspension</div>
                            </div>
                            {
                                inputsuspension.map((data, index) => {
                                    const { suspension, qty, unit } = data;
                                    return (
                                        <div>

                                            <div className="form-group row" key={index}>

                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangesuspension(index, evnt)} value={suspension} name="suspension" className="form-control" placeholder="Enter Platforming & Floring" />
                                                </div>
                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangesuspension(index, evnt)} value={qty} name="qty" className="form-control" placeholder="Enter qty" />

                                                </div>
                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangesuspension(index, evnt)} value={unit} name="unit" className="form-control" placeholder="Enter unit" />
                                                </div>

                                                <div className='col-sm-2'>
                                                    <span className="btn btn-outline-success " onClick={addsuspension}>Add Row</span>
                                                </div>



                                            </div>

                                            <div className="col">   
                        {(inputmultiFields.length!==1)? <button className="btn btn-outline-danger ml-2" onClick={removeInputFields}>x</button>:''}                  
                 
                    </div>
                                        </div>
                                    )
                                })
                            } */}

                            {/* <div className="form-group row">
                                <div className="col-sm-12  ">Mezzanine</div>
                            </div>
                            {
                                inputmezzanine.map((data, index) => {
                                    const { mezzanine, qty, unit } = data;
                                    return (
                                        <div>

                                            <div className="form-group row" key={index}>

                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangemezzanine(index, evnt)} value={mezzanine} name="mezzanine" className="form-control" placeholder="Enter Platforming & Floring" />
                                                </div>
                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangemezzanine(index, evnt)} value={qty} name="qty" className="form-control" placeholder="Enter qty" />

                                                </div>
                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangemezzanine(index, evnt)} value={unit} name="unit" className="form-control" placeholder="Enter unit" />
                                                </div>

                                                <div className='col-sm-2'>
                                                    <span className="btn btn-outline-success " onClick={addmezzanine}>Add Row</span>
                                                </div>



                                            </div>

                                            <div className="col">   
                        {(inputmultiFields.length!==1)? <button className="btn btn-outline-danger ml-2" onClick={removeInputFields}>x</button>:''}                  
                 
                    </div>
                                        </div>
                                    )
                                })
                            } */}

                            <div className="form-group row">
                                <div className="col-sm-12  "><b>Other</b></div>
                            </div>
                            {
                                inputothers.map((data, index) => {
                                    const { others, qty, unit } = data;
                                    return (
                                        <div>

                                            <div className="form-group row" key={index}>

                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangeothers(index, evnt)} value={others} name="others" className="form-control" placeholder="Enter Other" />
                                                </div>
                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangeothers(index, evnt)} value={qty} name="qty" className="form-control" placeholder="Enter qty" />

                                                </div>
                                                <div className='col-sm-3'>
                                                    <select type="text" onChange={(evnt) => handleChangeothers(index, evnt)} value={unit} name="unit" className="form-control" placeholder="Enter unit" >
                                                        <option>[NONE]</option>
                                                        <option>rmt</option>
                                                        <option>sqm</option>
                                                        <option>nos</option>
                                                        <option>set</option>
                                                    </select>
                                                </div>

                                                <div className='col-sm-2'>
                                                    <span
                                                        className="btn btn-outline-success"
                                                        onClick={addothers}
                                                        onKeyDown={(evnt) => handleKeyPress(evnt, addothers)}
                                                        tabIndex="0"
                                                    >
                                                        Add Row
                                                    </span>

                                                    <span
                                                        className="btn btn-sm btn-outline-danger ml-2"
                                                        onClick={(evnt) => removeothers(index, evnt)}
                                                        onKeyDown={(evnt) => handleKeyPress(evnt, () => removeothers(index, evnt))}
                                                        tabIndex="0"
                                                    >
                                                        ×
                                                    </span>
                                                </div>

                                            </div>


                                        </div>
                                    )
                                })
                            }
                            {apiResponse && (
                                <div className="alert alert-success" role="alert">
                                    Boq has been Created Successfully!
                                </div>
                            )}
                            {apiError && (
                                <div className="alert alert-danger" role="alert">
                                    {apiError}
                                </div>
                            )}
                             {!editorContentValid && (
                                        <div className="alert alert-danger ml-3 pl-2 mt-2" role="alert">
                                            Boq details are required.
                                        </div>
                                    )}
                            <button type="submit" class="btn btn-primary ml-3 mb-3">Save</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateBoqComponent