import { Link } from "react-router-dom";

function HomeComponent(props) {

    const backbutton = () => {
        props.navigate(-1);
    }
    return (<>
        <div className="container-fluid">
            {/* <div className='row mb-4'>
          <div className='col-sm-9 text-left'></div>
          <div className='col-sm-3'><button className="btn btn-info btn-sm" onClick={() => backbutton()}>back</button></div>
        </div> */}
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <div className="row">
                        <div class="d-flex flex-start align-items-center">
                        </div>
                        <div className="col-sm-12 text-center"><h2>Details of SOP</h2></div>

                    </div>
                </div>
            </div>

            <div className="card shadow mb-2">
                <div className="card-header py-2">
                    <div className="row">
                        <div class="d-flex flex-start align-items-center">
                        </div>
                        <div className="col-sm-9"><h4>Dashboard</h4></div>
                        <div className="col-sm-3"><Link to="/AdminDashboard" className="btn btn-success btn-sm">Click Here</Link></div>
                    </div>
                </div>
            </div>

            <div className="row">

            <div className="col-md-6">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                            <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>User List</h4></div>
                                <div className="col-sm-3"><Link to="/UserList" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                        <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>Create User</h4></div>
                                <div className="col-sm-3"><Link to="/CreateUser" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                            <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>Role List</h4></div>
                                <div className="col-sm-3"><Link to="/RolesList" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                        <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>Create Role</h4></div>
                                <div className="col-sm-3"><Link to="/CreateRole" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-md-6">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                            <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>Task List</h4></div>
                                <div className="col-sm-3"><Link to="/Kanban" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                        <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>Create Task</h4></div>
                                <div className="col-sm-3"><Link to="/CreateTask" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            
                
                <div className="col-md-6">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                        <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>Projects</h4></div>
                                <div className="col-sm-3"><Link to="/Project" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                            <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>Create Project</h4></div>
                                <div className="col-sm-3"><Link to="/AddProject" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            

            
                <div className="col-md-6">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                            <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>Meetings</h4></div>
                                <div className="col-sm-3"><Link to="/MeetingList" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                        <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>Create Meetings</h4></div>
                                <div className="col-sm-3"><Link to="/CreateMeeting" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            

            
                <div className="col-md-6">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                            <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>Boqs</h4></div>
                                <div className="col-sm-3"><Link to="/BoqList" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                        <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>Create Boq</h4></div>
                                <div className="col-sm-3"><Link to="/CreateBoq" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                        <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>Agreement</h4></div>
                                <div className="col-sm-3"><Link to="/AgreementList" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                        <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>Create Agreement</h4></div>
                                <div className="col-sm-3"><Link to="/CreateAgreement" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                        <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>Project Permits and Passes</h4></div>
                                <div className="col-sm-3"><Link to="/PermitPasessList" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                        <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>Create Permits and Passes</h4></div>
                                <div className="col-sm-3"><Link to="/CreatePermitAndPasses" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                        <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>Site Status Details</h4></div>
                                <div className="col-sm-3"><Link to="/Project" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                        <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>Create Site Status</h4></div>
                                <div className="col-sm-3"><Link to="/Project" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                        <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>Create Extracted Data</h4></div>
                                <div className="col-sm-3"><Link to="/CreateExtractedData" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                        <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>Extracted Data List</h4></div>
                                <div className="col-sm-3"><Link to="/ExtractedDataList" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                        <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>Approved Data List</h4></div>
                                <div className="col-sm-3"><Link to="/Project" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                        <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>Create Vendor</h4></div>
                                <div className="col-sm-3"><Link to="/CreateVandour" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                        <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>Vendor List</h4></div>
                                <div className="col-sm-3"><Link to="/VendorList" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                        <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>Create Selected Vendor</h4></div>
                                <div className="col-sm-3"><Link to="/CreateSelectVendor" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                        <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>Selected Vendor List</h4></div>
                                <div className="col-sm-3"><Link to="/SelectVendorList" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                        <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>Upload Site Photo And video</h4></div>
                                <div className="col-sm-3"><Link to="/SitePhotosAndVideo" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                        <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>Site Photo And video List</h4></div>
                                <div className="col-sm-3"><Link to="/SitePhotosAndVideosList" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                        <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>Unloading Material List</h4></div>
                                <div className="col-sm-3"><Link to="/SitePhotosAndVideosList" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card shadow mb-2">
                        <div className="card-header py-2">
                        <div className="row">
                                <div class="d-flex flex-start align-items-center">
                                </div>
                                <div className="col-sm-9"><h4>Create Unloading Material </h4></div>
                                <div className="col-sm-3"><Link to="/SitePhotosAndVideosList" className="btn btn-success btn-sm">Click Here</Link></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </>
    );
}
export default HomeComponent