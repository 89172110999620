import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import Service from "../../../Services/Admin/Service";
import React, { useState, useEffect } from 'react'

const MeetingListComponent = (props) => {


    const [data, setData] = useState([]);

    let userid = sessionStorage.getItem('id')
    useEffect(() => {

        getVendorData("Meeting/GetByUserID?userid=" + userid);
       

    }, [])

    const getVendorData = (method) => {
        Service.getdata(method).then((res) => {
            const task = filterTasksWithUniqueIds(res.data)
            setData(task);
            debugger
        });
    }

    function filterTasksWithUniqueIds(tasks) {
        // Create an object to store unique tasks based on their ID
        const uniqueTasks = tasks.reduce((acc, task) => {
            // Use the task ID as the key in the object
            const taskId = task.id;

            // If the task ID is not in the object, add it
            if (!acc[taskId]) {
                acc[taskId] = task;
            }

            return acc;
        }, {});

        // Convert the uniqueTasks object back to an array
        const uniqueTasksArray = Object.values(uniqueTasks);

        return uniqueTasksArray;
    }

    const backbutton = () => {
        props.navigate(-1);
    }

    const linkformatter = (data, row) => {
        return <>
            {
                row.showid !== 0 && row.showid !== null ?
                    row.createdby === userid || userid === "27" || userid === "60" ? <Link className="btn btn-warning btn-sm m-1" to={`/CreateMeetingMarketing/${data}`}>Edit</Link>
                        :
                        null
                    :
                    null
            }
            
            {
                row.projectId !== 0 && row.projectId !== null ?
                    row.createdby === userid ? <Link className="btn btn-warning btn-sm m-1" to={`/CreateMeeting/${data}`}>Edit</Link>
                        : null

                    :
                    null
            }
            {
                row.leadid !== "0" && row.leadid !== null ?
                    row.createdby === userid ? <Link className="btn btn-warning btn-sm m-1" to={`/CreateMeetingSales/${data}`}>Edit</Link>
                        : null

                    :
                    null
            }

            &nbsp; <Link className="btn btn-warning btn-sm" to={`/MeetingDetails/${data}`}>Details</Link>
        </>
    }

    const formatDateTime = (cell, row) => {
        // Format the date and time here, assuming 'time' is a string in ISO 8601 format
        const formattedDate = new Date(cell).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          
        });
        return formattedDate;
    }

    const column = [
        { dataField: 'topic', text: 'Topic', sort: true },
        {
            dataField: 'time',
            text: 'Meeting Date Time',
            sort: true,
            formatter: formatDateTime

        },
        { dataField: 'id', text: 'Actions', formatter: linkformatter }
    ]

    return (
        <div className="container-fluid">
            <div className="card shadow border-success mb-2">
                <div className="card-header rounded py-3">
                    <div className="row">
                        <div className="col-sm-3"><h3><b className='text-dark'>My Meetings</b></h3></div>
                        <div className="col-sm-6"></div>
                        <div className="col-sm-3 d-flex flex-row-reverse">
                            <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                            {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Meeting List</h6>
                    {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
                </div>

                <div className="card-body">
                    <div className="table-responsive">
                        <BootstrapTable keyField="id"
                            data={data}
                            columns={column}
                            // data-sortable="desc"
                            // order = "desc"
                            pagination={paginationFactory()}
                        // filter={filterFactory()}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MeetingListComponent