import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import TaskDetailsComponent from '../../../Components/Main/Task/TaskDetailsComponent';

function TaskDetails(props) {
    
    const params = useParams();
   
    let navigate = useNavigate();
    return (  
        <div> 
            <TaskDetailsComponent {...props} navigate={navigate} id={params.id} name = {params.name}/>
        </div>
    );  
  }
  
export default TaskDetails  