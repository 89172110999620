import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Service from '../../../Services/Admin/Service';
import { formatDate, getLoginUserData } from '../../../Helpers/supportedFunctions/supportedGlobalFunctions';

const DashboardFailedDeadlineComponent = (props) => {

    const [loading, setLoading] = useState(false);
    const [taskType, setTaskType] = useState('');
    const [failedshowtasklist, setfailedshowtasklist] = useState([]);
    const [failedleadtasklist, setfailedleadtasklist] = useState([]);
    const [failedtasklist, setfailedtasklist] = useState([]);

    useEffect(() => {

        GetfailedTasklist("Dashboard/GetAllfailedDeadlineTasklist?userid=" + getLoginUserData().userId);
        GetTaskType("TaskType/GetAll");
    }, [])

    const GetfailedTasklist = (method) => {
        Service.getdata(method).then((res) => {
            // setData(res.data);
            debugger;
            var data = res.data.filter(x => (x.projectId !== 0 || x.projectId !== null) && (x.leadid === 0 || x.leadid === null) && (x.showId === 0 || x.showId === null))
            setfailedtasklist(data);
            console.log(res.data);
            var showdata = res.data.filter(x => (x.showId !== 0 || x.showId !== null) && (x.leadid === 0 || x.leadid === null) && (x.projectId === 0 || x.projectId === null))
            setfailedshowtasklist(showdata);
            var leaddata = res.data.filter(x => (x.leadid !== 0 || x.leadid !== null) && (x.projectId === 0 || x.projectId === null) && (x.showId === 0 || x.showId === null))
            setfailedleadtasklist(leaddata);
            if (res.data) {
                setLoading(true);
            }
        });
    }

    const GetTaskType = (method) => {
        Service.getdata(method).then((res) => {
            setTaskType(res.data);
        });
    }

    const filterTaskType = (evt) => {
        debugger
        var [d] = taskType.filter((e) => e.id === evt);
        console.log(d.taskname)
        return d.taskname;
    }

    const backbutton = () => {
        props.navigate(-1);
    }

    return (
        <div className="container-fluid">

            <div className="card shadowborder-success mb-2">
                <div className="card-header rounded py-3">
                    <div className="row">

                        {loading === true && <div className="col-sm-6">
                            {props.name === "show" && <h3><b className='text-dark'>Show failed Deadline Task</b></h3>}
                            {props.name === "lead" && <h3><b className='text-dark'>Lead Failed Deadline Task</b></h3>}
                            {props.name === "project" && <h3><b className='text-dark'>Project Failed Deadline Task</b></h3>}
                        </div>}{loading === false &&
                            <div class=" text-primary col-sm-4">
                                <strong>Data Loading...</strong>
                                <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                            </div>
                        }
                        <div className="col-sm-4"></div>
                        <div className="col-sm-2 d-flex flex-row-reverse">
                            <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                            {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="card shadow mb-4">
                <div className="card-header py-3 ">
                    <h6 className="font-weight-bold text-success">List</h6>
                    {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
                </div>

                {props.name === "show" && <div className="card-body" style={{ maxHeight: "500px", overflow: "auto", }}>
                    <div class="table-responsive overflow-x-scroll" >
                        <table class="table table-bordered">
                            {/* <caption>List of users</caption> */}
                            <thead className="thead-dark" style={{ position: "sticky", top: "-20px", zIndex: "1" }}>

                                <tr>
                                    {/* <th scope="col">Task Id</th> */}
                                    <th scope="col" className='text-danger'>Task HeadLine</th>
                                    <th scope="col">Assigned by</th>
                                    <th scope="col" className='text-danger'>Employee Name</th>
                                    <th scope="col">Work requirement</th>
                                    <th scope="col">Deadline Date</th>
                                    <th scope="col">Show Name</th>
                                    <th scope="col">Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {failedshowtasklist.map((e) => (<tr>
                                    {/* <th scope="row">{e.id}</th> */}
                                    <td className='text-danger'>{e.headline}</td>
                                    <td>{e.taskassignuserdetails?.slice(0, 1).map((t) => (
                                        <p>{t.assignerName},</p>
                                    ))}</td>
                                    <td className='text-danger'>{e.taskassignuserdetails?.map((t) => (
                                        <p>{t.assigneeName},</p>
                                    ))}</td>
                                    <td>{filterTaskType(e.typeId)}</td>
                                    <td>{formatDate(e.finishdate)}</td>
                                    <td>{e.show}</td>
                                    <td><Link className="btn btn-warning btn-sm" to={`/TaskDetails/${e.id}&${"deadline"}`}>Details</Link></td>
                                </tr>))}


                            </tbody>
                        </table>
                    </div>
                </div>}

                {props.name === "lead" && <div className="card-body" style={{ maxHeight: "500px", overflow: "auto", }}>
                    <div class="table-responsive overflow-x-scroll" >
                        <table class="table table-bordered">
                            {/* <caption>List of users</caption> */}
                            <thead className="thead-dark" style={{ position: "sticky", top: "-20px", zIndex: "1" }}>

                                <tr>
                                    {/* <th scope="col">Task Id</th> */}
                                    <th scope="col" className='text-danger'>Task HeadLine</th>
                                    <th scope="col">Assigned by</th>
                                    <th scope="col" className='text-danger'>Employee Name</th>
                                    <th scope="col">Work Requirement</th>
                                    <th scope="col">Deadline Date</th>
                                    <th scope="col">Lead Name</th>
                                    <th scope="col">Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {failedleadtasklist.map((e) => (<tr>
                                    {/* <th scope="row">{e.id}</th> */}
                                    <td className='text-danger'>{e.headline}</td>
                                    <td>{e.taskassignuserdetails?.slice(0, 1).map((t) => (
                                        <p>{t.assignerName},</p>
                                    ))}</td>
                                    <td className='text-danger'>{e.taskassignuserdetails?.map((t) => (
                                        <p>{t.assigneeName},</p>
                                    ))}</td>
                                    <td>{filterTaskType(e.typeId)}</td>
                                    <td>{formatDate(e.finishdate)}</td>
                                    <td>{e.lead}</td>
                                    <td><Link className="btn btn-warning btn-sm" to={`/TaskDetails/${e.id}&${"deadline"}`}>Details</Link></td>

                                </tr>))}


                            </tbody>
                        </table>
                    </div>
                </div>}

                {props.name === "project" && <div className="card-body" style={{ maxHeight: "500px", overflow: "auto", }}>
                    <div class="table-responsive overflow-x-scroll" >
                        <table class="table table-bordered">
                            {/* <caption>List of users</caption> */}
                            <thead className="thead-dark" style={{ position: "sticky", top: "-20px", zIndex: "1" }}>

                                <tr>
                                    {/* <th scope="col">Task Id</th> */}
                                    <th scope="col" className='text-danger'>Task HeadLine</th>
                                    <th scope="col">Assigned by</th>
                                    <th scope="col" className='text-danger'>Employee Name</th>
                                    <th scope="col">Work Requirement</th>
                                    <th scope="col">Deadline Date</th>
                                    <th scope="col">Project Name</th>
                                    <th scope="col">Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {failedtasklist.map((e) => (<tr>
                                    {/* <th scope="row">{e.id}</th> */}
                                    <td className='text-danger'>{e.headline}</td>
                                    <td>{e.taskassignuserdetails?.slice(0,1).map((t) => (
                                                <p>{t.assignerName},</p>
                                            ))}</td>
                                    <td className='text-danger'>{e.taskassignuserdetails?.map((t) => (
                                        <p>{t.assigneeName},</p>
                                    ))}</td>
                                    <td>{filterTaskType(e.typeId)}</td>
                                    <td>{formatDate(e.finishdate)}</td>
                                    <td>{e.project}</td>
                                    <td><Link className="btn btn-warning btn-sm" to={`/TaskDetails/${e.id}&${"deadline"}`}>Details</Link></td>
                                </tr>))}


                            </tbody>
                        </table>
                    </div>
                </div>}
            </div>

        </div>
    )
}

export default DashboardFailedDeadlineComponent