import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import Service from "../../../Services/Admin/Service";
import ApiMethod from '../../../Helpers/ApiMethod/Method';
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState, convertFromHTML, ContentState, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Select from 'react-select';
import draftToHtml from 'draftjs-to-html';
import { getCurrentDate } from '../../../Helpers/supportedFunctions/supportedGlobalFunctions';
import KanbanViewCss from "./KanbanViewComponent.css";

const CreateSpecificTaskComponent = (props) => {

    var request = props.name;

    const date1 = getCurrentDate();
    const [loading, setLoading] = useState(false);
    const [currentDate, setCurrentDate] = useState('');
    const [status, setStatus] = useState([]);
    const [departent, setDepartment] = useState([]);
    const [apiResponse, setApiResponse] = useState(null);
    const [apiResponseComment, setapiResponseComment] = useState(null)
    const [selectedAssignees, setSelectedAssignees] = useState([]);
    const [dropdownTaskType, setdropdownTaskType] = useState([]);
    const [taskPriority, setTaskPriority] = useState([]);
    const [dropdownUser, setdropdownUser] = useState([]);
    const [taskTypeId, setTaskTypeId] = useState([]);
    const [loginUser, setLoginUser] = useState([]);
    const [showdata, setShowdata] = useState([]);
    const [projectError, setProjectError] = useState(false);
    const [statusError, setStatusError] = useState(false);
    const [assigneeError, setAssigneeError] = useState(false);
    const [priorityError, setPriorityError] = useState(false);
    const [requestTask, setRequestTask] = useState(false);
    const [discussionData, setDiscussionData] = useState([]);
    const [apiError, setApiError] = useState(null);
    const [inputCalendar, setInputCalendar] = useState(
        {
            summary: "",
            description: "",
            startTime: "",
            endTime: "",
            calendarId: "sop@xsproductions.com",
            refreshToken: "1//0gkWVmDAlyPkGCgYIARAAGBASNwF-L9Irz9KGiZeCkJMbqqXSTdlCky2cDgLR866ieBZs7Uwie0VBZZXQekor3AGqaieyoOCCvbM",
            users: [
                ""
            ]
        }
    );
    const [taskTypeError, setTaskTypeError] = useState(false);
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const [editorCommentState, setEditorCommentState] = useState(() =>
        EditorState.createEmpty()
    );
    const [selectedstatus, setselectedstatus] = useState([]);
    const [selectedpriority, setselectedpriority] = useState([]);
    let id = sessionStorage.getItem('id');
    let DeptId = sessionStorage.getItem('DeptId');
    const [inputField, setInputField] = useState({
        headline: '',
        shortDescription: '',
        projectId: 0,
        showId: '',
        leadid: 0,
        isRequested: false,
        parentId: null,
        typeId: 0,
        statusId: 0,
        priorityId: 0,
        planhour: 0,
        finishdate: '',
        createdby: id,
        updatedby: id,
        assignerid: id,
        isDeleted: false,
        isActive: true,
        contentLink: null,
        assigneeid: [0],
        removeAssigneeid: []
    })

    const [inputDiscussion, setInputDiscussion] = useState({
        "discussionid": 0,
        "taskid": 0,
        "userid": sessionStorage.getItem("id"),
    })

    const apis = ApiMethod.find(o => o.name === 'Category');


    useEffect(() => {
        getUserInfo("Users/GetById?id=" + id)
        getStatusData("Status/GetAll");
        getTaskTypeData("TaskType/GetAll");
        getTaskPriority("Priority/GetAll")
        getUserData("Users/GetAll");
        getShowData("Shows/GetAllShows");
        getDiscussionData("Task/GetALLTaskDiscussion?taskid=" + id);
        getDepartmentData("Department/GetAll");
        const today = new Date();

        const day = today.getDate();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours to 12-hour clock format
        const formattedHours = hours % 12 || 12;

        const formattedDate = `${day}/${month}/${year}`;
        const formattedTime = `${formattedHours}:${minutes}${ampm}`;

        const dateTimeString = `${formattedDate} ${formattedTime}`;
        setCurrentDate(dateTimeString);
        if (request) {
            setRequestTask(true);
        }
    }, [])

    useEffect(() => {
        if (apiResponse || apiError) {
            const timeoutId = setTimeout(removeSuccessMessage, 3000);


            return () => clearTimeout(timeoutId);


        }
    }, [apiResponse, apiError]);

    useEffect(() => {
        if (apiResponseComment) {
            const timeoutId = setTimeout(removeSuccessMessage, 3000);

            return () => clearTimeout(timeoutId);
        }
    }, [apiResponseComment]);

    const getUserInfo = (method) => {
        Service.getdata(method).then((res) => {
            setLoginUser(res.data);
        });
    }
    // const getCurrentDate = () => {
    //     const currentDate = new Date();
    //     const year = currentDate.getFullYear();
    //     const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    //     const day = currentDate.getDate().toString().padStart(2, '0');
    //     return `${year}-${month}-${day}`;
    // }

    const getDiscussionData = (method) => {
        Service.getdata(method).then((res) => {
            setDiscussionData(res.data);
        });
    }

    const getStatusData = (method) => {
        Service.getdata(method).then((res) => {
            setStatus(res.data);
        });
    }



    const getTaskTypeData = (method) => {
        Service.getdata(method).then((res) => {
            debugger
            if (userId === "1" || userId === "47" || userId === "56") {
                var data = res.data

                var tasktype = []
                tasktype = data.filter(x => x.deptId === 1)
                setdropdownTaskType(tasktype);
            }
            else {
                var intdeptId = parseInt(DeptId)
                var data = res.data
                if (intdeptId === null) {
                    setdropdownTaskType(tasktype);
                } else {
                    var tasktype = []
                    tasktype = data.filter(x => x.deptId === intdeptId)
                    setdropdownTaskType(tasktype);
                }
            }
        });
    }

    const getTaskPriority = (method) => {
        Service.getdata(method).then((res) => {
            setTaskPriority(res.data);
        });
    }

    const getShowData = (method) => {
        Service.getdata(method).then((res) => {
            setShowdata(res.data);
        });
    }

    const getDepartmentData = (method) => {
        Service.getdata(method).then((res) => {
            setDepartment(res.data);
        });
    }

    const userId = sessionStorage.getItem("id")
    const getUserData = (method) => {
        Service.getdata(method).then((res) => {
            var data = res.data;
            var intdeptId = parseInt(DeptId)
            var list = [];
            if (userId === "1" || userId === "2" || userId === "56" || userId === "47") {
                data.forEach((d, index) => {
                    var role = d.lstRole;

                    var checkdepartment = role.find(x => x.depId === 1)
                    if (checkdepartment !== undefined) {
                        d.rolename = d.lstRole[0].name;
                        list.push(d);
                    }
                    setdropdownUser(list);
                });
            }
            else {

                data.forEach((d, index) => {
                    var role = d.lstRole;
                    var checkdepartment = role.find(x => x.depId === intdeptId)
                    if (checkdepartment !== undefined) {
                        d.rolename = d.lstRole[0].name;
                        list.push(d);
                    }
                });
                setdropdownUser(list);
            }
        });
    }

    const handleEditorChange = (state) => {
        setEditorState(state);
    }

    const handleEditorComment = (state) => {
        setEditorCommentState(state);
    }

    const handleSelectChange = (selectedOptions) => {
        setSelectedAssignees(selectedOptions);
    };



    const backbutton = () => {
        props.navigate(-1);
    }

    const removeSuccessMessage = () => {
        setApiResponse(null);
        setapiResponseComment(null);
        setApiError(null);
    };

    // const handleChapterChange = (event) => {
    //     const selectedId = event.target.value;
    //     setSelectedChapterId(selectedId);
    //     debugger
    // };

    const submitButton = (evt) => {
        debugger
        if (evt && evt.preventDefault) {
            evt.preventDefault();
        }
        setLoading(true);
        const formData = new FormData(evt.target);

        const data = {}
        for (const [key, value] of formData) {
            data[key] = value;
        }

        function validateData(data) {
            const { headline, statusId, showId, typeId, priorityId, assigneeid, finishdate } = data;
            //  debugger

            if (!headline || assigneeid === "[NONE]" || !finishdate) {
                alert("Please fill in all required fields.");
                return false;
            }

            if (!statusId || statusId === "[NONE]") {
                setStatusError(true);
                return false;
            } else {
                setStatusError(false);
            }

            if (!showId || showId === "[NONE]") {
                setProjectError(true);
                return false;
            } else {
                setProjectError(false);
            }


            if (!priorityId || priorityId === "[NONE]") {
                setPriorityError(true);
                return false;
            } else {
                setPriorityError(false);
            }

            if (!typeId || typeId === "[NONE]") {
                setTaskTypeError(true);
                return false;
            } else {
                setTaskTypeError(false);
            }

            if (!assigneeid || assigneeid === "[NONE]") {
                setAssigneeError(true);
                return false;
            } else {
                setAssigneeError(false);
            }

            // All conditions are met, so return true to proceed forward.
            return true;
        }

        if (!validateData(data)) {
            // The validation returned false, so stop execution here.
            // You can add any specific handling for invalid data here, if needed.
            return;
        }


        const currentContent = editorState.getCurrentContent();
        const messageBody = convertToRaw(currentContent);
        var jsonData = JSON.stringify(messageBody);
        // const loadData = JSON.parse(jsonData);
        // var content=loadData.blocks[0].text;
        let currentContentAsHTML = draftToHtml(messageBody);
        //  inputField.ContentJosn = jsonData;

        inputField.shortDescription = jsonData;

        const extractedText = messageBody.blocks.length > 0 ? messageBody.blocks[0].text : '';
        console.log(extractedText)

        if (requestTask === true) {
            var request = true;
        }
        const task = {
            ...inputField,
            ...data,
            IsRequested: request,
            assigneeid: selectedAssignees.map((assignee) => assignee.value),
        };
        console.log(task)
        debugger
        var usersEmail = selectedAssignees.map((assignee) => assignee.email)

        var date1 = new Date(task.finishdate);
        date1.setHours(18, 0, 0, 0);

        const calendarTask = {
            ...inputCalendar,
            description: extractedText,
            summary: data.headline,
            startTime: new Date().toISOString(),
            endTime: date1.toISOString(),
            users:
                usersEmail,

        }
        console.log(calendarTask);
        debugger
        if (task) {
            Service.createorupdate("Task/CreateOrUpdate", task)
                .then(res => {
                    if (calendarTask) {
                        Service.createorupdate("user/calendarevent", calendarTask)
                            .then(res => {
                                if (res.data) {
                                    setLoading(false);
                                }
                                setApiResponse(res.data);//  Set the API response in state
                                //  window.location.href = "/KanbanAssignedTask";

                            })
                            .catch(error => {
                                //  Handle error if API call fails
                                console.error("API Error:", error);
                                setApiError("Error occurred while saving the task. Please try again later.");

                            });

                        window.location.href = "/KanbanAssignedTask";

                    }

                })
                .catch(error => {
                    // Handle error if API call fails
                    console.error("API Error:", error);
                    setApiError("Error occurred while saving the meeting. Please try again later.");
                });
        }

    }

    return (
        <div>
            <div className="container-fluid">

                <br />
                <div className="card shadow border border-success mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col-sm-3">{requestTask === true ? <h3>Request Task</h3> : <h3>Assign Task</h3>}</div>
                            <div className="col-sm-6"></div>
                            <div className="col-sm-3 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link>
                            </div>

                        </div>
                    </div>
                </div>
                <form onSubmit={submitButton}>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <div className="col-sm-12 d-flex">

                                {requestTask === true ? <>
                                    <h6 className="m-0 mt-1 font-weight-bold text-primary required-label">Note :-</h6>
                                    <div className="m-0 ml-2 ">Requested task is only given to Seniors or Head</div>
                                </> :
                                    <h6 className="m-0 mt-1 font-weight-bold text-primary ">TaskInfo :-</h6>}
                            </div>
                            {apiResponse && (
                                <div className="alert alert-success mt-2" role="alert">
                                    Task has been created successfully!
                                </div>
                            )}
                            {apiError && (
                                <div className="alert alert-danger" role="alert">
                                    {apiError}
                                </div>
                            )}
                        </div>

                        <div className="card-body" style={{ height: "auto" }}>
                            <div className='row'>
                                <div className='col-md-7'>
                                    <div className="form-group row">
                                        <div className='col-sm-12 required-label'>Task Headline</div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline"
                                            name="headline" placeholder="Enter Task headline" required /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 required-label'>Description</div>
                                        <Editor
                                            editorState={editorState}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            onEditorStateChange={handleEditorChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className='col-md-1'></div>
                                <div className='col-md-4'>
                                    <div className="form-group form-group-h row">
                                        <div className='col-sm-12 required-label'>Status</div>
                                        <div className='col-sm-12'>
                                            {statusError && <div className="alert alert-danger mt-3" role="alert">Please select a status.</div>}
                                            <select className="form-control form-control-fs form-control-user" readOnly value={1} name="statusId" required>
                                                <option>[NONE]</option>
                                                {
                                                    status.map((e, key) => {
                                                        //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;
                                                        return <option key={e.statusId} value={e.statusId}>{e.name}</option>;
                                                    })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group form-group-h row">
                                        <div className='col-sm-12 required-label'>Work Requirement</div>
                                        <div className='col-sm-12'>
                                            {taskTypeError && <div className="alert alert-danger mt-3" role="alert">Please select a task type.</div>}


                                            <select className="form-control form-control-fs form-control-user" name="typeId" required>
                                                <option>[NONE]</option>
                                                {
                                                    dropdownTaskType.map((e, key) => {
                                                        return <option key={e.id} value={e.id}>{e.taskname}</option>;
                                                    })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group form-group-h row">
                                        <div className='col-sm-12 required-label'>Priority</div>
                                        <div className='col-sm-12'>
                                            {priorityError && <div className="alert alert-danger mt-3" role="alert">Please select a priority.</div>}
                                            <select className="form-control form-control-fs  form-control-user" name="priorityId" required>
                                                <option>[NONE]</option>
                                                {
                                                    taskPriority.map((e, key) => {
                                                        return <option key={e.priorityId} value={e.priorityId}>{e.name}</option>;
                                                    })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group form-group-h row">
                                        <div className='col-sm-12 required-label'>Show Name</div>
                                        <div className='col-sm-12'>
                                            {projectError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a Show.</div>}
                                            <select className="form-control form-control-fs  form-control-user" name="showId" required>
                                                <option>[NONE]</option>
                                                {
                                                    showdata.map((e, key) => {
                                                        return <option key={e.showChapterId} value={e.showChapterId}>{e.chapterName}</option>;
                                                    })}
                                            </select>
                                        </div>
                                    </div>
                                    <h5>People</h5>
                                    <div className="form-group form-group-h row">
                                        {requestTask === true ? <div className='col-sm-5'>Requested From - </div> : <div className='col-sm-5'>Author - </div>}
                                        <div className='col-sm-7'>
                                            <b>{loginUser.firstname} {loginUser.lastname} </b>
                                        </div>
                                    </div>
                                    <div className="form-group form-group-h row">
                                        {requestTask === true ? <div className="col-sm-12 required-label">Requested To</div> : <div className="col-sm-12 required-label">Assigned To</div>}
                                        <div className="col-sm-12">
                                            {assigneeError && <div className="alert alert-danger  mt-3" role="alert">Please select a assignee</div>}
                                            <Select
                                                name="assigneeid"
                                                isMulti={true}
                                                placeholder="Enter meeting members"
                                                options={dropdownUser.map((user) => ({
                                                    value: user.id,
                                                    label: `${user.firstname} ${user.lastname} - ${user.rolename}`,
                                                    email: user.email,
                                                }))}
                                                value={selectedAssignees}
                                                onChange={handleSelectChange}
                                            />
                                        </div>
                                    </div>
                                    <h5>Dates</h5>

                                    <div className="form-group form-group-h row">
                                        <div className='col-sm-5'>Last Update - </div>
                                        <div className='col-sm-7'>
                                            <b>{currentDate}</b>
                                        </div>
                                    </div>
                                    <div className="form-group form-group-h row">
                                        <div className='col-sm-12 required-label'>Due Date / Finish date</div>
                                        <div className='col-sm-12'> <input type="date" className="form-control form-control-fs form-control-user" id="finishdate"
                                            name="finishdate" placeholder="Enter Task Topic" min={getCurrentDate()} required />
                                        </div>
                                    </div>
                                    {apiResponse && (
                                        <div className="alert alert-success  mt-2" role="alert">
                                            Task has been created successfully!
                                        </div>
                                    )}
                                    {apiError && (
                                        <div className="alert alert-danger" role="alert">
                                            {apiError}
                                        </div>
                                    )}
                                    {loading === false && <h6 className="font-weight-bold text-primary">{ }</h6>}{loading === true &&
                                        <div class=" text-primary">
                                            <strong>Saving...</strong>
                                            <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                                        </div>
                                    }
                                    <button className="btn btn-primary btn-block" type='submit'>Save</button>
                                </div>
                            </div>
                        </div>
                        <div className="form-group form-group-h row">
                            <div className='col-sm-4'>
                            </div>
                            <div className='col-sm-4'></div>
                            <div className='col-sm-4'></div>
                            <br></br>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CreateSpecificTaskComponent