import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Service from '../../../Services/Admin/Service';

const CreateProjectFeedbackComponent = (props) => {

  let id = sessionStorage.getItem('id');
  const [apiResponse, setApiResponse] = useState(null);
  const [projectData, setProjectData] = useState([]);
  const [fromAddress, setFromAddress] = useState('');
  const [detailFlag, setDetailFlag] = useState(false);
  const [size, setSize] = useState('');
  const [date, setDate] = useState('');
  const [standNo, setStandNo] = useState('');
  const [feedback, setFeedback] = useState('');
  const [confirmRecieptName, setConfirmRecieptName] = useState('');
  const [confirmRelayName, setConfirmRelayName] = useState('');
  const [projectid, setprojectid] = useState('');
  const [projectFeedbackData, setprojectFeedbackData] = useState([]);
  const [projectError, setProjectError] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [inputField, setInputFields] = useState({
    projectid: '',
    userid: id,
    fromAddress: "",
    stand: "",
    size: "",
    standNo: "",
    feedback: "",
    confirmRecieptName: "",
    confirmRelayName: "",
    createdBy: id,
    upatedBy: id,
  })

  useEffect(() => {
    getProjectData("Project/GetAll");
    var d = props.name;
    if (d === "details") {
      setDetailFlag(true);
    }
    if (props.name !== 0 && props.name !== undefined) {

      getProjectFeedbackbyId("Feedback/GetByIDProjectFeedback?id=" + props.id, '');

    }
    const currentDateObj = new Date();
    const day = String(currentDateObj.getDate()).padStart(2, '0');
    const month = String(currentDateObj.getMonth() + 1).padStart(2, '0');
    const year = currentDateObj.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;

    // Update the state variable with the formatted date
    setDate(formattedDate);
  }, [props.name])

  const getProjectFeedbackbyId = (method) => {
    Service.getdata(method).then((res) => {
      setprojectFeedbackData(res.data);
      console.log(res.data)
      debugger
      setFromAddress(res.data.fromAddress)
      setSize(res.data.size)
      setStandNo(res.data.standNo)
      setFeedback(res.data.feedback)
      setConfirmRecieptName(res.data.confirmRecieptName)
      setConfirmRelayName(res.data.confirmRelayName)
      setprojectid(res.data.projectid)
    });
  }

  const getProjectData = (method) => {
    Service.getdata(method).then((res) => {
      setProjectData(res.data);
    })
  }
  useEffect(() => {
    if (apiResponse || apiError) {
      const timeoutId = setTimeout(removeSuccessMessage, 3000);

      return () => clearTimeout(timeoutId);

    }
  }, [apiResponse, apiError]);

  const removeSuccessMessage = () => {
    setApiResponse(null);
    setApiError(null);
  };


  const handleFromAddressChange = (evt) => {
    setFromAddress(evt.target.value);
  }



  const handleSizeChange = (evt) => {
    setSize(evt.target.value);
  }



  const handleStandNoChange = (evt) => {
    setStandNo(evt.target.value);
  }


  const handleFeedbackChange = (evt) => {
    setFeedback(evt.target.value);
  }


  const handleConfirmRecieptNameChange = (evt) => {
    setConfirmRecieptName(evt.target.value);
  }


  const handleConfirmRelayNameChange = (evt) => {
    setConfirmRelayName(evt.target.value);
  }
  const handleProjectidChange = (evt) => {
    setprojectid(evt.target.value);
  }



  const save = async (evt) => {

    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }
    const formData = new FormData(evt.target);

    const data = {}
    for (const [key, value] of formData) {
      data[key] = value;
    }

    function validateData(data) {

      const { projectid, fromAddress } = data;

      
      if (!fromAddress || !standNo || !confirmRecieptName) {
        alert("Please fill in all required fields.");
        return false;
    }

      if (!projectid || projectid === "[NONE]") {
        setProjectError(true);
        return false;
      } else {
        setProjectError(false);
      }

      return true;
    }

    if (!validateData(data)) {
      return;
    }

    const task = {
      ...inputField,
      ...data,
    };
    console.log(task);
    debugger

    if (task) {
      Service.createorupdate("Feedback/CreateOrUpdateProjectFeedback", task)
        .then(res => {
          setApiResponse(res.data); // Set the API response in state
          window.location.href = "/ProjectFeedbackList";
        })
        .catch(error => {
          // Handle error if API call fails
          console.error("API Error:", error);
          setApiError("Error occurred while saving the feedback. Please try again later.");
        });
    }

  }

  const backbutton = () => {
    props.navigate(-1);
  }


  return (
    <div>
      <div className="container-fluid">

        <br />
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <div className="row">
              <div className="col-sm-4"><h3>Create Project Feedback</h3></div>
              <div className="col-sm-5"></div>
              <div className="col-sm-3 d-flex flex-row-reverse">
                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link>
              </div>

            </div>
          </div>
        </div>
        <form onSubmit={save}>
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Project Feedback</h6>
              {apiResponse && (
                <div className="alert alert-success mt-2" role="alert">
                  Feedback has been submitted successfully!
                </div>
              )}
              {apiError && (
                <div className="alert alert-danger" role="alert">
                  {apiError}
                </div>
              )}
            </div>

            <div className="card-body" style={{ height: "auto" }}>
              <div className='row'>
                <div className='col-md-5 ml-5'>
                  <div className="form-group row">
                    <div className='col-sm-12 required-label'><b>Ref. /XSPL/TBM/PAS 2023</b> </div>

                    {/* <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline"
                      name="contactName" placeholder="enter contact name" /></div> */}
                  </div>
                  <div className="form-group row">
                    <div className='col-sm-12 required-label'>From</div>
                    <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline" value={fromAddress} onChange={handleFromAddressChange}
                      name="fromAddress" placeholder="enter From address" required/></div>
                  </div>
                  <div className="form-group row">
                    <div className='col-sm-12 required-label'>Project</div>
                    <div className='col-sm-12'>
                      {projectError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a Project.</div>}
                      <select className="form-control  form-control-user" name="projectid" value={projectid} onChange={handleProjectidChange} >
                        <option>[NONE]</option>
                        {
                          projectData.map((e, key) => {
                            //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;
                            return <option key={e.id} value={e.id}>{e.projectname}</option>;
                          })}
                      </select>
                    </div>
                  </div>
                  <h5>Stand Info</h5>

                  <div className="form-group row">
                    <div className='col-sm-12 required-label'>Stand No.</div>
                    <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline" value={standNo} onChange={handleStandNoChange}
                      name="standNo" placeholder="enter stand" required/></div>
                  </div>
                  <h6>Note : I hereby confirm that our booth at the above mentioned show has been completed and handed over to us by complete satisfaction.</h6>
                  <div className="form-group row">
                    <div className='col-sm-12'>Feedback:</div>
                    <div className='col-sm-12'><textarea
                      rows={3} // You can adjust the number of visible rows
                      cols={100}
                      name='feedback'
                      onChange={handleFeedbackChange}
                      value={feedback}
                      placeholder="enter feedback" // You can adjust the number of visible columns
                      class="form-control"
                      autofocus
                    /></div>
                  </div>

                  <h5>I Confirm Receipt</h5>
                  <div className="form-group row">
                    <div className='col-sm-12 required-label'>Name</div>
                    <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline" value={confirmRecieptName} onChange={handleConfirmRecieptNameChange}
                      name="confirmRecieptName" placeholder="enter name" required/></div>
                  </div>
                  <div className="form-group row">
                    <div className='col-sm-12 required-label'>Signature</div>
                    <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline" value={confirmRecieptName} onChange={handleConfirmRecieptNameChange}
                      name="signature" placeholder="enter authority name/signature" required/></div>
                  </div>
                </div>
                <div className='col-md-1'></div>
                <div className='col-md-5'>


                  <div className="form-group row mt-5">
                    <div className='col-sm-12  '>To</div>
                    <div className='col-sm-12'><b>XS Productions India Pvt. Ltd.
                      A-84, Sector-83, Phase-II Noida-201305
                      Uttar Pradesh</b>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className='col-sm-12 '>Date</div>
                    <div className='col-sm-12'> <b>{date}</b> </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-12 required-label'>Stand Size</div>
                    <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline" value={size} onChange={handleSizeChange}
                      name="size" placeholder="enter stand size" required/></div>
                  </div>

                  <h5>I Confirm Relay</h5>
                  <div className="form-group row">
                    <div className='col-sm-12 required-label'>Name</div>
                    <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline" value={confirmRelayName} onChange={handleConfirmRelayNameChange}
                      name="confirmRelayName" placeholder="enter name" required/></div>
                  </div>
                  <div className="form-group row">
                    <div className='col-sm-12 required-label'>Signature</div>
                    <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline" value={confirmRelayName} onChange={handleConfirmRelayNameChange}
                      name="signature" placeholder="enter authority name/signature" required/></div>
                  </div>
                </div>
              </div>
              {!detailFlag && <button className="btn btn-primary ml-5" type='submit'>Save</button>}

            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CreateProjectFeedbackComponent