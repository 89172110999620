import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import VendorDetailsComponent from '../../../Components/Main/VendorComponent/VendorDetailsComponent';


function VendorDetails(props) {
    let navigate = useNavigate();
    const params = useParams();
    return (  
        <div> 
            <VendorDetailsComponent {...props} navigate={navigate} id={params.id} />
        </div>
    );  
  }
export default VendorDetails