import React, { useEffect, useState } from 'react'
import Service from '../../../Services/Admin/Service';
import { Link } from 'react-router-dom';

const UnloadingMaterialChecklistComponent = (props) => {


    const [project, setProject] = useState([]);
    const [projectError, setProjectError] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);
    const [apiError, setApiError] = useState(null);
  
    useEffect(() => {
      getProjectData("Project/GetAll");
    }, [])
  
    const getProjectData = (method) => {
      Service.getdata(method).then((res) => {
        setProject(res.data);
      });
    }
  
    useEffect(() => {
      if (apiResponse || apiError) {
        const timeoutId = setTimeout(removeSuccessMessage, 3000);
        return () => clearTimeout(timeoutId);
      }
    }, [apiResponse, apiError]);
  
    const removeSuccessMessage = () => {
      setApiResponse(null);
      setApiError(null);
    };
  
  
  
    const backbutton = () => {
      props.navigate(-1);
    }
  
    const save = async (evt) => {
      if (evt && evt.preventDefault) {
        evt.preventDefault();
      }
  
      const formData = new FormData(evt.target);
      const data = {}
      for (const [key, value] of formData) {
        data[key] = value;
      }
  
    }

  return (
    <div>
    <div className="container-fluid">
      <div className='row mb-4'>
      <div className="col-sm-6"></div>
              <div className="col-sm-2 d-flex flex-row-reverse">
                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link>
              </div>
      </div>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <div className="row">
            <div class="d-flex flex-start align-items-center">
              <img class="shadow-1-strong mb-2 me-32"
                src="https://w7.pngwing.com/pngs/163/964/png-transparent-contract-computer-icons-rental-agreement-others-miscellaneous-angle-text.png"
                alt="avatar" width="50"
                height="40" />
            </div>
            <div className="col-sm-3"><h2>Unloading Material List</h2></div>
            <div className="col-sm-6"></div>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid">
      <div className="card cardUser shadow mb-4" >
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Material List</h6>
          {apiResponse && (
            <div className="alert alert-success" role="alert">
              Agreement has been created successfully!
            </div>
          )}
          {apiError && (
            <div className="alert alert-danger" role="alert">
              {apiError}
            </div>
          )}
        </div>

        <div class="container">
          <form onSubmit={save} class="form-horizontal mt-2">

          <div className="form-group ">
              <div className='col-sm-9 required-label'>Project</div>
              <div className='col-sm-9'>
                {projectError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a project.</div>}
                <select className="form-control  form-control-user" name="projectid" required>
                  <option>[NONE]</option>
                  {
                    project.map((e, key) => {
                      return <option key={e.id} value={e.id}>{e.projectname}</option>;
                    })}
                </select>
              </div>
            </div>

            <div class="form-group">
              <label for="details" class="col-sm-3 required-label  control-label">Material Name</label>
              <div class="col-sm-9">
                <input type="text" id="materialName" name='materialName' placeholder="Material Name" required class="form-control" autofocus />
              </div>
            </div>

            <div class="form-group">
              <label for="details" class="col-sm-3 required-label  control-label">Details</label>
              <div class="col-sm-9">
              <input type="text" id="details" name='details' placeholder="Details" required class="form-control" autofocus />
              </div>
            </div>
            <button type="submit" class="btn btn-primary ml-3 mb-3">Save</button>
          </form>
        </div>
      </div>
    </div>
  </div>
  )
}

export default UnloadingMaterialChecklistComponent