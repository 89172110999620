import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState, convertFromHTML, ContentState, convertFromRaw } from "draft-js";
import Service from '../../../Services/Admin/Service';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { Link } from 'react-router-dom';

const CreateClientQuotationComponent = (props) => {

    let id = sessionStorage.getItem('id');
    const [project, setProject] = useState([]);
    const [percent, setPercent] = useState("00");
    const [detailFlag, setDetailFlag] = useState(false);
    const [finalCost, setFinalCost] = useState('00');
    const [editorContentValid, setEditorContentValid] = useState(true);
    const [projectError, setProjectError] = useState(false);
    const [typeError, setTypeError] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);
    const [boqDetails, setBoqDetails] = useState('');
    const [date, setDate] = useState('');
    const [Projectid, setProjectid] = useState('');
    const [typeid, settypeid] = useState('');

    const [clienttotalcost, setclienttotalcost] = useState('00');
    const [platformingSubTotal, setPlatformingSubTotal] = useState('00');
    const [wallingSubTotal, setwallingSubTotal] = useState('00');
    const [audioSubTotal, setaudioSubTotal] = useState('00');
    const [hospitalitySubTotal, sethospitalitySubTotal] = useState('00');
    const [lightingSubTotal, setlightingSubTotal] = useState('00');
    const [brandingSubTotal, setbrandingSubTotal] = useState('00');
    const [SuspensionSubTotal, setSuspensionSubTotal] = useState('00');
    const [MezzanineSubTotal, setMezzanineSubTotal] = useState('00');
    const [OtherSubTotal, setOtherSubTotal] = useState('00');

    const [vendor, setvendor] = useState([]);
    const [vendorId, setvendorId] = useState([]);
    const [vendorError, setvendorError] = useState(false);

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const [apiError, setApiError] = useState(null);
    const [inputField, setInputField] = useState(
        {
            id: props.id,
            projectid: 0,
            vendorid: 0,
            vendorClient: "Client",
            showdate: '',
            createdby: id,
            updatedBy: id,
        }
    )


    const timer = setTimeout(() => {
        SubTotal();
        handlePercentcalculation();
    }, 1000);
    const SubTotal = () => {
        const platformingvalue = parseFloat(platformingSubTotal);
        const wallingvalue = parseFloat(wallingSubTotal);
        const audiovalue = parseFloat(audioSubTotal);
        const hospitalityvalue = parseFloat(hospitalitySubTotal);
        const lightingvalue = parseFloat(lightingSubTotal);
        const brandingvalue = parseFloat(brandingSubTotal);
        const Suspensionvalue = parseFloat(SuspensionSubTotal);
        const Mezzaninevalue = parseFloat(MezzanineSubTotal);
        const Othervalue = parseFloat(OtherSubTotal);

        var total = platformingvalue + wallingvalue + audiovalue + hospitalityvalue + lightingvalue + brandingvalue + Suspensionvalue + Mezzaninevalue + Othervalue;
        setclienttotalcost(total);
    }


    const handlePercent = (evt) => {
        setPercent(evt.target.value);
        debugger
    }

    const handlePercentcalculation = () => {
        var taxAmount = 0;
        taxAmount = (percent / 100) * clienttotalcost + clienttotalcost;
        setFinalCost(taxAmount);
    }


    const CalculationofData = (currentlist, type) => {
        var total1 = 0
        currentlist.forEach((lst, index) => {


            if (lst.total !== '') {
                const current = parseFloat(lst.total);
                total1 = parseFloat(total1 + current);
                var total = total1 + "";
                if (type === "Platforming") {
                    setPlatformingSubTotal(total);
                } else if (type === "Walling") {
                    setwallingSubTotal(total);
                }
                else if (type === "Audio") {
                    setaudioSubTotal(total);
                }
                else if (type === "Hospitality") {
                    sethospitalitySubTotal(total);
                }
                else if (type === "Lighting") {
                    setlightingSubTotal(total);
                }
                else if (type === "Branding") {
                    setbrandingSubTotal(total);
                }
                else if (type === "Suspension") {
                    setSuspensionSubTotal(total);
                }
                else if (type === "Mezzanine") {
                    setMezzanineSubTotal(total);
                }
                else if (type === "Other") {
                    setOtherSubTotal(total);
                }

            }



            // platformingSubTotal += lst.total;            
        })

        
        if (currentlist.length === 0) {
            var total = 0;
            if (type === "Platforming") {
                setPlatformingSubTotal(total);
            } else if (type === "Walling") {
                setwallingSubTotal(total);
            }
            else if (type === "Audio") {
                setaudioSubTotal(total);
            }
            else if (type === "Hospitality") {
                sethospitalitySubTotal(total);
            }
            else if (type === "Lighting") {
                setlightingSubTotal(total);
            }
            else if (type === "Branding") {
                setbrandingSubTotal(total);
            }
            else if (type === "Suspension") {
                setSuspensionSubTotal(total);
            }
            else if (type === "Mezzanine") {
                setMezzanineSubTotal(total);
            }
            else if (type === "Other") {
                setOtherSubTotal(total);
            }

        }
    }

    const [inputplatforming, setplatforming] = useState([{
        platforming: '',
        qty: '',
        unit: '',
        rate: '',
        total: ''
    }]);

    const addplatforming = () => {
        setplatforming([...inputplatforming, {
            platforming: '',
            qty: '',
            unit: '',
            rate: '',
            total: ''
        }])
    }

    const removeInputFields = (index, evnt) => {
        const rows = [...inputplatforming];
        rows.splice(index, 1);
        setplatforming(rows);
        CalculationofData(rows, "Platforming");
    }

    const handleChangeplatforming = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputplatforming];
        list[index][name] = value;
        setplatforming(list);

        CalculationofData(list, "Platforming");
    }

    const [inputwalling, setwalling] = useState([{
        walling: '',
        qty: '',
        unit: '',
        rate: '',
        total: ''
    }]);

    const addwalling = () => {
        setwalling([...inputwalling, {
            walling: '',
            qty: '',
            unit: '',
            rate: '',
            total: ''
        }])
    }



    const removewalling = (index) => {
        debugger;
        const rows = [...inputwalling];
        rows.splice(index, 1);
        setwalling(rows);
        CalculationofData(rows, "Walling");
    }

    const handleChangewalling = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputwalling];
        list[index][name] = value;
        setwalling(list);
        CalculationofData(list, "Walling");

    }

    const [inputaudiovisuals, setaudiovisuals] = useState([{
        audiovisuals: '',
        qty: '',
        unit: '',
        rate: '',
        total: ''
    }]);

    const addaudiovisuals = () => {
        setaudiovisuals([...inputaudiovisuals, {
            audiovisuals: '',
            qty: '',
            unit: '',
            rate: '',
            total: ''
        }])
    }

    const removeaudiovisuals = (index) => {
        const rows = [...inputaudiovisuals];
        rows.splice(index, 1);
        setaudiovisuals(rows);
        CalculationofData(rows, "Audio");
    }

    const handleChangeaudiovisuals = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputaudiovisuals];
        list[index][name] = value;
        setaudiovisuals(list);
        CalculationofData(list, "Audio");

    }

    const [inputhospitality, sethospitality] = useState([{
        hospitality: '',
        qty: '',
        unit: '',
        rate: '',
        total: ''
    }]);

    const addhospitality = () => {
        sethospitality([...inputhospitality, {
            hospitality: '',
            qty: '',
            unit: '',
            rate: '',
            total: ''
        }])
    }

    const removehospitality = (index) => {
        const rows = [...inputhospitality];
        rows.splice(index, 1);
        sethospitality(rows);
        CalculationofData(rows, "Hospitality");
    }

    const handleChangehospitality = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputhospitality];
        list[index][name] = value;
        sethospitality(list);

        CalculationofData(list, "Hospitality");
    }
    const [inputlighting, setlighting] = useState([{
        lighting: '',
        qty: '',
        unit: '',
        rate: '',
        total: ''
    }]);

    const addlighting = () => {
        setlighting([...inputlighting, {
            lighting: '',
            qty: '',
            unit: '',
            rate: '',
            total: ''
        }])
    }

    const removelighting = (index) => {
        const rows = [...inputlighting];
        rows.splice(index, 1);
        setlighting(rows);
        CalculationofData(rows, "Lighting");
    }

    const handleChangelighting = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputlighting];
        list[index][name] = value;
        setlighting(list);

        CalculationofData(list, "Lighting");
    }

    const [inputbranding, setbranding] = useState([{
        branding: '',
        qty: '',
        unit: '',
        rate: '',
        total: ''
    }]);

    const addbranding = () => {
        setbranding([...inputbranding, {
            branding: '',
            qty: '',
            unit: '',
            rate: '',
            total: ''
        }])
    }
    const removebranding = (index) => {
        const rows = [...inputbranding];
        rows.splice(index, 1);
        setbranding(rows);
        CalculationofData(rows, "Branding");
    }
    const handleChangebranding = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputbranding];
        list[index][name] = value;
        setbranding(list);

        CalculationofData(list, "Branding");
    }

    const [inputsuspension, setsuspension] = useState([{
        suspension: '',
        qty: '',
        unit: '',
        rate: '',
        total: ''
    }]);

    const addsuspension = () => {
        setsuspension([...inputsuspension, {
            suspension: '',
            qty: '',
            unit: '',
            rate: '',
            total: ''
        }])
    }

    const removesuspension = (index) => {
        const rows = [...inputsuspension];
        rows.splice(index, 1);
        setsuspension(rows);
        CalculationofData(rows, "Suspension");
    }

    const handleChangesuspension = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputsuspension];
        list[index][name] = value;
        setsuspension(list);

        CalculationofData(list, "Suspension");
    }

    const [inputmezzanine, setmezzanine] = useState([{
        mezzanine: '',
        qty: '',
        unit: '',
        rate: '',
        total: ''
    }]);

    const addmezzanine = () => {
        setmezzanine([...inputmezzanine, {
            mezzanine: '',
            qty: '',
            unit: '',
            rate: '',
            total: ''
        }])
    }

    const removemezzanine = (index) => {
        const rows = [...inputmezzanine];
        rows.splice(index, 1);
        setmezzanine(rows);
        CalculationofData(rows, "Mezzanine");
    }

    const handleChangemezzanine = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputmezzanine];
        list[index][name] = value;
        setmezzanine(list);

        CalculationofData(list, "Mezzanine");
    }

    const [inputothers, setinputothers] = useState([{
        others: '',
        qty: '',
        unit: '',
        rate: '',
        total: ''
    }]);

    const addothers = () => {
        setinputothers([...inputothers, {
            others: '',
            qty: '',
            unit: '',
            rate: '',
            total: ''
        }])
    }

    const removeothers = (index) => {
        const rows = [...inputothers];
        rows.splice(index, 1);
        setinputothers(rows);
        CalculationofData(rows, "Other");
    }

    const handleInputDate = (e) => {
        setDate(e.target.value);
    }

    const handleChangeothers = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputothers];
        list[index][name] = value;
        setinputothers(list);

        CalculationofData(list, "Other");
    }


    useEffect(() => {
        getProjectData("Project/GetAll");
        getvendorData("Vendor/GetAll");
        var d = props.name;
        if (d === "details") {
            setDetailFlag(true);
        }
        if (props.id !== 0 && props.id !== undefined) {
            getVendorQuotationDatabyId("VendorClientQuotation/GetById?id=" + props.id, '');
        }
    }, [])

    const getProjectData = (method) => {
        Service.getdata(method).then((res) => {
            setProject(res.data);
        });
    }

    const getvendorData = (method) => {
        Service.getdata(method).then((res) => {
            setvendor(res.data);
        });
    }

    const handleInputVendor = (e) => {
        setvendorId(e.target.value);
    }

    const getVendorQuotationDatabyId = (method, id) => {
        Service.getdatabyId(method, id).then((res) => {
            setBoqDetails(res.data);
            console.log('Boq', res.data);
            setPercent(res.data.tax);
            debugger
            setProjectid(res.data.projectid);
            setDate(res.data.showdate);
            // setvendorId(res.data.vendorid);
            // setplatforming(res.data.inputplatforming)
            // setwalling(res.data.inputwalling)
            // setaudiovisuals(res.data.inputaudiovisuals)
            // sethospitality(res.data.inputhospitality)
            // setlighting(res.data.inputlighting)
            // setbranding(res.data.inputbranding)
            // setsuspension(res.data.inputsuspension)
            // setmezzanine(res.data.inputmezzanine)
            // setinputothers(res.data.inputothers)
            setvendorId(res.data.vendorid);
            CalculationofData(res.data.inputplatforming, "Platforming");
            setplatforming(res.data.inputplatforming)
            setwalling(res.data.inputwalling)
            CalculationofData(res.data.inputwalling, "Walling");
            setaudiovisuals(res.data.inputaudiovisuals)
            CalculationofData(res.data.inputaudiovisuals, "Audio");
            sethospitality(res.data.inputhospitality)
            CalculationofData(res.data.inputhospitality, "Hospitality");
            setlighting(res.data.inputlighting)
            CalculationofData(res.data.inputlighting, "Lighting");
            setbranding(res.data.inputbranding)
            CalculationofData(res.data.inputbranding, "Branding");
            setsuspension(res.data.inputsuspension)
            CalculationofData(res.data.inputsuspension, "Suspension");
            setmezzanine(res.data.inputmezzanine)
            CalculationofData(res.data.inputmezzanine, "Mezzanine");
            setinputothers(res.data.inputothers)
            CalculationofData(res.data.inputothers, "Other");
        });
    }

    function printPage() {
        // Add a class to the elements you want to print
        const elementsToPrint = document.querySelectorAll('.printable');

        // Add a class to the elements you want to exclude from printing
        const elementsToExclude = document.querySelectorAll('.non-printable');

        // Hide the elements to exclude from printing
        elementsToExclude.forEach(element => {
            element.style.display = 'none';
        });

        // Print the page
        window.print();

        // Show the elements that were previously hidden
        elementsToExclude.forEach(element => {
            element.style.display = 'block';
        });
    }



    useEffect(() => {
        if (apiResponse || apiError) {
            const timeoutId = setTimeout(removeSuccessMessage, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [apiResponse, apiError]);

    const removeSuccessMessage = () => {
        setApiResponse(null);
        setApiError(null);
    };

    const backbutton = () => {
        props.navigate(-1);
    }

    const handleInputProject = (e) => {
        setProjectid(e.target.value);
    }
    function formatDate(date) {
        if (!date) return ""; // Handle null or undefined dates
        const localDate = new Date(date);
        const year = localDate.getFullYear();
        const month = String(localDate.getMonth() + 1).padStart(2, '0');
        const day = String(localDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }


    const save = async (evt) => {
        debugger;
        if (evt && evt.preventDefault) {
            evt.preventDefault();
        }

        const formData = new FormData(evt.target);

        const data = {}
        for (const [key, value] of formData) {
            data[key] = value;
        }

        function validateData(data) {
            const { projectid, showdate  } = data;
debugger
            if ( !showdate) {
                alert("Please fill in all required fields.");
                return false;
            }

            if (!projectid || projectid === "[NONE]") {
                setProjectError(true);
                return false;
            } else {
                setProjectError(false);
            }



            return true
        }

        if (!validateData(data)) {
            return;
        }


        const task = {
            ...inputField,
            ...data,
            inputplatforming,
            inputwalling,
            inputaudiovisuals,
            inputhospitality,
            inputlighting,
            inputbranding,
            inputsuspension,
            inputmezzanine,
            inputothers
        };
        console.log(task)
        debugger
        if (task) {

            Service.createorupdate("VendorClientQuotation/CreateOrUpdate", task)
                .then(res => {
                    setApiResponse(res.data); // Set the API response in state
                    window.location.href = "/ClientListQuotation";

                })
                .catch(error => {
                    // Handle error if API call fails
                    console.error("API Error:", error);
                    setApiError("Error occurred while saving the meeting. Please try again later.");
                });
        }
    }

    return (
        <div>
            <div className="container-fluid">

                <div className="card shadow mb-4 non-printable">
                    <div className="card-header py-3">
                        <div className="row">
                            <div class="d-flex flex-start align-items-center">

                            </div>
                            <div className="col-sm-4"><h2>Client Costing</h2></div>
                            <div className="col-sm-5"></div>
                            <div className="col-sm-3 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link>
                               {detailFlag === true && <button className="btn btn-warning mt-2 ml-1  text-uppercase " onClick={() => printPage()}><i class="fa fa-download mr-1" aria-hidden="true"></i> Download</button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="card cardUser shadow mb-4" >
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Client Costing</h6>
                        {apiResponse && (
                            <div className="alert alert-success" role="alert">
                                Client has been created successfully!
                            </div>
                        )}
                        {apiError && (
                            <div className="alert alert-danger" role="alert">
                                {apiError}
                            </div>
                        )}
                    </div>

                    <div class="container">
                        <form onSubmit={save} class="form-horizontal mt-2">


                            {/* <div className="form-group ">
                                <div className='col-sm-11 required-label'>Vendor</div>
                                <div className='col-sm-11'>
                                    {vendorError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a project.</div>}
                                    <select className="form-control  form-control-user" value={vendorId} onChange={handleInputVendor} name="vendorid" required>
                                        <option>[NONE]</option>
                                        {
                                            vendor.map((e, key) => {
                                                //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;
                                                return <option key={e.id} value={e.id}>{e.supplierName}</option>;
                                            })}
                                    </select>
                                </div>
                            </div> */}

                            <div className="form-group ">
                                <div className='col-sm-11 required-label'>Project</div>
                                <div className='col-sm-11'>
                                    {projectError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a project.</div>}
                                    <select className="form-control  form-control-user" readOnly={detailFlag} value={Projectid} name="projectid" onChange={handleInputProject} required>
                                        <option>[NONE]</option>
                                        {
                                            project.map((e, key) => {
                                                return <option key={e.id} value={e.id}>{e.projectname}</option>;
                                            })}
                                    </select>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="boqType" class="col-sm-3 required-label  control-label">Show Date</label>
                                <div class="col-sm-11">
                                    <input type='date' className='form-control  form-control-user' readOnly={detailFlag} onChange={handleInputDate} value={formatDate(date)} name='showdate' id='showdate' required />
                                </div>
                            </div>

                            <br></br>
                            <div className="form-group row">
                                <div className='col-sm-3'><b>Description</b></div>
                                <div className='col-sm-3'><b>Qty</b></div>
                                {detailFlag === true ?<div className='col-sm-2'><b>Total</b></div>
                                :
                                <div className='col-sm-3'><b>Total</b></div>}
                                <div className='col-sm-3'><b>Sum Total</b></div>
                            </div>

                            <div className="form-group row">
                                <div className="col-sm-7 ">Platforming & Floring</div>
                                <div className="col-sm-4 ml-3">
                                    {detailFlag === false && <span className="btn btn-sm btn-outline-success " onClick={addplatforming}>Add Row</span>} &nbsp;
                                    {/* {(inputplatforming.length !== 1) ? <span className="btn btn-sm btn-outline-danger" onClick={removeInputFields}>Remove</span> : ''} */}
                                </div>

                            </div>

                            {
                                inputplatforming.map((data, index) => {
                                    const { platforming, qty, unit, rate, total } = data;
                                    return (
                                        <div>

                                            <div className="form-group row" key={index}>

                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangeplatforming(index, evnt)} readOnly={detailFlag} value={platforming} name="platforming" className="form-control" placeholder="Enter Description" />
                                                </div>
                                                <div className='col-sm-2'>
                                                    <input type="text" onChange={(evnt) => handleChangeplatforming(index, evnt)} readOnly={detailFlag} value={qty} name="qty" className="form-control" placeholder="Enter qty" />

                                                </div>
                                                {/* <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangeplatforming(index, evnt)} readOnly={detailFlag} value={unit} name="unit" className="form-control" placeholder="Enter unit" />
                                                </div>
                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangeplatforming(index, evnt)} readOnly={detailFlag} value={rate} name="rate" className="form-control" placeholder="Enter Rate" />
                                                </div> */}
                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangeplatforming(index, evnt)} readOnly={detailFlag} value={total} name="total" className="form-control" placeholder="Enter total" />
                                                </div>

                                                {detailFlag === false && <div className='col-sm-1'>
                                                    <span className="btn btn-sm btn-outline-danger" onClick={(evnt) => removeInputFields(index, evnt)} >×</span>
                                                </div>}

                                                <div className='col-sm-3'>
                                                    {index === 0 ?
                                                        <b className='ml-3'>{platformingSubTotal}</b>
                                                        : ''
                                                    }

                                                </div>



                                            </div>

                                            {/* <div className="col">   
                        {(inputmultiFields.length!==1)? <button className="btn btn-outline-danger" onClick={removeInputFields}>x</button>:''}                  
                 
                    </div> */}
                                        </div>
                                    )
                                })
                            }

                            <div className="form-group row">
                                <div className="col-sm-7 ">Walling & Special Construction</div>
                                <div className="col-sm-4 ml-3">
                                    {detailFlag === false && <span className="btn btn-sm btn-outline-success " onClick={addwalling}>Add Row</span>} &nbsp;
                                    {/* {(inputwalling.length !== 1) ? <span className="btn btn-sm btn-outline-danger" onClick={removewalling}>Remove</span> : ''} */}
                                </div>

                            </div>
                            {
                                inputwalling.map((data, index) => {
                                    const { walling, qty, unit, rate, total } = data;
                                    return (
                                        <div>

                                            <div className="form-group row" key={index}>

                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangewalling(index, evnt)} readOnly={detailFlag} value={walling} name="walling" className="form-control" placeholder="Enter Description" />
                                                </div>
                                                <div className='col-sm-2'>
                                                    <input type="text" onChange={(evnt) => handleChangewalling(index, evnt)} readOnly={detailFlag} value={qty} name="qty" className="form-control" placeholder="Enter qty" />

                                                </div>
                                                {/* <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangewalling(index, evnt)} readOnly={detailFlag} value={unit} name="unit" className="form-control" placeholder="Enter unit" />
                                                </div>

                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangewalling(index, evnt)} readOnly={detailFlag} value={rate} name="rate" className="form-control" placeholder="Enter rate" />

                                                </div> */}
                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangewalling(index, evnt)} readOnly={detailFlag} value={total} name="total" className="form-control" placeholder="Enter total" />
                                                </div>

                                                {detailFlag === false && <div className='col-sm-1'>
                                                    <span className="btn btn-sm btn-outline-danger" onClick={(evnt) => removewalling(index, evnt)} >×</span>
                                                </div>}

                                                <div className='col-sm-3'>
                                                    {index === 0 ?
                                                        <b className='ml-3'>{wallingSubTotal}</b>
                                                        : ''
                                                    }


                                                </div>




                                            </div>

                                            {/* <div className="col">   
                        {(inputmultiFields.length!==1)? <button className="btn btn-outline-danger" onClick={removeInputFields}>x</button>:''}                  
                 
                    </div> */}
                                        </div>
                                    )
                                })
                            }
                            <div className="form-group row">
                                <div className="col-sm-7 ">Audio Visuals</div>
                                <div className="col-sm-4 ml-3">
                                    {detailFlag === false && <span className="btn btn-sm btn-outline-success " onClick={addaudiovisuals}>Add Row</span>} &nbsp;
                                    {/* {(inputwalling.length !== 1) ? <span className="btn btn-sm btn-outline-danger" onClick={removewalling}>Remove</span> : ''} */}
                                </div>

                            </div>
                            {
                                inputaudiovisuals.map((data, index) => {
                                    const { audiovisuals, qty, unit, rate, total } = data;
                                    return (
                                        <div>

                                            <div className="form-group row" key={index}>

                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangeaudiovisuals(index, evnt)} readOnly={detailFlag} value={audiovisuals} name="audiovisuals" className="form-control" placeholder="Enter Description" />
                                                </div>
                                                <div className='col-sm-2'>
                                                    <input type="text" onChange={(evnt) => handleChangeaudiovisuals(index, evnt)} readOnly={detailFlag} value={qty} name="qty" className="form-control" placeholder="Enter qty" />

                                                </div>
                                                {/* <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangeaudiovisuals(index, evnt)} readOnly={detailFlag} value={unit} name="unit" className="form-control" placeholder="Enter unit" />
                                                </div>
                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangeaudiovisuals(index, evnt)} readOnly={detailFlag} value={rate} name="rate" className="form-control" placeholder="Enter rate" />

                                                </div> */}
                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangeaudiovisuals(index, evnt)} readOnly={detailFlag} value={total} name="total" className="form-control" placeholder="Enter total" />
                                                </div>
                                                {detailFlag === false && <div className='col-sm-1'>
                                                    <span className="btn btn-sm btn-outline-danger" onClick={(evnt) => removeaudiovisuals(index, evnt)} >×</span>
                                                </div>}

                                                <div className='col-sm-3'>
                                                    {index === 0 ?
                                                        <b className='ml-3'>{audioSubTotal}</b>
                                                        : ''
                                                    }


                                                </div>


                                            </div>

                                            {/* <div className="col">   
                        {(inputmultiFields.length!==1)? <button className="btn btn-outline-danger" onClick={removeInputFields}>x</button>:''}                  
                 
                    </div> */}
                                        </div>
                                    )
                                })
                            }

                            <div className="form-group row">
                                <div className="col-sm-7 ">Furniture & Hospitality Items</div>
                                <div className="col-sm-4 ml-3">
                                    {detailFlag === false && <span className="btn btn-sm btn-outline-success " onClick={addhospitality}>Add Row</span>} &nbsp;
                                    {/* {(inputwalling.length !== 1) ? <span className="btn btn-sm btn-outline-danger" onClick={removewalling}>Remove</span> : ''} */}
                                </div>

                            </div>
                            {
                                inputhospitality.map((data, index) => {
                                    const { hospitality, qty, unit, rate, total } = data;
                                    return (
                                        <div>

                                            <div className="form-group row" key={index}>

                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangehospitality(index, evnt)} readOnly={detailFlag} value={hospitality} name="hospitality" className="form-control" placeholder="Enter Description" />
                                                </div>
                                                <div className='col-sm-2'>
                                                    <input type="text" onChange={(evnt) => handleChangehospitality(index, evnt)} readOnly={detailFlag} value={qty} name="qty" className="form-control" placeholder="Enter qty" />

                                                </div>
                                                {/* <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangehospitality(index, evnt)} readOnly={detailFlag} value={unit} name="unit" className="form-control" placeholder="Enter unit" />
                                                </div>

                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangehospitality(index, evnt)} readOnly={detailFlag} value={rate} name="rate" className="form-control" placeholder="Enter rate" />

                                                </div> */}
                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangehospitality(index, evnt)} readOnly={detailFlag} value={total} name="total" className="form-control" placeholder="Enter total" />
                                                </div>

                                                {detailFlag === false && <div className='col-sm-1'>
                                                    <span className="btn btn-sm btn-outline-danger" onClick={(evnt) => removehospitality(index, evnt)} >×</span>
                                                </div>}

                                                <div className='col-sm-3'>
                                                    {index === 0 ?
                                                        <b className='ml-3'>{hospitalitySubTotal}</b>
                                                        : ''
                                                    }


                                                </div>



                                            </div>

                                            {/* <div className="col">   
                        {(inputmultiFields.length!==1)? <button className="btn btn-outline-danger" onClick={removeInputFields}>x</button>:''}                  
                 
                    </div> */}
                                        </div>
                                    )
                                })
                            }

                            <div className="form-group row">
                                <div className="col-sm-7 ">Lighting & Cabling</div>
                                <div className="col-sm-4 ml-3">
                                    {detailFlag === false && <span className="btn btn-sm btn-outline-success " onClick={addlighting}>Add Row</span>} &nbsp;
                                    {/* {(inputwalling.length !== 1) ? <span className="btn btn-sm btn-outline-danger" onClick={removewalling}>Remove</span> : ''} */}
                                </div>

                            </div>
                            {
                                inputlighting.map((data, index) => {
                                    const { lighting, qty, unit, rate, total } = data;
                                    return (
                                        <div>

                                            <div className="form-group row" key={index}>

                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangelighting(index, evnt)} readOnly={detailFlag} value={lighting} name="lighting" className="form-control" placeholder="Enter Description" />
                                                </div>
                                                <div className='col-sm-2'>
                                                    <input type="text" onChange={(evnt) => handleChangelighting(index, evnt)} readOnly={detailFlag} value={qty} name="qty" className="form-control" placeholder="Enter qty" />

                                                </div>
                                                {/* <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangelighting(index, evnt)} readOnly={detailFlag} value={unit} name="unit" className="form-control" placeholder="Enter unit" />
                                                </div>

                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangelighting(index, evnt)} readOnly={detailFlag} value={rate} name="rate" className="form-control" placeholder="Enter rate" />

                                                </div> */}
                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangelighting(index, evnt)} readOnly={detailFlag} value={total} name="total" className="form-control" placeholder="Enter total" />
                                                </div>

                                                {detailFlag === false && <div className='col-sm-1'>
                                                    <span className="btn btn-sm btn-outline-danger" onClick={(evnt) => removelighting(index, evnt)} >×</span>
                                                </div>}

                                                <div className='col-sm-3'>
                                                    {index === 0 ?
                                                        <b className='ml-3'>{lightingSubTotal}</b>
                                                        : ''
                                                    }


                                                </div>


                                            </div>

                                            {/* <div className="col">   
                        {(inputmultiFields.length!==1)? <button className="btn btn-outline-danger" onClick={removeInputFields}>x</button>:''}                  
                 
                    </div> */}
                                        </div>
                                    )
                                })
                            }

                            <div className="form-group row">
                                <div className="col-sm-7 ">Branding & Graphics</div>
                                <div className="col-sm-4 ml-3">
                                    {detailFlag === false && <span className="btn btn-sm btn-outline-success " onClick={addbranding}>Add Row</span>} &nbsp;
                                    {/* {(inputwalling.length !== 1) ? <span className="btn btn-sm btn-outline-danger" onClick={removewalling}>Remove</span> : ''} */}
                                </div>

                            </div>
                            {
                                inputbranding.map((data, index) => {
                                    const { branding, qty, unit, rate, total } = data;
                                    return (
                                        <div>

                                            <div className="form-group row" key={index}>

                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangebranding(index, evnt)} readOnly={detailFlag} value={branding} name="branding" className="form-control" placeholder="Enter Description" />
                                                </div>
                                                <div className='col-sm-2'>
                                                    <input type="text" onChange={(evnt) => handleChangebranding(index, evnt)} readOnly={detailFlag} value={qty} name="qty" className="form-control" placeholder="Enter qty" />

                                                </div>
                                                {/* <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangebranding(index, evnt)} readOnly={detailFlag} value={unit} name="unit" className="form-control" placeholder="Enter unit" />
                                                </div>

                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangebranding(index, evnt)} readOnly={detailFlag} value={rate} name="rate" className="form-control" placeholder="Enter rate" />

                                                </div> */}
                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangebranding(index, evnt)} readOnly={detailFlag} value={total} name="total" className="form-control" placeholder="Enter total" />
                                                </div>

                                                {detailFlag === false && <div className='col-sm-1'>
                                                    <span className="btn btn-sm btn-outline-danger" readOnly onClick={(evnt) => removebranding(index, evnt)} >×</span>
                                                </div>}


                                                <div className='col-sm-3'>
                                                    {index === 0 ?
                                                        <b className='ml-3'>{brandingSubTotal}</b>
                                                        : ''
                                                    }
                                                </div>


                                            </div>

                                            {/* <div className="col">   
                        {(inputmultiFields.length!==1)? <button className="btn btn-outline-danger" onClick={removeInputFields}>x</button>:''}                  
                 
                    </div> */}
                                        </div>
                                    )
                                })
                            }

                            <div className="form-group row">
                                <div className="col-sm-7 ">Suspension</div>
                                <div className="col-sm-4 ml-3">
                                    {detailFlag === false && <span className="btn btn-sm btn-outline-success " onClick={addsuspension}>Add Row</span>} &nbsp;
                                    {/* {(inputwalling.length !== 1) ? <span className="btn btn-sm btn-outline-danger" onClick={removewalling}>Remove</span> : ''} */}
                                </div>

                            </div>
                            {
                                inputsuspension.map((data, index) => {
                                    const { suspension, qty, unit, rate, total } = data;
                                    return (
                                        <div>

                                            <div className="form-group row" key={index}>

                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangesuspension(index, evnt)} readOnly={detailFlag} value={suspension} name="suspension" className="form-control" placeholder="Enter Description" />
                                                </div>
                                                <div className='col-sm-2'>
                                                    <input type="text" onChange={(evnt) => handleChangesuspension(index, evnt)} readOnly={detailFlag} value={qty} name="qty" className="form-control" placeholder="Enter qty" />

                                                </div>
                                                {/* <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangesuspension(index, evnt)} readOnly={detailFlag} value={unit} name="unit" className="form-control" placeholder="Enter unit" />
                                                </div>

                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangesuspension(index, evnt)} readOnly={detailFlag} value={rate} name="rate" className="form-control" placeholder="Enter v" />

                                                </div> */}
                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangesuspension(index, evnt)} readOnly={detailFlag} value={total} name="total" className="form-control" placeholder="Enter total" />
                                                </div>
                                                {detailFlag === false && <div className='col-sm-1'>
                                                    <span className="btn btn-sm btn-outline-danger" onClick={(evnt) => removesuspension(index, evnt)} >×</span>
                                                </div>}



                                                <div className='col-sm-3'>
                                                    {index === 0 ?
                                                        <b className='ml-3'>{SuspensionSubTotal}</b>
                                                        : ''
                                                    }
                                                </div>

                                            </div>

                                            {/* <div className="col">   
                        {(inputmultiFields.length!==1)? <button className="btn btn-outline-danger" onClick={removeInputFields}>x</button>:''}                  
                 
                    </div> */}
                                        </div>
                                    )
                                })
                            }

                            <div className="form-group row">
                                <div className="col-sm-7 ">Mezzanine</div>
                                <div className="col-sm-4 ml-3">
                                    {detailFlag === false && <span className="btn btn-sm btn-outline-success " onClick={addmezzanine}>Add Row</span>} &nbsp;
                                    {/* {(inputwalling.length !== 1) ? <span className="btn btn-sm btn-outline-danger" onClick={removewalling}>Remove</span> : ''} */}
                                </div>

                            </div>
                            {
                                inputmezzanine.map((data, index) => {
                                    const { mezzanine, qty, unit, rate, total } = data;
                                    return (
                                        <div>

                                            <div className="form-group row" key={index}>

                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangemezzanine(index, evnt)} readOnly={detailFlag} value={mezzanine} name="mezzanine" className="form-control" placeholder="Enter Description" />
                                                </div>
                                                <div className='col-sm-2'>
                                                    <input type="text" onChange={(evnt) => handleChangemezzanine(index, evnt)} readOnly={detailFlag} value={qty} name="qty" className="form-control" placeholder="Enter qty" />

                                                </div>
                                                {/* <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangemezzanine(index, evnt)} readOnly={detailFlag} value={unit} name="unit" className="form-control" placeholder="Enter unit" />
                                                </div>

                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangemezzanine(index, evnt)} readOnly={detailFlag} value={rate} name="rate" className="form-control" placeholder="Enter rate" />

                                                </div> */}
                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangemezzanine(index, evnt)} readOnly={detailFlag} value={total} name="total" className="form-control" placeholder="Enter total" />
                                                </div>

                                                {detailFlag === false && <div className='col-sm-1'>
                                                    <span className="btn btn-sm btn-outline-danger" onClick={(evnt) => removemezzanine(index, evnt)} >×</span>
                                                </div>}

                                                <div className='col-sm-3'>
                                                    {index === 0 ?
                                                        <b className='ml-3'>{MezzanineSubTotal}</b>
                                                        : ''
                                                    }
                                                </div>



                                            </div>

                                            {/* <div className="col">   
                        {(inputmultiFields.length!==1)? <button className="btn btn-outline-danger" onClick={removeInputFields}>x</button>:''}                  
                 
                    </div> */}
                                        </div>
                                    )
                                })
                            }

                            <div className="form-group row">
                                <div className="col-sm-7 ">Other Cost</div>
                                <div className="col-sm-4 ml-3">
                                    {detailFlag === false && <span className="btn btn-sm btn-outline-success " onClick={addothers}>Add Row</span>} &nbsp;
                                    {/* {(inputwalling.length !== 1) ? <span className="btn btn-sm btn-outline-danger" onClick={removewalling}>Remove</span> : ''} */}
                                </div>

                            </div>

                            {
                                inputothers.map((data, index) => {
                                    const { others, qty, unit, rate, total } = data;
                                    return (
                                        <div>

                                            <div className="form-group row" key={index}>

                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangeothers(index, evnt)} readOnly={detailFlag} value={others} name="others" className="form-control" placeholder="Enter Description" />
                                                </div>
                                                <div className='col-sm-2'>
                                                    <input type="text" onChange={(evnt) => handleChangeothers(index, evnt)} readOnly={detailFlag} value={qty} name="qty" className="form-control" placeholder="Enter qty" />

                                                </div>
                                                {/* <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangeothers(index, evnt)} readOnly={detailFlag} value={unit} name="unit" className="form-control" placeholder="Enter unit" />
                                                </div>

                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangeothers(index, evnt)} readOnly={detailFlag} value={rate} name="rate" className="form-control" placeholder="Enter Rate" />

                                                </div> */}
                                                <div className='col-sm-3'>
                                                    <input type="text" onChange={(evnt) => handleChangeothers(index, evnt)} readOnly={detailFlag} value={total} name="total" className="form-control" placeholder="Enter total" />
                                                </div>

                                                {detailFlag === false && <div className='col-sm-1'>
                                                    <span className="btn btn-sm btn-outline-danger" onClick={(evnt) => removeothers(index, evnt)} >×</span>
                                                </div>}

                                                <div className='col-sm-3'>
                                                    {index === 0 ?
                                                        <b className='ml-3'>{OtherSubTotal}</b>
                                                        : ''
                                                    }
                                                </div>



                                            </div>

                                            {/* <div className="col">   
                        {(inputmultiFields.length!==1)? <button className="btn btn-outline-danger" onClick={removeInputFields}>x</button>:''}                  
                 
                    </div> */}
                                        </div>
                                    )
                                })
                            }

                            <div className="form-group row">
                                <div className="col-sm-4 float-right"></div>
                                <div className="col-sm-4 float-right"></div>
                                <div className="col-sm-4 float-right">Total Cost INR &nbsp;&nbsp; {clienttotalcost}</div>


                            </div>
                            <div className="form-group row">
                                <div className="col-sm-4 float-right"></div>
                                <div className="col-sm-4 float-right"></div>
                                <div className="col-sm-4 float-right d-flex">Tax In Percent% &nbsp;&nbsp;
                                    <input type="Float" name="tax" value={percent} readOnly={detailFlag} onChange={(evnt) => handlePercent(evnt)} className="col-sm-3" placeholder="tax in %" />

                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-sm-4 float-right"></div>
                                <div className="col-sm-4 float-right"></div>
                                <div className="col-sm-4 float-right">Final Cost &nbsp;&nbsp; {finalCost}</div>
                            </div>
                            {apiResponse && (
                                <div className="alert alert-success" role="alert">
                                    client costing has been created successfully!
                                </div>
                            )}
                            {apiError && (
                                <div className="alert alert-danger" role="alert">
                                    {apiError}
                                </div>
                            )}
                            {detailFlag === false && <button type="submit" class="btn btn-primary ml-3 mb-3">Save</button>}

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateClientQuotationComponent