import React, { useEffect, useState } from 'react'
import Service from '../../../Services/Admin/Service';
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState, convertFromHTML, ContentState, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Select from 'react-select';
import draftToHtml from 'draftjs-to-html';
import { Link } from 'react-router-dom';

const BoqIsSharedComponent = (props) => {

  const id = props.id;
  const [apiError, setApiError] = useState(null);
  const [apiResponse, setApiResponse] = useState(null);
  const [issharedchecked, setissharedchecked] = useState(false);
  const [boqDetail, setBoqDetail] = useState([])
  const [sharedTo, setSharedTo] = useState(false)
  const [boqType, setboqType] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [inputField, setInputField] = useState(
    {
      id: props.id,
      boqdetails: "",
      projectid: 0,
      clientName: "",
      boqType: "",
      createdby: id,
      updatedBy: id
    }
  )

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    getBoqInfo("Boq/GetById?id=" + id)
    getSelectedVendorDetails("SelectVendor/GetAll");

  }, [])

  const getSelectedVendorDetails = (method) => {
    Service.getdata(method).then((res) => {
      setVendor(res.data);
      debugger
    });

  }

  const getBoqInfo = (method) => {
    Service.getdata(method).then((res) => {
      setBoqDetail(res.data);
      console.log(res.data);
      setboqType(res.data.boqType);
      debugger
      // if(res.data.boqType !== "3"){
      //   setSharedTo(false);
      // }
      const content1 = convertFromRaw(JSON.parse(res.data.boqdetails));

      setEditorState(EditorState.createWithContent(content1));
      setInputField(res.data);
      setissharedchecked(res.data.isShared);
    });
  }
  console.log(boqDetail)

  const backbutton = () => {
    props.navigate(-1);
  }

  useEffect(() => {
    if (apiResponse || apiError) {
      const timeoutId = setTimeout(removeSuccessMessage, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [apiResponse, apiError]);

  const removeSuccessMessage = () => {
    setApiResponse(null);
    setApiError(null);
  };

  const handleShare = (evt) => {
    
    if (evt === "3"  ) {
      debugger
      setSharedTo(true);

    }
    else {
      setSharedTo(false);
    }
  }

  const handleShareChange = (e) => {
    setboqType(e.target.value);
    debugger
  }

  const save = async (evt) => {
    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }

    const formData = new FormData(evt.target);

    const data = {};
    for (const [key, value] of formData) {
      data[key] = value;
    }

    const { isApproved, boqType, clientName } = data;
    const task = {
      ...inputField,
      boqType: boqType,
      clientName: clientName,
    };
    console.log(task);
    debugger
    if (task) {

      Service.createorupdate("Boq/CreateOrUpdate", task)
        .then(res => {
          setApiResponse(res.data); // Set the API response in state
        })
        .catch(error => {
          // Handle error if API call fails
          console.error("API Error:", error);
          setApiError("Error occurred while saving the Boq. Please try again later.");
        });
    }

  }

  const handleEditorChange = (state) => {
    setEditorState(state);
  }

  const handleIsCheckbox = (e) => {
    //alert(e.target.checked);
    inputField.isShared = e.target.checked;
    setissharedchecked(e.target.checked);
  }

  return (
    <div>
      <div className="container-fluid">

        <div className="card shadow border-success mb-2">
          <div className="card-header rounded py-3">
            <div className="row">
              <div class="d-flex flex-start align-items-center">
              </div>
              <div className="col-sm-4"><h2><b className='text-dark'>Boq Is Shared</b></h2></div>
              <div className="col-sm-6"></div>
              <div className="col-sm-2 d-flex flex-row-reverse">
                <button className="btn btn-info  ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid text-dark">
        <div className="card cardUser shadow mb-4" >
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-success">Shared</h6>
            {apiResponse && (
              <div className="alert alert-success" role="alert">
                Boq has been Updated Successfully!
              </div>
            )}
            {apiError && (
              <div className="alert alert-danger" role="alert">
                {apiError}
              </div>
            )}
          </div>
          <div class="container">
            <form onSubmit={save} class="form-horizontal mt-2">
              <div class="form-group row">
                <div className='col-sm-5 required-label'>Boq Details</div>
                <div class="col-sm-7">
                  <Editor
                    readOnly
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={handleEditorChange}
                    required
                  />
                </div>
                <div class="col-sm-9">
                </div>
              </div>

              <div class="form-group row">
                <div className='col-sm-5 required-label'>Project</div>
                <div className='col-sm-7'>
                  <b>{boqDetail.projectName}</b>
                </div>
                <div class="col-sm-9">
                </div>
              </div>

              <div class="form-group row">
                <div className='col-sm-5 required-label'>Shared To</div>
                <div className='col-sm-7'>
                  <select className="form-control  form-control-user" value={boqType} onChange={(e) => { handleShare(e.target.value); handleShareChange(e) }} name="boqType" required>
                    <option>[NONE]</option>
                    <option value={3}>Client</option>
                    {
                      vendor.map((e, key) => {
                        return <option key={e.vendorid} value={e.vendorid}>{e.supplierName}</option>;
                      })}
                  </select>
                  {sharedTo === true  ? <input type="text" name="clientName" value={boqDetail.clientName} className="form-control mt-1" placeholder="enter client name" /> : null}
                </div>
                <div class="col-sm-9">
                </div>
              </div>

              <div class="form-group row">
                <label for="details" class="col-sm-5  required-label  control-label">Is Shared</label>
                <div class="col-sm-7">
                  <input
                    type="checkbox"
                    id="details"
                    name='isShared'
                    checked={issharedchecked}
                    onChange={handleIsCheckbox}
                    autoFocus
                  />
                </div>
              </div>
              {apiResponse && (
                <div className="alert alert-success" role="alert">
                  Boq has been Updated Successfully!
                </div>
              )}
              {apiError && (
                <div className="alert alert-danger" role="alert">
                  {apiError}
                </div>
              )}

              <button type="submit" class="btn btn-primary ml-3 mb-3">Update</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BoqIsSharedComponent