import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import Select from "react-select";
import Service from "../../../Services/Admin/Service";
import React from "react";
import { useState, useEffect } from "react";
import {
  getCurrentDate,
  getLoginUserData,
} from "../../../Helpers/supportedFunctions/supportedGlobalFunctions";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

const RFPListComponent = (props) => {
  const id = sessionStorage.getItem("id");
  const role = sessionStorage.getItem("role");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [apiError, setApiError] = useState(null);
  const [apiResponse, setApiResponse] = useState(null);
  const [userName, setUserName] = useState([]);
  const [showAssigneePopup, setShowAssigneePopup] = useState(false);
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [inputField, setInputField] = useState([
    {
      rfpshareid: 0,
      rfpid: 0,
      userid: 0,
      createdby: id,
      updatedby: id,
    },
  ]);
  // auto task generation
  const [taskInputField, setTaskInputField] = useState({
    id: 0,
    headline: "Design Requirement",
    shortDescription:
      '{"blocks":[{"key":"etueq","text":"Final Dsign For This ","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
    projectId: 0,
    showId: 0,
    leadid: props.id,
    isRequested: false,
    parentId: null,
    // typeId: 119,
    typeId: 1130,
    statusId: 1,
    priorityId: 2,
    planhour: 0,
    finishdate: "",
    createdby: getLoginUserData().userId,
    updatedby: null,
    assignerid: getLoginUserData().userId,
    isDeleted: false,
    contentLink: null,
    isActive: true,
    assigneeid: [0],
    removeAssigneeid: [],
  });

  // google calendar
  const [calendarUser, setCalendarUser] = useState([]);
  const [inputCalendar, setInputCalendar] = useState({
    summary: "Create Design For This brief",
    description: "Design for this",
    startTime: "",
    endTime: "",
    calendarId: "sop@xsproductions.com",
    refreshToken:
      "1//0gkWVmDAlyPkGCgYIARAAGBASNwF-L9Irz9KGiZeCkJMbqqXSTdlCky2cDgLR866ieBZs7Uwie0VBZZXQekor3AGqaieyoOCCvbM",
    users: [""],
  });
  //set end auto task generation

  useEffect(() => {
    getUserData("Users/GetAll");
    getRFPData("RFP/GetAll?id=" + id);
  }, []);

  function filterTasksWithUniqueIds(tasks) {
    // Create an object to store unique tasks based on their ID
    const uniqueTasks = tasks.reduce((acc, task) => {
      // Use the task ID as the key in the object
      const taskId = task.id;

      // If the task ID is not in the object, add it
      if (!acc[taskId]) {
        acc[taskId] = task;
      }

      return acc;
    }, {});

    // Convert the uniqueTasks object back to an array
    const uniqueTasksArray = Object.values(uniqueTasks);

    return uniqueTasksArray;
  }

  const getRFPData = (method) => {
    Service.getdata(method, "").then((res) => {
      debugger
      var uniqueRFP = filterTasksWithUniqueIds(res.data);

      console.log(getCurrentDate);
      var progressrfp = uniqueRFP.filter((x) => x.dateTo >= getCurrentDate());
      setData(progressrfp.reverse());
      debugger;
      console.log(res.data);
    });
  };

  const getUserData = (method) => {
    Service.getdata(method).then((res) => {
      //   setUserName(res.data);
      var a = res.data;
      var list = [];

      a.forEach((d, index) => {
        var role = d.lstRole;
        var checkdepartment = role.find((x) => x.depId === 2 || x.depId === 4);
        if (checkdepartment !== undefined) {
          d.rolename = d.lstRole[0].name;
          list.push(d);
        }
      });
      setUserName(list);
    });
  };

  const shareRowData = (data) => {
    console.log("Share button clicked for data:", data);
    setRowData(data);
  };

  function extractValuesFromIndexedObject(indexedObject) {
    return Object.values(indexedObject).map((item) => item.value);
  }

  const handleSelectChange = (selectedOptions) => {
    setCalendarUser(selectedOptions);
    const extractedValues = extractValuesFromIndexedObject(selectedOptions);
    setSelectedAssignees(extractedValues);
  };

  const backbutton = () => {
    props.navigate(-1);
  };
  useEffect(() => {
    if (apiResponse || apiError) {
      const timeoutId = setTimeout(removeSuccessMessage, 3000);
      window.location.href = "/RFPList";
      return () => clearTimeout(timeoutId);
    }
  }, [apiResponse, apiError]);

  const removeSuccessMessage = () => {
    setApiResponse(null);
    setApiError(null);
  };

  const autoTaskCreation = () => {
    var multiUserTask = [];
    var descriptionTask = JSON.parse(taskInputField.shortDescription);
    descriptionTask.blocks[0].text = rowData.description;
    const modifiedDescription = JSON.stringify(descriptionTask);
    for (var i = 0; i < selectedAssignees.length; i++) {
      multiUserTask.push({
        ...taskInputField,
        assigneeid: [selectedAssignees[i]],
        leadid: rowData.name,
        shortDescription: modifiedDescription,
        finishdate: rowData.deadlineDate,
      });
      console.log(multiUserTask[i]);
      debugger;
      var date1 = new Date(rowData.deadlineDate);
      date1.setHours(18, 0, 0, 0);

      var userEmail = calendarUser[i].email;

      const calendarTask = {
        ...inputCalendar,
        description: rowData.leadid,
        startTime: new Date().toISOString(),
        endTime: date1,
        colorId: "4",
        users: [userEmail],
      };
      console.log(calendarTask);
      debugger;
      const save = async () => {
        if (calendarTask) {
          await Service.createorupdate("user/calendarevent", calendarTask).then(
            (res) => {
              return res.data;
            }
          );
        }
      };
      var res = save();
      console.log(res);
      debugger;
      if (res) {
        if (multiUserTask[i]) {
          Service.createorupdate("Task/CreateOrUpdate", multiUserTask[i])
            .then((res) => { })
            .catch((error) => {
              // Handle error if API call fails
              console.error("API Error:", error);
              setApiError(
                "Error occurred while saving the task. Please try again later."
              );
            });
        }
      }
    }
    window.location.href = "/KanbanAssignedTask";
  };

  const shareData = () => {
    setLoading(true);
    console.log(rowData);
    console.log(selectedAssignees);

    const data = [];
    for (let i = 0; i < selectedAssignees.length; i++) {
      const assignment = {
        rfpid: rowData.id,
        userid: selectedAssignees[i],
        createdby: id,

        updatedby: id,
      };
      data.push(assignment);
    }
    console.log(data);

    if (data) {
      Service.createorupdate("RFP/CreateOrUpdateshare", data)
        .then((res) => {
          if (data) {
            for (var m = 0; m <= data.length; m++) {
              console.log(data[m]);

              Service.createorupdate("Questionare/CreateOrUpdate", data[m])
                .then((res) => {
                  if (res.data) {
                    setLoading(false);
                  }
                  setApiResponse(res.data);
                  window.location.reload(true);
                })
                .catch((error) => {
                  console.error("API Error:", error);
                });
            }
            window.location.reload(true);
          }
          setApiResponse(res.data); // Set the API response in state
        })
        .catch((error) => {
          // Handle error if API call fails
          console.error("API Error:", error);
          setApiError(
            "Error occurred while saving the meeting. Please try again later."
          );
        });
    }
  };

  const formatDateTime = (cell, row) => {
    // Format the date and time here, assuming 'time' is a string in ISO 8601 format
    if (cell !== null) {
      const formattedDate = new Date(cell).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      return formattedDate;
    } else {
      return null;
    }
  };

  const linkformatter = (data, row) => {
    return (
      <>
        {row.createdby === parseInt(getLoginUserData().userId) ||
          role === "Manager - Experiential & Key Accounts" ||
          role === "DGM-Projects" ||
          role === "Assistant General Manager - Business Development" ||
          role === "Senior Manager - Sales & BD" ||
          role === "Sr.Manager-Projects" ||
          role === "Admin" ||
          role === "Managing Director(MD)" ? (
          <Link
            className="btn btn-warning btn-sm mb-1"
            to={`/EditRFPDetails/${data}`}
          >
            Edit
          </Link>
        ) : null}

        {/* <Link className="btn btn-info ml-1 btn-sm" to={`/RFPDetails/${data}`}>Download</Link> */}
        <Link className="btn btn-info ml-1 btn-sm" to={`/RFPDetails/${data}`}>
          Details
        </Link>
        {/* <button type="button" class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">Open Modal</button> */}
        {getLoginUserData().deptId !== "3" ||
          getLoginUserData().role ===
          "Executive - Software Developer(SOP Tool)" ? (
          <button
            type="button"
            class="btn btn-primary ml-1 btn-sm mt-1"
            onClick={() => shareRowData(row)}
            data-toggle="modal"
            data-target="#myModal"
          >
            <i class="fa fa-share-alt mr-1"></i>share RFP
          </button>
        ) : null}
        {/* {getLoginUserData().role === "Executive - Software Developer(SOP Tool)" ? <button type="button" class="btn btn-primary ml-1 btn-sm mt-1" onClick={() => shareRowData(row)} data-toggle="modal" data-target="#myModal">
                <i class="fa fa-share-alt mr-1"></i>share RFP
            </button>: null} */}
      </>
    );
  };

  const shared = (data, row) => {
    var IsCompleted = "";
    if (data === true) {
      IsCompleted = "Completed";
    } else {
      IsCompleted = "In Progress";
    }
    return IsCompleted;
  };

  const sharedToUser = (data, row) => {
    // Assuming data is an array of objects with asigneeName property
    if (Array.isArray(data) && data.length > 0) {
      // Use Set to remove duplicate asigneeName values
      const uniqueAsigneeNames = [...new Set(data.map((e) => e.asigneeName))];
      return uniqueAsigneeNames.join(", ");
    } else {
      // If data is not an array or is empty, handle it accordingly
      return "No shared users"; // Or any default value you prefer
    }
  };

  const column = [
    {
      dataField: "leadid",
      text: "Lead Name",
      sort: true,
      filter: textFilter(),
    },
    { dataField: "area", text: "Area", sort: true },
    {
      dataField: "createdByName",
      text: "Author",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "rfpShareList",
      text: "Shared To",
      sort: true,
      formatter: sharedToUser,
    },
    {
      dataField: "createdDate",
      text: "Created Date",
      sort: true,
      formatter: formatDateTime,
    },
    {
      dataField: "dateFrom",
      text: "Start Date",
      sort: true,
      formatter: formatDateTime,
    },
    {
      dataField: "dateTo",
      text: "End Date",
      sort: true,
      formatter: formatDateTime,
    },
    {
      dataField: "deadlineDate",
      text: "Design Deadline",
      formatter: formatDateTime,
      sort: true,
    },
    {
      dataField: "isCompleted",
      text: "Is Completed",
      sort: true,
      formatter: shared,
    },
    { dataField: "id", text: "Actions", formatter: linkformatter },
  ];

  return (
    <div className="container-fluid">
      <div className="card shadow border-success mb-2">
        <div className="card-header rounded py-3">
          <div className="row">
            <div className="col-sm-3 mt-1">
              <h3>
                <b className="text-dark">RFP List</b>
              </h3>
            </div>
            <div className="col-sm-5"></div>
            <div className="col-sm-4 d-flex flex-row-reverse">
              <button
                className="btn btn-info mt-2 ml-1  text-uppercase "
                onClick={() => backbutton()}
              >
                back
              </button>
              {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
            </div>
          </div>
        </div>
      </div>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-success">RFP List</h6>
          {apiResponse && (
            <div className="alert alert-success mt-2" role="alert">
              RFP has been shared successfully!
            </div>
          )}
          {apiError && (
            <div className="alert alert-danger" role="alert">
              {apiError}
            </div>
          )}
          {loading === false && (
            <h6 className="font-weight-bold text-primary">{ }</h6>
          )}
          {loading === true && (
            <div class=" text-primary">
              <strong>Sharing...</strong>
              <div
                class="spinner-border ml-auto"
                role="status"
                aria-hidden="true"
              ></div>
            </div>
          )}
          {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
        </div>

        <div className="card-body">
          <div className="table-responsive" style={{ overflowX: "auto" }}>
            <BootstrapTable
              keyField="id"
              data={data}
              rowStyle={{ color: "black", fontWeight: 600 }}
              columns={column}
              pagination={paginationFactory()}
              filter={filterFactory()}
            />
          </div>
        </div>
      </div>

      <div class="modal fade" id="myModal" role="dialog">
        <div
          id="exampleModalCenter"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  Share RFP
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <Select
                  name="assigneeid"
                  isMulti={true}
                  placeholder="Enter members to share with"
                  options={userName.map((user) => ({
                    value: user.id,
                    label: `${user.firstname} ${user.lastname}`,
                    email: user.email,
                  }))}
                  value={selectedAssignees.value}
                  onChange={handleSelectChange}
                  onClick={() => setShowAssigneePopup(false)}
                />
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <div class="btn-group">
                  {/* <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Share
                                    </button> */}
                  {/* <div class="dropdown-menu"> */}
                  {/* <button type='button' class="dropdown-item" onClick={shareData} data-dismiss="modal">Share Only</button> */}
                  {(getLoginUserData().deptId === "2" ||
                    getLoginUserData().deptId === "4" ||
                    getLoginUserData().role ===
                    "Executive - Software Developer(SOP Tool)") && (
                      <button
                        type="button"
                        class="btn btn-primary "
                        onClick={() => {
                          shareData();
                          autoTaskCreation();
                        }}
                        data-dismiss="modal"
                      >
                        Share and Create Task
                      </button>
                    )}
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {showAssigneePopup && (
          <>
            <Select
              name="assigneeid"
              isMulti={true}
              placeholder="Enter members to share with"
              options={userName.map((user) => ({
                value: user.id,
                label: `${user.firstname} ${user.lastname}`,
              }))}
              value={selectedAssignees.value}
              onChange={handleSelectChange}
              onClick={() => setShowAssigneePopup(false)}
            />
            <button
              className="close-popup-btn"
              onClick={() => setShowAssigneePopup(false)}
            >
              Close popup
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default RFPListComponent;
