import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import CreateContentComponent from '../../../Components/Main/CreateContentComponent/CreateContentComponent';

function CreateContent(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <CreateContentComponent {...props} navigate={navigate}  />
        </div>
    );  
  }
export default CreateContent  