import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import ContentIsApprovedComponent from '../../../Components/Main/CreateContentComponent/ContentIsApprovedComponent';

function ContentIsApproved(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <ContentIsApprovedComponent {...props} navigate={navigate} id={params.id} />
        </div>
    );  
  }
export default ContentIsApproved  