import React, { useEffect, useState } from 'react'
import Service from "../../../Services/Admin/Service";
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';



const ShowListComponent = (props) => {

  const userId  = sessionStorage.getItem("id"); 

  const [data, setData] = useState([]);


  useEffect(() => {
    getShowsData("Shows/GetALLShareshow?userid=" + userId);

  }, [])

  const getShowsData = (method) => {
    Service.getdata(method).then((res) => {
      setData(res.data);
      console.log("Show Data", res.data)
      debugger
    });
  }

  const backbutton = () => {
    props.navigate(-1);
  }

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="btn-group" role="group">
      {
        options.map(option => (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${currSizePerPage === `${option.page}` ? 'btn-secondary' : 'btn-warning'}`}
          >
            {option.text}
          </button>
        ))
      }
    </div>
  );

  const options = {
    sizePerPageRenderer
  };


  const linkformatter = (data, row) => {
    return <>
      <Link className="btn btn-info btn-sm" to={`/ExhibitorList/${data}`}>Exhibhitor</Link> &nbsp;

      {/* <Link className="btn btn-warning btn-sm" to={`/MeetingDetails/${data}`}>Details</Link> */}
    </>
  }

  const column = [
    // { dataField: 'showChapterId', text: 'show Chapter Id', sort: true,},
    { dataField: 'showName', text: 'Show Name', sort: true },
    // { dataField: 'isActive', text: 'Is Active' },
    {
      dataField: 'startdate',
      text: 'Start Date',
      sort: true,

      formatter: (cell, row) => {
        // Format the date here, assuming createddate is a string in ISO 8601 format
        const formattedDate = new Date(cell).toLocaleDateString('en-US', {
          day: 'numeric',
          year: 'numeric',
          month: 'short',
        });
        return formattedDate;
      }
    },
    {
      dataField: 'enddate', text: 'End Date', sort: true,
      formatter: (cell, row) => {
        // Format the date here, assuming createddate is a string in ISO 8601 format
        const formattedDate = new Date(cell).toLocaleDateString('en-US', {
          day: 'numeric',
          year: 'numeric',
          month: 'short',
        });
        return formattedDate;
      }
    },
    { dataField: 'showChapterId', text: 'Actions', formatter: linkformatter }
  ]
  return (
    <div className="container-fluid">
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <div className="row">
            <div className="col-sm-4"><h3>Shows List Sales (Exhibition)</h3></div>
            <div className="col-sm-5"></div>
            <div className="col-sm-3 d-flex flex-row-reverse">
              <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
              <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Shows List</h6>
          {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
        </div>

        <div className="card-body">
          <div className="table-responsive">
            <BootstrapTable keyField="id"
              data={data}
              columns={column}
              pagination={paginationFactory(options)}
              filter={filterFactory()}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShowListComponent