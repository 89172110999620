import React, { useEffect, useState } from 'react'
import Service from '../../../Services/Admin/Service';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { formatDate, getLoginUserData } from '../../../Helpers/supportedFunctions/supportedGlobalFunctions';

const SharedLeadDetailsComponent = (props) => {
    const userId = sessionStorage.getItem("id");
    let deptId = sessionStorage.getItem('DeptId');
    let role = sessionStorage.getItem('role');
    const [RFPError, setRFPErrror] = useState(false);
    const [dropdownUser, setdropdownUser] = useState([]);
    const [apiError, setApiError] = useState(null);
    const [detailFlag, setDetailFlag] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);
    const [exhibitorData, setExhibitordata] = useState([]);
    const [exhibitorDetail, setExhibitorDetail] = useState([]);
    const [historyDetail, setHistoryDetail] = useState([]);
    const [venue, setVenue] = useState([]);
    const [isConvincingDetail, setIsConvincingDetail] = useState(false);
    const [commentDetail, setCommentDetail] = useState([]);
    const [selectedAssignees, setSelectedAssignees] = useState([]);
    const [backgroundDetail, setBackgroundDetail] = useState([]);
    const [showDetail, setShowDetail] = useState([]);
    const [showData, setShowdata] = useState([]);
    const [sharedLeadData, setSharedLeaddata] = useState([]);
    const [leadName, setLeadName] = useState("");
    const [inputField, setInputField] = useState({
        id: 0,
        showid: '',
        exhibitorid: '',
        history: "",
        background: "",
        venue: "",
        isConvincing: '',
        comments: "",
        createdby: userId,
        updatedby: userId,


    }); // State to store the selected show ID

    useEffect(() => {
        getUserData("Users/GetAll");
        getShowData("Shows/GetAllShows");
        var d = props.name;
        if (d === "details") {
            setDetailFlag(true);
        }
        getSharedLeadDetails("Lead/GetById?id=" + props.id);
        updatenotification("Questionare/readnotification?leadId=" + props.id + "&userid=" + userId)
    }, []);

    const updatenotification = (method) => {
        Service.getdata(method).then((res) => {

        });
    }

    const getShowData = (method) => {
        Service.getdata(method).then((res) => {
            setShowdata(res.data);


        });
    }

    const getUserData = (method) => {
        Service.getdata(method).then((res) => {
            var data = res.data;
            var intdeptId = parseInt(deptId)
            var list = [];

            if (userId) {
                data.forEach((d, index) => {
                    var role = d.lstRole;

                    var checkdepartment = role.find(x => x.depId === 2 || x.deptId === 4)
                    if (checkdepartment !== undefined) {
                        d.rolename = d.lstRole[0].name;
                        list.push(d);
                    }
                    setdropdownUser(list);
                });

            }
            else {

                data.forEach((d, index) => {
                    var role = d.lstRole;
                    var checkdepartment = role.find(x => x.depId === intdeptId)
                    if (checkdepartment !== undefined) {
                        d.rolename = d.lstRole[0].name;
                        list.push(d);
                    }
                });
                setdropdownUser(list);
            }
        });
    }



    const getSharedLeadDetails = (method) => {
        Service.getdatabyId(method, '').then((res) => {
            setSharedLeaddata(res.data);
            setInputField(res.data)
            console.log(res.data);
            setShowDetail(res.data.showid);
            setVenue(res.data.venue);
            setLeadName(res.data.leadid)
            setExhibitorDetail(res.data.exhibitorid);
            setSelectedAssignees(res.data.salesHeadId);
            setIsConvincingDetail(res.data.isConvincing);
            setHistoryDetail(res.data.history);
            setCommentDetail(res.data.comments);
            setBackgroundDetail(res.data.background);

        });
    }

    useEffect(() => {
        if (showDetail) {
            getExhibitorData(`Shows/GetAllExhibitorbyId?id=${showDetail}`);
        }
    }, [showDetail]);


    const getExhibitorData = (method) => {
        Service.getdata(method).then((res) => {
            setExhibitordata(res.data);
        });
    }

    useEffect(() => {
        if (apiResponse || apiError || RFPError) {
            const timeoutId = setTimeout(removeSuccessMessage, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [apiResponse, apiError, RFPError]);

    const removeSuccessMessage = () => {
        setApiResponse(null);
        setApiError(null);
        setRFPErrror(null)
    };

    const handleShowNameChange = (evt) => {
        setShowDetail(evt.target.value);
    }

    const handleExhibitorChange = (evt) => {
        setExhibitorDetail(evt.target.value);
    }

    const handleHistoryChange = (evt) => {
        setHistoryDetail(evt.target.value);
    }

    const handleVenueChange = (evt) => {
        setVenue(evt.target.value);
    }

    const inputsHandler = (e) => {
        //  setInputField( {[e.target.name]: e.target.value} )
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }


    const handleIsConvincingChange = () => {
        if (isConvincingDetail === true) {
            setIsConvincingDetail(false);
        }
        else {
            setIsConvincingDetail(true)
        }
    }

    const handleBackgroundChange = (evt) => {
        setBackgroundDetail(evt.target.value);
    }

    const handleCommentChange = (evt) => {
        setCommentDetail(evt.target.value);
    }

    const save = async (evt) => {
        if (evt && evt.preventDefault) {
            evt.preventDefault();
        }

        const formData = new FormData(evt.target);

        const data = {}
        for (const [key, value] of formData) {
            data[key] = value;
        }

        const { exhibitorid, isConvincing } = data;
        debugger
        if (isConvincing === 'on') {
            var isConvincingData = true;
        }
        else {
            isConvincingData = false
        }

        // const isConvincingValue = data['isConvincing'] === 'true';
        const task = {
            ...inputField,
            ...data,
            exhibitorid: exhibitorDetail,
            showid: showDetail,
            id: props.id,
            isConvincing: isConvincingData,
            leadid: leadName,
            salesHeadId: selectedAssignees,
        };
        console.log(task);
        debugger
        if (task) {
            Service.createorupdate("Lead/CreateOrUpdate", task)
                .then(res => {
                    setApiResponse(res.data);
                    window.location.href = "/QualifiedLeadList"
                })
                .catch(error => {
                    // Handle API error, if necessary
                    console.error("API Error:", error);
                    setApiError("Error occurred while saving the meeting. Please try again later.");
                });
        }
    }



    const backbutton = () => {
        props.navigate("/SharedLeadList");
    }
    return (
        <div>
            <div className="container-fluid">
                <br />
                <div className="card shadow border-success mb-2">
                    <div className="card-header rounded py-3">
                        <div className="row">
                            <div className="col-sm-4"><h3><b className='text-dark'>Share Lead Details</b></h3></div>
                            <div className="col-sm-6"></div>
                            <div className="col-sm-2 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link>
                            </div>

                        </div>
                    </div>
                </div>
                <form onSubmit={save}>
                    <div className="card shadow" >
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-sucess">Lead Details</h6>
                            {apiResponse && (
                                <div className="alert alert-success" role="alert">
                                    Lead has been Shared successfully!
                                </div>
                            )}
                            {apiError && (
                                <div className="alert alert-danger" role="alert">
                                    {apiError}
                                </div>
                            )}
                        </div>

                        <div class="card shadow">
                            <div className="card-body">
                                <div className="row text-dark">
                                    <div className="col-sm-5 ml-5">
                                        <div className="form-group row">
                                            <div className='col-sm-3 required-label'><b>Show List</b></div>
                                            <div className='col-sm-9'>

                                                {inputField.showid !== null ? <select className="form-control form-control-fs  form-control-user" disabled={detailFlag} name="showid" onChange={handleShowNameChange} value={showDetail} required>
                                                    <option>[NONE]</option>
                                                    {
                                                        showData.map((e, key) => {
                                                            return <option key={e.showChapterId} value={e.showChapterId}>{e.chapterName}</option>;
                                                        })}
                                                </select> :
                                                    <input type='text' className="form-control form-control-fs  form-control-user" readOnly={detailFlag} name="showName" onChange={handleShowNameChange} value={inputField.showName} required />}

                                            </div>
                                        </div>

                                        {detailFlag === true && <div class="form-group row">
                                            <label for="objective" class="col-sm-3 control-label"><b>Show Start date</b></label>
                                            <div class="col-sm-9">
                                                <input type='date'
                                                    placeholder='enter Heading' // You can adjust the number of visible columns
                                                    class="form-control"
                                                    autofocus
                                                    readOnly={detailFlag}
                                                    value={formatDate(inputField.startDate)}
                                                />
                                            </div>
                                        </div>}
                                        <h5>POC</h5>
                                        {(inputField.createdby === parseInt(getLoginUserData().userId) || inputField.salesHeadId === parseInt(getLoginUserData().userId) || getLoginUserData().userId === "1") && <>
                                            <div class="form-group row">
                                                <label for="objective" class="col-sm-3 control-label"><b>Contact Name </b></label>
                                                <div class="col-sm-9">
                                                    <input type='text'

                                                        name='history'
                                                        placeholder='enter Heading' // You can adjust the number of visible columns
                                                        class="form-control"
                                                        autofocus
                                                        readOnly={detailFlag}
                                                        value={inputField.history}
                                                        onChange={inputsHandler}
                                                    />
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="objective" class="col-sm-3 control-label"><b>Company Name </b></label>
                                                <div class="col-sm-9">
                                                    <input type='text'
                                                        name='companyName'
                                                        placeholder='enter venue' // You can adjust the number of visible columns
                                                        class="form-control"
                                                        autofocus
                                                        readOnly={detailFlag}
                                                        value={inputField.companyName}
                                                        onChange={inputsHandler}
                                                    />
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label for="objective" class="col-sm-3 control-label"><b>Email</b> </label>
                                                <div class="col-sm-9">
                                                    <input type='text'
                                                        name='email'
                                                        placeholder='enter venue' // You can adjust the number of visible columns
                                                        class="form-control"
                                                        autofocus
                                                        readOnly={detailFlag}
                                                        value={inputField.email}
                                                        onChange={inputsHandler}
                                                    />
                                                </div>
                                            </div>
                                        </>}
                                        <div class="form-group row">
                                            <label for="objective" class="col-sm-3 control-label"><b>Brief Synopsis </b></label>
                                            <div class="col-sm-9">
                                                <textarea
                                                    rows={2} // You can adjust the number of visible rows
                                                    cols={105}
                                                    name='background'
                                                    placeholder='enter Brief Synopsis' // You can adjust the number of visible columns
                                                    class="form-control"
                                                    autofocus
                                                    readOnly={detailFlag}
                                                    value={inputField.background}
                                                    onChange={inputsHandler}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group row form-group row-h row">
                                            <div className="col-sm-3 required-label"><b>Account Manager</b></div>
                                            <div className='col-sm-9'>
                                                <select className="form-control form-control-fs  form-control-user" disabled={true} readOnly={detailFlag} onChange={handleExhibitorChange} value={selectedAssignees} name="exhibitorid" required>
                                                    <option>[NONE]</option>
                                                    {
                                                        dropdownUser.map((e, key) => {
                                                            return <option key={e.id} value={e.id}>{e.firstname} {e.lastname}</option>;
                                                        })}
                                                </select>
                                            </div>
                                        </div>


                                        {apiResponse && (
                                            <div className="alert alert-success" role="alert">
                                                Lead has been updated and shared successfully!
                                            </div>
                                        )}
                                        {apiError && (
                                            <div className="alert alert-danger" role="alert">
                                                {apiError}
                                            </div>
                                        )}
                                        {(props.name === "edit" || deptId === "2" || deptId === "4" || role === "Admin" || role === "Executive - Software Developer(SOP Tool)") && <button type="submit" class="btn btn-primary ml-2 mb-3">Save</button>}
                                        {/* {(deptId === "2" || deptId === "4" || role === "Admin") && <button type="submit" class="btn btn-primary ml-2 mb-3">Save</button>} */}

                                    </div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-5">
                                        <div className="form-group row">
                                            <div className='col-sm-4 required-label'><b>Exhibitor Name</b></div>
                                            <div className='col-sm-8'>
                                                {inputField.exhibitorid !== null ? <select className="form-control form-control-fs  form-control-user" disabled={detailFlag} onChange={handleExhibitorChange} value={exhibitorDetail} name="exhibitorid" required>
                                                    <option>[NONE]</option>
                                                    {
                                                        exhibitorData.map((e, key) => {
                                                            return <option key={e.exId} value={e.exId}>{e.exhibitorName}</option>;
                                                        })}
                                                </select> :
                                                    <input type='text' className="form-control form-control-fs  form-control-user" readOnly={detailFlag} name="exhibitorName" onChange={handleShowNameChange} value={inputField.exhibitorName} required />}

                                            </div>
                                        </div>

                                        {detailFlag === true && <div class="form-group row">
                                            <label for="objective" class="col-sm-4 control-label"><b>Show End date</b></label>
                                            <div class="col-sm-8">
                                                <input type='date'
                                                    placeholder='enter Heading' // You can adjust the number of visible columns
                                                    class="form-control"
                                                    autofocus
                                                    readOnly={detailFlag}
                                                    value={formatDate(inputField.endDate)}

                                                />
                                            </div>
                                        </div>}

                                        <div class="form-group row">
                                            <label for="objective" class="col-sm-4 control-label"><b>Venue/Place</b> </label>
                                            <div class="col-sm-8">
                                                <textarea
                                                    rows={1} // You can adjust the number of visible rows
                                                    cols={105}
                                                    name='venue'
                                                    placeholder='enter venue' // You can adjust the number of visible columns
                                                    class="form-control"
                                                    autofocus
                                                    readOnly={detailFlag}
                                                    value={inputField.venue}
                                                    onChange={inputsHandler}
                                                />
                                            </div>
                                        </div>
                                        {(inputField.createdby === parseInt(getLoginUserData().userId) || inputField.salesHeadId === parseInt(getLoginUserData().userId) || getLoginUserData().userId === "1") &&
                                            <>
                                                <div class="form-group row">
                                                    <label for="objective" class="col-sm-3 control-label"><b>Phone</b> </label>
                                                    <div class="col-sm-9">
                                                        <input type='text'
                                                            name='phone'
                                                            placeholder='enter Phone' // You can adjust the number of visible columns
                                                            class="form-control"
                                                            autofocus
                                                            readOnly={detailFlag}
                                                            value={inputField.phone}
                                                            onChange={inputsHandler}
                                                        />
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="objective" class="col-sm-3 control-label"><b>Position</b> </label>
                                                    <div class="col-sm-9">
                                                        <input type='text'
                                                            name='position'
                                                            placeholder='enter venue' // You can adjust the number of visible columns
                                                            class="form-control"
                                                            autofocus
                                                            readOnly={detailFlag}
                                                            value={inputField.position}
                                                            onChange={inputsHandler}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        }


                                        <div class="form-group row">
                                            <label for="objective" class="col-sm-3  control-label"><b>Additional Inputs</b></label>
                                            <div class="col-sm-9">
                                                <textarea
                                                    rows={2} // You can adjust the number of visible rows
                                                    cols={105}
                                                    name='comments'
                                                    placeholder='enter Additional Inputs' // You can adjust the number of visible columns
                                                    class="form-control"
                                                    autofocus
                                                    readOnly={detailFlag}
                                                    value={inputField.comments}
                                                    onChange={inputsHandler}
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group row ">
                                            <div class="col-sm-5">
                                                <div class="form-check">
                                                    {/* <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        name='isConvincing'
                                                        // readOnly={detailFlag}
                                                        id="flexCheckChecked"
                                                        checked={isConvincingDetail}
                                                        // value={isConvincingDetail}
                                                        onChange={handleIsConvincingChange}
                                                    /> */}
                                                    {detailFlag === true &&
                                                        <>
                                                            <input class="form-check-input" name="isConvincing" type="checkbox" checked={isConvincingDetail} onClick={handleIsConvincingChange} id="flexCheckDefault" />
                                                            <label class="form-check-label" for="flexCheckChecked">
                                                                <b>Is Convincing</b>
                                                            </label>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default SharedLeadDetailsComponent