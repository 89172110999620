import { useState, useEffect } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Link } from 'react-router-dom';
import ApiMethod from '../../../Helpers/ApiMethod/Method'
import Service from "../../../Services/Admin/Service";

function DetailTaskComponent(props) {
  const [detailFlag, setDetailFlag] = useState(false);
  const [data, setData] = useState('');
    const [questionaredata, setquestionaredata] = useState([]);
    const [currentuser, setcurrentuser] = useState('');
    const [messagebox, setmessagebox] = useState('');
    const [presalesvalue, setpresalesvalue] = useState('');
    let userid = sessionStorage.getItem('id');
    const [inputField, setInputField] = useState({
        id: 0,
        taskid: props.id,
        description: '',
        senderid: userid,
        receiverid: 0,
        createdby: userid,
    })

    const [inputFieldPre, setInputFieldPre] = useState({
        id: 0,
        typeofdata: '',
        footfall: Int32Array,
        exihibitorNumber: Int32Array,
        frequency: '',
        tradeshowhistory: '',
        sociamediapresence: '',
        marketing: '',
        companysize: Int32Array,
        turnover: '',
        influencers: '',
        interest: '',
        followers: '',
        previousdatadetails: '',
        taskid: props.id,
        createdby: userid,
    })
    // const apis = ApiMethod.find(o => o.name === 'Category');
    let id = sessionStorage.getItem('name');

    useEffect(() => {
        //   
        setcurrentuser(id)
        setmessagebox(false)
        getpresalesData("PreSales/GetOnebyTaskid?taskid=" + props.id)
        updatenotification("Questionare/readnotification?taskid=" + props.id + "&userid=" + userid)
        getData("Task/GetById?id=" + props.id);
        getQuestionareData("Questionare/GetAllbyTaskid?taskid=" + props.id);
    }, [])

    const getData = (method) => {
        Service.getdata(method).then((res) => {
            setData(res.data);
            console.log("58",res.data);
        });
    }

    const getpresalesData = (method) => {
        Service.getdata(method).then((res) => {
            setInputFieldPre(res.data);
            console.log("66",res.data);
        });
    }

    const updatenotification = (method) => {
        Service.getdata(method).then((res) => {            
            
        });
    }

    const getQuestionareData = (method) => {
        Service.getdata(method).then((res) => {
            setquestionaredata(res.data);
            console.log(res.data);

        });
    }

    const inputsHandler = (e) => {
        //  setInputField( {[e.target.name]: e.target.value} )
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const inputsHandlerPre = (e) => {
        //  setInputField( {[e.target.name]: e.target.value} )
        const { name, value } = e.target;
        setInputFieldPre((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const submitButtonPre = (e) => {
        e.preventDefault();
        let task = inputFieldPre;



        Service.createorupdate("PreSales/CreateOrUpdate", task).then(res => {
            alert("Data Submit Successfully");
            getData("Task/GetById?id=" + props.id);
            getQuestionareData("Questionare/GetAllbyTaskid?taskid=" + props.id);
        });
    }

    const submitButton = (e) => {
        e.preventDefault();
        let task = inputField;



        Service.createorupdate("Questionare/CreateOrUpdate", task).then(res => {
            alert("Message Submit Successfully");
            getData("Task/GetById?id=" + props.id);
            getQuestionareData("Questionare/GetAllbyTaskid?taskid=" + props.id);
        });
    }

    const accepttask = () => {
        Service.createorupdate("Task/AcceptTask?id=" + props.id, "").then(res => {
            alert("Task Accepted Successfully");
            getData("Task/GetById?id=" + props.id);
            getQuestionareData("Questionare/GetAllbyTaskid?taskid=" + props.id);
        });
    }

    const datechange = () => {
        Service.createorupdate("Task/DateChange?id=" + props.id, "").then(res => {
            alert("Date Change Request submit Successfully");
            getData("Task/GetById?id=" + props.id);
            getQuestionareData("Questionare/GetAllbyTaskid?taskid=" + props.id);
        });
    }

    const completetask = () => {
        Service.createorupdate("Task/CompletedTask?id=" + props.id, "").then(res => {
            alert("Task complete submit successfully");
            getData("Task/GetById?id=" + props.id);
            getQuestionareData("Questionare/GetAllbyTaskid?taskid=" + props.id);
            inputField.description = ''
        });
    }

    const validatetask = () => {
        Service.createorupdate("Task/ValidateTask?id=" + props.id + "&userid="+userid, "").then(res => {
            alert("Task Validated successfully");
            getData("Task/GetById?id=" + props.id);
            getQuestionareData("Questionare/GetAllbyTaskid?taskid=" + props.id);
          //  inputField.description = ''
        });
    }


    const linkformatter = (data, row) => {
        return <>
            <Link className="btn btn-warning btn-sm" to={`/EditCategory/${data}`}><i className="fas fa-edit"></i></Link>
        </>
    }

    const showMessagebox = () => {
        setmessagebox(true)
    }

    const closeMessagebox = () => {
        setmessagebox(false)
    }

    return (
        <div>
            <div className="container-fluid">

                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Details Tasks</h6>
                        {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
                    </div>

                    <div className="card-body">
                        {
                            data !== null ?
                                <div className="row">
                                    <div className="col-sm-2"><b>Project Name :</b></div>
                                    <div className="col-sm-10">{data.projectname} &nbsp; &nbsp; &nbsp;
                                    {
                                        data.projectname !== null ? 
                                        <a href={`/DetailProject/${data.projectid}`}>  Click here for details</a>
                                         : 
                                        ''
                                    }
                                        
                                     </div>
                                    <div className="col-sm-2"><b>Topic :</b></div>
                                    <div className="col-sm-4">{data.topic}</div>
                                    <div className="col-sm-2"><b>Task Type :</b></div>
                                    <div className="col-sm-4">{data.newtaskname}</div>
                                    <hr />
                                    <div className="col-sm-2"><b>Short Description :</b></div>
                                    <div className="col-sm-10">{data.shortdescription}</div>
                                    <hr />
                                    <div className="col-sm-2"><b>Long Description :</b></div>
                                    <div className="col-sm-10">{data.longdescription}</div>
                                    <hr />
                                    <div className="col-sm-2"><b>Deadline date :</b></div>
                                    <div className="col-sm-10">{data.deadlinedate}</div>
                                    <hr />
                                    <div className="col-sm-2"><b>Start Date :</b></div>
                                    <div className="col-sm-4">{data.startdate}</div>
                                    <div className="col-sm-2"><b>Completion Date :</b></div>
                                    <div className="col-sm-4">{data.completiondate}</div>
                                    <hr />
                                    <div className="col-sm-2"><b>Assignee Name :</b></div>
                                    <div className="col-sm-4">{data.assigneeName}</div>
                                    <div className="col-sm-2"><b>Assigner Name :</b></div>
                                    <div className="col-sm-4">{data.assignerName}</div>
                                    <hr />
                                    <div className="col-sm-2"><b>Complete OnTime :</b></div>
                                    <div className="col-sm-4"> <input type="checkbox" name="includeInTopMenu"
                                        className='form-control-user' checked={data.isCompleonTime} /></div>
                                    <div className="col-sm-2"><b>Task Finish :</b></div>
                                    <div className="col-sm-4"> <input type="checkbox" name="includeInTopMenu"
                                        className='form-control-user' checked={data.isCompleted} /></div>
                                    <hr />
                                    <div className="col-sm-4"><b>Is Request change Deadline Date :</b></div>
                                    <div className="col-sm-8"><input type="checkbox" name="includeInTopMenu"
                                        className='form-control-user' checked={data.isRequestDeadlineincrease} /></div>
                                    <hr />
                                    <div className="col-sm-2"><b>Validator Name :</b></div>
                                    <div className="col-sm-10">{data.validatorUsername}</div>

                                    <hr />
                                    {

                                        data.assigneeName === id ?
                                            data.startdate === null ?
                                                <>
                                                    <button type="button" class="btn btn-outline-primary col-md-2 ml-4" onClick={(e) => { accepttask(e) }}>Accept</button>
                                                </>
                                                :
                                                null
                                            :
                                            ''
                                    }
                                    <div className="col-sm-1"></div>
                                    {
                                        data.assigneeName === id ?
                                            data.isCompleted === true ? 
                                            ''
                                            :
                                            <button type="button" class="btn btn-outline-secondary col-md-2" onClick={(e) => { datechange(e) }}>Date Change</button>
                                            : ''
                                    }

                                    <div className="col-sm-1"></div>
                                    { messagebox === false ? 
                                        <button type="button" class="btn btn-outline-danger col-md-2" onClick={(e) => { showMessagebox(e) }}>Questions / Reply</button>
                                        :
                                        ''
                                    }

                                    <div className="col-sm-1"></div>
                                    {
                                        data.assigneeName === id ?
                                             data.isCompleted === true ? 
                                             ''
                                              :
                                              <button type="button" class="btn btn-outline-success col-md-2" onClick={(e) => { completetask(e) }}> Completed</button>
                                            
                                            :
                                             data.validatorUsername === " " ?
                                             <button type="button" class="btn btn-outline-success col-md-2" onClick={(e) => { validatetask(e) }}> Validated</button>
                                              : 
                                              ''
                                            
                                    }


                                </div>
                                :
                                ''
                        }


                    </div>
                </div>
                {data.tasktypeid === 2 ?
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Pre Identification Details</h6>
                        </div>

                        <div className="card-body">
                            <div className="form-group row">
                                <div className='col-sm-2'>Type Of Data</div>
                                <div className='col-sm-10'><input type="text" className="form-control form-control-user" id="typeofdata"
                                    onChange={inputsHandlerPre} value={inputFieldPre.typeofdata} name="typeofdata" placeholder="Enter Task Topic" /></div>

                            </div>
                            <div className="form-group row">
                                <div className='col-sm-2'>Footfall</div>
                                <div className='col-sm-4'><input type="text" className="form-control form-control-user" id="footfall"
                                    onChange={inputsHandlerPre} value={inputFieldPre.footfall} name="footfall" placeholder="Enter footfall" /></div>
                                    <div className='col-sm-2'>Exihibitor Number</div>
                                <div className='col-sm-4'><input type="text" className="form-control form-control-user" id="exihibitorNumber"
                                    onChange={inputsHandlerPre} value={inputFieldPre.exihibitorNumber} name="exihibitorNumber" placeholder="Enter Exihibitor Number" /></div>
                            </div>
                            <div className="form-group row">
                                <div className='col-sm-2'>frequency</div>
                                <div className='col-sm-4'><input type="text" className="form-control form-control-user" id="Frequency"
                                    onChange={inputsHandlerPre} value={inputFieldPre.frequency} name="frequency" placeholder="Enter frequency" /></div>
                                    <div className='col-sm-2'>Tradeshow history</div>
                                <div className='col-sm-4'><textarea type="text" className="form-control form-control-user" id="tradeshowhistory"
                                    onChange={inputsHandlerPre} value={inputFieldPre.tradeshowhistory} name="tradeshowhistory" placeholder="Enter tradeshowhistory" /></div>
                            </div>
                            <div className="form-group row">
                                <div className='col-sm-2'>Social Media presence</div>
                                <div className='col-sm-4'><input type="text" className="form-control form-control-user" id="sociamediapresence"
                                    onChange={inputsHandlerPre} value={inputFieldPre.sociamediapresence} name="sociamediapresence" placeholder="Enter Social media presence" /></div>
                                    <div className='col-sm-2'>Marketing</div>
                                <div className='col-sm-4'><input type="text" className="form-control form-control-user" id="marketing"
                                    onChange={inputsHandlerPre} value={inputFieldPre.marketing} name="marketing" placeholder="Enter marketing" /></div>
                            </div>
                            <div className="form-group row">
                                <div className='col-sm-2'>Company Size</div>
                                <div className='col-sm-4'><input type="text" className="form-control form-control-user" id="companysize"
                                    onChange={inputsHandlerPre} value={inputFieldPre.companysize} name="companysize" placeholder="Enter Company size" /></div>
                                    <div className='col-sm-2'>Turnover</div>
                                <div className='col-sm-4'><input type="text" className="form-control form-control-user" id="turnover"
                                    onChange={inputsHandlerPre} value={inputFieldPre.turnover} name="turnover" placeholder="Enter turnover" /></div>
                            </div>
                            <div className="form-group row">
                                <div className='col-sm-2'>Influencers</div>
                                <div className='col-sm-4'><input type="text" className="form-control form-control-user" id="influencers"
                                    onChange={inputsHandlerPre} value={inputFieldPre.influencers} name="influencers" placeholder="Enter Influencers" /></div>
                                    <div className='col-sm-2'>Interest</div>
                                <div className='col-sm-4'><input type="text" className="form-control form-control-user" id="interest"
                                    onChange={inputsHandlerPre} value={inputFieldPre.interest} name="interest" placeholder="Enter interest" /></div>
                            </div>
                            <div className="form-group row">
                                <div className='col-sm-2'>followers</div>
                                <div className='col-sm-4'><input type="text" className="form-control form-control-user" id="influencers"
                                    onChange={inputsHandlerPre} value={inputFieldPre.followers} name="followers" placeholder="Enter followers" /></div>
                                    <div className='col-sm-2'>Previous data details</div>
                                <div className='col-sm-4'><textarea type="text" className="form-control form-control-user" id="previousdatadetails"
                                    onChange={inputsHandlerPre} value={inputFieldPre.previousdatadetails} name="previousdatadetails" placeholder="Enter previous data details" /></div>
                            </div>
                            {   data.isCompleted === true ? 
                                                ''
                                                :
                                <button type="button" class="btn btn-outline-success col-md-3" onClick={submitButtonPre}> Submit</button>
                            }
                        </div>
                    </div>
                    :
                    null
                }

                {messagebox === true ?
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Message Box</h6>
                        </div>

                        <div className="card-body">
                            <div className="col-md-12">
                                <textarea type="text" className="form-control form-control-user" id="description"
                                    onChange={inputsHandler} value={inputField.description} name="description" placeholder="Description" />
                            </div><br></br>
                            <div className="row">
                                <div className="col-md-2">

                                </div>
                                <button type="button" class="btn btn-outline-success col-md-3" onClick={submitButton}> Submit</button>
                                <div className="col-md-2">

                                </div>
                                <button type="button" class="btn btn-outline-danger col-md-3" onClick={(e) => { closeMessagebox(e) }}> Close</button>

                                <div className="col-md-2">

                                </div>
                            </div>
                            <></>
                        </div>
                    </div>
                    :
                    null
                }

                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Question & Answers</h6>
                        {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
                    </div>

                    <div className="card-body">
                        {
                            questionaredata.map((i, index) => {

                                // return  <div class="alert alert-primary" key={index} role="alert">
                                //         {i.description}
                                //     </div>
                                return <>
                                    {
                                        i.senderName !== id ?
                                            <div class="alert alert-primary" key={index} role="alert">
                                                <div className="row">
                                                    <div className="col-md-10">
                                                        {
                                                            i.description.split('\n').map((line, i) => (
                                                                <span key={i}>
                                                                    {line}
                                                                    <br />
                                                                </span>
                                                            ))
                                                        }

                                                    </div>
                                                    <div className="col-md-2">
                                                        <small>{i.senderName}</small> <br></br>
                                                        <small>{i.createddate}</small>
                                                    </div>
                                                </div>

                                            </div>
                                            :
                                            <div class="alert alert-secondary" key={index} role="alert">
                                                <div className="row">
                                                    <div className="col-md-10">
                                                        {
                                                            i.description.split('\n').map((line, i) => (
                                                                <span key={i}>
                                                                    {line}
                                                                    <br />
                                                                </span>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className="col-md-2">
                                                        <small>  {i.senderName}</small> <br></br>
                                                        <small>{i.createddate}</small>
                                                    </div>
                                                </div>
                                            </div>
                                    }

                                </>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DetailTaskComponent