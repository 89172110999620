import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Service from '../../../Services/Admin/Service';
import { filterWithUniqueIds } from '../../../Helpers/supportedFunctions/supportedGlobalFunctions';

const CreatePPTComponent = (props) => {

    let id = sessionStorage.getItem('id');
    const [leadError, setLeadError] = useState(false);
    const [detailFlag, setDetailFlag] = useState(false);
    const [rfpLead, setRFPLead] = useState([]);
    const [apiError, setApiError] = useState(null);
    const [apiResponse, setApiResponse] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState('');
    const [inputField, setInputField] = useState(
        {
            
            fileid: 0,
            files: "",
            leadid: "",
            createdby: id,
            updatedby: id,
        })


    useEffect(() => {
        getRFPAll("RFP/GetAll?id=" + 1);
        var d = props.name;
        debugger
        if (d === "details") {
            setDetailFlag(true);
        }

        if (props.id !== 0 && props.id !== undefined) {
            getPPTbyId("Presentation/GetById?id=" + props.id, '');
        }
    }, [])

    const getRFPAll = (method) => {
        Service.getdata(method).then((res) => {
            var uniqueRFP = filterWithUniqueIds(res.data)
            setRFPLead(uniqueRFP);

        });
    }

    const getPPTbyId = (method) => {
     
        Service.getdata(method).then((res) => {
            setInputField(res.data);
         debugger
        })
    }

    const inputsHandler = (e) => {
        //  setInputField( {[e.target.name]: e.target.value} )
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }




    useEffect(() => {
        if (apiResponse || apiError) {
            const timeoutId = setTimeout(removeSuccessMessage, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [apiResponse, apiError]);

    const removeSuccessMessage = () => {
        setApiResponse(null);
        setApiError(null);
    };

    const save = async (evt) => {
        if (evt && evt.preventDefault) {
            evt.preventDefault();
        }

        const formData = new FormData(evt.target);

        const data = {}
        for (const [key, value] of formData) {
            data[key] = value;
        }

        function validateData(data) {
            const { files, leadid, path } = data;

            // if (!files || files === "[NONE]") {
            //     alert("Please fill in all required fields.");
            //     return false;
            // }

            if (!leadid || leadid === "[NONE]") {
                setLeadError(true);
                return false;
            } else {
                setLeadError(false);
            }

            return true;
        }

        if (!validateData(data)) {
            return;
        }
        debugger;

        const formData1 = new FormData();
        formData1.append("files", selectedFiles);

        const task = {
            ...inputField,
            ...data,
        };
        console.log(task);
        debugger
        if (task) {
            Service.createorupdateFile("Presentation/CreateOrUpdate?Leadid=" + data.leadid + "&createdby=" + id + "&path=" + data.path, formData1)
                .then(res => {
                    setApiResponse(res.data);
                    // Set the API response in state
                    setTimeout(() => {
                        window.location.href = "/PPTList";
                    }, 500)
                })
                .catch(error => {
                    // Handle error if API call fails
                    console.error("API Error:", error);
                    setApiError("Error occurred while saving the meeting. Please try again later.");
                });
        }
    }

    const externalLink = (data) => {
        window.open(data, '_blank')
    }


    const backbutton = () => {
        props.navigate(-1);
    }
    return (
        <div>
            <div className="container-fluid">

                <br />

                <div className="card shadow border-success mb-2">
                    <div className="card-header rounded py-3">
                        <div className="row">
                            <div className="col-sm-3"><h3><b className='text-dark'>Create PPT</b></h3></div>
                            <div className="col-sm-6"></div>
                            <div className="col-sm-3 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link> */}
                            </div>

                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="card cardUser shadow mb-4" >
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-success">Add PPT</h6>
                            {apiResponse && (
                                <div className="alert alert-success" role="alert">
                                    PPT has been uploaded successfully!
                                </div>
                            )}
                            {apiError && (
                                <div className="alert alert-danger" role="alert">
                                    {apiError}
                                </div>
                            )}
                        </div>

                        <div class="container">
                            <form onSubmit={save} className="form-horizontal text-dark mt-2">
                                <div className="form-group ">
                                    <div className='col-sm-9 required-label'><b >Lead</b></div>
                                    <div className='col-sm-9'>
                                        {leadError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a Lead</div>}
                                        <select className="form-control  form-control-user" onChange={inputsHandler} value={inputField.leadid} readOnly={detailFlag} name="leadid" required>
                                            <option>[NONE]</option>
                                            {
                                                rfpLead.map((e, key) => {
                                                    //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;
                                                    return <option key={e.id} value={e.leadid}>{e.leadid}</option>;
                                                })}
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="field" class="col-sm-3 required-label  control-label"><b>Attach PPT Drive Link</b></label>
                                    <div class="col-sm-9">
                                        <textarea type="text" id="topic" name='path' onChange={inputsHandler} value={inputField.path} readOnly={detailFlag} rows="4" cols="80" required />
                                    </div>
                                    <span className='btn btn-sm btn-info ml-3' onClick={() => externalLink(inputField.path)} >Open Drive Link</span>
                                </div>
                                <button type="submit" class="btn btn-primary ml-3 mb-3">Save</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default CreatePPTComponent