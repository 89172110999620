import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import BoqDetailsComponent from '../../../Components/Main/BoqComponent/BoqDetailsComponent';

function BoqDetails(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <BoqDetailsComponent {...props} navigate={navigate} id={params.id}  />
        </div>
    );  
  }
export default BoqDetails  