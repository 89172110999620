import { useState, useEffect } from "react";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Link } from 'react-router-dom';
import ApiMethod from '../../../Helpers/ApiMethod/Method'
import Service from "../../../Services/Admin/Service";


function AgreementListComponent(props) {
    const [data, setData] = useState([]);

    useEffect(() => {
        getData("Agreement/GetAll");
    }, [])

    const getData = (method) => {
        Service.getdata(method).then((res) => {
            setData(res.data);
            console.log(res.data);
            debugger
        });
    }

    const formatDateTime = (cell, row) => {
        // Format the date and time here, assuming 'time' is a string in ISO 8601 format
        const formattedDate = new Date(cell).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            // hour: '2-digit',
            // minute: '2-digit',

        });
        return formattedDate;
    }

    const linkformatter = (data, row) => {
        return <>  <Link className="btn btn-warning m-1 btn-sm" to={`/CreateAgreement/${data}`}>Edit</Link>
            &nbsp; <Link className="btn btn-warning m-1 btn-sm" to={`/AgreementDetails/${data}`}>Details</Link>
            &nbsp; <Link className="btn btn-warning m-1 btn-sm" to={`/AgreementIsApproved/${data}`}>Approved</Link>
            &nbsp; <Link className="btn btn-warning m-1 btn-sm" to={`/AgreementIsShared/${data}`}>Shared</Link>
        </>
    }

    const backbutton = () => {
        props.navigate(-1);
    }

    const column = [
        { dataField: 'projectName', text: 'Project Name', sort: true },
        { dataField: 'type', text: 'type' },
        { dataField: 'isAproved', text: 'isApproved', sort: true },
        { dataField: 'isShared', text: 'isShared', sort: true },
        { dataField: 'createdDate', text: 'createdDate', formatter: formatDateTime },
        { dataField: 'id', text: 'Actions', formatter: linkformatter }
    ]



    return (<div>
        <div className="container-fluid">

            <div className="card shadow border-success mb-2">
                <div className="card-header rounded py-3">
                    <div className="row">
                        <div className="col-sm-4"><h3><b className="text-dark">Agreement List</b></h3></div>
                        <div className="col-sm-6"></div>
                        <div className="col-sm-2 d-flex flex-row-reverse">
                            <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                            {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-success">List Of Agreement</h6>
                    {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
                </div>

                <div className="card-body">
                    <div className="table-responsive">
                        <BootstrapTable keyField="id"
                            data={data}
                            columns={column}
                            rowStyle={{ color: "black" }}
                            pagination={paginationFactory()}
                        // filter={filterFactory()}
                        />
                    </div>
                </div>
            </div>
        </div>

    </div>)
}
export default AgreementListComponent