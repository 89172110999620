import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Service from '../../../Services/Admin/Service';

const CreateContentComponent = (props) => {
  const [apiError, setApiError] = useState(null);
  const [leadError, setLeadError] = useState(null);
  const [leadData, setLeadData] = useState([]);
  const [apiResponse, setApiResponse] = useState(null);
  const [inputField, setInputField] = useState({

  })

  useEffect(() => {
    getLeadDataDetails("Lead/GetAll");
  }, [])

  const getLeadDataDetails = (method) => {
    Service.getdata(method, '').then((res) => {
      setLeadData(res.data);
      // setLeadData(res.data.leadId)
      // console.log(res.data);

    });
  }

  useEffect(() => {
    if (apiResponse || apiError) {
      const timeoutId = setTimeout(removeSuccessMessage, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [apiResponse, apiError]);

  const removeSuccessMessage = () => {
    setApiResponse(null);
    setApiError(null);
  };


  const save = (evt) => {

    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }

    const formData = new FormData(evt.target);

    const data = {}
    for (const [key, value] of formData) {
      data[key] = value;
    }

    const { bothObjective, visualDetails, targetAudience } = data;


    const task = {
      ...inputField,
      ...data,
    };
    debugger

    if (task) {
      Service.createorupdate("ExtractedData/CreateOrUpdate", task)
        .then(res => {
          setApiResponse(res.data); // Set the API response in state
          window.location.href = "/ExtractedDataList";
        })
        .catch(error => {
          // Handle error if API call fails
          console.error("API Error:", error);
          setApiError("Error occurred while saving the meeting. Please try again later.");
        });
    }
  }


  const handleInputStatus = (e) => {
    setLeadData(e.target.value);
  }


  const backbutton = () => {
    props.navigate(-1);
  }


  return (
    <div>
      <div className="container-fluid">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <div className="row">
              <div class="d-flex flex-start align-items-center">
                <img class="shadow-1-strong me-32"
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSK1fTUGLvONyLXW2lUoHwYCOLXWa2SUHyL-vhAKrMrag&s" alt="avatar" width="40"
                  height="40" />
              </div>
              <div className="col-sm-3"><h2>Create Content</h2></div>
              <div className="col-sm-5"></div>
              <div className="col-sm-3">
                <Link to="/AdminDashboard" className="btn btn-secondary mt-1 ml-2 btn-sm">Home</Link>
                <button className="btn btn-info mt-1 ml-1 btn-sm" onClick={() => backbutton()}>back</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="card cardUser shadow mb-4" >
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">Add</h6>
            {apiResponse && (
              <div className="alert alert-success" role="alert">
                Content has been created successfully!
              </div>
            )}
            {apiError && (
              <div className="alert alert-danger" role="alert">
                {apiError}
              </div>
            )}
          </div>

          <div class="container">
            <form onSubmit={save} class="form-horizontal mt-2">

              <div class="form-group">
                <label for="departmentId" class="col-sm-3 required-label control-label">Lead</label>
                <div class="col-sm-9">
                  {leadError && <div className="alert alert-danger mt-3" role="alert">Please select a lead.</div>}
                  <select className="form-control form-control-fs form-control-user" id='leadId' name="leadId" required>
                    <option>[NONE]</option>
                    {
                      leadData.map((e, key) => {
                        return <option key={e.id} value={e.id}>{e.leadid}</option>;
                      })}
                  </select>
                </div>
              </div>

              <div className="form-group">
                <div className='col-sm-12 required-label'>Attachment Content</div>
                <div className='col-sm-12'>
                  <input type="file" accept="" className="form-control-user" id="headline"
                    name="headline" placeholder="http://example.com" required />
                </div>
              </div>
              <button type="submit" class="btn btn-primary ml-2 mb-3">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateContentComponent