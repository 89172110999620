import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import AddProjectAssignUser from '../../../Components/Main/AssignComponent/AddProjectAssignUser';

function AddProjectAssign(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <AddProjectAssignUser {...props} navigate={navigate}  />
        </div>
    );  
  }
export default AddProjectAssign  