import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Service from '../../../Services/Admin/Service';

const ContentDetailsComponent = (props) => {

  const id = props.id;
  const [data, setData] = useState([]);

  useEffect(() => {
    getContentDataDetails("ExtractedData/GetById?id=" + id);
  }, [])

  const getContentDataDetails = (method) => {
    Service.getdatabyId(method, '').then((res) => {
      setData(res.data);
      console.log(res.data);
    });
  }

  const backbutton = () => {
    props.navigate(-1);
  }
  return (
    <div>
      <div className="container-fluid">
        <br />
        <form onSubmit="">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <div className="row">
                <div className="col-sm-3"><h3>Content Data Details</h3></div>
                <div className="col-sm-5"></div>
                <div className="col-sm-3">
                  <Link to="/AdminDashboard" className="btn btn-secondary mt-1 ml-2 btn-sm">Home</Link>
                  <button className="btn btn-info mt-1 ml-1 btn-sm" onClick={() => backbutton()}>back</button>
                </div>
              </div>
            </div>
          </div>
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-primary">Details</h6>
            </div>
            <div className="card-body" style={{ height: "auto" }}>
              <div className='row'>
                <div className='col-md-5'>
                  <div className="form-group row">
                    <div className='col-sm-5 required-label'>Objective of Booth</div>
                    <div className='col-sm-7'>
                      <b>

                      </b>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-5 required-label'>	Visual Appeal Details</div>
                    <div className='col-sm-7'>
                      <b>

                      </b>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-5 required-label'>	Target Audience</div>
                    <div className='col-sm-7'>
                      <b>

                      </b>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-5 required-label'>	Target Audience</div>
                    <div className='col-sm-7'>
                      <b>

                      </b>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-5 required-label'>Unique Selling Proposition</div>
                    <div className='col-sm-7'>
                      <b>

                      </b>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-5 required-label'>Fuctionality</div>
                    <div className='col-sm-7'>
                      <b>

                      </b>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-5 required-label'>Engagement Strategies</div>
                    <div className='col-sm-7'>
                      <b>

                      </b>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-5 required-label'>Branding & Messaging</div>
                    <div className='col-sm-7'>
                      <b>

                      </b>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-5 required-label'>Case Studies of Testimonials</div>
                    <div className='col-sm-7'>
                      <b>

                      </b>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-5 required-label'>Budget and ROI</div>
                    <div className='col-sm-7'>
                      <b>

                      </b>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-5 required-label'>TimeLine & Logistics</div>
                    <div className='col-sm-7'>
                      <b>

                      </b>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-5 required-label'>Technology & Integration</div>
                    <div className='col-sm-7'>
                      <b>

                      </b>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-5 required-label'>Sustainale Eco-friendly Practices</div>
                    <div className='col-sm-7'>
                      <b>

                      </b>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-5 required-label'>Measurement And Analysis</div>
                    <div className='col-sm-7'>
                      <b>

                      </b>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-5 required-label'>Sustainability</div>
                    <div className='col-sm-7'>
                      <b>

                      </b>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ContentDetailsComponent