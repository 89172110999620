import React from 'react'
import { Link } from 'react-router-dom';

const ClientVendorDashboardComponent = (props) => {
    const role = sessionStorage.getItem("role");



    const backbutton = () => {
        props.navigate(-1);
    }
    const roleButtons = [
        {
            role: "DGM-Projects",
            buttons: [
                { label: "Create Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateVandour" },
                { label: "Select Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSelectVendor" },
                { label: "Vendor List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/VendorList" },
                { label: "Create Vendor costing", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateVendorQuotation" },
                { label: "Vendor costing List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/VendorClientQuotationList" },
                { label: "Projectwise Selected Vendors", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SelectVendorList" },
                // { label: "Selected Vendor List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SelectVendorList" },
                { label: "Create Client costing", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateClientQuotation" },
                { label: "Client costing List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ClientListQuotation" },

            ]
        },

        {
            role: "Managing Director(MD)",
            buttons: [
                { label: "Create Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateVandour" },
                { label: "Select Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSelectVendor" },
                { label: "Vendor List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/VendorList" },
                { label: "Create Vendor costing", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateVendorQuotation" },
                { label: "Vendor costing List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/VendorClientQuotationList" },
                { label: "Projectwise Selected Vendors", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SelectVendorList" },
                // { label: "Selected Vendor List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SelectVendorList" },
                { label: "Create Client costing", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateClientQuotation" },
                { label: "Client costing List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ClientListQuotation" },

            ]
        },

        {
            role: "Admin",
            buttons: [
                { label: "Create Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateVandour" },
                { label: "Select Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSelectVendor" },
                { label: "Vendor List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/VendorList" },
                { label: "Create Vendor costing", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateVendorQuotation" },
                { label: "Vendor costing List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/VendorClientQuotationList" },
                { label: "Projectwise Selected Vendors", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SelectVendorList" },
                // { label: "Selected Vendor List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SelectVendorList" },
                { label: "Create Client costing", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateClientQuotation" },
                { label: "Client costing List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ClientListQuotation" },

            ]
        },

        //operations
        {
            role: "Executive - Software Developer(SOP Tool)",
            buttons: [
                { label: "Create Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateVandour" },
                { label: "Select Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSelectVendor" },
                { label: "Vendor List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/VendorList" },
                { label: "Create Vendor costing", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateVendorQuotation" },
                { label: "Vendor costing List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/VendorClientQuotationList" },
                { label: "Projectwise Selected Vendors", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SelectVendorList" },
                // { label: "Selected Vendor List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SelectVendorList" },
                { label: "Create Client costing", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateClientQuotation" },
                { label: "Client costing List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ClientListQuotation" },

            ]
        },



        {
            role: "Assitant Manager - Process",
            buttons: [
                { label: "Create Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateVandour" },
                { label: "Select Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSelectVendor" },
                { label: "Vendor List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/VendorList" },
                { label: "Create Vendor costing", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateVendorQuotation" },
                { label: "Vendor costing List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/VendorClientQuotationList" },
                { label: "Projectwise Selected Vendors", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SelectVendorList" },
                // { label: "Selected Vendor List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SelectVendorList" },
                { label: "Create Client costing", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateClientQuotation" },
                { label: "Client costing List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ClientListQuotation" },

            ]
        },

        {
            role: "Commercial-Head-Projects",
            buttons: [
                { label: "Create Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateVandour" },
                { label: "Select Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSelectVendor" },
                { label: "Vendor List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/VendorList" },
                { label: "Create Vendor costing", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateVendorQuotation" },
                { label: "Vendor costing List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/VendorClientQuotationList" },
                { label: "Projectwise Selected Vendors", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SelectVendorList" },
                // { label: "Selected Vendor List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SelectVendorList" },
                { label: "Create Client costing", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateClientQuotation" },
                { label: "Client costing List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ClientListQuotation" },

            ]
        },

        {
            role: "Project Manager",
            buttons: [
                { label: "Create Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateVandour" },
                { label: "Select Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSelectVendor" },
                { label: "Vendor List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/VendorList" },
                { label: "Create Vendor costing", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateVendorQuotation" },
                { label: "Vendor costing List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/VendorClientQuotationList" },
                { label: "Projectwise Selected Vendors", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SelectVendorList" },
                // { label: "Selected Vendor List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SelectVendorList" },
                { label: "Create Client costing", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateClientQuotation" },
                { label: "Client costing List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ClientListQuotation" },

            ]
        },

        {
            role: "Project-Manager-Poland",
            buttons: [
                { label: "Create Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateVandour" },
                { label: "Select Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSelectVendor" },
                { label: "Vendor List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/VendorList" },
                { label: "Create Vendor costing", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateVendorQuotation" },
                { label: "Vendor costing List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/VendorClientQuotationList" },
                { label: "Projectwise Selected Vendors", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SelectVendorList" },
                // { label: "Selected Vendor List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SelectVendorList" },
                { label: "Create Client costing", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateClientQuotation" },
                { label: "Client costing List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ClientListQuotation" },

            ]
        },

        {
            role: "Senior Executive - Administration",
            buttons: [
                { label: "Create Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateVandour" },
                { label: "Select Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSelectVendor" },
                { label: "Vendor List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/VendorList" },
                { label: "Create Vendor costing", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateVendorQuotation" },
                { label: "Vendor costing List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/VendorClientQuotationList" },
                { label: "Projectwise Selected Vendors", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SelectVendorList" },
                // { label: "Selected Vendor List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SelectVendorList" },
                { label: "Create Client costing", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateClientQuotation" },
                { label: "Client costing List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ClientListQuotation" },

            ]
        },

        {
            role: "Sr.Manager-Projects",
            buttons: [
                { label: "Create Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateVandour" },
                { label: "Select Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSelectVendor" },
                { label: "Vendor List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/VendorList" },
                { label: "Create Vendor costing", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateVendorQuotation" },
                { label: "Vendor costing List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/VendorClientQuotationList" },
                { label: "Projectwise Selected Vendors", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SelectVendorList" },
                // { label: "Selected Vendor List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SelectVendorList" },
                { label: "Create Client costing", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateClientQuotation" },
                { label: "Client costing List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ClientListQuotation" },

            ]
        },

        {
            role: "Head of Projects - North America",
            buttons: [
                { label: "Create Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateVandour" },
                { label: "Select Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSelectVendor" },
                { label: "Vendor List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/VendorList" },
                { label: "Create Vendor costing", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateVendorQuotation" },
                { label: "Vendor costing List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/VendorClientQuotationList" },
                { label: "Projectwise Selected Vendors", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SelectVendorList" },
                // { label: "Selected Vendor List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SelectVendorList" },
                { label: "Create Client costing", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateClientQuotation" },
                { label: "Client costing List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ClientListQuotation" },

            ]

        },

        {
            role: "Senior Manager-Projects & Client Servicing",
            buttons: [
                { label: "Create Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateVandour" },
                { label: "Select Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSelectVendor" },
                { label: "Vendor List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/VendorList" },
                { label: "Create Vendor costing", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateVendorQuotation" },
                { label: "Vendor costing List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/VendorClientQuotationList" },
                { label: "Projectwise Selected Vendors", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SelectVendorList" },
                // { label: "Selected Vendor List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SelectVendorList" },
                { label: "Create Client costing", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateClientQuotation" },
                { label: "Client costing List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ClientListQuotation" },

            ]
        },

        {
            role: "Executive - Operations",
            buttons: [
                { label: "Create Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateVandour" },
                { label: "Select Vendor", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSelectVendor" },
                { label: "Vendor List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/VendorList" },
                { label: "Create Vendor costing", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateVendorQuotation" },
                { label: "Vendor costing List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/VendorClientQuotationList" },
                { label: "Projectwise Selected Vendors", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SelectVendorList" },
                // { label: "Selected Vendor List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SelectVendorList" },
                { label: "Create Client costing", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateClientQuotation" },
                { label: "Client costing List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ClientListQuotation" },

            ]
        },

    ]

    return (
        <div>
            <div className="container-fluid">
                <div className="card shadow mb-2">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="d-flex flex-start align-items-center">

                            </div>
                            <div className="col-sm-5"><h2>Client Vendor Dashboard</h2></div>
                            <div className="col-sm-4"></div>
                            <div className="col-sm-3 d-flex flex-row-reverse">
                                <button className="btn btn-info text-uppercase  ml-1 mt-2  " onClick={() => backbutton()}>Back</button>
                                <Link to="/AdminDashboard" className="btn btn-secondary text-uppercase  ml-1 mt-2 ">Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="card cardUser shadow mb-2">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Dashboard</h6>
                    </div>
                    <div className="container" style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                        <div className="row">
                            {roleButtons.map((roleButtonGroup, index) => (
                                (roleButtonGroup.role === role) && (
                                    roleButtonGroup.buttons.map((button, buttonIndex) => (
                                        <div key={buttonIndex} className="col-xl-4 col-md-8">
                                            <div className="card border-success shadow p-1 mt-4 ml-3">
                                                <div className="btn-group">
                                                    <Link to={button.to} className={button.className} type="button">
                                                        {button.label}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )
                            ))}

                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default ClientVendorDashboardComponent