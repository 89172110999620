import React, { useEffect, useState } from 'react'
import Service from '../../../Services/Admin/Service';
import { Link } from 'react-router-dom';

const CreateExtractedData = (props) => {
  let id = sessionStorage.getItem('id');

  const [apiError, setApiError] = useState(null);
  const [apiResponse, setApiResponse] = useState(null);
  const [inputField, setInputField] = useState({
    client: "",
    country: "",
    objective: "",
    fitment: "",
    cycle: "",
    noofvisitors: "",
    totalspace: "",
    concurrentusers: "",
    createdby: id,
    updatedby: id
  })

  useEffect(() => {
    if (apiResponse || apiError) {
      const timeoutId = setTimeout(removeSuccessMessage, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [apiResponse, apiError]);

  const removeSuccessMessage = () => {
    setApiResponse(null);
    setApiError(null);
  };

  const backbutton = () => {
    props.navigate(-1);
  }

  const save = async (evt) => {
    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }

    const formData = new FormData(evt.target);

    const data = {}
    for (const [key, value] of formData) {
      data[key] = value;
    }

    function validateData(data) {

      const { field, client, country, objective, fitment, cycle, noofvisitors, totalspace, concurrentusers } = data;

      if (!field || !country || !client || !objective || !fitment || !cycle || !noofvisitors || !totalspace || !concurrentusers) {
        alert("Please fill in all required fields.");
        return;
      }
      return true;

    }

    if (!validateData(data)) {
      // The validation returned false, so stop execution here.
      // You can add any specific handling for invalid data here, if needed.
      return;
    }

    const task = {
      ...inputField,
      ...data,
    };
    debugger

    if (task) {
      Service.createorupdate("ExtractedData/CreateOrUpdate", task)
        .then(res => {
          setApiResponse(res.data); // Set the API response in state
          window.location.href = "/ExtractedDataList";
        })
        .catch(error => {
          // Handle error if API call fails
          console.error("API Error:", error);
          setApiError("Error occurred while saving the meeting. Please try again later.");
        });
    }


  }

  return (
    <div>
      <div className="container-fluid">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <div className="row">
              <div class="d-flex flex-start align-items-center">
                <img class="shadow-1-strong me-32"
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSK1fTUGLvONyLXW2lUoHwYCOLXWa2SUHyL-vhAKrMrag&s" alt="avatar" width="40"
                  height="40" />
              </div>
              <div className="col-sm-3"><h2>Extracted Data</h2></div>
              <div className="col-sm-5"></div>
              <div className="col-sm-3">
                <Link to="/AdminDashboard" className="btn btn-secondary mt-1 ml-2 btn-sm">Home</Link>
                <button className="btn btn-info mt-1 ml-1 btn-sm" onClick={() => backbutton()}>back</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="card cardUser shadow mb-4" >
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">Add</h6>
            {apiResponse && (
              <div className="alert alert-success" role="alert">
                Extracted Data has been created successfully!
              </div>
            )}
            {apiError && (
              <div className="alert alert-danger" role="alert">
                {apiError}
              </div>
            )}
          </div>

          <div class="container">
            <form onSubmit={save} class="form-horizontal mt-2">
              <div class="form-group">
                <label for="field" class="col-sm-3 required-label  control-label">Field</label>
                <div class="col-sm-9">
                  <textarea
                    rows={4} // You can adjust the number of visible rows
                    cols={105}
                    name='field'
                    placeholder='enter field details' // You can adjust the number of visible columns
                    class="form-control"
                    autofocus
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="client" class="col-sm-3 required-label  control-label">Client Name</label>
                <div class="col-sm-9">
                  <input type="text" id="client" name='client' placeholder="Client Name" required class="form-control" autofocus />
                </div>
              </div>
              <div class="form-group">
                <label for="country" class="col-sm-3 required-label  control-label">Country</label>
                <div class="col-sm-9">
                  <input type="text" id="country" name='country' placeholder="country" required class="form-control" autofocus />
                </div>
              </div>
              <div class="form-group">
                <label for="objective" class="col-sm-3 required-label control-label">Objective </label>
                <div class="col-sm-9">
                  <textarea
                    rows={2} // You can adjust the number of visible rows
                    cols={105}
                    name='objective'
                    placeholder='enter objective' // You can adjust the number of visible columns
                    class="form-control"
                    autofocus
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="fitment" class="col-sm-3 required-label control-label">Fitment </label>
                <div class="col-sm-9">
                  <input type="text" id="fitment" placeholder="Fitment" class="form-control" name="fitment" required />
                </div>
              </div>
              <div class="form-group">
                <label for="cycle" class="col-sm-3 required-label  control-label">Cycle</label>
                <div class="col-sm-9">
                  <input type="text" id="cycle" name='cycle' placeholder="Cycle" required class="form-control" autofocus />
                </div>
              </div>
              <div class="form-group">
                <label for="noofvisitors" class="col-sm-3 required-label  control-label">Number of Visitors</label>
                <div class="col-sm-9">
                  <input type="text" id="noofvisitors" name='noofvisitors' placeholder="Number of Visitors" required class="form-control" autofocus />
                </div>
              </div>
              <div class="form-group">
                <label for="totalspace" class="col-sm-3 required-label  control-label">Total Space</label>
                <div class="col-sm-9">
                  <input type="text" id="totalspace" name='totalspace' placeholder="Total Space" required class="form-control" autofocus />
                </div>
              </div>
              <div class="form-group">
                <label for="concurrentusers" class="col-sm-3 required-label  control-label">Concurrent Users</label>
                <div class="col-sm-9">
                  <input type="text" id="concurrentusers" name='concurrentusers' placeholder="Concurrent Users" required class="form-control" autofocus />
                </div>
              </div>
              <button type="submit" class="btn btn-primary ml-2 mb-3">Save</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateExtractedData