import React, { Component } from 'react' 
import { useNavigate, useParams } from 'react-router-dom';
import CreateMeetingMarketingComponent from '../../../Components/Main/Meeting/CreateMeetingMarketingComponent';


function CreateMeetingMarketing(props) {
    let navigate = useNavigate();
    let params = useParams();
    return (  
        <div> 
            <CreateMeetingMarketingComponent {...props} navigate={navigate} id={params.id}/>
        </div>
    );  
  }
  
export default CreateMeetingMarketing;  

