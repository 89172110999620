import React, { Component } from 'react' 
import { useNavigate,useParams } from 'react-router-dom';
import CreatePermitAndPassesComponent from '../../../Components/Main/PermitAndPassesComponent/CreatePermitAndPassesComponent';

function CreatePermitAndPasses(props) {
    let navigate = useNavigate();
    const params = useParams();
    return (  
        <div> 
            <CreatePermitAndPassesComponent {...props} navigate={navigate} id={params.id}  />
        </div>
    );  
  }
  
export default CreatePermitAndPasses  