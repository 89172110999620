import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import Testing2Component from '../../../Components/Main/TestingComponent/Testing2Component';

function Testing2(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <Testing2Component {...props} navigate={navigate}  />
        </div>
    );  
  }
export default Testing2  