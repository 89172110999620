import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import AddTaskTypeComponent from '../../../Components/Main/TaskTypeComponent/AddTaskTypeComponent';

function AddTasktype(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    
    return (  
        <div> 
            <AddTaskTypeComponent {...props} navigate={navigate} id={params.id}   />
        </div>
    );  
  }
export default AddTasktype  