import { useState, useEffect } from "react";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Link } from 'react-router-dom';
import ApiMethod from '../../../Helpers/ApiMethod/Method'
import Service from "../../../Services/Admin/Service";


const PrePLListComponent = (props) => {
    const [data, setData] = useState([]);
    // const apis = ApiMethod.find(o => o.name === 'Category');

    useEffect(() => {
        getCategoryData("PrePL/GetAll");
    }, [])

    const getCategoryData = (method) => {
        Service.getdata(method).then((res) => {
            setData(res.data);
        });
    }

    const backbutton = () => {
        props.navigate(-1);
    }

    const staticname1 = "details";
    const staticname2 = "edit";

    const linkformatter = (data, row) => {
        return <>
            <Link className="btn btn-info btn-sm m-1" to={`/DetailPrePL/${data}&${staticname1}`}><i className="fas fa-detail"></i>Details</Link>
            <Link className="btn btn-warning btn-sm m-1" to={`/DetailPrePL/${data}&${staticname2}`}><i className="fas fa-edit"></i>Edit</Link>
        </>
    }

    const formatDateTime = (cell, row) => {
        // Format the date and time here, assuming 'time' is a string in ISO 8601 format
        const formattedDate = new Date(cell).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            // hour: '2-digit',
            // minute: '2-digit',
            // second: '2-digit',
        });
        return formattedDate;
    }

    const column = [
        { dataField: 'client', text: 'Client' },
        { dataField: 'projectHead', text: 'Project Head', sort: true },
        { dataField: 'fair', text: 'fair' },
        { dataField: 'area', text: 'Area' },
        {
            dataField: 'fairDate',
            text: 'Fair Date',
            sort: true,
            formatter: formatDateTime
        },
        { dataField: 'id', text: 'Actions', formatter: linkformatter }
    ]

    return (
        <div>
            <div className="container-fluid">
                <div className="card shadow border-success mb-2">
                    <div className="card-header rounded py-3">
                        <div className="row">
                            <div className="col-sm-4">
                            <h3><b className="text-dark">Pre PL List</b></h3></div>

                            <div className="col-sm-6"></div>
                            <div className="col-sm-2 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-success">List</h6>
                        {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
                    </div>

                    <div className="card-body">
                        <div className="table-responsive">
                            <BootstrapTable keyField="id"
                                data={data}
                                columns={column}

                                pagination={paginationFactory()}
                            // filter={filterFactory()}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default PrePLListComponent