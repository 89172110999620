import Service from "../../../Services/Admin/Service";
import React, { useEffect, useState } from "react";
import KanbanViewCss from "./KanbanViewComponent.css";
import { Link } from "react-router-dom";

function KanbanMyTaskComponent(props) {
    const deptId = sessionStorage.getItem("DeptId");
    let userid = sessionStorage.getItem('id')

    var request = props.name;
    const staticName17 = "Request";

    const [backlogdata, setbacklogdata] = useState([]);
    const [show, setShow] = useState([]);
    const [Projectdata, setProjectdata] = useState([]);
    const [rfpLead, setRFPLead] = useState([]);
    const [lead, setLead] = useState([]);
    const [requestTask, setRequestTask] = useState(false);
    const [progressData, setprogressData] = useState([]);
    const [reviewData, setReviewData] = useState([]);
    const [doneData, setdoneData] = useState([]);
    const [archiveData, setarchiveData] = useState([]);

    const initCall = () => {
        getStatusData("Status/GetAll", 0)
        getShowData("Shows/GetAllShows");
        getRFPAll("RFP/GetAll?id=" + 1);
        getLeadData("Lead/GetAllLead");
        getProjectData("Project/GetAll");

        if (request) {
            setRequestTask(true);
        }
    }

    useEffect(() => {
        initCall();
    }, [requestTask, props])

    const getShowData = (method) => {
        Service.getdata(method).then((res) => {
            setShow(res.data);
        });
    }

    const getLeadData = (method) => {
        Service.getdata(method).then((res) => {
            setLead(res.data);
        });
    }

    const getRFPAll = (method) => {
        Service.getdata(method).then((res) => {
            var uniqueRFP = filterTasksWithUniqueIds(res.data)

            setRFPLead(uniqueRFP);

        });
    }

    const getProjectData = (method) => {
        Service.getdata(method).then((res) => {
            setProjectdata(res.data);
        });
    }

    const getStatusData = (method, selectedProjectId) => {
        //;
        Service.getdata(method).then((res) => {
            var currentdata = res.data;
            currentdata.forEach(element => {
                getTaskData("Task/GetAll?userid=" + userid + "&statusid=" + element.statusId, element.statusId, selectedProjectId);
            });
        });

    };

    function formatDate(dateString) {
        const dateObject = new Date(dateString);

        const day = String(dateObject.getDate()).padStart(2, '0');
        const month = String(dateObject.getMonth() + 1).padStart(2, '0');
        const year = dateObject.getFullYear();

        return `${day}-${month}-${year}`;
    }

    const getPriorityColor = (priority) => {
        switch (priority.toLowerCase()) {
            case "low":
                return "	#0096FF";
            case "medium":
                return "#32CD32";
            case "high":
                return "#ff0000";
            default:
                return "black";
        }
    };

    function filterTasksWithUniqueIds(tasks) {
        // Create an object to store unique tasks based on their ID
        const uniqueTasks = tasks.reduce((acc, task) => {
            // Use the task ID as the key in the object
            const taskId = task.id;

            // If the task ID is not in the object, add it
            if (!acc[taskId]) {
                acc[taskId] = task;
            }

            return acc;
        }, {});

        // Convert the uniqueTasks object back to an array
        const uniqueTasksArray = Object.values(uniqueTasks);

        return uniqueTasksArray;
    }

    const handleProjectChange = (evt) => {

        var projectId = parseInt(evt.target.value);



        if (projectId !== 0) {

            var progress = progressData.filter(x => x.projectId === projectId)
            var back = backlogdata.filter(x => x.projectId === projectId)
            var review = reviewData.filter(x => x.projectId === projectId)

            if (progress.length === 0 && back.length === 0 && review.length === 0) {
                getStatusData("Status/GetAll", projectId)


            }
            else {
                setprogressData(progress);
                setReviewData(review);
                setbacklogdata(back);

            }

        }
        else {
            getStatusData("Status/GetAll", 0)
        }

    }

    const handleLeadChange = (evt) => {

        var leadId = parseInt(evt.target.value);



        if (leadId !== 0) {


            var progress = progressData.filter(x => x.leadid === leadId)
            var back = backlogdata.filter(x => x.leadid === leadId)
            var review = reviewData.filter(x => x.leadid === leadId)

            if (progress.length === 0 && back.length === 0 && review.length === 0) {
                getStatusData("Status/GetAll", leadId)
            }
            else {
                setprogressData(progress);
                setReviewData(review);
                setbacklogdata(back);
            }

        }
        else {
            getStatusData("Status/GetAll", 0)
        }

    }

    const handleShowChange = (evt) => {

        var showId = parseInt(evt.target.value);



        if (showId !== 0) {

            var progress = progressData.filter(x => x.projectId === showId)
            var back = backlogdata.filter(x => x.projectId === showId)
            var review = reviewData.filter(x => x.projectId === showId)

            if (progress.length === 0 && back.length === 0 && review.length === 0) {
                getStatusData("Status/GetAll", showId)
            }
            else {
                setprogressData(progress);
                setReviewData(review);
                setbacklogdata(back);
            }

        }
        else {
            getStatusData("Status/GetAll", 0)
        }

    }


    const getTaskData = (method, statusid, selectedProjectId) => {
        Service.getdata(method).then((res) => {
            let taskData;

            if (requestTask === true) {
                taskData = res.data.filter((x) => x.isRequested === true);
            } else {
                taskData = res.data.filter((x) => x.isRequested === false || x.isRequested === null);
            }

            setdoneData(taskData);

            const task = filterTasksWithUniqueIds(taskData)
            if (statusid === 1) {
                if (selectedProjectId === 0) {

                    setbacklogdata(task.reverse());
                    debugger
                    console.log("Task Data", (task))
                }
                else {
                    var b = task.filter(x => x.projectId === selectedProjectId || x.leadid === selectedProjectId || x.showId === selectedProjectId)
                    setbacklogdata(b.reverse());
                }

            } else if (statusid === 2) {

                if (selectedProjectId === 0) {

                    setprogressData(task.reverse());
                    console.log("Task Data", (task))
                }
                else {
                    var c = task.filter(x => x.projectId === selectedProjectId || x.leadid === selectedProjectId || x.showId === selectedProjectId)
                    setprogressData(c.reverse());
                }
            }
            else if (statusid === 3) {

                if (selectedProjectId === 0) {

                    setReviewData(task.reverse());
                    console.log("Task Data", (task))
                }
                else {
                    var d = task.filter(x => x.projectId === selectedProjectId || x.leadid === selectedProjectId || x.showId === selectedProjectId)
                    setReviewData(d.reverse());
                }

            }
        });
    }


    const backbutton = () => {
        props.navigate(-1);
    }

    const assigned = () => {
        initCall();
        window.location.href = "/KanbanMyTask";
    }

    return (
        <>
            <div className="container-fluid">
                <div className="card border-success shadow mb-2">
                    <div className="card-header rounded py-3">
                        <div className="row">
                            <div className="col-sm-8"><h3><b>My  Task</b></h3></div>
                            {/* <div className="col-sm-6">
                                <select name="" id="" className="btn btn-primary ">
                                    <option disabled selected value="" className="btn btn-light">Task Type</option>
                                    <option className="btn btn-light" value="MyTask">My Task</option>
                                    <option className="btn btn-light" value="MyTask">Assigned Task</option>
                                </select>
                            </div> */}
                            <div className="col-sm-4 d-flex flex-row-reverse">
                                {requestTask === false ? <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button> :
                                    <button to="/KanbanAssignedTask" onClick={() => assigned()} className="btn btn-info mt-2 ml-1 text-uppercase "> My Task</button>}
                                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
                                {/* {requestTask === false && <Link to={`/KanbanMyTask/${staticName17}`} className="btn btn-info text-uppercase btn-secondary pt-2 mt-2 ml-2"> Requested Task</Link>} */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card shadow border-success mb-4">
                    <div className="card-header rounded py-3">
                        <div className="row">
                            <div className="col-sm-3">
                                {deptId === "4" && <h3>Project Task</h3>}
                                {deptId === "1" && <h3>Show Task</h3>}
                                {deptId === "3" && <h3>RFP Task</h3>}

                                {deptId === "2" && <h3>LeadWise Task</h3>}
                            </div>
                            <div className="col-sm-3"></div>

                            <div className="col-sm-6 ">

                                {
                                    deptId === "4" || deptId === "5" ? (
                                        <div className="  d-flex flex-row-reverse">
                                            <div className="col-sm-2 "><h4>Project</h4></div>
                                            <div className="col-sm-7">
                                                <select
                                                    className="form-control form-control-fs "
                                                    readOnly
                                                    onChange={handleProjectChange}
                                                    name="projectId"

                                                >
                                                    <option value="0" >[ ALL ]</option>
                                                    {Projectdata.map((e, key) => (
                                                        <option key={e.id} value={e.id}>
                                                            {e.projectname}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    ) : null
                                }

                                {deptId === "1" ?

                                    (<div className="d-flex flex-row-reverse">
                                        <div className='col-sm-2 '><h4>Show</h4></div>
                                        <div className='col-sm-7'>

                                            <select
                                                className="form-control form-control-fs"
                                                readOnly name='showId'
                                                required
                                                onChange={handleShowChange}
                                            >
                                                <option className="col-sm-5" value="0">[ ALL ]</option>
                                                {
                                                    show.map((e, key) => {
                                                        return <option key={e.showChapterId} value={e.showChapterId}>{e.chapterName}</option>;
                                                    })}
                                            </select>
                                        </div>
                                    </div>) : null
                                }
                                {deptId === "2" || deptId === "3" ? (
                                    <div className="d-flex flex-row-reverse">
                                        {deptId === "2" ? <div className='col-sm-3 '><h4>Lead</h4></div> :
                                            <div className='col-sm-2 '> <h4>RFP</h4></div>}
                                        <div className='col-sm-7'>

                                            <select
                                                className="form-control form-control-fs"
                                                readOnly
                                                name='leadId'
                                                required
                                                onChange={handleLeadChange}
                                            >
                                                <option value="0">[ ALL ]</option>
                                                {
                                                    rfpLead.map((e, key) => {
                                                        return <option key={e.id} value={e.leadMainId}>{e.leadid}</option>;
                                                    })}
                                            </select>
                                        </div>
                                    </div>
                                ) : null}

                            </div>
                        </div>
                    </div>
                </div>


                <div className="card shadow mb-4">
                    <div className="container-fluid">
                        <div className="row py-3">
                            <div className="col-sm-4">
                                <div className=""><h4>{backlogdata.length}</h4></div>
                                <div className="">Open</div>
                                <hr></hr>

                                {backlogdata.map((item) => (
                                    <div className="">
                                        <div class="items border border-light">
                                            <Link className=" link-no-underline ml-3 mb-2 btn-sm" to={`/TaskDetails/${item.id}`}>
                                                <div class="card draggable shadow-sm" id="cd1" draggable="true" ondragstart="drag(event)">
                                                    <div class="card-body p-2 bg-dark text-white">
                                                        <div class="card-title">
                                                            <div class="lead font-weight-light">Task - #{item.id}</div>
                                                            {/* <div class="lead font-weight-light"></div> */}
                                                        </div>
                                                        <p>
                                                            {item.headline}
                                                        </p>
                                                        <p>
                                                            {item.leadName}{item.projectName}
                                                        </p>
                                                        <div className="form-group form-group-h row">
                                                            {requestTask === true ? <div className="col-sm-5 required-label">Requested From</div> :
                                                                <div className="col-sm-4 required-label">Assigned From</div>}
                                                            <div className="col-sm-6 ">
                                                                {
                                                                    item.taskassignuserdetails?.slice(0, 1).map((e) => (

                                                                        <b>{e.assignerName}, </b>

                                                                    ))}
                                                            </div>
                                                        </div>
                                                        <div className="form-group form-group-h row">
                                                            {requestTask === true ? <div className="col-sm-4 required-label">Requested To</div> :
                                                                <div className="col-sm-4 required-label">Assigned To</div>}
                                                            <div className="col-sm-6 ">

                                                                {item.taskassignuserdetails
                                                                    ? [...new Set(item.taskassignuserdetails?.map(item => item.assigneeName))]
                                                                        .map((name, index) => (
                                                                            <b key={index}>{name}, </b>
                                                                        ))
                                                                    : null
                                                                }

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-7">
                                                                <p>Date- {formatDate(item.finishdate)}</p>
                                                            </div>
                                                            <div className="col-md-3 ">
                                                                {/* Apply inline style based on priority */}
                                                                <div className="priority" style={{ fontSize: "21px", fontWeight: "600", color: getPriorityColor(item.priority), textAlign: "right" }}>
                                                                    {item.priority}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                            {/* <div class="dropzone rounded" ondrop="drop(event)" ondragover="allowDrop(event)" ondragleave="clearDrop(event)"> &nbsp; </div> */}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="col-sm-4">
                                <div className=""><h4>{progressData.length}</h4></div>
                                <div className="">WIP</div>
                                <hr></hr>
                                {progressData.map((item) => (
                                    <div className="">
                                        <div class="items border border-light">
                                            <Link className=" link-no-underline ml-3 mb-2 btn-sm" to={`/TaskDetails/${item.id}`}>
                                                <div class="card draggable shadow-sm" id="cd1" draggable="true" ondragstart="drag(event)">
                                                    <div class="card-body p-2 bg-dark text-white">
                                                        <div class="card-title">
                                                            <div class="lead font-weight-light">Task - #{item.id}</div>
                                                            <div class="lead font-weight-light"></div>
                                                        </div>
                                                        <p>
                                                            {item.headline}
                                                        </p>
                                                        <p>
                                                            {item.leadName}{item.projectName}
                                                        </p>
                                                        <div className="form-group form-group-h row">
                                                            <div className="col-sm-4 required-label">Assigned By</div>
                                                            <div className="col-sm-6 ">
                                                                {
                                                                    item.taskassignuserdetails?.slice(0, 1).map((e) => (

                                                                        <b>{e.assignerName}, </b>

                                                                    ))}
                                                            </div>
                                                        </div>
                                                        <div className="form-group form-group-h row">
                                                            <div className="col-sm-4 required-label">Assigned To</div>
                                                            <div className="col-sm-6 ">
                                                                {item.taskassignuserdetails
                                                                    ? [...new Set(item.taskassignuserdetails?.map(item => item.assigneeName))]
                                                                        .map((name, index) => (
                                                                            <b key={index}>{name}, </b>
                                                                        ))
                                                                    : null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-7">
                                                                <p>Date- {formatDate(item.finishdate)}</p>
                                                            </div>
                                                            <div className="col-md-3 ">
                                                                {/* Apply inline style based on priority */}
                                                                <div className="priority" style={{ fontSize: "21px", fontWeight: "600", color: getPriorityColor(item.priority), textAlign: "right" }}>
                                                                    {item.priority}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                            {/* <div class="dropzone rounded" ondrop="drop(event)" ondragover="allowDrop(event)" ondragleave="clearDrop(event)"> &nbsp; </div> */}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="col-sm-4">
                                <div className=""><h4>{reviewData.length}</h4></div>
                                <div className="">Under Review</div>
                                <hr></hr>
                                {reviewData.map((item) => (
                                    <div className="">
                                        <div class="items border border-light">
                                            <Link className=" link-no-underline ml-3 mb-2 btn-sm" to={`/TaskDetails/${item.id}`}>
                                                <div class="card draggable shadow-sm" id="cd1" draggable="true" ondragstart="drag(event)">
                                                    <div class="card-body p-2 bg-dark text-white">
                                                        <div class="card-title">
                                                            <div class="lead font-weight-light">Task - #{item.id}</div>
                                                            <div class="lead font-weight-light"></div>
                                                        </div>
                                                        <p>
                                                            {item.headline}
                                                        </p>
                                                        <p>
                                                            {item.leadName}{item.projectName}
                                                        </p>
                                                        <div className="form-group form-group-h row">
                                                            <div className="col-sm-4 required-label">Assigned By</div>
                                                            <div className="col-sm-6 ">
                                                                {
                                                                    item.taskassignuserdetails?.slice(0, 1).map((e) => (

                                                                        <b>{e.assignerName}, </b>

                                                                    ))}
                                                            </div>
                                                        </div>
                                                        <div className="form-group form-group-h row">
                                                            <div className="col-sm-4 required-label">Assigned To</div>
                                                            <div className="col-sm-6 ">

                                                                {item.taskassignuserdetails
                                                                    ? [...new Set(item.taskassignuserdetails?.map(item => item.assigneeName))]
                                                                        .map((name, index) => (
                                                                            <b key={index}>{name}, </b>
                                                                        ))
                                                                    : null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-7">
                                                                <p>Date- {formatDate(item.finishdate)}</p>
                                                            </div>
                                                            <div className="col-md-4 ">
                                                                {/* Apply inline style based on priority */}
                                                                <div className="priority" style={{ fontSize: "21px", fontWeight: "600", color: getPriorityColor(item.priority), textAlign: "right" }}>
                                                                    {item.priority}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                            {/* <div class="dropzone rounded" ondrop="drop(event)" ondragover="allowDrop(event)" ondragleave="clearDrop(event)"> &nbsp; </div> */}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/* <div className="col-sm-3">
                                <div className="">0</div>
                                <div className="">Closed</div>
                                <hr></hr>
                                {doneData.map((item) => (
                                    <div className="">
                                        <div class="items border border-light">
                                            <Link className=" link-no-underline ml-3 mb-2 btn-sm" to={`/TaskDetails/${item.id}`}>
                                                <div class="card draggable shadow-sm" id="cd1" draggable="true" ondragstart="drag(event)">
                                                    <div class="card-body p-2 bg-dark text-white">
                                                        <div class="card-title">
                                                            <div class="lead font-weight-light">Task - #{item.id}</div>
                                                            <div class="lead font-weight-light"></div>
                                                        </div>
                                                        <p>
                                                            {item.headline}
                                                        </p>
                                                        <div className="row">
                                                            <div className="col-md-7">
                                                                <p>Date- {formatDate(item.finishdate)}</p>
                                                            </div>
                                                            <div className="col-md-5">
                                                                <p className="priority" style={{ fontSize: "18px", fontWeight: "600", color: getPriorityColor(item.priority) }}>
                                                                    {item.priority}
                                                                </p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div> */}
                        </div>
                    </div>


                </div>
            </div>


        </>
    );
}

export default KanbanMyTaskComponent