import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import ShowApprovalListComponent from '../../../Components/Main/ApprovalListComponent/ShowApprovalListComponent';

function ShowApprovalList(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <ShowApprovalListComponent {...props} navigate={navigate} id={params.id} name={params.name}  />
        </div>
    );  
  }
export default ShowApprovalList 