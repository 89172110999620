import Service from '../../../Services/Admin/Service'
import React, { useState } from 'react'
import Cookies from 'js-cookie';
import logo from "../../Main/xsnxt.png";

function LoginComponent(props) {
    const [loading, setLoading] = useState(false); // New state for loading
    const [userDetails, setUserDetails] = useState(''); // New state for loading
    const [inputField, setInputField] = useState({
        Email: '',
        Password: ''
    })



    const fetchUserDetails = (userid) => {

        const method = "Users/GetById?id=" + userid;
        Service.getdatabyId(method, '').then((res) => {
            if (res !== undefined) {
                setUserDetails(res.data);

                Cookies.set('role', res.data.lstRole[0].name, { expires: 7 });
                Cookies.set('DeptId', res.data.lstRole[0].depId, { expires: 7 });
                console.log("CUrrent USer role", res.data)
                sessionStorage.setItem('role', Cookies.get('role'));
                sessionStorage.setItem('DeptId', Cookies.get('DeptId'));

                var lr = res.data.lstRole;
                const deptRole = [];
                const roleLst = [];
                lr.forEach((d, index) => {
                    deptRole.push(d.depId);
                    roleLst.push(d.name);
                })
                Cookies.set('Deptlst', deptRole, { expires: 7 });
                Cookies.set('Rolelst', roleLst, { expires: 7 });
                sessionStorage.setItem('Rolelst', Cookies.get('Rolelst'));
                sessionStorage.setItem('Deptlst', Cookies.get('Deptlst'));

                window.location.href = "/";
            }

        });
    }


    const inputsHandler = (e) => {
        //  setInputField( {[e.target.name]: e.target.value} )
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const submitButton = (e) => {
        e.preventDefault();
        let Users = inputField;

        setLoading(true); // Set loading state to true before making the API call

        Service.createorupdate("Login/Authentication", Users)
            .then(res => {
                setLoading(false); // Set loading state to false after receiving the API response

                if (res.data.status === "Successfully") {
                    Cookies.set('id', res.data.id, { expires: 7 });
                    Cookies.set('name', res.data.fullName, { expires: 7 });
                    Cookies.set('token', res.data.Token, { expires: 7 });
                    Cookies.set('email', res.data.email, { expires: 7 });
                    // const cookieValue = Cookies.get('name');
                    sessionStorage.setItem('name', Cookies.get('name'));
                    sessionStorage.setItem('id', Cookies.get('id'));
                    sessionStorage.setItem('token', Cookies.get('token'));
                    sessionStorage.setItem('email', Cookies.get('email'));

                    fetchUserDetails(res.data.id);

                    // sessionStorage.setItem('role', 'Graphic-Head');
                    // sessionStorage.setItem('role', result);

                } else if (res.data.Status === "Password not Matched") {
                    alert("Password not Matched");
                } else {
                    alert(res.data.status);
                }
            })
            .catch(error => {
                setLoading(false);
                console.error('API Error:', error);
            });
    };

    return (
        <div className='backgroundMain p-2'>
                <div className="col-sm-12 text-light">
                    <h1 className='mt-5 text-center'><b>Welcome To</b></h1>
                </div>
            <div className='d-flex justify-content-around flex-wrap'>
                <div className="col-sm-5 d-flex justify-content-center">
                    <img src={logo} className='loginLogo' alt="Xs-Worldwide"/>
                </div>
                    <div className="col-sm-5 loginBox">
                        <div className="col-lg-8 rounded backgroundMain">
                            <div className="p-2">
                                <div className="text-center">
                                    <h1 className="h4 text-light mb-4">Log In</h1>
                                </div>
                                <form className="user d-flex justify-content-center flex-column" onSubmit={submitButton}>
                                    <div className="form-group">
                                        <input type="email" className="form-control btnLogin" placeholder="Enter your Email Address.." onChange={inputsHandler} value={inputField.Email} name="Email"></input>
                                    </div>
                                    <div className="form-group">
                                        <input type="password" className="form-control btnLogin" placeholder="Password" onChange={inputsHandler} value={inputField.Password} name="Password" />
                                    </div>
                                    <div className="form-group">
                                        <div className="custom-control custom-checkbox small">
                                            <input type="checkbox" className="custom-control-input " id="customCheck" />
                                            <label className="custom-control-label" for="customCheck">Remember
                                                Me</label>
                                        </div>
                                    </div>
                                    <button className="btn btn-sm btn-warning text-dark btnLogin " type="submit ">
                                        <h5 className='font-weight-bold'>Log In</h5>
                                        {loading && <div className="ml-2 justify-content-center"><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></div>}
                                    </button>
                                    <hr />

                                </form>
                            </div>
                        </div>

                
                </div>
            </div>

        </div>
    )
}

export default LoginComponent