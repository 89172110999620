import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Service from '../../../Services/Admin/Service';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const DashboardUserTaskComponent = (props) => {
    let id = sessionStorage.getItem('id');

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false); // New state for loading

    function filterTasksWithUniqueIds(tasks) {
        // Create an object to store unique tasks based on their ID
        const uniqueTasks = tasks.reduce((acc, task) => {
            // Use the task ID as the key in the object
            const taskId = task.userid;

            // If the task ID is not in the object, add it
            if (!acc[taskId]) {
                acc[taskId] = task;
            }

            return acc;
        }, {});

        // Convert the uniqueTasks object back to an array
        const uniqueTasksArray = Object.values(uniqueTasks);

        return uniqueTasksArray;
    }


    useEffect(() => {
        Getuserwiselist("Dashboard/GetAllUserwiseTasklist?userid=1");
    }, [])


    const Getuserwiselist = (method) => {
        Service.getdata(method).then((res) => {
            const task = filterTasksWithUniqueIds(res.data)
            setData(task);
            if (task) {
                setLoading(true);
            }

            console.log(res.data);
        });
    }

    const backbutton = () => {
        props.navigate(-1);
    }

    const calculateHours = (time) => {
        if (time !== null && time !== 0) {
            const hours = Math.floor(time / 60);
            const minutes = time % 60;

            return `${hours} hrs ${minutes} min`;
        } else {
            return null;
        }
    };

    return (
        <div className="container-fluid" >

            <div className="card shadow border-success mb-2">
                <div className="card-header rounded py-3">
                    <div className="row">
                        <div className="col-sm-4"><h3><b className='text-dark'>Users Progress Report</b></h3></div>
                        <div className="col-sm-6"></div>
                        <div className="col-sm-2 d-flex flex-row-reverse">
                            <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                            {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="card shadow p-0" style={{  overflow: "auto", }}>
                <div className="card-header">
                    {loading === true && <h6 className="font-weight-bold text-success">Users Details</h6>}{loading === false &&
                        <div class=" text-primary">
                            <strong>Loading...</strong>
                            <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                        </div>
                    }
                </div>
                <div className="card-body m-0" style={{ maxHeight: "390px", overflow: "auto", }}>
                    <div className="row text-dark">
                        <div className="col-lg-12 ml-0" >
                            <table className="table table-xl">
                                <thead className="thead-dark" style={{ position: "sticky", top: "-19px", zIndex: "1" }}>

                                    <th>#</th>
                                    <th>Employee Name</th>
                                    {/* <th>Role Name</th> */}
                                    <th>Progress Task</th>
                                    <th>Completed Task</th>
                                    <th>Total Task</th>
                                    <th>Failed Deadline </th>
                                    <th>Todays Time</th>

                                </thead>

                                {loading === true && <tbody className='text-dark'>
                                    {data.map((pr, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td><b>{pr.fullname}</b></td>
                                            {/* <td>{pr.roleName}</td> */}
                                            <td><b>{pr.inprogresstask}</b></td>
                                            <td><b>{pr.completedtask}</b></td>
                                            <td><b>{pr.totaltask}</b></td>
                                            <td><b>{pr.faileddeadlinetask}</b></td>
                                            <td><b>{calculateHours(pr.todayMinutes)}</b></td>
                                        </tr>
                                    ))}
                                </tbody>}
                            </table>
                        </div>


                    </div>
                </div>
            </div>

        </div>
    )
}

export default DashboardUserTaskComponent