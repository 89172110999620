import React, { useState, useEffect } from 'react'
import Service from "../../../Services/Admin/Service";
import ApiMethod from '../../../Helpers/ApiMethod/Method'

function AddTaskTypeComponent(props) {
    let uerid = sessionStorage.getItem('id');
    const [inputField, setInputField] = useState({
        id:props.id,
        taskname: '',
        isPredefined: false,
        createdby: uerid,        
    })

    useEffect(() => {       
        getCategoryDatabyId("TaskType/GetById?id=", props.id)
    }, [])


    const getCategoryDatabyId = (method, id) => {
        Service.getdatabyId(method, id).then((res) => {
            setInputField(res.data);
        });
    }

    const inputsHandler = (e) => {
        //  setInputField( {[e.target.name]: e.target.value} )
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const backbutton = () => {
        props.navigate(-1);
    }

    const submitButton = (e) => {
        e.preventDefault();
        let project = inputField;
        project.createdby = uerid;

     //   const apis = ApiMethod.find(o => o.name === 'Category');
        Service.createorupdate("TaskType/CreateOrUpdate", project).then(res => {
            props.navigate('/TasksTypeList');
        });
    }

    
    return (

        <div>
            <div className="container-fluid">
                <div className='row'>
                    <div className='col-sm-9 text-left'></div>
                    <div className='col-sm-3'><button className="btn btn-info btn-sm" onClick={() => backbutton()}>back</button></div>
                </div>
                <br />
                <form>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <div className="row">
                                <div className="col-sm-3"><h3>Add Task Type</h3></div>
                                <div className="col-sm-6"></div>
                                <div className="col-sm-3">
                                    <button className="btn btn-primary btn-block" onClick={submitButton} type='submit'>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Task Type Info</h6>
                        </div>

                        <div className="card-body">
                            <div className="form-group row">
                                <div className='col-sm-3'>Project</div>
                                <div className='col-sm-9'><input type="text" className="form-control form-control-user" id="name"
                                    onChange={inputsHandler} value={inputField.taskname} name="taskname" placeholder="TaskType Name" /></div>

                            </div>
                            
                            <div className="form-group row">
                                <div className='col-sm-3'>Is Predefined ?</div>
                                <div className='col-sm-3'><input type="checkbox" name="isPredefined"
                                    className='form-control-user' checked={inputField.isPredefined} onChange={() => setInputField(prev => ({ ...prev, isPredefined: !prev.isPredefined }))} id="isPredefined" /></div>
                                <div className='col-sm-6'></div>

                            </div>
                        </div>

                    </div>


                    
                </form>
            </div>

        </div>
    )
}

export default AddTaskTypeComponent