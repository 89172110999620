import React, { useEffect, useState } from 'react'
import Service from '../../../Services/Admin/Service';
import { convertToRaw, EditorState, convertFromHTML, ContentState, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { Editor } from "react-draft-wysiwyg";
import { Link } from 'react-router-dom';

const BoqIsApprovedComponent = (props) => {


  const id = props.id;
  const [isapprovedchecked, setisapprovedchecked] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [boqDetail, setBoqDetail] = useState([]);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [inputField, setInputField] = useState(
    {
      id: props.id,
      boqdetails: "",
      projectid: 0,
      boqType: "",
      createdby: id,
      updatedBy: id
    }
  )

  useEffect(() => {
    getBoqInfo("Boq/GetById?id=" + id)
  }, [])

  const getBoqInfo = (method) => {
    Service.getdata(method).then((res) => {
      setBoqDetail(res.data);
      const content1 = convertFromRaw(JSON.parse(res.data.boqdetails));
      setEditorState(EditorState.createWithContent(content1));
      setInputField(res.data);
      setisapprovedchecked(res.data.isAproved);
    });
  }


  useEffect(() => {
    if (apiResponse || apiError) {
      const timeoutId = setTimeout(removeSuccessMessage, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [apiResponse, apiError]);

  const removeSuccessMessage = () => {
    setApiResponse(null);
    setApiError(null);
  };

  const save = async (evt) => {
    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }

    const formData = new FormData(evt.target);

    const data = {};
    for (const [key, value] of formData) {
      data[key] = value;
    }

    const { isApproved } = data;
    console.log(isApproved)

    const task = {
      ...inputField
    };
    if (task) {

      Service.createorupdate("Boq/CreateOrUpdate", task)
        .then(res => {
          setApiResponse(res.data); // Set the API response in state
        })
        .catch(error => {
          // Handle error if API call fails
          console.error("API Error:", error);
          setApiError("Error occurred while saving the Boq. Please try again later.");
        });
    }

  }

  const backbutton = () => {
    props.navigate(-1);
  }

  const handleEditorChange = (state) => {
    setEditorState(state);
  }

  const handleIsCheckbox = (e) => {
    //alert(e.target.checked);
    inputField.isAproved = e.target.checked;
    setisapprovedchecked(e.target.checked);
  }

  return (
    <div>
      <div className="container-fluid">

        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <div className="row">
              <div class="d-flex flex-start align-items-center">
              </div>
              <div className="col-sm-3"><h2>Boq Is Aprroved</h2></div>
              <div className="col-sm-6"></div>
              <div className="col-sm-2 d-flex flex-row-reverse">
                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="card cardUser shadow mb-4" >
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">Approved</h6>
            {apiResponse && (
              <div className="alert alert-success" role="alert">
                Boq has been Updated Successfully!
              </div>
            )}
            {apiError && (
              <div className="alert alert-danger" role="alert">
                {apiError}
              </div>
            )}
          </div>
          <div class="container">
            <form onSubmit={save} class="form-horizontal mt-2">
              <div class="form-group row">
                <label for="details" class="col-sm-5 required-label  control-label">Agreement Details</label>
                <div class="col-sm-7">
                  <Editor
                    readOnly
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={handleEditorChange}
                    required
                  />
                </div>

              </div>

              <div class="form-group row">
                <div className='col-sm-5 required-label'>Project</div>
                <div className='col-sm-7'>
                  <b>{boqDetail.projectid}</b>
                </div>
                <div class="col-sm-9">
                </div>
              </div>

              <div class="form-group row">
                <div className='col-sm-5 required-label'>Boq Type</div>
                <div className='col-sm-7'>
                  <b>{boqDetail.boqType}</b>
                </div>
                <div class="col-sm-9">
                </div>
              </div>

              <div class="form-group row">
                <label for="details" class="col-sm-5  required-label  control-label">Is Approved</label>
                <div class="col-sm-7">
                  <input
                    type="checkbox"
                    id="details"
                    name='isApproved'
                    checked={isapprovedchecked}
                    onChange={handleIsCheckbox}
                    autoFocus
                  />
                </div>
              </div>
              {apiResponse && (
              <div className="alert alert-success" role="alert">
                Boq has been Updated Successfully!
              </div>
            )}
            {apiError && (
              <div className="alert alert-danger" role="alert">
                {apiError}
              </div>
            )}
              <button type="submit" class="btn btn-primary ml-3 mb-3">Update</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BoqIsApprovedComponent