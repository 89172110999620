import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import ClientVendorDashboardComponent from '../../../Components/Main/AdminPanelComponent/ClientVendorDashboardComponent';

function ClientVendorDashboard(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <ClientVendorDashboardComponent {...props} navigate={navigate} id={params.id} />
        </div>
    );  
  }
export default ClientVendorDashboard  