import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Service from '../../../Services/Admin/Service';

const VendorDetailsComponent = (props) => {

    const id = props.id;
    const [data, setData] = useState([]);

    useEffect(() => {
        getVendorDetails("Vendor/GetById?id=" + id);
    }, []);

    const getVendorDetails = (method) => {
        Service.getdatabyId(method, '').then((res) => {
            setData(res.data);
        });
    }

    const backbutton = () => {
        props.navigate(-1);
    }

    return (
        <div>
            <div className="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div class="d-flex flex-start align-items-center">
                                <img class="shadow-1-strong me-32"
                                    src="https://e7.pngegg.com/pngimages/52/220/png-clipart-vendor-computer-icons-font-supplier-miscellaneous-silhouette.png" alt="avatar" width="45"
                                    height="45" />
                            </div>
                            <div className="col-sm-3"><h2>Vendor Details</h2></div>
                            <div className="col-sm-6"></div>
                            <div className="col-sm-2 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="card cardUser shadow mb-4" >
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Details #{data.id}</h6>
                    </div>

                    <div class="container">
                        <form class="form-horizontal mt-2">
                            <div class="form-group row">
                                <label for="supplier" class="col-sm-3 required-label  control-label">Supplier Name</label>
                                <div class="col-sm-9">
                                    <b>{data.supplierName}</b>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="firstName" class="col-sm-3 required-label  control-label">Phone</label>
                                <div class="col-sm-9">
                                    <b>{data.phone}</b>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="email" class="col-sm-3 required-label control-label">Email </label>
                                <div class="col-sm-9">
                                    <b>{data.email}</b>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="website" class="col-sm-3 required-label control-label">Website </label>
                                <div class="col-sm-9">
                                    <b>{data.website}</b>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="country" class="col-sm-3 required-label  control-label">Country</label>
                                <div class="col-sm-9">
                                    <b>{data.country}</b>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="Region" class="col-sm-3 required-label  control-label">Region</label>
                                <div class="col-sm-9">
                                    <b>{data.region}</b>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="lastProject" class="col-sm-3 required-label  control-label">Last project</label>
                                <div class="col-sm-9">
                                    <b>{data.lastProject}</b>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="service" class="col-sm-3 required-label control-label">Type of Service</label>
                                <div class="col-sm-9">
                                    <b>{data.service}</b>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VendorDetailsComponent