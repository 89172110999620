import React from 'react'
import { Link } from 'react-router-dom';

const DashboardPreSalesOptionsComponent = (props) => {

  const role = sessionStorage.getItem("role");

  const backbutton = () => {
    props.navigate(-1);
  }

  const roleButtons = [

    {
      role: "Managing Director(MD)",
      buttons: [
        { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateSalesTask" },
        { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
        { label: "My Tasks", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
        { label: "Create Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingSales" },
        { label: "My Meeting", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
        { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/RFPList" },
        { label: "Attach PPT", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreatePPT" },
        { label: "PPT List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/PPTList" },
      ]
    },

    {
      role: "Admin",
      buttons: [
        { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateSalesTask" },
        { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
        { label: "My Tasks", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
        { label: "Create Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingSales" },
        { label: "My Meeting", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
        { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/RFPList" },
        { label: "Attach PPT", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreatePPT" },
        { label: "PPT List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/PPTList" },
      ]
    },

    //operations
    {
      role: "Assitant Manager - Process",
      buttons: [
        { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateSalesTask" },
        { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
        { label: "My Tasks", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
        { label: "Create Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingSales" },
        { label: "My Meeting", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
        { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/RFPList" },
        { label: "Attach PPT", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreatePPT" },
        { label: "PPT List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/PPTList" },
      ]
    },

    {
      role: "Executive - Software Developer(SOP Tool)",
      buttons: [
        { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateSalesTask" },
        { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
        { label: "My Tasks", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
        { label: "Create Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingSales" },
        { label: "My Meeting", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
        { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/RFPList" },
        { label: "Attach PPT", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreatePPT" },
        { label: "PPT List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/PPTList" },
      ]
    },
    {
      role: "GM-Commercial",
      buttons: [
        { label: "New Task", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreateSalesTask" },
        { label: "Assigned Task", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
        { label: "My Tasks", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
        { label: "Create Meeting", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingSales" },
        { label: "My Meeting", className: "btn btn-danger btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
        { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/RFPList" },
        { label: "Attach PPT", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/CreatePPT" },
        { label: "PPT List", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/PPTList" },
      ]
    },


  ];
  return (
    <div>
      <div className="container-fluid">
        <div className="card shadow border border-success mb-2">
          <div className="card-header rounded rounded py-3">
            <div className="row">
              <div className="d-flex flex-start align-items-center">

              </div>
              <div className="col-sm-3 mt-1"><h2><b className='text-dark'>Dashboard</b></h2></div>
              <div className="col-sm-6"></div>
              <div className="col-sm-3 d-flex flex-row-reverse">
                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="card cardUser shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-success">Dashboard</h6>
          </div>
          <div className="container" style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
            <div className="row">
              {roleButtons.map((roleButtonGroup, index) => (
                (roleButtonGroup.role === role) && (
                  roleButtonGroup.buttons.map((button, buttonIndex) => (
                    <div key={buttonIndex} className="col-xl-4 col-md-8">
                      <div className="card border-success shadow p-1 mt-4 ml-3">
                        <div className="btn-group">
                          <Link to={button.to} className={button.className} type="button">
                            {button.label}
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
                )
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardPreSalesOptionsComponent