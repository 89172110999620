import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import DasboardMarketingOptionsComponent from '../../../Components/Main/DashboardOptionsComponent/DasboardMarketingOptionsComponent';
import AdminCalendarComponent from '../../../Components/Main/CalendarComponent/adminCalendarComponent';

function AdminCalendar(props){
     let navigate = useNavigate();
     const params = useParams();
     
     return (  
         <div> 
             <AdminCalendarComponent {...props}  navigate={navigate} />
         </div>
     );   

}

export default AdminCalendar