import React, { Component } from 'react' 
import { useNavigate } from 'react-router-dom';
import ClientListQuotationComponent from '../../../Components/Main/VendorQuotationComponent/ClientListQuotationComponent';

function ClientListQuotation(props) {
    let navigate = useNavigate();
    return (  
        <div> 
            <ClientListQuotationComponent {...props} navigate={navigate} />
        </div>
    );  
  }
  
export default ClientListQuotation  