import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import Service from "../../../Services/Admin/Service";
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';

const ContentAttachmentListComponent = (props) => {
    const [data, setData] = useState([]);
    // const apis = ApiMethod.find(o => o.name === 'Category');

    useEffect(() => {
        getCategoryData("");
    }, [])

    const getCategoryData = (method) => {
        Service.getdata(method).then((res) => {
            setData(res.data);
        });
    }

    const backbutton = () => {
        props.navigate(-1);
    }

    const linkformatter = (data, row) => {
        return <>
            <Link className="btn btn-warning btn-sm" to={`//${data}`}><i className="fas fa-edit">View</i></Link>
        </>
    }



    const column = [
        { dataField: 'showid', text: 'Show Name', sort: true },
        { dataField: 'Link', text: 'ContentLink' },
        { dataField: 'id', text: 'Actions', formatter: linkformatter }
    ]

    return (
        <div>
            <div className="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col-sm-3"><h3>Content Link List</h3></div>

                            <div className="col-sm-6"></div>
                            <div className="col-sm-3">
                                <Link to="/AdminDashboard" className="btn btn-secondary mt-1 ml-2 btn-sm">Home</Link>
                                <button className="btn btn-info mt-1 ml-1 btn-sm" onClick={() => backbutton()}>back</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">List Of Content</h6>
                        {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
                    </div>

                    <div className="card-body">
                        <div className="table-responsive">
                            <BootstrapTable keyField="id"
                                data={data}
                                columns={column}

                                pagination={paginationFactory()}
                            // filter={filterFactory()}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ContentAttachmentListComponent