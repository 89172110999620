import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import Service from "../../../Services/Admin/Service";
import React, { useState, useEffect } from 'react'
const SupplierApprovalListComponent = (props) => {
  const [data, setData] = useState([]);
  // const apis = ApiMethod.find(o => o.name === 'Category');

  useEffect(() => {
    getCategoryData("ApporvalList/GetByProjectID?id=" + props.id);
  }, [])

  const getCategoryData = (method) => {
    Service.getdata(method).then((res) => {
      console.log(res.data)
      debugger
      const filteredData = res.data.filter((item) => item.typeId === 3);
      setData(filteredData);
      debugger

    });
  }

  const backbutton = () => {
    props.navigate(-1);
  }

  var staticName1 = "edit";
  var staticName2 = "details";

  const linkformatter = (data, row) => {
    return <>
      <Link className="btn btn-warning btn-sm" to={`/CreateApprovalList/${data}&${staticName1}`}> Edit </Link>
      <Link className="btn btn-warning ml-1 btn-sm" to={`/CreateApprovalList/${data}&${staticName2}`}>Details </Link>
    </>
  }



  const column = [
    { dataField: 'material', text: 'material', sort: true },
    { dataField: 'id', text: 'Actions', formatter: linkformatter }
  ]
  return (
    <div>
      <div className="container-fluid">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <div className="row">
              <div className="col-sm-7"><h3>Material List - {props.name}</h3></div>

              <div className="col-sm-2"></div>
              <div className="col-sm-3 d-flex flex-row-reverse">
                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">Material List</h6>
            {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
          </div>

          <div className="card-body">
            <div className="table-responsive">
              <BootstrapTable keyField="id"
                data={data}
                columns={column}

                pagination={paginationFactory()}
              // filter={filterFactory()}
              />
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default SupplierApprovalListComponent