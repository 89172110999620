import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import DesignListComponent from '../../../Components/Main/DesignComponent/DesignListComponent';

function DesignList(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <DesignListComponent {...props} navigate={navigate} id = {params.id} name = {params.name}/>
        </div>
    );  
  }
export default DesignList  