import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Service from '../../../Services/Admin/Service';

const NotificationComponent = (props) => {

    const [data, setData] = useState([]);
    let id = sessionStorage.getItem('id');
    let name = sessionStorage.getItem('name');
    let updatecount = sessionStorage.getItem('updatecount');
    const [notification, setnotification] = useState([]);

    useEffect(() => {
        let currentcount = updatecount + 1;
        sessionStorage.setItem('updatecount', currentcount);
        GetNotification("Dashboard/GetAllNotificationlist?userid=" + id + "&isRead=", 1 );
    }, [updatecount])

    const GetNotification = (method, id) => {
        Service.getdatabyId(method, id).then((res) => {
            setnotification(res.data);
            debugger
            // if (res.data.length > notification.length && !notificationShown) {
            //     toast.success('New notification received!');
            //     addNotification({
            //         title: 'sop notification',
            //         duration: '4000',
            //         native: true,
            //     })
            //     setNotificationShown(true)

            // }
            // console.log(res.data);
            // debugger
        });
    }

    const formatDateTime = (cell, row) => {
        // Format the date and time here, assuming 'time' is a string in ISO 8601 format
        const formattedDate = new Date(cell).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',

        });
        return formattedDate;
    }


    const backbutton = () => {
        props.navigate(-1);
    }
    return (
        <div className="container-fluid">
            <div className="card shadow mb-1">
                <div className="card-header ">
                    <div className="row">
                        <div className="col-sm-8"><h4>My Notifications</h4></div>
                        <div className="col-sm-4 d-flex flex-row-reverse">
                            {/* <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button> */}
                            <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary">Home</Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card  mb-4  ">
                <div className="container-fluid ">
                    <div className="row py-3 ">
                        <div className="col-sm-12 ">
                            {/* <div className=""><h4>{ }</h4></div> */}
                            {/* <div className="">Notification Message Center</div> */}
                            <div className="">
                                {notification.length === 0 && <div><b>You have No Notification</b></div>}
                                {notification.map((pr, index) => (
                                    <div class="">
                                        <div class="card draggable" id="cd1" draggable="true" ondragstart="drag(event)">
                                            <div class="">

                                                <div className='' key={index}>
                                                    {pr.taskid !== null && pr.taskid !== 0 ?
                                                        (
                                                            <>
                                                                <a className="dropdown-item d-flex justify-content-between " href={`/TaskDetails/${pr.taskid}`}>
                                                                    <div className="dropdown-list-image mr-3 d-flex">
                                                                        <img className="img-profile rounded-circle" src="https://source.unsplash.com/random" height={"35px"} width={"35px"} alt="" />
                                                                        <div className="font-weight-bold py-1 ml-2">
                                                                            <div className="text-truncate"><b>Task Id #{pr.taskid}-</b>{pr.description}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="font-weight-bold  ">
                                                                        <div className="small mt-1">{pr.senderName} ( {formatDateTime(pr.createddate)} )</div>
                                                                    </div>
                                                                </a>
                                                            </>)
                                                        :
                                                        pr.meetingId !== null ?
                                                            <a className="dropdown-item  d-flex justify-content-between" href={`/MeetingDetails/${pr.meetingId}`}>
                                                                <div className="dropdown-list-image mr-3 d-flex">
                                                                    <img className="img-profile rounded-circle" src="https://source.unsplash.com/random" height={"35px"} width={"35px"} alt="" />
                                                                    <div className="font-weight-bold py-1 ml-2">
                                                                        <div className="text-truncate"><b>Meeting Id #{pr.meetingId}-</b>{pr.description}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="font-weight-bold ">
                                                                    <div className="small mt-1">{pr.senderName} ( {formatDateTime(pr.createddate)} )</div>
                                                                </div>
                                                            </a>
                                                            :
                                                            pr.rfpid !== null && pr.rfpid !== 0 ?
                                                                (<a className="dropdown-item d-flex justify-content-between" href={`/RFPDetails/${pr.rfpid}`}>
                                                                    <div className="dropdown-list-image mr-3 d-flex">
                                                                        <img className="img-profile rounded-circle" src="https://source.unsplash.com/random" height={"35px"} width={"35px"} alt="" />
                                                                        <div className="font-weight-bold py-1 ml-2">
                                                                            <div className="text-truncate"><b>RFP Id #{pr.rfpid}-</b>{pr.description}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="font-weight-bold ">
                                                                        <div className="small mt-1">{pr.senderName} ( {formatDateTime(pr.createddate)} )</div>
                                                                    </div>
                                                                </a>)
                                                                :
                                                                null}
                                                </div>

                                            </div>
                                        </div>
                                        {/* <div class="dropzone rounded" ondrop="drop(event)" ondragover="allowDrop(event)" ondragleave="clearDrop(event)"> &nbsp; </div> */}
                                    </div>

                                ))}
                            </div>
                        </div>

                    </div>
                </div>


            </div>
        </div>
    )
}

export default NotificationComponent