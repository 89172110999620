import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import DashboardLeadComponent from '../../../Components/Main/LeadComponent/DashboardLeadComponent';


function DashboardLead(props) {
    let navigate = useNavigate();
    const params = useParams()
    return (  
        <div> 
            <DashboardLeadComponent {...props} navigate={navigate} name={params.name} />
        </div>
    );  
  }
export default DashboardLead  