import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import QualifiedLeadDetailsComponent from '../../../Components/Main/LeadComponent/QualifiedLeadDetailsComponent';


function QualifiedLeadDetails(props) {
    let navigate = useNavigate();
    const params = useParams();
    return (  
        <div> 
            <QualifiedLeadDetailsComponent {...props} navigate={navigate} id={params.id} />
        </div>
    );  
  }
export default QualifiedLeadDetails  