import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Service from '../../../Services/Admin/Service';

const RFPSharedComponemt = (props) => {

    const [RFPError, setRFPErrror] = useState(false);
    const [RFPdata, setRFPdata] = useState([]);

    useEffect(() => {
        getRFPData("Status/GetAll");

    }, [])

    const getRFPData = (method) => {
        Service.getdata(method).then((res) => {
            setRFPdata(res.data);
        });
    }


    const backbutton = () => {
        props.navigate(-1);
    }
    return (
        <div>
            <div className="container-fluid">

                <br />
                <form>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <div className="row">
                                <div className="col-sm-3"><h3>Share RFP</h3></div>
                                <div className="col-sm-6"></div>
                                <div className="col-sm-3">
                                    <button className="btn btn-primary" type='submit'>Share</button>
                                    <Link to="/AdminDashboard" className="btn btn-secondary mt-1 ml-2 btn-sm">Home</Link>
                                    <button className="btn btn-info mt-1 ml-1 btn-sm" onClick={() => backbutton()}>back</button>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Share</h6>

                        </div>

                        <div className="card-body" style={{ height: "auto" }}>
                            <div className='row'>
                                <div className='col-md-5 ml-5'>
                                    <div className="form-group form-group-h row">
                                        <div className='col-sm-12 required-label'>Project</div>
                                        <div className='col-sm-12'>
                                            {RFPError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a project.</div>}
                                            <select className="form-control form-control-fs  form-control-user" name="projectId" required>
                                                <option>[NONE]</option>
                                                {
                                                    RFPdata.map((e, key) => {
                                                        return <option key={e.id} value={e.id}>{e.projectname}</option>;
                                                    })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group form-group-h row">
                                        <div className='col-sm-12 required-label'>Attachment</div>
                                        <div className='col-sm-12'>
                                            <input type="file" className="form-control-user" id="headline"
                                                name="headline" placeholder="http://example.com" required />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default RFPSharedComponemt