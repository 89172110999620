/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect, useState } from 'react'
import ApiMethod from '../../../Helpers/ApiMethod/Method'
import Service from "../../../Services/Admin/Service";
import { Link } from 'react-router-dom';

function CreateRoleComponent(props) {

    const [department, setDepartment] = useState([]);
    const [selectedDepartment, setselectedDepartment] = useState([])
    const [apiResponse, setApiResponse] = useState(null);
    const [departmentError, setDepartmentError] = useState(false);
    let id = sessionStorage.getItem('id');
    const [inputField, setInputField] = useState({
        name: '',
        depId: '',
        createdby: id,
        updatedby: id,
        isActive: true,
        isDeleted: false,
        lstrole:[]
    });
    useEffect(() => {
        getDepartmentInfo("Department/GetAll");
    }, [])

    useEffect(() => {
        if (apiResponse) {
            const timeoutId = setTimeout(removeSuccessMessage, 3000);

            return () => clearTimeout(timeoutId);
        }
    }, [apiResponse]);

    const getDepartmentInfo = (method) => {
        Service.getdata(method).then((res) => {
            setDepartment(res.data);
        });
    }

    const handleInputStatus = (e) => {
        setselectedDepartment(e.target.value);
    }

    const removeSuccessMessage = () => {
        setApiResponse(null);
    };

    const save = (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        const formData = new FormData(e.target);

        const data = {}
        for (const [key, value] of formData) {
            data[key] = value;
        }
        
        function validateData(data) {
            const{name,departmentId} = data;
            if(!name) {
                alert("Please fill in all required fields.")
                return false;
            }

            if (!departmentId || departmentId === "[NONE]") {
                setDepartmentError(true);
                return false;
            } else {
                setDepartmentError(false);
            }

            return true;
        }

        if (!validateData(data)) {
            // The validation returned false, so stop execution here.
            // You can add any specific handling for invalid data here, if needed.
            return;
        }

        const task = {
            ...inputField,
            ...data,  
        }
        debugger
        if (selectedDepartment.length === 0) {
            task.depId = 0;
        } else {
            task.depId = selectedDepartment;
        }
       console.log(task)

        Service.createorupdate("Roles/CreateOrUpdate", task).then(res => {
            if (res.status === 200) {
                setApiResponse(res.data);
            }
        })
        .catch(res => {
            // Handle API error, if necessary
            if(res.status!==200){
                console.error("API Error:", res);
            }
        });

    }

    const backbutton = () => {
        props.navigate(-1);
    }

    return (
        <div>
            <div className="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div class="d-flex flex-start align-items-center">
                                <img class="shadow-1-strong me-32"
                                    src="https://icons.veryicon.com/png/o/commerce-shopping/project-2/role-6.png" alt="avatar" width="40"
                                    height="40" />
                            </div>
                            <div className="col-sm-3"><h2>Assign Role</h2></div>
                            <div className="col-sm-5"></div>
                            <div className="col-sm-3">
                                <Link to="/AdminDashboard" className="btn btn-secondary mt-1 ml-2 btn-sm">Home</Link>
                                <button className="btn btn-info mt-1 ml-1 btn-sm" onClick={() => backbutton()}>back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="card cardUser shadow mb-4" >
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Role</h6>
                        {apiResponse && (
                                <div className="alert alert-success" role="alert">
                                    Role has been Assigned successfully!
                                </div>
                            )}
                    </div>
                    <div class="container">
                        <form onSubmit={save} class="form-horizontal mt-2" role="form">
                            <div class="form-group">
                                <label for="name" class="col-sm-3 required-label  control-label">Name</label>
                                <div class="col-sm-9">
                                    <input type="text" id="name" placeholder="Name" name='name' required class="form-control" autofocus />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="departmentId" class="col-sm-3 required-label control-label">Department</label>
                                <div class="col-sm-9">
                                {departmentError && <div className="alert alert-danger mt-3" role="alert">Please select a department.</div>}
                                    <select className="form-control form-control-fs form-control-user" id='departmentId' name="departmentId" onChange={handleInputStatus} required>
                                        <option>[NONE]</option>
                                        {
                                            department.map((e,key) => {
                                                //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;
                                                return <option key={e.depId} value={e.depId}>{e.name}</option>;
                                            })}
                                    </select>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary ml-2 mb-3">Save</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateRoleComponent