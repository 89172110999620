import React, { Component } from 'react' 
import CreateMeetingComponent from '../../../Components/Main/Meeting/CreateMeetingComponent';
import { useNavigate, useParams } from 'react-router-dom';


function CreateMeeting(props) {
    let navigate = useNavigate();
    let params = useParams();
    return (  
        <div> 
            <CreateMeetingComponent {...props} navigate={navigate} id={params.id}/>
        </div>
    );  
  }
  
export default CreateMeeting;  