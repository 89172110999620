import React, { useEffect, useState } from 'react'
import CustomerService from '../../../Services/Admin/Service';
import Service from '../../../Services/Admin/Service';
import { useDropzone } from 'react-dropzone';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { getLoginUserData } from '../../../Helpers/supportedFunctions/supportedGlobalFunctions';

function CreatePrePLComponent(props) {

    let id = sessionStorage.getItem('id');
    const [project, setProject] = useState([]);
    const [projectId, setProjectId] = useState('');
    const [projectError, setProjectError] = useState(false);
    const [projectManagerError, setProjectManagerError] = useState(false);
    const [detailFlag, setDetailFlag] = useState(false);
    const [currentDate, setCurrentDate] = useState('');
    const [element, setelement] = useState([]);

    const [vendor, setvendor] = useState([]);

    const [apiResponse, setApiResponse] = useState(null);

    const [apiError, setApiError] = useState(null);
    const [inputFields, setInputFields] = useState({
        id: 0,
        projectId: '',
        // date: "",
        projectHead: "Sushil bhatt",
        fair: "",
        projectManager: "",
        venue: "",
        fairDate: "",
        client: "",
        area: "",
        approverby: id,
        createdBy: id,
        updatedBy: id,
    })

    const [prePlStandConstructions, setprePlStandConstructions] = useState([{
        element: '',
        supplierid: '',
        currency: '',
        amount: '',
        paymentTerm: '',
    }]);

    const addprePlStandConstructions = () => {
        setprePlStandConstructions([...prePlStandConstructions, {
            element: '',
            supplierid: '',
            currency: '',
            amount: '',
            paymentTerm: '',
        }])

    }


    const handleprePlStandConstructions = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...prePlStandConstructions];
        list[index][name] = value;
        setprePlStandConstructions(list);
    }

    const [prePlServices, setprePlServices] = useState([{
        element: '',
        supplierid: '',
        currency: '',
        amount: '',
        paymentTerm: '',
    }]);

    const addprePlServices = () => {
        setprePlServices([...prePlServices, {
            element: '',
            supplierid: '',
            currency: '',
            amount: '',
            paymentTerm: '',
        }])

    }


    const handleprePlServices = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...prePlServices];
        list[index][name] = value;
        setprePlServices(list);
    }

    const [prePlShowServices, setprePlShowServices] = useState([{
        element: '',
        supplierid: '',
        currency: '',
        amount: '',
        paymentTerm: '',
    }]);

    const addprePlShowServices = () => {
        setprePlShowServices([...prePlShowServices, {
            element: '',
            supplierid: '',
            currency: '',
            amount: '',
            paymentTerm: '',
        }])

    }


    const handleprePlShowServices = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...prePlShowServices];
        list[index][name] = value;
        setprePlShowServices(list);
    }


    const [prePlManPowers, setprePlManPowers] = useState([{
        element: '',
        supplierid: '',
        currency: '',
        amount: '',
        paymentTerm: '',
    }]);

    const addprePlManPowers = () => {
        setprePlManPowers([...prePlManPowers, {
            element: '',
            supplierid: '',
            currency: '',
            amount: '',
            paymentTerm: '',
        }])

    }


    const handleprePlManPowers = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...prePlManPowers];
        list[index][name] = value;
        setprePlManPowers(list);
    }

    const [prePlOthers, setprePlOthers] = useState([{
        element: '',
        supplierid: '',
        currency: '',
        amount: '',
        paymentTerm: '',
    }]);

    const addprePlOthers = () => {
        setprePlOthers([...prePlOthers, {
            element: '',
            supplierid: '',
            currency: '',
            amount: '',
            paymentTerm: '',
        }])

    }


    const handleprePlOthers = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...prePlOthers];
        list[index][name] = value;
        setprePlOthers(list);
    }
    const [prePlTbls, setprePlTbls] = useState([{
        element: '',
        supplierid: '',
        currency: '',
        amount: '',
        paymentTerm: '',
    }]);

    const addprePlTbls = () => {
        setprePlTbls([...prePlTbls, {
            element: '',
            supplierid: '',
            currency: '',
            amount: '',
            paymentTerm: '',
        }])

    }


    const handleprePlTbls = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...prePlTbls];
        list[index][name] = value;
        setprePlTbls(list);
    }






    // const removeInputFields = (index) => {
    //     const rows = [...inputmultiFields];
    //     rows.splice(index, 1);
    //     setinputmultiFields(rows);
    // }




    useEffect(() => {
        getProjectData("Project/GetAll");
        getvendorData("Vendor/GetAll");
        getelementData("PrePL/GetAllPreElement");

        var d = props.name;
        if (d === "details") {
            setDetailFlag(true);
        }
        else if(d === "edit"){
            setDetailFlag(false);
        }

        if (props.id !== 0 && props.id !== undefined) {
            getprePLDatabyId("PrePL/GetById?id=" + props.id, '');
        }

        const today = new Date();

        const day = today.getDate();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours to 12-hour clock format
        const formattedHours = hours % 12 || 12;

        const formattedDate = `${year}-${month}-${day}`;
        const formattedTime = `${formattedHours}:${minutes}${ampm}`;

        const dateTimeString = `${formattedDate}`;
        setCurrentDate(dateTimeString);
    }, [])

    const getProjectData = (method) => {
        Service.getdata(method).then((res) => {
            setProject(res.data);
        });
    }

    const getprePLDatabyId = (method) => {
        Service.getdata(method).then((res) => {
            setInputFields(res.data);
            setprePlStandConstructions(res.data.prePlStandConstructions);
            setprePlServices(res.data.prePlServices);
            setprePlShowServices(res.data.prePlShowServices);
            setprePlManPowers(res.data.prePlManPowers);
            setprePlOthers(res.data.prePlOthers);
            setprePlTbls(res.data.prePlTbls);
            debugger
        });
    }

    useEffect(() => {
        if (apiResponse || apiError) {
            const timeoutId = setTimeout(removeSuccessMessage, 3000);

            return () => clearTimeout(timeoutId);
        }
    }, [apiResponse, apiError]);

    const removeSuccessMessage = () => {
        setApiResponse(null);
        setApiError(null);
    };

    const getelementData = (method) => {
        Service.getdata(method).then((res) => {
            setelement(res.data);

        });
    }

    const getvendorData = (method) => {
        Service.getdata(method).then((res) => {
            setvendor(res.data);
            debugger
        });
    }

    const backbutton = () => {
        props.navigate(-1);
    }

    const inputsHandler = (e) => {

        //  setInputField( {[e.target.name]: e.target.value} )
        const { name, value } = e.target;
        setInputFields((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const projectChange = (e) => {

        setProjectId(e.target.value);
    }

    const save = async (evt) => {
debugger
        if (evt && evt.preventDefault) {
            evt.preventDefault();
        }
        // const formData = new FormData(evt.target);

        // const data = {}
        // for (const [key, value] of formData) {
        //     data[key] = value;
        // }

        // function validateData(data) {

        //     const { projectId,  projectManager } = data;

        //     if ( projectManager === "[NONE]") {
        //         setProjectManagerError(true);
        //         return false;
        //     }


        //     if (!projectId || projectId === "[NONE]") {
        //         setProjectError(true);
        //         return false;
        //     } else {
        //         setProjectError(false);
        //     }
        //     return true;
        // }

        // if (!validateData(data)) {
        //     // The validation returned false, so stop execution here.
        //     // You can add any specific handling for invalid data here, if needed.
        //     return;
        // }

        const task = {
            ...inputFields,
            
            prePlStandConstructions,
            prePlShowServices,
            prePlServices,
            prePlManPowers,
            prePlOthers,
            prePlTbls,
            // date: currentDate,
        };
        console.log(task)
        debugger

        if (task) {
            Service.createorupdate("PrePL/CreateOrUpdate", task)
                .then(res => {
                    setApiResponse(res.data);
                    window.location.href = "/PrePLList";

                })
                .catch(error => {
                    console.error("API Error:", error);
                    setApiError("Error occurred while saving the pre pl . Please try again later.");
                });
        }
    }

    function formatDate(date) {
        if (!date) return ""; // Handle null or undefined dates
        const localDate = new Date(date);
        const year = localDate.getFullYear();
        const month = String(localDate.getMonth() + 1).padStart(2, '0');
        const day = String(localDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    // ===============================================================>
    const [uploadProgress, setUploadProgress] = useState(0);
    const [files, setFiles] = useState([]);
    const [uploadError, setUploadError] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [inputFileData, setinputFileData] = useState({
        typeId: 0,
        fileType: "PrePL",
        fileName: "",
        createdby: getLoginUserData().userId,
    })

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles);
            setUploadError(null);  // Reset error state when new files are added
            setUploadSuccess(false);
            // Reset success state when new files are added
        }
    });

    const handleUpload = async () => {
        if (files.length === 0) {
            setUploadError('No files selected for upload');
            return;
        }

        const formData = new FormData();
        files.forEach(file => {
            formData.append('files', file);
        });

        const data = {
            ...inputFileData,
            typeId: inputFields.id,
            fileName: files[0].name,
        };

        try {
            const response = await CustomerService.createorupdateFile("FileUpload/UploadFile", formData, (progress) => {
                setUploadProgress(progress);
            });

            if (response.status === 200) {
                const updateResponse = await CustomerService.createorupdate("FileUpload/CreateOrUpdate", data);
                if (updateResponse.status !== undefined) {
                    alert("File uploaded successfully");
                    window.location.reload();
                    setUploadSuccess(true);
                } else {
                    alert("File uploaded successfully but table not created");

                }
            } else {
                alert("Lead not created due to data mismatch, try again later");
            }
        } catch (error) {
            console.error("API Error during file upload:", error);
            setUploadError("File upload failed");
        }
    };

    const handleDownload = (file) => {
        debugger
        const fileName = file;

        // Change to the name of the file you want to download
        Service.downloadFile("FileUpload/download/", fileName)
            .then(res => {

                const url = window.URL.createObjectURL(new Blob([res.data], { type: res.data.type }));
                debugger
                if (res.data.type === 'application/pdf') {
                    window.open(url, '_blank');
                }
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(error => {
                // Handle API error, if necessary
                console.error("API Error:", error);

            });
    };

    //    ============================================================> 


    return (<>

        <div>
            <div className="container-fluid">

                <br />
                <div className="card shadow border-success mb-4">
                    <div className="card-header rounded py-3">
                        <div className="row">
                            <div className="col-sm-4">
                                {detailFlag === false ? (
                                    <h3><b className='text-dark'>Create Pre P&L </b></h3>
                                ) : (
                                    <h3><b className='text-dark'>Pre P&L Details</b></h3>
                                )}
                            </div>

                            <div className="col-sm-6"></div>
                            <div className="col-sm-2 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link> */}
                            </div>

                        </div>
                    </div>
                </div>
                <form onSubmit={save}>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-success">Pre P&L</h6>
                            {apiResponse && (
                                <div className="alert alert-success mt-2" role="alert">
                                    Pre P&L has been created successfully!
                                </div>
                            )}
                            {apiError && (
                                <div className="alert alert-danger" role="alert">
                                    {apiError}
                                </div>
                            )}
                        </div>

                        <div className="card-body" style={{ height: "auto" }}>
                            <div className='row text-dark'>
                                <div className='col-md-5 ml-5'>

                                    <div className="form-group row">
                                        <div className='col-sm-12 required-label'><b>Project</b></div>
                                        <div className='col-sm-12'>
                                            {projectError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a Project.</div>}
                                            <select className="form-control  form-control-user" onChange={inputsHandler} value={inputFields.projectId} readOnly={detailFlag} name="projectId"  >
                                                <option>[NONE]</option>
                                                {
                                                    project.map((e, key) => {
                                                        //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;
                                                        return <option key={e.id} value={e.id}>{e.projectname}</option>;
                                                    })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row d-flex">
                                        <div className='col-sm-5 '><b>Date</b></div>
                                        <div className='col-sm-3'> <b>{currentDate}</b>  
                                        <input type="date" hidden={true} onChange={inputsHandler} value={formatDate(inputFields.date)} readOnly={detailFlag} className="form-control form-control-user" id="headline"
                                            name="date" placeholder="Date"/></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 required-label'><b>Show</b></div>
                                        <div className='col-sm-12'><input type="text" value={inputFields.fair} onChange={inputsHandler} readOnly={detailFlag} className="form-control form-control-user" id="headline"
                                            name="fair" placeholder="enter Show" required/></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 required-label'><b>Venue</b></div>
                                        <div className='col-sm-12'><textarea
                                            rows={1} // You can adjust the number of visible rows
                                            cols={100}
                                            name='venue'
                                            readOnly={detailFlag}
                                            value={inputFields.venue}
                                            onChange={inputsHandler}
                                            placeholder='enter Venue' // You can adjust the number of visible columns
                                            class="form-control"
                                            autofocus
                                            required
                                        /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 required-label'><b>Area</b></div>
                                        <div className='col-sm-12'><input type="text" value={inputFields.area} onChange={inputsHandler} readOnly={detailFlag} className="form-control form-control-user" id="headline"
                                            name="area" placeholder="enter area in sqm." required /></div>
                                    </div>

                                </div>
                                <div className='col-md-1'></div>
                                <div className='col-md-5'>
                                    <div className="form-group row d-flex">
                                        <div className='col-sm-5 '><b>Project Head</b></div>
                                        <div className='col-sm-4'> <b>Sushil Bhatt</b>  <input type="text" value={"Sushil bhatt"} hidden={true} onChange={inputsHandler} readOnly={detailFlag} className="form-control form-control-user" id="headline"
                                            name="projectHead" placeholder="enter Project Head name " /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 required-label'><b>Project Manager</b></div>
                                        <div className='col-sm-12'>
                                            <select className="form-control  form-control-user" onChange={inputsHandler} value={inputFields.projectManager} readOnly={detailFlag} name="projectManager"  >
                                                <option>[NONE]</option>
                                                <option>Abhishek Srivastava</option>
                                                <option>Arun</option>
                                                <option>Sunil Kumar Sheel</option>
                                                <option>Mayank Kushwaha</option>
                                                <option>Vivaan Raghuvanshi</option>
                                                <option>Mohd. Shakil </option>
                                                <option>Vishal Chhibber</option>

                                            </select>

                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 required-label'><b>Show Date</b></div>
                                        <div className='col-sm-12'><input type="date" value={formatDate(inputFields.fairDate)} readOnly={detailFlag} onChange={inputsHandler} className="form-control form-control-user" id="headline"
                                            name="fairDate" placeholder="enter stall number" required /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 required-label'><b>Client</b></div>
                                        <div className='col-sm-12'><input type="text" value={inputFields.client} readOnly={detailFlag} onChange={inputsHandler} className="form-control form-control-user" id="headline"
                                            name="client" placeholder="enter Client" required /></div>
                                    </div>
                                    {/* Attachment start */}
                                  {(props.name === "edit" || props.name === "details") &&
                                  <>
                                  <div className="form-group row">
                                        <div className='col-sm-12'>
                                            <div {...getRootProps({ className: 'dropzone' })}>
                                                <input {...getInputProps()} />
                                                <p>Drag 'n' drop some files here, or click to select files</p>
                                            </div>
                                            <div>
                                                {files.length > 0 && (
                                                    <div>
                                                        <h4>Selected files:</h4>
                                                        <ul>
                                                            {files.map(file => (
                                                                <li key={file.path}>{file.path}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                           {props.name === "edit" && <span className='btn btn-success bg-success' type="button" onClick={handleUpload}>Upload</span>}
                                            <div>
                                                {(uploadProgress > 0 && uploadProgress < 100) && <progress value={uploadProgress} max="100">{uploadProgress}%</progress>}
                                                {(uploadProgress > 0 && uploadProgress < 100) && <div value={uploadProgress} max="100">{uploadProgress}%</div>}
                                            </div>
                                            {uploadError && <div className="error">{uploadError}</div>}
                                            {uploadSuccess && <div className="success">Files uploaded successfully!</div>}
                                        </div>
                                    </div>
                                    <div className="form-group d-flex flex-column">
                                        {
                                            inputFields.allfiles?.map((e, index) => (
                                                <div className="m-1" key={index}>
                                                    <div className='btn btn-sm  btn-secondary' type="submit" onClick={() => { handleDownload(e.fileName) }} ><i class="fa-regular fa-file-pdf mr-1"></i>{e.fileName}</div>
                                                </div>
                                            ))}
                                    </div>
                                    </>
                                    }
                                    {/* Attachment end */}
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className='col-md-12 ml-5'>
                                    <h5><b>Expenses</b></h5>
                                    <div className="form-group row">
                                        <div className="col-sm-12 required-label"><b>Stand and construction</b></div>
                                    </div>
                                    {
                                        prePlStandConstructions.map((data, index) => {
                                            const { element, supplierid, currency, amount, paymentterm } = data;




                                            return (
                                                <div>
                                                    <div className="form-group row" key={index}>

                                                        <div className='col-sm-2'>
                                                            <input type="text" onChange={(evnt) => handleprePlStandConstructions(index, evnt)} required readOnly={detailFlag} value={element} name="element" className="form-control" placeholder="Enter element" />
                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <select className="form-control  form-control-user" readOnly={detailFlag} required onChange={(evnt) => handleprePlStandConstructions(index, evnt)} name="supplierid" value={supplierid} >
                                                                <option>[Select Vendor]</option>
                                                                {
                                                                    vendor.map((e, key) => {
                                                                        //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;
                                                                        return <option key={e.id} value={e.id}>{e.supplierName}</option>;
                                                                    })}
                                                            </select>

                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <input type="text" onChange={(evnt) => handleprePlStandConstructions(index, evnt)} required readOnly={detailFlag} value={currency} name="currency" className="form-control" placeholder="Enter currency" />
                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <input type="text" onChange={(evnt) => handleprePlStandConstructions(index, evnt)} required readOnly={detailFlag} value={amount} name="amount" className="form-control" placeholder="enter amount" />
                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <input type="text" onChange={(evnt) => handleprePlStandConstructions(index, evnt)} required readOnly={detailFlag} value={paymentterm} name="paymentTerm" className="form-control" placeholder="enter payment term" />
                                                        </div>
                                                        <div className='col-sm-1'>
                                                            <span className="btn btn-outline-success " onClick={addprePlStandConstructions}>Add</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="form-group row">
                                        <div className="col-sm-12 required-label"><b>Services</b></div>
                                    </div>
                                    {
                                        prePlServices.map((data, index) => {
                                            const { element, supplierid, currency, amount, paymentterm } = data;



                                            return (
                                                <div>
                                                    <div className="form-group row" key={index}>

                                                        <div className='col-sm-2'>
                                                            <input type="text" onChange={(evnt) => handleprePlServices(index, evnt)} readOnly={detailFlag} required value={element} name="element" className="form-control" placeholder="Enter element" />
                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <select className="form-control  form-control-user" onChange={(evnt) => handleprePlServices(index, evnt)} required readOnly={detailFlag} name="supplierid" value={supplierid} >
                                                                <option>[Select Vendor]</option>
                                                                {
                                                                    vendor.map((e, key) => {
                                                                        //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;
                                                                        return <option key={e.id} value={e.id}>{e.supplierName}</option>;
                                                                    })}
                                                            </select>

                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <input type="text" onChange={(evnt) => handleprePlServices(index, evnt)} value={currency} readOnly={detailFlag} name="currency" className="form-control" placeholder="Enter currency" />
                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <input type="text" onChange={(evnt) => handleprePlServices(index, evnt)} value={amount} readOnly={detailFlag} name="amount" className="form-control" placeholder="enter amount" />
                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <input type="text" onChange={(evnt) => handleprePlServices(index, evnt)} value={paymentterm} readOnly={detailFlag} name="paymentTerm" className="form-control" placeholder="enter payment term" />
                                                        </div>
                                                        <div className='col-sm-1'>
                                                            <span className="btn btn-outline-success " onClick={addprePlServices}>Add</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="form-group row">
                                        <div className="col-sm-12 required-label"><b>Show Services</b></div>
                                    </div>
                                    {
                                        prePlShowServices.map((data, index) => {
                                            const { element, supplierid, currency, amount, paymentterm } = data;



                                            return (
                                                <div>
                                                    <div className="form-group row" key={index}>

                                                        <div className='col-sm-2'>
                                                            <input type="text" onChange={(evnt) => handleprePlShowServices(index, evnt)} readOnly={detailFlag} required value={element} name="element" className="form-control" placeholder="Enter element" />
                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <select className="form-control  form-control-user" onChange={(evnt) => handleprePlShowServices(index, evnt)} readOnly={detailFlag} name="supplierid" value={supplierid} required>
                                                                <option>[Select Vendor]</option>
                                                                {
                                                                    vendor.map((e, key) => {
                                                                        //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;
                                                                        return <option key={e.id} value={e.id}>{e.supplierName}</option>;
                                                                    })}
                                                            </select>

                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <input type="text" onChange={(evnt) => handleprePlShowServices(index, evnt)} value={currency} required readOnly={detailFlag} name="currency" className="form-control" placeholder="Enter currency" />
                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <input type="text" onChange={(evnt) => handleprePlShowServices(index, evnt)} value={amount} readOnly={detailFlag} name="amount" className="form-control" placeholder="enter amount" />
                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <input type="text" onChange={(evnt) => handleprePlShowServices(index, evnt)} value={paymentterm} readOnly={detailFlag} name="paymentTerm" className="form-control" placeholder="enter payment term" />
                                                        </div>
                                                        <div className='col-sm-1'>
                                                            <span className="btn btn-outline-success " onClick={addprePlShowServices}>Add</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="form-group row">
                                        <div className="col-sm-12 required-label"><b>Man power</b></div>
                                    </div>
                                    {
                                        prePlManPowers.map((data, index) => {
                                            const { element, supplierid, currency, amount, paymentterm } = data;



                                            return (
                                                <div>
                                                    <div className="form-group row" key={index}>

                                                        <div className='col-sm-2'>
                                                            <input type="text" onChange={(evnt) => handleprePlManPowers(index, evnt)} readOnly={detailFlag} required value={element} name="element" className="form-control" placeholder="Enter element" />
                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <select className="form-control  form-control-user" onChange={(evnt) => handleprePlManPowers(index, evnt)} required readOnly={detailFlag} name="supplierid" value={supplierid} >
                                                                <option>[Select Vendor]</option>
                                                                {
                                                                    vendor.map((e, key) => {
                                                                        //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;
                                                                        return <option key={e.id} value={e.id}>{e.supplierName}</option>;
                                                                    })}
                                                            </select>

                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <input type="text" onChange={(evnt) => handleprePlManPowers(index, evnt)} value={currency} name="currency" readOnly={detailFlag} className="form-control" placeholder="Enter currency" />
                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <input type="text" onChange={(evnt) => handleprePlManPowers(index, evnt)} value={amount} name="amount" readOnly={detailFlag} className="form-control" placeholder="enter amount" />
                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <input type="text" onChange={(evnt) => handleprePlManPowers(index, evnt)} value={paymentterm} name="paymentTerm" readOnly={detailFlag} className="form-control" placeholder="enter payment term" />
                                                        </div>
                                                        <div className='col-sm-1'>
                                                            <span className="btn btn-outline-success " onClick={addprePlManPowers}>Add</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="form-group row">
                                        <div className="col-sm-12 required-label"><b>Others</b></div>
                                    </div>
                                    {
                                        prePlOthers.map((data, index) => {
                                            const { element, supplierid, currency, amount, paymentterm } = data;



                                            return (
                                                <div>
                                                    <div className="form-group row" key={index}>

                                                        <div className='col-sm-2'>
                                                            <input type="text" onChange={(evnt) => handleprePlOthers(index, evnt)} readOnly={detailFlag} required value={element} name="element" className="form-control" placeholder="Enter element" />
                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <select className="form-control  form-control-user" onChange={(evnt) => handleprePlOthers(index, evnt)} required readOnly={detailFlag} name="supplierid" value={supplierid} >
                                                                <option>[Select Vendor]</option>
                                                                {
                                                                    vendor.map((e, key) => {
                                                                        //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;
                                                                        return <option key={e.id} value={e.id}>{e.supplierName}</option>;
                                                                    })}
                                                            </select>

                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <input type="text" onChange={(evnt) => handleprePlOthers(index, evnt)} value={currency} readOnly={detailFlag} name="currency" className="form-control" placeholder="Enter currency" />
                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <input type="text" onChange={(evnt) => handleprePlOthers(index, evnt)} value={amount} name="amount" readOnly={detailFlag} className="form-control" placeholder="enter amount" />
                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <input type="text" onChange={(evnt) => handleprePlOthers(index, evnt)} value={paymentterm} name="paymentTerm" readOnly={detailFlag} className="form-control" placeholder="enter payment term" />
                                                        </div>
                                                        <div className='col-sm-1'>
                                                            <span className="btn btn-outline-success " onClick={addprePlOthers}>Add</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                    <div className="form-group row">
                                        <div className="col-sm-12 required-label"><b>Tbl</b></div>
                                    </div>
                                    {
                                        prePlTbls.map((data, index) => {
                                            const { element, supplierid, currency, amount, paymentterm } = data;
                                            return (
                                                <div>
                                                    <div className="form-group row" key={index}>

                                                        <div className='col-sm-2'>
                                                            <input type="text" onChange={(evnt) => handleprePlTbls(index, evnt)} readOnly={detailFlag} required value={element} name="element" className="form-control" placeholder="Enter element" />
                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <select className="form-control  form-control-user" onChange={(evnt) => handleprePlTbls(index, evnt)} required readOnly={detailFlag} name="supplierid" value={supplierid} >
                                                                <option>[Select Vendor]</option>
                                                                {
                                                                    vendor.map((e, key) => {
                                                                        //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;
                                                                        return <option key={e.id} value={e.id}>{e.supplierName}</option>;
                                                                    })}
                                                            </select>

                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <input type="text" onChange={(evnt) => handleprePlTbls(index, evnt)} value={currency} readOnly={detailFlag} name="currency" className="form-control" placeholder="Enter currency" />
                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <input type="text" onChange={(evnt) => handleprePlTbls(index, evnt)} value={amount} readOnly={detailFlag} name="amount" className="form-control" placeholder="enter amount" />
                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <input type="text" onChange={(evnt) => handleprePlTbls(index, evnt)} value={paymentterm} readOnly={detailFlag} name="paymentTerm" className="form-control" placeholder="enter payment term" />
                                                        </div>
                                                        <div className='col-sm-1'>
                                                            <span className="btn btn-outline-success " onClick={addprePlTbls}>Add</span>
                                                        </div>
                                                    </div>
                                                </div>

                                            )
                                        })
                                    }
                                    {apiResponse && (
                                        <div className="alert alert-success mt-2" role="alert">
                                            Pre P&L has been created successfully!
                                        </div>
                                    )}
                                    {apiError && (
                                        <div className="alert alert-danger" role="alert">
                                            {apiError}
                                        </div>
                                    )}
                                    {detailFlag === false && <button className="btn btn-primary" type='submit'>Save</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>);
}
export default CreatePrePLComponent