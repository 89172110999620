import React, { useEffect, useState } from 'react'
import Service from '../../../Services/Admin/Service';
import BootstrapTable from 'react-bootstrap-table-next';
import { convertToRaw, EditorState, convertFromHTML, ContentState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Link } from 'react-router-dom';
import { getLoginUserData } from '../../../Helpers/supportedFunctions/supportedGlobalFunctions';

const MeetingDetailsComponent = (props) => {

    const [data, setData] = useState([]);
    const [userId, setUserId] = useState([]);
    const [location, setLocation] = useState('');
    const [kind, setKind] = useState('');
    const [lead, setLead] = useState([]);
    const [Projectdata, setProjectdata] = useState([]);
    const [rfpData, setRFPData] = useState([]);
    const [show, setShow] = useState([]);
    const [showId, setShowId] = useState([]);
    const [agendaDetails, setAgendaDetails] = useState([]);
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const id = props.id;
    const userid = sessionStorage.getItem("id");
    const deptId = sessionStorage.getItem("DeptId");
    useEffect(() => {
        getLeadData("Lead/GetAllLead");
        getShowData("Shows/GetAllShows");
        getProjectData("Project/GetAll");
        getRFPData("RFP/GetAll?id=1");
        getMeetingDetails("Meeting/GetById?id=" + id);
        updatenotification("Questionare/readnotification?meetingId=" + props.id + "&userid=" + userid)

    }, []);

    const getMeetingDetails = (method) => {
        Service.getdatabyId(method, '').then((res) => {
            setData(res.data);
            console.log(res.data);
            debugger
            setShowId(res.data.showid);
            setUserId(res.data.createdby)
            setLocation(res.data.location);
            setKind(res.data.kind);
            // const content1 = convertFromRaw(JSON.parse(res.data.meetingMinutes));
            const content1 = convertFromRaw(JSON.parse(res.data.meetingMinutes));
            setEditorState(EditorState.createWithContent(content1));
        });
    }

    const updatenotification = (method) => {
        debugger
        Service.getdata(method).then((res) => {
            console.log(res.data);
        });
    }

    const inputmultiFields = data[0];

    const getShowData = (method) => {
        Service.getdata(method).then((res) => {
            setShow(res.data);
        });
    }

    const getLeadData = (method) => {
        Service.getdata(method).then((res) => {
            setLead(res.data);
        });
    }

    const getProjectData = (method) => {
        Service.getdata(method).then((res) => {
            setProjectdata(res.data);
        });
    }

    const getRFPData = (method) => {
        Service.getdata(method).then((res) => {
            setRFPData(res.data);
        });
    }


    const handleEditorChange = (state) => {
        console.log('New Editor State:', state)
        setEditorState(state);
    }


    const backbutton = () => {
        props.navigate(-1);
    }

    function formatDate(dateString) {
        const dateObject = new Date(dateString);

        const day = String(dateObject.getDate()).padStart(2, '0');
        const month = String(dateObject.getMonth() + 1).padStart(2, '0');
        const year = dateObject.getFullYear();

        return `${day}-${month}-${year}`;
    }

    const formatDateTime = (cell, row) => {
        // Format the date and time here, assuming 'time' is a string in ISO 8601 format
        const formattedDate = new Date(cell).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',

        });
        return formattedDate;
    }

    return (
        <div>
            <div className="container-fluid">

                <div className="card shadow border-success mb-2">
                    <div className="card-header rounded py-3">
                        <div className="row">
                            <div class="d-flex flex-start align-items-center">
                               
                            </div>
                            <div className="col-sm-6"><h2><b className='text-dark'>Meeting Details</b></h2></div>
                            <div className="col-sm-4"></div>
                            <div className="col-sm-2 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid text-dark">
                <div className="card cardUser shadow mb-4" >
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-success">Details #{data.id}</h6>
                    </div>

                    <div class="container">
                        <form class="form-horizontal mt-2 text-dark">
                            <div class="form-group row">
                                <label for="topic" class="col-sm-3 required-label  control-label"><b>Topic</b></label>
                                <div class="col-sm-4">
                                    <b>
                                        {data.topic}
                                    </b>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="Meeting Time" className="col-sm-3 required-label control-label" ><b>Meeting Time</b></label>
                                <div className="col-sm-9">
                                    <b>
                                        {formatDateTime(data.time)}
                                    </b>
                                </div>
                            </div>

                            
                             {getLoginUserData().deptId === "4" &&   
                             <div className="form-group  row">
                                    <label for="topic" class="col-sm-3 required-label  control-label"><b>Project</b></label>
                                    <div className='col-sm-4'>
                                        <select className="form-control form-control-fs  form-control-user" readOnly value={data.projectId} name='projectId' required>
                                            <option>[NONE]</option>
                                            {
                                                Projectdata.map((e, key) => {
                                                    return <option key={e.id} value={e.id}>{e.projectname}</option>;
                                                })}
                                        </select>
                                    </div>
                                </div>}


                            {data.leadid === "0" && data.projectId === 0 && (
                                <div className="form-group  row">
                                    <label for="topic" class="col-sm-3 required-label  control-label"><b>Show List</b></label>
                                    <div className='col-sm-4'>
                                        <select className="form-control form-control-fs  form-control-user" readOnly value={showId} name='showId' required>
                                            <option>[NONE]</option>
                                            {
                                                show.map((e, key) => {
                                                    return <option key={e.showChapterId} value={e.showChapterId}>{e.chapterName}</option>;
                                                })}
                                        </select>
                                    </div>
                                </div>)}

                                {(getLoginUserData().deptId ==="3" || getLoginUserData.deptId === "2") && (
                                <div className="form-group  row">
                                    <label for="topic" class="col-sm-3 required-label  control-label"><b>RFP</b></label>
                                    <div className='col-sm-4'>
                                        <select className="form-control form-control-fs  form-control-user" readOnly value={data.projectId} name='showId' required>
                                            <option>[NONE]</option>
                                            {
                                                rfpData.map((e, key) => {
                                                    return <option key={e.id} value={e.id}>{e.leadid}</option>;
                                                })}
                                        </select>
                                    </div>
                                </div>)}

                            {/* Conditionally render MyLead component */}
                            {data.showid === 0 && data.projectId === 0 && (
                                <div className="form-group  row">
                                    <label for="topic" class="col-sm-3 required-label  control-label"><b>Lead List</b></label>
                                    <div className='col-sm-4'>
                                        <select className="form-control form-control-fs  form-control-user" readOnly value={data.leadid} name='leadid' required>
                                            <option>[NONE]</option>
                                            {
                                                lead.map((e, key) => {
                                                    return <option key={e.id} value={e.id}>{e.leadid}</option>;
                                                })}
                                        </select>
                                    </div>
                                </div>
                            )}
                            <div class="form-group row">
                                <label for="field" class="col-sm-3 required-label  control-label"><b>Location</b></label>
                                <div class="col-sm-4">
                                    <b>{location}</b>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label for="topic" class="col-sm-3 required-label  control-label"><b>Meeting Kind</b></label>

                                <div className='col-sm-4'>
                                    <select className="form-control  form-control-user" value={kind} readOnly name="kind" required>
                                        <option>[NONE]</option>
                                        <option>Virtual</option>
                                        <option>In person</option>

                                    </select>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="meetingMinutes" class="col-sm-12 required-label  control-label"><b>Meeting Minutes</b></label>
                                <div class="col-sm-7">
                                    <Editor
                                        readOnly
                                        editorState={editorState}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        onEditorStateChange={handleEditorChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label for="details" class="col-sm-3 required-label  control-label"><b>List of Meeting Members</b></label>
                                <div className="col-sm-9">
                                    {
                                        data.lstmeetingMembers?.map((e) => (

                                            <b>{e.firstname}, &nbsp; </b>

                                        ))}
                                </div>
                            </div>

                            <div className="form-group row">
                                <label for="details" className="col-sm-3 required-label control-label"><b>Created By</b></label>
                                <div className="col-sm-9">
                                    {
                                        data.lstmeetingMembers?.map((e) => (
                                            e.id === userId && ( // Check if the user's ID matches the desired ID
                                                <div key={e.id}>
                                                    <b>
                                                        {e.firstname} {e.lastname}
                                                    </b>
                                                </div>
                                            )
                                        ))
                                    }
                                </div>
                            </div>

                            {<div className="form-group row">
                                <label for="details" class="ml-1 required-label  control-label"><b>Agenda Details :</b></label>
                                <div class="ml-2">
                                    <b>
                                        {
                                            data.inputmultiFields?.map((e) => (
                                                <div>
                                                    <b>
                                                        {e.agenda}
                                                    </b>
                                                </div>
                                            ))}
                                    </b>
                                </div>
                                <label for="details" class="ml-1 required-label  control-label"><b>Agenda Decision :</b></label>
                                <div class="ml-2">
                                    <b>
                                        {
                                            data.inputmultiFields?.map((e) => (
                                                <div>
                                                    <b>
                                                        {e.decision}
                                                    </b>
                                                </div>
                                            ))}

                                    </b>
                                </div>
                                <label for="details" class="ml-1 required-label  control-label"><b>Date:</b></label>
                                <div class="ml-2">
                                    <b>
                                        {
                                            data.inputmultiFields?.map((e) => (
                                                <div>
                                                    <b>
                                                        {e.targetdate ? formatDate(e.targetdate) : ""}
                                                    </b>
                                                </div>
                                            ))}
                                    </b>
                                </div>
                                <label for="details" class="ml-1 required-label  control-label"><b>Responsibility:</b></label>
                                <div class="ml-2">
                                    <b>
                                        {
                                            data.inputmultiFields?.map((e) => (
                                                <div>
                                                    <b>
                                                        {e.responsiblity}
                                                    </b>
                                                </div>
                                            ))}
                                    </b>
                                </div>
                            </div>}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MeetingDetailsComponent