import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import { convertToRaw, EditorState, convertFromHTML, ContentState, convertFromRaw, getDefaultKeyBinding } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { getCurrentDate } from '../../../Helpers/supportedFunctions/supportedGlobalFunctions';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Service from '../../../Services/Admin/Service';
import draftToHtml from 'draftjs-to-html';
import Select from 'react-select';
import { Link } from 'react-router-dom';

const CreateMultiTaskComponent = (props) => {
  var dept = props.name;

  let id = sessionStorage.getItem('id');
  const userId = sessionStorage.getItem("id");
  let DeptId = sessionStorage.getItem('DeptId');
  var role = sessionStorage.getItem("role")

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [project, setProject] = useState([]);
  const [loading, setLoading] = useState(false);

  const [projectId, setProjectId] = useState([]);
  const [projectError, setProjectError] = useState(false);
  const [status, setStatus] = useState([]);
  const [taskPriority, setTaskPriority] = useState([]);
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [dropdownUser, setdropdownUser] = useState([]);
  const [dropdownTaskType, setdropdownTaskType] = useState([]);
  const [editorContentValid, setEditorContentValid] = useState(true);
  const [loginUser, setLoginUser] = useState([]);
  const [apiResponse, setApiResponse] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [inputField, setInputField] = useState({
    projectId: '',
    headline: '',
    assignerid: userId,
    createdby: userId,

  })
  const [inputCalendar, setInputCalendar] = useState(
    {
      summary: "",
      description: "",
      startTime: "",
      endTime: "",
      calendarId: "sop@xsproductions.com",
      refreshToken: "1//0gkWVmDAlyPkGCgYIARAAGBASNwF-L9Irz9KGiZeCkJMbqqXSTdlCky2cDgLR866ieBZs7Uwie0VBZZXQekor3AGqaieyoOCCvbM",
      users: [
        ""
      ]
    }
  );
  // const params = useSearchParams();
  const [serachParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    getUserInfo("Users/GetById?id=" + id)
    getStatusData("Status/GetAll");
    getUserData("Users/GetAll");
    getProjectData("Project/GetAll");
    getTaskPriority("Priority/GetAll")
    getTaskTypeData("TaskType/GetAll");

    var projectId = serachParams.get("projectId")
    debugger
    if (projectId !== null) {
      //  window.location.href = '/KanbanAssignedTask';
      setApiResponse(true);
      setTimeout(() => {
        window.location.href = '/KanbanAssignedTask';

      }, 3000);

    }

  }, [])

  const getTaskPriority = (method) => {
    Service.getdata(method).then((res) => {
      setTaskPriority(res.data);
    });
  }

  const getStatusData = (method) => {
    Service.getdata(method).then((res) => {
      setStatus(res.data);
    });
  }


  const getUserData = (method) => {
    Service.getdata(method).then((res) => {
      var data = res.data;
      var intdeptId = parseInt(DeptId)
      var list = [];

      if ((dept !== "Marketing")) {
        data.forEach((d, index) => {
          var role = d.lstRole;

          var checkdepartment = role.find(x => x.depId === 4)
          if (checkdepartment !== undefined) {
            d.rolename = d.lstRole[0].name;
            list.push(d);
          }
          setdropdownUser(list);
        });
      }

      else {

        data.forEach((d, index) => {
          var role = d.lstRole;
          var checkdepartment = role.find(x => x.depId === 1)
          if (checkdepartment !== undefined) {
            d.rolename = d.lstRole[0].name;
            list.push(d);
          }
        });
        setdropdownUser(list);
      }

    });
  }






  const handleKeyPress = (event, callback) => {
    // Check if the key pressed is Enter (key code 13)
    if (event.key === 'Enter') {
      // Prevent the default behavior of the Enter key (form submission)
      event.preventDefault();
      // Invoke the callback function (e.g., addplatforming or removeplatforming)
      callback();
    }
  };

  const [inputmultiFields, setinputmultiFields] = useState([{
    typeId: '',
    priorityId: '',
    assigneeid: '',
    finishdate: '',
    statusId: '',
  }]);

  const removeInputField = (index) => {
    const rows = [...inputmultiFields];
    rows.splice(index, 1);
    setinputmultiFields(rows);
  }
  const addInputField = () => {
    setinputmultiFields([...inputmultiFields, {
      typeId: '',
      priorityId: '',
      assigneeid: '',
      finishdate: '',
      statusId: '',
    }])
  }
  const handleChange = (index, evnt) => {
    debugger
    const { name, value } = evnt.target;
    const list = [...inputmultiFields];
    list[index][name] = value;
    setinputmultiFields(list);
  }

  const handleChangeUser = (index, evnt) => {
    debugger
    // const { name, value } = evnt;
    const list = [...inputmultiFields];
    list[index]["assigneeid"] = evnt;
    setinputmultiFields(list);
  }

  //   const handleSelectChange = (selectedOptions) => {
  // debugger
  //     setSelectedAssignees(selectedOptions);
  //   };// Avinash

  const handleEditorChange = (state) => {
    setEditorState(state);
    setEditorContentValid(true);
  }


  const getProjectData = (method) => {
    Service.getdata(method).then((res) => {
      setProject(res.data);
    });
  }

  const getUserInfo = (method) => {
    Service.getdata(method).then((res) => {
      setLoginUser(res.data);
      debugger
    });
  }

  useEffect(() => {
    if (apiResponse || apiError) {
      const timeoutId = setTimeout(removeSuccessMessage, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [apiResponse, apiError]);

  const removeSuccessMessage = () => {
    setApiResponse(null);
    setApiError(null);
  };

  const backbutton = () => {
    props.navigate(-1);
  }

  const getTaskTypeData = (method) => {
    Service.getdata(method).then((res) => {
      debugger
      var intdeptId = parseInt(DeptId)
      var data = res.data
      if (intdeptId === null) {
        setdropdownTaskType(tasktype);
      } else {
        var tasktype = []
        tasktype = data.filter(x => x.deptId === 4)
        setdropdownTaskType(tasktype);
      }

    });
  }



  const save = (evt) => {
    setLoading(true);
    const currentContent = editorState.getCurrentContent();
    const messageBody = convertToRaw(currentContent);
    var jsonData = JSON.stringify(messageBody);
    const extractedText = messageBody.blocks.length > 0 ? messageBody.blocks[0].text : '';
    console.log(extractedText)
    var multiField = inputmultiFields;
    multiField.forEach(element => {
      debugger;
      var task = {
        headline: inputField.headline,
        shortDescription: jsonData,
        projectId: inputField.projectId,
        typeId: element.typeId,
        finishdate: element.finishdate,
        statusId: 1,
        priorityId: element.priorityId,
        createdby: inputField.createdby,
        isRequested: false,
        assignerid: inputField.assignerid,
        assigneeid: element.assigneeid.map((assignee) => assignee.value),
      }
      console.log(task)

      var usersEmail = element.assigneeid.map((assignee) => assignee.email)
      debugger
      var date1 = new Date(task.finishdate);
      date1.setHours(18, 0, 0, 0);

      const calendarTask = {
        ...inputCalendar,
        description: extractedText,
        summary: inputField.headline,
        startTime: new Date().toISOString(),
        endTime: date1.toISOString(),
        users:
          usersEmail,
      }
      console.log(calendarTask);
      debugger
      if (task) {
        Service.createorupdate("Task/CreateOrUpdate", task)
          .then(res => {
            if (calendarTask) {
              Service.createorupdate("user/calendarevent", calendarTask)
                .then(res => {
                  if (res.data) {
                    setLoading(false);
                  }
                  setApiResponse(res.data); // Set the API response in state
                  //   window.location.href = "/KanbanAssignedTask";
                })
                .catch(error => {
                  // Handle error if API call fails
                  console.error("API Error:", error);
                  setApiError("Error occurred while saving the task. Please try again later.");
                });
            }
            if (res.data) {
              setLoading(false);
            }
            //Set the API response in state
            window.location.href = "/KanbanAssignedTask";
          })
          .catch(error => {
            // Handle error if API call fails
            console.error("API Error:", error);
            setApiError("Error occurred while saving the task. Please try again later.");

          });
      }

    });
    // setTimeout(() => {
    //   window.location.href = '/KanbanAssignedTask';
    // }, 30000); 


  }

  const projectChange = (e) => {
    setProjectId(e.target.value);
  }

  const inputsHandler = (e) => {
    //  setInputField( {[e.target.name]: e.target.value} )
    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  return (
    <div>
      <div className="container-fluid">

        <div className="card shadow border-success mb-4">
          <div className="card-header rounded py-3">
            <div className="row">
              <div class="d-flex flex-start align-items-center">

              </div>
              <div className="col-sm-4"><h2><b>Create Multi Task</b></h2></div>
              <div className="col-sm-6"></div>
              <div className="col-sm-2 d-flex flex-row-reverse">
                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2">Home</Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="card cardUser shadow mb-4" >
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">Task Info</h6>
            {apiResponse && (
              <div className="alert alert-success" role="alert">
                Task has been Assigned successfully!
              </div>
            )}
            {apiError && (
              <div className="alert alert-danger" role="alert">
                {apiError}
              </div>
            )}
          </div>


          <form onSubmit={save} class="form-horizontal mt-2">
            <div className="form-group ">
              <div className='col-sm-12 required-label'><b>Project Name</b></div>
              <div className='col-sm-12'>
                {projectError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a Project.</div>}
                <select className="form-control  form-control-user" value={inputField.projectId} onChange={inputsHandler} name="projectId" required>
                  <option>[NONE]</option>
                  {
                    project.map((e, key) => {
                      //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;
                      return <option key={e.id} value={e.id}>{e.projectname}</option>;
                    })}
                </select>
              </div>
            </div>
            <div class="form-group" >
              <label for="field" class="col-sm-3 required-label  control-label"><b>Task Headline</b></label>
              <div className='col-sm-12'><input type="text" className="form-control form-control-user" value={inputField.headline} id="headline" onChange={inputsHandler}
                name="headline" placeholder="Enter Task headline" required /></div>
            </div>

            <div class="form-group d-flex">
              <label for="field" class="col-sm-3 required-label  control-label"><b>Author</b> </label>
              <div className='col-sm-6'>
                <b>{loginUser.firstname} {loginUser.lastname} </b>
              </div>
            </div>

            <div className="form-group ">
              <div className="col-sm-11 required-label"><b>Description</b></div>
              <div className='col-sm-12 mr-1 '>

                {!editorContentValid && (
                  <div className="alert alert-danger ml-3 pl-2 mt-2" role="alert">
                    Project Description is required.
                  </div>
                )}
                <Editor
                  editorState={editorState}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  onEditorStateChange={handleEditorChange}
                  required
                />
              </div>

            </div>
            <div className="form-group d-flex">
              <div className='col-sm-3 ml-2'><b>Work Requirement</b></div>
              <div className='col-sm-2 ml-2'><b>Priority</b></div>
              <div className='col-sm-2 ml-2'><b>Assign Too</b></div>
              <div className='col-sm-2 ml-2'><b>Due Date</b></div>
              <div className='col-sm-2 ml-2'><b>Status</b></div>
            </div>
            <div className='ml-3' style={{}}>
              {/* <div className="form-group row">
                  <div className="col-sm-12 required-label ml-3">Agenda Topic of discussion</div>
                </div> */}
              {
                inputmultiFields.map((data, index) => {
                  const { typeId, priorityId, assigneeid, finishdate, statusId } = data;
                  // const formattedDate = targetdate ? targetdate.split('T')[0] : '';


                  return (
                    <div>

                      <div className="form-group row" key={index}>

                        <div className='col-sm-3'>
                          {/* <input type="text" onChange={(evnt) => handleChange(index, evnt)} value={agenda} name="agenda" className="form-control" placeholder="Work Requirement" /> */}
                          <select className="form-control" name="typeId" value={typeId} onChange={(evnt) => handleChange(index, evnt)} required>
                            <option>[NONE]</option>
                            {
                              dropdownTaskType.map((e, key) => {
                                return <option key={e.id} value={e.id}>{e.taskname}</option>;
                              })}
                          </select>
                        </div>
                        <div className='col-sm-2'>
                          {/* <input type="text" onChange={(evnt) => handleChange(index, evnt)} value={decision} name="decision" className="form-control" placeholder="Enter decision" /> */}
                          <select className="form-control" name="priorityId" value={priorityId} onChange={(evnt) => handleChange(index, evnt)} required>
                            <option>[NONE]</option>
                            {
                              taskPriority.map((e, key) => {
                                return <option key={e.priorityId} value={e.priorityId}>{e.name}</option>;
                              })}
                          </select>
                        </div>
                        <div className='col-sm-2'>
                          {/* <input type="text" onChange={(evnt) => handleChange(index, evnt)} value={responsiblity} name="responsiblity" className="form-control" placeholder="Enter responsiblity" /> */}
                          <Select
                            name="assigneeid"
                            isMulti={true}
                            placeholder="Enter Assignee"
                            options={dropdownUser.map((user) => ({
                              value: user.id,
                              label: `${user.firstname} ${user.lastname} - ${user.rolename}`,
                              email: user.email,
                            }))}
                            value={assigneeid}
                            //  onChange={handleSelectChange}
                            onChange={(evnt) => handleChangeUser(index, evnt)}
                          />
                        </div>
                        <div className='col-sm-2'>
                          <input type="date" onChange={(evnt) => handleChange(index, evnt)} value={finishdate} name="finishdate" min={getCurrentDate()} className="form-control" />
                        </div>
                        <div className='col-sm-1'>
                          {/* <input type="text" onChange={(evnt) => handleChange(index, evnt)} value={decision} name="decision" className="form-control" placeholder="Enter decision" /> */}
                          <select className="form-control" readOnly value={1} name="statusId" required>
                            <option>[NONE]</option>
                            {
                              status.map((e, key) => {
                                //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;
                                return <option key={e.statusId} value={e.statusId}>{e.name}</option>;
                              })}
                          </select>
                        </div>
                        <div className='col-sm-1'>
                          <span className="btn btn-outline-success " tabIndex="0" onClick={addInputField}>Add</span>
                        </div>
                        <div className="col-sm-1">
                          {(inputmultiFields.length !== 1) ?
                            <span className="btn btn-outline-danger"
                              tabIndex="0"
                              // onClick={(evnt) => removeInputField(index, evnt)}
                              onClick={(evnt) => removeInputField(index, evnt)}
                              onKeyDown={(evnt) => handleKeyPress(evnt, () => removeInputField(index, evnt))}
                            >
                              x
                            </span>
                            : ''}
                        </div>

                      </div>

                    </div>
                  )
                })
              }
            </div>
            <div className="col-sm-4">
            </div>

            {/* {apiResponse && (
              <div className="alert alert-success" role="alert">
                Tasks has been Assigned successfully!
              </div>
            )}
            {apiError && (
              <div className="alert alert-danger" role="alert">
                {apiError}
              </div>
            )} */}
            {loading === false && <h6 className="font-weight-bold text-primary">{ }</h6>}{loading === true &&
              <div class=" text-primary">
                <strong>Saving...</strong>
                <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
              </div>
            }
            <button type="submit" class="btn btn-primary ml-3 mt-3 mb-3">Save</button>
          </form>
        </div>

      </div>
    </div>
  )
}


export default CreateMultiTaskComponent