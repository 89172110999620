import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import FeedbackDashboardComponent from '../../../Components/Main/AdminPanelComponent/FeedbackDashboardComponent';

function FeedbackDashboard(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <FeedbackDashboardComponent {...props} navigate={navigate} id={params.id} />
        </div>
    );  
  }
export default FeedbackDashboard  