import React, { useEffect, useState } from 'react'
import Service from '../../../Services/Admin/Service';
import { Link } from 'react-router-dom';
import { formatDate, getLoginUserData } from '../../../Helpers/supportedFunctions/supportedGlobalFunctions';

const CreateSelectVendorComponent = (props) => {
  debugger
  const [projectList, setProjectList] = useState([]);
  const [apiResponse, setApiResponse] = useState(null);
  const [detailFlag, setdetailFlag] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [projectId, setProjectId] = useState([]);
  const [inputField, setInputField] = useState({
    id: 0,
    vendorid: 0,
    projectid: 0,
    cost: "",
    isCostFinal: false,
    createdby: getLoginUserData().userId
  })

  //api response
  useEffect(() => {
    if (apiResponse || apiError) {
      const timeoutId = setTimeout(removeSuccessMessage, 3000);
      // window.location.href = "/MeetingList";
      return () => clearTimeout(timeoutId);
    }
  }, [apiResponse, apiError]);

  const removeSuccessMessage = () => {
    setApiResponse(null);
    setApiError(null);
  };
  //api response


  useEffect(() => {
    getProjectData("Project/GetAll");
    getVendorList("Vendor/GetAll");
    getSelectdVendorList("SelectVendor/GetByProjectID?id=" + props.id);
    if (props.name === "details") {
      setdetailFlag(true)
    }
  }, [])

  const getProjectData = (method) => {
    Service.getdata(method, '').then((res) => {
      setProjectList(res.data)

    });
  }

  const getVendorList = (method) => {
    Service.getdata(method, '').then((res) => {
      setVendorList(res.data)

    });
  }

  const getSelectdVendorList = (method) => {
    Service.getdata(method, '').then((res) => {
      setInputmultiField(res.data)
      var t = res.data;
      setSelectedVendor(t.filter((x) => x.isCostFinal === true));
      setInputField(...res.data);
      setProjectId(parseInt(props.id));
      debugger
    });
  }


  const handleProjectChange = (evt) => {
    setProjectId(evt.target.value);
    var projectId = projectList.filter(x => x.id = evt.target.value);

    if (projectId.length !== 0) {
      getProjectDataByid("Project/GetById?id=", projectId[0].id);
    }
  }

  const save = (evt) => {
    evt.preventDefault();
    const data = [];
    for (var i = 0; i < inputmultiField.length; i++) {

      data.push({
        ...inputField,
        projectid: projectId,
        id: inputmultiField[i].id,
        isCostFinal: (/true/).test(inputmultiField[i].isCostFinal),
        cost: inputmultiField[i].cost,
        vendorid: inputmultiField[i].vendorid,

      })
      console.log(data);
      debugger
    }

    console.log(data);
    debugger
    if (data.length !== 0) {
      for (i = 0; i < inputmultiField.length; i++) {
        Service.createorupdate("SelectVendor/CreateOrUpdate", data[i])
          .then(res => {

            setApiResponse(res.data);

          })
          .catch(error => {
            // Handle API error, if necessary
            console.error("API Error:", error);
            setApiError("Error occurred. Please try again later.");
          });
      }
      window.location.reload(true);

    }


  }


  const getProjectDataByid = (method, id) => {
    Service.getdatabyId(method, id).then((res) => {
      console.log(res.data);

      setInputField((prevState) => ({
        ...prevState,
        ["startDate"]: res.data.startdate,
        ["endDate"]: res.data.enddate,
        ["venue"]: res.data.venue,
      }));


    });
  }

  const [inputmultiField, setInputmultiField] = useState([{
    vendorid: "",
    cost: "",
    isCostFinal: false,
  }])

  const addInputField = () => {
    setInputmultiField([...inputmultiField, {
      vendorId: '',
      cost: '',
      isCostFinal: false,
    }])

  }

  const removeInputFields = (index) => {
    const rows = [...inputmultiField];
    rows.splice(index, 1);
    setInputmultiField(rows);
  }

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...inputmultiField];
    list[index][name] = value;
    setInputmultiField(list);

  }

  const backbutton = () => {
    props.navigate(-1);
  }


  return (
    <div>
      <div className="container-fluid">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <div className="row">
              <div class="d-flex flex-start align-items-center">
              </div>

              <div className="col-sm-4"><h2>Select Vendor</h2></div>
              <div className="col-sm-5"></div>
              <div className="col-sm-3 d-flex flex-row-reverse">
                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="card cardUser shadow mb-4" >
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">Add Vendor</h6>
            {apiResponse && (
              <div className="alert alert-success" role="alert">
                Vendor has been updated successfully!
              </div>
            )}
            {apiError && (
              <div className="alert alert-danger" role="alert">
                {apiError}
              </div>
            )}
          </div>

          <div class="col-sm-12">
            <form onSubmit={save} class="form-horizontal mt-2">
              <div class="form-group d-flex">
                <div class="col-sm-1   "><b>Project</b></div>
                <div class="col-sm-2">
                  <select className="form-control" disabled={detailFlag} name="projectid" value={projectId} onChange={handleProjectChange}>
                    <option value="">[NONE]</option>
                    {projectList.map((e, key) => {
                      return <option className='p-1' key={e.id} value={e.id}>{e.projectname}</option>
                    })}
                  </select>
                </div>
                <div class="col-sm-1  "><b>Venue</b></div>
                <div className="col-sm-2">
                  <input type="text" class="form-control" readOnly={detailFlag} placeholder='enter show venue' name="venue" value={inputField.venue} />
                </div>
                <div for="supplier" class="col-sm-1"><b>ShowStart</b></div>
                <div className="col-sm-2">
                  <input type="date" class="form-control" readOnly={detailFlag} name="startDate" value={formatDate(inputField.startDate)} />
                </div>
                <div for="supplier" class="col-sm-1"><b>Show End </b></div>
                <div className="col-sm-2">
                  <input type="date" class="form-control" readOnly={detailFlag} name="endDate" value={formatDate(inputField.endDate)} />
                </div>
              </div>
              {inputmultiField.map((data, index) => {
                const {
                  vendorid, cost, isCostFinal
                } = data;
                return (
                  <div className="col-sm-12">
                    <div class="form-group d-flex" key={index}>
                      <div for="supplier" class="col-sm-1"><b>Vendor</b></div>
                      <div class="col-sm-2">
                        <select className="form-control" name="vendorid" readOnly={detailFlag} value={vendorid} onChange={(evnt) => handleChange(index, evnt)}>
                          <option value="">[NONE]</option>
                          {vendorList.map((e, key) => {
                            return <option key={e.id} value={e.id}>{e.supplierName}</option>
                          })}
                        </select>
                      </div>
                      {(props.name !== "details") && <div className="form-group mt-1">
                        {detailFlag !== true && <Link className='btn-sm btn-primary ' to="/CreateVandour">Add Vendor</Link>}
                      </div>}
                      <div class="col-sm-2 "><b>Vendor Costing</b></div>
                      <div class="col-sm-2">
                        <input type="text" id="cost" placeholder="enter Costing of vendor" value={cost} readOnly={detailFlag} onChange={(evnt) => handleChange(index, evnt)} class="form-control" name="cost" />
                      </div>
                      <div for="supplier" class="col-sm-1"><b>Final Vendor</b></div>
                      <div class="col-sm-2 d-flex">
                        <select className="form-control" name="isCostFinal" disabled={detailFlag} value={isCostFinal} onChange={(evnt) => handleChange(index, evnt)}>
                          <option value={false}>Not Selected</option>
                          <option value={true}>Selected</option>
                        </select>
                      </div>
                      {(props.name !== "details") && <div className='row'> <span className="btn btn-outline-success ml-2 mb-2" onClick={addInputField}>Add More</span>
                        {(inputmultiField.length !== 1) ? <span className="btn btn-outline-danger ml-3" onClick={removeInputFields}>x</span> : ''}</div>}

                    </div>
                    <hr />
                  </div>
                )
              })}

              {detailFlag !== true && <button type="submit" class="btn btn-primary ml-4 mb-3">Save</button>}
            </form>
          </div>


          {/* // result */}
          {(props.name === "details" || props.name === "edit") && <div className="container-fluid">
            {(props.name === "details" || props.props === "edit") && <div className="card-header py-3">
              <h4 className="m-0 font-weight-bold text-primary">Final Selected Vendor</h4>

            </div>}
            {selectedVendor.map((data, index) => {
              const {
                vendorid, cost, isCostFinal
              } = data;
              return (
                <div className="col-sm-12 mt-1">
                  <div class="form-group d-flex" key={index}>
                    <div for="supplier" class="col-sm-1"><b>Vendor</b></div>
                    <div class="col-sm-2">
                      <select className="form-control" disabled name="vendorid" value={vendorid} onChange={(evnt) => handleChange(index, evnt)}>
                        <option value="">[NONE]</option>
                        {vendorList.map((e, key) => {
                          return <option key={e.id} value={e.id}>{e.supplierName}</option>
                        })}
                      </select>
                    </div>
                    <div class="col-sm-2 "><b>Vendor Costing</b></div>
                    <div class="col-sm-2">
                      <input type="text" id="cost" readOnly placeholder="enter Costing of vendor" value={cost} onChange={(evnt) => handleChange(index, evnt)} class="form-control" name="cost" />
                    </div>
                    <div for="supplier" class="col-sm-2"><b>Final Vendor</b></div>
                    <div class="col-sm-2 d-flex">
                      <select className="form-control" name="isCostFinal" disabled value={isCostFinal} onChange={(evnt) => handleChange(index, evnt)}>
                        <option value={false}>Not Selected</option>
                        <option value={true}>Selected</option>
                      </select>
                    </div>

                  </div>
                  <hr />
                </div>
              )
            })}
          </div>}

          {/* // results */}
        </div>
      </div>

    </div>
  )
}

export default CreateSelectVendorComponent