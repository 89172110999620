import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import Testing3Component from '../../../Components/Main/TestingComponent/Testing3Component';

function Testing3(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <Testing3Component {...props} navigate={navigate} id={params.id} name = {params.name}  />
        </div>
    );  
  }
export default Testing3  