import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import SummarySheetListComponent from '../../../Components/Main/DesignerMasterComponent/SummarySheetListComponent';

function SummarySheetList () {
const params  = useParams();
const navigate = useNavigate();
return (
    <SummarySheetListComponent navigate={navigate} />
)
}

export default SummarySheetList
