import React from 'react'
import { useState } from 'react';
import Service from '../../../Services/Admin/Service';
import { useEffect } from 'react';
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState, convertFromHTML, ContentState, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Select from 'react-select';
import draftToHtml from 'draftjs-to-html';
import { Link } from 'react-router-dom';

const CreateAgreementComponent = (props) => {
    let id = sessionStorage.getItem('id');
    const [project, setProject] = useState([]);
    const [projectError, setProjectError] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);
    const [apiError, setApiError] = useState(null);
    const [agreemntdetails, setagreementdetails] = useState('');

    const [Projectid, setProjectid] = useState('');
    const [typeid, settypeid] = useState('');

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    const handleEditorChange = (state) => {
        setEditorState(state);
    }
    const [inputField, setInputField] = useState(
        {
            id: props.id,
            isAproved: false,
            details: "",
            projectid: 0,
            type: "",
            createdby: id,
            updatedBy: id
        }
    )


    useEffect(() => {
        getProjectData("Project/GetAll");
        if (props.id !== 0 && props.id !== undefined) {
            getProjectDatabyId("Agreement/GetById/?id=" + props.id, '');
        }
    }, [])

    const getProjectData = (method) => {
        Service.getdata(method).then((res) => {
            setProject(res.data);
        });
    }

    const getProjectDatabyId = (method, id) => {
        Service.getdatabyId(method, id).then((res) => {
            setagreementdetails(res.data);
            console.log('Agreement', res.data);
            setProjectid(res.data.projectid);
            settypeid(res.data.type);

            const content1 = convertFromRaw(JSON.parse(res.data.details));

            setEditorState(EditorState.createWithContent(content1));

        });
    }

    useEffect(() => {
        if (apiResponse || apiError) {
            const timeoutId = setTimeout(removeSuccessMessage, 3000);

            return () => clearTimeout(timeoutId);
        }
    }, [apiResponse, apiError]);

    const removeSuccessMessage = () => {
        setApiResponse(null);
        setApiError(null);
    };

    const backbutton = () => {
        props.navigate(-1);
    }

    const handleInputProject = (e) => {
        setProjectid(e.target.value);
    }

    const handleInputType = (e) => {
        settypeid(e.target.value);
    }

    const save = async (evt) => {

        if (evt && evt.preventDefault) {
            evt.preventDefault();
        }

        const formData = new FormData(evt.target);

        const data = {}
        for (const [key, value] of formData) {
            data[key] = value;
        }

        function validateData(data) {
            const { projectid, type } = data;

            if (!type) {
                alert("Please fill in all required fields.");
                return false;
            }

            if (!projectid || projectid === "[NONE]") {
                setProjectError(true);
                return false;
            } else {
                setProjectError(false);
            }

            return true
        }

        if (!validateData(data)) {
            return;
        }

        const currentContent = editorState.getCurrentContent();
        const messageBody = convertToRaw(currentContent);
        var jsonData = JSON.stringify(messageBody);
        // const loadData = JSON.parse(jsonData);
        // var content=loadData.blocks[0].text;
        let currentContentAsHTML = draftToHtml(messageBody);
        //  inputField.ContentJosn = jsonData;

        inputField.details = jsonData;

        const task = {
            ...inputField,
            ...data,
        };
        console.log(task)
        debugger
        if (task) {

            Service.createorupdate("Agreement/CreateOrUpdate", task)
                .then(res => {

                    setApiResponse(res.data);
                    window.location.href = "/AgreementList";
                    // Set the API response in state
                })
                .catch(error => {
                    // Handle error if API call fails
                    console.error("API Error:", error);
                    setApiError("Error occurred while saving the meeting. Please try again later.");
                });
        }


    }

    return (
        <div>
            <div className="container-fluid">

                <div className="card shadow border-success mb-2">
                    <div className="card-header rounded py-3">
                        <div className="row">
                            <div class="d-flex flex-start align-items-center">

                            </div>
                            {props.id ? <div className="col-sm-4"><h2><b className='text-dark'>Edit Agreement</b></h2></div> :
                                <div className="col-sm-4"><h2><b className='text-dark'>New Agreement</b></h2></div>}
                            <div className="col-sm-6"></div>
                            <div className="col-sm-2 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="card cardUser shadow mb-4" >
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-success">Add</h6>
                        {apiResponse && (
                            <div className="alert alert-success" role="alert">
                                Agreement has been created successfully!
                            </div>
                        )}
                        {apiError && (
                            <div className="alert alert-danger" role="alert">
                                {apiError}
                            </div>
                        )}
                    </div>

                    <div class="container">
                        <form onSubmit={save} class="form-horizontal text-dark mt-2">

                            <div className="form-group ">
                                <div className='col-sm-9 required-label'><b>Project</b></div>
                                <div className='col-sm-9'>
                                    {projectError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a project.</div>}
                                    <select className="form-control  form-control-user" value={Projectid} name="projectid" required onChange={handleInputProject}>
                                        <option>[NONE]</option>
                                        {
                                            project.map((e, key) => {
                                                return <option key={e.id} value={e.id}>{e.projectname}</option>;
                                            })}
                                    </select>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="agreementType" class="col-sm-3 required-label  control-label"><b>Agreement Type</b></label>
                                <div class="col-sm-9">
                                    <select className="form-control  form-control-user" value={typeid} name="type" required onChange={handleInputType}>
                                        <option>[NONE]</option>
                                        <option value="Vendor">Vendor</option>
                                        <option value="Client">Client</option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="details" class="col-sm-3 required-label  control-label"><b>Agreement Details</b></label>
                                <div class="col-sm-12">
                                    <Editor
                                        editorState={editorState}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        onEditorStateChange={handleEditorChange}
                                        required
                                    />
                                </div>

                            </div>


                            <button type="submit" class="btn btn-primary ml-3 mb-3">Save</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateAgreementComponent