import { Link } from 'react-router-dom';
import React from 'react'

const Design2dListComponent = (props) => {
    const role = sessionStorage.getItem("role");

    const backbutton = () => {
        props.navigate(-1);
    }

    const staticid = 0;
    const staticname7 = "3D Design";
    const staticname8 = "Immersive Design";
    const staticname9 = "Graphic Design";
    const staticname10 = "Logo Design";
    const staticname11 = "Be-Matrix Design";
    const staticname12 = "2D Design";
    const staticname13 = "Logo Sizes";
    const staticname14 = "AR Design";
    const staticname15 = "VR Design";
    const staticname16 = "Graphic Sizes";
    // Define an array of buttons for each role
    const roleButtons = [

        {
            role: "Managing Director(MD)",
            buttons: [
                { label: "Graphic Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname9}` },
                { label: "Bematrix-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname11}` },
                { label: "3D-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname7}` },
                { label: "Logo Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname10}` },
                { label: "Immersive Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname8}` },
                { label: "2D Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname12}` },
                { label: "Logo Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname13}` },
                { label: "AR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname14}` },
                { label: "VR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname15}` },
                { label: "Graphic Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname16}` },


            ]
        },

        //operations
        {
            role: "Assitant Manager - Process",
           buttons: [
                { label: "Graphic Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname9}` },
                { label: "Bematrix-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname11}` },
                { label: "3D-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname7}` },
                { label: "Logo Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname10}` },
                { label: "Immersive Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname8}` },
                { label: "2D Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname12}` },
                { label: "Logo Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname13}` },
                { label: "AR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname14}` },
                { label: "VR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname15}` },
                { label: "Graphic Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname16}` },


            ]
        },

        {
            role: "Executive - Software Developer(SOP Tool)",
           buttons: [
                { label: "Graphic Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname9}` },
                { label: "Bematrix-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname11}` },
                { label: "3D-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname7}` },
                { label: "Logo Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname10}` },
                { label: "Immersive Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname8}` },
                { label: "2D Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname12}` },
                { label: "Logo Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname13}` },
                { label: "AR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname14}` },
                { label: "VR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname15}` },
                { label: "Graphic Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname16}` },


            ]
        },


        {
            role: "GM-Commercial",
           buttons: [
                { label: "Graphic Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname9}` },
                { label: "Bematrix-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname11}` },
                { label: "3D-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname7}` },
                { label: "Logo Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname10}` },
                { label: "Immersive Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname8}` },
                { label: "2D Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname12}` },
                { label: "Logo Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname13}` },
                { label: "AR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname14}` },
                { label: "VR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname15}` },
                { label: "Graphic Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname16}` },


            ]
        },

        {
            role: "Deputy General Manager - Creative Head Immersive",
           buttons: [
                { label: "Graphic Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname9}` },
                { label: "Bematrix-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname11}` },
                { label: "3D-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname7}` },
                { label: "Logo Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname10}` },
                { label: "Immersive Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname8}` },
                { label: "2D Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname12}` },
                { label: "Logo Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname13}` },
                { label: "AR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname14}` },
                { label: "VR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname15}` },
                { label: "Graphic Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname16}` },


            ]
        },

        {
            role: "DGM-Projects",
           buttons: [
                { label: "Graphic Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname9}` },
                { label: "Bematrix-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname11}` },
                { label: "3D-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname7}` },
                { label: "Logo Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname10}` },
                { label: "Immersive Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname8}` },
                { label: "2D Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname12}` },
                { label: "Logo Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname13}` },
                { label: "AR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname14}` },
                { label: "VR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname15}` },
                { label: "Graphic Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname16}` },


            ]
        },

        {
            role: "Sr.Manager-Projects",
           buttons: [
                { label: "Graphic Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname9}` },
                { label: "Bematrix-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname11}` },
                { label: "3D-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname7}` },
                { label: "Logo Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname10}` },
                { label: "Immersive Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname8}` },
                { label: "2D Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname12}` },
                { label: "Logo Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname13}` },
                { label: "AR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname14}` },
                { label: "VR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname15}` },
                { label: "Graphic Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname16}` },


            ]
        },

        {
            role: "Project Manager",
           buttons: [
                { label: "Graphic Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname9}` },
                { label: "Bematrix-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname11}` },
                { label: "3D-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname7}` },
                { label: "Logo Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname10}` },
                { label: "Immersive Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname8}` },
                { label: "2D Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname12}` },
                { label: "Logo Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname13}` },
                { label: "AR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname14}` },
                { label: "VR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname15}` },
                { label: "Graphic Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname16}` },


            ]
        },

        {
            role: "Senior Manager-Projects & Client Servicing",
           buttons: [
                { label: "Graphic Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname9}` },
                { label: "Bematrix-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname11}` },
                { label: "3D-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname7}` },
                { label: "Logo Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname10}` },
                { label: "Immersive Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname8}` },
                { label: "2D Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname12}` },
                { label: "Logo Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname13}` },
                { label: "AR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname14}` },
                { label: "VR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname15}` },
                { label: "Graphic Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname16}` },


            ]
        },

        {
            role: "Manager-Creative-Design",
           buttons: [
                { label: "Graphic Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname9}` },
                { label: "Bematrix-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname11}` },
                { label: "3D-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname7}` },
                { label: "Logo Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname10}` },
                { label: "Immersive Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname8}` },
                { label: "2D Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname12}` },
                { label: "Logo Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname13}` },
                { label: "AR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname14}` },
                { label: "VR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname15}` },
                { label: "Graphic Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname16}` },


            ]
        },

        {
            role: "Executive - Operations",
           buttons: [
                { label: "Graphic Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname9}` },
                { label: "Bematrix-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname11}` },
                { label: "3D-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname7}` },
                { label: "Logo Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname10}` },
                { label: "Immersive Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname8}` },
                { label: "2D Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname12}` },
                { label: "Logo Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname13}` },
                { label: "AR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname14}` },
                { label: "VR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname15}` },
                { label: "Graphic Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname16}` },


            ]
        },

        {
            role: "Executive - Software Developer",
           buttons: [
                { label: "Graphic Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname9}` },
                { label: "Bematrix-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname11}` },
                { label: "3D-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname7}` },
                { label: "Logo Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname10}` },
                { label: "Immersive Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname8}` },
                { label: "2D Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname12}` },
                { label: "Logo Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname13}` },
                { label: "AR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname14}` },
                { label: "VR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname15}` },
                { label: "Graphic Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname16}` },


            ]
        },

        {
            role: "Senior Executive - 3D Generalist",
           buttons: [
                { label: "Graphic Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname9}` },
                { label: "Bematrix-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname11}` },
                { label: "3D-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname7}` },
                { label: "Logo Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname10}` },
                { label: "Immersive Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname8}` },
                { label: "2D Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname12}` },
                { label: "Logo Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname13}` },
                { label: "AR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname14}` },
                { label: "VR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname15}` },
                { label: "Graphic Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname16}` },


            ]
        },

        {
            role: "Senior-Executive-Design Drafting",
           buttons: [
                { label: "Graphic Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname9}` },
                { label: "Bematrix-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname11}` },
                { label: "3D-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname7}` },
                { label: "Logo Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname10}` },
                { label: "Immersive Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname8}` },
                { label: "2D Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname12}` },
                { label: "Logo Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname13}` },
                { label: "AR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname14}` },
                { label: "VR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname15}` },
                { label: "Graphic Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname16}` },


            ]
        },

        {
            role: "Executive - 3D Modeler",
           buttons: [
                { label: "Graphic Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname9}` },
                { label: "Bematrix-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname11}` },
                { label: "3D-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname7}` },
                { label: "Logo Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname10}` },
                { label: "Immersive Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname8}` },
                { label: "2D Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname12}` },
                { label: "Logo Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname13}` },
                { label: "AR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname14}` },
                { label: "VR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname15}` },
                { label: "Graphic Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname16}` },


            ]
        },

        {
            role: "Manager - 3D Production",
           buttons: [
                { label: "Graphic Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname9}` },
                { label: "Bematrix-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname11}` },
                { label: "3D-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname7}` },
                { label: "Logo Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname10}` },
                { label: "Immersive Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname8}` },
                { label: "2D Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname12}` },
                { label: "Logo Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname13}` },
                { label: "AR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname14}` },
                { label: "VR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname15}` },
                { label: "Graphic Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname16}` },


            ]
        },

        {
            role: "Executive - 3D Modeling & Texturing Artist",
           buttons: [
                { label: "Graphic Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname9}` },
                { label: "Bematrix-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname11}` },
                { label: "3D-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname7}` },
                { label: "Logo Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname10}` },
                { label: "Immersive Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname8}` },
                { label: "2D Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname12}` },
                { label: "Logo Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname13}` },
                { label: "AR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname14}` },
                { label: "VR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname15}` },
                { label: "Graphic Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname16}` },


            ]
        },

        {
            role: "Assistant Manager - Visual Designer",
           buttons: [
                { label: "Graphic Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname9}` },
                { label: "Bematrix-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname11}` },
                { label: "3D-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname7}` },
                { label: "Logo Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname10}` },
                { label: "Immersive Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname8}` },
                { label: "2D Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname12}` },
                { label: "Logo Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname13}` },
                { label: "AR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname14}` },
                { label: "VR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname15}` },
                { label: "Graphic Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname16}` },


            ]
        },
        {
            role: "Assistant Manager - Technical Design",
           buttons: [
                { label: "Graphic Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname9}` },
                { label: "Bematrix-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname11}` },
                { label: "3D-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname7}` },
                { label: "Logo Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname10}` },
                { label: "Immersive Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname8}` },
                { label: "2D Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname12}` },
                { label: "Logo Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname13}` },
                { label: "AR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname14}` },
                { label: "VR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname15}` },
                { label: "Graphic Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname16}` },


            ]
        },

        {
            role: "Assistant Manager - Creative Design",
           buttons: [
                { label: "Graphic Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname9}` },
                { label: "Bematrix-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname11}` },
                { label: "3D-Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname7}` },
                { label: "Logo Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname10}` },
                { label: "Immersive Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname8}` },
                { label: "2D Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname12}` },
                { label: "Logo Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname13}` },
                { label: "AR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname14}` },
                { label: "VR Design List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname15}` },
                { label: "Graphic Sizes List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/DesignList/${staticid}&${staticname16}` },


            ]
        },


       

    ];

    return (
        <div>
            <div className="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="d-flex flex-start align-items-center">
                               
                            </div>
                            <div className="col-sm-4"><h2>Deigns Dashboard</h2></div>
                            <div className="col-sm-6"></div>
                            <div className="col-sm-2 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="card cardUser shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Designs</h6>
                    </div>
                    <div className="container" style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                        <div className="row">
                            {roleButtons.map((roleButtonGroup, index) => (
                                (roleButtonGroup.role === role) && (
                                    roleButtonGroup.buttons.map((button, buttonIndex) => (
                                        <div key={buttonIndex} className="col-xl-4 col-md-8">
                                            <div className="card border-success shadow p-1 mt-3 ml-3">
                                                <div className="btn-group">
                                                    <Link to={button.to} className={button.className} type="button">
                                                        {button.label}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )
                            ))}
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Design2dListComponent