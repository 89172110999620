import React, { Component } from 'react' 
import { useNavigate } from 'react-router-dom';
import SitePhotosAndVideosListComponent from '../../../Components/Main/PermitAndPassesComponent/SitePhotosAndVideosListComponent';

function SitePhotosAndVideosList(props) {
    let navigate = useNavigate();
    return (  
        <div> 
            <SitePhotosAndVideosListComponent {...props} navigate={navigate} />
        </div>
    );  
  }
  
export default SitePhotosAndVideosList  