import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import MaterialLinkListComponent from '../../../Components/Main/MaterialComponent/MaterialLinkListComponent';

function MaterialLinkList(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <MaterialLinkListComponent {...props} navigate={navigate} id = {params.id} name = {params.name} />
        </div>
    );  
  }
export default MaterialLinkList  