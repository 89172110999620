import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import ShareLeadListComponent from '../../../Components/Main/LeadComponent/ShareLeadListComponent';


function ShareLeadList(props) {
    let navigate = useNavigate();
    const params = useParams()
    return (  
        <div> 
            <ShareLeadListComponent {...props} navigate={navigate} name={params.name} />
        </div>
    );  
  }
export default ShareLeadList  