
// New Project Route
import TasksList from '../Pages/Main/Tasks/TasksList'
import MyTasksList from '../Pages/Main/Tasks/MyTasksList'
// import AssignedTaskList from '../Pages/Main/Tasks/AssignedTaskList'
import CreateTask from '../Pages/Main/Tasks/CreateTask'
import DetailTask from '../Pages/Main/Tasks/DetailTask'
import CreateUser from '../Pages/Main/User/CreateUser'
import UserList from '../Pages/Main/User/UserList'
import CreateRole from '../Pages/Main/Roles/CreateRole'
import UserRolesList from '../Pages/Main/Roles/RolesList'
import TasktypeList from '../Pages/Main/TaskType/TasktypeList'
import AddTasktype from '../Pages/Main/TaskType/AddTaskType'
import ProjectList from '../Pages/Main/Project/ProjectList'
import DetailProject from '../Pages/Main/Project/DetailProject'
import AddProject from '../Pages/Main/Project/AddProject'
import Dashboard from '../Pages/Main/Dashboard/Dashboard'
import AssignProjectUserList from '../Pages/Main/AssignProjectUser/AssignProjectUserList'
import AddProjectAssign from '../Pages/Main/AssignProjectUser/AddProjectAssign'
import Kanban from '../Pages/Main/Tasks/Kanban'
import TicketDetails from '../Pages/Main/Tasks/TicketDetails'
import TaskDetails from '../Pages/Main/Tasks/TaskDetails'
import CreateVandour from '../Pages/Main/Vendor/CreateVandour'
import CreateLead from '../Pages/Main/Lead/CreateLead'
import CreateExtractedData from '../Pages/Main/ExtractedData/CreateExtratedData'
import ExtractedDataDetails from '../Pages/Main/ExtractedData/ExtractedDataDetails'
import CreateMeeting from '../Pages/Main/Meeting/CreateMeeting'
import MeetingDetails from '../Pages/Main/Meeting/MeetingDetails'
import MeetingList from '../Pages/Main/Meeting/MeetingList'
import CreateBoq from '../Pages/Main/Boq/CreateBoq'
import CreateAgreement from '../Pages/Main/Agreement/CreateAgreement'
import BoqDetails from '../Pages/Main/Boq/BoqDetails'
import AgreementDetails from '../Pages/Main/Agreement/AgreementDetails'
import AgreementIsShared from '../Pages/Main/Agreement/AgreementIsShared'
import AgreementIsApproved from '../Pages/Main/Agreement/AgreementIsApproved'
import BoqIsShared from '../Pages/Main/Boq/BoqIsShared'
import BoqIsApproved from '../Pages/Main/Boq/BoqIsApproved'
import CreatePermitAndPasses from '../Pages/Main/PermitAndPasses/PermitAndPasses'
import UnloadingMaterialChecklist from '../Pages/Main/PermitAndPasses/UnloadingMaterialChecklist'
import Home from '../Pages/Main/Home/Home'
// import InsideProject from '../Pages/Main/Home/InsideProject'
import AgreementList from '../Pages/Main/Agreement/AgreementList'
import BoqList from '../Pages/Main/Boq/BoqList'
import CreateSelectVendor from '../Pages/Main/SelectVendor/CreateSelectVendor'
import VendorList from '../Pages/Main/Vendor/VendorList'
import PermitPasessList from '../Pages/Main/PermitAndPasses/PermitPasessList'
import SelectVendorList from '../Pages/Main/SelectVendor/SelectVendorList'
import SitePhotosAndVideosList from '../Pages/Main/PermitAndPasses/SitePhotosAndVideosList'
import SitePhotosAndVideo from '../Pages/Main/PermitAndPasses/SitePhotosAndVideo'

import CreateUnloadingMaterial from '../Pages/Main/UnloadingMaterial/CreateUnloadingMaterial'
import UnloadingMaterialList from '../Pages/Main/UnloadingMaterial/UnloadingMaterialList'

import ExtractedDataList from '../Pages/Main/ExtractedData/ExtractedDataList'
import PermitPasessDetails from '../Pages/Main/PermitAndPasses/PermitAndPassesDetails'
import VendorDetails from '../Pages/Main/Vendor/VendorDetails'
import SelectVendorDetails from '../Pages/Main/SelectVendor/SelectVendorDetails'
import AdminDashboard from '../Pages/Main/AdminPanel/AdminPanelDashboard'
import KanbanMyTask from '../Pages/Main/Tasks/KanbanMyTask'
import KanbanAssignedTask from '../Pages/Main/Tasks/KanbanAssignedTask'
import ProjectPrePL from '../Pages/Main/PrePL/ProjectPrePL'
import CreatePrePL from '../Pages/Main/PrePL/CreatePrePL'
import CreateVendorQuotation from '../Pages/Main/VendorQuotation/CreateVendorQuotation'
import CreateClientQuotation from '../Pages/Main/VendorQuotation/CreateClientQuotation'
import Testing from '../Pages/Main/Testing/Testing'
import CreateContent from '../Pages/Main/Content/CreateContent'
import ExtractedDataIsApproved from '../Pages/Main/ExtractedData/ExtractedDataIsApproved'
import ContentList from '../Pages/Main/Content/ContentList'
import ContentDetails from '../Pages/Main/Content/ContentDetails'
import ContentIsApproved from '../Pages/Main/Content/ContentIsApproved'
import VendorCostingList from '../Pages/Main/Vendor/VendorCostingList'
import CreateVendorCosting from '../Pages/Main/Vendor/CreateVendorCosting'
import VendorCostingDetails from '../Pages/Main/Vendor/VendorCostingDetails'
import ApprovedExtraxctedDataList from '../Pages/Main/ExtractedData/ApprovedExtractedDataList'
import DenyExtractedDataList from '../Pages/Main/ExtractedData/DenyExtractedDataList'
import DashboardOperationsOption from '../Pages/Main/DashboadOptions/DashboardOperationsOption'
import DasboardMarketingOptions from '../Pages/Main/DashboadOptions/DasboardMarketingOptions'
import CreateRFP from '../Pages/Main/RFP/CreateRFP'
import Testing2 from '../Pages/Main/Testing/testing2'
import RFPList from '../Pages/Main/RFP/RFPList'
import ShowList from '../Pages/Main/LifeTool/ShowList'
import ExhibitorList from '../Pages/Main/LifeTool/ExhibitorList'
import CreateSpecificTask from '../Pages/Main/Tasks/CreateSpecificTask'
import SpecificTaskList from '../Pages/Main/Tasks/SpecificTaskList'
import SpecificTaskDetail from '../Pages/Main/Tasks/SpecificTaskDetail'
import RFPDetails from '../Pages/Main/RFP/RFPDetails'
import RFPShared from '../Pages/Main/RFP/RFPShared'
import DashboardPreSalesOptions from '../Pages/Main/DashboadOptions/DashboardPreSaleOptions'
import DashboardSalesOptions from '../Pages/Main/DashboadOptions/DashboardSalesOptions'
import PPTList from '../Pages/Main/PPT/PPTList'
import CreatePPT from '../Pages/Main/PPT/CreatePPT'
import ShareLead from '../Pages/Main/Lead/ShareLead'
// import DashboardICDesign from '../Pages/Main/DashboadOptions/DashboardICOptions'
import DashboardICDesignOptions from '../Pages/Main/DashboadOptions/DashboardICOptions'
import CreateSalesTask from '../Pages/Main/Tasks/CreateSalesTask'
import DashboardCommercialOptions from '../Pages/Main/DashboadOptions/DashboardCommercialOptions'
import CreateMeetingSales from '../Pages/Main/Meeting/CreateMeetingSales'
import CreateMeetingMarketing from '../Pages/Main/Meeting/CreateMeetingMarketing'
import PrePLList from '../Pages/Main/PrePL/PrePLList'
import ShareLeadList from '../Pages/Main/Lead/ShareLeadList'
import QualifiedLeadList from '../Pages/Main/Lead/QualifiedLeadList'
import QualifiedLeadDetails from '../Pages/Main/Lead/QualifiedLeadDetails'
import SharedLeadDetails from '../Pages/Main/Lead/SharedLeadDetails'
import VendorClientQuotationList from '../Pages/Main/VendorQuotation/VendorClientQuotationList'
import DesignList from '../Pages/Main/Design/DesignList'
import AttachContentLink from '../Pages/Main/Attachment/AttachContentLink'
import ContentAttachmentList from '../Pages/Main/Attachment/ContentAttachmentList'
import KanbanMyArchievedTask from '../Pages/Main/Tasks/KanbanMyArchievedTask'
import CreateMaterial from '../Pages/Main/Material/CreateMaterial'
import MaterialLinkList from '../Pages/Main/Material/MaterialLinkList'
import EditRFPDetails from '../Pages/Main/RFP/EditRFPDetails'
import Design2dList from '../Pages/Main/Design/AdminDesign'
import CreateCustomerFeedback from '../Pages/Main/Feedback/CreateCustomerFeedback'
import CreateProjectClosureFeedback from '../Pages/Main/Feedback/CreateProjectClosureFeedback'
import CreateProjectFedback from '../Pages/Main/Feedback/CreateProjectFeedback'
import ProjectFeedbackList from '../Pages/Main/Feedback/ProjectFeedbackList'
import ProjectClosureFeedbackList from '../Pages/Main/Feedback/ProjectClosureFeedbackList'
import CustomerFeedbackList from '../Pages/Main/Feedback/CustomerFeedbackList'
import CreateProjectExecutionTracker from '../Pages/Main/Feedback/CreateProjectExecutionTracker'
import CreateApprovalList from '../Pages/Main/ApprovalList/CreateApprovalList'
import ClientApprovalList from '../Pages/Main/ApprovalList/ClientApprovalList'
import SupplierApprovalList from '../Pages/Main/ApprovalList/SupplierApprovalList'
import ApprovalList from '../Pages/Main/ApprovalList/ApprovalList'
import ShowApprovalList from '../Pages/Main/ApprovalList/ShowApprovalList'
import ProjectwiseExecutionTrackerList from '../Pages/Main/Feedback/ProjectwiseExecutionTrackerList'
import ExecutionTrackerList from '../Pages/Main/Feedback/ExecutionTrackerList'
import ClientListQuotation from '../Pages/Main/VendorQuotation/ClientQuotationList'
import CreateDesignTask from '../Pages/Main/Tasks/CreateDesignTask'
import CreateFinanceTask from '../Pages/Main/Tasks/CreateFinanceTask'
import AdminShowList from '../Pages/Main/LifeTool/AdminShowList'
import FeedbackDashboard from '../Pages/Main/AdminPanel/FeedbackDashboard'
import ClientVendorDashboard from '../Pages/Main/AdminPanel/ClientVendorDashboard'
import Notification from '../Pages/Main/Notification/notification'
import Profile from '../Pages/Main/Profie/profile'
import RFPFinalDesign from '../Pages/Main/RFP/RFPFinalDesign'
import CreateMultiTask from '../Pages/Main/Tasks/MultiTask'
import TotalProjectDashboard from '../Pages/Main/Dashboard/TotalProjectDashboard'
import DashboardFailedDeadline from '../Pages/Main/Dashboard/DashboardFailedDeadlines'
import DashboardUserTask from '../Pages/Main/Dashboard/DashboardUserTask'
import EmployeesTaskDetails from '../Pages/Main/Dashboard/EmloyeesTaskDetails'
import EmployeesPogressTask from '../Pages/Main/Dashboard/EmployeesProgressTask'
import EmployeesClosedTask from '../Pages/Main/Dashboard/EmployeesClosedTask'
import CreateMultiTaskMarketing from '../Pages/Main/Tasks/CreateMultiTaskMarketing'
import CreateRFPNew from '../Pages/Main/RFP/CreateRFPNew'
import RFPDetailsNew from '../Pages/Main/RFP/RFPDetailsNew'
import EditRFP from '../Pages/Main/RFP/EditRFP'
import DashboardLead from '../Pages/Main/Lead/DashboardLead'
import CreateProjectExecution from '../Pages/Main/Feedback/CreateProjectExecution'
import CostingList from '../Pages/Main/SelectVendor/CostingList'
import AdminCalendar from '../Pages/Main/Calendar/adminCalendar'
import UserCalendar from '../Pages/Main/Calendar/userCalendar'
import UserSheet from '../Pages/Main/DesignerMasterSheet/UserSheet'
import UserSheetList from '../Pages/Main/DesignerMasterSheet/UserSheetList'
import EditUserSheet from '../Pages/Main/DesignerMasterSheet/EditUserSheet'
import RFPMasterSheet from '../Pages/Main/DesignerMasterSheet/RFPMasterSheet'
import SummarySheetList from '../Pages/Main/DesignerMasterSheet/SummarySheetList'
import Testing3 from '../Pages/Main/Testing/Testing3'



const routes = [
  { path: '/', name: 'Home', component: Dashboard, element: Dashboard, exact: true },
  { path: '/ProjectHome', name: 'ProjectHome', component: Home, element: Home },
  { path: '/InsideProject', name: 'ProjectHome', component: Home, element: Home },
  { path: '/PrePLProjects', name: 'ProjectPrePL', component: ProjectPrePL, element: ProjectPrePL },
  { path: '/PrePLList', name: 'PrePLList', component: PrePLList, element: PrePLList },
  { path: '/Kanban', name: 'TasksList', component: Kanban },
  { path: '/KanbanMyTask', name: 'TasksList', component: KanbanMyTask },
  { path: '/KanbanMyTask/:name', name: 'TasksList', component: KanbanMyTask },
  { path: '/KanbanMyArchievedTask', name: 'KanbanMyArchievedTask', component: KanbanMyArchievedTask },
  { path: '/KanbanAssignedTask', name: 'TasksList', component: KanbanAssignedTask },
  { path: '/KanbanAssignedTask/:name', name: 'TasksList', component: KanbanAssignedTask },
  { path: '/Alltasks', name: 'TasksList', component: TasksList },
  { path: '/MyTasks', name: 'MyTasksList', component: MyTasksList },
  // { path: '/AssignedTask', name: 'AssignedTaskList', component: AssignedTaskList },
  { path: '/CreateTask', name: 'CreateTask', component: CreateTask },
  { path: '/CreateTask/:name', name: 'CreateTask', component: CreateTask },
  { path: '/CreateDesignTask', name: 'CreateDesignTask', component: CreateDesignTask },
  { path: '/CreateDesignTask/:name', name: 'CreateDesignTask', component: CreateDesignTask },
  { path: '/CreateFinanceTask', name: 'CreateFinanceTask', component: CreateFinanceTask },
  { path: '/CreateFinanceTask/:name', name: 'CreateFinanceTask', component: CreateFinanceTask },
  { path: '/Detail/:id', name: 'DetailTask', component: DetailTask },
  { path: '/UserList', name: 'UserList', component: UserList },
  { path: '/CreateUser', name: 'CreateUser', component: CreateUser },
  { path: '/RolesList', name: 'UserRolesList', component: UserRolesList },
  { path: '/CreateRole', name: 'CreateRole', component: CreateRole },
  { path: '/TasksTypeList', name: 'TasktypeList', component: TasktypeList },
  { path: '/AddTasktype/:id', name: 'AddTasktype', component: AddTasktype },
  { path: '/AddTasktype', name: 'AddTasktype', component: AddTasktype },
  { path: '/ProjectList', name: 'ProjectList', component: ProjectList },
  { path: '/ProjectList/:name', name: 'ProjectList', component: ProjectList },
  { path: '/DetailProject/:id', name: 'DetailProject', component: DetailProject },
  { path: '/AddProject', name: 'AddProject', component: AddProject },
  { path: '/AddProject/:id&:name', name: 'AddProject', component: AddProject },
  { path: '/AddProject/:id', name: 'AddProject', component: AddProject },
  { path: '/AddProjectUser', name: 'AddProjectUser', component: AssignProjectUserList },
  { path: '/AddAssignUser', name: 'AddProjectAssign', component: AddProjectAssign },
  { path: '/TicketDetails', name: 'TicketDetails', component: TicketDetails },
  { path: '/TaskDetails/:id', name: 'TaskDetails', component: TaskDetails },
  { path: '/TaskDetails/:id&:name', name: 'TaskDetails', component: TaskDetails },
  { path: '/CreateLead', name: 'CreateLead', component: CreateLead },
  { path: '/ShareLead', name: 'ShareLead', component: ShareLead },
  { path: '/SharedLeadList', name: 'ShareLeadList', component: ShareLeadList },
  { path: '/DashboardLead', name: 'DashboardLead', component: DashboardLead },
  { path: '/DashboardLead/:name', name: 'DashboardLead', component: DashboardLead },
  { path: '/SharedLeadList/:name', name: 'ShareLeadList', component: ShareLeadList },
  { path: '/SharedLeadDetails/:id&:name', name: 'SharedLeadDetails', component: SharedLeadDetails },
  { path: '/QualifiedLeadDetails/:id', name: 'QualifiedLeadDetails', component: QualifiedLeadDetails },
  { path: '/QualifiedLeadList', name: 'QualifiedLeadList', component: QualifiedLeadList },
  { path: '/CreateVandour', name: 'CreateVandour', component: CreateVandour },
  { path: '/CreateVandour/:id', name: 'CreateVandour', component: CreateVandour },
  { path: '/VendorDetails/:id', name: 'VendorDetails', component: VendorDetails },
  { path: '/VendorList', name: 'VendorList', component: VendorList },
  { path: '/CreateVendorQuotation', name: 'CreateVendorQuotation', component: CreateVendorQuotation, element: CreateVendorQuotation },
  { path: '/CreateClientQuotation', name: 'CreateClientQuotation', component: CreateClientQuotation, element: CreateClientQuotation },  
  { path: '/CreateClientQuotation/:id&:name', name: 'CreateClientQuotation', component: CreateClientQuotation, element: CreateClientQuotation },  
  { path: '/VendorQuotationDetail/:id&:name', name: 'CreateVendorQuotation', component: CreateVendorQuotation, element: CreateVendorQuotation },
  { path: '/ClientQuotationDetail/:id&:name', name: 'CreateClientQuotation', component: CreateClientQuotation, element: CreateClientQuotation },
  { path: '/VendorClientQuotationList', name: 'ClientQuotationList', component: VendorClientQuotationList, element: VendorClientQuotationList },
  { path: '/ClientListQuotation', name: 'ClientListQuotation', component: ClientListQuotation, element: ClientListQuotation },
  { path: '/CreateExtractedData', name: 'CreateExtractedData', component: CreateExtractedData },
  { path: '/ApprovedExtraxctedDataList', name: 'ApprovedExtraxctedDataList', component: ApprovedExtraxctedDataList },
  { path: '/DenyExtractedDataList', name: 'DenyExtractedDataList', component: DenyExtractedDataList },
  { path: '/ExtractedDataDetails/:id', name: 'ExtractedDataDetails', component: ExtractedDataDetails },
  { path: '/ExtractedDataIsApproved/:id', name: 'ExtractedDataIsApproved', component: ExtractedDataIsApproved },
  { path: '/ExtractedDataList', name: 'ExtractedDataList', component: ExtractedDataList },
  { path: '/CreateMeeting', name: 'CreateMeeting', component: CreateMeeting },
  { path: '/CreateMeeting/:id', name: 'CreateMeeting', component: CreateMeeting },
  { path: '/CreateMeetingSales', name: 'CreateMeetingSales', component: CreateMeetingSales },
  { path: '/CreateMeetingSales/:id', name: 'CreateMeetingSales', component: CreateMeetingSales },
  { path: '/CreateMeetingMarketing', name: 'CreateMeetingMarketing', component: CreateMeetingMarketing },
  { path: '/CreateMeetingMarketing/:id', name: 'CreateMeetingMarketing', component: CreateMeetingMarketing },
  { path: '/CreateMeeting/:id', name: 'CreateMeeting', component: CreateMeeting },
  { path: '/MeetingList', name: 'MeetingList', component: MeetingList },
  { path: '/MeetingDetails/:id', name: 'MeetingDetails', component: MeetingDetails },
  { path: '/CreateBoq', name: 'CreateBoq', component: CreateBoq },
  { path: '/CreateBoq/:id', name: 'CreateBoq', component: CreateBoq },
  { path: '/BoqList', name: 'BoqList', component: BoqList },
  { path: '/BoqDetails/:id', name: 'BoqDetails', component: BoqDetails },
  { path: '/BoqIsApproved/:id', name: 'BoqIsApproved', component: BoqIsApproved },
  { path: '/BoqIsShared/:id', name: 'BoqIsShared', component: BoqIsShared },
  { path: '/CreateAgreement', name: 'CreateAgreement', component: CreateAgreement },
  { path: '/CreateAgreement/:id', name: 'CreateAgreement', component: CreateAgreement },
  { path: '/AgreementList', name: 'AgreementList', component: AgreementList },
  { path: '/AgreementDetails/:id', name: 'AgreementDetails', component: AgreementDetails },
  { path: '/AgreementIsShared/:id', name: 'AgreementIsShared', component: AgreementIsShared },
  { path: '/AgreementIsApproved/:id', name: 'AgreementIsApproved', component: AgreementIsApproved },
  { path: '/CreatePermitAndPasses', name: 'CreatePermitAndPasses', component: CreatePermitAndPasses },
  { path: '/PermitPasessList', name: 'PermitPasessList', component: PermitPasessList },
  { path: '/PermitPasessDetails/:id', name: 'PermitPasessDetails', component: PermitPasessDetails },
  { path: '/UnloadingMaterialChecklist', name: 'UnloadingMaterialChecklist', component: UnloadingMaterialChecklist },
  { path: '/SitePhotosAndVideo', name: 'SitePhotosAndVideo', component: SitePhotosAndVideo },
  { path: '/SitePhotosAndVideo/:id&:name', name: 'SitePhotosAndVideo', component: SitePhotosAndVideo },
  { path: '/SitePhotosAndVideosList', name: 'SitePhotosAndVideosList', component: SitePhotosAndVideosList },
  { path: '/CreateSelectVendor', name: 'CreateSelectVendor', component: CreateSelectVendor },

  { path: '/SelectVendorList', name: 'SelectVendorList', component: SelectVendorList },  
  { path: '/CreateUnloadingMaterial', name: 'CreateUnloadingMaterial', component: CreateUnloadingMaterial },
  { path: '/UnloadingMaterialList', name: 'UnloadingMaterialList', component: UnloadingMaterialList },

  { path: '/CreateSelectVendor/:id&:name', name: 'CreateSelectVendor', component: CreateSelectVendor },
  { path: '/SelectVendorDetails/:id', name: 'SelectVendorDetails', component: SelectVendorDetails },
  { path: '/SelectVendorList', name: 'SelectVendorList', component: SelectVendorList },
  { path: '/CostingList', name: 'CostingList', component: CostingList },
  { path: '/AdminDashboard', name: 'AdminDashboard', component: AdminDashboard },
  { path: '/FeedbackDashboard', name: 'FeedbackDashboard', component: FeedbackDashboard },
  { path: '/ClientVendorDashboard', name: 'ClientVendorDashboard', component: ClientVendorDashboard },
  { path: '/DashboardOperationsOption', name: 'DashboardOperationsOption', component: DashboardOperationsOption },
  { path: '/DasboardMarketingOptions', name: 'DasboardMarketingOptions', component: DasboardMarketingOptions },
  { path: '/DashboardSalesOptions', name: 'DashboardSalesOptions', component: DashboardSalesOptions },
  { path: '/DashboardPreSalesOptions', name: 'DashboardPreSalesOptions', component: DashboardPreSalesOptions },
  { path: '/DashboardICDesignOptions', name: 'DashboardICDesignOptions', component: DashboardICDesignOptions },
  { path: '/DashboardCommercialOptions', name: 'DashboardCommercialOptions', component: DashboardCommercialOptions },
  { path: '/Testing', name: 'Testing', component: Testing },
  { path: '/Testing2', name: 'Testing2', component: Testing2 },
  { path: '/Testing3/:id', name: 'Testing3', component: Testing3 },
  { path: '/Testing3', name: 'Testing3', component: Testing3 },

  { path: '/CreateContent', name: 'CreateContent', component: CreateContent },
  { path: '/ContentList', name: 'ContentList', component: ContentList },
  { path: '/ContentDetails/:id', name: 'ContentDetails', component: ContentDetails },
  { path: '/ContentIsApproved/:id', name: 'ContentIsApproved', component: ContentIsApproved },
  { path: '/VendorCostingList', name: 'VendorCostingList', component: VendorCostingList },
  { path: '/CreateVendorCosting', name: 'CreateVendorCosting', component: CreateVendorCosting },
  { path: '/VendorCostingDetails/:id', name: 'VendorCostingDetails', component: VendorCostingDetails },
  { path: '/CreateRFPNew', name: 'CreateRFP', component: CreateRFP },
  { path: '/CreateRFP', name: 'CreateRFPNew', component: CreateRFPNew },
  { path: '/RFPList', name: 'RFPList', component: RFPList },
  { path: '/RFPDetailsNew/:id', name: 'RFPDetails', component: RFPDetails },
  { path: '/RFPDetails/:id', name: 'RFPDetailsNew', component: RFPDetailsNew },
  { path: '/RFPFinalDesign/:id&:name', name: 'RFPFinalDesign', component: RFPFinalDesign },
  { path: '/EditRFP/:id', name: 'EditRFPDetails', component: EditRFPDetails },
  { path: '/EditRFPDetails/:id', name: 'EditRFP', component: EditRFP },
  { path: '/RFPShared', name: 'RFPShared', component: RFPShared },
  { path: '/ShowList', name: 'ShowList', component: ShowList },
  { path: '/AdminShowList', name: 'AdminShowList', component: AdminShowList },
  { path: '/CreateSpecificTask/:id&:name', name: 'CreateSpecificTask', component: CreateSpecificTask },
  { path: '/CreateSpecificTask', name: 'CreateSpecificTask', component: CreateSpecificTask },
  { path: '/CreateSpecificTask/:name', name: 'CreateSpecificTask', component: CreateSpecificTask },
  { path: '/SpecificTaskList/:id&:name', name: 'SpecificTaskList', component: SpecificTaskList },
  { path: '/SpecificTaskDetail/:id', name: 'SpecificTaskDetail', component: SpecificTaskDetail },
  { path: '/ExhibitorList/:id', name: 'ExhibitorList', component: ExhibitorList },

  { path: '/PPTList', name: 'PPTList', component: PPTList },
  { path: '/CreatePPT', name: 'CreatePPT', component: CreatePPT },
  { path: '/CreatePPT/:id&:name', name: 'CreatePPT', component: CreatePPT },
  { path: '/CreateSalesTask', name: 'CreateSalesTask', component: CreateSalesTask },
  { path: '/CreateSalesTask/:name', name: 'CreateSalesTask', component: CreateSalesTask },
  { path: '/AdminDesign', name: 'Design2dList', component: Design2dList },
  { path: '/DesignList/:id&:name', name: 'DesignList', component: DesignList },
  { path: '/AttachContentLink', name: 'AttachContentLink', component: AttachContentLink },
  { path: '/ContentAttachmentList', name: 'ContentAttachmentList', component: ContentAttachmentList },
  { path: '/CreateMaterial', name: 'CreateMaterial', component: CreateMaterial },
  { path: '/MaterialLinkList/:id&:name', name: 'MaterialLinkList', component: MaterialLinkList },
  { path: '/CreateCustomerFeedback/:id&:name', name: 'CreateCustomerFeedback', component: CreateCustomerFeedback },
  { path: '/CreateCustomerFeedback', name: 'CreateCustomerFeedback', component: CreateCustomerFeedback },
  { path: '/CreateProjectClosureFeedback/:id&:name', name: 'CreateProjectClosureFeedback', component: CreateProjectClosureFeedback },
  { path: '/CreateProjectClosureFeedback', name: 'CreateProjectClosureFeedback', component: CreateProjectClosureFeedback },
  { path: '/CreateProjectFeedback/:id&:name', name: 'CreateProjectFedback', component: CreateProjectFedback },
  { path: '/CreateProjectFeedback', name: 'CreateProjectFedback', component: CreateProjectFedback },
  { path: '/CreateProjectExecutionTracker', name: 'CreateProjectExecutionTracker', component: CreateProjectExecutionTracker },
  { path: '/CreateProjectExecution', name: 'CreateProjectExecution', component: CreateProjectExecution },
  { path: '/CreateProjectExecutionTracker/:id&:name', name: 'CreateProjectExecutionTracker', component: CreateProjectExecutionTracker },
  { path: '/CreateProjectExecution/:id&:name', name: 'CreateProjectExecution', component: CreateProjectExecution },
  { path: '/EditProjectExecutionTracker/:id&:name', name: 'CreateProjectExecutionTracker', component: CreateProjectExecutionTracker },

  { path: '/ProjectwiseExecutionTrackerList', name: 'ProjectwiseExecutionTrackerList', component: ProjectwiseExecutionTrackerList },
  { path: '/ExecutionTrackerList', name: 'ExecutionTrackerList', component: ExecutionTrackerList },
  { path: '/CustomerFeedbackList', name: 'CustomerFeedbackList', component: CustomerFeedbackList },
  { path: '/ProjectClosureFeedbackList', name: 'ProjectClosureFeedbackList', component: ProjectClosureFeedbackList },
  { path: '/ProjectFeedbackList', name: 'ProjectFeedbackList', component: ProjectFeedbackList },
  { path: '/CreatePrePL', name: 'CreatePrePL', component: CreatePrePL, element: CreatePrePL },  
  { path: '/DetailPrePL/:id&:name', name: 'CreatePrePL', component: CreatePrePL },
  { path: '/CreateApprovalList', name: 'CreateApprovalList', component: CreateApprovalList },  
  { path: '/CreateApprovalList/:id&:name', name: 'CreateApprovalList', component: CreateApprovalList },  
  { path: '/ClientApprovalList/:id&:name', name: 'ClientApprovalList', component: ClientApprovalList },  
  { path: '/ShowApprovalList/:id&:name', name: 'ShowApprovalList', component: ShowApprovalList },  
  { path: '/ApprovalList', name: 'ApprovalList', component: ApprovalList },  
  { path: '/SupplierApprovalList/:id&:name', name: 'SupplierApprovalList', component: SupplierApprovalList },  
  { path: '/NotificationCenter', name: 'Notification', component: Notification },  
  { path: '/Profile', name: 'Profile', component: Profile },  
  { path: '/CreateMultiTask', name: 'CreateMultiTask', component: CreateMultiTask },  
  { path: '/TotalProjectDashboard', name: 'TotalProjectDashboard', component: TotalProjectDashboard },  
  { path: '/DashboardFailedDeadline/:name', name: 'DashboardFailedDeadline', component: DashboardFailedDeadline },  
  { path: '/DashboardUserTask', name: 'DashboardUserTask', component: DashboardUserTask },  
  { path: '/EmployeesTaskDetails', name: 'EmployeesTaskDetails', component: EmployeesTaskDetails },  
  { path: '/EmployeesPogressTask', name: 'EmployeesPogressTask', component: EmployeesPogressTask },  
  { path: '/EmployeesClosedTask', name: 'EmployeesClosedTask', component: EmployeesClosedTask },  
  { path: '/CreateMultiTaskMarketing', name: 'CreateMultiTaskMarketing', component: CreateMultiTaskMarketing },  
  { path: '/QualifiedLeadList/:name', name: 'QualifiedLeadList', component: QualifiedLeadList },  
  { path: '/AdminCalendar', name: 'AdminCalendar', component: AdminCalendar },  
  { path: '/UserCalendar', name: 'UserCalendar', component: UserCalendar },  
  { path: '/UserCalendar/:name', name: 'UserCalendar', component: UserCalendar },  
  { path: '/UserSheet', name: 'UserSheet', component: UserSheet },  
  { path: '/EditUserSheet/:id&:name', name: 'EditUserSheet', component: EditUserSheet },  
  { path: '/UserSheetList', name: 'UserSheetList', component: UserSheetList },  
  { path: '/SummarySheetList', name: 'SummarySheetList', component: SummarySheetList },  
  { path: '/RFPMasterSheet', name: 'RFPMasterSheet', component: RFPMasterSheet },  






  // { path: '/Topic', name: 'TopicList', component: TopicList },
  // { path: '/AddTopic', name: 'AddTopic', component: AddTopic },
  // { path: "/EditTopic/:id", name: 'EditTopic', component: EditTopic },

  // { path: '/Role', name: 'Role', component: RoleList },
  // { path: '/AddRole', name: 'Role', component: AddRole },
  // { path: "/EditRole/:id", name: 'EditRole', component: EditRole },

  // { path: '/Countries', name: 'Countries', component: CountryList },
  // { path: '/AddCountries', name: 'AddCountries', component: AddCountry },
  // { path: "/EditCountries/:id", name: 'EditCountries', component: EditCountry },


  // { path: "/Customer", name: 'Customers', component: CustomerList },
  // { path: '/AddCustomer', name: 'AddCustomer', component: AddCustomer },
  // { path: "/EditCustomer/:id", name: 'EditRole', component: EditCustomer },

  // { path: "/Category", name: 'Customers', component: CategoryList },
  // { path: '/AddCategory', name: 'AddCustomer', component: AddCategory },
  // { path: "/EditCategory/:id", name: 'EditRole', component: EditCategory },

  // { path: "/Manufacturer", name: 'Customers', component: ManufacturerList },
  // { path: '/AddManufacturer', name: 'AddCustomer', component: AddManufacturer },
  // { path: "/EditManufacturer/:id", name: 'EditRole', component: EditManufacturer },

  // { path: "/Product", name: 'Customers', component: ProductList },
  // { path: '/AddProduct', name: 'AddCustomer', component: AddProduct },
  // { path: "/EditManufacturer/:id", name: 'EditRole', component: EditManufacturer },

  // { path: "/Discount", name: 'Discount', component: DiscountList },
  // { path: '/AddDiscount', name: 'AddDiscount', component: AddDiscount },
  // { path: "/EditDiscount/:id", name: 'EditRole', component: EditDiscount },

  // { path: '/TaxCategory', name: 'TaxCategory', component: TaxCategoryList },
  // { path: '/AddTaxCategory', name: 'AddTaxCategory', component: AddTaxCategory },
  // { path: "/EditTaxCategory/:id", name: 'EditTaxCategory', component: EditTaxCategory },

  // { path: "/Picture/:id&:name", name: 'Picture', component: Picture },

  // { path: '/ProductAttribute', name: 'ProductAttributeList', component: ProductAttributeList },
  // { path: '/AddProductAttribute', name: 'AddProductAttribute', component: AddProductAttribute },
  // { path: "/EditProduct/:id", name: 'EditProduct', component: EditProduct },

  // { path: '/ProductAttributeDetail/:id&:name', name: 'ProductAttribute', component: ProductAttribute },

  // { path: '/ProductAttributeValue/:id&:name', name: 'ProductAttributeValue', component: ProductAttributeValue },

  // { path: '/EmailAccount', name: 'EmailAccount', component: EmailAccount },

  // { path: '/State/:id&:name', name: 'State', component: StateList },
  // { path: '/AddState/:id&:name', name: 'State', component: AddState },
  // { path: '/EditState/:id&:name', name: 'State', component: EditState },

  // { path: '/ProductReview', name: 'ReviewList', component: ReviewList },
  // { path: '/EditReview/:id', name: 'Edit Review', component: EditReview },

  // { path: '/Newsletter', name: 'NewsletterList', component: NewsletterList },

  // { path: '/Poll', name: 'PollList', component: PollList },
  // { path: '/AddPoll', name: 'PollList', component: AddPoll },
  // { path: '/EditPoll/:id', name: 'PollList', component: EditPoll },
  // { path: '/PollAnswer/:id&:name', name: 'PollAnswer', component: PollAnswer },

  // { path: '/AllSettings', name: 'PollList', component: SettingList },
  // { path: '/AddSetting', name: 'PollList', component: AddSetting },
  // { path: '/EditSetting/:id', name: 'PollList', component: EditSetting },
  // { path: '/GeneralSetting', name: 'PollList', component: GeneralSetting },

]

export default routes