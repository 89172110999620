import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Service from '../../../Services/Admin/Service';

const PermitAndPassesDetailsComponent = (props) => {

    const [data, setData] = useState([]);


    const backbutton = () => {
        props.navigate(-1);
    }

    const id = props.id;
    useEffect(() => {
        getPermitAndPassesInfo("Boq/GetById?id=" + id)
    }, [])

    const getPermitAndPassesInfo = (method) => {
        Service.getdata(method).then((res) => {
            setData(res.data);
        });
    }

    return (
        <div>
            <div className="container-fluid">

                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div class="d-flex flex-start align-items-center">
                                <img class="shadow-1-strong mb-2 me-32"
                                    src="https://w7.pngwing.com/pngs/163/964/png-transparent-contract-computer-icons-rental-agreement-others-miscellaneous-angle-text.png"
                                    alt="avatar" width="50"
                                    height="40" />
                            </div>
                            <div className="col-sm-3"><h2>Permit And Passes Details</h2></div>
                            <div className="col-sm-6"></div>
                            <div className="col-sm-2 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="card cardUser shadow mb-4" >
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Details #{data.id}</h6>

                    </div>

                    <div class="container">
                        <form class="form-horizontal mt-2">
                            <div class="form-group row">
                                <div className='col-sm-5 required-label'>Construction Date</div>
                                <div className='col-sm-7'>
                                    <b>{data.constructionDate}</b>
                                </div>
                                <div class="col-sm-9">
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className='col-sm-5 required-label'>Project</div>
                                <div className='col-sm-7'>
                                    <b>{data.projectName}</b>
                                </div>
                                <div class="col-sm-9">
                                </div>
                            </div>

                            <div class="form-group row">
                                <div className='col-sm-5 required-label'>Is Passes Arranged</div>
                                <div className='col-sm-7'>
                                    <b>{data.isPassArranged}</b>
                                </div>
                                <div class="col-sm-9">
                                </div>
                            </div>

                            <div class="form-group row">
                                <div className='col-sm-5 required-label'>Arranged Date</div>
                                <div className='col-sm-7'>
                                    <b>{data.arrangedDate}</b>
                                </div>
                                <div class="col-sm-9">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PermitAndPassesDetailsComponent