import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import Service from "../../../Services/Admin/Service";
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';

const PPTListComponent = (props) => {
    const [data, setData] = useState([]);
    // const apis = ApiMethod.find(o => o.name === 'Category');

    useEffect(() => {
         getCategoryData("Presentation/GetAll");
        // getCategoryData("Task/GetAllTypetaskdetails?typename=" + props.name);
    }, [])

    // const getCategoryData = (method) => {
    //     Service.getdata(method).then((res) => {
    //         setData(res.data);
    //         console.log(res.data);
    //         debugger
    //     });
    // }

    const getCategoryData = (method) => {
        Service.getdata(method).then((res) => {
            // const filteredData = res.data.filter((element) => element.contentLink !== null);

            // Set the state with the filtered data
            setData(res.data);

            // Log the filtered data
            console.log(res.data);
            debugger
        });
    }


    const backbutton = () => {
        props.navigate(-1);
    }

    const externalLink = (data) => {
        window.open(data, '_blank')
    }

    const linkformatter = (data, row) => {
        var Co = data;
        debugger
        if (Co !== undefined && Co !== null) {
            var content = Co.split(',');
            return (
                <>
                        <div className='d-flex'>
                    {content?.map((link, index) => (
                            <div className="form-group  d-flex " key={index}>
                                <div className='btn btn-sm  ml-2 btn-info' onClick={() => externalLink(link)}>
                                    {index !== 0 ? 'Oldest Link ' + index : 'Latest Link'}
                                </div>
                            </div>
                    ))}
                        </div>
                </>
            );
        } else {
            return null; // Return null if content is undefined or null
        }
    }

    const linkformatterActions = (data, row) => {
        return <>
            {/* <Link target="_blank" rel="noopener noreferrer"  to={`${data}`}>{data}</Link> */}
            <Link className="btn btn-warning btn-sm" to={`/TaskDetails/${data}`}>See Details</Link>
        </>
    }



    const formatDateTime = (cell, row) => {
        // Format the date and time here, assuming 'time' is a string in ISO 8601 format
        const formattedDate = new Date(cell).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            // hour: '2-digit',
            // minute: '2-digit',
            // second: '2-digit',
        });
        return formattedDate;
    }

    const column = [

        { dataField: 'leadid', text: 'Lead Name', sort: true },
        { dataField: 'path', text: 'PPT Link', formatter: linkformatter },
        { dataField: 'createdate', text: 'Created Date', formatter: formatDateTime },
        // { dataField: 'id', text: 'Actions', formatter: linkformatterActions }
    ]

    return (
        <div>
            <div className="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col-sm-3"><h3>PPT List</h3></div>

                            <div className="col-sm-6"></div>
                            <div className="col-sm-3 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">List Of PPT</h6>
                        {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
                    </div>

                    <div className="card-body">
                        <div className="table-responsive">
                            <BootstrapTable keyField="id"
                                data={data}
                                columns={column}

                                pagination={paginationFactory()}
                            // filter={filterFactory()}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default PPTListComponent