import React, { useEffect, useRef, useState } from 'react'
import Service from '../../../Services/Admin/Service';
import { Link } from 'react-router-dom';

const EmployeesClosedTaskComponent = (props ) => {
    const [loading, setLoading] = useState(false); // New state for loading
    //sanjay
    const [overAllTaskData, setOverAllTaskData] = useState([]);
    const [dataName, setDataName] = useState('');
    let myRef = useRef()
    //sanjay
    const [data, setdata] = useState([]);
    const [taskData, setTaskData] = useState([]);
    const [requireDay, setRequireDay] = useState(0);
    const [spentDay, setspentDay] = useState(0);

    const [cmpltTask, setCmpltTask] = useState([]);
    //Marketing
 
    const [cmpltMarketingTask, setCmpltMarketingTask] = useState([]);
    // Marketing over
    //Sales
  
    const [cmpltSalesTask, setCmpltSalesTask] = useState([]);
    // Sales over
    //Design

    const [cmpltDesignTask, setCmpltDesignTask] = useState([]);
    // Design over
    //Operations

    const [cmpltOperationsTask, setCmpltOperationsTask] = useState([]);
    // Operations over
    //Accounts

    const [cmpltAccountsTask, setCmpltAccountsTask] = useState([]);
    // Accounts over

    useEffect(() => {
        getProjectData("Dashboard/GetAllTask");

    }, [props.id])

    const getProjectData = (method) => {
        Service.getdata(method).then((res) => {
            // setdata(...res.data);
            var r = [...res.data]
            console.log(...res.data);
            debugger
            var arr = [];
            r.map((e) => (
                arr.push(...e.lstTaskReport)
            ))
            setdata(arr);
             var totalTask = arr.filter((e) => e.taskStatus === "Closed");
            setTaskData(totalTask)
            debugger
            setOverAllTaskData(arr);
            if (overAllTaskData) {
                setLoading(true);
            }
            loadNumericData(arr);
            setDataName("OverAll Data")
            debugger
        });

    }



    const requ = [];
    let spentdata = 0;
    useEffect(() => {
        // This effect will run whenever taskData is updated

    }, [taskData]);

    function loadNumericData(data) {
        if (data.length > 0) {
            debugger
            const cmpltTasks = data.filter((e) => e.taskStatus === "Closed");
       

            setCmpltTask(cmpltTasks);

            debugger
            setRequireDay(parseInt(requ[requ.length - 1]))
            setspentDay(spentdata);

            //Marketing Start
            const cmpltMarketingTask = data.filter((e) => e.taskStatus === "Closed" && e.department === "Marketing");


            setCmpltMarketingTask(cmpltMarketingTask);
       
            //Marketing Over

            //Sales Start
            const cmpltSalesTask = data.filter((e) => e.taskStatus === "Closed" && e.department === "Sales");


            setCmpltSalesTask(cmpltSalesTask);

            //Sales Over

            //Design Start
            const cmpltDesignTask = data.filter((e) => e.taskStatus === "Closed" && e.department === "Design");


            setCmpltDesignTask(cmpltDesignTask);

            //Design Over

            //Operation Start
            const cmpltOperationsTask = data.filter((e) => e.taskStatus === "Closed" && e.department === "Operation");

            setCmpltOperationsTask(cmpltOperationsTask);

            //Operation Over

            //Operation Start
            const cmpltAccountsTask = data.filter((e) => e.taskStatus === "Closed" && e.department === "Accounts & Finance");
     

            setCmpltAccountsTask(cmpltAccountsTask);

            //    Please update task efficecy formula
            //    var taskeff = (parseInt(requ[requ.length - 1]) / spentData) * 100;
            // settaskefficency(taskeff);
        }
    }

    function FilterLoadData(status, dept, all) {

        var data = overAllTaskData;
        if (all === 1) {
            // var totalTask = data.filter((e) => e.taskStatus !== "Closed");
            // setTaskData(data);
            setDataName("OverAll Task");
        }
        else if (all === 2) {
            var openAllTask = data.filter((e) => e.taskStatus === "Open");
            setTaskData(openAllTask);
            setDataName("OverAll Open Task");
        }

        else if (all === 3) {
            var cmpltAllTask = data.filter((e) => e.taskStatus === "Closed");
            setTaskData(cmpltAllTask);
            setDataName("OverAll Closed Task");
        }

        else if (all === 4) {
            var InPogressAllTask = data.filter((e) => e.taskStatus === "WIP");
            setTaskData(InPogressAllTask);
            setDataName("OverAll InProgress Task");
        }

        else if (all === 5) {
            var holdAllTask = data.filter((e) => e.taskStatus === "Under Review");
            setTaskData(holdAllTask);
            setDataName("OverAll InHold Task");
        }

        else if (status === 1 && dept === 1) {
            var marketingOpenTask = data.filter((e) => e.taskStatus === "Open" && e.department === "Marketing");
            setTaskData(marketingOpenTask);
            setDataName("Marketing Open Task");
        }

        else if (status === 2 && dept === 1) {
            var marketingInProgressTask = data.filter((e) => e.taskStatus === "WIP" && e.department === "Marketing");
            setTaskData(marketingInProgressTask);
            setDataName("Marketing InProgress Task");
        }

        else if (status === 3 && dept === 1) {
            var marketingInholdTask = data.filter((e) => e.taskStatus === "Under Review" && e.department === "Marketing");
            setTaskData(marketingInholdTask);
            setDataName("Marketing Hold Task");
        }

        else if (status === 4 && dept === 1) {
            var marketingClosedTask = data.filter((e) => e.taskStatus === "Closed" && e.department === "Marketing");
            setTaskData(marketingClosedTask);
            setDataName("Marketing Closed Task");
        }

        else if (status === 5 && dept === 1) {
            var marketingTask = data.filter((e) => e.department === "Marketing");
            setTaskData(marketingTask);
            setDataName("Marketing Total Task");
        }

        else if (status === 1 && dept === 2) {
            var SalesOpenTask = data.filter((e) => e.taskStatus === "Open" && e.department === "Sales");
            setTaskData(SalesOpenTask);
            setDataName("Sales Open Task");
        }

        else if (status === 2 && dept === 2) {
            var SalesInProgressTask = data.filter((e) => e.taskStatus === "WIP" && e.department === "Sales");
            setTaskData(SalesInProgressTask);
            setDataName("Sales Progress Task");
        }

        else if (status === 3 && dept === 2) {
            var SalesInholdTask = data.filter((e) => e.taskStatus === "Under Review" && e.department === "Sales");
            setTaskData(SalesInholdTask);
            setDataName("Sales Hold Task");
        }

        else if (status === 4 && dept === 2) {
            var SalesClosedTask = data.filter((e) => e.taskStatus === "Closed" && e.department === "Sales");
            setTaskData(SalesClosedTask);
            setDataName("Sales Closed Task");
        }

        else if (status === 5 && dept === 2) {
            var SalesTask = data.filter((e) => e.department === "Sales");
            setTaskData(SalesTask);
            setDataName("Sales Total Task");
        }

        else if (status === 1 && dept === 3) {
            var DesignOpenTask = data.filter((e) => e.taskStatus === "Open" && e.department === "Design");
            setTaskData(DesignOpenTask);
            setDataName("Design Open Task");
        }

        else if (status === 2 && dept === 3) {
            var DesignInProgressTask = data.filter((e) => e.taskStatus === "WIP" && e.department === "Design");
            setTaskData(DesignInProgressTask);
            setDataName("Design Progress Task");
        }

        else if (status === 3 && dept === 3) {
            var DesignInholdTask = data.filter((e) => e.taskStatus === "Under Review" && e.department === "Design");
            setTaskData(DesignInholdTask);
            setDataName("Design Hold Task");
        }

        else if (status === 4 && dept === 3) {
            var DesignClosedTask = data.filter((e) => e.taskStatus === "Closed" && e.department === "Design");
            setTaskData(DesignClosedTask);
            setDataName("Design Closed Task");
        }

        else if (status === 5 && dept === 3) {
            var DesignTask = data.filter((e) => e.department === "Design");
            setTaskData(DesignTask);
            setDataName("Design Total Task");
        }

        else if (status === 1 && dept === 4) {
            var OperationOpenTask = data.filter((e) => e.taskStatus === "Open" && e.department === "Operation");
            setTaskData(OperationOpenTask);
            setDataName("Operation Open Task");
        }

        else if (status === 2 && dept === 4) {
            var OperationInProgressTask = data.filter((e) => e.taskStatus === "WIP" && e.department === "Operation");
            setTaskData(OperationInProgressTask);
            setDataName("Operation Progress Task");
        }

        else if (status === 3 && dept === 4) {
            var OperationInholdTask = data.filter((e) => e.taskStatus === "Under Review" && e.department === "Operation");
            setTaskData(OperationInholdTask);
            setDataName("Operation Hold Task");
        }

        else if (status === 4 && dept === 4) {
            var OperationClosedTask = data.filter((e) => e.taskStatus === "Closed" && e.department === "Operation");
            setTaskData(OperationClosedTask);
            setDataName("Operation Closed Task");
        }

        else if (status === 5 && dept === 4) {
            var OperationTask = data.filter((e) => e.department === "Operation");
            setTaskData(OperationTask);
            setDataName("Operation Total Task");
        }

        else if (status === 1 && dept === 5) {
            var AccountsOpenTask = data.filter((e) => e.taskStatus === "Open" && e.department === "Accounts & Finance");
            setTaskData(AccountsOpenTask);
            setDataName("Accounts Open Task");
        }

        else if (status === 2 && dept === 5) {
            var AccountsInProgressTask = data.filter((e) => e.taskStatus === "WIP" && e.department === "Accounts & Finance");
            setTaskData(AccountsInProgressTask);
            setDataName("Accounts Progress Task");
        }

        else if (status === 3 && dept === 5) {
            var AccountsInholdTask = data.filter((e) => e.taskStatus === "Under Review" && e.department === "Accounts & Finance");
            setTaskData(AccountsInholdTask);
            setDataName("Accounts Hold Task");
        }

        else if (status === 4 && dept === 5) {
            var AccountsClosedTask = data.filter((e) => e.taskStatus === "Closed" && e.department === "Accounts & Finance");
            setTaskData(AccountsClosedTask);
            setDataName("Accounts Closed Task");
        }

        else if (status === 5 && dept === 5) {
            var AccountsTask = data.filter((e) => e.department === "Accounts & Finance");
            setTaskData(AccountsTask);
            setDataName("Accounts Total Task");
        }

        window.scrollTo({ behavior: 'smooth', top: myRef.current.offsetTop });
    }

    const projectPage = () => {
        window.location.href = "/TotalProjectDashboard";
    }

    function formatDate(dateString) {
        const dateObject = new Date(dateString);

        const day = String(dateObject.getDate()).padStart(2, '0');
        const month = String(dateObject.getMonth() + 1).padStart(2, '0');
        const year = dateObject.getFullYear();

        if (day !== "NaN") {

            return `${day}-${month}-${year}`;
        }

        else {
            return ""
        }
    }



    //var req = 0;
    const dayRequiredCalculaion = (start, end) => {

        const dateDifference = new Date(new Date(end) - new Date(start));
        const day = String(dateDifference.getDate()).padStart(2, '0');

        if (requ[requ.length - 1] !== undefined) {

            var d = parseInt(requ[requ.length - 1]) + parseInt(day);
            requ.push(d);
        } else {
            requ.push(day);
        }

        // req = requ[requ.length - 1]
        console.log("Last value of requ array:", requ[requ.length - 1]);

        // if(requ.length === data.lstTaskReport.length ){
        //     setRequireDay(parseInt(requ[requ.length - 1]) )
        // }

        return `${day}`;
    }

    const daySpentCalculaion = (start, end, status) => {

        if (status === "Closed") {
            const dateDifference = new Date(new Date(end) - new Date(start));
            const day = String(dateDifference.getDate()).padStart(2, '0');
            spentdata = parseInt(spentdata) + parseInt(day);
            return `${day}`;
        }

        else {
            return 0
        }


    };

    const ProgressPercent = (e) => {
        if (e === "Open") {
            return "25%";
        }
        else if (e === "WIP") {
            return "50%";
        }
        else if (e === "Under Review") {
            return "75%";
        }
        else if (e === "Closed") {
            return "100%";
        }
    }

    const RemainingPercent = (e) => {
        if (e === "Open") {
            return "75%";
        }
        else if (e === "WIP") {
            return "50%";
        }
        else if (e === "Under Review") {
            return "25%";
        }
        else if (e === "Closed") {
            return "0%";
        }
    }


    return (
        <div>
            <div className="container-fluid">
                <br />

                <form>
                    <div className="card shadow border-success mb-2">
                        <div className="card-header rounded py-3">
                            <div className="row">
                                <div className="col-sm-8"><h3><b className='text-dark'>Employees Task Details - {data.project}</b></h3></div>
                                <div className="col-sm-1"></div>
                                <div className="col-sm-3 d-flex flex-row-reverse">
                                    <Link className="btn btn-info mt-2 ml-1  text-uppercase " to='/'>back</Link>
                                    {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            {loading === true && <h6 className="font-weight-bold text-success">All Details Info</h6>}{loading === false &&
                                <div class=" text-primary">
                                    <strong>Loading...</strong>
                                    <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                                </div>
                            }
                        </div>

                        <div className="card-body">



                            <div className='col-sm-12 row text-dark'>
                                <div className='col-sm-4'>
                                    <div className="card-header" >
                                        <h6 className="m-0 font-weight-bold text-dark">Total Completed Task</h6>
                                    </div>

                                    <div className="card-body">
                             

                                        <div className="form-group row d-flex border border-dark rounded btn" onClick={() => FilterLoadData(0, 0, 3)}>
                                            <div className='col-sm-4'>Completed</div>
                                            <div className='col-sm-5'></div>
                                            <div className='col-sm-3'> <b>{cmpltTask.length}</b></div>
                                        </div>

                                    </div>
                                </div>

                                <div className='col-sm-4'>

                                </div>
                                <div className='col-sm-4'>

                                </div>

                                {/* Department */}
                                <div className='col-sm-4'>
                                    <div className="card-header">
                                        <h6 className="m-0 font-weight-bold text-info">Marketing Completed Task</h6>
                                    </div>

                                    <div className="card-body">
                    

                                        <div className="form-group row d-flex border border-info rounded btn" onClick={() => FilterLoadData(4, 1, 0)}>
                                            <div className='col-sm-4'>Completed</div>
                                            <div className='col-sm-5'></div>
                                            <div className='col-sm-3'> <b>{cmpltMarketingTask.length}</b></div>
                                        </div>

            
                                    </div>
                                </div>

                                <div className='col-sm-4'>
                                    <div className="card-header">
                                        <h6 className="m-0 font-weight-bold text-success">Sales Completed Task</h6>
                                    </div>

                                    <div className="card-body">
    

                                        <div className="form-group row d-flex border border-success rounded btn" onClick={() => FilterLoadData(4, 2, 0)}>
                                            <div className='col-sm-4'>Completed</div>
                                            <div className='col-sm-5'></div>
                                            <div className='col-sm-3'> <b>{cmpltSalesTask.length}</b></div>
                                        </div>


                                    </div>
                                </div>

                                <div className='col-sm-4'>
                                    <div className="card-header">
                                        <h6 className="m-0 font-weight-bold text-primary">Design Completed Task</h6>
                                    </div>

                                    <div className="card-body">


                                        <div className="form-group row d-flex border border-primary rounded btn" onClick={() => FilterLoadData(4, 3, 0)}>
                                            <div className='col-sm-4'>Completed</div>
                                            <div className='col-sm-5'></div>
                                            <div className='col-sm-3'> <b>{cmpltDesignTask.length}</b></div>
                                        </div>


                                    </div>
                                </div>

                                <div className='col-sm-4'>
                                    <div className="card-header">
                                        <h6 className="m-0 font-weight-bold text-warning">Operations Completed Task</h6>
                                    </div>

                                    <div className="card-body">


                                        <div className="form-group row d-flex border border-warning rounded btn" onClick={() => FilterLoadData(4, 4, 0)}>
                                            <div className='col-sm-4'>Completed</div>
                                            <div className='col-sm-5'>Completed</div>
                                            <div className='col-sm-3'> <b>{cmpltOperationsTask.length}</b></div>
                                        </div>

   

                                    </div>
                                </div>

                                <div className='col-sm-4'>
                                    <div className="card-header">
                                        <h6 className="m-0 font-weight-bold text-danger">Finance/Accounts Completed Task</h6>
                                    </div>

                                    <div className="card-body">
              

                                        <div className="form-group row d-flex border border-danger rounded btn" onClick={() => FilterLoadData(4, 5, 0)}>
                                            <div className='col-sm-4'>Completed</div>
                                            <div className='col-sm-5'></div>
                                            <div className='col-sm-3'> <b>{cmpltAccountsTask.length}</b></div>
                                        </div>

               
                                    </div>
                                </div>

  
                            </div>

                        </div>

                    </div>
                    <h4 ref={myRef} >Filtered Data - {dataName}</h4>
                    <div className="card shadow mb-4" id='tdData' >
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">Filtered Data- {dataName}</h6>
                            {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
                        </div>

                        <div className="card-body" style={{ maxHeight: "500px", overflow: "auto", }} >
                            <div class="table-responsive overflow-x-scroll" >
                                <table class="table table-bordered">
                                    {/* <caption>List of users</caption> */}
                                    <thead className='thead-dark' style={{ position: "sticky", top: "-19px", zIndex: "1" }}>
                                        <tr>
                                            {/* <th scope="col">Task Id</th> */}
                                            <th scope="col">Work Requirement</th>
                                            <th scope="col">Department</th>
                                            <th scope="col">Project/Lead/Show</th>
                                            <th scope="col">Task HeadLine</th>
                                            <th scope="col">Starting Date</th>
                                            <th scope="col">Deadline Date</th>
                                            {/* <th scope="col">Completed Date</th> */}
                                            <th scope="col">Status</th>
                                            {/* <th scope="col">Days spent</th> */}
                                            {/* <th scope="col">Days Required</th> */}
                                            <th scope="col">Assigned by</th>
                                            <th scope="col">Assigned To</th>
                                            <th scope="col">Progress%</th>
                                            <th scope="col">Remainig%</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {taskData.map((e) => (<tr>
                                            {/* <th scope="row">{e.id}</th> */}
                                            <td>{e.taskType}</td>
                                            <td>{e.department}</td>
                                            <td>{e.project}</td>
                                            <td>{e.headline}</td>
                                            <td>{formatDate(e.startDate)}</td>
                                            <td>{formatDate(e.deadlineDate)}</td>
                                            {/* <td>{formatDate(e.taskStatus !== "Closed" ? "" : e.completedDate)}</td> */}
                                            <td>{e.taskStatus}</td>
                                            {/* <td>{daySpentCalculaion(e.startDate, e.completedDate, e.taskStatus)}</td> */}
                                            {/* <td>{dayRequiredCalculaion(e.startDate, e.deadlineDate)}</td> */}
                                            <td>{e.taskassignuserdetails?.slice(0, 1).map((t) => (
                                                <p>{t.assignerName},</p>
                                            ))}</td>
                                            <td>{e.taskassignuserdetails?.map((t) => (
                                                <p>{t.assigneeName},</p>
                                            ))}</td>
                                            <td>{ProgressPercent(e.taskStatus)}</td>
                                            <td>{RemainingPercent(e.taskStatus)}</td>
                                        </tr>))}


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>



                </form>
            </div>

        </div>
    )
}


export default EmployeesClosedTaskComponent