import React, { Component } from 'react'

export class Footer extends Component {
  render() {
    return (
      <div className='page-container'>
        <footer className="sticky-footer" style={{backgroundColor:"#d7ffce"}}>
          <div className="container my-auto" >
            <div className="copyright text-center my-auto" >
              <span>Copyright © XS-Worldwide 2023</span>
            </div>
          </div>
        </footer>
      </div>
    )
  }
}

export default Footer  