import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Service from '../../../Services/Admin/Service';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
const TotalProjectDashboardComponent = (props) => {

    const userId = sessionStorage.getItem("id")
    const [data, setData] = useState([]);
    var role = sessionStorage.getItem("role");
    var deptId = sessionStorage.getItem("DeptId");

    useEffect(() => {
        getCategoryData("Project/GetAllByUserId?id=" + userId);
        // getCategoryData("Project/GetAll");
    }, [])

    const getCategoryData = (method) => {
        Service.getdata(method).then((res) => {
            var result = filterTasksWithUniqueIds(res.data);
            var date = new Date();
            date.setHours(0, 0, 0, 0);
            var data = result.filter((e) => new Date(e.enddate) > date)
            console.log(data);
            debugger
            setData(data.reverse());
        });
    }

    const staticname1 = "details";
    const staticname2 = "edit";


    function filterTasksWithUniqueIds(tasks) {
        // Create an object to store unique tasks based on their ID
        const uniqueTasks = tasks.reduce((acc, task) => {
            // Use the task ID as the key in the object
            const taskId = task.id;

            // If the task ID is not in the object, add it
            if (!acc[taskId]) {
                acc[taskId] = task;
            }

            return acc;
        }, {});

        // Convert the uniqueTasks object back to an array
        const uniqueTasksArray = Object.values(uniqueTasks);

        return uniqueTasksArray;
    }


    const backbutton = () => {
        props.navigate(-1);
    }


    const formatDateTime = (cell, row) => {
        // Format the date and time here, assuming 'time' is a string in ISO 8601 format
        const formattedDate = new Date(cell).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            // hour: '2-digit',
            // minute: '2-digit',
            // second: '2-digit',
        });
        return formattedDate;
    }
    const linkformatter = (data, row) => {
        return <>
            &nbsp; <Link className="btn btn-warning btn-sm" to={`/DetailProject/${data}`}>Details</Link>
        </>
    }

    const column = [
        { dataField: 'projectname', text: 'Project Name', sort: true },
        { dataField: 'createddate', text: 'Created Date', sort: true, formatter: formatDateTime },
        { dataField: 'id', text: 'Actions', formatter: linkformatter }
    ]

    return (
        <div>
            <div className="container-fluid">

                <div className="card shadow border-success mb-2">
                    <div className="card-header rounded py-3">
                        <div className="row">
                            <div className="col-sm-3"><h3><b className='text-dark'>Projects</b></h3></div>
                            <div className="col-sm-6"></div>
                            <div className="col-sm-3 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-2  text-uppercase " onClick={() => backbutton()}>back</button>
                                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-success">Details Of Projects</h6>
                        {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
                    </div>

                    <div className="card-body">
                        <div className="table-responsive">
                            <BootstrapTable keyField="id"
                                data={data}
                                columns={column}
                                rowStyle={{ color: "black" }}
                                pagination={paginationFactory()}
                            // filter={filterFactory()}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default TotalProjectDashboardComponent