import React, { useState } from 'react'
import { Editor } from "react-draft-wysiwyg";
import Service from "../../../Services/Admin/Service";
import ApiMethod from '../../../Helpers/ApiMethod/Method'
import { convertToRaw, EditorState, convertFromHTML, ContentState, convertFromRaw } from "draft-js";
import TicketDetailsComponent from "./TicketDetailsComponent.css"


// // import Service from "../../../Services/Admin/Service";


function TicketDetails(props) {

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    const handleEditorChange = (state) => {
        setEditorState(state);
    }

    const backbutton = () => {
        props.navigate(-1);
    }
    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className='col-sm-9 text-left'></div>
                    <div className='col-sm-3'><button className="btn btn-info btn-sm" onClick={() => backbutton()}>back</button></div>
                </div>
                <br />

                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col-sm-3"><h3>Ticket Details</h3></div>
                            <div className="col-sm-6"></div>
                        </div>
                    </div>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div class="card-body" style={{ display: "flex" }}>
                                <img class="rounded-circle shadow-1-strong me-3"
                                    src="https://w7.pngwing.com/pngs/184/113/png-transparent-user-profile-computer-icons-profile-heroes-black-silhouette-thumbnail.png" alt="avatar" width="45"
                                    height="45" />
                                <Editor
                                    editorState={editorState}
                                    wrapperClassName="demo-wrapper1"
                                    editorClassName="demo-editor1"
                                    onEditorStateChange={handleEditorChange}
                                />
                            </div>
                            <div className='col-sm-6 ml-5'><button className="btn btn-primary btn-sm mb-2" onClick={() => backbutton()}>save</button></div>
                            <div className='col-sm-5 text-left'></div>

                                <div class="card-body card-body1">
                                    <div class="d-flex flex-start align-items-center">
                                        <img class="rounded-circle shadow-1-strong me-3"
                                            src="https://w7.pngwing.com/pngs/184/113/png-transparent-user-profile-computer-icons-profile-heroes-black-silhouette-thumbnail.png" alt="avatar" width="45"
                                            height="45" />
                                        <div>
                                            <h6 class="font-weight-bold text-primary ml-2 mb-2">Lily Coleman</h6>
                                            <p class="text-muted small ml-2 mb-2">
                                                Shared publicly - Jan 2020
                                            </p>
                                        </div>
                                    </div>

                                    <p class="mt-2 mb-0 pl-5 pb-1">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                        quis nostrud exercitation ullamco laboris nisi ut aliquip consequat.
                                    </p>

                                    <div class="small d-flex justify-content-start pl-5">
                                        <a href="#!" class="d-flex align-items-center me-3">
                                            <i class="far fa-thumbs-up me-2"></i>
                                            <p class="mb-0">Like</p>
                                        </a>
                                        <a href="#!" class="d-flex align-items-center me-3">
                                            <i class="fas fa-reply ml-2 me-2"></i>
                                            <p class="mb-0">Reply</p>
                                        </a>
                                    </div>
                                </div>
                                <div class="card-body card-body3 mt-2">
                                    <div class="d-flex flex-start align-items-center">
                                        <img class="rounded-circle shadow-1-strong me-3"
                                            src="https://w7.pngwing.com/pngs/184/113/png-transparent-user-profile-computer-icons-profile-heroes-black-silhouette-thumbnail.png" alt="avatar" width="45"
                                            height="45" />
                                        <div>
                                            <h6 class="font-weight-bold text-primary ml-2 mb-2">Lily Coleman</h6>
                                            <p class="text-muted small ml-2 mb-2">
                                                Shared publicly - Jan 2020
                                            </p>
                                        </div>
                                    </div>

                                    <p class="mt-0 mb-0 pl-5 pb-1">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                        quis nostrud exercitation ullamco laboris nisi ut aliquip consequat.
                                    </p>

                                    <div class="small d-flex justify-content-start pl-5">
                                        <a href="#!" class="d-flex align-items-center me-3">
                                            <i class="far fa-thumbs-up me-2"></i>
                                            <p class="mb-0">Like</p>
                                        </a>
                                        <a href="#!" class="d-flex align-items-center me-3">
                                            <i class="fas fa-reply ml-2 me-2"></i>
                                            <p class="mb-0">Reply</p>
                                        </a>
                                    </div>
                                </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default TicketDetails
