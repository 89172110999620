import React, { useEffect, useState } from 'react'
import Service from '../../../Services/Admin/Service';
import Select from 'react-select';
import { Link } from 'react-router-dom';

const CreateLeadComponent = (props) => {

    const userId = sessionStorage.getItem("id");
    let DeptId = sessionStorage.getItem('DeptId');

    let id = sessionStorage.getItem('id');
    const [RFPError, setRFPErrror] = useState(false);
    const [apiError, setApiError] = useState(null);
    const [assigneeError, setAssigneeError] = useState(false);
    const [dropdownUser, setdropdownUser] = useState([]);
    const [selectedAssignees, setSelectedAssignees] = useState([]);
    const [apiResponse, setApiResponse] = useState(null);
    const [exhibitorData, setExhibitordata] = useState([]);
    const [showData, setShowdata] = useState([]);
    const [selectedShow, setSelectedShow] = useState("");
    const [inputField, setInputField] = useState({

        showName: '',
        exhibitorName: '',
        history: "",
        background: "",
        venue: "",
        isConvincing: true,
        directLead: true,
        comments: "",
        createdby: id,
        updatedby: id,

    }); // State to store the selected show ID

    useEffect(() => {
        getUserData("Users/GetAll");
    }, []);

    const handleSelectChange = (selectedOptions) => {
        setSelectedAssignees(selectedOptions);
    };
    const getUserData = (method) => {
        Service.getdata(method).then((res) => {
            var data = res.data;
            var intdeptId = parseInt(DeptId)
            var list = [];



            data.forEach((d, index) => {
                var role = d.lstRole;
                var checkdepartment = role.find(x => x.depId === 2 || x.depId === 4)
                if (checkdepartment !== undefined) {
                    d.rolename = d.lstRole[0].name;
                    list.push(d);
                }
            });
            setdropdownUser(list);

        });
    }


    const handleShowChange = (evt) => {
        const selectedShowId = evt.target.value;
        setSelectedShow(selectedShowId); // Update the selected show ID
    }

    // Add selectedShow as a dependency to trigger the effect when it changes


    useEffect(() => {
        if (apiResponse || apiError || RFPError) {
            const timeoutId = setTimeout(removeSuccessMessage, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [apiResponse, apiError, RFPError]);

    const removeSuccessMessage = () => {
        setApiResponse(null);
        setApiError(null);
        setRFPErrror(null)
    };

    const createLead = async (evt) => {
        if (evt && evt.preventDefault) {
            evt.preventDefault();
        }

        const formData = new FormData(evt.target);

        const data = {}
        for (const [key, value] of formData) {
            data[key] = value;
        }

        function validateData(data) {
            const { exhibitorName, salesHeadId, showName, startDate, endDate } = data;


            // if(!exhibitorName || showName || startDate || endDate){
            //     window.alert
            // }

            if (!salesHeadId || salesHeadId === "[NONE]") {
                setAssigneeError(true);
                return false;
            } else {
                setAssigneeError(false);
            }
            return true
        }

        if (!validateData(data)) {
            return;
        }

        const isConvincingValue = data['isConvincing'] === 'true';
        const task = {
            ...inputField,
            ...data,
            isConvincing: isConvincingValue,

        };
        console.log(task);
        debugger
        if (task) {
            Service.createorupdate("Lead/CreateOrUpdate", task)
                .then(res => {
                    setApiResponse(res.data);
                    window.location.href = "/SharedLeadList";

                })
                .catch(error => {
                    // Handle API error, if necessary
                    console.error("API Error:", error);
                    setApiError("Error occurred while saving lead. Please try again later.");
                });
        }
    }

    const backbutton = () => {
        props.navigate(-1);
    }
    return (
        <div>
            <div className="container-fluid">
                <br />
                <div className="card shadow border-success mb-2">
                    <div className="card-header rounded py-3">
                        <div className="row">
                            <div className="col-sm-4"><h3>Create Lead</h3></div>
                            <div className="col-sm-6"></div>
                            <div className="col-sm-2 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link>
                            </div>

                        </div>
                    </div>
                </div>
                <form>
                    <div className="container-fluid">
                        <div className="card cardUser shadow mb-4" >
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">Add Lead</h6>
                                {apiResponse && (
                                    <div className="alert alert-success" role="alert">
                                        Lead has been Shared successfully!
                                    </div>
                                )}
                                {apiError && (
                                    <div className="alert alert-danger" role="alert">
                                        {apiError}
                                    </div>
                                )}
                            </div>

                            <div class="container">
                                <form class="form-horizontal mt-2" onSubmit={createLead}>
                                    <div className="form-group">
                                        <div className='col-sm-9 required-label'>Show Name </div>
                                        <div className='col-sm-9'>
                                            {RFPError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a show and exhibitor.</div>}
                                            <input type='text' name='showName' placeholder='enter showName ' onClick={handleShowChange} class="form-control" autofocus required />
                                        </div>
                                    </div>

                                    {/* <div className="form-group">
                                        <div className='col-sm-9 required-label'>Show List sales</div>
                                        <div className='col-sm-9'>
                                            {RFPError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a show and exhibitor.</div>}

                                            <select className="form-control form-control-fs  form-control-user" name="showid" onChange={handleShowChange} required>
                                                <option>[NONE]</option>
                                                {
                                                    showData.map((e, key) => {
                                                        return <option key={e.showChapterId} value={e.showId}>{e.showName}</option>;
                                                    })}
                                            </select>
                                        </div>
                                    </div> */}
                                    <div className="form-group">
                                        <div className='col-sm-3 required-label'>Exhibitor Name</div>
                                        <div className='col-sm-9'>
                                            <input type='text' name='exhibitorName' placeholder='enter Exhibitor ' class="form-control" autofocus required />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className='col-sm-9 required-label'>Show Start Date</div>
                                        <div className='col-sm-9'>
                                            <input type='date' name='startDate' placeholder='enter Exhibitor ' class="form-control" autofocus required />

                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className='col-sm-9 required-label'>Show End Date</div>
                                        <div className='col-sm-9'>
                                            <input type='date' name='endDate' placeholder='enter Exhibitor ' class="form-control" autofocus required />

                                        </div>
                                    </div>


                                    <div class="form-group">
                                        <label for="objective" class="col-sm-3 control-label required-label">Venue </label>
                                        <div class="col-sm-9">
                                            <textarea
                                                rows={1} // You can adjust the number of visible rows
                                                cols={105}
                                                name='venue'
                                                placeholder='enter Venue ' // You can adjust the number of visible columns
                                                class="form-control"
                                                autofocus
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="objective" class="col-sm-3 control-label">History </label>
                                        <div class="col-sm-9">
                                            <textarea
                                                rows={2} // You can adjust the number of visible rows
                                                cols={105}
                                                name='history'
                                                placeholder='enter History ' // You can adjust the number of visible columns
                                                class="form-control"
                                                autofocus
                                            />
                                        </div>
                                    </div>


                                    <div class="form-group">
                                        <label for="objective" class="col-sm-3 control-label">Background </label>
                                        <div class="col-sm-9">
                                            <textarea
                                                rows={2} // You can adjust the number of visible rows
                                                cols={105}
                                                name='background'
                                                placeholder='enter background' // You can adjust the number of visible columns
                                                class="form-control"
                                                autofocus
                                            />
                                        </div>
                                    </div>



                                    <div class="form-group">
                                        <label for="objective" class="col-sm-3  control-label">Comments </label>
                                        <div class="col-sm-9">
                                            <textarea
                                                rows={2} // You can adjust the number of visible rows
                                                cols={105}
                                                name='comments'
                                                placeholder='enter Comments' // You can adjust the number of visible columns
                                                class="form-control"
                                                autofocus
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <div className="col-sm-9 required-label">Lead Assigned To</div>
                                        <div className="col-sm-9">
                                            {assigneeError && <div className="alert alert-danger  mt-3" role="alert">Please select a assignee</div>}
                                            <Select
                                                name="salesHeadId"
                                                isMulti={true}
                                                placeholder="Enter Assignee "
                                                options={dropdownUser.map((user) => ({
                                                    value: user.id,
                                                    label: `${user.firstname} ${user.lastname} - ${user.rolename}`,
                                                }))}
                                                value={selectedAssignees}
                                                onChange={handleSelectChange}
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group" hidden={true}>

                                        <div class="col-sm-9">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    value="false"
                                                    name='isConvincing'
                                                    id="flexCheckChecked"
                                                />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    Is Convincing
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {apiResponse && (
                                        <div className="alert alert-success" role="alert">
                                            Lead has been craeted successfully!
                                        </div>
                                    )}
                                    {apiError && (
                                        <div className="alert alert-danger" role="alert">
                                            {apiError}
                                        </div>
                                    )}
                                    <button type="submit" class="btn btn-primary ml-2 mb-3">Save</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CreateLeadComponent