import React, { useEffect, useState } from 'react';
import CustomerService from '../../../Services/Admin/Service';
import Service from "../../../Services/Admin/Service";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Select from 'react-select';
import { useDropzone } from 'react-dropzone';
import '../TestingComponent/testing.css';  // Ensure this path is correct
import draftToHtml from 'draftjs-to-html';
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState, convertFromHTML, ContentState, convertFromRaw } from "draft-js";
import { Link } from 'react-router-dom';
import { Mention, MentionsInput } from 'react-mentions';
import defaultStyle from '../Task/defaultStyle';
import defaultMentionStyle from '../Task/defaultMentionStyle';
import { getLoginUserData, hasLink } from '../../../Helpers/supportedFunctions/supportedGlobalFunctions';

const TaskDetailsComponent = (props) => {



    const [taskDetail, setTaskDetail] = useState([])
    const [loadingImage, setLoadingImage] = useState(false);
    const [loading, setLoading] = useState(false); // New state for loading
    const [cmntLoading, setCmntLoading] = useState(false); // New state for loading

    const id = props.id;
    let DeptId = sessionStorage.getItem('DeptId');

    var Createdby = 0;

    const userId = sessionStorage.getItem("id");
    const userEdit = parseInt(userId);
    const [detailFlag, setDetailFlag] = useState(false);
    const [detailFlagEdit, setDetailFlagEdit] = useState(false);
    var currentuserid = parseInt(userId);
    const senderName = sessionStorage.getItem("name");
    const [status, setStatus] = useState([]);
    const [commentInput, setCommentInput] = useState({
        comments: ''
    });
    const [comments, setComments] = useState([]);
    const [userName, setUserName] = useState([]);
    const [designUser, setDesignUser] = useState([]);
    const [rfpLead, setRFPLead] = useState([]);
    const [userNameData, setUserNameData] = useState([]);
    const [statusId, setStatusId] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [taskassignerid, settaskassignerid] = useState(0);
    const [contentLink, setContentLink] = useState([]);
    const [latestContentLink, setLatestContentLink] = useState('');
    const [oldContentLink, setOldContentLink] = useState('');
    const [dropdownTaskType, setdropdownTaskType] = useState([]);
    const [showAssigneePopup, setShowAssigneePopup] = useState(false);
    const [isRequested, setIsRequested] = useState(false);
    const [TaskTypeid, setTaskTypeid] = useState('');
    const [headline, setHeadline] = useState('');
    const [taskPriority, setTaskPriority] = useState([]);
    const [file, setFile] = useState(null);
    const [taskPriorityid, settaskPriorityid] = useState('');
    const [dateId, setdateId] = useState([]);
    const [departent, setDepartment] = useState([]);
    const [show, setShow] = useState([]);
    const [showId, setShowId] = useState([]);
    const [lead, setLead] = useState([]);
    const [leadId, setLeadId] = useState([]);
    const [departentid, setdepartentid] = useState('');
    const [Projectdata, setProjectdata] = useState([]);
    const [projectId, setProjectid] = useState([]);
    const [statusError, setStatusError] = useState(false);
    const [showDiscussion, setShowDiscussion] = useState(false);
    const [apiResponseComment, setapiResponseComment] = useState(null)
    const [apiResponse, setApiResponse] = useState(null);
    const [apiSharedResponse, setApiSharedResponse] = useState(null);
    const [apiError, setApiError] = useState(null);
    const [discussionData, setDiscussionData] = useState([]);
    const [taskAssignerId, settaskAssignerId] = useState(0);
    const [selectedAssignees, setSelectedAssignees] = useState([]);
    const [showImage, setShowImage] = useState('');

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
const [allFiles, setAllFiles] = useState([]);
    const [inputField, setInputField] = useState({
        id: id,
        headline: '',
        shortDescription: '',
        projectId: '',
        parentId: null,
        showId: 0,
        leadId: 0,
        typeId: 0,
        statusId: 0,
        priorityId: 0,
        planhour: 0,
        finishdate: '',
        createdby: id,
        updatedby: userId,
        assignerid: id,
        isDeleted: false,
        isActive: true,
        assigneeid: [0],
        contentLink: null,
        removeAssigneeid: []
    })

    const [sharedData, setSharedData] = useState({
        id: 0,
        headline: '',
        shortDescription: '',
        projectId: 0,
        parentId: props.id,
        typeId: 0,
        statusId: 1,
        priorityId: 0,
        planhour: 0,
        finishdate: '',
        createdby: id,
        updatedby: id,
        assignerid: id,
        isDeleted: false,
        isActive: true,
        assigneeid: [0],
        contentLink: null,
        removeAssigneeid: []

    })

    const [inputQuestionare, setInputQuestionare] = useState({
        taskid: 0,
        description: "",
        senderid: 0,
        senderName: "",
        receiverid: 0,
        receiverName: "",
        isRead: false,
        createdby: userId,
    })

    //final designer
    const handleChange = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputmultiDesignerFields];
        list[index][name] = value;
        setinputmultiDesignerFields(list);
    }


    const [inputmultiDesignerFields, setinputmultiDesignerFields] = useState([{
        particularsName: '',
        deadlineClient: null,
        adheredClient: null,
        remarksClient: '',
        deadlineInternal: null,
        adheredInternal: null,
        remarksInternal: '',
        deadlineSupplier: null,
        adheredSupplier: null,
        remarksSupplier: '',
    }]);

    const addInputField = () => {
        setinputmultiDesignerFields([...inputmultiDesignerFields, {
            particularsName: '',
            deadlineClient: null,
            adheredClient: '',
            remarksClient: '',
            deadlineInternal: null,
            adheredInternal: '',
            remarksInternal: '',
            deadlineSupplier: null,
            adheredSupplier: '',
            remarksSupplier: '',
        }])

    }

    const removeInputFields = (index) => {
        const rows = [...inputmultiDesignerFields];
        rows.splice(index, 1);
        setinputmultiDesignerFields(rows);
    }
    // set final designer

    const [name, setName] = useState({
        id: '',
        display: '',
    })

    const mentionData = (data) => {

        var arr = [];

        data.forEach((x, index) => {
            var abc = {};

            abc.id = x.assigneeName;
            abc.display = x.assigneeName;
            arr.push(abc);


        })

        var createdUser = data[0];
        var abc = {};
        abc.id = createdUser.assignerName;
        abc.display = createdUser.assignerName;
        arr.push(abc);
        setUserNameData(arr);
    }


    const handleDiscussionButtonClick = () => {
        setShowDiscussion((prevShowDiscussion) => !prevShowDiscussion);
    };

    const [inputDiscussion, setInputDiscussion] = useState({
        "discussionid": 0,
        "taskid": props.id,
        "userid": sessionStorage.getItem("id")
    })

    const initCall = () => {
        getDiscussionData("Task/GetALLTaskDiscussion?taskid=" + inputDiscussion.taskid);
        getTaskInfo("Task/GetById?id=" + id)
        getTaskTypeData("TaskType/GetAll");
        getShowData("Shows/GetAllShows");
        getLeadData("Lead/GetAllLead");
        getRFPAll("RFP/GetAll?id=" + 1);
        getProjectData("Project/GetAll");
        getTaskPriority("Priority/GetAll")
        getUserData("Users/GetAll");
        getDepartmentData("Department/GetAll");
        updatenotification("Questionare/readnotification?taskid=" + props.id + "&userid=" + userId)
        getStatusData("Status/GetAll");

    }


    useEffect(() => {
        initCall();
    }, [])


    function filterTasksWithUniqueIds(tasks) {
        const uniqueTasks = tasks.reduce((acc, task) => {
            const taskId = task.id;

            if (!acc[taskId]) {
                acc[taskId] = task;
            }

            return acc;
        }, {});

        const uniqueTasksArray = Object.values(uniqueTasks);

        return uniqueTasksArray;
    }

    const getStatusData = (method) => {
        Service.getdata(method).then((res) => {
            setStatus(res.data);
        });
    }
    const updatenotification = (method) => {
        Service.getdata(method).then((res) => {

        });
    }

    const redirectToRFP = () => {

        var rfpDetails = rfpLead.filter((x) => x.name === leadId.toString());
        var projectLead = Projectdata.filter((x) => x.id === projectId);
        if (projectLead.length !== 0) {

            var ProjectRfp = rfpLead.filter((x) => x.leadid === projectLead[0].leadid)
            console.log(rfpDetails);
            window.location.href = `/RFPDetails/${ProjectRfp[0].id}`;
        }

        else if (rfpDetails.length !== 0) {
            window.location.href = `/RFPDetails/${rfpDetails[0].id}`;
        }

    }

    const getShowData = (method) => {
        Service.getdata(method).then((res) => {
            setShow(res.data);
        });
    }

    const getLeadData = (method) => {
        Service.getdata(method).then((res) => {

            if (DeptId !== 3) {
                const filteredData = res.data.filter((element) => element.isConvincing !== false);
                setLead(filteredData);

            }
            else {
                setLead(res.data);
            }
            // setLead(res.data);

        });
    }

    const getProjectData = (method) => {
        Service.getdata(method).then((res) => {
            setProjectdata(res.data);
        });
    }

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };
    const handleFileUpload = async (e) => {
        setLoadingImage(true);

        const file = e.target.files[0];
        const base64 = await convertToBase64(file);
        setShowImage(base64);
        const comment = {
            ...inputDiscussion,

        };
        comment.comments = base64;
        comment.msgType = "Image";
        console.log(comment)
        const data1 = [];
        var users = [];
        users.push(...selectedAssignees)
        users.push(taskDetail.createdby)
        console.log(selectedAssignees)
        console.log(users)
        for (var i = 0; i < users.length; i++) {
            var task1 = {};
            if (i === users.length - 1) {

                task1 = {
                    ...inputQuestionare,

                    taskid: props.id,
                    description: "Image Added in Task By",
                    receiverid: taskDetail.createdby,
                    receiverName: taskDetail.taskassignuserdetails[0].assignerName,
                    senderid: userId,
                    senderName: senderName,
                };
            }
            else {

                task1 = {
                    ...inputQuestionare,

                    taskid: props.id,
                    description: "Image Added in Task By",
                    receiverid: users[i],
                    receiverName: taskDetail.taskassignuserdetails[i].assigneeName,
                    senderid: userId,
                    senderName: senderName,
                };
            }

            data1.push(task1)
        }
        // for notification
        console.log(data1)

        if (comment) {
            Service.createorupdate("Task/CreateOrUpdateDiscussion", comment)
                .then(res => {

                    setapiResponseComment(res.data); // Set the API response in state
                    setComments(prevComments => [...prevComments, comment]);
                    getDiscussionData("Task/GetALLTaskDiscussion?taskid=" + inputDiscussion.taskid);
                    setCommentInput({ comments: '' });
                    if (data1) {
                        for (var m = 0; m <= data1.length; m++) {
                            console.log(data1[m]);

                            Service.createorupdate("Questionare/CreateOrUpdate", data1[m])
                                .then(res => {
                                    if (res.data) {
                                        setLoading(false);
                                        setLoadingImage(false);

                                    }
                                    // setApiResponse(res.data);

                                })
                                .catch(error => {

                                    console.error("API Error:", error);
                                });
                        }
                        window.location.reload(true);
                    }
                })
                .catch(error => {
                    // Handle error if API call fails
                    console.error("API Error:", error);
                });
        }
        // setPostImage({ ...postImage, myFile: base64 });
    };

    const externalLinkImage = (data) => {
        const newWindow = window.open();
        newWindow.document.write('<html><body>');
        newWindow.document.write(`<img src="${data}" alt="Base64 Image" />`);
        newWindow.document.write('</body></html>');

    }


    const getUserData = (method) => {
        Service.getdata(method).then((res) => {
            setUserName(res.data);
            var data = res.data;
            var intdeptId = parseInt(DeptId)
            var list = [];

            data.forEach((d, index) => {
                var role = d.lstRole;
                var checkdepartment = role.find(x => x.depId === 3)
                if (checkdepartment !== undefined) {
                    d.rolename = d.lstRole[0].name;
                    list.push(d);
                }
            });
            setDesignUser(list);

        });
    }

    const getDiscussionData = (method) => {
        Service.getdata(method).then((res) => {
            const updatedData = res.data.map((item) => ({
                ...item,
                comments: hasLink(item.comments),
            }));
            setDiscussionData(res.data);
            setComments(updatedData); // Set the comments in the state
        });
    }

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        console.log(selectedFile)

        // Handle the selected file as needed
        console.log('Selected file:', selectedFile);
    };

    useEffect(() => {
        if (apiResponse || apiError || apiSharedResponse) {
            const timeoutId = setTimeout(removeSuccessMessage, 3000);

            return () => clearTimeout(timeoutId);
        }
    }, [apiResponse, apiError, apiSharedResponse]);

    useEffect(() => {
        if (apiResponseComment) {
            const timeoutId = setTimeout(removeSuccessMessage, 3000);

            return () => clearTimeout(timeoutId);
        }
    }, [apiResponseComment]);

    const removeSuccessMessage = () => {
        setApiResponse(null);
        setapiResponseComment(null);
        setApiError(null);
        setApiSharedResponse(null);
    };


    const getTaskTypeData = (method) => {
        Service.getdata(method).then((res) => {
            setdropdownTaskType(res.data)

        });
    }

    const getTaskPriority = (method) => {
        Service.getdata(method).then((res) => {
            setTaskPriority(res.data);
        });
    }

    const getDepartmentData = (method) => {
        Service.getdata(method).then((res) => {
            setDepartment(res.data);
        });
    }

    const getTaskInfo = (method) => {
        Service.getdata(method).then((res) => {
            setTaskDetail(res.data);
            console.log("Task Details", res.data)

            settaskassignerid(res.data.createdby);
            setAllFiles(res.data.allfiles.reverse());
            debugger
            Createdby = res.data.createdby;
            if (res.data.taskassignuserdetails.length !== 0) {
                var taskAssignerId = res.data.taskassignuserdetails[0].assignerid;
                settaskAssignerId(taskAssignerId)

            }

            if (res.data.shortDescription !== null) {
                const content1 = convertFromRaw(JSON.parse(res.data.shortDescription));
                setEditorState(EditorState.createWithContent(content1));
            }

            setStatusId(res.data.statusId);
            setHeadline(res.data.headline);
            setTaskTypeid(res.data.typeId);
            settaskPriorityid(res.data.priorityId);
            setIsRequested(res.data.isRequested);
            setProjectid(res.data.projectId);
            setShowId(res.data.showId);
            setLeadId(res.data.leadid);
            setDueDate(res.data.finishdate);

            var Co = res.data.contentLink;

            if (Co !== undefined && Co !== null) {
                setOldContentLink(res.data.contentLink);
                var content = Co.split(',');
                setContentLink(content);
            }
            setdepartentid("sales");
            setdateId(res.data.finishdate);

            const assigneeIds = res.data.taskassignuserdetails.map((assignee) => assignee.assigneeid);
            setSelectedAssignees(assigneeIds);

            mentionData(res.data.taskassignuserdetails);


            var bar = '' + res.data.createdby;

            if (res.data.statusId === 4 && userId !== bar) {
                setDetailFlag(true);
            }

            if (res.data.createdby !== userEdit) {
                setDetailFlagEdit(true);
            }
            else {
                setDetailFlagEdit(false);
            }
        });
    }


    const getRFPAll = (method) => {
        Service.getdata(method).then((res) => {
            var uniqueRFP = res.data.filter((x) => parseInt(x.name) === x.leadMainId);

            setRFPLead(uniqueRFP);



        });
    }

    const handleStatusChange = (e) => {
        setStatusId(e.target.value);
    }

    const handledueDateChange = (e) => {
        setDueDate(e.target.value);

    }
    const handleTaskChange = (e) => {
        setTaskTypeid(e.target.value);

    }

    const handleLinkChange = (e) => {
        setLatestContentLink(e.target.value)
    }

    const handlePriorityChange = (e) => {
        settaskPriorityid(e.target.value);

    }
    const handleProjectChange = (e) => {
        setProjectid(e.target.value);

    }
    const handleShowChange = (e) => {
        setShowId(e.target.value);

    }

    const handleLeadChange = (e) => {
        setLeadId(e.target.value);

    }

    function extractValuesFromIndexedObject(indexedObject) {
        return Object.values(indexedObject).map(item => item.value);
    }

    const handleSelectChange = (selectedOptions) => {
        const extractedValues = extractValuesFromIndexedObject(selectedOptions);
        setSelectedAssignees(extractedValues);

    };

    const shared = (evt) => {
        setLoading(true);

        const task = {
            ...sharedData,

            assignerid: userId,
            id: props.id,

            assigneeid: selectedAssignees,
        };

        if (task) {
            Service.createorupdate("Task/CreateOrUpdateShareTask", task)
                .then(res => {
                    if (res.status === 200) {
                        setLoading(false);
                        setApiSharedResponse(res.data);
                        setApiResponse(null);
                        initCall();
                    }
                })
                .catch(error => {

                    console.error("API Error:", error);
                    setApiError("Error occurred Please try again later.");
                    setLoading(false);

                });
        }
    }



    const submitButton = (evt) => {

        if (evt && evt.preventDefault) {
            evt.preventDefault();
        }
        setLoading(true);

        const formData = new FormData(evt.target);

        const data = {}
        for (const [key, value] of formData) {
            data[key] = value;
        }

        const { headline, statusId, projectId, typeId, priorityId, assigneeid, finishdate, contentLink } = data;




        const currentContent = editorState.getCurrentContent();
        const messageBody = convertToRaw(currentContent);
        var jsonData = JSON.stringify(messageBody);


        let currentContentAsHTML = draftToHtml(messageBody);


        inputField.shortDescription = jsonData;
        if (data.projectId === undefined) {
            data.projectId = 0;
        }
        if (data.showId === "[NONE]") {
            data.showId = 0;
        }
        if (data.leadid === "[NONE]") {
            data.leadid = 0;
        }

        if (oldContentLink !== "" && oldContentLink !== null) {

            if (latestContentLink === "") {
                data.contentLink = oldContentLink;

            }
            else {

                data.contentLink = latestContentLink + "," + oldContentLink;
            }

        }
        else {
            if (contentLink === "") {
                data.contentLink = null;
            }
            else {
                data.contentLink = latestContentLink;
            }

        }

        inputField.createdby = taskAssignerId;
        inputField.updatedby = getLoginUserData().userId;
        inputField.assignerid = taskAssignerId;
        const task = {
            priorityId: taskPriorityid,
            ...inputField,
            ...data,
            assigneeid: selectedAssignees,
        };
        console.log(task)
        const updatedAssignees = [...selectedAssignees, taskDetail.createdby];


        const data1 = []
        for (var i = 0; i < selectedAssignees.length; i++) {

            const task1 = {
                ...inputQuestionare,

                taskid: props.id,
                description: "Task Status Change",
                receiverid: selectedAssignees[i],
                receiverName: taskDetail.taskassignuserdetails[i].assigneeName,
                senderid: userId,
                senderName: senderName,
                createdby: taskDetail.createdby,
            };

            data1.push(task1)
        }
        data1.push({
            ...inputQuestionare,

            taskid: props.id,
            description: "Task Status Change By",
            receiverid: taskDetail.createdby,
            receiverName: taskDetail.taskassignuserdetails[0].assignerName,
            senderid: userId,
            senderName: senderName,
            createdby: taskDetail.createdby,
        })
        // for notification
        console.log(data1)

        if (task) {
            Service.createorupdate("Task/CreateOrUpdate", task)
                .then(res => {
                    if (data1) {
                        for (var m = 0; m <= data1.length; m++) {
                            console.log(data1[m]);

                            Service.createorupdate("Questionare/CreateOrUpdate", data1[m])
                                .then(res => {
                                    if (res.data) {
                                        setLoading(false);
                                    }
                                    // setApiResponse(res.data);
                                })
                                .catch(error => {

                                    console.error("API Error:", error);
                                });
                        }
                        window.location.reload(true);
                    }
                })
                .catch(error => {

                    console.error("API Error:", error);
                    setApiError("Error occurred Please try again later.");

                });
        }

    }

    const submitDiscussion = (evt) => {
        if (evt && evt.preventDefault) {
            evt.preventDefault();
        }
        setCmntLoading(true);
        const formData = new FormData(evt.target);

        const data = {}
        for (const [key, value] of formData) {
            data[key] = value;
        }

        function validateData(data) {
            const { comments } = data;

            if (!comments) {
                alert("Please fill in all required fields.");
                return false;
            }
            //condition  met, so return true to proceed forward.
            return true;
        }

        if (!validateData(data)) {

            return;
        }

        const comment = {
            ...inputDiscussion,
            ...data,
        };
        console.log(comment)
        const data1 = [];
        var users = [];
        users.push(...selectedAssignees)
        users.push(taskDetail.createdby)
        console.log(selectedAssignees)
        console.log(users)
        for (var i = 0; i < users.length; i++) {
            var task1 = {};
            if (i === users.length - 1) {

                task1 = {
                    ...inputQuestionare,

                    taskid: props.id,
                    description: "Comment Added in Task By",
                    receiverid: taskDetail.createdby,
                    receiverName: taskDetail.taskassignuserdetails[0].assignerName,
                    senderid: userId,
                    senderName: senderName,
                };
            }
            else {

                task1 = {
                    ...inputQuestionare,

                    taskid: props.id,
                    description: "Comment Added in Task By",
                    receiverid: users[i],
                    receiverName: taskDetail.taskassignuserdetails[i].assigneeName,
                    senderid: userId,
                    senderName: senderName,
                };
            }

            data1.push(task1)
        }
        // for notification
        console.log(data1)

        if (comment) {
            Service.createorupdate("Task/CreateOrUpdateDiscussion", comment)
                .then(res => {
                    setapiResponseComment(res.data); // Set the API response in state
                    setComments(prevComments => [...prevComments, comment]);
                    getDiscussionData("Task/GetALLTaskDiscussion?taskid=" + inputDiscussion.taskid);
                    setCommentInput({ comments: '' });
                    if (data1) {
                        for (var m = 0; m <= data1.length; m++) {
                            console.log(data1[m]);

                            Service.createorupdate("Questionare/CreateOrUpdate", data1[m])
                                .then(res => {
                                    // setApiResponse(res.data);
                                })
                                .catch(error => {

                                    console.error("API Error:", error);
                                });
                        }
                        window.location.reload(true);
                    }
                })
                .catch(error => {
                    // Handle error if API call fails
                    console.error("API Error:", error);
                });
        }

    }
    const handleEditorChange = (state) => {
        setEditorState(state);
    }

    const handleHeadlineChange = (evt) => {
        setHeadline(evt.target.value);
    }


    function formatDate(date) {
        if (!date) return ""; // Handle null or undefined dates
        const localDate = new Date(date);
        const year = localDate.getFullYear();
        const month = String(localDate.getMonth() + 1).padStart(2, '0');
        const day = String(localDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    function formatDateTime(dateString) {
        const dateObject = new Date(dateString);

        const day = String(dateObject.getDate()).padStart(2, '0');
        const month = String(dateObject.getMonth() + 1).padStart(2, '0');
        const year = dateObject.getFullYear();
        const hours = String(dateObject.getHours()).padStart(2, '0');
        const minutes = String(dateObject.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const humanReadableHours = hours % 12 || 12; // Convert to 12-hour format

        return `${day}-${month}-${year} ${humanReadableHours}:${minutes} ${ampm}`;
    }


    const backbutton = () => {
        props.navigate(-1);
    }

    const externalLink = (data) => {

        window.open(data, '_blank')
    }



    // ===============================================================>
    const [uploadProgress, setUploadProgress] = useState(0);
    const [files, setFiles] = useState([]);
    const [uploadError, setUploadError] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [inputFileData, setinputFileData] = useState({
        typeId: 0,
        fileType: "Task",
        fileName: "",
        createdby: getLoginUserData().userId,
    })

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles);
            setUploadError(null);  // Reset error state when new files are added
            setUploadSuccess(false);
            // Reset success state when new files are added
        }
    });

    const handleUpload = async () => {
        if (files.length === 0) {
          setUploadError('No files selected for upload');
          return;
        }
    
        const formData = new FormData();
        files.forEach(file => {
          formData.append('files', file);
        });
    
        const data = {
          ...inputFileData,
          typeId: taskDetail.id,
          fileName: files[0].name,
        };
    
        try {
          debugger
          if (data) {
            const response = await CustomerService.createorupdateFile("FileUpload/UploadFile", formData, (progress) => {
              setUploadProgress(progress);
              if(progress === 100){
              
              }
            });
            console.log("File upload response:", response);
            debugger;
            if (response.status === 200) {
              const updateResponse = await CustomerService.createorupdate("FileUpload/CreateOrUpdate", data);
              debugger
              if (updateResponse.status === 200) {
                alert("File uploaded successfully");
                setinputFileData({
                  typeId: 0,
                  fileType: "RFP",
                  fileName: "",
                  createdby: getLoginUserData().userId,
                });
                window.location.reload();
                setUploadSuccess(true);
              } else {
                alert("File uploaded successfully but table not created");
              }
            } else {
              alert("File uploaded successfully but table not created");
            }
          }
        } catch (error) {
          debugger
          console.error("API Error during file upload:", error);
          setUploadError("File upload failed");
        }
      };
const [downloadLoader, setDownloadLoader] = useState(false);
    const handleDownload = (file) => {
        setDownloadLoader(true)
        debugger
        const fileName = file;

        // Change to the name of the file you want to download
        Service.downloadFile("FileUpload/download/", fileName)
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data], { type: res.data.type }));
                debugger
                if (res.data.type === 'application/pdf') {
                    window.open(url, '_blank');
                    setDownloadLoader(false);
                }
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                setDownloadLoader(false);

                link.click();
                link.remove();
            })
            .catch(error => {
                // Handle API error, if necessary
                console.error("API Error:", error);

            });
    };

    //    ============================================================> 

    return (
        <div>
            <div className="container-fluid">

                <br />
                <div className="card shadow mb-2 border-success">
                    <div className="card-header rounded py-3">
                        <div className="row">
                            <div className="col-sm-4"><h3><b className='text-dark'>Tasks Details - {props.id}</b></h3>
                            </div>
                            <div className="col-sm-6"></div>
                            <div className="col-sm-2 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
                <form onSubmit={submitButton}>
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            {isRequested === true ? <>
                                <h6 className="m-0 mt-1 font-weight-bold text-primary required-label">Requested task</h6>
                            </> :
                                <h6 className="m-0 mt-1 font-weight-bold text-success ">TaskInfo :-</h6>}
                        </div>


                        <div className="card-body" style={{ height: "auto" }}>
                            <div className='row'>
                                <div className='col-md-7'>
                                    <div className="form-group row">
                                        <div className='col-sm-12 required-label'><b>Task Headline</b> </div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline"
                                            value={headline} readOnly={detailFlagEdit} name="headline" placeholder="Enter Task headline" onChange={handleHeadlineChange} required /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 required-label'><b>Description</b></div>
                                        <Editor
                                            readOnly={detailFlagEdit}
                                            editorState={editorState}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor"
                                            onEditorStateChange={handleEditorChange}
                                            required
                                        />
                                    </div>

                                </div>
                                <div className='col-md-1'></div>
                                <div className='col-md-4'>

                                    <div className="form-group form-group-h row">
                                        <div className='col-sm-12 required-label'><b>Status</b></div>
                                        <div className='col-sm-12'>
                                            {statusError && <div className="alert alert-danger mt-3" role="alert">Please select a status.</div>}
                                            <select className="form-control form-control-fs form-control-user" disabled={detailFlag} value={statusId} name="statusId" onChange={handleStatusChange} required>
                                                <option hidden>[NONE]</option>
                                                {
                                                    status.map((e, key) => {
                                                        //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;

                                                        return e.statusId === 4 && currentuserid === taskDetail.createdby ?
                                                            <option key={e.statusId} value={e.statusId}>{e.name}</option>
                                                            :
                                                            e.statusId === 1 || e.statusId === 2 || e.statusId === 3 ?
                                                                <option key={e.statusId} value={e.statusId}>{e.name}</option> :
                                                                e.statusId === 4 && statusId === 4
                                                                    ?
                                                                    <option key={e.statusId} value={e.statusId}>{e.name}</option> :
                                                                    ''
                                                            ;

                                                    })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group form-group-h row">
                                        <div className='col-sm-12 required-label'><b>Work Requirement</b></div>
                                        <div className='col-sm-12'>

                                            <select className="form-control form-control-fs form-control-user" readOnly={detailFlagEdit} name="typeId" value={TaskTypeid} onChange={handleTaskChange} required>
                                                <option>[NONE]</option>
                                                {
                                                    dropdownTaskType.map((e, key) => {
                                                        return <option key={e.id} value={e.id}>{e.taskname}</option>;
                                                    })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group form-group-h row">
                                        <div className='col-sm-12 required-label'><b>Priority</b></div>
                                        <div className='col-sm-12'>

                                            <select className="form-control form-control-fs  form-control-user" readOnly={detailFlagEdit} value={taskPriorityid} name="priorityId" onChange={handlePriorityChange} required>
                                                <option>[NONE]</option>
                                                {
                                                    taskPriority.map((e, key) => {
                                                        return <option key={e.priorityId} value={e.priorityId}>{e.name}</option>;
                                                    })}
                                            </select>
                                        </div>
                                    </div>
                                    {
                                        (showId === null || showId === 0) && (leadId === 0 || leadId === null) ? (
                                            <div className="form-group form-group-h row">
                                                <div className="col-sm-3 required-label mt-1"><b>Project</b></div>
                                                {(projectId !== null || projectId !== 0) && <button onClick={redirectToRFP} className=' btn text-primary font-weight-bold '> <b>Open RFP</b> </button>}
                                                <div className="col-sm-12">
                                                    <select
                                                        className="form-control form-control-fs form-control-user"
                                                        readOnly={detailFlagEdit}
                                                        value={projectId}
                                                        name="projectId"
                                                        onChange={handleProjectChange}
                                                        required
                                                    >
                                                        <option>[NONE]</option>
                                                        {Projectdata.map((e, key) => (
                                                            <option key={e.id} value={e.id}>
                                                                {e.projectname}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        ) : null
                                    }

                                    {(leadId === 0 || leadId === "NONE") && (projectId === 0 || projectId == null) ?

                                        <div className="form-group form-group-h row">
                                            <div className='col-sm-12 required-label'><b>Show Name</b></div>
                                            <div className='col-sm-12'>

                                                <select className="form-control form-control-fs  form-control-user" readOnly={detailFlagEdit} value={showId} name='showId' onChange={handleShowChange} required>
                                                    <option>[NONE]</option>
                                                    {
                                                        show.map((e, key) => {
                                                            return <option key={e.showChapterId} value={e.showChapterId}>{e.chapterName}</option>;
                                                        })}
                                                </select>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                    {/* Conditionally render MyLead component */}
                                    {(showId === 0 || showId === "NONE") && (projectId === 0 || projectId == null) ? (

                                        <div className="form-group form-group-h row">
                                            {DeptId === "2" ? <div className='col-sm-4 required-label'><b>Lead Name</b></div> :
                                                <div className='col-sm-4 required-label'><b>RFP</b></div>}

                                            {(leadId !== null || leadId !== 0) && <button onClick={redirectToRFP} className=' btn text-primary font-weight-bold '> <b>click here</b> </button>}

                                            <div className='col-sm-12'>

                                                <select className="form-control form-control-fs  form-control-user" readOnly={detailFlagEdit} value={leadId} name='leadid' onChange={handleLeadChange} required>
                                                    <option>[NONE]</option>
                                                    {
                                                        rfpLead.map((e, key) => {
                                                            return <option key={e.id} value={e.leadMainId}>{e.leadid}</option>;
                                                        })}
                                                </select>
                                            </div>
                                        </div>
                                    ) : null}


                                    <h5>People</h5>

                                    <div className="form-group form-group-h row">
                                        {isRequested === true ? <div className='col-sm-5'><b>Requested From -</b> </div> : <div className='col-sm-5'><b>Author -</b> </div>}
                                        <div className='col-sm-7'>
                                            {
                                                taskDetail.taskassignuserdetails?.slice(0, 1).map((e) => (
                                                    <div>
                                                        <b>
                                                            {e.assignerName}
                                                        </b>
                                                    </div>
                                                ))}
                                        </div>

                                    </div>
                                    <div className="form-group form-group-h row">
                                        {isRequested === true ? <div className="col-sm-5 required-label"><b>Requested To</b></div> : <div className="col-sm-5 required-label"><b>Assigned To</b></div>}
                                        <div className="col-sm-7 ">

                                            {taskDetail.taskassignuserdetails
                                                ? [...new Set(taskDetail.taskassignuserdetails?.map(item => item.assigneeName))]
                                                    .map((name, index) => (
                                                        <b key={index}>{name}, </b>
                                                    ))
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <h5>Dates</h5>

                                    <div className="form-group form-group-h row">
                                        <div className='col-sm-5'><b>Last Update - </b></div>
                                        <div className='col-sm-7'>
                                            <b>{formatDateTime(taskDetail.date)} </b>
                                        </div>

                                    </div>

                                    <div className="form-group form-group-h row">
                                        <div className='col-sm-12 required-label'><b>Due Date / Finish date</b></div>
                                        <div className='col-sm-12'> <input type="date" className="form-control form-control-fs form-control-user" name='finishdate' id="finishdate"
                                            readOnly={detailFlagEdit} value={formatDate(dueDate)} placeholder="Enter Task Topic" required onChange={handledueDateChange} />
                                        </div>
                                    </div>
                                    {/* content link */}
                                    <div className="form-group row">
                                        <div>
                                            <div {...getRootProps({ className: 'dropzone' })}>
                                                <input {...getInputProps()} />
                                                <p>Drag 'n' drop some files here, or click to select files</p>
                                            </div>
                                            <div>
                                                {files.length > 0 && (
                                                    <div>
                                                        <h4>Selected files:</h4>
                                                        <ul>
                                                            {files.map(file => (
                                                                <li key={file.path}>{file.path}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                            <span className='btn btn-success bg-success' type="button" onClick={handleUpload}>Upload</span>
                                            <div>
                                                {(uploadProgress > 0 && uploadProgress < 100) && <progress value={uploadProgress} max="100">{uploadProgress}%</progress>}
                                                {(uploadProgress > 0 && uploadProgress < 100) && <div value={uploadProgress} max="100">{uploadProgress}%</div>}
                                            </div>
                                            {uploadError && <div className="error">{uploadError}</div>}
                                            {uploadSuccess && <div className="success">Files uploaded successfully!</div>}
                                        </div>
                                    </div>
                                    {downloadLoader === false && <h6 className="font-weight-bold text-primary">{ }</h6>}{downloadLoader === true &&
                                <div class=" text-primary">
                                    <strong>Downloading please wait...</strong>
                                    <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                                </div>
                            }
                                    <div className="form-group d-flex flex-column">
                                        {
                                            allFiles?.map((e, index) => (
                                                <div className="m-1" key={index}>
                                                    <div className='btn btn-sm  btn-secondary' type="submit" onClick={() => { handleDownload(e.fileName) }} ><i class="fa-regular fa-file-pdf mr-1"></i>{e.fileName}</div>
                                                </div>
                                            ))}
                                    </div>
                                    {/* end content link */}

                                </div>
                            </div>
                            {apiSharedResponse && (
                                <div className="alert alert-success col-sm-7" role="alert">
                                    Task has been Shared successfully!
                                </div>
                            )}
                            {apiError && (
                                <div className="alert alert-danger col-sm-7" role="alert">
                                    {apiError}
                                </div>
                            )}
                            {apiResponse && (
                                <div className="alert alert-success col-sm-7" role="alert">
                                    Status has been Update successfully!
                                </div>
                            )}
                            {apiError && (
                                <div className="alert alert-danger col-sm-7" role="alert">
                                    {apiError}
                                </div>
                            )}
                            {loading === false && <h6 className="font-weight-bold text-primary">{ }</h6>}{loading === true &&
                                <div class=" text-primary">
                                    <strong>Saving...</strong>
                                    <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                                </div>
                            }

                            {props.name !== "deadline" && <button className="btn btn-primary mr-1" type='submit'>Save</button>}
                            {props.name !== "deadline" && <span type="button" class="btn btn-primary mt-2" data-toggle="modal" data-target="#exampleModalCenter">
                                <i class="fa fa-share-alt mr-1"></i>share task
                            </span>}

                            <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="exampleModalLongTitle">Share Task</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <Select
                                                name="assigneeid"
                                                isMulti={true}
                                                placeholder="Enter members to share with"
                                                options={userName.map((user) => ({
                                                    value: user.id,
                                                    label: `${user.firstname} ${user.lastname}`,
                                                }))}
                                                value={selectedAssignees.value}
                                                onChange={handleSelectChange}
                                                onClick={() => setShowAssigneePopup(false)}
                                            />
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                            <button type='submit' onClick={shared} data-dismiss="modal" class="btn btn-primary">share</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {showAssigneePopup && (
                                <>
                                    <Select
                                        name="assigneeid"
                                        isMulti={true}
                                        placeholder="Enter members to share with"
                                        options={userName.map((user) => ({
                                            value: user.id,
                                            label: `${user.firstname} ${user.lastname}`,
                                        }))}
                                        value={selectedAssignees.value}
                                        onChange={handleSelectChange}
                                        onClick={() => setShowAssigneePopup(false)}
                                    />
                                    <button className="close-popup-btn" onClick={() => setShowAssigneePopup(false)}>
                                        Close popup
                                    </button>
                                </>
                            )}

                        </div>
                    </div>
                </form>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col-sm-9">
                                <div className="col-sm-6"></div>
                                <button className="btn btn-primary mr-2" onClick={handleDiscussionButtonClick}>
                                    Discussion
                                </button>

                            </div>
                        </div>
                    </div>

                    <div className="card-header py-3">
                        {apiResponseComment && (
                            <div className="alert alert-success" role="alert">
                                Comment Done successfully..!!
                            </div>
                        )}
                        
                        <div className="row d-flex flex-column">
                            <div class="card-body" style={{ display: "flex" }}>
                                <img class="rounded-circle shadow-1-strong me-3 mt-3"
                                    src="https://w7.pngwing.com/pngs/184/113/png-transparent-user-profile-computer-icons-profile-heroes-black-silhouette-thumbnail.png" alt="avatar" width="45"
                                    height="45" />
                                <form onSubmit={submitDiscussion} className='card-body' style={{ display: "flex" }}>

                                    <div className='col-sm-10  '>
                                        {/* <img src={showImage} height={100} width={100} alt="" /> */}
                                        <MentionsInput className="form-control w-100 form-control-user"
                                            id="comment"
                                            name="comments"
                                            style={defaultStyle}
                                            placeholder="Enter Your Message" value={commentInput.comments}
                                            onChange={(e) => setCommentInput({ ...commentInput, comments: e.target.value })}>
                                            <Mention data={userNameData} style={defaultMentionStyle} />
                                        </MentionsInput>
                                    </div>
                                    {loadingImage === false && <h6 className="font-weight-bold text-primary">{ }</h6>}{loadingImage === true &&
                                        <div class=" text-primary">
                                            <strong>Uploading...</strong>
                                            <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                                        </div>
                                    }
                                    {cmntLoading === false && <h6 className="font-weight-bold text-primary">{ }</h6>}{cmntLoading === true &&
                            <div class=" text-primary">
                                <strong>Sending...</strong>
                                <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                            </div>
                        }
                                    <div className='col-sm-2 d-flex'>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            accept='.png,.jpeg,.jpg'
                                            style={{ display: 'none' }}
                                            onChange={handleFileUpload}
                                        />
                                        {props.name !== "deadline" && <>  <label htmlFor="fileInput" className="btn btn-primary mr-1 ml-3">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                fill="currentColor"
                                                className="bi bi-paperclip"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z" />
                                            </svg>
                                        </label>
                                            <button type='submit' className="btn btn-primary mr-3">comment</button></>}

                                    </div>
                                </form>
                                <div className='col-sm-0'></div>
                            </div>
                            {/* <h5>note</h5> */}
                            {comments.slice().reverse().map((messageData) => (
                                <div class="card-body mt-1 card-body1">
                                    <div class="d-flex flex-start justify-content-between align-items-center">
                                        <div className='d-flex'>

                                            <img class="rounded-circle shadow-1-strong me-3"
                                                src="https://w7.pngwing.com/pngs/184/113/png-transparent-user-profile-computer-icons-profile-heroes-black-silhouette-thumbnail.png" alt="avatar" width="45"
                                                height="45" />
                                            {
                                                messageData.msgType === "Image" ?
                                                    <img src={messageData.comments} className='ml-3 btn' data-toggle="tooltip" data-placement="top" title="Click to open" onClick={() => externalLinkImage(messageData.comments)} height={50} width={100} alt='not loaded' />
                                                    :
                                                    <p class="mb-0 mt-2 pl-3 pb-1" dangerouslySetInnerHTML={{ __html: messageData.comments }} />

                                            }

                                        </div>
                                        <div class="small d-flex flex-row-reverse justify-content-start pr-5">
                                            <div className='d-flex'>
                                                <p class="text-muted font-weight-bold  ml-2 ">
                                                    Date-{formatDateTime(messageData.createdDate)}
                                                </p>
                                                <h6 class="font-weight-bold text-primary ml-2 ">{messageData.userName} </h6>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </div>


        </div>

    )
}

export default TaskDetailsComponent