import axios from "axios";

//const EMPLOYEE_API_BASE_URL = "https://localhost:7116/api/";

const EMPLOYEE_API_BASE_URL = "https://hybrid-exhibit.com/api/";

class CustomerService {
  async getdata(method) {
    try {
      const res = await axios
        .get(EMPLOYEE_API_BASE_URL + method)
        .then((response) => {
          console.log(response);
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
      return res;
    } catch (err) {
      console.log(`Error: ${err?.response?.data}`);
    }
  }

  async createorupdate(method, value) {
    try {
      const res = await axios
        .post(EMPLOYEE_API_BASE_URL + method, value)
        .then((response) => {
          console.log(response);
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
      return res;
    } catch (err) {
      console.log(`Error: ${err?.response?.data}`);
    }
  }

  async createorupdateFile(method, value, onUploadProgress) {
    try {
      const res = await axios
        .post(EMPLOYEE_API_BASE_URL + method, value, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            onUploadProgress(progress); // Call the passed callback
            console.log(`Upload Progress: ${progress}%`);
          },
        })
        .then((response) => {
          console.log(response);
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
      return res;
    } catch (err) {
      console.log(`Error: ${err?.response?.data}`);
    }
  }

  async downloadFile(method, filename) {
    try {
      const res = await axios
        .get(EMPLOYEE_API_BASE_URL + method + filename, {
          responseType: "blob",
        })
        .then((response) => {
          console.log(response);
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
      return res;
    } catch (err) {
      console.log(`Error: ${err?.response?.data}`);
    }
  }

  async getdatabyId(method, Id) {
    try {
      const res = await axios
        .get(EMPLOYEE_API_BASE_URL + method + Id)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log(error);
        });
      return res;
    } catch (err) {
      console.log(`Error: ${err?.response?.data}`);
    }
  }

  async delete(method, Id) {
    try {
      const res = await axios
        .post(EMPLOYEE_API_BASE_URL + method + Id)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
      return res;
    } catch (err) {
      console.log(`Error: ${err?.response?.data}`);
    }
  }
}

export default new CustomerService();
