import React from 'react'
import { Link } from 'react-router-dom';

const DashboardSalesOptionsComponent = (props) => {
  const role = sessionStorage.getItem("role");

  const backbutton = () => {
    props.navigate(-1);
  }


  const staticid = 0;
  const staticname16 = "Create PPT";

  const roleButtons = [

    {
      role: "Managing Director(MD)",
      buttons: [
        { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSalesTask" },
        { label: "Assigned Tasks", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
        { label: "My Tasks", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
        { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
        { label: "Create Meeting Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingSales" },
        { label: "Meetings List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
        { label: "Upcoming Shows List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminShowList" },
        // { label: "Upcoming Shows List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/showList" },
        
        { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLeadList" },
        { label: "Create RFP", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/CreateRFP" },
        { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
        { label: "RFP MasterSheet", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/RFPMasterSheet" },
        { label: "PPT List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/PPTList/${staticid}&${staticname16}` },

      ]
    },

    {
      role: "Admin",
      buttons: [
        { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSalesTask" },
        { label: "Assigned Tasks", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
        { label: "My Tasks", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
        { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
        { label: "Create Meeting Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingSales" },
        { label: "Meetings List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
        { label: "Upcoming Shows List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminShowList" },
        // { label: "Upcoming Shows List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/showList" },
        
        { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLeadList" },
        { label: "Create RFP", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/CreateRFP" },
        { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
        { label: "RFP MasterSheet", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/RFPMasterSheet" },
        { label: "PPT List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/PPTList" },
        { label: "Attach PPT ", className: "btn btn-danger btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/CreatePPT" },

      ]
    },

    //operations
    {
      role: "Executive - Software Developer(SOP Tool)",
      buttons: [
        { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSalesTask" },
        { label: "Assigned Tasks", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
        { label: "My Tasks", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
        { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
        { label: "Create Meeting Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingSales" },
        { label: "Meetings List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
        { label: "Upcoming Shows List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminShowList" },
        // { label: "Upcoming Shows List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/showList" },
        
        { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLeadList" },
        { label: "Create RFP", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/CreateRFP" },
        { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
        { label: "RFP MasterSheet", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/RFPMasterSheet" },
        { label: "PPT List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/PPTList/${staticid}&${staticname16}` },

        
        { label: "PPT List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/PPTList" },
        { label: "Attach PPT ", className: "btn btn-danger btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/CreatePPT" },

      ]
    },

    {
      role: "Assitant Manager - Process",
      buttons: [
        { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSalesTask" },
        { label: "Assigned Tasks", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
        { label: "My Tasks", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
        { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
        { label: "Create Meeting Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingSales" },
        { label: "Meetings List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
        { label: "Upcoming Shows List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminShowList" },
        // { label: "Upcoming Shows List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/showList" },
        
        { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLeadList" },
        { label: "Create RFP", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/CreateRFP" },
        { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
        { label: "RFP MasterSheet", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/RFPMasterSheet" },
        { label: "PPT List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/PPTList/${staticid}&${staticname16}` },

        
        { label: "PPT List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/PPTList" },
        { label: "Attach PPT ", className: "btn btn-danger btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/CreatePPT" },

      ]
    },
    {
      role: "Marketing Deputy Manager",
      buttons: [
        { label: "New Task", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateSalesTask" },
        { label: "Assigned Tasks", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/KanbanAssignedTask" },
        { label: "My Tasks", className: "btn btn-info btn-md font-weight-bold text-uppercase", to: "/KanbanMyTask" },
        { label: "Closed task", className: "btn btn-secondary btn-md font-weight-bold text-uppercase", to: "/KanbanMyArchievedTask" },
        { label: "Create Meeting Sales", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: "/CreateMeetingSales" },
        { label: "Meetings List", className: "btn btn-warning btn-md font-weight-bold text-uppercase", to: "/MeetingList" },
        { label: "Upcoming Shows List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/AdminShowList" },
        // { label: "Upcoming Shows List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/showList" },
        
        { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLeadList" },
        { label: "Create RFP", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/CreateRFP" },
        { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
        { label: "RFP MasterSheet", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/RFPMasterSheet" },
        { label: "PPT List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/PPTList/${staticid}&${staticname16}` },

        
        { label: "PPT List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/PPTList" },
        { label: "Attach PPT ", className: "btn btn-danger btn-md font-weight-bold text-uppercase salesHeadPPT", to: "/CreatePPT" },

      ]
    },

    {
      role: "Sr.Manager-Projects",
      buttons: [
        
        { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLeadList" },
        { label: "Create RFP", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/CreateRFP" },
        { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
        { label: "Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/SharedLeadList  " },
        { label: "RFP MasterSheet", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/RFPMasterSheet" },

      ]
    },


    {
      role: "DGM-Projects",
      buttons: [
        
        { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLeadList" },
        { label: "Create RFP", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/CreateRFP" },
        { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
        
        { label: "RFP MasterSheet", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/RFPMasterSheet" },

      ]
    },


    {
      role: "Manager - Graphics",
      buttons: [
        
        { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLeadList" },
        { label: "Create RFP", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/CreateRFP" },
        { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
        { label: "RFP MasterSheet", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/RFPMasterSheet" },
        
      ]
    },
    {
      role: "Assistant-Manager-Projects",
      buttons: [
        
        { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLeadList" },
        { label: "Create RFP", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/CreateRFP" },
        { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
        { label: "RFP MasterSheet", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/RFPMasterSheet" },
        
      ]
    },

    {
      role: "Deputy Manager - Administration",
      buttons: [
        
        { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLeadList" },
        { label: "Create RFP", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/CreateRFP" },
        { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
        { label: "RFP MasterSheet", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/RFPMasterSheet" },
        
      ]
    },

    {
      role: "Assistant Manager - Graphics",
      buttons: [
        
        { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLeadList" },
        { label: "Create RFP", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/CreateRFP" },
        { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
        { label: "RFP MasterSheet", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/RFPMasterSheet" },
        
      ]
    },
    {
      role: "Senior Executive - Projects",
      buttons: [
        
        { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLeadList" },
        { label: "Create RFP", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/CreateRFP" },
        { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
        
        { label: "RFP MasterSheet", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/RFPMasterSheet" },
      ]
    },

    {
      role: "Deputy-Manager-Projects",
      buttons: [
        
        { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLeadList" },
        { label: "Create RFP", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/CreateRFP" },
        { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
        { label: "RFP MasterSheet", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/RFPMasterSheet" },
        
      ]
    },

    {
      role: "Executive - Operations",
      buttons: [
        
        { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLeadList" },
        { label: "Create RFP", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/CreateRFP" },
        { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
        { label: "RFP MasterSheet", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/RFPMasterSheet" },
        
      ]
    },

    {
      role: "Senior Manager-Projects & Client Servicing",
      buttons: [
        
        { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLeadList" },
        { label: "Create RFP", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/CreateRFP" },
        { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
        { label: "RFP MasterSheet", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/RFPMasterSheet" },
        
      ]
    },

    {
      role: "Head of Projects - North America",
      buttons: [
        
        { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLeadList" },
        { label: "Create RFP", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/CreateRFP" },
        { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
        { label: "RFP MasterSheet", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/RFPMasterSheet" },
        
      ]
    },

    {
      role: "Project-Manager-Poland",
      buttons: [
        
        { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLeadList" },
        { label: "Create RFP", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/CreateRFP" },
        { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
        { label: "RFP MasterSheet", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/RFPMasterSheet" },
        
      ]
    },

    {
      role: "Project Manager",
      buttons: [
        
        { label: "Qualified Lead List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/QualifiedLeadList" },
        { label: "Create RFP", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP ", to: "/CreateRFP" },
        { label: "RFP List", className: "btn btn-info btn-md font-weight-bold text-uppercase salesHeadRFP", to: "/RFPList" },
        { label: "RFP MasterSheet", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/RFPMasterSheet" },
        
      ]
    },


  ];
  return (
    <div>
      <div className="container-fluid">
        <div className="card shadow border rounded border-success mb-2">
          <div className="card-header rounded py-3">
            <div className="row">
              <div className="d-flex flex-start align-items-center">

              </div>
              <div className="col-sm-3"><h2><b className='text-dark'>Dashboard</b></h2></div>
              <div className="col-sm-6"></div>
              <div className="col-sm-3 d-flex flex-row-reverse">
                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="card cardUser shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-success">Dashboard</h6>
          </div>
          <div className="container" style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
            <div className="row">
              {roleButtons.map((roleButtonGroup, index) => (
                (roleButtonGroup.role === role) && (
                  roleButtonGroup.buttons.map((button, buttonIndex) => (
                    <div key={buttonIndex} className="col-xl-4 col-md-8">
                      <div className="card border-success shadow p-1 mt-4 ml-3">
                        <div className="btn-group">
                          <Link to={button.to} className={button.className} type="button">
                            {button.label}
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
                )
              ))}
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default DashboardSalesOptionsComponent