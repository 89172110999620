import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { filterWithUniqueIds, formatDate, getCurrentDate, getCurrentDateTimeForDB, getLoginUserData } from '../../../Helpers/supportedFunctions/supportedGlobalFunctions';
import Service from '../../../Services/Admin/Service';
import Select from 'react-select';

const CreateProjectExecutionComponent = (props) => {
  const [allRFPData, setALLRFPData] = useState([]);
  const [rfpId, setRfpId] = useState(0);
  const [agendahide, setagendahide] = useState('none');
  const [projectManager, setProjectManager] = useState([]);
  const [detailFlag, setDetailFlag] = useState(false);
  const [projectExecutionId, setProjectExecutionId] = useState()
  const [apiError, setApiError] = useState(null);
  const [rfpError, setRFPError] = useState(null);
  const [apiResponse, setApiResponse] = useState(null);
  const [projectSelectedAssignees, setProjectSelectedAssignees] = useState([]);
  // const [salesHeadId, setSalesHeadId] = useState([]);
  const [inputExecutionfield, setInputExecutionfield] = useState({
    id: props.id,
    rfpId: 0,
    accountManagerName: "",
    projectManagerId: 0,
    client: "",
    boothName: "",
    exhibitionName: "",
    exhibitionStartDate: "",
    exhibitionEndDate: "",
    dimensions: "",
    area: "",
    venue: "",
    buildupStartDate: null,
    buildupEndDate: null,
    travellingDateDeparture: null,
    travellingArrivalDate: null,
    contractorName: "",
    phone: "",
    email: "",
    handoverDateClient: null,
    meetingDateClient: null,
    clientAttachment: "",
    supplierAttachment: "",
    handoverDateSupplier: null,
    meetingDateSupplier: null,
    createdby: getLoginUserData().userId,
    updatedby: getLoginUserData().userId
  });

  useEffect(() => {
    if (apiResponse || apiError) {
      const timeoutId = setTimeout(removeSuccessMessage, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [apiResponse, apiError]);

  const removeSuccessMessage = () => {
    setApiResponse(null);
    setApiError(null);
  };


  useEffect(() => {
    getAllRFPData("RFP/GetAll?id=" + getLoginUserData().userId)
    getUserData("Users/GetAll");
    var d = props.name;
    if (d === "details") {
      setDetailFlag(true);
    }

    if (props.id !== 0 && props.id !== undefined) {
      setagendahide("");
      getExecutionDatabyId("ProjectExecutionNew/GetById?id=" + props.id, '');
    }

  }, [])

  const getUserData = (method) => {
    Service.getdata(method).then((res) => {
      var a = res.data
      var intdeptId = parseInt(getLoginUserData().deptId)
      var list = [];
      a.forEach((d, index) => {
        var role = d.lstRole;
        var checkdepartment = role.find(x => x.depId === 2 || x.depId === 4)
        if (checkdepartment !== undefined) {
          d.rolename = d.lstRole[0].name;
          list.push(d);
        }
      });
      setProjectManager(list);

    });

  };

  const getExecutionDatabyId = (method) => {
    Service.getdata(method).then((res) => {
      console.log(res.data);
      var r = res.data.projectExecutionData;
      setContractSent(...r.filter((x) => x.particularsName === "Contract Sent"));
      setContractSigned(...r.filter((x) => x.particularsName === "Contract Signed"));
      setPayment(...r.filter((x) => x.particularsName === "Payment Done/Recieved"));
      setVisa(...r.filter((x) => x.particularsName === "Visa Invitation Letter"));
      setMaterilsDetails(...r.filter((x) => x.particularsName === "Material Details"));
      setLoginDetails(...r.filter((x) => x.particularsName === "Login Details"));
      setDesignApprovals(...r.filter((x) => x.particularsName === "Design Approvals"));
      setRefundableSecurity(...r.filter((x) => x.particularsName === "Refundable Security"));
      setStaticCalculation(...r.filter((x) => x.particularsName === "Static Calculation"));
      setDrawing(...r.filter((x) => x.particularsName === "Technical Drawing"));
      setElectricity(...r.filter((x) => x.particularsName === "Electricity Order"));
      setRigging(...r.filter((x) => x.particularsName === "Rigging"));
      setinternet(...r.filter((x) => x.particularsName === "Internet"));
      setCleaning(...r.filter((x) => x.particularsName === "Cleaning"));
      setWater(...r.filter((x) => x.particularsName === "Water"));
      setLogos(...r.filter((x) => x.particularsName === "Logos"));
      setGraphics(...r.filter((x) => x.particularsName === "Graphics"));
      setBadges(...r.filter((x) => x.particularsName === "Badges"));
      setTravel(...r.filter((x) => x.particularsName === "Travel Requisition"));
      setHandover(...r.filter((x) => x.particularsName === "Handover Protocol"));
      var t3 = r.filter((x) => x.particularsName !== "Handover Protocol"
        && x.particularsName !== "Contract Sent"
        && x.particularsName !== "Contract Signed"
        && x.particularsName !== "Payment Done/Recieved"
        && x.particularsName !== "Visa Invitation Letter"
        && x.particularsName !== "Material Details"
        && x.particularsName !== "Login Details"
        && x.particularsName !== "Design Approvals"
        && x.particularsName !== "Refundable Security"
        && x.particularsName !== "Static Calculation"
        && x.particularsName !== "Technical Drawing"
        && x.particularsName !== "Electricity Order"
        && x.particularsName !== "Rigging"
        && x.particularsName !== "Internet"
        && x.particularsName !== "Cleaning"
        && x.particularsName !== "Water"
        && x.particularsName !== "Logos"
        && x.particularsName !== "Graphics"
        && x.particularsName !== "Badges"
        && x.particularsName !== "Travel Requisition"
      );

      setInputExecutionfield(res.data);


      var arr = [];


      arr.push({ value: res.data.projectManagerId, label: res.data.projectManagerName });

      setProjectSelectedAssignees(arr);

      setRfpId(res.data.rfpId);
      // setinputmultiFields(res.data.projectExecutionData);
      setinputmultiFields(t3);
      // setContractSent(res.data.projectExecutionData)


    });
  }




  const getAllRFPData = (method) => {
    Service.getdata(method, '').then((res) => {
      var uniqueRFP = filterWithUniqueIds(res.data)
      var projectRFP = uniqueRFP.filter((e) => e.isConvertedProject === true);
      setALLRFPData(projectRFP);
      console.log(res.data)

    });
  }


  const handleInputRFP = (e) => {
    setRfpId(e.target.value);
    var RFPId = allRFPData.filter((x) => x.id === parseInt(e.target.value))

    if (RFPId.length !== null) {
      getRFPDatabyid("RFP/GetById?id=", RFPId[0].id);
    }
  }

  const inputExecutionHandler = (e) => {
    const { name, value } = e.target;
    setInputExecutionfield((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const getRFPDatabyid = (method, id) => {
    Service.getdatabyId(method, id).then((res) => {
      console.log(res.data);

      setInputExecutionfield((prevState) => ({
        ...prevState,
        ["exhibitionStartDate"]: res.data.dateFrom,
        ["exhibitionEndDate"]: res.data.dateTo,
        ["venue"]: res.data.venue,
        ["exhibitionName"]: res.data.leadid,
        ["accountManagerName"]: res.data.createdByName,

      }))
    })
  }

  const handleSelectChange = (selectedOptions) => {

    // const selectedAssigneeIds = selectedOptions.map((option) => option.value);
    // const selectedAssigneeEmails = selectedOptions.map((option) => option.email);
    setProjectSelectedAssignees(selectedOptions);

  };



  // project execution data details collection 
  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...inputmultiFields];
    list[index][name] = value;
    setinputmultiFields(list);
  }


  const [inputmultiFields, setinputmultiFields] = useState([{
    particularsName: null,
    deadlineClient: null,
    adheredClient: null,
    remarksClient: null,
    deadlineInternal: null,
    adheredInternal: null,
    remarksInternal: null,
    deadlineSupplier: null,
    adheredSupplier: null,
    remarksSupplier: null,
  }]);

  const addInputField = () => {
    setinputmultiFields([...inputmultiFields, {
      particularsName: '',
      deadlineClient: null,
      adheredClient: '',
      remarksClient: '',
      deadlineInternal: null,
      adheredInternal: '',
      remarksInternal: '',
      deadlineSupplier: null,
      adheredSupplier: '',
      remarksSupplier: '',
    }])

  }

  const removeInputFields = (index) => {
    const rows = [...inputmultiFields];
    rows.splice(index, 1);
    setinputmultiFields(rows);
  }


  const [contractSent, setContractSent] = useState({ particularsName: "Contract Sent" });
  const handleContractchange = (evt) => {

    const { name, value } = evt.target;
    debugger
    if (name === name && value === "") {
      const { name, value } = { name: evt.target.name, value: null };
      setContractSent((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
    else {
      setContractSent((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }



  }

  const [contractSigned, setContractSigned] = useState({ particularsName: "Contract Signed" });
  const handleContractSignedChange = (evt) => {
    const { name, value } = evt.target;

    if (name === name && value === "") {
      const { name, value } = { name: evt.target.name, value: null };
      setContractSigned((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
    else {
      setContractSigned((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }

  }

  const [payment, setPayment] = useState({ particularsName: "Payment Done/Recieved" });
  const handlePaymentchange = (evt) => {
    const { name, value } = evt.target;

    if (name === name && value === "") {
      const { name, value } = { name: evt.target.name, value: null };
      setPayment((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
    else {
      setPayment((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const [visa, setVisa] = useState({ particularsName: "Visa Invitation Letter" });
  const handleVisachange = (evt) => {
    const { name, value } = evt.target;

    if (name === name && value === "") {
      const { name, value } = { name: evt.target.name, value: null };
      setVisa((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
    else {
      setVisa((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const [materialDetails, setMaterilsDetails] = useState({ particularsName: "Material Details" });
  const handleMaterialDetailschange = (evt) => {
    const { name, value } = evt.target;

    if (name === name && value === "") {
      const { name, value } = { name: evt.target.name, value: null };
      setMaterilsDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
    else {
      setMaterilsDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const [LoginDetails, setLoginDetails] = useState({ particularsName: "Login Details" });
  const handleLoginDetailschange = (evt) => {
    const { name, value } = evt.target;

    if (name === name && value === "") {
      const { name, value } = { name: evt.target.name, value: null };
      setLoginDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
    else {
      setLoginDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const [designApprovals, setDesignApprovals] = useState({ particularsName: "Design Approvals" });
  const handleDesignApprovalschange = (evt) => {
    const { name, value } = evt.target;

    if (name === name && value === "") {
      const { name, value } = { name: evt.target.name, value: null };
      setDesignApprovals((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
    else {
      setDesignApprovals((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const [refundableSecurity, setRefundableSecurity] = useState({ particularsName: "Refundable Security" });
  const handleSecuritychange = (evt) => {
    const { name, value } = evt.target;

    if (name === name && value === "") {
      const { name, value } = { name: evt.target.name, value: null };
      setRefundableSecurity((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
    else {
      setRefundableSecurity((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const [staticCalculation, setStaticCalculation] = useState({ particularsName: "Static Calculation" });
  const handleStaticchange = (evt) => {
    const { name, value } = evt.target;
    if (name === name && value === "") {
      const { name, value } = { name: evt.target.name, value: null };
      setStaticCalculation((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
    else {
      setStaticCalculation((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const [drawing, setDrawing] = useState({ particularsName: "Technical Drawing" });
  const handleDrawingchange = (evt) => {
    const { name, value } = evt.target;

    if (name === name && value === "") {
      const { name, value } = { name: evt.target.name, value: null };
      setDrawing((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
    else {
      setDrawing((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }
  const [electricity, setElectricity] = useState({ particularsName: "Electricity Order" });
  const handleElectricitychange = (evt) => {
    const { name, value } = evt.target;

    if (name === name && value === "") {
      const { name, value } = { name: evt.target.name, value: null };
      setElectricity((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
    else {
      setElectricity((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }
  const [rigging, setRigging] = useState({ particularsName: "Rigging" });
  const handleRiggingchange = (evt) => {
    const { name, value } = evt.target;

    if (name === name && value === "") {
      const { name, value } = { name: evt.target.name, value: null };
      setRigging((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
    else {
      setRigging((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const [internet, setinternet] = useState({ particularsName: "Internet" });
  const handleInternetchange = (evt) => {
    const { name, value } = evt.target;

    if (name === name && value === "") {
      const { name, value } = { name: evt.target.name, value: null };
      setinternet((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
    else {
      setinternet((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }
  const [cleaning, setCleaning] = useState({ particularsName: "Cleaning" });
  const handleCleaningchange = (evt) => {
    const { name, value } = evt.target;

    if (name === name && value === "") {
      const { name, value } = { name: evt.target.name, value: null };
      setCleaning((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
    else {
      setCleaning((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const [water, setWater] = useState({ particularsName: "Water" });
  const handleWaterchange = (evt) => {
    const { name, value } = evt.target;

    if (name === name && value === "") {
      const { name, value } = { name: evt.target.name, value: null };
      setWater((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
    else {
      setWater((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const [logos, setLogos] = useState({ particularsName: "Logos" });
  const handleLogoschange = (evt) => {
    const { name, value } = evt.target;

    if (name === name && value === "") {
      const { name, value } = { name: evt.target.name, value: null };
      setLogos((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
    else {
      setLogos((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const [graphics, setGraphics] = useState({ particularsName: "Graphics" });
  const handleGraphicschange = (evt) => {
    const { name, value } = evt.target;

    if (name === name && value === "") {
      const { name, value } = { name: evt.target.name, value: null };
      setGraphics((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
    else {
      setGraphics((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const [badges, setBadges] = useState({ particularsName: "Badges" });
  const handleBadgeschange = (evt) => {
    const { name, value } = evt.target;

    if (name === name && value === "") {
      const { name, value } = { name: evt.target.name, value: null };
      setBadges((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
    else {
      setBadges((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const [travel, setTravel] = useState({ particularsName: "Travel Requisition" });
  const handleTravelchange = (evt) => {
    const { name, value } = evt.target;

    if (name === name && value === "") {
      const { name, value } = { name: evt.target.name, value: null };
      setTravel((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
    else {
      setTravel((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const [handover, setHandover] = useState({ particularsName: "Handover Protocol" });
  const handleHandoverchange = (evt) => {
    const { name, value } = evt.target;
    if (name === name && value === "") {
      const { name, value } = { name: evt.target.name, value: null };
      setHandover((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
    else {
      setHandover((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }
  // project execution data details collection end




  const save = (evt) => {
    evt.preventDefault();

    var projectManagerId = { ...projectSelectedAssignees.slice(0, 1).map((x) => x.value) }
    var projectManagerName = { ...projectSelectedAssignees.slice(0, 1).map((x) => x.label) }

    var listData = [];
    listData.push(...inputmultiFields);
    listData.push(contractSent);
    listData.push(contractSigned);
    listData.push(payment);
    listData.push(visa);
    listData.push(materialDetails);
    listData.push(LoginDetails);
    listData.push(designApprovals);
    listData.push(refundableSecurity);
    listData.push(staticCalculation);
    listData.push(drawing);
    listData.push(electricity);
    listData.push(rigging);
    listData.push(internet);
    listData.push(cleaning);
    listData.push(water);
    listData.push(logos);
    listData.push(graphics);
    listData.push(badges);
    listData.push(travel);
    listData.push(handover);
    // if(inputmultiFields[0].particularsName===""){
    //    inputmultiFields.splice(0,1);
    // };


    function validateData(rfpId) {

      if (!rfpId || rfpId === "[NONE]") {
        setRFPError(true);
        return false;
      } else {
        setRFPError(false);
      }

      return true;
    }

    if (!validateData(rfpId)) {
      return;
    }
    const executionData = {
      ...inputExecutionfield,
      projectExecutionData: listData,
      rfpId: parseInt(rfpId),
      projectManagerId: projectManagerId[0],
      projectManagerName: projectManagerName[0],
    }
    console.log(executionData);

    if (executionData) {
      Service.createorupdate("ProjectExecutionNew/CreateOrUpdate", executionData)
        .then(res => {
          setApiResponse(res.data);
          if (props.id === 0 || props.id === "" || props.id === null || props.id === undefined) {
            window.location.href = "/ProjectwiseExecutionTrackerList";
          }
          // Set the API response in state
        })
        .catch(error => {
          // Handle error if API call fails
          setApiError(error);
          console.error("API Error:", error);

          // setApiError("Error occurred while saving the RFP. Please try again later.");
        });
    }
  }

  const backbutton = () => {
    props.navigate(-1);
  }
  return (
    <div style={{ maxHeight: "71vh" }}>
      <div className="container-fluid" >
        <br />
        <form onSubmit={save}>
          <div className="card shadow mb-2 border-success sticky-top" style={{ position: "sticky", zIndex: "1" }}>
            <div className="card-header rounded py-3">
              <div className="row">
                <div className="col-sm-7"><h3><b className='text-dark'>Project Execution Planning</b></h3></div>
                <div className="col-sm-1"></div>
                <div className="col-sm-4 d-flex flex-row-reverse">
                  <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                  {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
                  <button type='submit' className='btn btn-primary pt-2 mt-2 text-uppercase'>Save</button>
                </div>
                {apiResponse && (
                  <div className="alert alert-success" role="alert">
                    Project execution has been Updated successfully...!
                  </div>
                )}
                {apiError && (
                  <div className="alert alert-danger" role="alert">
                    {apiError}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="card shadow mb-4">
            <div className="card-header py-3 "><h6 className='m-0 mt-1 font-weight-bold text-success'>Execution Planning</h6></div>

            <div className="card-body" style={{ height: "auto" }}>
              <div className="row">
                <div className="col-md-5">

                  <div className="form-group row">
                    <div className='col-sm-12 required-label'><b>Select Project</b></div>
                    <div className='col-sm-12'>
                      {rfpError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a Project.</div>}

                      <select className="form-control  form-control-user" disabled={detailFlag} value={rfpId} name="rfpId" onChange={handleInputRFP} required>
                        <option>[NONE]</option>
                        {
                          allRFPData.map((e, key) => {
                            return <option key={e.id} value={e.id}>{e.leadid}</option>;
                          })}

                      </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-4  mt-2'><b>Client</b></div>
                    <div className='col-sm-8'>
                      <input type="text" className="form-control form-control-user"
                        name="client" placeholder="enter Client Name" readOnly={detailFlag} value={inputExecutionfield.client} onChange={inputExecutionHandler} />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-4  mt-2'><b>Exhibition Name</b></div>
                    <div className='col-sm-8'>
                      <input type="text" className="form-control form-control-user"
                        name="exhibitionName" readOnly={detailFlag} value={inputExecutionfield.exhibitionName} onChange={inputExecutionHandler} placeholder="enter Exhibition Name" />
                    </div>
                  </div>

                  <div className="form-group row d-flex">
                    <div className='col-sm-3 mt-1 '><b>Exhibition Start Date</b></div>

                    <input type="date" className="form-control col-sm-3 mb-1 form-control-user" readOnly={detailFlag} value={formatDate(inputExecutionfield.exhibitionStartDate)} multiple name="exhibitionStartDate" />

                    <div className='col-sm-3 mt-1 '><b> Exhibition &nbsp; &nbsp; End Date</b>   </div>

                    <input type="date" className="form-control col-sm-3 mb-1  form-control-user" readOnly={detailFlag} value={formatDate(inputExecutionfield.exhibitionEndDate)} name="exhibitionEndDate" />
                  </div>

                  <div className="form-group row d-flex">
                    <div className='col-sm-3 mt-1 '><b>Build Start Date</b></div>

                    <input type="date" className="form-control col-sm-3 mb-1 form-control-user" readOnly={detailFlag} value={formatDate(inputExecutionfield.buildupStartDate)} multiple name="buildupStartDate" onChange={inputExecutionHandler} />

                    <div className='col-sm-3 mt-1 '><b> Build End&nbsp; &nbsp; Date</b>   </div>

                    <input type="date" className="form-control col-sm-3 mb-1  form-control-user" readOnly={detailFlag} value={formatDate(inputExecutionfield.buildupEndDate)} name="buildupEndDate" onChange={inputExecutionHandler} />
                  </div>

                  <h5>Contractor Info</h5>
                  <div className="form-group row">
                    <div className='col-sm-4  mt-2'><b>Name</b></div>
                    <div className='col-sm-8'>
                      <input type="text" className="form-control form-control-user"
                        name="contractorName" readOnly={detailFlag} value={inputExecutionfield.contractorName} placeholder="enter Contractor Name" onChange={inputExecutionHandler} />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-4  mt-2'><b>Phone</b></div>
                    <div className='col-sm-8'>
                      <input type="text" className="form-control form-control-user"
                        name="phone" readOnly={detailFlag} placeholder="enter phone Number" value={inputExecutionfield.phone} onChange={inputExecutionHandler} />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-4  mt-2'><b>Email</b></div>
                    <div className='col-sm-8'>
                      <input type="text" className="form-control form-control-user"
                        name="email" readOnly={detailFlag} placeholder="enter Email" value={inputExecutionfield.email} onChange={inputExecutionHandler} />
                    </div>
                  </div>
                  <h5>Client</h5>
                  <div className="form-group row d-flex">
                    <div className='col-sm-3 mt-1 '><b>Handover Date Time Client</b></div>

                    <input type="datetime-local" min={getCurrentDateTimeForDB()} className="form-control col-sm-3 mb-1 form-control-user" readOnly={detailFlag} value={inputExecutionfield.handoverDateClient} name="handoverDateClient" onChange={inputExecutionHandler} />

                    <div className='col-sm-3 mt-1 '><b> Meeting Date Client</b> </div>

                    <input type="date" className="form-control col-sm-3 mb-1  form-control-user" readOnly={detailFlag} name="meetingDateClient" value={formatDate(inputExecutionfield.meetingDateClient)} onChange={inputExecutionHandler} />
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-4  mt-2'><b>MOM Client</b></div>
                    <div className='col-sm-8'>
                      <input type="text" className="form-control form-control-user"
                        name="clientAttachment" readOnly={detailFlag} onChange={inputExecutionHandler} value={inputExecutionfield.clientAttachment} placeholder="enter Client Attachment" />
                    </div>
                  </div>

                </div>
                {/* <div className='col-md-1'></div> */}
                <div className="col-md-6 ml-5">

                  <div className="form-group row">
                    <div className='col-sm-2 '><b>Account Manager</b></div>
                    <div className='col-sm-10'>
                      <input type="text" className="form-control form-control-user"
                        name="accountManagerName" readOnly={detailFlag} value={inputExecutionfield.accountManagerName} placeholder="enter AccountManager Name" />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-2 '><b>Project Manager</b></div>
                    <div className='col-sm-10'>
                      <Select
                        name="projectManagerId"
                        // isDisabled={detailFlag}
                        isMulti={true}
                        placeholder="Enter Assignee "
                        options={projectManager.map((user) => ({
                          value: user.id,
                          label: `${user.firstname} ${user.lastname} - ${user.rolename}`,
                        }))}
                        value={projectSelectedAssignees}
                        onChange={handleSelectChange}

                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-4 '><b>Booth Name</b></div>
                    <div className='col-sm-8'>
                      <input type="text" className="form-control form-control-user"
                        name="boothName" readOnly={detailFlag} value={inputExecutionfield.boothName} onChange={inputExecutionHandler} placeholder="enter Booth Name" />
                    </div>
                  </div>

                  <div className="form-group row d-flex">
                    <div className='col-sm-3 '><b>Travel Departure Date</b></div>

                    <input type="date" readOnly={detailFlag} className="form-control col-sm-3 mb-1 form-control-user" value={formatDate(inputExecutionfield.travellingDateDeparture)} name="travellingDateDeparture" onChange={inputExecutionHandler} />

                    <div className='col-sm-3 '><b>Travel Arrival Date</b></div>

                    <input type="date" className="form-control col-sm-3 mb-1  form-control-user" readOnly={detailFlag} value={formatDate(inputExecutionfield.travellingArrivalDate)} name="travellingArrivalDate" onChange={inputExecutionHandler} />
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-4  mt-2'><b>Venue</b></div>
                    <div className='col-sm-8'>
                      <input type="text" className="form-control form-control-user"
                        value={inputExecutionfield.venue} readOnly={detailFlag} name="venue" placeholder="enter Venue" />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-4  mt-2'><b>Area</b></div>
                    <div className='col-sm-8'>
                      <input type="text" className="form-control form-control-user"
                        name="area" value={inputExecutionfield.area} readOnly={detailFlag} onChange={inputExecutionHandler} placeholder="enter Area" />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-4  mt-2'><b>Dimensions</b></div>
                    <div className='col-sm-8'>
                      <input type="text" className="form-control form-control-user"
                        name="dimensions" value={inputExecutionfield.dimensions} readOnly={detailFlag} onChange={inputExecutionHandler} placeholder="enter Dimensions" />
                    </div>
                  </div>

                  <h5>Supplier</h5>

                  <div className="form-group row d-flex">
                    <div className='col-sm-3 mt-1 '><b>Handover Date Time Supplier</b></div>

                    <input type="datetime-local" min={getCurrentDateTimeForDB()} className="form-control col-sm-3 mb-1 form-control-user" readOnly={detailFlag} name="handoverDateSupplier" value={inputExecutionfield.handoverDateSupplier} onChange={inputExecutionHandler} />

                    <div className='col-sm-3 mt-1 '><b> Meeting Date Supplier</b> </div>

                    <input type="date" className="form-control col-sm-3 mb-1  form-control-user" readOnly={detailFlag} name="meetingDateSupplier" value={formatDate(inputExecutionfield.meetingDateSupplier)} onChange={inputExecutionHandler} />
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-4  mt-2'><b>MOM Supplier</b></div>
                    <div className='col-sm-8'>
                      <input type="text" className="form-control form-control-user"
                        name="supplierAttachment" readOnly={detailFlag} onChange={inputExecutionHandler} value={inputExecutionfield.supplierAttachment} placeholder='Attach Supplier attachment' />
                    </div>
                  </div>
                  {/* <div className="form-group row">
                    <button type='submit' className='btn btn-primary text-uppercase'>Save</button>
                  </div> */}

                </div>
              </div>

              <div className="row">

                <h5 className='col-sm-12'><b>Headline</b></h5>
                <div className='col-sm-12 shadow bg-info rounded mt-2 d-flex sticky-top mb-4' style={{ position: "sticky", top: "17vh" }}>
                  <h5 className='ml-2 col-sm-4 mt-2 d-flex justify-content-center'><b>Client</b></h5>
                  <h5 className='ml-2 col-sm-4 mt-2 d-flex justify-content-center'><b>Internal</b></h5>
                  <h5 className='ml-2 col-sm-3 mt-2 d-flex justify-content-center'><b>Supplier</b></h5>

                </div>

                <h5 className='col-sm-12'><b>Contract Sent</b></h5>
                <div className="col-sm-4">
                  <div className="form-group row">
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Deadline</b></div>
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Adhered</b></div>
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Remarks</b></div>
                  </div>

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineClient" readOnly={detailFlag} onChange={handleContractchange} value={formatDate(contractSent.deadlineClient)} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredClient" disabled={detailFlag} value={contractSent.adheredClient} onChange={handleContractchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-md-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={99}
                        readOnly={detailFlag}
                        value={contractSent.remarksClient}
                        onChange={handleContractchange}
                        name='remarksClient'
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                      />
                    </div>
                    {/* <span class="border-left border-dark ">hello</span> */}

                  </div>


                </div>
                <div className="col-sm-4">
                  <div className="form-group row">
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Deadline</b></div>
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Adhered</b></div>
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Remarks</b></div>
                  </div>
                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineInternal" readOnly={detailFlag} value={formatDate(contractSent.deadlineInternal)} onChange={handleContractchange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredInternal" disabled={detailFlag} value={contractSent.adheredInternal} onChange={handleContractchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        onChange={handleContractchange}
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksInternal'
                        value={contractSent.remarksInternal}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus

                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group row">
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Deadline</b></div>
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Adhered</b></div>
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Remarks</b></div>
                  </div>
                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        onChange={handleContractchange} name="deadlineSupplier" readOnly={detailFlag} value={formatDate(contractSent.deadlineSupplier)} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredSupplier" disabled={detailFlag} value={contractSent.adheredSupplier} onChange={handleContractchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        onChange={handleContractchange}
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksSupplier'
                        value={contractSent.remarksSupplier}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus

                      />
                    </div>
                  </div>
                </div>

                <h5 className='col-sm-12'><b>Contract Signed</b></h5>

                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineClient" readOnly={detailFlag} value={formatDate(contractSigned.deadlineClient)} onChange={handleContractSignedChange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredClient" disabled={detailFlag} value={contractSigned.adheredClient} onChange={handleContractSignedChange} >
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea

                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        name='remarksClient'
                        readOnly={detailFlag}
                        value={contractSigned.remarksClient}
                        onChange={handleContractSignedChange}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus

                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineInternal" readOnly={detailFlag} value={formatDate(contractSigned.deadlineInternal)} onChange={handleContractSignedChange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredInternal" disabled={detailFlag} value={contractSigned.adheredInternal} onChange={handleContractSignedChange} >
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        name='remarksInternal'
                        readOnly={detailFlag}
                        value={contractSigned.remarksInternal}
                        onChange={handleContractSignedChange}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus

                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineSupplier" readOnly={detailFlag} value={formatDate(contractSigned.deadlineSupplier)} onChange={handleContractSignedChange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredSupplier" disabled={detailFlag} value={contractSigned.adheredSupplier} onChange={handleContractSignedChange} >
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksSupplier'
                        value={contractSigned.remarksSupplier}
                        onChange={handleContractSignedChange}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus

                      />
                    </div>
                  </div>
                </div>

                <h5 className='col-sm-12'><b>Paymment Done/Recieved</b></h5>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineClient" readOnly={detailFlag} value={formatDate(payment.deadlineClient)} onChange={handlePaymentchange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredClient" disabled={detailFlag} value={payment.adheredClient} onChange={handlePaymentchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        onChange={handlePaymentchange}
                        name='remarksClient'
                        value={payment.remarksClient}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus

                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        readOnly={detailFlag} name="deadlineInternal" value={formatDate(payment.deadlineInternal)} onChange={handlePaymentchange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredInternal" disabled={detailFlag} value={payment.adheredInternal} onChange={handlePaymentchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        onChange={handlePaymentchange}
                        name='remarksInternal'
                        value={payment.remarksInternal}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus

                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineSupplier" readOnly={detailFlag} value={formatDate(payment.deadlineSupplier)} onChange={handlePaymentchange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredSupplier" disabled={detailFlag} value={payment.adheredSupplier} onChange={handlePaymentchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        onChange={handlePaymentchange}
                        name='remarksSupplier'
                        value={payment.remarksSupplier}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus

                      />
                    </div>
                  </div>
                </div>

                <h5 className='col-sm-12'><b>Visa Invitation Letter</b></h5>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineClient" readOnly={detailFlag} value={formatDate(visa.deadlineClient)} onChange={handleVisachange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredClient" disabled={detailFlag} value={visa.adheredClient} onChange={handleVisachange} >
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        onChange={handleVisachange}
                        name='remarksClient'
                        value={visa.remarksClient}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus

                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineInternal" readOnly={detailFlag} value={formatDate(visa.deadlineInternal)} onChange={handleVisachange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredInternal" disabled={detailFlag} value={visa.adheredInternal} onChange={handleVisachange} >
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        onChange={handleVisachange}
                        name='remarksInternal'
                        value={visa.remarksInternal}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus

                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineSupplier" readOnly={detailFlag} value={formatDate(visa.deadlineSupplier)} onChange={handleVisachange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredSupplier" disabled={detailFlag} value={visa.adheredSupplier} onChange={handleVisachange} >
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        value={visa.remarksSupplier}
                        onChange={handleVisachange}
                        name='remarksSupplier'
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus

                      />
                    </div>
                  </div>
                </div>

                <h5 className='col-sm-12'><b>Material Details</b></h5>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineClient" readOnly={detailFlag} value={formatDate(materialDetails.deadlineClient)} onChange={handleMaterialDetailschange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredClient" disabled={detailFlag} value={materialDetails.adheredClient} onChange={handleMaterialDetailschange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksClient'
                        value={materialDetails.remarksClient}
                        onChange={handleMaterialDetailschange}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus

                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineInternal" readOnly={detailFlag} value={formatDate(materialDetails.deadlineInternal)} onChange={handleMaterialDetailschange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredInternal" disabled={detailFlag} value={materialDetails.adheredInternal} onChange={handleMaterialDetailschange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksInternal'
                        value={materialDetails.remarksInternal}
                        onChange={handleMaterialDetailschange}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus

                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineSupplier" readOnly={detailFlag} value={formatDate(materialDetails.deadlineSupplier)} onChange={handleMaterialDetailschange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredSupplier" disabled={detailFlag} value={materialDetails.adheredSupplier} onChange={handleMaterialDetailschange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksSupplier'
                        value={materialDetails.remarksSupplier}
                        onChange={handleMaterialDetailschange}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus

                      />
                    </div>
                  </div>
                </div>

                <h5 className='col-sm-12'><b>Login Details</b></h5>
                <div className="col-sm-4">
                  <div className="form-group row">
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Deadline</b></div>
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Adhered</b></div>
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Remarks</b></div>
                  </div>
                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineClient" readOnly={detailFlag} value={formatDate(LoginDetails.deadlineClient)} onChange={handleLoginDetailschange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredClient" disabled={detailFlag} value={LoginDetails.adheredClient} onChange={handleLoginDetailschange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksClient'
                        value={LoginDetails.remarksClient}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus

                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group row">
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Deadline</b></div>
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Adhered</b></div>
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Remarks</b></div>
                  </div>
                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineInternal" readOnly={detailFlag} value={formatDate(LoginDetails.deadlineInternal)} onChange={handleLoginDetailschange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredInternal" disabled={detailFlag} value={LoginDetails.adheredInternal} onChange={handleLoginDetailschange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksInternal'
                        value={LoginDetails.remarksInternal}
                        onChange={handleLoginDetailschange}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus

                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group row">
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Deadline</b></div>
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Adhered</b></div>
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Remarks</b></div>
                  </div>
                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineSupplier" readOnly={detailFlag} value={formatDate(LoginDetails.deadlineSupplier)} onChange={handleLoginDetailschange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredSupplier" disabled={detailFlag} value={LoginDetails.adheredSupplier} onChange={handleLoginDetailschange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        onChange={handleLoginDetailschange}
                        name='remarksSupplier'
                        value={LoginDetails.remarksSupplier}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus

                      />
                    </div>
                  </div>
                </div>

                <h5 className='col-sm-12'><b>Design Approval</b></h5>

                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineClient" readOnly={detailFlag} value={formatDate(designApprovals.deadlineClient)} onChange={handleDesignApprovalschange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredClient" disabled={detailFlag} value={designApprovals.adheredClient} onChange={handleDesignApprovalschange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        onChange={handleDesignApprovalschange}
                        value={designApprovals.remarksClient}
                        name='remarksClient'
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus

                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineInternal" readOnly={detailFlag} value={formatDate(designApprovals.deadlineInternal)} onChange={handleDesignApprovalschange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredInternal" disabled={detailFlag} value={designApprovals.adheredInternal} onChange={handleDesignApprovalschange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksInternal'
                        value={designApprovals.remarksInternal}
                        onChange={handleDesignApprovalschange}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus

                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineSupplier" readOnly={detailFlag} value={formatDate(designApprovals.deadlineSupplier)} onChange={handleDesignApprovalschange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredSupplier" disabled={detailFlag} value={designApprovals.adheredSupplier} onChange={handleDesignApprovalschange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        onChange={handleDesignApprovalschange}
                        name='remarksSupplier'
                        value={designApprovals.remarksSupplier}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus

                      />
                    </div>
                  </div>
                </div>

                <h5 className='col-sm-12'><b>Refundable Security</b></h5>

                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineClient" readOnly={detailFlag} value={formatDate(refundableSecurity.deadlineClient)} onChange={handleSecuritychange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredClient" disabled={detailFlag} value={refundableSecurity.adheredClient} onChange={handleSecuritychange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksClient'
                        value={refundableSecurity.remarksClient}
                        onChange={handleSecuritychange}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus

                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineInternal" readOnly={detailFlag} value={formatDate(refundableSecurity.deadlineInternal)} onChange={handleSecuritychange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredInternal" disabled={detailFlag} value={refundableSecurity.adheredInternal} onChange={handleSecuritychange} >
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        onChange={handleSecuritychange}
                        name='remarksInternal'
                        value={refundableSecurity.remarksInternal}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus

                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineSupplier" readOnly={detailFlag} value={formatDate(refundableSecurity.deadlineSupplier)} onChange={handleSecuritychange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredSupplier" disabled={detailFlag} value={refundableSecurity.adheredSupplier} onChange={handleSecuritychange} >
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksSupplier'
                        value={refundableSecurity.remarksSupplier}
                        onChange={handleSecuritychange}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus

                      />
                    </div>
                  </div>
                </div>

                <h5 className='col-sm-12'><b>Static Calculation</b></h5>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineClient" readOnly={detailFlag} value={formatDate(staticCalculation.deadlineClient)} onChange={handleStaticchange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredClient" disabled={detailFlag} value={staticCalculation.adheredClient} onChange={handleStaticchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        onChange={handleStaticchange}
                        name='remarksClient'
                        value={staticCalculation.remarksClient}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus

                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineInternal" readOnly={detailFlag} value={formatDate(staticCalculation.deadlineInternal)} onChange={handleStaticchange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredInternal" disabled={detailFlag} value={staticCalculation.adheredInternal} onChange={handleStaticchange} >
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        onChange={handleStaticchange}
                        name='remarksInternal'
                        value={staticCalculation.remarksInternal}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus

                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineSupplier" readOnly={detailFlag} value={formatDate(staticCalculation.deadlineSupplier)} onChange={handleStaticchange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredSupplier" disabled={detailFlag} value={staticCalculation.adheredSupplier} onChange={handleStaticchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        onChange={handleStaticchange}
                        name='remarksSupplier'
                        value={staticCalculation.remarksSupplier}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus

                      />
                    </div>
                  </div>
                </div>

                <h5 className='col-sm-12'><b>Technical Drawings</b></h5>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineClient" readOnly={detailFlag} value={formatDate(drawing.deadlineClient)} onChange={handleDrawingchange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredClient" disabled={detailFlag} value={drawing.adheredClient} onChange={handleDrawingchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksClient'
                        value={drawing.remarksClient}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleDrawingchange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineInternal" readOnly={detailFlag} value={formatDate(drawing.deadlineInternal)} onChange={handleDrawingchange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredInternal" disabled={detailFlag} value={drawing.adheredInternal} onChange={handleDrawingchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksInternal'
                        value={drawing.remarksInternal}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleDrawingchange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineSupplier" readOnly={detailFlag} value={formatDate(drawing.deadlineSupplier)} onChange={handleDrawingchange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredSupplier" disabled={detailFlag} value={drawing.adheredSupplier} onChange={handleDrawingchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksSupplier'
                        value={drawing.remarksSupplier}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleDrawingchange}
                      />
                    </div>
                  </div>
                </div>

                <h5 className='col-sm-12'><b>Electricity Order</b></h5>
                <div className="col-sm-4">
                  <div className="form-group row">
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Deadline</b></div>
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Adhered</b></div>
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Remarks</b></div>
                  </div>
                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineClient" readOnly={detailFlag} value={formatDate(electricity.deadlineClient)} onChange={handleElectricitychange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredClient" disabled={detailFlag} value={electricity.adheredClient} onChange={handleElectricitychange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksClient'
                        value={electricity.remarksClient}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleElectricitychange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group row">
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Deadline</b></div>
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Adhered</b></div>
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Remarks</b></div>
                  </div>
                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineInternal" readOnly={detailFlag} value={formatDate(electricity.deadlineInternal)} onChange={handleElectricitychange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredInternal" disabled={detailFlag} value={electricity.adheredInternal} onChange={handleElectricitychange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksInternal'
                        value={electricity.remarksInternal}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleElectricitychange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group row">
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Deadline</b></div>
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Adhered</b></div>
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Remarks</b></div>
                  </div>
                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"

                        name="deadlineSupplier" readOnly={detailFlag} value={formatDate(electricity.deadlineSupplier)} onChange={handleElectricitychange} />

                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredSupplier" disabled={detailFlag} value={electricity.adheredSupplier} onChange={handleElectricitychange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksSupplier'
                        value={electricity.remarksSupplier}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleElectricitychange}
                      />
                    </div>
                  </div>
                </div>

                <h5 className='col-sm-12'><b>Rigging</b></h5>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineClient" readOnly={detailFlag} value={formatDate(rigging.deadlineClient)} onChange={handleRiggingchange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredClient" disabled={detailFlag} value={rigging.adheredClient} onChange={handleRiggingchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksClient'
                        value={rigging.remarksClient}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleRiggingchange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineInternal" readOnly={detailFlag} value={formatDate(rigging.deadlineInternal)} onChange={handleRiggingchange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredInternal" disabled={detailFlag} value={rigging.adheredInternal} onChange={handleRiggingchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksInternal'
                        value={rigging.remarksInternal}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleRiggingchange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineSupplier" readOnly={detailFlag} value={formatDate(rigging.deadlineSupplier)} onChange={handleRiggingchange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredSupplier" disabled={detailFlag} value={rigging.adheredSupplier} onChange={handleRiggingchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksSupplier'
                        value={rigging.remarksSupplier}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleRiggingchange}
                      />
                    </div>
                  </div>
                </div>
                <h5 className='col-sm-12'><b>Internet</b></h5>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineClient" readOnly={detailFlag} value={formatDate(internet.deadlineClient)} onChange={handleInternetchange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredClient" disabled={detailFlag} value={internet.adheredClient} onChange={handleInternetchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksClient'
                        value={internet.remarksClient}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleInternetchange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineInternal" readOnly={detailFlag} value={formatDate(internet.deadlineInternal)} onChange={handleInternetchange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredInternal" disabled={detailFlag} value={internet.adheredInternal} onChange={handleInternetchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksInternal'
                        value={internet.remarksInternal}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleInternetchange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineSupplier" readOnly={detailFlag} value={formatDate(internet.deadlineSupplier)} onChange={handleInternetchange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredSupplier" disabled={detailFlag} value={internet.adheredSupplier} onChange={handleInternetchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksSupplier'
                        value={internet.remarksSupplier}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleInternetchange}
                      />
                    </div>
                  </div>
                </div>

                <h5 className='col-sm-12'><b>Cleaning</b></h5>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineClient" readOnly={detailFlag} value={formatDate(cleaning.deadlineClient)} onChange={handleCleaningchange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredClient" disabled={detailFlag} value={cleaning.adheredClient} onChange={handleCleaningchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksClient'
                        value={cleaning.remarksClient}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleCleaningchange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineInternal" readOnly={detailFlag} value={formatDate(cleaning.deadlineInternal)} onChange={handleCleaningchange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredInternal" disabled={detailFlag} value={cleaning.adheredInternal} onChange={handleCleaningchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksInternal'
                        value={cleaning.remarksInternal}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleCleaningchange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineSupplier" readOnly={detailFlag} value={formatDate(cleaning.deadlineSupplier)} onChange={handleCleaningchange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredSupplier" disabled={detailFlag} value={cleaning.adheredSupplier} onChange={handleCleaningchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksSupplier'
                        value={cleaning.remarksSupplier}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleCleaningchange}
                      />
                    </div>
                  </div>
                </div>

                <h5 className='col-sm-12'><b>Water</b></h5>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineClient" readOnly={detailFlag} value={formatDate(water.deadlineClient)} onChange={handleWaterchange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredClient" disabled={detailFlag} value={water.adheredClient} onChange={handleWaterchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksClient'
                        value={water.remarksClient}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleWaterchange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineInternal" readOnly={detailFlag} value={formatDate(water.deadlineInternal)} onChange={handleWaterchange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredInternal" disabled={detailFlag} value={water.adheredInternal} onChange={handleWaterchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksInternal'
                        value={water.remarksInternal}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleWaterchange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineSupplier" readOnly={detailFlag} value={formatDate(water.deadlineSupplier)} onChange={handleWaterchange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredSupplier" disabled={detailFlag} value={water.adheredSupplier} onChange={handleWaterchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksSupplier'
                        value={water.remarksSupplier}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleWaterchange}
                      />
                    </div>
                  </div>
                </div>

                <h5 className='col-sm-12'><b>Logos</b></h5>
                <div className="col-sm-4">
                  <div className="form-group row">
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Deadline</b></div>
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Adhered</b></div>
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Remarks</b></div>
                  </div>
                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineClient" readOnly={detailFlag} value={formatDate(logos.deadlineClient)} onChange={handleLogoschange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredClient" disabled={detailFlag} value={logos.adheredClient} onChange={handleLogoschange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksClient'
                        value={logos.remarksClient}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleLogoschange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group row">
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Deadline</b></div>
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Adhered</b></div>
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Remarks</b></div>
                  </div>
                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineInternal" readOnly={detailFlag} value={formatDate(logos.deadlineInternal)} onChange={handleLogoschange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredInternal" disabled={detailFlag} value={logos.adheredInternal} onChange={handleLogoschange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksInternal'
                        value={logos.remarksInternal}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleLogoschange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group row">
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Deadline</b></div>
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Adhered</b></div>
                    <div className='col-sm-2 d-flex justify-content-center ml-5'><b>Remarks</b></div>
                  </div>
                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineSupplier" readOnly={detailFlag} value={formatDate(logos.deadlineSupplier)} onChange={handleLogoschange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredSupplier" disabled={detailFlag} value={logos.adheredSupplier} onChange={handleLogoschange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksSupplier'
                        value={logos.remarksSupplier}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleLogoschange}
                      />
                    </div>
                  </div>
                </div>

                <h5 className='col-sm-12'><b>Graphics</b></h5>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineClient" readOnly={detailFlag} value={formatDate(graphics.deadlineClient)} onChange={handleGraphicschange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredClient" disabled={detailFlag} value={graphics.adheredClient} onChange={handleGraphicschange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksClient'
                        value={graphics.remarksClient}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleGraphicschange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineInternal" readOnly={detailFlag} value={formatDate(graphics.deadlineInternal)} onChange={handleGraphicschange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredInternal" disabled={detailFlag} value={graphics.adheredInternal} onChange={handleGraphicschange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksInternal'
                        value={graphics.remarksInternal}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleGraphicschange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineSupplier" readOnly={detailFlag} value={formatDate(graphics.deadlineSupplier)} onChange={handleGraphicschange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredSupplier" disabled={detailFlag} value={graphics.adheredSupplier} onChange={handleGraphicschange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksSupplier'
                        value={graphics.remarksSupplier}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleGraphicschange}
                      />
                    </div>
                  </div>
                </div>
                <h5 className='col-sm-12'><b>Badges</b></h5>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineClient" readOnly={detailFlag} value={formatDate(badges.deadlineClient)} onChange={handleBadgeschange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredClient" disabled={detailFlag} value={badges.adheredClient} onChange={handleBadgeschange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksClient'
                        value={badges.remarksClient}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleBadgeschange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineInternal" readOnly={detailFlag} value={formatDate(badges.deadlineInternal)} onChange={handleBadgeschange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredInternal" disabled={detailFlag} value={badges.adheredInternal} onChange={handleBadgeschange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksInternal'
                        value={badges.remarksInternal}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleBadgeschange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineSupplier" readOnly={detailFlag} value={formatDate(badges.deadlineSupplier)} onChange={handleBadgeschange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredSupplier" disabled={detailFlag} value={badges.adheredSupplier} onChange={handleBadgeschange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksSupplier'
                        value={badges.remarksSupplier}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleBadgeschange}
                      />
                    </div>
                  </div>
                </div>

                <h5 className='col-sm-12'><b>Travel Requisition</b></h5>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineClient" readOnly={detailFlag} value={formatDate(travel.deadlineClient)} onChange={handleTravelchange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredClient" disabled={detailFlag} value={travel.adheredClient} onChange={handleTravelchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksClient'
                        value={travel.remarksClient}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleTravelchange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineInternal" readOnly={detailFlag} value={formatDate(travel.deadlineInternal)} onChange={handleTravelchange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredInternal" disabled={detailFlag} value={travel.adheredInternal} onChange={handleTravelchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksInternal'
                        value={travel.remarksInternal}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleTravelchange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineSupplier" readOnly={detailFlag} value={formatDate(travel.deadlineSupplier)} onChange={handleTravelchange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredSupplier" disabled={detailFlag} value={travel.adheredSupplier} onChange={handleTravelchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksSupplier'
                        value={travel.remarksSupplier}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleTravelchange}
                      />
                    </div>
                  </div>
                </div>

                <h5 className='col-sm-12'><b>Handover Protocol</b></h5>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineClient" readOnly={detailFlag} value={formatDate(handover.deadlineClient)} onChange={handleHandoverchange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredClient" disabled={detailFlag} value={handover.adheredClient} onChange={handleHandoverchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksClient'
                        value={handover.remarksClient}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleHandoverchange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineInternal" readOnly={detailFlag} value={formatDate(handover.deadlineInternal)} onChange={handleHandoverchange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredInternal" disabled={detailFlag} value={handover.adheredInternal} onChange={handleHandoverchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksInternal'
                        value={handover.remarksInternal}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleHandoverchange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">

                  <div className="form-group row">
                    <div className='col-sm-4'>
                      <input type="date" className="form-control ml-1"
                        name="deadlineSupplier" readOnly={detailFlag} value={formatDate(handover.deadlineSupplier)} onChange={handleHandoverchange} />
                    </div>
                    <div className='col-sm-3'>
                      <select className="form-control ml-0" name="adheredSupplier" disabled={detailFlag} value={handover.adheredSupplier} onChange={handleHandoverchange}>
                        <option>[NONE]</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>

                    <div className='col-sm-5'>
                      <textarea
                        rows={1} // You can adjust the number of visible rows
                        cols={100}
                        readOnly={detailFlag}
                        name='remarksSupplier'
                        value={handover.remarksSupplier}
                        placeholder='if Not Adhered?' // You can adjust the number of visible columns
                        class="form-control"
                        autofocus
                        onChange={handleHandoverchange}
                      />
                    </div>
                  </div>
                </div>
                {/* </>} */}

                {detailFlag === false && <div className='col-sm-2 mb-2'>
                  <span className="btn btn-outline-success" onClick={() => { agendahide === "" ? setagendahide("none") : setagendahide("") }}>New Additions</span>
                </div>}

                <div style={{ display: agendahide }}>

                  {
                    inputmultiFields.map((data, index) => {
                      const {
                        particularsName,
                        deadlineClient,
                        adheredClient,
                        remarksClient,
                        deadlineInternal,
                        remarksInternal,
                        adheredInternal,
                        deadlineSupplier,
                        adheredSupplier,
                        remarksSupplier } = data;
                      return (

                        <div className="row mb-1">
                          <h5 className='col-sm-12'>
                            <div className="col-sm-3">

                              <input type="text" className='form-control' readOnly={detailFlag} name='particularsName' value={particularsName} placeholder='enter Additional Particular' onChange={(evnt) => handleChange(index, evnt)} />
                            </div>
                            <div className="col-sm-8"></div>
                          </h5>

                          <div className="col-sm-4">

                            <div className="form-group row">
                              <div className='col-sm-4'>
                                <input type="date" className="form-control ml-1"
                                  name="deadlineClient" readOnly={detailFlag} value={formatDate(deadlineClient)} onChange={(evnt) => handleChange(index, evnt)} />
                              </div>
                              <div className='col-sm-3'>
                                <select className="form-control ml-0" disabled={detailFlag} name="adheredClient" value={adheredClient} onChange={(evnt) => handleChange(index, evnt)}>
                                  <option>[NONE]</option>
                                  <option value={true}>Yes</option>
                                  <option value={false}>No</option>
                                </select>
                              </div>

                              <div className='col-sm-5'>
                                <textarea
                                  rows={1} // You can adjust the number of visible rows
                                  cols={100}
                                  readOnly={detailFlag}
                                  name='remarksClient'
                                  placeholder='if Not Adhered?' // You can adjust the number of visible columns
                                  class="form-control"
                                  autofocus
                                  value={remarksClient}
                                  onChange={(evnt) => handleChange(index, evnt)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4">

                            <div className="form-group row">
                              <div className='col-sm-4'>
                                <input type="date" className="form-control ml-1"
                                  name="deadlineInternal" readOnly={detailFlag} value={formatDate(deadlineInternal)} onChange={(evnt) => handleChange(index, evnt)} />
                              </div>
                              <div className='col-sm-3'>
                                <select className="form-control ml-0" disabled={detailFlag} name="adheredInternal" value={adheredInternal} onChange={(evnt) => handleChange(index, evnt)}>
                                  <option>[NONE]</option>
                                  <option value={true}>Yes</option>
                                  <option value={false}>No</option>
                                </select>
                              </div>

                              <div className='col-sm-5'>
                                <textarea
                                  rows={1} // You can adjust the number of visible rows
                                  cols={100}
                                  readOnly={detailFlag}
                                  value={remarksInternal}
                                  name='remarksInternal'
                                  placeholder='if Not Adhered?' // You can adjust the number of visible columns
                                  class="form-control"
                                  autofocus
                                  onChange={(evnt) => handleChange(index, evnt)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4">

                            <div className="form-group row">
                              <div className='col-sm-4'>
                                <input type="date" className="form-control ml-1"
                                  name="deadlineSupplier" readOnly={detailFlag} value={formatDate(deadlineSupplier)} onChange={(evnt) => handleChange(index, evnt)} />
                              </div>
                              <div className='col-sm-3'>
                                <select className="form-control ml-0" disabled={detailFlag} value={adheredSupplier} name="adheredSupplier" onChange={(evnt) => handleChange(index, evnt)}>
                                  <option>[NONE]</option>
                                  <option value={true}>Yes</option>
                                  <option value={false}>No</option>
                                </select>
                              </div>

                              <div className='col-sm-5'>
                                <textarea
                                  rows={1} // You can adjust the number of visible rows
                                  cols={100}
                                  readOnly={detailFlag}
                                  name='remarksSupplier'
                                  value={remarksSupplier}
                                  placeholder='if Not Adhered?' // You can adjust the number of visible columns
                                  class="form-control"
                                  autofocus
                                  onChange={(evnt) => handleChange(index, evnt)}
                                />
                              </div>
                            </div>
                          </div>

                          {detailFlag === false && <div className='col-sm-2'>
                            <span className="btn btn-outline-success " onClick={addInputField}>Add More</span>
                          </div>}
                          <div className="col-sm-1">
                            {(inputmultiFields.length !== 1) ? <span className="btn btn-outline-danger" onClick={removeInputFields}>x</span> : ''}

                          </div>

                        </div>

                        // </div>
                      )
                    })
                  }
                </div>

              </div>
            </div>
          </div>
        </form>
      </div >
    </div >
  )
}

export default CreateProjectExecutionComponent