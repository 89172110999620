import React, { useEffect, useState } from 'react'
import Service from '../../../Services/Admin/Service';
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState, convertFromHTML, ContentState, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Select from 'react-select';
import draftToHtml from 'draftjs-to-html';
import { Link } from 'react-router-dom';

const AgreementIsSharedComponent = (props) => {
    const userId = sessionStorage.getItem("id");

    const id = props.id;
    const [agreementData, setAgreementData] = useState([]);
    const [apiResponse, setApiResponse] = useState(null);
    const [issharedchecked, setissharedchecked] = useState(false);
    const [apiError, setApiError] = useState(null);
    const [inputField, setInputField] = useState(
        {
            id: props.id,
            details: "",
            projectid: 0,
            isAproved: false,
            isShared: false,
            type: "",
            createdby: userId,
            updatedBy: userId,
        }
    )

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    useEffect(() => {
        getAgreementDetails("Agreement/GetById?id=" + id)
    }, []);


    const getAgreementDetails = (method) => {
        Service.getdata(method).then((res) => {
            setAgreementData(res.data);
            console.log(res.data);
            debugger
            const content1 = convertFromRaw(JSON.parse(res.data.details));

            setEditorState(EditorState.createWithContent(content1));
            setInputField(res.data);
            setissharedchecked(res.data.isShared);
        });
    }

    useEffect(() => {
        if (apiResponse || apiError) {
            const timeoutId = setTimeout(removeSuccessMessage, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [apiResponse, apiError]);

    const removeSuccessMessage = () => {
        setApiResponse(null);
        setApiError(null);
    };

    const backbutton = () => {
        props.navigate(-1);
    }
    const handleIsCheckbox = (e) => {
        //alert(e.target.checked);
        inputField.isShared = e.target.checked;
        setissharedchecked(e.target.checked);
    }

    const handleEditorChange = (state) => {
        setEditorState(state);
    }

    const save = async (evt) => {
        debugger;
        if (evt && evt.preventDefault) {
            evt.preventDefault();

        }
        const formData = new FormData(evt.target);

        const data = {}
        for (const [key, value] of formData) {
            data[key] = value;
        }

        const task = {
            ...inputField
        };
        if (task) {

            Service.createorupdate("Agreement/CreateOrUpdate", task)
                .then(res => {
                    setApiResponse(res.data); // Set the API response in state
                })
                .catch(error => {
                    // Handle error if API call fails
                    console.error("API Error:", error);
                    setApiError("Error occurred while saving the meeting. Please try again later.");
                });
        }
    }

    return (
        <div>
            <div className="container-fluid">

                <div className="card shadow border-success mb-2">
                    <div className="card-header rounded py-3">
                        <div className="row">
                            <div class="d-flex flex-start align-items-center">

                            </div>
                            <div className="col-sm-4"><h2><b className='text-dark'>New Agreement Shared</b></h2></div>
                            <div className="col-sm-6"></div>
                            <div className="col-sm-2 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="card cardUser shadow mb-4" >
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-success">Shared</h6>

                    </div>


                    <div class="container">
                        <form onSubmit={save} class="form-horizontal text-dark mt-2">
                            <div class="form-group row">
                                <label for="details" class="col-sm-5 required-label  control-label"><b>Agreement Details</b></label>
                                <div class="col-sm-7">
                                    <Editor
                                        readOnly
                                        editorState={editorState}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        onEditorStateChange={handleEditorChange}
                                        required
                                    />
                                </div>

                            </div>

                            <div class="form-group row">
                                <div className='col-sm-5 required-label'><b>Project</b></div>
                                <div className='col-sm-7'>
                                    <b>{agreementData.projectName}</b>
                                </div>
                                <div class="col-sm-9">
                                </div>
                            </div>

                            <div class="form-group row">
                                <div className='col-sm-5 required-label'><b>Agreement Type</b></div>
                                <div className='col-sm-7'>
                                    <b> {agreementData.type}</b>
                                </div>
                                <div class="col-sm-9">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="details" class="col-sm-5  required-label  control-label"><b>Is Shared</b></label>
                                <div class="col-sm-7">
                                    <input
                                        type="checkbox"
                                        id="isShared"
                                        name='isShared'
                                        checked={issharedchecked}
                                        onChange={handleIsCheckbox}
                                        autoFocus
                                    />
                                </div>
                            </div>
                            {apiResponse && (
                                <div className="alert alert-success" role="alert">
                                    Agreement has been Updated Successfully!
                                </div>
                            )}
                            {apiError && (
                                <div className="alert alert-danger" role="alert">
                                    {apiError}
                                </div>
                            )}
                            <button type="submit" class="btn btn-primary ml-3 mb-3">Save</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AgreementIsSharedComponent