import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Service from '../../../Services/Admin/Service';
import { formatDate, getCurrentDate } from '../../../Helpers/supportedFunctions/supportedGlobalFunctions';

const CreateRFPComponent = (props) => {

    const [apiResponse, setApiResponse] = useState(null);
    const [leadError, setLeadError] = useState(false);
    const [leadid, setLeadid] = useState([]);
    const [deadlineError, setDeadlineError] = useState(false)
    const [leadData, setLeadData] = useState([]);
    const [apiError, setApiError] = useState(null);
    let id = sessionStorage.getItem('id');
    const [inputField, setInputField] = useState({
        name: "",
        leadid: "",
        leadName: "",
        exhibitionName: "",
        venue: "",
        dateTo: "",
        dateFrom: "",
        deadlineDate: "",
        floorplan: "",
        guidelines: "",
        stallNo: "",
        hallNo: "",
        budget: "",
        contactName: "",
        fax: "",
        email: "",
        position: "",
        companyName: "",
        phone: "",
        billingAddress: "",
        anySpecific: "",
        website: "",
        description: "",
        noofProduct: "",
        designRefrence: "",
        yourpurpose: "",
        brandguidelines: "",
        do: "",
        dont: "",
        updatedby: id,
        createdby: id,

    });

    useEffect(() => {
        getLeadData("Lead/GetAll?userId=" + id);
    })

    const getLeadData = (method) => {
        Service.getdata(method).then((res) => {
            setLeadData(res.data);
            console.log(res.data)

        })
    }

    const handleInputStatus = (e) => {

        setLeadid(e.target.value);

        debugger
        var showid = leadData.filter(x => x.leadid === e.target.value);
        if (showid.length !== 0) {
            getShowDetailsById("Shows/GetALLShowsChapterId?id=", showid[0].showid);
            getLeadDetailsById("Lead/GetById?id=", showid[0].id);

        }

    }

    const getLeadDetailsById = (method, id) => {
        Service.getdatabyId(method, id).then((res) => {
            console.log(res.data);
            debugger
            setInputField((prevState) => ({
                ...prevState,
                ["startdate"]: res.data.startDate,
                ["enddate"]: res.data.endDate,
                ["exhibitionName"]: res.data.exhibitorName,
                ["venue"]: res.data.venue,
            }));

             debugger
        });
    }

    const getShowDetailsById = (method, id) => {
        Service.getdatabyId(method, id).then((res) => {
            console.log(res.data);
            setInputField((prevState) => ({
                ...prevState,
                ["startdate"]: res.data.startDate,
                ["enddate"]: res.data.endDate,
                ["exhibitionName"]: res.data.exhibitorName,
                ["venue"]: res.data.venue,
            }));

            debugger
        });
    }

    useEffect(() => {
        if (apiResponse || apiError) {
            const timeoutId = setTimeout(removeSuccessMessage, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [apiResponse, apiError]);

    const removeSuccessMessage = () => {
        setApiResponse(null);
        setApiError(null);
    };

    const save = (evt) => {
        if (evt.preventDefault && evt) {
            evt.preventDefault();
        }
        const formData = new FormData(evt.target);

        const data = {};
        for (const [key, value] of formData) {
            data[key] = value;
        }

        const {
            name,
            leadid,
            exhibitionName,
            venue, dateTo,
            dateFrom,
            deadlineDate,
            floorplan,
            guidelines,
            stallNo,
            hallNo,
            budget,
            contactName,
            fax,
            email,
            position,
            companyName,
            phone,
            billingAddress,
            anySpecific, website,
            description,
            noofProduct,
            designRefrence,
            yourpurpose,
            brandguidelines,
            dont,
            contentLink,


        } = data;

        function validateData(data) {
            const { leadid, deadlineDate } = data;
            debugger

            if (!deadlineDate || deadlineDate === "[NONE]") {
                setDeadlineError(true);
                return false;
            } else {
                setDeadlineError(false);
            }

            if (!leadid || leadid === "[NONE]") {
                setLeadError(true);
                return false;
            } else {
                setLeadError(false);
            }





            // All conditions are met, so return true to proceed forward.
            return true;
        }

        if (!validateData(data)) {
            // The validation returned false, so stop execution here.
            // You can add any specific handling for invalid data here, if needed.
            return;
        }


        const task = {
            ...inputField,
            ...data,
        };
        console.log(task)
        debugger
        if (task) {
            Service.createorupdate("RFP/CreateOrUpdate", task)
                .then(res => {
                    setApiResponse(res.data);
                    window.location.href = "/RFPList";
                    // Set the API response in state
                })
                .catch(error => {
                    // Handle error if API call fails
                    console.error("API Error:", error);
                    setApiError("Error occurred while saving the RFP. Please try again later.");
                });
        }

    }

    const inputsHandler = (e) => {
        //  setInputField( {[e.target.name]: e.target.value} )
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const externalLink = (data) => {
        debugger
        window.open(data, '_blank')
    }

    const backbutton = () => {
        props.navigate(-1);
    }

    return (
        <div>
            <div className="container-fluid">

                <br />
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col-sm-3"><h3>Create RFP</h3></div>
                            <div className="col-sm-5"></div>
                            <div className="col-sm-4 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link>
                            </div>

                        </div>
                    </div>
                </div>
                <form onSubmit={save}>
                    <div className="card shadow mb-4">
                        <div className="card-header d-flex py-3">
                            <h6 className="m-0 mt-1 font-weight-bold text-primary required-label">Note :-</h6>
                            <div className="m-0 ml-2 d-flex">If&nbsp;<div className="required-label"></div>&nbsp;field  not available please write N/A instead. </div>
                            {apiResponse && (
                                <div className="alert alert-success mt-2" role="alert">
                                    RFP has been created successfully!
                                </div>
                            )}
                            {apiError && (
                                <div className="alert alert-danger" role="alert">
                                    {apiError}
                                </div>
                            )}
                        </div>

                        <div className="card-body" style={{ height: "auto" }}>
                            <div className='row'>
                                <div className='col-md-5 ml-5'>
                                    <div className="form-group row">
                                        <div className='col-sm-12 required-label'><b>Lead</b></div>

                                        <div className='col-sm-12'>
                                            {leadError && <div className="alert alert-danger pl-2 mt-2" role="alert">Please select a Lead.</div>}
                                            <select className="form-control  form-control-user" onChange={handleInputStatus} name="leadid" required>
                                                <option>[NONE]</option>
                                                {
                                                    leadData.map((e, key) => {
                                                        //  return <option key={e.status_id} value={e.status_id}>{e.name}</option>;
                                                        return <option key={e.id} value={e.leadid}>{e.leadid}</option>;
                                                    })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Exhibhition Name</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="was"
                                            name="exhibitionName" placeholder="enter exhibhition name" onChange={inputsHandler} value={inputField.exhibitionName} /></div>
                                    </div>
                                    {/* <div className="form-group row">
                                        <div className='col-sm-12 required-label'>End Date</div>
                                        <div className='col-sm-12'><input type="date" className="form-control form-control-user" id="headline"
                                            name="dateTo" placeholder="Date To" /></div>
                                    </div> */}
                                    <div className="form-group row d-flex">
                                        <div className='col-sm-3 mt-1'><b>Show Start Date</b></div>
                                        {/* <div className='col-sm-2'> */}
                                        <input type="date" className="form-control col-sm-3 mb-1 form-control-user " id="" name="dateFrom" placeholder="enter" value={formatDate(inputField.startdate)} onChange={inputsHandler}/>
                                        {/* </div> */}
                                        <div className='col-sm-3 mt-1 '><b>Show End Date</b>   </div>
                                        {/* <div className='col-sm-4'> */}
                                        <input type="date" className="form-control col-sm-3 mb-1  form-control-user" id="" name="dateTo" placeholder="Date To" value={formatDate(inputField.enddate)} />
                                        {/* </div> */}
                                    </div>

                                    <div className="form-group row">
                                        {deadlineError && <div className="alert alert-danger mt-3" role="alert">Please select a design Deadline.</div>}
                                        <div className='col-sm-12 required-label'><b>Design Deadline</b></div>
                                        <div className='col-sm-12'><input type="date" className="form-control form-control-user" required id="headline"
                                            name="deadlineDate" placeholder="Enter Design Deadline" min={getCurrentDate()}/></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 required-label'><b>Floor Plan</b></div>
                                        <div className='col-sm-12'><textarea
                                            rows={1} // You can adjust the number of visible rows
                                            cols={100}
                                            name='floorplan'
                                            placeholder='enter floor plan link' // You can adjust the number of visible columns
                                            class="form-control"
                                            autofocus
                                            required
                                        /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='btn btn-sm ml-3 btn-info' onClick={() => externalLink()} >Open floorPlan </div>
                                    </div>
                                    <h5>Contact Info</h5>
                                    <div className="form-group row">
                                        <div className='col-sm-12 required-label'><b>Contact Name</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline"
                                            name="contactName" placeholder="enter contact name" required /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 required-label'><b>Position</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline"
                                            name="position" placeholder="enter position" required /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 required-label'><b>Company Name</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline"
                                            name="companyName" placeholder="enter company name" required /></div>
                                    </div>

                                    <div className="form-group row">
                                        <div className='col-sm-12 required-label'><b>Phone</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline"
                                            name="phone" placeholder="enter phone" required /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 required-label'><b>Web Address</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline"
                                            name="website" placeholder="http://example.com" required /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='btn btn-sm ml-3 btn-info' onClick={() => externalLink()} >Open Website</div>
                                    </div>
                                    <h5>Product display</h5>
                                    <div className="form-group row">
                                        <div className='col-sm-12 -label'><b>Number of Products to Display If Any</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline"
                                            name="noofProduct" placeholder="enter number of products" /></div>
                                    </div>
                                    <h6>Note : We need product LXWXH with drawing and photo if possible., you can attached product photo and drawing seperately</h6>

                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Your Purpose</b></div>
                                        <div className='col-sm-12'><textarea
                                            rows={3} // You can adjust the number of visible rows
                                            cols={100}
                                            name='yourpurpose'
                                            placeholder='Please elaborate your purpose by providing examples of stalls you like will help us understand your taste better and also the style you are looking for.' // You can adjust the number of visible columns
                                            class="form-control"
                                            autofocus
                                        /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 required-label'><b>Do's</b></div>
                                        <div className='col-sm-12'><textarea
                                            rows={3} // You can adjust the number of visible rows
                                            cols={100}
                                            name='do'
                                            placeholder="enter Do's /optional" // You can adjust the number of visible columns
                                            class="form-control"
                                            autofocus
                                            required
                                        /></div>
                                    </div>
                                   

                                </div>
                                <div className='col-md-1'></div>
                                <div className='col-md-5'>
                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Venue/Place/City</b></div>
                                        <div className='col-sm-12'><textarea
                                            rows={1} // You can adjust the number of visible rows
                                            cols={100}
                                            name='venue'
                                            value={inputField.venue}
                                            placeholder='enter Venue/Place/City' // You can adjust the number of visible columns
                                            class="form-control"
                                            autofocus
                                        /></div>
                                    </div>
                                    
                                    <div className="form-group row">
                                        <div className='col-sm-12 required-label'><b>Exhibhition Guidelines</b></div>
                                        <div className='col-sm-12'><textarea
                                            rows={3} // You can adjust the number of visible rows
                                            cols={100}
                                            name='guidelines'
                                            placeholder='enter guidelines details link' // You can adjust the number of visible columns
                                            class="form-control"
                                            autofocus
                                            required
                                        /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='btn btn-sm ml-3 btn-info' onClick={() => externalLink()} ><b>Exhibition Guidelines</b></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Stall No</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline"
                                            name="stallNo" placeholder="enter stall number" /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Hall No.</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline"
                                            name="hallNo" placeholder="enter hall number" /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 -label'><b>Budget</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline"
                                            name="budget" placeholder="enter budget" /></div>
                                    </div>
                                    <hr />

                                    <div className="form-group row" hidden={true}>
                                        <div className='col-sm-12 -label'><b>Fax</b></div>
                                        <div className='col-sm-12'><input type="text" className="form-control form-control-user" id="headline"
                                            name="fax" placeholder="enter fax" /></div>
                                    </div>
                                    <div className="form-group row" hidden={true}>
                                        <div className='col-sm-12 required-label '><b>Email</b></div>
                                        <div className='col-sm-12'><input type="text" value={"test@gmail.com"} className="form-control form-control-user" id="headline"
                                            name="email" placeholder="enter email address"  /></div>
                                    </div>
                                    <div className="form-group row" hidden={true}>
                                        <div className='col-sm-12 required-label'><b>Billing Address</b></div>
                                        <div className='col-sm-12'><textarea
                                            rows={2} // You can adjust the number of visible rows
                                            cols={100}
                                            name='billingAddress'
                                            placeholder='enter billing address' // You can adjust the number of visible columns
                                            class="form-control"
                                            value={"testBillingAddress"}
                                            autofocus
                                            
                                        /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Any Specific Need</b></div>
                                        <div className='col-sm-12'><textarea
                                            rows={2} // You can adjust the number of visible rows
                                            cols={100}
                                            name='anySpecific'
                                            placeholder='enter specific need' // You can adjust the number of visible columns
                                            class="form-control"
                                            autofocus
                                        /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 -label'><b>Description If Any</b></div>
                                        <div className='col-sm-12'><textarea
                                            rows={2} // You can adjust the number of visible rows
                                            cols={100}
                                            name='description'
                                            placeholder='enter description' // You can adjust the number of visible columns
                                            class="form-control"
                                            autofocus
                                        /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 '><b>Design Refrence</b></div>
                                        <div className='col-sm-12'><textarea
                                            rows={3} // You can adjust the number of visible rows
                                            cols={100}
                                            name='designRefrence'
                                            placeholder='Please give design refrence if any' // You can adjust the number of visible columns
                                            class="form-control"
                                            autofocus
                                        /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='col-sm-12 required-label'><b>Brand Guidelines</b></div>
                                        <div className='col-sm-12'><textarea
                                            rows={3} // You can adjust the number of visible rows
                                            cols={100}
                                            name='brandguidelines'
                                            placeholder='brand guidelines if any' // You can adjust the number of visible columns
                                            class="form-control"
                                            autofocus
                                            required
                                        /></div>
                                    </div>



                                    <div className="form-group row">
                                        <div className='col-sm-12'><b>Additional Attachments Link</b></div>
                                        <div className='col-sm-12'><textarea
                                            rows={2} // You can adjust the number of visible rows
                                            cols={100}
                                            name='contentLink'
                                            placeholder="enter additional attachment Link if any" // You can adjust the number of visible columns
                                            class="form-control size1"
                                            autofocus
                                        /></div>
                                    </div>
                                    <div className="form-group row">
                                        <div className='btn btn-sm ml-3 btn-info' onClick={() => externalLink()} >Open Additional Link</div>
                                    </div>

                                    <div className="form-group row">
                                        <div className='col-sm-12 required-label'><b>Dont's</b></div>
                                        <div className='col-sm-12'><textarea
                                            rows={3} // You can adjust the number of visible rows
                                            cols={100}
                                            name='dont'
                                            placeholder="enter Dont's /optional" // You can adjust the number of visible columns
                                            class="form-control"
                                            autofocus
                                            required
                                        /></div>
                                    </div>
                                </div>

                            </div>
                            {apiResponse && (
                                <div className="alert alert-success mt-2" role="alert">
                                    RFP has been created successfully!
                                </div>
                            )}
                            {apiError && (
                                <div className="alert alert-danger" role="alert">
                                    {apiError}
                                </div>
                            )}
                            <button className="btn btn-primary text-uppercase ml-5 mt-2" type='submit'>Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CreateRFPComponent