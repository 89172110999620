import React, { useEffect, useState } from 'react'
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, EditorState, convertFromHTML, ContentState, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Service from "../../../Services/Admin/Service";
import { Link } from 'react-router-dom';

const BoqDetailsComponent = (props) => {

  const [boqDetail, setBoqDetail] = useState([])
  const [vendor, setVendor] = useState([]);
  const [shared, setShared] = useState([]);
  const [boqLink, setBoqLink] = useState('');
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const id = props.id;
  useEffect(() => {
    getBoqInfo("Boq/GetById?id=" + id)
    getSelectedVendorDetails("SelectVendor/GetAll");

  }, [])

  const getSelectedVendorDetails = (method) => {
    Service.getdata(method).then((res) => {
      setVendor(res.data);
      debugger
    });

  }

  const getBoqInfo = (method) => {
    Service.getdata(method).then((res) => {
      setBoqDetail(res.data);
      if (res.data.isShared === true) {
        setShared("True");
      }
      else {
        setShared("False");
      }
      console.log(res.data);
      debugger
      const content1 = convertFromRaw(JSON.parse(res.data.boqdetails));

      setEditorState(EditorState.createWithContent(content1));
    });
  }
  console.log(boqDetail)

  function formatDate(dateString) {
    const dateObject = new Date(dateString);

    const day = String(dateObject.getDate()).padStart(2, '0');
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const year = dateObject.getFullYear();

    return `${day}-${month}-${year}`;
  }

  const backbutton = () => {
    props.navigate(-1);
  }

  const handleEditorChange = (state) => {
    setEditorState(state);
  }

  const externalLink = (data) => {
    debugger
    window.open(data, '_blank')
  }

  function formatDate(dateString) {
    const dateObject = new Date(dateString);

    const day = String(dateObject.getDate()).padStart(2, '0');
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const year = dateObject.getFullYear();

    return `${day}-${month}-${year}`;
  }

  return (
    <div>
      <div className="container-fluid">

        <div className="card shadow border-success mb-2">
          <div className="card-header rounded py-3">
            <div className="row">
              <div class="d-flex flex-start align-items-center">
              </div>
              <div className="col-sm-4"><h2><b className='text-dark'>Boq Details</b></h2></div>
              <div className="col-sm-6"></div>
              <div className="col-sm-2 d-flex flex-row-reverse">
                <button className="btn btn-infoml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid text-dark">
        <div className="card cardUser shadow mb-4" >
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-success">Details</h6>
          </div>
          <div class="container">
            <form class="form-horizontal mt-2">

              <div class="form-group row mb-4">
                <div className='col-sm-5 required-label'>Project</div>
                <div className='col-sm-7'>
                  <b>{boqDetail.projectName}</b>

                </div>
                <div class="col-sm-9">
                </div>
              </div>

              <div class="form-group row mb-4">
                <div className='col-sm-5 required-label'>Shared To</div>
                <div className='col-sm-4'>
                  <select className="form-control " value={boqDetail.boqType} name="boqType" required>
                    <option>[NONE]</option>
                    <option value={3}>Client</option>
                    {
                      vendor.map((e, key) => {
                        return <option key={e.vendorid} value={e.vendorid}>{e.supplierName}</option>;
                      })}
                  </select>
                  {boqDetail.boqType === "3" ? <b className='mt-1'>{boqDetail.clientName}</b> : null}

                </div>
                <div class="col-sm-9">
                </div>
              </div>

              <div class="form-group row mb-4">
                <div className='col-sm-5 required-label'>is Shared</div>
                <div className='col-sm-7'>
                  <b>{shared}</b>
                </div>
                <div class="col-sm-9">
                </div>
              </div>

              <div class="form-group row mb-4">
                <div className='col-sm-5 required-label'>Approved Date</div>
                <div className='col-sm-7'>
                  <b>{formatDate(boqDetail.createdDate)}</b>
                </div>
                <div class="col-sm-9">
                </div>
              </div>

             {boqDetail.boqLink!==null && <div class="form-group row mb-4">
                <div className='col-sm-5 required-label'>Boq Link</div>
                <div className='col-sm-7'>
                  
                  <div className="form-group row">
                    <div className='btn btn-sm ml-3 btn-info' onClick={() => externalLink(boqDetail.boqLink)} >Open Boq Link</div>
                  </div>
                </div>
                <div class="col-sm-9">
                </div>
              </div>}


              <div class="form-group row mb-4">
                <div className='col-sm-5 required-label'>Boq Details</div>
                <div class="col-sm-12">
                  <Editor
                    readOnly
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={handleEditorChange}
                    required
                  />
                </div>
                <div class="col-sm-9">
                </div>
              </div>


              <h6>Platform & Flooring</h6>
              <div className="form-group row">
                <label for="details" class="ml-2 required-label  control-label">Platform & Flooring Details :</label>
                <div class="ml-2 col-sm-2">
                  {
                    boqDetail.inputplatforming?.map((e) => (
                      <div>
                        <b>
                          {e.platforming}
                        </b>
                      </div>
                    ))}
                </div>
                <label for="details" class="ml-2 required-label  control-label">Quantity :</label>
                <div class="ml-2 col-sm-2">
                  {
                    boqDetail.inputplatforming?.map((e) => (
                      <div>
                        <b>
                          {e.qty}
                        </b>
                      </div>
                    ))}
                </div>
                <label for="details" class="ml-2 required-label  control-label">Unit:</label>
                <div class="ml-2 col-sm-2">
                  {
                    boqDetail.inputplatforming?.map((e) => (
                      <div>
                        <b>
                          {e.unit}
                        </b>
                      </div>
                    ))}
                </div>

              </div>

              <h6>Walling & Special Construction</h6>
              <div className="form-group row">
                <label for="details" class="ml-2 required-label  control-label">Platform & Flooring Details :</label>
                <div class="ml-2 col-sm-2">
                  {
                    boqDetail.inputwalling?.map((e) => (
                      <div>
                        <b>
                          {e.walling}
                        </b>
                      </div>
                    ))}
                </div>
                <label for="details" class="ml-2 required-label  control-label">Quantity :</label>
                <div class="ml-2 col-sm-2">
                  {
                    boqDetail.inputwalling?.map((e) => (
                      <div>
                        <b>
                          {e.qty}
                        </b>
                      </div>
                    ))}
                </div>
                <label for="details" class="ml-2 required-label  control-label">Unit:</label>
                <div class="ml-2 col-sm-2">
                  {
                    boqDetail.inputwalling?.map((e) => (
                      <div>
                        <b>
                          {e.unit}
                        </b>
                      </div>
                    ))}
                </div>

              </div>

              <h6>Audio Visuals</h6>
              <div className="form-group row">
                <label for="details" class="ml-2 required-label  control-label">Platform & Flooring Details :</label>
                <div class="ml-2 col-sm-2">
                  {
                    boqDetail.inputaudiovisuals?.map((e) => (
                      <div>
                        <b>
                          {e.audiovisuals}
                        </b>
                      </div>
                    ))}
                </div>
                <label for="details" class="ml-2 required-label  control-label">Quantity :</label>
                <div class="ml-2 col-sm-2">
                  {
                    boqDetail.inputaudiovisuals?.map((e) => (
                      <div>
                        <b>
                          {e.qty}
                        </b>
                      </div>
                    ))}
                </div>
                <label for="details" class="ml-2 required-label  control-label">Unit:</label>
                <div class="ml-2 col-sm-2">
                  {
                    boqDetail.inputaudiovisuals?.map((e) => (
                      <div>
                        <b>
                          {e.unit}
                        </b>
                      </div>
                    ))}
                </div>

              </div>

              <h6>Furniture & Hospitality Items</h6>
              <div className="form-group row">
                <label for="details" class="ml-2 required-label  control-label">Platform & Flooring Details :</label>
                <div class="ml-2 col-sm-2">
                  {
                    boqDetail.inputhospitality?.map((e) => (
                      <div>
                        <b>
                          {e.hospitality}
                        </b>
                      </div>
                    ))}
                </div>
                <label for="details" class="ml-2 required-label  control-label">Quantity :</label>
                <div class="ml-2 col-sm-2">
                  {
                    boqDetail.inputhospitality?.map((e) => (
                      <div>
                        <b>
                          {e.qty}
                        </b>
                      </div>
                    ))}
                </div>
                <label for="details" class="ml-2 required-label  control-label">Unit:</label>
                <div class="ml-2 col-sm-2">
                  {
                    boqDetail.inputhospitality?.map((e) => (
                      <div>
                        <b>
                          {e.unit}
                        </b>
                      </div>
                    ))}
                </div>

              </div>

              <h6>Lighting & Cabling</h6>
              <div className="form-group row">
                <label for="details" class="ml-2 required-label  control-label">Platform & Flooring Details :</label>
                <div class="ml-2 col-sm-2">
                  {
                    boqDetail.inputlighting?.map((e) => (
                      <div>
                        <b>
                          {e.lighting}
                        </b>
                      </div>
                    ))}
                </div>
                <label for="details" class="ml-2 required-label  control-label">Quantity :</label>
                <div class="ml-2 col-sm-2">
                  {
                    boqDetail.inputlighting?.map((e) => (
                      <div>
                        <b>
                          {e.qty}
                        </b>
                      </div>
                    ))}
                </div>
                <label for="details" class="ml-2 required-label  control-label">Unit:</label>
                <div class="ml-2 col-sm-2">
                  {
                    boqDetail.inputlighting?.map((e) => (
                      <div>
                        <b>
                          {e.unit}
                        </b>
                      </div>
                    ))}
                </div>

              </div>

              <h6>Branding & Graphics</h6>
              <div className="form-group row">
                <label for="details" class="ml-2 required-label  control-label">Platform & Flooring Details :</label>
                <div class="ml-2 col-sm-2">
                  {
                    boqDetail.inputbranding?.map((e) => (
                      <div>
                        <b>
                          {e.branding}
                        </b>
                      </div>
                    ))}
                </div>
                <label for="details" class="ml-2 required-label  control-label">Quantity :</label>
                <div class="ml-2 col-sm-2">
                  {
                    boqDetail.inputbranding?.map((e) => (
                      <div>
                        <b>
                          {e.qty}
                        </b>
                      </div>
                    ))}
                </div>
                <label for="details" class="ml-2 required-label  control-label">Unit:</label>
                <div class="ml-2 col-sm-2">
                  {
                    boqDetail.inputbranding?.map((e) => (
                      <div>
                        <b>
                          {e.unit}
                        </b>
                      </div>
                    ))}
                </div>

              </div>

              {/* <h6>Suspension</h6>
              <div className="form-group row">
                <label for="details" class="ml-2 required-label  control-label">Platform & Flooring Details :</label>
                <div class="ml-2 col-sm-2">
                  {
                    boqDetail.inputsuspension?.map((e) => (
                      <div>
                        <b>
                          {e.suspension}
                        </b>
                      </div>
                    ))}
                </div>
                <label for="details" class="ml-2 required-label  control-label">Quantity :</label>
                <div class="ml-2 col-sm-2">
                  {
                    boqDetail.inputsuspension?.map((e) => (
                      <div>
                        <b>
                          {e.qty}
                        </b>
                      </div>
                    ))}
                </div>
                <label for="details" class="ml-2 required-label  control-label">Unit:</label>
                <div class="ml-2 col-sm-2">
                  {
                    boqDetail.inputsuspension?.map((e) => (
                      <div>
                        <b>
                          {e.unit}
                        </b>
                      </div>
                    ))}
                </div>

              </div>

              <h6>Mezzanine</h6>
              <div className="form-group row">
                <label for="details" class="ml-2 required-label  control-label">Platform & Flooring Details :</label>
                <div class="ml-2 col-sm-2">
                  {
                    boqDetail.inputmezzanine?.map((e) => (
                      <div>
                        <b>
                          {e.mezzanine}
                        </b>
                      </div>
                    ))}
                </div>
                <label for="details" class="ml-2 required-label  control-label">Quantity :</label>
                <div class="ml-2 col-sm-2">
                  {
                    boqDetail.inputmezzanine?.map((e) => (
                      <div>
                        <b>
                          {e.qty}
                        </b>
                      </div>
                    ))}
                </div>
                <label for="details" class="ml-2 required-label  control-label">Unit:</label>
                <div class="ml-2 col-sm-2">
                  {
                    boqDetail.inputmezzanine?.map((e) => (
                      <div>
                        <b>
                          {e.unit}
                        </b>
                      </div>
                    ))}
                </div>

              </div> */}

              <h6>Other Cost</h6>
              <div className="form-group row">
                <label for="details" class="ml-2 required-label  control-label">Platform & Flooring Details :</label>
                <div class="ml-2 col-sm-2">
                  {
                    boqDetail.inputothers?.map((e) => (
                      <div>
                        <b>
                          {e.others}
                        </b>
                      </div>
                    ))}
                </div>
                <label for="details" class="ml-2 required-label  control-label">Quantity :</label>
                <div class="ml-2 col-sm-2">
                  {
                    boqDetail.inputothers?.map((e) => (
                      <div>
                        <b>
                          {e.qty}
                        </b>
                      </div>
                    ))}
                </div>
                <label for="details" class="ml-2 required-label  control-label">Unit:</label>
                <div class="ml-2 col-sm-2">
                  {
                    boqDetail.inputothers?.map((e) => (
                      <div>
                        <b>
                          {e.unit}
                        </b>
                      </div>
                    ))}
                </div>

              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BoqDetailsComponent