import React, { useEffect, useState } from "react";
import Service from "../../../Services/Admin/Service";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { getLoginUserData } from "../../../Helpers/supportedFunctions/supportedGlobalFunctions";

const DashboardLeadComponent = (props) => {
  const deptId = sessionStorage.getItem("DeptId");
  const [data, setData] = useState([]);

  useEffect(() => {
    debugger;

    if (getLoginUserData().userId === "1") {
      getallLeadData("Lead/GetAllLead");
    }

    if (getLoginUserData().deptId === "1") {
      getuserWiseLeadData(
        "Lead/GetAll?userId=" +
          getLoginUserData().userId +
          "&dept=" +
          "Marketing"
      );
    } else if (getLoginUserData().deptId === "2") {
      getuserWiseLeadData(
        "Lead/GetAll?userId=" + getLoginUserData().userId + "&dept=" + "Sales"
      );
    } else {
      getuserWiseLeadData(
        "Lead/GetAll?userId=" + getLoginUserData().userId + "&dept=" + "Sales"
      );
    }
  }, []);

  const getallLeadData = (method) => {
    Service.getdata(method).then((res) => {
      if (props.name === "salesLead") {
        var filteredData = res.data.filter(
          (x) => x.directLead === true && x.createRFP === true
        );
        setData(filteredData);
      } else {
        filteredData = res.data.filter(
          (x) => x.directLead === false && x.isConvincing === true
        );
        setData(filteredData);
      }
    });
  };

  const getuserWiseLeadData = (method) => {
    Service.getdata(method).then((res) => {
      setData(res.data);
      debugger;
    });
  };

  const backbutton = () => {
    props.navigate(-1);
  };

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div className="btn-group" role="group">
      {options.map((option) => (
        <button
          key={option.text}
          type="button"
          onClick={() => onSizePerPageChange(option.page)}
          className={`btn ${
            currSizePerPage === `${option.page}`
              ? "btn-secondary"
              : "btn-warning"
          }`}
        >
          {option.text}
        </button>
      ))}
    </div>
  );

  const options = {
    sizePerPageRenderer,
  };

  const staticname1 = "details";
  const staticname2 = "edit";

  const linkformatter = (data, row) => {
    return (
      <>
        <Link
          className="btn btn-info btn-sm"
          to={`/QualifiedLeadDetails/${data}&${staticname1}`}
        >
          details
        </Link>{" "}
        &nbsp;
        {/* {(deptId === "1" || getLoginUserData().userId === "3" || getLoginUserData().userId === "5" || deptId === "2") && <Link className="btn btn-warning btn-sm" to={`/SharedLeadDetails/${data}&${staticname2}`}>Edit</Link>} &nbsp; */}
        {/* <Link className="btn btn-warning btn-sm" to={`/MeetingDetails/${data}`}>Details</Link> */}
      </>
    );
  };

  const column = [
    // { dataField: 'showChapterId', text: 'show Chapter Id', sort: true,},
    { dataField: "leadid", text: "Lead Name", sort: true },
    { dataField: "isConvincing", text: "Convincing", sort: true },
    { dataField: "comments", text: "Comments", sort: true },
    // { dataField: 'isActive', text: 'Is Active' },

    { dataField: "id", text: "Actions", formatter: linkformatter },
  ];
  return (
    <div className="container-fluid">
      <div className="card shadow border-success mb-2">
        <div className="card-header rounded py-3">
          <div className="row">
            <div className="col-sm-4">
              <h3>Lead List</h3>
            </div>
            <div className="col-sm-6"></div>
            <div className="col-sm-2 d-flex flex-row-reverse">
              <button
                className="btn btn-info mt-2 ml-1  text-uppercase "
                onClick={() => backbutton()}
              >
                back
              </button>
              {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-1 ml-2">Home</Link> */}
            </div>
          </div>
        </div>
      </div>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Lead List</h6>
          {/* //  <button className="btn btn-primary" onClick={this.addRole}> Add Role</button> */}
        </div>

        <div className="card-body">
          <div className="table-responsive">
            <BootstrapTable
              keyField="id"
              data={data}
              columns={column}
              rowStyle={{ color: "black" }}
              pagination={paginationFactory(options)}
              filter={filterFactory()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLeadComponent;
