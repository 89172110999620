import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Service from '../../../Services/Admin/Service';
import { filterWithUniqueIds, formatDate, getLoginUserData } from '../../../Helpers/supportedFunctions/supportedGlobalFunctions';

const UserSheetListComponent = (props) => {

    const [rfpData, setRfpData] = useState([]);
    const [userTimesheetData, setUserTimeheetData] = useState([]);
    const [isConverted, setIsCoverted] = useState([]);

    useEffect(() => {
        getRFPData("RFP/GetAll?id=" + getLoginUserData().userId);
        getuserTimeSheetData("DesignerTimesheet/GetALLbyUserId?id=" + getLoginUserData().userId);
        // getuserTimeSheetDatabyRFpId("DesignerTimesheet/GetAll?id=" + getLoginUserData().userId);
    }, [])

    const getRFPData = (method) => {
        Service.getdata(method).then((res) => {
            var uniqueRFP = filterWithUniqueIds(res.data);
            setRfpData(uniqueRFP.reverse());
        })
    }

    const getuserTimeSheetData = (method) => {
        Service.getdata(method).then((res) => {
            setUserTimeheetData(res.data);
            var projectConverted = res.data((x) => x.status === "Converted");
            setIsCoverted(projectConverted);
        })
    }

    const conversionRate = (converted, total, status) => {
        if(status === "Converted"){
            const rate = (converted / total) * 100 ;
            return `${rate}%`;
        }
        else {
            return `${0}%`;
        }
    }

    const backbutton = () => {
        props.navigate(-1);
    }
    return (
        <div>
            <div className="container-fluid">

                <div className="card shadow border-success mb-2">
                    <div className="card-header rounded py-3">
                        <div className="row">
                            <div className="col-sm-7 row">
                                <h3 className='mt-2'><b className='text-dark'>User Timesheet List</b></h3>
                            </div>
                            <div className="col-sm-1"></div>
                            <div className="col-sm-4 d-flex flex-row-reverse">
                                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
                            </div>

                        </div>
                    </div>
                </div>

                <div className="card shadow mb-4">
                    <div className="card-header d-flex py-3">
                        <h6 className="m-0 mt-1 font-weight-bold text-success ">User Timesheet</h6>
                    </div>

                    <form style={{ overflowX: "auto", overflowY: "auto", position: "sticky", zIndex: 1, maxHeight: "500px" }}>
                        <div className=" d-flex ml-3">
                            <div className="col-sm-3" style={{ background: "#1cc88a",color:"black" }}><b>RFP</b></div>
                            <div className="col-sm-2" style={{ background: "#1cc88a",color:"black" }}><b>Show Name</b></div>
                            <div className="col-sm-2" style={{ background: "#1cc88a",color:"black" }}><b>Area</b></div>
                            <div className="col-sm-3" style={{ background: "#1cc88a",color:"black" }}><b>Date</b></div>
                            <div className="col-sm-3" style={{ background: "#1cc88a",color:"black" }}><b>Option Made</b></div>
                            <div className="col-sm-3" style={{ background: "#1cc88a",color:"black" }}><b>Working hrs.</b></div>
                            <div className="col-sm-2" style={{ background: "#1cc88a",color:"black" }}><b>Option Type</b></div>
                            <div className="col-sm-2" style={{ background: "#1cc88a",color:"black" }}><b>Client Type</b></div>
                            <div className="col-sm-2" style={{ background: "#1cc88a",color:"black" }}><b>Start date</b></div>
                            <div className="col-sm-2" style={{ background: "#1cc88a",color:"black" }}><b>End Date</b></div>
                            <div className="col-sm-2" style={{ background: "#1cc88a",color:"black" }}><b>Project Priority</b></div>
                            <div className="col-sm-2" style={{ background: "#1cc88a",color:"black" }}><b>Completion Date</b></div>
                            <div className="col-sm-3" style={{ background: "#1cc88a",color:"black" }}><b>Conversion status</b></div>
                            <div className="col-sm-3" style={{ background: "#1cc88a",color:"black" }}><b>Revision</b></div>
                            <div className="col-sm-2" style={{ background: "#1cc88a",color:"black" }}><b>Conversion Rate</b></div>
                            <div className="col-sm-1" style={{ background: "#1cc88a",color:"black" }}><b>Actions</b></div>
                        </div>
                        <div className="card-body">
                            {userTimesheetData.map((item) => {
                                return (
                                    <div className="row  mb-1">
                                        <div className=" d-flex" style={{ maxWidth: "100%" }}>
                                            <div className="col-sm-3">
                                                <div className="form-group row">
                                                    {/* <div className='col-sm-12 required-label'><b>RFP</b></div> */}
                                                    <div className='col-sm-12'>
                                                        <select className="form-control  form-control-user" readOnly value={item.rfpId} name="rfpId" required>
                                                            <option>[NONE]</option>
                                                            {
                                                                rfpData.map((e, key) => {
                                                                    return <option key={e.id} value={e.id}>{e.leadid}</option>;
                                                                })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-group row">
                                                    {/* <div className='col-sm-12 required-label'><b>Show Name</b></div> */}
                                                    <div className='col-sm-12'>
                                                        <input type="text" className='form-control  mb-1 form-control-user' readOnly value={item.showName} />

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-group row">
                                                    {/* <div className='col-sm-12 required-label'><b>Area</b></div> */}
                                                    <div className='col-sm-12'>
                                                        <input type="text" className='form-control  mb-1 form-control-user' readOnly value={item.area} />

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="form-group row">
                                                    {/* <div className='col-sm-12 required-label'><b>Date</b></div> */}
                                                    <div className='col-sm-12'>
                                                        <input type="date" className='form-control  mb-1 form-control-user' readOnly name='date' value={formatDate(item.date)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="form-group row">
                                                    {/* <div className='col-sm-12 required-label'><b>Option Made</b></div> */}

                                                    <div className='col-sm-12'>
                                                        <input type="text" className='form-control  mb-1 form-control-user' readOnly name='date' value={item.optionMade} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="form-group row">
                                                    {/* <div className='col-sm-12 required-label'><b>Working hrs.</b></div> */}
                                                    <div className='col-sm-12'>
                                                        <select className="form-control  form-control-user" readOnly name="workingHours" required value={item.workingHours}>
                                                            <option>[NONE]</option>
                                                            <option>2</option>
                                                            <option>4</option>
                                                            <option>6</option>
                                                            <option>8</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="form-group row">
                                                    <div className='col-sm-12'>
                                                        <select className="form-control  form-control-user" readOnly value={item.optionType} required>
                                                            <option>[NONE]</option>
                                                            <option>Custom</option>
                                                            <option>Be-Matrix</option>
                                                            <option>Custom & B.M.</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="form-group row">
                                                    <div className='col-sm-12'>
                                                        <select className="form-control  form-control-user" readOnly value={item.clientType} required>
                                                            <option>[NONE]</option>
                                                            <option>Old</option>
                                                            <option>New</option>
                                                            <option>Tendor</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group row">
                                                    {/* <div className='col-sm-12 required-label'><b>Start Date</b></div> */}
                                                    <div className='col-sm-12'>
                                                        <input type="date" className='form-control  mb-1 form-control-user' readOnly name='startDate' value={formatDate(item.startDate)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group row">
                                                    {/* <div className='col-sm-12 required-label'><b>End Date</b></div> */}
                                                    <div className='col-sm-12'>
                                                        <input type="date" className='form-control  mb-1 form-control-user' readOnly name='endDate' value={formatDate(item.endDate)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group row">
                                                    <div className='col-sm-12'>
                                                        <select className="form-control  form-control-user" readOnly value={item.projectPriority} required>
                                                            <option>[NONE]</option>
                                                            <option>Basic</option>
                                                            <option>Low</option>
                                                            <option>High</option>
                                                            <option>Very High</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group row">
                                                    {/* <div className='col-sm-12 required-label'><b>End Date</b></div> */}
                                                    <div className='col-sm-12'>
                                                        <input type="date" className='form-control  mb-1 form-control-user' readOnly name='completionDate' value={formatDate(item.completionDate)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="form-group row">
                                                    {/* <div className='col-sm-12 required-label'><b>Conversion Status</b></div> */}
                                                    <div className='col-sm-12'>
                                                        <select className="form-control  form-control-user" readOnly name="status" required value={item.status}>
                                                            <option>[NONE]</option>
                                                            <option>Converted</option>
                                                            <option>Not Converted</option>
                                                        </select>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="form-group row">
                                                    {/* <div className='col-sm-12 required-label'><b>Revision</b></div> */}
                                                    <div className='col-sm-12'>
                                                        <input type="text" className='form-control  mb-1 form-control-user' readOnly name='revisionMade' placeholder='revision number' value={item.revisionMade} />

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group row">
                                                    {/* <div className='col-sm-12 required-label'><b>Conversion Rate</b></div> */}
                                                    <div className='col-sm-12'>
                                                        <input type="text" className='form-control  mb-1 form-control-user' readOnly value={conversionRate(item.convertedCount, item.totalOptionMade, item.status)} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-1">
                                                <div className="form-group row">
                                                    {/* <div className='col-sm-12 required-label'><b>Actions</b></div> */}
                                                    <div className='col-sm-12'>
                                                        <Link to={`/EditUserSheet/${item.id}&edit`} className="btn btn-info">Edit</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default UserSheetListComponent