import React from 'react'
import { Link } from 'react-router-dom';

const FeedbackDashboardComponent = (props) => {

    const role = sessionStorage.getItem("role");



    const backbutton = () => {
        props.navigate(-1);
    }
    const roleButtons = [
        {
            role: "DGM-Projects",
            buttons: [
                { label: "Create Customer Feedback", className: "btn btn-success btn-md font-weight-bold text-uppercase ", to: `/CreateCustomerFeedback` },
                { label: "Create Project Closure Feedback", className: "btn btn-success btn-md font-weight-bold text-uppercase ", to: `/CreateProjectClosureFeedback` },
                { label: "Project Closure Feedback List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/ProjectClosureFeedbackList` },
                { label: "Customer Feedback List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/CustomerFeedbackList` },
                { label: "Create project Feedback", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateProjectFeedback` },
                { label: "project Feedback List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/ProjectFeedbackList` },
            ]
        },

        {
            role: "Managing Director(MD)",
            buttons: [
                { label: "create project Execution planning", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateProjectExecutionTracker` },
                { label: "Project Execution planning List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ProjectwiseExecutionTrackerList" },
                { label: "Create Customer Feedback", className: "btn btn-success btn-md font-weight-bold text-uppercase ", to: `/CreateCustomerFeedback` },
                { label: "Create Project Closure Feedback", className: "btn btn-success btn-md font-weight-bold text-uppercase ", to: `/CreateProjectClosureFeedback` },
                { label: "Project Closure Feedback List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/ProjectClosureFeedbackList` },
                { label: "Customer Feedback List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/CustomerFeedbackList` },
                { label: "Create project Feedback", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateProjectFeedback` },
                { label: "project Feedback List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/ProjectFeedbackList` },
            ]
          },
          {
            role: "Admin",
            buttons: [
                { label: "create project Execution planning", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateProjectExecutionTracker` },
                { label: "Project Execution planning List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ProjectwiseExecutionTrackerList" },
                { label: "Create Customer Feedback", className: "btn btn-success btn-md font-weight-bold text-uppercase ", to: `/CreateCustomerFeedback` },
                { label: "Create Project Closure Feedback", className: "btn btn-success btn-md font-weight-bold text-uppercase ", to: `/CreateProjectClosureFeedback` },
                { label: "Project Closure Feedback List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/ProjectClosureFeedbackList` },
                { label: "Customer Feedback List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/CustomerFeedbackList` },
                { label: "Create project Feedback", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateProjectFeedback` },
                { label: "project Feedback List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/ProjectFeedbackList` },
            ]
          },
      
      
          //operations
          {
            role: "Executive - Software Developer(SOP Tool)",
            buttons: [
                { label: "create project Execution planning", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateProjectExecutionTracker` },
                { label: "Project Execution planning List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ProjectwiseExecutionTrackerList" },
                { label: "Create Customer Feedback", className: "btn btn-success btn-md font-weight-bold text-uppercase ", to: `/CreateCustomerFeedback` },
                { label: "Create Project Closure Feedback", className: "btn btn-success btn-md font-weight-bold text-uppercase ", to: `/CreateProjectClosureFeedback` },
                { label: "Project Closure Feedback List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/ProjectClosureFeedbackList` },
                { label: "Customer Feedback List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/CustomerFeedbackList` },
                { label: "Create project Feedback", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateProjectFeedback` },
                { label: "project Feedback List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/ProjectFeedbackList` },
            ]
          },
      
      
      
          {
            role: "Assitant Manager - Process",
            buttons: [
                { label: "create project Execution planning", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateProjectExecutionTracker` },
                { label: "Project Execution planning List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: "/ProjectwiseExecutionTrackerList" },
                { label: "Create Customer Feedback", className: "btn btn-success btn-md font-weight-bold text-uppercase ", to: `/CreateCustomerFeedback` },
                { label: "Create Project Closure Feedback", className: "btn btn-success btn-md font-weight-bold text-uppercase ", to: `/CreateProjectClosureFeedback` },
                { label: "Project Closure Feedback List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/ProjectClosureFeedbackList` },
                { label: "Customer Feedback List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/CustomerFeedbackList` },
                { label: "Create project Feedback", className: "btn btn-success btn-md font-weight-bold text-uppercase", to: `/CreateProjectFeedback` },
                { label: "project Feedback List", className: "btn btn-primary btn-md font-weight-bold text-uppercase", to: `/ProjectFeedbackList` },
            ]
          },


    ]

    return (
        <div>
            <div className="container-fluid">
                <div className="card shadow mb-2">
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="d-flex flex-start align-items-center">

                            </div>
                            <div className="col-sm-3"><h2>Feedback Dashboard</h2></div>
                            <div className="col-sm-4"></div>
                            <div className="col-sm-5 d-flex flex-row-reverse">
                                <button className="btn btn-info text-uppercase  ml-1 mt-2  " onClick={() => backbutton()}>Back</button>
                                <Link to="/AdminDashboard" className="btn btn-secondary text-uppercase  ml-1 mt-2 ">Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="card cardUser shadow mb-2">
                    <div className="card-header py-3">
                        <h6 className="m-0 font-weight-bold text-primary">Dashboard</h6>
                    </div>
                    <div className="container" style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
                        <div className="row">
                            {roleButtons.map((roleButtonGroup, index) => (
                                (roleButtonGroup.role === role) && (
                                    roleButtonGroup.buttons.map((button, buttonIndex) => (
                                        <div key={buttonIndex} className="col-xl-4 col-md-8">
                                            <div className="card border-success shadow p-1 mt-4 ml-3">
                                                <div className="btn-group">
                                                    <Link to={button.to} className={button.className} type="button">
                                                        {button.label}
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )
                            ))}

                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default FeedbackDashboardComponent