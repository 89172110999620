import React, { Component } from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import EmployeesClosedTaskComponent from '../../../Components/Main/DashboardComponent/EmployeesClosedTaskComponent';

function EmployeesClosedTask(props){
    let navigate = useNavigate();
    const params = useParams();
     
     return (  
         <div> 
             <EmployeesClosedTaskComponent {...props}  navigate={navigate} name={params.name} />
         </div>
     );  

}

export default EmployeesClosedTask