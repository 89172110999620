import React, { Component } from 'react' 
import { useNavigate, useParams } from 'react-router-dom';
import PermitAndPassesDetailsComponent from '../../../Components/Main/PermitAndPassesComponent/PermitAndPassesDetailsComponent';

function PermitPasessDetails(props) {
    let navigate = useNavigate();
    const params = useParams()
    return (  
        <div> 
            <PermitAndPassesDetailsComponent {...props} navigate={navigate} id={params.id} />
        </div>
    );  
  }
  
export default PermitPasessDetails  