import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Service from '../../../Services/Admin/Service';
import { formatDate, getLoginUserData } from '../../../Helpers/supportedFunctions/supportedGlobalFunctions';

const RFPMasterSheetComponent = () => {
  const [rfpMasterSheetdata, setRFPMasterSheetData] = useState([]);
  const [modalData, setModalData] = useState({ rfpId: 0, optionMade: '', userId: 0, designerName: '' });
  const [ratingValue, setRatingValue] = useState(0);
  const [ratingInputData, setRatingInputData] = useState(
    {
      id: 0,
      userId: 0,
      rfpId: 0,
      userName: "",
      optionMade: "",
      kam: 0,
      createdby: getLoginUserData().userId,
    }
  )
  useEffect(() => {
    getRFPMasterSheetData("DesignerTimesheet/GetALLRFPMasterSheet")
  }, [])

  const handleRatingButtonClick = (rfpId, optionMade, userId, designerName) => {
    setModalData({ rfpId, optionMade, userId, designerName });

  };


  const inputRatingHandler = (evt) => {
    setRatingValue(evt.target.value);

  }

  const save = (evt) => {

    if(parseInt(ratingValue)>10||parseInt(ratingValue)<=0){
      alert("Please fill Ratings between 1-10");
      return;
    }
    debugger
    evt.preventDefault();
    var rating = {
      ...ratingInputData,
      kam: parseInt(ratingValue),
      rfpId: modalData.rfpId,
      userId: modalData.userId,
      optionMade: modalData.optionMade,
      userName: modalData.designerName,
    }
    console.log(rating)
    debugger
    if (rating) {
      Service.createorupdate("DesignerTimesheet/CreateOrUpdateSummaryKAM", rating).then((res) => {
        if (res.status === 200) {
          window.location.href = "/RFPMasterSheet";
        }
      })
    }
  }

  const statusChange = (evt,Id) => {
    
    const data = {
      designerTimesheetdetails:[
        {status:evt}, 
      ],
      id: Id,
    }
console.log(data)
debugger
    if(data)
    {
        Service.createorupdate("DesignerTimesheet/CreateOrUpdate",data).then((res)=>{
            if(res.status === 200){
              window.location.href = "/RFPMasterSheet";
            }
        })
    }
  }


  const getRFPMasterSheetData = (method) => {
    Service.getdata(method).then((res) => {

      const userRFP = res.data.filter((x) => x.accountManager === getLoginUserData().name || getLoginUserData().name === "Jothieswaran " || getLoginUserData().role === "Admin" || getLoginUserData().userId === "3");
      debugger
      if (userRFP.length > null) {
        setRFPMasterSheetData(res.data);
      }
    })

  }
  const areaScale = (evt) => {
    var areaValue = parseInt(evt);
    if (areaValue >= 18 && areaValue <= 50) {
      return "Small(S)";
    }
    else if (areaValue >= 51 && areaValue <= 150) {
      return "Medium(M)"
    }
    else if (areaValue >= 150) {
      return "Large(L)"
    }

  }
  const backbutton = (props) => {
    props.navigate(-1);
  }

  return (
    <div>
      <div className="container-fluid">

        <div className="card shadow border-success mb-2">
          <div className="card-header rounded py-3">
            <div className="row">
              <div className="col-sm-7 row">
                <h3 className='mt-2'><b className='text-dark'>RFP Master Sheet</b></h3>
              </div>
              <div className="col-sm-1"></div>
              <div className="col-sm-4 d-flex flex-row-reverse">
                <button className="btn btn-info mt-2 ml-1  text-uppercase " onClick={() => backbutton()}>back</button>
                {/* <Link to="/AdminDashboard" className="btn  text-uppercase btn-secondary pt-2 mt-2 ml-2">Home</Link> */}
              </div>

            </div>
          </div>
        </div>

        <div className="card shadow mb-4">
          <div className="card-header d-flex py-3">
            <h6 className="m-0 mt-1 font-weight-bold text-success ">Master Sheet</h6>
          </div>
          <form>
            <div className="card-body" style={{ overflowX: "auto", overflowY: "auto", maxHeight: "500px" }}>
              <div class="row" >
                <div className="col-lg-12 ml-0">
                  <table class="table table-xl">
                    {/* <caption>List of users</caption> */}
                    <thead className='thead bg-success' style={{ position: "sticky", top: "-19px", zIndex: "1",color:"black" }}>
                      <tr>
                        {/* <th scope="col">Task Id</th> */}
                        <th scope="col">RFP Name</th>
                        <th scope="col">Show Name</th>
                        <th scope="col">Account Manager</th>
                        <th scope="col">Client Type</th>
                        <th scope="col">Area</th>
                        <th scope="col">Area Scale</th>
                        <th scope="col"><h5 className='d-flex justify-content-center' ><b>Multi Option Details</b></h5></th>

                      </tr>
                    </thead>
                    <tbody>
                      {rfpMasterSheetdata.map((e) => (<tr>
                        {/* <th scope="row">{e.id}</th> */}
                        <td>{e.rfpName}</td>
                        <td>{e.showName}</td>
                        <td>{e.accountManager}</td>
                        <td>{e.clientType}</td>
                        <td>{e.area}</td>
                        <td>{areaScale(e.area)}</td>
                        <td>
                          <table>
                            <thead style={{ color:"black", backgroundColor:"lightgreen" }}>
                              <th>Designer Name</th>
                              <th>RFP Date</th>
                              <th>Option Made</th>
                              <th scope="col">Start Date</th>
                              <th scope="col">End Date</th>
                              <th scope="col">Completion Date</th>
                              <th scope="col">Working Hrs</th>
                              <th scope="col">Design Status</th>
                              <th scope="col">Revision Made</th>
                              <th scope="col">Design Type</th>
                              <th scope="col">Project Priority</th>
                              <th scope="col">Design Rating</th>
                              {(getLoginUserData().deptId !== "3" || getLoginUserData().userId === "47") && <th scope="col">Actions</th>}
                            </thead>
                            <tbody>

                              {e.rfpMasterSheetMultFields.map((i) => {
                                return (
                                  <tr>
                                    <td>{i.designerName}</td>
                                    <td>{formatDate(i.rfpDate)}</td>
                                    <td>{i.optionMade}</td>
                                    <td>{formatDate(i.startDate)}</td>
                                    <td>{formatDate(i.endDate)}</td>
                                    <td>{formatDate(i.completionDate)}</td>
                                    <td>{i.totalWorkingHr}</td>
                                    <td><select className="btn" onChange={(e) => statusChange(e.target.value, i.dailyCalendarId)} name="status" value={i.status}>
                                      <option>[NONE]</option>
                                      <option>Converted</option>
                                      <option>Not Converted</option>

                                    </select></td>
                                    <td>{i.revisionMade}</td>
                                    <td>{i.optionType}</td>
                                    <td>{i.projectPriority}</td>
                                    <td>{i.kamMasterSheet}</td>
                                    {(getLoginUserData().deptId !== "3" || getLoginUserData().userId === "47") && 
                                    <td>
                                      {(!i.kamMasterSheet > 0 && e.accountManager === getLoginUserData().name) && <span
                                        type="button"
                                        className="ml-1 btn-sm mt-1 spanStyle"
                                        onClick={() => handleRatingButtonClick(i.rfpId, i.optionMade, i.userId, i.designerName)}
                                        data-toggle="modal"
                                        data-target="#myModal"
                                      >
                                        Rating
                                      </span>}
                                    </td>}

                                  </tr>
                                )
                              })}

                              <td></td>
                            </tbody>
                          </table>
                        </td>
                        {/* <td><Link className="btn btn-warning btn-sm" to={`/TaskDetails/${e.id}&${"deadline"}`}>Details</Link></td> */}
                      </tr>))}


                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="modal fade" id="myModal" role="dialog">
        <div id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h5 class="modal-title" id="exampleModalLongTitle">Fill Rating</h5> */}
                <div className="col-sm-12">
                  <form onSubmit={save}>
                    <div className="col-md-4">
                      <div className="form-group row">
                        <div className='col-sm-12 required-label'><b>Design Rating(1-10)</b></div>
                        <div className='col-sm-12'>
                          <input type="number" min={0} max={10} className='form-control  mb-1 form-control-user' name='KAM' onChange={inputRatingHandler} />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">

              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <div class="btn-group">
                  <button type="button" class="btn btn-primary" onClick={save} data-dismiss="modal">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
export default RFPMasterSheetComponent