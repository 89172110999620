import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import CreateBoqComponent from '../../../Components/Main/BoqComponent/CreateBoqComponent';

function CreateBoq(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <CreateBoqComponent {...props} navigate={navigate} id={params.id} />
        </div>
    );  
  }
export default CreateBoq  