import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import SupplierApprovalListComponent from '../../../Components/Main/ApprovalListComponent/SupplierApprovalListComponent';

function SupplierApprovalList(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <SupplierApprovalListComponent {...props} navigate={navigate} id={params.id} name={params.name}  />
        </div>
    );  
  }
export default SupplierApprovalList 