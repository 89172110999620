import React, { Component } from 'react' 
import { useNavigate, useParams } from 'react-router-dom';
import CreateMeetingSalesComponent from '../../../Components/Main/Meeting/CreateMeetingSalesComponent';


function CreateMeetingSales(props) {
    let navigate = useNavigate();
    let params = useParams();
    return (  
        <div> 
            <CreateMeetingSalesComponent {...props} navigate={navigate} id={params.id}/>
        </div>
    );  
  }
  
export default CreateMeetingSales;  