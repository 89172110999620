import React from 'react' 
import { useNavigate ,useParams } from 'react-router-dom';
import AgreementDetailsComponent from '../../../Components/Main/AgreementComponent/AgreementDetailsComponent';

function AgreementDetails(props) {
    let navigate = useNavigate();
    const params = useParams();
    
    return (  
        <div> 
            <AgreementDetailsComponent {...props} navigate={navigate} id={params.id} />
        </div>
    );  
  }
export default AgreementDetails  